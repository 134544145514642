define("frontend/routes/add-client/primary-contact", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/utils/email-type"], function (_exports, _baseRoute, _cleanupOnTransition, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: Ember.inject.service(),

    model(params) {
      let {
        householdId,
        searchedEmail
      } = params;

      if (householdId) {
        return this.store.findRecord('household', householdId).then(household => {
          let householdUser = household.get('owner');

          if (householdUser.get('addresses.length') == 0) {
            householdUser.get('addresses').pushObject(this.buildAddress());
          }

          if (householdUser.get('phone_numbers.length') == 0) {
            householdUser.get('phone_numbers').pushObject(this.buildPhone());
          }

          return householdUser;
        });
      } else {
        let invoice_frequency = null;

        try {
          invoice_frequency = this.get('currentUser.user.company.default_invoice_frequency.name');
        } catch (err) {
          invoice_frequency = null;
        }

        let user = this.store.createRecord('user');
        let householdUser = this.store.createRecord('household-user', {
          user,
          invoice_frequency
        });
        let defaultEmail = this.store.createRecord('email', {
          address: searchedEmail,
          email_type: _emailType.default.PREFERRED
        });
        householdUser.get('emails').pushObject(defaultEmail);
        householdUser.get('phone_numbers').pushObject(this.buildPhone());
        householdUser.get('addresses').pushObject(this.buildAddress());
        return householdUser;
      }
    },

    buildAddress() {
      let user = this.get('currentUser.user');
      let state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
      let country = user.get('company.country') || user.get('client_accounts.firstObject.country');
      return this.store.createRecord('address', {
        state,
        country
      });
    },

    buildPhone() {
      return this.store.createRecord('phone-number');
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);
    },

    actions: {
      goNext(user) {
        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(savedUser => {
          return this.transitionTo('add-client.secondary-contact', savedUser.get('household.id'));
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});