define("frontend/controllers/invoices-summary", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    invoiceDetail: inject.controller('invoices-summary.invoice-detail'),
    paymentDetail: inject.controller('invoices-summary.payment-detail'),
    activeInvoiceId: computed.readOnly('invoiceDetail.model.id'),
    activePaymentId: computed.readOnly('paymentDetail.model.id'),
    showAddAdjustment: false,
    showAddPayment: false,
    deletingPayment: null,
    queryParams: ['showAddAdjustment', 'showAddPayment', 'backRoute', 'backHouseholdId', 'backId'],
    editing: computed.readOnly('invoiceDetail.editing'),
    media: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    onInvoiceDetail: computed.equal('currentRouteName', 'invoices-summary.invoice-detail'),
    onPaymentDetail: computed.equal('currentRouteName', 'invoices-summary.payment-detail'),
    detailsController: inject.controller('invoices-summary.invoice-detail'),
    editingInvoice: computed.readOnly('detailsController.editing'),
    salesTaxRate: computed.readOnly('invoiceDetail.salesTaxRate'),
    hasClose: computed('showAddPayment', 'showAddAdjustment', 'onInvoiceDetail', 'editingInvoice', 'media.isMobile', 'saving', function () {
      if (this.get('showAddPayment') || this.get('showAddAdjustment')) {
        return false;
      }

      if (this.get('onInvoiceDetail')) {
        return !this.get('editingInvoice') && !this.get('saving') && !this.get('media.isMobile');
      } else {
        return !this.get('saving') && !this.get('media.isMobile');
      }
    }),
    sidePanelClassNames: computed('model.invoiceListItems.[]', function () {
      if (this.get('model.invoiceListItems.length') == 0) {
        return 'no-invoice-list-items';
      }
    }),
    invoices: computed('model.invoiceListItems.@each.invoice', function () {
      return this.get('model.invoiceListItems').filter(item => item.get('invoice') != null).map(listItem => listItem.get('invoice'));
    }),
    invoicePayments: computed('model.invoiceListItems.@each.invoice', function () {
      return this.get('model.invoiceListItems').filter(item => item.get('invoice_payment') != null).map(listItem => listItem.get('invoice_payment'));
    }),
    deletingPaymentInfoText: computed('deletingPayment', 'deletingPayment.payment_method', function () {
      return `... that you'd like to remove this $${this.get('deletingPayment.amount')} ${this.get('deletingPayment.payment_method')} payment?`;
    }),
    actions: {
      saveAdjustment(adjustment) {
        let firstDraftInvoice = this.get('invoices').filterBy('isDraft', true).get('firstObject.invoice_detail');
        let shouldReload = firstDraftInvoice == null;
        adjustment.set('invoice_detail', firstDraftInvoice);
        adjustment.set('updated_at', (0, _moment.default)());
        adjustment.set('household', this.get('model.household'));
        this.set('showAddAdjustment', false);
        adjustment.save().then(() => {
          if (shouldReload) {
            this.send('refresh');
          } else {
            firstDraftInvoice.reload();
          }
        });
      },

      deletePayment(payment) {
        this.set('deletingPayment', payment);
      },

      confirmDeletePayment() {
        if (this.get('deleting')) {
          return;
        }

        this.set('deleting', true);
        let paymentId = this.get('deletingPayment.id');
        let lastItem = null;
        this.get('model.invoiceListItems').every(item => {
          if (item.get('invoice_payment.id') == paymentId) {
            return false;
          }

          lastItem = item;
          return true;
        });
        this.get('deletingPayment').destroyRecord().then(() => {
          if (lastItem === null) {
            this.transitionToRoute('invoices-summary', this.get('model.household.id'));
          } else if (lastItem.get('invoice')) {
            this.send('clickInvoice', lastItem.get('invoice'));
          } else if (lastItem.get('invoice_payment')) {
            this.send('clickPayment', lastItem.get('invoice_payment'));
          }

          this.send('refresh');
        }).finally(() => {
          this.set('deleting', false);
        });
      },

      savePayment(payment) {
        this.set('showAddPayment', false);

        if (this.get('savingPayment')) {
          return;
        }

        this.set('savingPayment', true);
        payment.set('household', this.get('model.household'));
        payment.save().then(() => {
          this.send('refresh');
          this.set('showAddPayment', false);
        }).catch(() => {
          let appController = Ember.getOwner(this).lookup('controller:application');
          appController.send('notify', {
            message: 'Oops! Something went wrong.',
            type: 'error'
          });
        }).finally(() => this.set('savingPayment', false));
      }

    }
  });

  _exports.default = _default;
});