define("frontend/routes/invoices-summary/invoice-detail", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model(_ref) {
      let {
        invoiceId
      } = _ref;
      // Async relationships are cached
      Ember.run.scheduleOnce('afterRender', () => this.store.findRecord('invoice-detail', invoiceId));
      return this.store.findRecord('invoice', invoiceId);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('saving', false);
      controller.set('alreadyResent', false);
      controller.set('editingAdjustment', null);
      controller.get('deletedAdjustments', []);
    },

    actions: {
      goBack() {
        this.transitionTo('invoices-summary.index', this.get('controller.model.household.id'));
      }

    }
  });

  _exports.default = _default;
});