define("frontend/components/portal-sidebar-nav", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['sidebar-nav'],
    classNameBindings: ['collapsed'],
    tagName: 'nav',
    currentUser: service(),
    beacon: service(),
    reactApp: service(),
    collapsed: true,
    expanded: computed.not('collapsed'),
    timeZone: service(),
    session: service(),
    media: service(),
    accountSession: service(),
    inboxHasUnreadMessages: service(),
    hasUnreadMessages: computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    documentsLink: computed('currentUser.user.household.id', function () {
      const householdId = this.get('currentUser.user.household.id');
      return this.get('reactApp').getPetParentDocumentsUrl(householdId);
    }),

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)('.ember-application').addClass('no-body-padding');
      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('.ember-application').removeClass('no-body-padding');
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },

    showSwitchAccount: computed('currentUser.user.staff_companies', function () {
      let staffCompanies = this.get('currentUser.user.staff_companies');

      if (staffCompanies && staffCompanies.length >= 1) {
        return true;
      }

      return false;
    }),
    showDocumentsDot: computed.alias('currentUser.hasAgreementsToReview'),

    handleMouseEnter() {
      if (this.get('media.isDesktop')) {
        this.set('collapsed', false);
      }
    },

    handleMouseLeave() {
      this.set('collapsed', true);
    },

    fullDate: computed('timeZone', function () {
      let tz = this.get('timeZone');
      return (0, _moment.default)().utcOffset(tz.get('offset')).format('dddd, MMMM D, YYYY');
    }),
    shortDate: computed('timeZone', function () {
      let tz = this.get('timeZone');
      return (0, _moment.default)().utcOffset(tz.get('offset')).format('MMM D');
    }),
    actions: {
      signOut() {
        this.get('accountSession').signOut();
      },

      openSupport() {
        this.get('beacon').openSupportBeacon();
      }

    }
  });

  _exports.default = _default;
});