define("frontend/components/multiple-household-message-thread", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['messages-thread', 'has-side-panel'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    messages: [],
    noMessages: computed.equal('messages.length', 0),
    specificDatesSelected: false,
    typedMessage: '',
    households: [],
    company: null,
    datedMessages: computed('messages.[]', function () {
      let datedMessages = {};
      let messages = this.get('messages');
      messages.forEach(message => {
        let date = moment(message.get('created_at')).startOf('day').format('YYYY-MM-DD');

        if (!datedMessages[date]) {
          datedMessages[date] = [];
        }

        datedMessages[date].push(message);
      });
      let messageObjects = [];
      let sortedDates = Object.keys(datedMessages).sort(this.sortDates);
      sortedDates.forEach(date => {
        let sortedMessages = datedMessages[date].sortBy('created_at');
        messageObjects.push(Ember.Object.create({
          date,
          messages: sortedMessages
        }));
      });
      return messageObjects;
    }),
    householdsObserver: observer('households.[]', 'selectedDistributionList', function () {
      if (this.get('selectedDistributionList') == '' && this.get('households.count') > 1) {
        this.set('messages', []);
      }
    }),

    didInsertElement() {
      this.scrollToBottomOfThread();
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },

    sortDates(a, b) {
      let momentA = moment(a);
      let momentB = moment(b);

      if (momentA.isBefore(momentB)) {
        return -1;
      }

      return 1;
    },

    scrollToBottomOfThread() {
      Ember.run.scheduleOnce('afterRender', () => {
        let messageThread = document.getElementById('messages-area');

        if (messageThread) {
          messageThread.scrollTop = messageThread.scrollHeight;
        }
      });
    },

    actions: {
      sendMessage() {
        let message = this.get('typedMessage');

        if (!message) {
          return;
        }

        this.set('typedMessage', '');
        this.set('sendingMessage', true);
        let household_ids = [];
        let sentToText = null;

        if (this.get('selectedDistributionList') === 'selectedAppointments') {
          sentToText = `Sent to ${this.get('familiesWithAppointmentsTitle').replace('...', '').toLowerCase()} ${this.get('familiesWithAppointmentsText').replace('...', '')}`;
        } else if (this.get('selectedDistributionList') === undefined) {
          sentToText = 'Sent to select families';
        } else {
          sentToText = 'Sent to all families';
        }

        household_ids = this.get('households').map(_ref => {
          let {
            id
          } = _ref;
          return id;
        });
        let data = {
          message,
          household_ids,
          sent_by_staff: true,
          company_id: this.get('company.id'),
          show_in_grouped_messages: this.get('selectedDistributionList') !== '',
          send_to_all_households: this.get('selectedDistributionList') === 'allHouseholds',
          sent_to: sentToText,
          email_send_required: this.get('specificDatesSelected')
        };

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/plain_text`,
          method: 'POST',
          data
        }).then(() => {
          this.set('sendingMessage', false);

          if (this.get('selectedDistributionList') === '') {
            this.get('households').forEach(household => {
              household.set('user_last_seen_message_thread', moment());
            });
          }

          this.get('transitionBackToInbox')();
        });
      },

      sendImageMessage(photo, finishedUploadingCallback) {
        this.set('uploadingPhoto', true);
        let fileType = photo.type;
        let splitFileType = fileType.split('/');
        let fileName = photo.name;

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/create_upload_url`,
          method: 'POST',
          data: {
            file_type: fileType
          }
        }).then(response => {
          let formData = new FormData();

          for (let key in response.fields) {
            let value = response.fields[key];
            formData.set(key, value);
          }

          return photo.upload(response.url, {
            data: response.fields
          }).then(() => {
            return response.fields.key;
          });
        }).then(key => {
          let household_ids = [];
          let sentToText = null;

          if (this.get('selectedDistributionList') === '') {
            household_ids = this.get('households').map(household => household.get('id'));
          } else {
            if (this.get('selectedDistributionList') === 'selectedAppointments') {
              sentToText = `Sent to ${this.get('familiesWithAppointmentsTitle').replace('...', '').toLowerCase()} ${this.get('familiesWithAppointmentsText').replace('...', '')}`;
            } else {
              sentToText = 'Sent to all families';
            }

            household_ids = this.get('households').map(_ref2 => {
              let {
                id
              } = _ref2;
              return id;
            });
          }

          let data = {
            image_url: key,
            household_ids,
            sent_by_staff: !this.get('isPetParent'),
            company_id: this.get('company.id'),
            show_in_grouped_messages: this.get('selectedDistributionList') !== '',
            file_name: fileName,
            file_type: splitFileType[0] == 'application' ? splitFileType[1] : splitFileType[0],
            send_to_all_households: this.get('selectedDistributionList') === 'allHouseholds',
            sent_to: sentToText,
            email_send_required: this.get('specificDatesSelected')
          };
          return _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/image`,
            method: 'POST',
            data
          }).then(() => {
            Ember.run.schedule('afterRender', () => {
              if (finishedUploadingCallback) {
                finishedUploadingCallback();
              }
            });
            this.set('uploadingPhoto', false);

            if (this.get('selectedDistributionList') === '') {
              this.get('households').forEach(household => {
                household.set('user_last_seen_message_thread', moment());
              });
              this.get('transitionBackToInbox')();
            } else {
              window.location.reload();
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});