define("frontend/controllers/schedule", ["exports", "frontend/utils/calendar-util", "frontend/config/environment", "frontend/utils/date-util", "moment", "jquery"], function (_exports, _calendarUtil, _environment, _dateUtil, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inFuture
  } = _dateUtil.default;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['hidingSidePanel'],
    scheduleDragActionService: inject.service(),
    router: inject.service(),
    store: inject.service(),
    media: inject.service(),
    ajax: inject.service(),
    selectedAppointment: null,
    reportCard: null,
    reportCardAppointment: null,
    searchValue: '',
    hidingSidePanel: false,
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    onCalendarRoute: computed.equal('currentRouteName', 'schedule.calendar'),
    scheduleCalendar: Ember.inject.controller('schedule.calendar'),
    currentUser: inject.service(),
    showAppointmentInFutureFlash: false,
    appointmentInFutureFlashAction: null,
    calendarOptions: inject.service(),
    showingCalendarOptions: computed.alias('calendarOptions.showingCalendarOptions'),
    onRequestsPage: computed.equal('care_provider_id', 'requests'),
    pendingRequestsCount: 0,
    highlightWindowStart: null,
    highlightWindowEnd: null,
    appointmentReportCardQueue: [],
    groupedAppointmentQueue: [],
    services: computed.alias('model.services'),
    printButtonUrl: 'assets/images/printer-gray.svg',
    showPrintModal: false,
    showPrintDetails: false,
    searchFieldPlaceholder: "Search schedule by pet name",
    loadingCalendarDates: true,
    groupedReportCardComponent: computed('groupedReportCard.draft', function () {
      return this.get('groupedReportCard.draft') ? 'edit-grouped-report-card' : 'view-grouped-report-card';
    }),
    onWeekCalendar: computed('currentRouteName', 'scheduleCalendar.selectedCareProvider', function () {
      return this.get('currentRouteName') === 'schedule.calendar' && this.get('scheduleCalendar.selectedCareProvider') !== 'all';
    }),
    groupedAppointment: computed('selectedGroupedAppointment', 'selectedAppointment.grouped_appointment', function () {
      return Ember.isBlank(this.get('selectedGroupedAppointment')) ? this.get('selectedAppointment.grouped_appointment') : this.get('selectedGroupedAppointment');
    }),
    staffWithAll: computed('staff.@each.label', 'currentUser.user.canViewAppointmentRequests', 'pendingRequestsCount', function () {
      let staff = [...this.get('staff')];
      staff.unshift({
        label: 'All staff',
        value: 'all'
      });
      staff.unshift({
        label: 'Cancellations',
        value: 'cancellations'
      });

      if (this.get('media.isMobile') && this.get('currentUser.user.canViewAppointmentRequests')) {
        let label = this.get('pendingRequestsCount') > 0 ? `Requests <div class="request-count">${this.get('pendingRequestsCount')}</div>` : 'Requests';
        staff.unshift({
          label,
          value: 'requests'
        });
      }

      return staff;
    }),
    staffWithOnlyCurrent: computed('currentUser.user.id', 'currentUser.user.company.staff_appointment_approval', 'media.isMobile', function () {
      let staff = [{
        label: 'My schedule',
        value: this.get('currentUser.user.id')
      }, {
        label: 'Cancellations',
        value: 'cancellations'
      }];

      if (this.get('media.isMobile') && this.get('currentUser.user.company.staff_appointment_approval')) {
        let label = this.get('pendingRequestsCount') > 0 ? `Requests <div class="request-count">${this.get('pendingRequestsCount')}</div>` : 'Requests';
        staff.unshift({
          label,
          value: 'requests'
        });
      }

      return staff;
    }),
    staff: computed('model.staff.[]', 'model.staff.@each.deleted', 'model.staff.@each.fullName', function () {
      if (!this.get('model.staff')) {
        return [];
      }

      let staff = this.get('model.staff').map(staff => {
        return {
          label: `${staff.get('fullName')}`,
          value: staff.id
        };
      });
      return staff.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }

        if (b.label > a.label) {
          return -1;
        }

        return 0;
      });
    }),
    isToday: computed('date', function () {
      let selectedDay = (0, _moment.default)(this.get('date')).startOf('day');
      let today = (0, _moment.default)().startOf('day');
      return selectedDay.diff(today, 'days') === 0;
    }),
    isThisWeek: computed('date', function () {
      let selectedWeek = (0, _moment.default)(this.get('date')).startOf('week');
      let thisWeek = (0, _moment.default)().startOf('week');
      return selectedWeek.diff(thisWeek, 'weeks') === 0;
    }),

    warnAppointmentInFuture(actionTaken) {
      this.set('showAppointmentInFutureFlash', true);
      this.set('appointmentInFutureFlashAction', actionTaken);
    },

    hideWarningAppointmentInFuture() {
      this.set('showAppointmentInFutureFlash', false);
      this.set('appointmentInFutureFlashAction', null);
    },

    // Handles both complete and uncomplete
    // TODO: Consider moving to service
    setComplete(appointment, completed) {
      if (appointment.get('updatingCompleteStatus')) {
        return;
      }

      appointment.set('updatingCompleteStatus', true);

      let handleCompleting = (finishedAtLat, finishedAtLong) => {
        let groupedAppointment = this.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));
        let data = {
          appointments: []
        };
        groupedAppointment.get('appointments').forEach(appt => {
          data.appointments.push({
            id: appt.get('id'),
            completed,
            finished_at_latitude: finishedAtLat,
            finished_at_longitude: finishedAtLong
          });
        });
        return _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/complete`,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(response => {
          response.appointments.forEach(apptResponse => {
            if (apptResponse.error) {
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: apptResponse.error,
                type: 'user-error'
              });
            }
          });
          let reloadPromises = groupedAppointment.get('appointments').map(appt => {
            return appt.reload().then(() => {
              let isCompleted = appt.get('completed');
              let showReportCard = appt.get('show_report_card_on_completion');

              if (isCompleted && showReportCard) {
                this.get('groupedAppointmentQueue').push(appt.get('grouped_appointment'));
              }
            });
          });
          return Ember.RSVP.all(reloadPromises).then(() => {
            if (this.get('groupedAppointmentQueue.length') > 0) {
              this.startGroupedReportCardQueue();
            } else {
              this.send('closeAppointmentDetails');
            }

            appointment.set('updatingCompleteStatus', false);
          });
        }).fail(error => {
          let message = 'Unexpected error marking appointment complete. Please try again';
          let type = 'error';

          if (error && error.responseText) {
            let parsed = JSON.parse(error.responseText);

            if (parsed.errors) {
              message = parsed.errors.appointments;
            } else if (parsed.message) {
              message = parsed.message;
            }
          }

          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type
          });
          appointment.set('updatingCompleteStatus', false);
        });
      }; // make sure that we do not show gps pins if only marking complete...


      let gpsEnabled = appointment.get('offered_service.service_category.enable_gps_on_check_in');
      let startedAt = appointment.get('started_at');
      let checkInRequired = appointment.get('offered_service.service_category.show_check_in_check_out_appointment');
      let startsAt = (0, _moment.default)(appointment.get('service.starts_at'));
      let duration = appointment.get('offered_service.duration_in_minutes');
      let endTime = appointment.get('time_frame_end_time');
      let apptStartsAt;

      if (endTime) {
        apptStartsAt = (0, _moment.default)(`${startsAt.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mmA');
      } else {
        apptStartsAt = startsAt;
      }

      let careProvider = appointment.get('service.care_provider.id');
      let user = this.get('currentUser.user.id');
      let inTimeWindow = (0, _moment.default)().isBefore(apptStartsAt.add(duration, 'minutes')); // make sure to check if inside the time window on right side conditional

      if (careProvider === user && !this.get('media.isDesktop') && (checkInRequired && gpsEnabled && startedAt || !checkInRequired && gpsEnabled && inTimeWindow)) {
        navigator.geolocation.getCurrentPosition(position => handleCompleting(position.coords.latitude, position.coords.longitude), () => handleCompleting(null, null));
      } else {
        handleCompleting(null, null);
      }
    },

    checkAppointment(actionToCall) {
      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_check?id=${this.get('selectedAppointment.id')}`,
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(response => {
          if (response.closed) {
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            this.send(actionToCall);
          }
        });
      }
    },

    checkGroupedAppointment(actionToCall) {
      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_check?id=${this.get('selectedGroupedAppointment.appointments.firstObject.id')}`,
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(response => {
          if (response.closed) {
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            this.send(actionToCall);
          }
        });
      }
    },

    checkService(actionToCall) {
      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/service_check?id=${this.get('selectedService.id')}`,
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(response => {
          if (response.closed) {
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            this.send(actionToCall);
          }
        });
      }
    },

    queueGroupedAppointment(groupedAppointment) {
      this.get('groupedAppointmentQueue').push(groupedAppointment);
    },

    startGroupedReportCardQueue() {
      let queue = this.get('groupedAppointmentQueue');
      let uniqueQueue = [];
      queue.forEach(ga => {
        let alreadyExists = uniqueQueue.some(value => value.get('id') == ga.get('id'));

        if (!alreadyExists) {
          uniqueQueue.push(ga);
        }
      });
      this.set('groupedAppointmentQueue', uniqueQueue);
      this.nextReportCard();
    },

    nextReportCard() {
      let groupedAppointment = this.get('groupedAppointmentQueue').shift();

      if (groupedAppointment) {
        let appt = groupedAppointment.get('appointments.firstObject');

        if (appt.get('grouped_report_card')) {
          this.send('openReportCardModal', appt.get('grouped_report_card'));
        } else {
          let grc = this.get('store').createRecord('grouped_report_card', {
            appt_id: appt.get('id')
          });
          grc.save().then(() => {
            groupedAppointment.get('appointments').forEach(groupedApptAppt => {
              groupedApptAppt.set('grouped_report_card', grc);
            });
            this.send('openReportCardModal', grc);
          });
        }
      } else {
        this.set('groupedReportCard', null);
      }
    },

    transitionToEdit(appointmentIds) {
      let queryParams = {
        backRoute: this.get('currentRouteName'),
        ids: appointmentIds,
        backDate: this.get('date'),
        backCareProviderId: this.get('care_provider_id')
      };
      this.transitionToRoute('edit-single-appointment.index', {
        queryParams
      });
    },

    actions: {
      cancelAppointmentInFutureFlash() {
        this.hideWarningAppointmentInFuture();
      },

      todayAppointmentInFutureFlash() {
        this.send('today');
        this.send('cancelAppointmentInFutureFlash');
      },

      addInstructions() {
        this.set('showAddInstructionsModal', true);
      },

      saveInstructions(appointment) {
        this.set('showAddInstructionsModal', false);
        appointment.save().catch(() => {
          appointment.rollbackAttributes();
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: 'An unexpected error occurred, unable to save instructions',
            type: 'error'
          });
        });
      },

      cancelAddInstructions() {
        this.set('showAddInstructionsModal', false);
        this.get('selectedAppointment').rollbackAttributes();
      },

      removeInstructions(appointment) {
        this.set('showAddInstructionsModal', false);
        appointment.set('special_instructions', '');
        appointment.save().catch(() => {
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: 'An unexpected error occurred, unable to remove instructions',
            type: 'error'
          });
        });
      },

      clickPet(pet) {
        this.transitionToRoute('client-list.household.pet', pet.get('household.id'), pet.get('id'), {
          queryParams: {
            backDate: this.get('date'),
            backCareProviderId: this.get('care_provider_id')
          }
        });
      },

      denyDeleteService() {
        this.set('showDeleteServiceFlash', false);
      },

      startAppointment(appointment) {
        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('starting');
          return;
        }

        appointment.set('updatingCompleteStatus', true);

        let handleStarting = (startedAtLatitude, startedAtLongitude) => {
          let groupedAppointment = this.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));
          let data = {
            appointments: []
          };
          groupedAppointment.get('appointments').forEach(appt => {
            data.appointments.push({
              id: appt.get('id'),
              start: true,
              started_at_latitude: startedAtLatitude,
              started_at_longitude: startedAtLongitude
            });
          });

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/appointments/start`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data)
          }).then(response => {
            let reloadPromises = [];
            groupedAppointment.get('appointments').forEach(appt => {
              reloadPromises.push(appt.reload());
            });
            Ember.RSVP.all(reloadPromises).then(() => {
              appointment.set('updatingCompleteStatus', false);
            });
          }).fail(() => {
            let message = 'Unexpected error starting appointment. Please try again';
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message,
              type: 'error'
            });
            appointment.set('updatingCompleteStatus', false);
          });
        };

        if (appointment.get('offered_service.service_category.enable_gps_on_check_in') && !this.get('media.isDesktop')) {
          navigator.geolocation.getCurrentPosition(position => handleStarting(position.coords.latitude, position.coords.longitude), () => handleStarting(null, null));
        } else {
          handleStarting(null, null);
        }
      },

      undoStartAppointment(appointment) {
        let groupedAppointment = this.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));
        appointment.set('updatingCompleteStatus', true);
        let data = {
          appointments: []
        };
        groupedAppointment.get('appointments').forEach(appt => {
          data.appointments.push({
            id: appt.get('id'),
            start: false
          });
        });

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/start`,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(response => {
          let reloadPromises = [];
          groupedAppointment.get('appointments').forEach(appt => {
            reloadPromises.push(appt.reload());
          });
          Ember.RSVP.all(reloadPromises).then(() => {
            appointment.set('updatingCompleteStatus', false);
          });
        }).fail(() => {
          let message = 'Unexpected error unstarting appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          appointment.set('updatingCompleteStatus', false);
        });
      },

      deleteAppointment() {
        this.checkAppointment('setShowDeleteAppointmentModal');
        this.set('methodOfDeletion', 'appointment details delete button');
      },

      cancelAppointment() {
        this.checkAppointment('setShowCancelAppointmentModal');
      },

      restoreAppointment() {
        if (this.get('restoringAppointment')) {
          return;
        }

        this.set('restoringAppointment', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/${this.get('selectedAppointment.id')}/restore`,
          method: 'POST'
        }).then(response => {
          this.get('selectedAppointment').reload().then(reloadedAppt => {
            let groupedAppointment = reloadedAppt.get('grouped_appointment');
            let allAppointmentsNotCanceled = groupedAppointment.get('appointments').filter(appt => {
              return !appt.get('canceled_at');
            }).length === groupedAppointment.get('appointments.length');

            if (allAppointmentsNotCanceled) {
              groupedAppointment.set('canceled', false);
            }

            if (response.removed_cancellation_fee) {
              this.set('showRemovedCancellationFeeModal', true);
            } else {
              let appt = this.get('selectedAppointment');
              let message = `You've successfully restored ${appt.get('pet.name')}'s ${appt.get('offered_service.name')} on ${(0, _moment.default)(appt.get('service.starts_at')).format('ddd, MMM D')}, to ${appt.get('service.care_provider.fullName')}'s schedule`;
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message,
                type: 'success-fireworks'
              });
            }

            this.send('closeAppointmentDetails');
            this.set('restoringAppointment', false);
          });
        }).fail(() => {
          let message = 'Unexpected error restoring appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('restoringAppointment', false);
        });
      },

      closeRemovedCancellationFeeModal() {
        this.set('showRemovedCancellationFeeModal', false);
      },

      setShowDeleteAppointmentModal() {
        this.set('showDeleteAppointmentModal', true);
      },

      setShowDeleteServiceFlash() {
        this.set('showDeleteServiceFlash', true);
      },

      setShowCancelAppointmentModal() {
        this.set('showCancelAppointmentModal', true);
      },

      confirmDeleteAppointment() {
        this.set('showDeleteAppointmentModal', false);

        if (this.get('deletingAppointment')) {
          return;
        }

        this.set('deletingAppointment', true);
        this.get('selectedAppointment').destroyRecord({
          adapterOptions: {
            method: this.get('methodOfDeletion')
          }
        }).then(() => {
          this.send('closeAppointmentDetails');
        }).catch(() => {
          this.get('selectedAppointment').rollbackAttributes();
        }).finally(() => {
          this.set('deletingAppointment', false);
          this.set('methodOfDeletion', null);
        });
      },

      confirmArchiveGroupedAppointment(groupedAppointment, apptIds) {
        this.set('showDeleteAppointmentModal', false);

        if (this.get('deletingAppointment')) {
          return;
        }

        this.set('deletingAppointment', true);
        let appts = groupedAppointment.get('appointments').filter(appt => apptIds.includes(appt.get('id')));
        let promises = appts.map(appt => appt.destroyRecord({
          adapterOptions: {
            method: 'dragging to trash can'
          }
        }));
        Ember.RSVP.Promise.all(promises).then(() => {
          this.send('closeAppointmentDetails');
        }).finally(() => {
          this.set('deletingAppointment', false);
          this.set('selectedGroupedAppointment', null);
        });
      },

      confirmCancelAppointment(apptIds, cancellationFee) {
        this.set('showCancelAppointmentModal', false);

        if (this.get('cancelingAppointment')) {
          return;
        }

        this.set('cancelingAppointment', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/cancel_multiple`,
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee
          }
        }).then(() => {
          let appointment = this.get('selectedAppointment');

          if (appointment) {
            appointment.reload().then(reloadedAppt => {
              let groupedAppointment = reloadedAppt.get('grouped_appointment');
              let allAppointmentsCanceled = groupedAppointment.get('appointments').filter(appt => {
                return appt.get('canceled_at');
              }).length === groupedAppointment.get('appointments.length');

              if (allAppointmentsCanceled) {
                groupedAppointment.set('canceled', true);
              }
            });
            this.send('closeAppointmentDetails');
            this.set('cancelingAppointment', false);
          } else {
            throw 'Must select an appointment to cancel.';
          }
        }).fail(() => {
          let message = 'Unexpected error canceling appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('cancelingAppointment', false);
        });
      },

      confirmCancelGroupedAppointment(groupedAppointment, apptIds, cancellationFee, cancellationNote) {
        this.set('showCancelAppointmentModal', false);

        if (this.get('cancelingAppointment')) {
          return;
        }

        this.set('cancelingAppointment', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/cancel_multiple`,
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee,
            cancellation_notes: cancellationNote
          }
        }).then(() => {
          let appts = groupedAppointment.get('appointments').filter(appt => apptIds.includes(appt.get('id')));
          let reloadPromises = [];
          appts.forEach(appointment => {
            reloadPromises.push(appointment.reload());
          });
          Ember.RSVP.all(reloadPromises).then(() => {
            let allAppointmentsCanceled = appts.filter(appt => {
              return appt.get('canceled_at');
            }).length === groupedAppointment.get('appointments.length');

            if (allAppointmentsCanceled) {
              groupedAppointment.set('canceled', true);
            }

            this.send('closeAppointmentDetails');
            this.set('cancelingAppointment', false);
          });
        }).fail(() => {
          let message = 'Unexpected error canceling appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('cancelingAppointment', false);
        });
      },

      denyDeleteAppointment() {
        this.set('showDeleteAppointmentModal', false);
        this.set('methodOfDeletion', null);
      },

      denyCancelAppointment() {
        this.set('showCancelAppointmentModal', false);
      },

      confirmDeleteService() {
        this.set('showDeleteServiceFlash', false);

        if (this.get('deletingService')) {
          return;
        }

        this.set('deletingService', true);
        this.get('selectedService').destroyRecord().then(() => {
          this.send('closeAppointmentDetails');
        }).catch(() => {
          this.get('selectedService').rollbackAttributes();
        }).finally(() => {
          this.set('deletingService', false);
        });
      },

      editAppointment() {
        this.checkAppointment('checkShouldShowModifyModal');
      },

      checkShouldShowModifyModal() {
        if (this.get('groupedAppointment.appointments.length') > 1) {
          this.set('showModifyAppointmentModal', true);
        } else {
          this.transitionToEdit([this.get('selectedAppointment.id')]);
        }
      },

      closeModifyAppointmentModal() {
        this.set('showModifyAppointmentModal', false);
      },

      modifyAppointment(appointmentIds) {
        this.transitionToEdit(appointmentIds);
        this.set('showModifyAppointmentModal', false);
      },

      modifyAppointmentRequest(appointmentRequest) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          requestId: appointmentRequest.get('id')
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams
        });
      },

      setPendingRequestsCount(pendingRequestsCount) {
        this.set('pendingRequestsCount', pendingRequestsCount);
      },

      updateCalendarHighlight() {
        this.set('loadingCalendarDates', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests/pending_dates`,
          method: 'GET'
        }).done(response => {
          this.set('pendingRequestDates', response.pending_dates);
          this.set('loadingCalendarDates', false);
        });
      },

      clickSearch() {
        this.toggleProperty('searching');
        this.set('searchValue', '');
        Ember.run.scheduleOnce('afterRender', () => {
          (0, _jquery.default)('.search-input').focus();
        });
      },

      clickDragAndDrop() {
        this.toggleProperty('dragAndDropActivated');
        this.set('showDragAndDropFlashNote', true);
        Ember.run.later(() => {
          this.set('showDragAndDropFlashNote', false);
        }, 2000);
      },

      clickFilter() {
        this.get('requestListReference').showFilterOptions();
      },

      deleteAppointmentOrService(obj) {
        this.get('scheduleDragActionService').stopDragging();
        this.set('methodOfDeletion', 'dragging to trash can');

        if (obj.constructor.modelName === 'service') {
          this.set('selectedService', obj);
          this.checkService('setShowDeleteServiceFlash');
        } else {
          this.set('selectedGroupedAppointment', obj);
          this.checkGroupedAppointment('setShowDeleteAppointmentModal');
        }
      },

      dragDropCancelAppointment(obj) {
        this.get('scheduleDragActionService').stopDragging();
        this.set('selectedGroupedAppointment', obj);
        this.checkGroupedAppointment('setShowCancelAppointmentModal');
      },

      transitionToNew() {
        let selectedHouseholdId = null;
        let filter = '';
        let date = this.get('date');
        let backRoute = this.get('currentRouteName');
        let queryParams = {
          selectedHouseholdId,
          filter,
          date,
          backRoute
        };
        this.transitionToRoute('add-appointment.index', {
          queryParams
        });
      },

      goToRequests() {
        this.set('care_provider_id', 'requests');
      },

      hideSidePanel() {
        this.set('hidingSidePanel', true);
        $('.ember-application').addClass('side-panel-hidden');

        _calendarUtil.default.resizeExistingHeaders();
      },

      showSidePanel() {
        this.set('hidingSidePanel', false);
        $('.ember-application').removeClass('side-panel-hidden');

        _calendarUtil.default.resizeExistingHeaders();
      },

      nextDay() {
        let nextDay = (0, _moment.default)(this.get('date')).add(1, 'day');
        this.send('changeDate', nextDay);
      },

      previousDay() {
        let prevDay = (0, _moment.default)(this.get('date')).subtract(1, 'day');
        this.send('changeDate', prevDay);
      },

      nextWeek() {
        let nextWeek = (0, _moment.default)(this.get('date')).add(1, 'week');
        this.send('changeDate', nextWeek);
      },

      previousWeek() {
        let prevWeek = (0, _moment.default)(this.get('date')).subtract(1, 'week');
        this.send('changeDate', prevWeek);
      },

      today() {
        this.send('changeDate', (0, _moment.default)());
      },

      changeDate(momentDate) {
        this.send('closeAppointmentDetails');
        this.set('date', momentDate.format('YYYY-MM-DD'));
      },

      closeAppointmentDetails() {
        this.set('selectedAppointment', null);
      },

      markComplete(appointment) {
        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('completing');
        } else {
          this.setComplete(appointment, true);
        }
      },

      markUncomplete(appointment) {
        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('completing');
        } else {
          this.setComplete(appointment, false);
        }
      },

      closeGroupedReportCard() {
        this.nextReportCard();
        this.send('closeAppointmentDetails');
      },

      openReportCardModal(groupedReportCard) {
        this.set('groupedReportCard', groupedReportCard);
      },

      startDraggingRequest(appointmentRequest) {
        this.set('highlightWindowStart', appointmentRequest.get('time_frame_start_time'));
        this.set('highlightWindowEnd', appointmentRequest.get('time_frame_end_time'));
      },

      endDraggingRequest() {
        this.set('highlightWindowStart', null);
        this.set('highlightWindowEnd', null);
      },

      hoverPrinterButton(hovered) {
        if (hovered) {
          this.set('printButtonUrl', 'assets/images/printer-black.svg');
        } else {
          this.set('printButtonUrl', 'assets/images/printer-gray.svg');
        }
      },

      onOpenPrintModal() {
        this.set('showPrintModal', true);
      },

      onClosePrintModal() {
        this.set('showPrintModal', false);
      },

      onShowPrintDetails() {
        this.set('showPrintDetails', true);
        this.send('printSchedule');
      },

      onHidePrintDetails() {
        this.set('showPrintDetails', false);
        this.send('printSchedule');
      },

      printSchedule() {
        let w = window.open();
        let weekType = this.get('currentUser.user.company.weekType');
        let care_provider_id = this.get('care_provider_id');
        let schedule_type = this.get('currentRouteName') == 'schedule.calendar' ? 'calendar' : 'list';
        let show_details = this.get('showPrintDetails');
        let start_date, end_date;

        if (schedule_type === 'calendar') {
          start_date = care_provider_id === 'all' ? this.get('date') : (0, _moment.default)(this.get('date')).startOf(weekType).format('Y-M-D');
          end_date = care_provider_id === 'all' ? this.get('date') : (0, _moment.default)(this.get('date')).endOf(weekType).format('Y-M-D');
        } else {
          start_date = this.get('date');
          end_date = this.get('date');
        } // When testing on dev remove dataType: 'html'


        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/services/print`,
          method: 'GET',
          dataType: 'html',
          data: {
            care_provider_id,
            start_date,
            end_date,
            schedule_type,
            show_details
          }
        }).done(response => {
          this.send('onClosePrintModal');
          w.document.open();
          w.document.write(response);
          w.document.close();

          w.onload = function () {
            setTimeout(() => {
              w.print();
              w.close();
            }, 500);
          };
        });
      },

      setRequestListChild(requestListReference) {
        this.set('requestListReference', requestListReference);
      }

    }
  });

  _exports.default = _default;
});