define("frontend/routes/edit-client/add-secondary-contact", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/mixins/transition-back-route", "frontend/utils/email-type"], function (_exports, _baseRoute, _cleanupOnTransition, _transitionBackRoute, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, _transitionBackRoute.default, {
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    saving: computed.alias('addClientSaving.saving'),

    model() {
      let household = this._super(...arguments);

      let user = this.store.createRecord('user');
      let householdUser = this.store.createRecord('household-user', {
        user,
        household
      });
      let defaultEmail = this.store.createRecord('email', {
        email_type: _emailType.default.PREFERRED
      });
      let defaultPhone = this.store.createRecord('phone-number');
      householdUser.get('phone_numbers').pushObject(defaultPhone);
      householdUser.get('emails').pushObject(defaultEmail);
      householdUser.get('addresses').pushObject(this.buildAddress(household));
      return householdUser;
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);
    },

    buildAddress(household) {
      let address = household.get('owner.addresses.firstObject');

      if (address) {
        return this.store.createRecord('address', {
          street: address.get('street'),
          city: address.get('city'),
          state: address.get('state'),
          zipcode: address.get('zipcode'),
          country: address.get('country'),
          address_type: address.get('address_type'),
          notes: address.get('notes')
        });
      } else {
        let user = this.get('currentUser.user');
        let state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        let country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        return this.store.createRecord('address', {
          state,
          country
        });
      }
    },

    actions: {
      save(user) {
        this.set('saving', true);
        let editClient = Ember.getOwner(this).lookup('controller:edit-client');
        let household = editClient.get('model');
        let backRoute = editClient.get('backRoute');
        let backId = editClient.get('backId');
        let ownerEmails = user.get('household.owner.emails');
        let emails = user.get('emails');
        let duplicateEmail = false;
        emails.forEach(email => {
          ownerEmails.forEach(ownerEmail => {
            if (email.get('address').toLowerCase() == ownerEmail.get('address').toLowerCase()) {
              duplicateEmail = true;
              this.get('flashMessage').sendFlashMessage(`Sorry, humans can't share emails! Please remove ${email.get('address')} from ${user.get('household.owner.first_name')}'s profile and save before adding a new human with this email.`, 'user-error');
              this.set('saving', false);
              return;
            }
          });
        });

        if (duplicateEmail) {
          return;
        }

        this.get('addClientSaving').saveUser(user).then(() => {
          if (this.get('currentUser.user.isPetParent')) {
            household = null;
          }

          return this.transitionBack(backRoute, household, backId);
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});