define("frontend/services/google-maps-geocode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    geocode(address) {
      let geocoder = new google.maps.Geocoder();
      return new Ember.RSVP.Promise((resolve, reject) => {
        geocoder.geocode({
          address
        }, (results, status) => {
          if (status === 'OK') {
            let [{
              place_id
            }] = results;
            let [{
              geometry
            }] = results;
            resolve({
              placeId: place_id,
              location: geometry.location // lat: geometry.location.lat(),
              // lng: geometry.location.lng()

            });
          } else {
            reject(status);
          }
        });
      });
    }

  });

  _exports.default = _default;
});