define("frontend/controllers/admin/index", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    impersonateEmail: '',
    session: inject.service(),
    accountSession: inject.service(),
    actions: {
      impersonate() {
        let store = this.get('session.store');
        let user_email = this.get('impersonateEmail');

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/impersonate_session`, {
          user: {
            user_email
          }
        }).then(response => {
          store.clear().then(() => {
            store.persist(response).then(() => {
              this.get('accountSession').clearCompany();
            });
          });
        });
      }

    }
  });

  _exports.default = _default;
});