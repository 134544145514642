define("frontend/mixins/add-appointment", ["exports", "frontend/utils/string-util", "moment"], function (_exports, _stringUtil, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Mixin.create({
    addAppointmentData: inject.service(),
    repeatsWeekly: computed.equal('addAppointmentData.selectedRepeatOption', 'weekly'),
    repeatsBiweekly: computed.equal('addAppointmentData.selectedRepeatOption', 'biweekly'),
    repeatsMonthly: computed.equal('addAppointmentData.selectedRepeatOption', 'monthly'),
    repeatsNever: computed.equal('addAppointmentData.selectedRepeatOption', 'never'),
    repeats: computed.not('repeatsNever'),
    repeatsWeekDays: computed.equal('addAppointmentData.selectedRepeatOption', 'weekdays'),
    repeatsDaily: computed.equal('addAppointmentData.selectedRepeatOption', 'daily'),
    repeatsDailyOrWeekDays: computed.or('repeatsDaily', 'repeatsWeekDays'),

    sortDateAsc(firstDate, secondDate) {
      let momentFirstDate = (0, _moment.default)(firstDate);
      let momentSecondDate = (0, _moment.default)(secondDate);

      if (momentFirstDate.isAfter(momentSecondDate)) {
        return 1;
      } else if (momentFirstDate.isBefore(momentSecondDate)) {
        return -1;
      } else {
        return 0;
      }
    },

    repeatSummary: computed('addAppointmentData.endDate', 'startDate', 'repeats', 'repeatDaysText', 'selectedOneTimeDates.[]', 'addAppointmentData.selectedEndDateOption', function () {
      if (this.get('repeats')) {
        let startDate = (0, _moment.default)(this.get('startDate'), 'YYYY-MM-DD').format('dddd, MMM D, YYYY');
        let endDate = this.get('addAppointmentData.selectedEndDateOption') === 'never' ? 'never' : this.get('addAppointmentData.endDate');

        if (this.get('addAppointmentData.selectedEndDateOption') !== 'never') {
          endDate = `on ${(0, _moment.default)(endDate, 'YYYY-MM-DD').format('dddd, MMM D, YYYY')}`;
        }

        let repeatsDays = this.get('repeatDaysText');
        return `You are adding appointments that ${repeatsDays} beginning ${startDate}, and ending ${endDate}.`;
      } else {
        let selectedOneTimeDates = this.get('selectedOneTimeDates').sort(this.sortDateAsc).map(day => (0, _moment.default)(day).format('ddd, MMM D, YYYY')).join('<br>');
        return `You are adding appointments on the following dates: <br><br>${selectedOneTimeDates}`;
      }
    }),
    repeatDaysText: computed('repeatsDaily', 'repeatsWeekDays', 'repeatsWeekly', 'repeatsBiweekly', 'repeatsMonthly', 'selectedRepeatDaysText', function () {
      let selectedDays = this.get('selectedRepeatDaysText');

      if (this.get('repeatsDaily')) {
        return 'repeat daily Monday through Sunday';
      } else if (this.get('repeatsWeekDays')) {
        return 'repeat weekdays Monday through Friday';
      } else if (this.get('repeatsWeekly')) {
        return `repeat weekly on ${selectedDays}`;
      } else if (this.get('repeatsBiweekly')) {
        return `repeat every other week on ${selectedDays}`;
      } else if (this.get('repeatsMonthly')) {
        return 'repeat on the 1st of each month';
      }
    }),
    selectedRepeatDaysText: computed('selectedRepeatWeekDays.[]', function () {
      let sortedRepeatWeekDays = this.get('selectedRepeatWeekDays').sort(this.sortDateAsc).map(day => (0, _moment.default)(day).format('dddd'));
      return _stringUtil.default.joinWithAnd(sortedRepeatWeekDays);
    })
  });

  _exports.default = _default;
});