define("frontend/components/admin-payment-refund-modal", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    selectedPayment: null,
    selectedCompanyOption: null,
    refundAmount: null,

    didInsertElement() {
      if (this.get('userEmail')) {
        this.set('loading', true);

        _jquery.default.get(`${_environment.default.APP.host}/api/v2/users/payments_lookup`, {
          email: this.get('userEmail')
        }).then(response => {
          this.set('companyPayments', response.company_payments);
          let companyOptions = [];
          response.company_payments.forEach(companyPayment => {
            companyOptions.push({
              value: companyPayment.company_name,
              label: companyPayment.company_name
            });
          });
          this.set('companyOptions', companyOptions);
          this.set('loading', false);
        });
      }
    },

    payments: computed('companyPayments.[]', 'selectedCompanyOption', function () {
      let company = this.get('selectedCompanyOption');
      let companyPayments = this.get('companyPayments');

      if (!companyPayments || !company) {
        return [];
      }

      let payments = null;
      companyPayments.forEach(companyPayment => {
        if (companyPayment.company_name == company.value) {
          payments = companyPayment.payments;
        }
      });
      return payments;
    }),
    actions: {
      onClose() {
        if (this.get('selectedPayment')) {
          this.set('selectedPayment', null);
          return;
        }

        this.set('selectedCompanyOption', null);
        this.set('refundAmount', null);
        this.get('onClose')();
      },

      onSelectedAction(option) {
        this.set('selectedCompanyOption', option);
      },

      clickedPayment(payment) {
        this.set('selectedPayment', payment);
      },

      refundPayment() {
        let payment = this.get('selectedPayment');
        let amount = parseFloat(this.get('refundAmount'));
        let areYouSure = confirm(`Are you sure you want to refund $${amount}`);

        if (!areYouSure) {
          return;
        }

        this.set('loading', true);

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/invoice_payments/${payment.id}/refund_payment`, {
          amount_to_refund: amount
        }).then(() => {
          let message = `Refunded $${amount} for ${this.get('userEmail')}`;
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'success'
          });
          this.set('selectedPayment', null);
          this.set('selectedCompanyOption', null);
          this.set('refundAmount', null);
          this.set('loading', false);
          this.get('onClose')();
        }).fail(response => {
          let message = 'Unexpected error refunding payment. Please try again';

          if (response.responseJSON.error) {
            message = response.responseJSON.error;
          }

          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('loading', false);
        });
      }

    }
  });

  _exports.default = _default;
});