define("frontend/components/decline-appointment-request-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    appointmentRequest: null,
    offeredService: computed.alias('appointmentRequest.offered_service'),
    pickupWindow: computed.alias('appointmentRequest.pickupWindow'),
    possessiveName: computed('appointmentRequest.who_requested_first_name', function () {
      let name = this.get('appointmentRequest.who_requested_first_name');

      if (name.slice(-1) == 's') {
        return `${name}'`;
      }

      return `${name}'s`;
    }),
    formattedDate: computed('appointmentRequest.starts_at', function () {
      let date = moment.parseZone(this.get('appointmentRequest.starts_at')).startOf('day');
      let today = moment().startOf('day');

      if (date.diff(today, 'days') === 0) {
        return 'today';
      } else if (date.diff(today, 'days') === 1) {
        return 'tomorrow';
      } else {
        return `on ${date.format('ddd, MMM D')}`;
      }
    }),

    willInsertElement() {
      this._super(...arguments);

      this.set('decliningAppointment', false);
      this.set('appointmentRequest.declined_note', this.initialNote());
    },

    initialNote() {
      let request = this.get('appointmentRequest');
      return `Hi ${request.get('who_requested_first_name')}!\nI’m so sorry we won’t be able to fit ${request.get('petNames')} in ${this.get('formattedDate')}! Looking forward to seeing them again hopefully soon!\nTake care!\n${this.get('currentUser.user.getFirstName')}`;
    },

    actions: {
      declineAppointmentRequest() {
        this.set('decliningAppointment', true);
        this.get('declineAppointmentRequest')();
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});