define("frontend/components/payment-form/bank-account", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['pay-container', 'center-block'],
    currentUser: inject.service(),
    cookies: inject.service(),
    savedBankAccountInfo: false,
    plaidRedirect: false,
    oauthEnabled: false,

    didInsertElement() {
      let storedToken = this.get('cookies').read('plaidLinkToken');

      if (this.get('plaidRedirect') && storedToken) {
        this.get('cookies').clear('plaidLinkToken', {
          path: '/'
        });
        this.setupPlaidLink(storedToken, this);
      } else {
        let self = this;
        this.getPlaidLinkToken(this.get('currentUser.user.id')).then(response => {
          self.get('cookies').write('plaidLinkToken', response.token, {
            path: '/'
          });
          self.setupPlaidLink(response.token, self);
        });
      }
    },

    showLinkButtons: computed('nickname', 'savedBankAccountInfo', function () {
      if (this.get('nickname') || this.get('savedBankAccountInfo')) {
        return false;
      }

      return true;
    }),

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('#plaid-link-div')).off('click');
      (0, _jquery.default)(this.element.querySelectorAll('#new-plaid-link-div')).off('click');
    },

    getPlaidLinkToken(userId) {
      return _jquery.default.ajax({
        url: `/api/v2/users/${userId}/plaid_link_token`,
        method: 'GET',
        data: {
          oauth: this.get('oauthEnabled')
        }
      });
    },

    setupPlaidLink(token, self) {
      let plaidRedirect = self.get('plaidRedirect');
      let plaidParams = {
        env: _environment.default.plaid_env,
        token,
        selectAccount: true,

        onSuccess(public_token, metadata) {
          let account_nickname = `${metadata.institution.name.toUpperCase()} (${metadata.account.name})`;
          self.send('setBankAccount', public_token, metadata.account_id, account_nickname);
          linkHandler.destroy();
        },

        onExit(err, metadata) {
          if (err && err.error_code) {
            self.set('error', err);
          } else if (metadata.status === 'institution_not_found') {
            self.get('openManualBankAccount')();
          } else {
            if (self.get('onClose')) {
              self.get('onClose')();
            }
          }

          linkHandler.destroy();
        }

      };

      if (plaidRedirect) {
        plaidParams.receivedRedirectUri = window.location.href;
      }

      let linkHandler = Plaid.create(plaidParams);

      let plaidLinkHandler = () => {
        if (this.get('onPlaidOpen')) {
          this.get('onPlaidOpen')();
        }

        linkHandler.open();
      };

      (0, _jquery.default)(this.element.querySelectorAll('#plaid-link-div')).click(plaidLinkHandler);
      (0, _jquery.default)(this.element.querySelectorAll('#new-plaid-link-div')).click(plaidLinkHandler);

      if (plaidRedirect) {
        linkHandler.open();
      }
    },

    actions: {
      setBankAccount(token, account_id, nickname) {
        this.get('setBankAccount')(token, account_id, nickname);

        if (this.get('isDestroyed')) {
          return;
        }

        this.send('closeLinkButtons');
      },

      openLinkButtons() {
        this.set('showLinkButtons', true);
        this.set('manuallyOpenedLinkButtons', true);
      },

      closeLinkButtons() {
        this.set('showLinkButtons', false);
        this.set('manuallyOpenedLinkButtons', false);
      },

      openManualBankAccount() {
        this.openManualBankAccount();
      }

    }
  });

  _exports.default = _default;
});