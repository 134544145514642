define("frontend/routes/companies/getting-paid", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    get
  } = Ember;

  var _default = _baseRoute.default.extend({
    flashMessage: inject.service(),

    model() {
      let company = this._super(...arguments);

      let url = `${_environment.default.APP.host}/api/v2/companies/${company.get('id')}/payment_details`;
      let invoiceFrequencies = this.store.findAll('invoice-frequency');

      let payment_details = _jquery.default.ajax({
        url,
        method: 'GET'
      });

      let invoiceFrequencyCounts = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/companies/${company.get('id')}/invoice_frequency_counts`,
        method: 'GET'
      });

      return Ember.RSVP.hash({
        company,
        invoiceFrequencies,
        invoiceFrequencyCounts,
        payment_details
      });
    },

    afterModel(model) {
      this._super(...arguments);

      let {
        company
      } = model;
      company.set('tax_id', model.payment_details.tax_id);
      company.set('bank_account_number', model.payment_details.bank_account_number);
      company.set('bank_user_id', `${model.payment_details.bank_user_id}`);
      company.set('bank_routing_number', model.payment_details.bank_routing_number);
      company.set('bank_account_nickname', model.payment_details.bank_account_nickname);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('editingAutoBill', false);
      controller.set('saving', false);
      controller.set('editingBankAccount', false);
      controller.set('showConfirmSwitchUserInvoiceFrequencies', false);
      controller.set('didValidate', false);
      controller.set('changingTaxId', false);
      controller.get('bankUser');
      controller.set('originalDefaultInvoiceFrequency', model.company.get('default_invoice_frequency'));
      controller.set('originalInvoiceFrequencies', model.company.get('invoice_frequencies').map(f => f));

      if (model.company.get('charge_service_fee')) {
        controller.set('serviceFeeDisabled', true);
      }

      model.company.notifyPropertyChange('auto_pay');

      if (model.company.get('sales_tax_policies.length') > 0) {
        model.company.get('sales_tax_policies').forEach(policy => {
          if (policy.get('is_default')) {
            controller.set('originalDefaultSalesTaxPolicy', policy);
          }
        });
      }
    },

    actions: {
      save(company) {
        let companies = Ember.getOwner(this).lookup('controller:companies');
        companies.set('saving', true);
        company.save().then(() => window.location = '/').catch(error => {
          let message = get(error, 'errors.0.detail') || 'Unknown error encountered';
          this.get('flashMessage').sendFlashMessage(message, 'user-error');
          companies.set('saving', false);
        });
      },

      cancel() {
        let model = this.controller.get('model.company');
        let invoiceFrequencies = model.get('invoice_frequencies');
        model.rollbackAttributes();
        invoiceFrequencies.forEach(invoiceFrequency => {
          invoiceFrequency.rollbackAttributes();
        });
        this.transitionTo('index').then(() => window.location.reload());
      }

    }
  });

  _exports.default = _default;
});