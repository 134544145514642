define("frontend/controllers/companies/paying-staff", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    timeZone: inject.service(),
    payFrequencyOptions: [{
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'semi_monthly',
      label: '1st and 15th each month'
    }, {
      value: 'bi_weekly',
      label: 'Every other week'
    }, {
      value: 'monthly',
      label: 'Monthly'
    }],
    payFrequency: computed('model.pay_frequency', {
      set(_key, value) {
        if (value === 'bi_weekly' && this.get('model.pay_period') == null) {
          this.set('payPeriod', this.get('payPeriodOptions.firstObject.id'));
        } else {
          this.set('payPeriod', null);
        }

        this.set('model.pay_frequency', value);
        return this.get('model.pay_frequency');
      },

      get() {
        return this.get('model.pay_frequency');
      }

    }),
    selectedPayFrequency: computed('model.pay_frequency', 'payFrequencyOptions', function () {
      let options = this.get('payFrequencyOptions');
      let selected = options.filter(option => this.get('model.pay_frequency') == option.value);
      return selected.get('firstObject');
    }),
    payPeriodOptions: computed(function () {
      let options;
      let offset = this.get('timeZone').get('offset');
      let startDate = (0, _moment.default)();
      startDate = _moment.default.utc(startDate, 'MM-DD-YYYY').utcOffset(offset).startOf('week').add('day', 1);

      let endDate = _moment.default.utc(startDate, 'MM-DD-YYYY').utcOffset(offset).endOf('week').add('day', 1);

      endDate.add(1, 'week');
      options = [{
        label: `${startDate.format('M/D')} - ${endDate.format('M/D')}`,
        value: startDate.format()
      }];
      startDate.subtract(1, 'week');
      endDate.subtract(1, 'week');
      options.push({
        label: `${startDate.format('M/D')} - ${endDate.format('M/D')}`,
        value: startDate.format()
      });
      return options;
    }),
    payPeriod: computed('model.pay_period', 'model.pay_frequency', {
      set(key, value) {
        this.set('model.pay_period', value);
        return this.get('model.pay_period');
      },

      get() {
        if (this.get('model.pay_period')) {
          let offset = this.get('timeZone').get('offset');

          let startDate = _moment.default.utc((0, _moment.default)(this.get('model.pay_period')), 'MM-DD-YYYY').utcOffset(offset).startOf('week').add('day', 1);

          let found = false;

          while (!found) {
            this.get('payPeriodOptions').forEach(_ref => {
              let {
                value
              } = _ref;

              if (startDate.format() == value) {
                this.set('model.pay_period', value);
                found = true;
                return;
              }
            });
            startDate.add(2, 'week');
          }
        } else {
          this.set('model.pay_period', this.get('payPeriodOptions.firstObject.value'));
        }

        return this.get('model.pay_period');
      }

    }),
    selectedPayPeriod: computed('payPeriod', 'model.pay_period', 'payPeriodOptions', function () {
      this.get('payPeriod');
      let options = this.get('payPeriodOptions');
      let selected = options.filter(option => this.get('model.pay_period') == option.value);
      return selected.get('firstObject');
    }),
    staffApprovalHelpText: computed('model.staff_appointment_approval', function () {
      if (this.get('model.staff_appointment_approval')) {
        return "Your team will need to approve any appointments assigned to them. You’ll still be able to approve appointments on their behalf as well!";
      } else {
        return "By enabling this setting, your team will need to approve any appointments assigned to them. You’ll still be able to approve appointments on their behalf as well!";
      }
    }),
    actions: {
      save() {
        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        this.get('model').save().then(() => {
          return this.transitionToRoute('index');
        }).finally(() => {
          this.set('saving', false);
        });
      },

      toggleStaffApproval(value) {
        this.set('model.staff_appointment_approval', value);
      },

      changePayFrequency(option) {
        this.set('model.pay_frequency', option.value);
      },

      changePayPeriod(option) {
        this.set('model.pay_period', option.value);
      }

    }
  });

  _exports.default = _default;
});