define("frontend/helpers/state-or-province", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stateOrProvince = stateOrProvince;

  function stateOrProvince(_ref) {
    let [country] = _ref;

    if (!country) {
      return 'State';
    } // TODO: move 'canada' to common shared location


    if (country.toLowerCase() === 'canada') {
      return 'Province';
    } else {
      return 'State';
    }
  }

  var _default = Ember.Helper.helper(stateOrProvince);

  _exports.default = _default;
});