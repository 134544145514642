define("frontend/components/cancellation-fee-policy-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    serviceCategory: null,
    cancellationFeePolicy: null,
    percentageType: computed.equal('cancellationFeePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('cancellationFeePolicy.amount_type', 'fixed'),
    percentageCompensation: computed.equal('cancellationFeePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('cancellationFeePolicy.employee_compensation_type', 'fixed'),
    afterMethod: computed.equal('cancellationFeePolicy.method', 'after'),
    withinMethod: computed.equal('cancellationFeePolicy.method', 'within'),
    timeDropdownOptions: {
      interval: 30,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      minTime: '12:00AM',
      maxTime: '11:30PM',
      startTime: '12:00AM',
      timeFormat: 'h:mm p',
      momentFormat: 'h:mm A'
    },
    daysBeforeOptions: [{
      label: 'on the day of',
      value: '0'
    }, {
      label: 'the day before',
      value: '1'
    }, {
      label: '2 days before',
      value: '2'
    }, {
      label: '3 days before',
      value: '3'
    }, {
      label: '4 days before',
      value: '4'
    }, {
      label: '5 days before',
      value: '5'
    }, {
      label: '6 days before',
      value: '6'
    }, {
      label: '7 days before',
      value: '7'
    }, {
      label: '8 days before',
      value: '8'
    }, {
      label: '9 days before',
      value: '9'
    }, {
      label: '10 days before',
      value: '10'
    }, {
      label: '11 days before',
      value: '11'
    }, {
      label: '12 days before',
      value: '12'
    }, {
      label: '13 days before',
      value: '13'
    }, {
      label: '14 days before',
      value: '14'
    }, {
      label: '30 days before',
      value: '30'
    }],
    selectedDaysBefore: computed('cancellationFeePolicy.days_before', function () {
      let options = this.get('daysBeforeOptions');
      let selected = options.filter(option => this.get('cancellationFeePolicy.days_before') == option.value);
      return selected.get('firstObject');
    }),
    withinOptions: [{
      label: '30 minutes',
      value: '30'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '1 hour 30 minutes',
      value: '90'
    }, {
      label: '2 hours',
      value: '120'
    }, {
      label: '4 hours',
      value: '240'
    }, {
      label: '6 hours',
      value: '360'
    }, {
      label: '8 hours',
      value: '480'
    }, {
      label: '10 hours',
      value: '600'
    }, {
      label: '12 hours',
      value: '720'
    }, {
      label: '24 hours',
      value: '1440'
    }, {
      label: '48 hours',
      value: '2880'
    }, {
      label: '3 days',
      value: '4320'
    }, {
      label: '4 days',
      value: '5760'
    }, {
      label: '5 days',
      value: '7200'
    }, {
      label: '6 days',
      value: '8640'
    }, {
      label: '7 days',
      value: '10080'
    }, {
      label: '8 days',
      value: '11520'
    }, {
      label: '9 days',
      value: '12960'
    }, {
      label: '10 days',
      value: '14400'
    }, {
      label: '11 days',
      value: '15840'
    }, {
      label: '12 days',
      value: '17280'
    }, {
      label: '13 days',
      value: '18720'
    }, {
      label: '14 days',
      value: '20160'
    }, {
      label: '30 days',
      value: '43200'
    }],
    selectedWithinHours: computed('cancellationFeePolicy.within_minutes', function () {
      let options = this.get('withinOptions');
      let selected = options.filter(option => this.get('cancellationFeePolicy.within_minutes') == option.value);
      return selected.get('firstObject');
    }),

    didInsertElement() {
      this.set('loading', false);
    },

    amountError: computed('percentageType', 'cancellationFeePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('cancellationFeePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'cancellationFeePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('cancellationFeePolicy.employee_compensation_amount');

        if (amount === null || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    timeError: computed('afterMethod', 'cancellationFeePolicy.time', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let time = this.get('cancellationFeePolicy.time');

        if (!time && this.get('afterMethod')) {
          return '* required';
        }
      }

      return false;
    }),
    actions: {
      cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
      },

      changeDaysBefore(option) {
        this.set('cancellationFeePolicy.days_before', option.value);
      },

      changeWithinHours(option) {
        this.set('cancellationFeePolicy.within_minutes', option.value);
      },

      removeCancellationFeePolicy() {
        this.get('removeCancellationFeePolicy')(this.get('cancellationFeePolicy'));
      },

      save() {
        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('timeError') || this.get('compensationAmountError')) {
          return;
        }

        this.set('loading', true);
        this.get('save')();
      }

    }
  });

  _exports.default = _default;
});