define("frontend/routes/companies/pricing", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    media: Ember.inject.service(),

    model() {
      this._super(...arguments);

      let serviceCategories = this.store.query('service-category', {}, {
        reload: true
      });
      let offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      return Ember.RSVP.hash({
        serviceCategories,
        offeredServices
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('dragAndDropActivated', !this.get('media.isMobile'));
    },

    actions: {
      reload() {
        this.refresh().then(() => {
          this.get('controller').send('repositionOfferedServices');
        });
      }

    }
  });

  _exports.default = _default;
});