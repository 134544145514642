define("frontend/utils/invoice-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DRAFT = 'draft';
  const SENT = 'sent';
  const DUE = 'due';
  const PAID = 'paid';
  var _default = {
    DRAFT,
    SENT,
    DUE,
    PAID
  };
  _exports.default = _default;
});