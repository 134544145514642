define("frontend/controllers/inbox", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    onThread: computed.equal('currentRouteName', 'inbox.household'),
    onInbox: computed.equal('currentRouteName', 'inbox.index'),
    onDistributionListThread: computed.equal('currentRouteName', 'inbox.distribution-list'),
    queryParams: ['filter'],
    filter: '',
    selectedHousehold: null,
    noHouseholds: computed.equal('model.allHouseholds.length', 0),
    noHouseholdsWithMessages: computed.equal('model.householdsWithMessages.length', 0),
    showingNewMessagePage: false,
    selectedHouseholds: [],
    specificDatesSelected: false,
    selectedDistributionList: '',
    familiesWithAppointmentsText: '',
    familiesWithAppointmentsTitle: 'Families with appointments on...',
    messagesForDistributionThreadInfModel: null,
    showMoreOptions: false,
    sortedHouseholdsWithMessages: computed('model.householdsWithMessages.[]', 'model.householdsWithMessages.@each.last_message_time', function () {
      let sortedContent = this.get('model.householdsWithMessages.content').sort((householdA, householdB) => {
        let firstMessageTime = householdA.get('last_message_time');
        let secondMessageTime = householdB.get('last_message_time');

        if (firstMessageTime == secondMessageTime) {
          householdA.get('id') < householdB.get('id') ? 1 : -1;
        }

        firstMessageTime < secondMessageTime ? 1 : -1;
      });
      this.get('infinity').replace(this.get('model.householdsWithMessages'), sortedContent);
      return this.get('model.householdsWithMessages');
    }),
    showNoHouseholdsError: computed('filter', 'noHouseholds', 'noHouseholdsWithMessages', 'showingNewMessagePage', function () {
      return this.get('showingNewMessagePage') && this.get('noHouseholds') || !this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages');
    }),
    showNoError: computed('showingNewMessagePage', 'noHouseholdsWithMessages', function () {
      return !this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages') && !this.get('filter');
    }),
    sidePanelClass: computed('selectedHouseholds.[]', function () {
      if (this.get('selectedHouseholds.length') > 0) {
        return 'show-top-bar';
      }

      return '';
    }),
    showSidePanel: computed('onInbox', 'media.isMobile', 'selectedHouseholdsForNewMessage', 'showingNewMessagePage', 'noHouseholdsWithMessages', 'filter', function () {
      if (!this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages') && !this.get('media.isMobile') && !this.get('filter')) {
        return false;
      }

      return (this.get('onInbox') || !this.get('media.isMobile')) && !this.get('selectedHouseholdsForNewMessage');
    }),
    messagesForSingleHousehold: computed('showingNewMessagePage', 'selectedHouseholds.[]', function () {
      if (this.get('showingNewMessagePage') && this.get('selectedHouseholds.length') == 1) {
        let company_id = this.get('currentUser.user.company.id');
        return this.get('infinity').model('message', {
          household_id: this.get('selectedHouseholds.firstObject.id'),
          company_id,
          perPage: 20,
          startingPage: 1
        });
      }
    }),
    messagesForDistributionThread: computed('showingNewMessagePage', 'selectedDistributionList', 'selectedHouseholds.[]', function () {
      // This will recalculate anytime more households are fetched causing it
      // to flash a blank screen, so we're caching it instead
      if (this.get('messagesForDistributionThreadInfModel')) {
        return this.get('messagesForDistributionThreadInfModel');
      }

      if (this.get('showingNewMessagePage') && this.get('selectedDistributionList') !== '') {
        let company_id = this.get('currentUser.user.company.id');
        let infModel = this.get('infinity').model('distribution-message', {
          company_id,
          perPage: 20,
          startingPage: 1
        });
        this.set('messagesForDistributionThreadInfModel', infModel);
        return infModel;
      }
    }),
    actions: {
      selectHousehold(household) {
        this.transitionToRoute('inbox.household', household.get('id'));
      },

      selectDistributionList() {
        this.set('selectedHousehold', null);
        this.transitionToRoute('inbox.distribution-list');
      },

      clickedHouseholdForNewMessage(household) {
        let selectedHouseholds = this.get('selectedHouseholds');
        let found = false;
        selectedHouseholds.forEach(selectedHousehold => {
          if (selectedHousehold.get('id') == household.get('id')) {
            selectedHouseholds.removeObject(household);
            found = true;
          }
        });

        if (!found) {
          selectedHouseholds.pushObject(household);
        }
      },

      callbackAfterSentMessage() {
        let householdId = this.get('selectedHouseholds.firstObject.id');
        this.transitionToRoute('inbox.household', householdId).then(() => {
          this.set('showingNewMessagePage', false);
          this.set('selectedHouseholds', []);
          this.send('parentRefresh');
        });
      },

      clickedNewMessage() {
        this.toggleProperty('showingNewMessagePage');
        this.set('filter', '');
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('selectedHouseholds', []);

        if (!this.get('showingNewMessagePage')) {
          this.send('parentRefresh');
        }
      },

      transitionBackToInbox() {
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('showingNewMessagePage', false);
        this.set('selectedDistributionList', '');
        this.set('selectedHouseholds', []);
        this.send('parentRefresh');
        this.send('transitionToInbox');
      },

      transitionBackToDistributionListThread() {
        this.set('selectedHousehold', null);
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('showingNewMessagePage', false);
        this.set('selectedDistributionList', '');
        this.set('selectedHouseholds', []);
        this.send('parentRefresh');
        this.send('transitionToDistributionList');
      },

      mobileClickedDone() {
        this.toggleProperty('showingNewMessagePage');
        let households = this.get('selectedHouseholds');
        let length = households.get('length');

        if (length == 1) {
          let householdId = households.get('firstObject.id');
          this.transitionToRoute('inbox.household', householdId).then(() => {
            this.set('showingNewMessagePage', false);
            this.set('selectedHouseholds', []);
            this.send('parentRefresh');
          });
        } else if (length > 1) {
          this.set('selectedHouseholdsForNewMessage', true);
        }
      },

      transitionBackToNewMessage() {
        this.toggleProperty('showingNewMessagePage');
        this.set('selectedHouseholdsForNewMessage', false);
      },

      mobileTransitionToHousehold() {
        this.send('mobileToggleMoreOptions');
        this.send('goToHousehold', this.get('selectedHousehold'));
      },

      mobileArchiveThread() {
        this.send('mobileToggleMoreOptions');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/archive_thread`,
          method: 'POST',
          data: {
            household_id: this.get('selectedHousehold')
          }
        }).then(() => {
          this.send('transitionBackToInbox');
        });
      },

      mobileToggleMoreOptions() {
        this.toggleProperty('showMoreOptions');
      }

    }
  });

  _exports.default = _default;
});