define("frontend/controllers/companies/remove-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    companies: inject.controller(),
    store: inject.service(),
    router: inject.service(),
    saving: computed.alias('companies.saving'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    offeredService: computed('model.offered_service.id', function () {
      let id = this.get('model.offered_service.id');
      let promise = this.get('store').find('offered_service', id);
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    serviceName: computed('model.offered_service', function () {
      return `${this.get('model.offered_service.name')} (${this.get('model.offered_service_duration')})`;
    }),
    offeredServiceOptions: computed('model.offered_services', 'offeredService.is_add_on', function () {
      let services = this.get('model.offered_services');
      services = services.filter(service => service.offered_service.id != this.get('model.offered_service.id'));

      if (this.get('offeredService.is_add_on')) {
        services = services.filter(service => service.offered_service.is_add_on);
      } else {
        services = services.filter(service => !service.offered_service.is_add_on);
      }

      return $.map(services, function (service) {
        return {
          label: `${service.offered_service.name} (${service.offered_service.duration_display})`,
          value: service.offered_service.id,
          all_day_service: service.offered_service.all_day_service
        };
      });
    }),
    hasAppointments: computed('offeredService.is_add_on', 'model.grouped_appointments', 'model.recurring_appointments', 'model.appointment_requests', function () {
      if (this.get('offeredService.is_add_on')) {
        return this.get('addOnHasAppointments');
      } else {
        let appts = this.get('model.grouped_appointments') || [];
        let rec_appts = this.get('model.recurring_appointments') || [];
        let appt_requests = this.get('model.appointment_requests') || [];
        return appts.length > 0 || rec_appts.length > 0 || appt_requests.length > 0;
      }
    }),
    addOnHasAppointments: computed('model.recurring_appointment_add_ons', 'model.grouped_appointments', 'model.appointment_requests', function () {
      let appts = this.get('model.grouped_appointments') || [];
      let rec_appts = this.get('model.recurring_appointment_add_ons') || [];
      let appt_requests = this.get('model.appointment_requests') || [];
      return appts.length > 0 || rec_appts.length > 0 || appt_requests.length > 0;
    }),
    actions: {
      transitionToPricing() {
        this.transitionToRoute('companies.pricing');
      },

      transitionToSingleEdit(id) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id'),
          ids: [id]
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams
        });
      },

      transitionToRecurringEdit(id) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id')
        };
        this.transitionToRoute('edit-recurring-appointment-group.index', id, {
          queryParams
        });
      },

      transitionToRequestEdit(id) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id'),
          requestId: id
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams
        });
      },

      remove() {
        this.set('saving', true);
        this.get('offeredService').get('content').destroyRecord().then(() => {
          window.location.href = `/companies/${this.get('model.company_id')}/pricing`;
        });
      },

      cancel() {
        this.transitionToRoute('companies.pricing');
      }

    }
  });

  _exports.default = _default;
});