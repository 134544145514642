define("frontend/components/offered-service-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['offered-service-bar'],
    classNameBindings: ['color'],
    label: '',
    value: 0,
    total: 0,
    fixedValue: computed('value', function () {
      return parseFloat(this.get('value')) * 0.8;
    })
  });

  _exports.default = _default;
});