define("frontend/components/sidebar-nav", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    },
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ["sidebar-nav"],
    classNameBindings: ["collapsed"],
    tagName: "nav",
    currentUser: Ember.inject.service(),
    beacon: Ember.inject.service(),
    reactApp: Ember.inject.service(),
    collapsed: true,
    expanded: computed.not("collapsed"),
    timeZone: service(),
    accountSession: service(),
    inboxHasUnreadMessages: service(),
    hasUnreadMessages: computed.alias("inboxHasUnreadMessages.hasUnreadMessages"),
    reportsLink: computed.alias("reactApp.reportsUrl"),

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(".ember-application").addClass("no-body-padding");
      this.element.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
      this.element.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(".ember-application").removeClass("no-body-padding");
      this.element.removeEventListener("mouseenter", this.handleMouseEnter);
      this.element.removeEventListener("mouseleave", this.handleMouseLeave);
    },

    companyFirstLetter: computed("currentUser.user.company_name", function () {
      let companyName = this.get("currentUser.user.company_name");
      return companyName ? companyName[0] : "";
    }),
    showSwitchAccount: computed("currentUser.user.staff_companies", "currentUser.user.has_client_accounts", function () {
      let staffCompanies = this.get("currentUser.user.staff_companies");

      if (staffCompanies && staffCompanies.length > 1 || this.get('currentUser.user.has_client_accounts')) {
        return true;
      }

      return false;
    }),
    adminPage: computed("currentUser.user.isOwner", function () {
      if (this.get("currentUser.user.isOwner")) {
        return "admin";
      } else if (this.get("currentUser.user.isManager")) {
        return "admin.email-look-up";
      } else {
        return "admin.breeds";
      }
    }),

    handleMouseEnter(event) {
      // The activity log is a child of the sidebar_nav causing this to trigger
      // if the user mouses over the activity log
      if (event.pageX < 60) {
        this.set("collapsed", false);
      }
    },

    handleMouseLeave() {
      this.set("collapsed", true);
    },

    fullDate: computed("timeZone", function () {
      let tz = this.get("timeZone");
      return (0, _moment.default)().utcOffset(tz.get("offset")).format("dddd, MMMM D, YYYY");
    }),
    shortDate: computed("timeZone", function () {
      let tz = this.get("timeZone");
      return (0, _moment.default)().utcOffset(tz.get("offset")).format("MMM D");
    }),
    actions: {
      signOut() {
        this.get("accountSession").signOut();
      },

      openSupport() {
        this.get('beacon').openSupportBeacon();
      }

    }
  });

  _exports.default = _default;
});