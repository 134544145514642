define("frontend/components/date-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputActivated: false,
    tabindex: 0,
    value: null,
    hint: null,
    showHint: false,
    hover: false,
    classNames: ['date-callout'],
    borderTop: true,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).hover(() => {
        this.set('hover', true);
      }, () => {
        this.set('hover', false);
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.send('resetActivated');
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).off('hover');
    },

    actions: {
      resetActivated() {
        if (this.get('focused')) {
          return;
        }

        this.set('inputActivated', !!this.get('value') && this.get('value') !== '__/__/____');
      },

      focusIn() {
        this.set('focused', true);
      },

      focusOut() {
        this.set('focused', false);
        this.set('inputActivated', !!this.get('value') && !!this.get('value').match(/\d{2}\/\d{2}\/\d{4}/));
      }

    }
  });

  _exports.default = _default;
});