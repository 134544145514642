define("frontend/components/appointment-complete-button", ["exports", "jquery", "frontend/config/environment", "frontend/mixins/money-formater"], function (_exports, _jquery, _environment, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  /**
   * This component handles the details around the appointment complete button which includes showing the appropriate
   * spinner and handling the toggle-like state of the button where an appointment can also be uncompleted.
   *
   * Actions sent by this component:
   * =====================================================================================================================
   * - onMarkComplete
   *   Sent when the complete button has been selected signifying that a previously uncompleted appointment should be
   *   completed.
   *
   * - onMarkUncomplete
   *   Sent when the uncomplete button has been selected signifying that a previously completed appointment should be marked
   *   as uncompleted.
   *
   */
  var _default = Ember.Component.extend(_moneyFormater.default, {
    classNames: ['appointment-complete-button-container'],
    currentUser: inject.service(),
    media: inject.service(),
    loadingLateBookingFee: false,

    /**
     * Used to determine whether or not the appointment is in a loading state to show the spinner in the button
     *
     * @var boolean
     */
    updatingAppointment: computed.readOnly('appointment.updatingCompleteStatus'),
    canStartAppointment: computed('appointment.time_frame_end_time', 'appointment.offered_service.duration_in_minutes', 'appointment.started_at', 'appointment.service.care_provider.id', 'appointment.offered_service.service_category.content.show_check_in_check_out_appointment', 'appointment.offered_service.duration_in_minutes', 'currentUser.user.id', function () {
      let careProvider = this.get('appointment.service.care_provider.id');
      let user = this.get('currentUser.user.id');

      if (this.get('appointment.started_at') || careProvider !== user || !this.get('appointment.offered_service.service_category.content.show_check_in_check_out_appointment') || this.get('appointment.offered_service.duration_in_minutes') === 0) {
        return false;
      }

      let startsAt = moment(this.get('appointment.service.starts_at'));
      let duration = this.get('appointment.offered_service.duration_in_minutes');
      let endTime = this.get('appointment.time_frame_end_time');
      let apptStartsAt;

      if (endTime) {
        apptStartsAt = moment(`${startsAt.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD h:mmA');
      } else {
        apptStartsAt = startsAt;
      }

      return moment().isBefore(apptStartsAt.add(duration, 'minutes').add(2, 'hours'));
    }),
    canFinishAppointment: computed('appointment.started_at', 'appointment.finished_at', function () {
      return !!this.get('appointment.started_at') && !this.get('appointment.finished_at');
    }),
    appointmentCompletedThroughCheckInCheckOut: computed.and('appointment.started_at', 'appointment.finished_at', 'appointment.completed'),
    reportCardSent: computed('appointment.grouped_report_card', function () {
      let reportCard = this.get('appointment.grouped_report_card');

      if (reportCard) {
        return !reportCard.get('draft');
      }

      return false;
    }),
    cannotUncomplete: computed('currentUser.user.isImpersonated', 'appointmentCompletedThroughCheckInCheckOut', 'reportCardSent', function () {
      if (this.get('currentUser.user.isImpersonated')) {
        return false;
      }

      return this.get('appointmentCompletedThroughCheckInCheckOut') || this.get('reportCardSent');
    }),
    confirmationModalText: computed('appointment', 'lateBookingFee', function () {
      if (this.get('lateBookingFee') > 0) {
        return `When you uncomplete this visit, there’s a ${this.formatMoney(this.get('lateBookingFee'))} late booking fee associated with this appointment that will be removed from the ${this.get('appointment.appointment_detail.family_last_name')} family’s draft invoice.`;
      } else {
        return 'Are you sure you’d like to uncomplete this appointment?';
      }
    }),
    actions: {
      onMarkComplete() {
        this.onMarkComplete();
      },

      onMarkUncomplete() {
        this.set('showConfirmModal', true);
        this.set('loadingLateBookingFee', true);
        this.set('lateBookingFee', null);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/${this.get('appointment.id')}/check_late_booking_fee`,
          method: 'GET'
        }).then(response => {
          this.set('loadingLateBookingFee', false);

          if (response.amount > 0) {
            this.set('lateBookingFee', response.amount);
          }
        });
      },

      confirmMarkUncomplete() {
        this.set('showConfirmModal', false);
        this.onMarkUncomplete();
      },

      onStart() {
        this.onStart();
      },

      onUndoStart() {
        this.onUndoStart();
      }

    }
  });

  _exports.default = _default;
});