define("frontend/services/add-client-saving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Service.extend({
    errorHandler: inject.service(),
    saving: false,

    savePet(pet) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        pet.save().then(savedPet => {
          resolve(savedPet);
        }).catch(error => {
          this.get('errorHandler').handle(error);
          reject(error);
        });
      });
    },

    saveUser(user) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        user.save().then(savedUser => {
          savedUser.get('phone_numbers').filterBy('id', null).invoke('deleteRecord');
          savedUser.get('emails').filterBy('id', null).invoke('deleteRecord');
          savedUser.get('addresses').filterBy('id', null).invoke('deleteRecord');
          resolve(savedUser);
        }).catch(error => {
          this.get('errorHandler').handle(error);
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});