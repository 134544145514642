define("frontend/components/header-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['header-progress-bar'],
    value: 0,
    maxValue: 0,
    percent: computed('value', 'maxValue', function () {
      return this.get('value') / this.get('maxValue') * 100;
    })
  });

  _exports.default = _default;
});