define("frontend/components/dropdown-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-callout'],
    inputActivated: false,
    tabindex: 0,
    value: null,
    hint: null,
    hover: false,
    showHint: false,
    neverDisableInput: false,
    clickedCallout: false,
    disabled: false,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).hover(() => {
        this.set('hover', true);
      }, () => {
        this.set('hover', false);
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).off('hover');
    },

    willRender() {
      this.send('resetActivated');
    },

    actions: {
      selectOption(value) {
        this.set('hover', false);

        if (this.selectOption) {
          this.selectOption(value);
        }
      },

      open() {
        if (this.get('open')) {
          this.get('open')(...arguments);
        }
      },

      resetActivated() {
        let hasSelectedValue = this.get('value.length') > 0 || typeof this.get('value') === 'boolean' || typeof this.get('value') === 'number' || this.get('clickedCallout') || this.get('neverDisableInput');
        this.set('inputActivated', hasSelectedValue);
      },

      focusIn() {
        // This is a hack for opening and closing dropdown callouts
        Ember.run.scheduleOnce('afterRender', () => {
          Ember.run.later(() => {
            (0, _jquery.default)(this.element.querySelectorAll('.popout-dropdown .ember-power-select-trigger')).get(0).dispatchEvent(new MouseEvent('click'));
          }, 50);
        });
        this.set('clickedCallout', true);
        this.send('resetActivated');
      },

      focusOut() {
        this.set('clickedCallout', false);
        this.send('resetActivated');
      }

    }
  });

  _exports.default = _default;
});