define("frontend/components/sign-up-inputs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    countryInfo: inject.service(),
    states: computed.readOnly('countryInfo.states'),
    needMostOptions: ['Managing scheduling', 'Keeping my client info organized', 'Simplifying billing and payments', 'Everything. Help!', "I'm not sure..."],
    request: {},
    actions: {
      onSubmit() {
        this.get('onSubmit')(this.get('request'));
      },

      highlightFirstStateMatch(api) {
        if (api && api.results && api.results.length) {
          return api.results[0];
        }

        return null;
      }

    }
  });

  _exports.default = _default;
});