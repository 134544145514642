define("frontend/routes/client-list/household/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    afterModel(model) {
      this.transitionTo('client-list.household.household-user', model.get('id'), model.get('owner.id'));
    }

  });

  _exports.default = _default;
});