define("frontend/components/added-auto-bill-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,
    readableFrequency: {
      'daily': 'daily',
      'weekly': 'weekly',
      'biweekly': 'every other week',
      'semimonthly': '1st and 15th',
      'monthly': 'monthly'
    },
    longFormReadableFrequency: {
      'daily': 'each day',
      'weekly': 'each week',
      'biweekly': 'every other week',
      'semimonthly': 'on the 1st and 15th of each month',
      'monthly': 'each month'
    },
    defaultInvoiceFrequency: Ember.computed('company.default_invoice_frequency', function () {
      return this.get('readableFrequency')[this.get('company.default_invoice_frequency.name')];
    }),
    defaultLongFormFrequency: Ember.computed('company.default_invoice_frequency', function () {
      return this.get('longFormReadableFrequency')[this.get('company.default_invoice_frequency.name')];
    }),
    actions: {
      close() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});