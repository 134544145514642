define("frontend/mixins/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Mixin.create({
    // On address it's 'street' on other models it's street_address
    adaptedStreet: computed('street', 'street_address', function () {
      return this.get('street') || this.get('street_address');
    }),
    hasAddress: computed.and('city', 'state', 'zipcode', 'adaptedStreet'),
    fullAddress: computed('adaptedStreet', 'city', 'state', 'zipcode', function () {
      if (!this.get('adaptedStreet') || !this.get('city') || !this.get('state') || !this.get('zipcode')) {
        return null;
      }

      return `${this.get('adaptedStreet')}, ${this.get('city')}, ${this.get('state')} ${this.get('zipcode')}`;
    }),
    shortAddress: computed('adaptedStreet', 'city', function () {
      if (this.get('adaptedStreet') && this.get('city')) {
        return `${this.get('adaptedStreet')} (${this.get('city')})`;
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});