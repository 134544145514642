define("frontend/services/stripe-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Service.extend({
    ajax: inject.service(),

    createPaymentMethod(userId, stripe_token) {
      let payment_method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'bank_account';
      let url = `/api/v2/users/${userId}/create_stripe_payment_method`;
      return this.get('ajax').request(url, {
        url,
        method: 'POST',
        data: {
          stripe_token,
          payment_method,
          id: userId
        }
      });
    },

    fetchCustomerInfo(userId, companyId) {
      let url = `/api/v2/users/${userId}/get_stripe_info?company_id=${companyId}`;
      return this.get('ajax').request(url);
    },

    fetchCompanyCustomerInfo() {}

  });

  _exports.default = _default;
});