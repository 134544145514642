define("frontend/helpers/format-rolling-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatRollingDate = formatRollingDate;

  function formatRollingDate(_ref) {
    let [date, format, useExpandedFormat = false, timeZone = null] = _ref;
    let today = (0, _moment.default)().startOf('day');

    if (timeZone) {
      today = (0, _moment.default)().tz(timeZone).startOf('day');
    }

    date = timeZone ? _moment.default.tz(date, 'YYYY-MM-DD', timeZone).startOf('day') : (0, _moment.default)(date, 'YYYY-MM-DD').startOf('day');
    let formatted = useExpandedFormat ? date.format('MMMM D') : date.format('MMM D');

    if (date.diff(today, 'days') === 0) {
      return `<span class="blue">today, ${formatted}</span>`;
    } else if (date.diff(today, 'days') === 1) {
      return `<span class="blue">tomorrow, ${formatted}</span>`;
    } else if (date.diff(today, 'days') === -1) {
      return `yesterday, ${formatted}`;
    }

    if (date.diff(today.endOf('month'), 'years') <= -1) {
      return `${date.format(format)}, ${date.format('YYYY')}`;
    } else {
      return date.format(format);
    }
  }

  var _default = Ember.Helper.helper(formatRollingDate);

  _exports.default = _default;
});