define("frontend/controllers/client-list/household/documents", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    removingDocument: null,
    currentUser: inject.service(),
    hasContracts: computed.gt('model.contracts.length', 0),
    hasMultipeContracts: computed.gt('model.contracts.length', 1),
    signedContracts: computed.equal('model.household.esignature_status', 'signed'),
    contractsNotSent: computed.equal('model.household.esignature_status', 'none'),
    showContractsAlert: computed('hasContracts', 'signedContracts', function () {
      return this.get('hasContracts') && !this.get('signedContracts');
    }),
    clickedContractButton: false,
    careProviderAddedNewClient: false,
    emptyDocuments: computed('model.documents', function () {
      let documents = this.get('model.documents');
      return documents.length == 0;
    }),
    shouldSeeHouseholdContractAlert: computed('model.household.id', 'currentUser.user.households_contract_alerts_dismissed.[]', function () {
      return this.get('showContractsAlert') && this.get('currentUser.user').shouldSeeHouseholdContractAlert(parseInt(this.get('model.household.id')));
    }),
    clientContractsAvailableToSend: computed('currentUser.user.company.client_contracts.[]', 'model.signatureRequests.[]', function () {
      let signatureRequests = this.get('model.signatureRequests');
      let clientContracts = this.get('currentUser.user.company.client_contracts').map(contract => {
        return Ember.Object.create({
          id: contract.get('id'),
          sent: signatureRequests.isAny('client_contract_id', parseInt(contract.get('id')))
        });
      });
      let numChoices = clientContracts.filterBy('sent', false).length;
      return numChoices > 0;
    }),
    actions: {
      toggleSendDocumentsModal() {
        if (this.get('careProviderAddedNewClient')) {
          this.send('togglePropAndDirectToBillingPage');
        } else {
          this.toggleProperty('showSendDocumentsModal');
        }
      },

      toggleCareProviderAddedNewClient() {
        this.toggleProperty('careProviderAddedNewClient');
      },

      togglePropAndDirectToBillingPage() {
        this.toggleProperty('careProviderAddedNewClient');
        let household_id = this.get('model.household.id');
        window.location.href = `/client-list/${household_id}/billing`;
      },

      denyRemoveDocument() {
        this.set('removingDocument', null);
      },

      confirmRemoveDocument() {
        let household = this.get('model.household');
        let doc = this.get('removingDocument');

        _jquery.default.ajax({
          url: `/api/v2/households/${household.get('id')}/user_documents/${doc.id}`,
          method: 'DELETE'
        }).then(() => {
          this.send('reload');
        }).done(() => {
          this.set('removingDocument', null);
        });
      },

      removeDocument(doc) {
        this.set('removingDocument', doc);
      },

      activateUploadFilePicker() {
        $('input').click();
      },

      uploadDocument(doc, fileName) {
        let household = this.get('model.household');
        this.set('uploading', true);

        _jquery.default.ajax({
          url: `/api/v2/households/${household.get('id')}/user_documents`,
          method: 'POST',
          data: {
            user_document: {
              document: doc,
              filename: fileName
            }
          }
        }).then(() => {
          this.send('reload');
        }).done(() => {
          this.set('uploading', false);
        });
      },

      sendContracts() {
        let householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/send_client_contracts`,
          method: 'POST',
          data: {
            household_id: householdId,
            send_email: true
          }
        }).then(() => {
          this.set('justSentContracts', true);
          this.set('model.household.esignature_status', 'sent');
          this.set('clickedContractButton', true);
          this.set('sendingContracts', false);
        });
      },

      sendClientContracts(clientContractIds) {
        let householdId = this.get('model.household.id');

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/send_specific_contracts_to_household`,
          method: 'POST',
          data: {
            contract_ids: clientContractIds,
            household_id: householdId
          }
        }).then(() => {
          if (this.get('careProviderAddedNewClient')) {
            this.send('togglePropAndDirectToBillingPage');
          } else {
            window.location.reload();
          }
        });
      },

      sendContractReminder() {
        let householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/send_client_contract_reminder`,
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(() => {
          this.set('justSentContracts', false);
          this.set('clickedContractButton', true);
          this.set('sendingContracts', false);
        });
      },

      sendReminder() {
        let householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/send_reminder`,
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(() => {
          this.set('justSentContracts', false);
          this.set('clickedContractButton', true);
          this.set('sendingContracts', false);
        });
      },

      denyCancelRequest() {
        this.set('selectedSignatureRequest', null);
      },

      cancelRequest(signatureRequest) {
        let archive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('selectedSignatureRequest', signatureRequest);
        this.set('isArchiving', archive);
      },

      confirmCancelRequest() {
        let signatureRequest = this.get('selectedSignatureRequest');

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/cancel_request`,
          method: 'POST',
          data: {
            signature_request_id: signatureRequest.id
          }
        }).then(() => {
          window.location.reload();
        });
      },

      emailPDF(signatureRequestId) {
        _jquery.default.ajax({
          url: `/api/v2/client_contracts/email_pdf`,
          method: 'POST',
          data: {
            signature_request_id: signatureRequestId
          }
        }).then(() => {
          window.location.reload();
        });
      },

      dismissAgreements() {
        let householdId = parseInt(this.get('model.household.id'));
        this.get('currentUser.user').addHouseholdIdToDismissedContracts(householdId);

        _jquery.default.ajax({
          url: '/api/v2/user_dismissed_contracts_alert',
          method: 'POST',
          data: {
            household_id: householdId
          }
        });
      }

    }
  });

  _exports.default = _default;
});