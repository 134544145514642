define("frontend/components/report-card-image-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    ajax: inject.service(),
    media: inject.service(),
    classNames: ['report-card--image-save'],
    classNameBindings: ['isDesktop:desktop', 'isTablet:tablet'],
    iconUrl: 'assets/images/report-card/save-icon.svg',
    isDesktop: computed.readOnly('media.isDesktop'),
    isTablet: computed.readOnly('media.isTablet'),
    image: null,
    reportCard: null,
    actions: {
      hoverIcon(hovering) {
        if (hovering) {
          this.set('iconUrl', 'assets/images/report-card/save-icon-hover.svg');
        } else {
          this.set('iconUrl', 'assets/images/report-card/save-icon.svg');
        }
      },

      saveImage() {
        this.get('reportCard').downloadImage(this.get('image'));
      }

    }
  });

  _exports.default = _default;
});