define("frontend/routes/edit-client/remove-pet", ["exports", "frontend/routes/base-route", "frontend/config/environment"], function (_exports, _baseRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: inject.service(),

    model(params) {
      return this.store.findRecord('pet', params.pet_id);
    },

    setupController(controller) {
      this._super(...arguments);

      if (this.get('currentUser.user.isPetParent')) {
        jQuery.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_check/has_future_appointments?pet_id=${controller.model.id}`,
          method: 'GET'
        }).done(response => {
          const {
            hasFutureAppointments
          } = response;

          if (hasFutureAppointments) {
            controller.set('futureAppointments', true);
          } else {
            controller.set('futureAppointments', false);
          }
        }).fail(() => {
          this.get('flashMessage').sendFlashMessage("We were unable to load some information. Please refresh the page.", 'error');
        });
      } else {
        jQuery.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_check/future_appointments_with_current_care_provider?pet_id=${controller.model.id}`,
          method: 'GET'
        }).done(response => {
          const {
            futureAppointmentsWithCurrentCareProvider
          } = response;

          if (futureAppointmentsWithCurrentCareProvider) {
            controller.set('futureAppointments', true);
          } else {
            controller.set('futureAppointments', false);
          }
        }).fail(() => {
          this.get('flashMessage').sendFlashMessage("We were unable to load some information. Please refresh the page.", 'error');
        });
      }
    },

    transitionToPet() {
      let pet = this.get('controller.model');
      this.transitionTo('edit-client.pet', pet);
    },

    transitionToList(params) {
      return this.transitionTo('client-list.household', params['household.id']);
    },

    transitionToPortal() {
      window.location.href = '/portal/profile';
    },

    actions: {
      remove() {
        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        let pet = this.get('controller.model');
        let params = pet.getProperties('household.id');
        pet.destroyRecord().then(() => {
          if (this.get('currentUser').user.isPetParent) {
            return this.transitionToPortal().then(() => {
              this.set('saving', false);
            });
          } else {
            return this.transitionToList(params).then(() => {
              this.set('saving', false);
            });
          }
        });
      },

      cancel() {
        this.transitionToPet();
      }

    }
  });

  _exports.default = _default;
});