define("frontend/models/invoice-adjustment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DISCOUNT = _exports.ADDITIONAL_CHARGE = void 0;
  const {
    computed
  } = Ember;
  const DISCOUNT = 'discount';
  _exports.DISCOUNT = DISCOUNT;
  const ADDITIONAL_CHARGE = 'additional_charge';
  _exports.ADDITIONAL_CHARGE = ADDITIONAL_CHARGE;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    invoice_detail: _emberData.default.belongsTo('invoice-detail'),
    household: _emberData.default.belongsTo('household'),
    isDiscount: computed.lt('amount', 0),
    isCharge: computed.not('isDiscount'),
    is_sales_tax: _emberData.default.attr('boolean'),
    is_cancellation_fee: _emberData.default.attr('boolean'),
    surcharge_policy_type: _emberData.default.attr('string'),
    appointment_date: _emberData.default.attr('string'),
    appointment_sortable_date: _emberData.default.attr('string'),
    appointment_category_name: _emberData.default.attr('string'),
    offered_service_name: _emberData.default.attr('string'),
    surcharge_policy_description: _emberData.default.attr('string'),
    markedForDeletion: false,
    apply_sales_tax: _emberData.default.attr('boolean'),
    sales_tax_rate: _emberData.default.attr('number'),
    isWeekendSurcharge: computed.equal('surcharge_policy_type', 'WeekendSurchargePolicy'),
    isLateBookingSurcharge: computed.equal('surcharge_policy_type', 'LateBookingSurchargePolicy'),
    isHolidaySurcharge: computed.equal('surcharge_policy_type', 'HolidaySurchargePolicy'),
    isOffHoursSurcharge: computed.equal('surcharge_policy_type', 'OffHoursSurchargePolicy')
  });

  _exports.default = _default;
});