define("frontend/components/archive-appointment-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    willInsertElement() {
      this._super(...arguments);

      this.set('cancelingAppointment', false);
    },

    appointments: computed('groupedAppointment.sortedAppointments.[]', 'selectedAppointment', function () {
      let selectedAppt = this.get('selectedAppointment');

      if (selectedAppt) {
        return [{
          id: selectedAppt.get('id'),
          avatarOrPlaceholder: selectedAppt.get('pet').get('avatarOrPlaceholder'),
          pet_name: selectedAppt.get('pet').get('name'),
          selected: true
        }];
      }

      let appointments = this.get('groupedAppointment.sortedAppointments').map(appt => {
        return {
          id: appt.get('id'),
          avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
          pet_name: appt.get('pet').get('name'),
          selected: true
        };
      });
      return appointments;
    }),
    household: computed('groupedAppointment.appointments.[]', 'selectedAppointment', function () {
      if (this.get('selectedAppointment')) {
        return this.get('selectedAppointment.pet.household');
      } else {
        return this.get('groupedAppointment.appointments.firstObject.pet.household');
      }
    }),
    pickupWindow: computed('groupedAppointment.pickup_window', 'selectedAppointment.pickup_window', function () {
      if (this.get('selectedAppointment')) {
        return this.get('selectedAppointment.pickup_window');
      } else {
        return this.get('groupedAppointment.pickup_window');
      }
    }),
    offeredService: computed('groupedAppointment.offered_service', 'selectedAppointment.offered_service', function () {
      if (this.get('selectedAppointment')) {
        return this.get('selectedAppointment.offered_service');
      } else {
        return this.get('groupedAppointment.offered_service');
      }
    }),
    service: computed('groupedAppointment.service', 'selectedAppointment.service', function () {
      if (this.get('selectedAppointment')) {
        return this.get('selectedAppointment.service');
      } else {
        return this.get('groupedAppointment.service');
      }
    }),
    month: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('ddd');
    }),
    showSelectionError: computed('appointments.@each.selected', function () {
      let selected = this.get('appointments').filter(appt => appt.selected);
      return selected.length == 0;
    }),
    actions: {
      archiveAppointment() {
        if (this.get('showSelectionError')) {
          return;
        }

        let selectedAppts = this.get('appointments').filter(appt => appt.selected);
        let apptIds = selectedAppts.map(appt => appt.id);
        this.set('archivingAppointment', true);
        let groupedAppointment = this.get('groupedAppointment');

        if (groupedAppointment) {
          this.get('archiveGroupedAppointment')(groupedAppointment, apptIds);
        } else {
          this.get('archiveAppointments')(apptIds);
        }
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});