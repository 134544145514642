define("frontend/components/add-email", ["exports", "frontend/mixins/add-side-panel", "frontend/utils/email-type"], function (_exports, _addSidePanel, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Component.extend(_addSidePanel.default, {
    classNames: ['add-email', 'add-with-side-panel'],
    householdUser: null,
    household: null,
    store: inject.service(),
    emailType: computed('householdUser.emails.@each.email_type', function () {
      let types = this.get('householdUser.emails').mapBy('email_type');

      if (types.indexOf(_emailType.default.PREFERRED) < 0) {
        return _emailType.default.PREFERRED;
      } else {
        return _emailType.default.ALTERNATE;
      }
    }),
    actions: {
      editEmail(email) {
        this.showEditSidePanel(email.getProperties('email_type', 'address'), email);
      },

      addEmail() {
        let emailObject = Ember.Object.create({
          email_type: this.get('emailType'),
          address: ''
        });
        this.showAddSidePanel(emailObject);
      },

      createEmail(emailObject) {
        let {
          email_type,
          address
        } = emailObject;
        let existingEmails = this.get('householdUser.emails');

        if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length > 0 && email_type === _emailType.default.PREFERRED) {
          existingEmails.forEach(email => email.set('email_type', _emailType.default.ALTERNATE));
        }

        let email = this.get('store').createRecord('email', {
          email_type,
          address
        });
        this.get('householdUser.emails').pushObject(email);
        this.send('deactivateSidePanel');
      },

      updateEmail(sidePanelObject) {
        let model = this.get('model');
        let existingEmails = this.get('householdUser.emails');

        if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length > 0 && sidePanelObject.email_type === _emailType.default.PREFERRED) {
          existingEmails.forEach(email => email.set('email_type', _emailType.default.ALTERNATE));
        } else if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length <= 1 && sidePanelObject.email_type === _emailType.default.ALTERNATE) {
          let existingEmail = this.get('householdUser.emails').filter(email => email.get('address') !== sidePanelObject.address).get('firstObject');
          existingEmail.setProperties({
            email_type: _emailType.default.PREFERRED
          });
        }

        model.setProperties(sidePanelObject);
        this.send('deactivateSidePanel');
      },

      // eslint-disable-next-line no-unused-vars
      removeEmail(sidePanelObject) {
        let model = this.get('model');

        if (model.get('email_type') === _emailType.default.PREFERRED) {
          model.deleteRecord();
          let existingEmail = this.get('householdUser.emails.firstObject');
          existingEmail.setProperties({
            email_type: _emailType.default.PREFERRED
          });
        } else {
          model.deleteRecord();
        }

        this.send('deactivateSidePanel');
      }

    }
  });

  _exports.default = _default;
});