define("frontend/components/recurring-service-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['service-box'],
    attributeBindings: ['service-id', 'style'],
    classNameBindings: ['selected'],
    service: null,
    showTime: false,
    selected: true,
    style: computed('showTime', function () {
      let pixelHeight = 105;
      return Ember.String.htmlSafe(`height: ${pixelHeight}px;`);
    })
  });

  _exports.default = _default;
});