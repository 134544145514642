define("frontend/components/masked-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    mask: null,
    hiddenInput: false,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('input')).mask(this.get('mask'), {
        selectOnFocus: true
      });

      if (this.get('hiddenInput')) {
        (0, _jquery.default)(this.element.querySelectorAll('input')).attr('type', 'password');
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('input')).off('focusout');
      (0, _jquery.default)(this.element.querySelectorAll('input')).unmask();
    },

    actions: {
      focusIn() {
        if (this.get('hiddenInput')) {
          (0, _jquery.default)(this.element.querySelectorAll('input')).attr('type', '');
        }

        Ember.run.later(() => {
          let replacedValue = this.get('value').replace(/_|-/g, '');

          if (replacedValue.trim().length === 0) {
            (0, _jquery.default)(this.element.querySelectorAll('input')).get(0).setSelectionRange(0, 0);
          }
        }, 10);
        this.focusIn();
      },

      focusOut() {
        if (this.get('hiddenInput')) {
          (0, _jquery.default)(this.element.querySelectorAll('input')).attr('type', 'password');
        }

        this.focusOut();
      }

    }
  });

  _exports.default = _default;
});