define("frontend/components/add-on-service-pick-pets-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    appointments: [],
    pets: [],
    appointmentsInfo: computed('appointments.[]', 'pets.[]', function () {
      if (this.get('appointments.length') > 0) {
        return this.get('appointments').map(appt => {
          return {
            id: appt.get('id'),
            avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
            pet_name: appt.get('pet').get('name'),
            appointment: appt,
            selected: true
          };
        });
      } else {
        return this.get('pets').map(pet => {
          return {
            id: pet.get('id'),
            avatarOrPlaceholder: pet.get('avatarOrPlaceholder'),
            pet_name: pet.get('name'),
            pet,
            selected: true
          };
        });
      }
    }),
    actions: {
      createAddOnServices() {
        let selectedAppts = this.get('appointmentsInfo').filter(appt => appt.selected);

        if (this.get('appointments.length') > 0) {
          let appointments = selectedAppts.map(appt => appt.appointment);
          this.get('createAddOnServices')(appointments);
        } else {
          let pets = selectedAppts.map(appt => appt.pet);
          this.get('createAddOnServices')(pets);
        }
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});