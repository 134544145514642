define("frontend/controllers/invoices-summary/invoice-detail", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    deletedAdjustments: [],
    queryParams: ['editing'],
    addingAdjustment: false,
    errorHandler: inject.service(),
    editing: false,
    flashMessage: inject.service(),
    alreadyResent: false,
    isVoided: computed.alias('model.isVoided'),
    showMenu: false,
    sendingBillNow: false,

    updateAppointmentsComplete(appointmentModels) {
      if (appointmentModels.get('length') === 0) {
        return [];
      }

      let appointments = appointmentModels.map(a => {
        return {
          id: a.get('id'),
          completed: a.get('completed')
        };
      });
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/complete?price_synchronously=true`,
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            appointments
          })
        }).then(response => {
          this.store.pushPayload(response);
          response.appointments.forEach(appt => {
            if (appt.error) {
              appointmentModels.filterBy('id', `${appt.id}`).get('firstObject').toggleProperty('completed');
              this.get('flashMessage').error(appt.error);
            }
          });
          resolve(response);
        }).fail(error => {
          reject(error);
        });
      });
    },

    billNowModalText: computed('model.invoice_detail.company_invoice_number', function () {
      return `This will email invoice #${this.get('model.company_invoice_number')} to the ${this.get('model.household.owner.last_name')} family. Once sent, you won’t be able to make changes to their appointments in the period covered by this bill (${moment(this.get('model.start_date')).format('M/D')} - ${moment().format('M/D')}).`;
    }),
    salesTaxRate: computed('model.invoice_detail.sales_tax_rate', function () {
      return this.get('model.invoice_detail.sales_tax_rate');
    }),
    cantBillNow: computed('model.invoice_detail.appointments.@each.completed', 'model.invoice_detail.invoice_adjustments.[]', function () {
      return this.get('model.invoice_detail.appointments').isEvery('completed', false) && this.get('model.invoice_detail.invoice_adjustments.length') === 0;
    }),
    actions: {
      print() {
        if (this.get('showMenu')) {
          this.set('showMenu', false);
        }

        let w = window.open();

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}`,
          method: 'GET',
          dataType: 'html'
        }).done(response => {
          w.document.open();
          w.document.write(response);
          w.document.close();
          $(w).load(() => {
            w.print();
          });
        }).fail(function () {// TODO: Show error dialog?
        });
      },

      resend() {
        this.set('alreadyResent', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}/resend_invoice`,
          method: 'POST'
        }).then(() => {
          this.get('model').reload();
          this.get('flashMessage').success(`Invoice #${this.get('model.company_invoice_number')} is on its way to the ${this.get('model.household.owner.last_name')} family`);
        });
      },

      showVoidConfirm() {
        this.set('showVoidInvoice', true);
      },

      showUnvoidConfirm() {
        if (this.get('showMenu')) {
          this.set('showMenu', false);
        }

        this.set('showUnvoidInvoice', true);
      },

      hideVoidInvoice() {
        this.set('showVoidInvoice', false);
      },

      hideUnvoidConfirm() {
        this.set('showUnvoidInvoice', false);
      },

      void() {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}/void_invoice`,
          method: 'POST'
        }).then(() => {
          this.send('refresh');
          this.set('showVoidInvoice', false);
        });
      },

      unvoid() {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}/unvoid_invoice`,
          method: 'POST'
        }).then(() => {
          this.send('refresh');
          this.set('showUnvoidInvoice', false);
        });
      },

      edit() {
        this.set('editing', true);
      },

      cancel() {
        this.set('editing', false);

        if (this.get('editingAdjustment')) {
          this.send('closeAdjustment');
        }

        this.get('model.invoice_detail.invoice_adjustments').filterBy('markedForDeletion', true).forEach(a => a.set('markedForDeletion', false));
        this.get('model.invoice_detail.invoice_adjustments').forEach(a => a.rollbackAttributes());
        this.get('model.invoice_detail.appointments').forEach(a => a.rollbackAttributes());
        this.set('deletedAdjustments', []);
      },

      showBillNowModal() {
        this.set('showBillNowModal', true);
      },

      closeBillNowModal() {
        this.set('showBillNowModal', false);
      },

      billNow() {
        if (this.get('sendingBillNow')) {
          return;
        }

        this.set('sendingBillNow', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}/close_invoice`,
          method: 'POST'
        }).then(() => {
          window.location.reload();
        });
      },

      async save() {
        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);

        try {
          // remove extra sales tax adjustments added/changed in real time
          // the backend will edit this adjustment itself
          this.get('model.invoice_detail.invoice_adjustments').filterBy('is_sales_tax', true).map(a => a.rollbackAttributes()); // delete invoice adjustments
          // forEach and map swallow exceptions so have to use a regular
          // for loop here

          let adjustmentsToDelete = this.get('model.invoice_detail.invoice_adjustments').filterBy('markedForDeletion', true);

          for (let i = 0; i < adjustmentsToDelete.length; i++) {
            await adjustmentsToDelete[i].destroyRecord();
          } // Create/update new invoice adjustments


          let adjustmentsToSave = this.get('model.invoice_detail.invoice_adjustments').filterBy('hasDirtyAttributes', true).filterBy('markedForDeletion', false);

          for (let i = 0; i < adjustmentsToSave.length; i++) {
            await adjustmentsToSave[i].save();
          }

          let dirtyAppts = this.get('model.invoice_detail.appointments').filterBy('hasDirtyAttributes', true);
          await this.updateAppointmentsComplete(dirtyAppts);
          this.set('editingAdjustment', null);
          this.set('editing', false); // If viewing website in React Native WebView

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("closeWebView");
          } else {
            window.location.href = `${window.location.pathname}?editing=false`;
          }
        } catch (error) {
          this.get('errorHandler').handle(error.message);
        } finally {
          this.set('saving', false);
          this.set('deletedAdjustments', []);
        }
      },

      editAdjustment(adjustment) {
        if (this.get('editingAdjustment')) {
          this.get('editingAdjustment').rollbackAttributes();
        }

        this.set('editingAdjustment', adjustment);
        this.set('addingAdjustment', false);
      },

      addAdjustment() {
        let rec = this.store.createRecord('invoice-adjustment');
        this.set('editingAdjustment', rec);
        this.get('model.invoice_detail.invoice_adjustments').pushObject(rec);
        this.set('addingAdjustment', true);
      },

      closeAdjustment() {
        if (!this.get('editingAdjustment.isNew')) {
          this.get('editingAdjustment').rollbackAttributes();
        }

        this.set('editingAdjustment', null);
        this.set('addingAdjustment', false);
      },

      saveAdjustment() {
        this.set('editingAdjustment', null);
        this.set('addingAdjustment', false);
      },

      removeAdjustment() {
        this.set('editingAdjustment.markedForDeletion', true);
        this.set('editingAdjustment', null);
      },

      closeModifyAppointmentModal() {
        this.set('showModifyAppointmentModal', false);
        this.set('selectedGroupedAppointment', null);
      },

      modifyAppointment(appointmentIds) {
        let ids = this.get('selectedGroupedAppointment.appointments').map(appt => appt.get('id'));
        let queryParams = {
          backRoute: 'invoices-summary.invoice-detail',
          backHouseholdId: this.get('model.household.id'),
          backId: this.get('model.id'),
          ids: appointmentIds ? appointmentIds : ids
        };
        this.set('showModifyAppointmentModal', false);
        this.set('selectedGroupedAppointment', null);
        this.transitionToRoute('edit-single-appointment', {
          queryParams
        });
      },

      clickAppointment(groupedAppointment) {
        if (!this.get('editing')) {
          return;
        }

        if (groupedAppointment.appointments.length > 1) {
          this.set('selectedGroupedAppointment', groupedAppointment);
          this.set('showModifyAppointmentModal', true);
        } else {
          this.set('selectedGroupedAppointment', groupedAppointment);
          this.send('modifyAppointment');
        }
      }

    }
  });

  _exports.default = _default;
});