define("frontend/components/request-appointment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeClientAccounts: Ember.computed('clientAccounts.[]', function () {
      return this.get('clientAccounts').filter(account => !account.company_deactivated && !account.denied_request && account.pet_parents_activated || account.staff_status == 'Owner');
    }),
    actions: {
      chooseCompany(clientAccount) {
        this.get('chooseCompany')(clientAccount);
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});