define("frontend/controllers/change-password", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    password: null,
    confirmPassword: null,
    passwordError: false,
    confirmError: false,
    currentUser: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    resetPassword: computed.alias('currentUser.user.has_temporary_password'),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    avatarLogo: computed('currentUser.user.avatar_url', function () {
      let logo = this.get('currentUser.user.avatar_url');

      if (logo) {
        return logo;
      } else {
        return 'assets/images/staff/placeholder-human-image@3x.png';
      }
    }),
    companyLogo: computed('isPetParent', 'currentUser.user.company.logo_url', 'currentUser.user.client_accounts.[]', function () {
      let logo = null;

      if (this.get('isPetParent')) {
        let [newestCompany] = this.get('currentUser.user.client_accounts').sort((a, b) => {
          return a.created_at < b.created_at ? 1 : -1;
        });

        if (newestCompany) {
          logo = newestCompany.company_logo;
        }
      } else {
        logo = this.get('currentUser.user.company.logo_url');
      }

      if (logo) {
        return logo;
      } else {
        return '/assets/images/settings/company-placeholder.png';
      }
    }),
    companyName: computed('isPetParent', 'currentUser.user.client_accounts.[]', function () {
      if (this.get('isPetParent')) {
        let [newestCompany] = this.get('currentUser.user.client_accounts').sort((a, b) => {
          return a.created_at < b.created_at ? 1 : -1;
        });

        if (newestCompany) {
          return newestCompany.company_name;
        }
      }

      return null;
    }),
    actions: {
      savePassword() {
        let pass = this.get('password');
        let confirm = this.get('confirmPassword');
        this.set('passwordError', false);
        this.set('confirmError', false);

        if (!pass || pass.length < 8) {
          this.set('passwordError', true);
        } else if (pass != confirm) {
          this.set('confirmError', true);
        } else {
          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/users/${this.get('currentUser.user.id')}/update_password`,
            method: 'POST',
            data: {
              password: pass
            }
          }).done(response => {
            // This is the only way to get the redirect to pull the new currentUser
            let store = this.get('session.store');
            let cookieData = {
              authenticated: {
                authenticator: 'authenticator:jwt',
                token: this.get('session.data.authenticated.token'),
                refresh_token: this.get('session.data.authenticated.refresh_token'),
                user_email: this.get('session.data.authenticated.tokenData.user_email'),
                exp: this.get('session.data.authenticated.tokenData.exp'),
                tokenData: this.get('session.data.authenticated.tokenData')
              }
            };
            store.clear().then(() => {
              store.persist(cookieData).then(() => {
                if (this.get('resetPassword')) {
                  this.transitionToRoute('/');
                } else if (this.get('isPetParent')) {
                  if (response.is_primary_contact) {
                    this.transitionToRoute('add-client.primary-contact', {
                      queryParams: {
                        householdId: this.get('currentUser.user.household.id')
                      }
                    });
                  } else {
                    this.transitionToRoute('add-client.secondary-contact', this.get('currentUser.user.household.id'));
                  }
                } else {
                  this.transitionToRoute('profile.edit-about-me');
                }
              });
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});