define("frontend/components/message-thread/household-select/household-option", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    household: null,
    currentUser: inject.service(),
    firestore: inject.service(),
    firestoreListener: null,
    names: computed('household.owner', 'household.secondaryContact', function () {
      if (this.get('household.secondaryContact')) {
        return `${this.get('household.owner.getFirstName')} & ${this.get('household.secondaryContact.getFirstName')}`;
      }

      return this.get('household.owner.fullName');
    }),
    hasNewMessage: computed('household.user_last_seen_message_thread', 'lastMessageTime', function () {
      if (!this.get('lastMessageTime')) {
        return false;
      }

      let lastSeen = this.get('household.user_last_seen_message_thread');
      let lastMessage = this.get('lastMessageTime');

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage;
    }),

    didInsertElement() {
      this._super(...arguments);

      let self = this;
      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_message_times`);

      if (collection) {
        let firestoreListener = collection.doc(`${self.get('currentUser.user.company.id')}_${self.get('household.id')}`).onSnapshot(function (doc) {
          if (doc.exists) {
            let lastMessageTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              lastMessageTime = moment.unix(lastMessageTime).format();
            }

            let company_id = self.get('currentUser.user.company.id');

            _jquery.default.ajax({
              url: `${_environment.default.APP.host}/api/v2/messages`,
              method: 'GET',
              data: {
                household_id: self.get('household.id'),
                company_id,
                last_message_time: lastMessageTime
              }
            }).then(response => {
              self.set('lastMessageTime', doc.data().staff_last_message);
              let {
                messages
              } = response;

              if (!response.meta.company_ids_with_automated_messages_in_inbox.includes(parseInt(company_id))) {
                messages = messages.filter(message => ['PlainTextMessage', 'ImageMessage'].includes(message.message_type));
              }

              let lastMessage = messages.sortBy('created_at').get('lastObject');

              if (lastMessage) {
                self.get('household').set('last_message_time', lastMessage.created_at);
                self.get('household').set('last_message_text', lastMessage.message);
              }
            });
          } else {
            self.set('lastMessageTime', null);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    }

  });

  _exports.default = _default;
});