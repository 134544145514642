define("frontend/controllers/portal/invoices-summary/company/invoice-detail", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    deletedAdjustments: [],
    addingAdjustment: false,
    company: inject.controller('portal.invoices-summary.company'),
    companyId: computed.readOnly('company.company_id'),
    actions: {
      print() {
        let w = window.open();

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoices/${this.get('model.id')}`,
          method: 'GET',
          dataType: 'html'
        }).done(response => {
          w.document.open();
          w.document.write(response);
          w.document.close();
          $(w).load(() => {
            w.print();
          });
        }).fail(function () {// TODO: Show error dialog?
        });
      },

      clickAppointment() {}

    }
  });

  _exports.default = _default;
});