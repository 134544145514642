define("frontend/mixins/money-formater", ["exports", "accounting/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    formatMoney(amount) {
      let currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
      return (0, _formatMoney.default)(amount, currency, 2).replace(/\.00/g, '');
    }

  });

  _exports.default = _default;
});