define("frontend/models/surcharge-fee-compensation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    }),
    employee_name: _emberData.default.attr(''),
    cancellation_compensation: _emberData.default.attr('number'),
    weekend_compensation: _emberData.default.attr('number'),
    holiday_compensation: _emberData.default.attr('number'),
    late_booking_compensation: _emberData.default.attr('number'),
    off_hours_compensation: _emberData.default.attr('number'),
    totalCompensation: computed('cancellation_compensation', 'weekend_compensation', 'off_hours_compensation', 'holiday_compensation', 'late_booking_compensation', function () {
      return this.get('cancellation_compensation') + this.get('weekend_compensation') + this.get('off_hours_compensation') + this.get('holiday_compensation') + this.get('late_booking_compensation');
    })
  });

  _exports.default = _default;
});