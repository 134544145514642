define("frontend/components/report-card-duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['report-card-duration'],
    durationTime: computed('appointment.started_at', 'appointment.finished_at', function () {
      let start = (0, _moment.default)(this.get('appointment.started_at'));
      let finish = (0, _moment.default)(this.get('appointment.finished_at'));

      let duration = _moment.default.duration(finish.diff(start));

      let hours = duration.hours();

      if (hours < 10) {
        hours = `0${hours}`;
      }

      let minutes = duration.minutes();

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      let seconds = duration.seconds();

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    }),
    startTime: computed('appointment.started_at', 'appointment.company_time_zone', function () {
      let time = _moment.default.tz(this.get('appointment.started_at'), this.get('appointment.company_time_zone'));

      let timeDisplay = time.format('h:mm');
      let meridiem = time.format('A');
      return `${timeDisplay}<span class="report-card-duration--time-meridiem">${meridiem}</span>`;
    }),
    finishTime: computed('appointment.finished_at', 'appointment.company_time_zone', function () {
      let time = _moment.default.tz(this.get('appointment.finished_at'), this.get('appointment.company_time_zone'));

      let timeDisplay = time.format('h:mm');
      let meridiem = time.format('A');
      return `${timeDisplay}<span class="report-card-duration--time-meridiem">${meridiem}</span>`;
    })
  });

  _exports.default = _default;
});