define("frontend/components/invoices-widget/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const FULL_WIDTH = 373;

  var _default = Ember.Component.extend({
    classNames: ['progress-bar'],
    value: 0,
    maxValue: 0,
    percent: computed('value', 'maxValue', function () {
      return this.get('value') / this.get('maxValue');
    }),
    width: computed('percent', function () {
      return this.get('percent') * FULL_WIDTH;
    })
  });

  _exports.default = _default;
});