define("frontend/mixins/dates-to-month-ranges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    datesToMonthRanges(dateArray) {
      let months = {};
      let sortedDates = dateArray.sort().map(date => moment(date));
      sortedDates.forEach(date => {
        let month = date.format('MMMM');

        if (!months[month]) {
          months[month] = [];
        }

        months[month].push(date.format('DD'));
      });
      let dateRanges = [];
      Object.keys(months).forEach(month => {
        let days = months[month].sort().map(day => parseInt(day));
        let ranges = [];

        for (let i = 0; i < days.length; i++) {
          let rangeStart = days[i];
          let rangeEnd = days[i];

          while (i + 1 < days.length && days[i] + 1 == days[i + 1]) {
            rangeEnd = days[i + 1];
            i += 1;
          }

          if (rangeStart == rangeEnd) {
            ranges.push(rangeStart);
          } else {
            ranges.push(`${rangeStart}-${rangeEnd}`);
          }
        }

        dateRanges.push(`${month} ${ranges.join(', ')}`);
      });
      return dateRanges.join('<br />');
    }

  });

  _exports.default = _default;
});