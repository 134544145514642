define("frontend/components/appointment-requests-list/header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['header-dropdown'],
    options: [],
    selectedOption: null,

    didInsertElement() {
      this._super(...arguments);

      $(window).click(_ref => {
        let {
          target
        } = _ref;
        let $target = $(target);

        if ($target.parent().is('.request-header, .header-text')) {
          return;
        }

        this.close();
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      $(window).off('click');
    },

    groupedHeaders: Ember.computed('options.[]', function () {
      return !Array.isArray(this.get('options'));
    }),
    headerEntries: Ember.computed('groupedHeaders', function () {
      if (this.get('groupedHeaders')) {
        return Object.entries(this.get('options'));
      }
    }),
    checkmarkImage: Ember.computed('selectedOption', function () {
      return `assets/images/appointment-requests/check-${this.get('selectedOption')}.svg`;
    }),
    actions: {
      selectOption(option) {
        this.get('selectOption')(option);
      }

    }
  });

  _exports.default = _default;
});