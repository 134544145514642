define("frontend/controllers/client-list/household/billing", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['oauth_state_id'],
    oauth_state_id: null,
    oauthStatePresent: computed.notEmpty('oauth_state_id'),
    plaidCookiePresent: computed.notEmpty('plaidCookie'),
    plaidRedirect: computed.and('oauthStatePresent', 'plaidCookiePresent'),
    anySidePanelActive: false,
    saving: false,
    showSendContractsModal: false,
    errorHandler: inject.service(),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    cookies: inject.service(),
    plaidCookie: computed('cookies', function () {
      return this.get('cookies').read('plaidLinkToken');
    }),
    actions: {
      saveHouseholdUser() {
        let reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.get('model.household.owner').save().then(() => {
          if (reload) {
            this.get('model.household').reload();
          }
        }).catch(error => {
          this.get('errorHandler').handle(error);
        });
      },

      setSaving() {
        this.set('saving', true);
      },

      closeSendContractsModal() {
        this.set('showSendContractsModal', false);
      },

      sendContracts() {
        let householdId = this.get('model.household.id');
        this.set('showSendContractsModal', false);

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/send_client_contracts`,
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(() => {
          this.set('model.household.esignature_status', 'sent');
          this.get('flashMessage').sendFlashMessage(`Your company agreements have been successfully sent to the ${this.get('model.household.owner.last_name')} family`);
        });
      },

      redirectToMicrodeposits() {
        this.transitionToRoute('microdeposits', this.get('model.household.owner.emd5'));
      }

    }
  });

  _exports.default = _default;
});