define("frontend/adapters/appointment", ["exports", "frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    deleteRecord(store, type, snapshot) {
      let data = {};

      if (snapshot.adapterOptions) {
        data = {
          method: snapshot.adapterOptions.method
        };
      }

      return this.ajax(this.buildURL(type.modelName, snapshot.id, 'deleteRecord'), 'DELETE', {
        data
      });
    }

  });

  _exports.default = _default;
});