define("frontend/components/amount-to-pay-by-service", ["exports", "ember-data", "ember-cp-validations", "jquery"], function (_exports, _emberData, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'amount': [(0, _emberCpValidations.validator)('number', {
      allowString: true
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'additional_dog_amount': [(0, _emberCpValidations.validator)('number', {
      allowString: true
    }), (0, _emberCpValidations.validator)('additional-dog-amount')]
  });
  const {
    computed,
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['amount-to-pay-by-service'],
    store: service(),
    compensationType: null,
    offeredServiceAmounts: [],
    isInvalid: computed.not('isValid'),
    options: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    saveClicked: false,
    // Makes an API call for all offered services and fills in pre existing offeredServiceAmounts
    offeredServiceAmountsCopy: computed('offeredServiceAmounts', function () {
      let hasVariablePrices = this.get('offeredServiceAmounts.length') > 0;
      let osas = this.get('offeredServiceAmounts') || [];
      let promise = this.get('store').query('offered-service', {}, {
        reload: true
      }).then(offeredServices => {
        let amounts = [];
        offeredServices.forEach(offeredService => {
          let osa = osas.findBy('offered_service_id', +offeredService.id);

          if (!osa) {
            let amount = hasVariablePrices ? 0 : null;
            let additional_dog_amount = null;
            let offeredServiceAmount = Ember.Object.extend(Validations).create(Ember.getOwner(this).ownerInjection(), {
              offered_service_id: +offeredService.id,
              offered_service_name: offeredService.get('nameWithDuration'),
              position: offeredService.get('position'),
              is_add_on: offeredService.get('is_add_on'),
              amount,
              additional_dog_amount,
              employee: this.get('employee')
            });
            amounts.pushObject(offeredServiceAmount);
          } else {
            let osaWithValidation = Ember.Object.extend(Validations).create(Ember.getOwner(this).ownerInjection(), osa);
            amounts.push(osaWithValidation);
          }
        });
        return amounts;
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    isValid: computed('offeredServiceAmountsCopy.@each.amount', 'offeredServiceAmountsCopy.@each.additional_dog_amount', function () {
      if (!this.get('offeredServiceAmountsCopy.content')) {
        return false;
      }

      return this.get('offeredServiceAmountsCopy').every(offeredServiceAmount => offeredServiceAmount.get('validations.isValid'));
    }),
    actions: {
      selectDifferForAdditionalDogs(option) {
        this.set('employee.differs_for_additional_dogs', option);

        if (option === false) {
          let offeredServiceAmounts = this.get('offeredServiceAmountsCopy');
          offeredServiceAmounts.forEach(offeredServiceAmount => {
            Ember.set(offeredServiceAmount, 'additional_dog_amount', 0);
          });
        }
      },

      close() {
        this.close();
      },

      save() {
        if (this.get('isValid')) {
          let offeredServiceAmounts = this.get('offeredServiceAmountsCopy.content').map(offeredServiceAmount => {
            let {
              offered_service_id,
              offered_service_name
            } = offeredServiceAmount;
            let amount = Number(offeredServiceAmount.amount);
            let additional_dog_amount = Number(offeredServiceAmount.additional_dog_amount);
            return Ember.Object.create({
              offered_service_id,
              offered_service_name,
              amount,
              additional_dog_amount
            });
          });
          this.save(offeredServiceAmounts);
        } else {
          this.set('saveClicked', true);
          let [ele] = (0, _jquery.default)('.red-validation-error').first();

          if (!ele) {
            return;
          }

          ele.scrollIntoView({
            block: 'center'
          });
        }
      }

    }
  });

  _exports.default = _default;
});