define("frontend/routes/companies/category-settings", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      this._super(...arguments);

      return this.store.query('service-category', {}, {
        reload: true
      });
    },

    setupController(controller, model, transition) {
      this._super(...arguments);

      let {
        page
      } = transition.to.params;
      controller.set('onTab', page);
      controller.set('loading', false);
    },

    actions: {
      refreshModel() {
        return this.refresh();
      }

    }
  });

  _exports.default = _default;
});