define("frontend/components/invoice-receipt/appointment-group", ["exports", "ember-data", "moment", "ember-computed-change-gate/change-gate", "jquery"], function (_exports, _emberData, _moment, _changeGate, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group', 'with-bottom-border'],
    collapsed: true,
    showGroup: computed.not('collapsed'),
    incompleteAppointments: computed.filterBy('groupedAppointments', 'completed', false),
    completeAppointments: computed.filterBy('groupedAppointments', 'completed', true),
    household: null,
    overriddenCost: false,
    groupedAppointments: null,
    wasClosedAndHasSalesTax: null,

    willInsertElement() {
      this._super(...arguments);

      this.notifyPropertyChange('editingInvoice');
    },

    allAppointments: computed('groupedAppointments.@each.appointments.[]', function () {
      let appts = [];
      this.get('groupedAppointments').forEach(groupedAppointment => {
        groupedAppointment.get('appointments').forEach(appt => {
          appts.push(appt);
        });
      });
      return appts;
    }),
    groupedAddOnServicesByOfferedService: computed('groupedAppointments.@each.appointments.[]', function () {
      let addOnServicesByOfferedService = {};
      this.get('groupedAppointments').forEach(groupedAppointment => {
        let groupedAppointmentAddOns = {};
        let appointments = groupedAppointment.get('appointments');
        appointments.forEach(appointment => {
          let add_ons = appointment.get('appointment_add_on_services') || [];
          add_ons.forEach(add_on => {
            let osId = add_on.get('offered_service.id');

            if (groupedAppointmentAddOns[osId] == null) {
              groupedAppointmentAddOns[osId] = {
                offered_service: add_on.get('offered_service'),
                pets: [],
                appointments: []
              };
            }

            groupedAppointmentAddOns[osId].appointments.push(appointment);
            groupedAppointmentAddOns[osId].pets.push(appointment.get('pet'));
          });
        });
        Object.entries(groupedAppointmentAddOns).forEach(entry => {
          let key = `${entry[0]}-${entry[1].appointments.length}`;

          if (addOnServicesByOfferedService[key] == null) {
            addOnServicesByOfferedService[key] = {
              offeredServiceId: entry[0],
              offeredService: entry[1].offered_service,
              pets: entry[1].pets,
              groupedAppointments: [],
              appointments: []
            };
          }

          addOnServicesByOfferedService[key].appointments.push(entry[1].appointments);
          addOnServicesByOfferedService[key].groupedAppointments.push(groupedAppointment);
        });
      }); // Turn the dictionary into an array of dictionaries so we can sort it
      // and still access each element by dot notation

      let entries = Object.entries(addOnServicesByOfferedService).sort((x, y) => x[0].localeCompare(y[0]));
      entries = entries.map(entry => {
        return {
          key: entry[0],
          info: entry[1]
        };
      });
      return entries;
    }),
    editingInvoiceChanged: observer('editingInvoice', function () {
      this.set('collapsed', !this.get('editingInvoice'));
    }),
    cost: computed('allAppointments.@each.cost', 'overriddenCost', function () {
      if (this.get('overriddenCost') !== false) {
        return this.get('overriddenCost');
      }

      if (this.get('overriddenCost') == null) {
        return false;
      }

      return this.get('allAppointments').reduce((acc, appointment) => acc += appointment.get('completed') ? appointment.get('cost') : 0, 0);
    }),
    // Even though the appointments aren't changing, this CP is forcing effective price to recompute
    firstAppointment: (0, _changeGate.default)('sortedGroupedAppointments.@each.completed', function () {
      let firstCompletedAppt = this.get('sortedGroupedAppointments').find(appt => appt.get('completed'));

      if (firstCompletedAppt) {
        return firstCompletedAppt;
      } else {
        return this.get('sortedGroupedAppointments.firstObject');
      }
    }),
    effectivePrice: computed('invoice.id', 'invoice.household.id', 'offeredService.id', 'firstAppointment.id', function () {
      let offeredServiceId = this.get('offeredService.id');
      let householdId = this.get('invoice.household.id');
      let firstCompleted = this.get('firstAppointment');
      let invoiceId = this.get('invoice.id');
      let date = (0, _moment.default)(firstCompleted.get('time')).format('YYYY-MM-DD');

      let promise = _jquery.default.ajax({
        url: `/api/v2/effective_offered_service_prices?offered_service_id=${offeredServiceId}&household_id=${householdId}&date=${date}&invoice_id=${invoiceId}`,
        method: 'GET'
      });

      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    totalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', 'pets.[]', function () {
      let price = parseFloat(this.get('effectivePrice.price'));
      let incPrice = parseFloat(this.get('effectivePrice.incremental_price'));
      let petCount = this.get('pets').length;
      return price + (petCount - 1) * incPrice;
    }),
    hasIncrementalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', function () {
      return this.get('effectivePrice.incremental_price') != this.get('effectivePrice.price') && this.get('pets').length > 1;
    }),
    appointmentsSorting: ['time'],
    sortedGroupedAppointments: computed.sort('groupedAppointments', 'appointmentsSorting'),
    pets: computed('allAppointments.@each.pet', function () {
      return this.get('allAppointments').mapBy('pet').reduce((acc, pet) => {
        if (acc.mapBy('id').indexOf(pet.get('id')) < 0) {
          acc.push(pet);
        }

        return acc;
      }, []);
    })
  });

  _exports.default = _default;
});