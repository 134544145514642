define("frontend/routes/accept-staff", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      email: null
    },

    model(params) {
      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/households/accept_request`,
        method: 'POST',
        data: {
          token: params.token,
          confirmation_token: params.email
        }
      }).done(() => {
        window.location.href = '/';
      });
    }

  });

  _exports.default = _default;
});