define("frontend/controllers/client-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    noHouseholds: computed.equal('model.length', 0),
    noFilter: computed.equal('filter', ''),
    clientList: inject.controller(),
    notLoading: computed.readOnly('model.isFulfilled'),
    noHouseholdsAndNoFilter: computed.and('noHouseholds', 'noFilter', 'notLoading'),
    filter: computed.alias('clientList.filter'),
    currentUser: inject.service()
  });

  _exports.default = _default;
});