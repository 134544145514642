define("frontend/components/cancel-multiple-appointments-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    household: null,
    selectedAppointments: null,
    selectedAppointmentCount: computed.alias('selectedAppointments.length'),
    loading: false,
    cancellationNotes: null,

    didInsertElement() {
      this.set('loading', false);
    },

    actions: {
      denyCancel() {
        this.get('denyCancel')();
      },

      confirmCancel() {
        this.set('loading', true);
        this.get('confirmCancel')(this.get('cancellationNotes'));
      }

    }
  });

  _exports.default = _default;
});