define("frontend/components/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    icon: null,
    title: null,
    primaryText: null,
    secondaryText: null,
    primaryAction: null,
    secondaryAction: null
  });

  _exports.default = _default;
});