define("frontend/models/appointment-request", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    pets: _emberData.default.hasMany('pet', {
      async: true
    }),
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: true
    }),
    add_on_services: _emberData.default.attr(),
    household: _emberData.default.belongsTo('household', {
      async: true
    }),
    care_provider: _emberData.default.belongsTo('care-provider', {
      async: true
    }),
    starts_at: _emberData.default.attr('string'),
    company_id: _emberData.default.attr(),
    company_name: _emberData.default.attr(),
    company_logo: _emberData.default.attr(),
    time_zone: _emberData.default.attr('string'),
    time_frame_start_time: _emberData.default.attr('string'),
    time_frame_end_time: _emberData.default.attr('string'),
    special_instructions: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    approved_at: _emberData.default.attr(),
    declined_at: _emberData.default.attr(),
    approved_or_declined_by: _emberData.default.attr(),
    who_requested_first_name: _emberData.default.attr(),
    who_requested_last_initial: _emberData.default.attr(),
    who_requested_last_name: _emberData.default.attr(),
    declined_note: _emberData.default.attr(),
    staff_declines: _emberData.default.attr(),
    pickupWindow: computed('time_frame_start_time', 'time_frame_end_time', function () {
      let startTime = this.get('time_frame_start_time');
      let endTime = this.get('time_frame_end_time');

      if (startTime && endTime) {
        return `${startTime} - ${endTime}`;
      }
    }),
    starts_at_time: computed('starts_at', function () {
      return this.get('startsAtMoment').format('h:mm A');
    }),
    starts_at_date: computed('starts_at', function () {
      return this.get('startsAtMoment').startOf('day');
    }),
    startsAtMoment: computed('starts_at', function () {
      return moment.parseZone(this.get('starts_at')).tz(this.get('time_zone'));
    }),
    sortedPets: computed('pets.[]', function () {
      return this.get('pets').sortBy('name');
    }),
    petNames: computed('sortedPets.[]', function () {
      let pets = this.get('sortedPets');
      return _stringUtil.default.joinWithAnd(pets.map(pet => pet.get('name')));
    })
  });

  _exports.default = _default;
});