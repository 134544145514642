define("frontend/controllers/companies/integrations/quickbooks", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    flashMessage: inject.service(),
    company: computed.alias('currentUser.user.company'),
    currentTab: 'status',
    onStatusTab: computed.equal('currentTab', 'status'),
    onAboutTab: computed.equal('currentTab', 'about'),
    actions: {
      openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      },

      openEffectiveDateModal() {
        this.set('showEffectiveDateModal', true);
      },

      closeEffectiveDateModal() {
        this.set('showEffectiveDateModal', false);
      },

      openConfirmationModal(effectiveDate) {
        this.set('effectiveDate', effectiveDate);
        this.set('showEffectiveDateModal', false);
        this.set('showConfirmationModal', true);
      },

      closeConfirmationModal() {
        this.set('effectiveDate', null);
        this.set('showConfirmationModal', false);
      },

      openDisconnectModal() {
        this.set('showDisconnectModal', true);
      },

      closeDisconnectModal() {
        this.set('showDisconnectModal', false);
      },

      quickbookAuth() {
        this.set('showConfirmationModal', false);
        let effectiveDate = this.get('effectiveDate');

        _jquery.default.ajax({
          url: `/api/v2/companies/${this.get('currentUser.user.company.id')}/quickbooks_authenticate`,
          method: 'POST',
          data: {
            effective_date: effectiveDate
          }
        }).done(_ref => {
          let {
            redirect
          } = _ref;
          return window.location.replace(redirect);
        });
      },

      syncNow() {
        this.set('syncNowDisabled', true);

        _jquery.default.ajax({
          url: `/api/v2/companies/${this.get('currentUser.user.company.id')}/quickbooks_export`,
          method: 'POST'
        }).then(() => {
          this.get('flashMessage').sendFlashMessage('QuickBooks sync has been successfully started! It may take a few minutes to finish', 'success');
        });
      },

      disconnectQuickbooks() {
        this.set('disconnectDisabled', true);

        _jquery.default.ajax({
          url: `/api/v2/companies/${this.get('currentUser.user.company.id')}/quickbooks_disconnect`,
          method: 'POST'
        }).then(() => {
          window.location.reload();
        });
      }

    }
  });

  _exports.default = _default;
});