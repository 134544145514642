define("frontend/helpers/zip-or-postal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.zipOrPostal = zipOrPostal;

  function zipOrPostal(_ref) {
    let [country] = _ref;

    // TODO: move 'canada' to common shared location
    if (!country) {
      return 'Zip code';
    }

    if (country.toLowerCase() === 'canada') {
      return 'Postal code';
    } else if (country.toLowerCase() === 'australia') {
      return 'Postcode';
    } else {
      return 'Zip code';
    }
  }

  var _default = Ember.Helper.helper(zipOrPostal);

  _exports.default = _default;
});