define("frontend/controllers/companies/category-settings", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['categoryId', 'showHolidayPolicyModal'],
    currentUser: inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    flashMessage: inject.service(),
    firestore: inject.service(),
    firestoreListener: null,
    categoryId: null,
    showTimeBlockModal: false,
    selectedTimeFrame: null,
    onTab: null,
    onTimeBlocks: computed.equal('onTab', 'time-blocks'),
    onPolicies: computed.equal('onTab', 'policies'),
    onAccountability: computed.equal('onTab', 'accountability'),
    hasTimeFrames: computed.gt('selectedCategory.time_frames.length', 0),
    showAccountability: computed.or('selectedCategory.supports_report_cards', 'selectedCategory.supports_gps', 'selectedCategory.supports_check_in_out'),
    timeBlockLinkClasses: computed('onTimeBlocks', function () {
      if (this.get('onTimeBlocks')) {
        return 'header-link active';
      }

      return 'header-link';
    }),
    policiesLinkClasses: computed('onPolicies', function () {
      if (this.get('onPolicies')) {
        return 'header-link active';
      }

      return 'header-link';
    }),
    windowOptions: [{
      label: 'fifteen-minute',
      value: '15',
      window: 'thirty-minute'
    }, {
      label: 'thirty-minute',
      value: '30',
      window: 'one-hour'
    }, {
      label: 'forty five-minute',
      value: '45',
      window: 'one-hour and thirty-minute'
    }, {
      label: 'one-hour',
      value: '60',
      window: 'two-hour'
    }, {
      label: 'one-hour and thirty-minute',
      value: '90',
      window: 'three-hour'
    }, {
      label: 'two-hour',
      value: '120',
      window: 'four-hour'
    }, {
      label: 'two-hour and thirty-minute',
      value: '150',
      window: 'five-hour'
    }, {
      label: 'three-hour',
      value: '180',
      window: 'six-hour'
    }],
    selectedCategory: computed('categoryId', 'model.[]', function () {
      const {
        categoryId,
        model
      } = this.getProperties('categoryId', 'model');
      const firstAvailableCategory = model.get('firstObject');
      let selectedCategory = firstAvailableCategory;

      if (categoryId) {
        const foundCategory = model.findBy('id', categoryId);
        selectedCategory = foundCategory || firstAvailableCategory;
      }

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      let self = this;
      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_time_frames`);

      if (collection) {
        let firestoreListener = collection.doc(`${this.get('company.id')}_${selectedCategory.get('id')}`).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set('isUpdating', doc.data().updating);
          } else {
            self.set('isUpdating', false);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }

      return selectedCategory;
    }),
    selectedTimeWindow: computed('selectedCategory', 'selectedCategory.time_window_in_minutes', function () {
      let options = this.get('windowOptions');
      let selected = options.filter(option => this.get('selectedCategory.time_window_in_minutes') == option.value);
      return selected.get('firstObject');
    }),
    categoryOptions: computed('model.[]', function () {
      return this.get('model').map(category => {
        return {
          label: category.get('name'),
          value: category.get('id')
        };
      });
    }),
    selectedCategoryOption: computed('categoryOptions.[]', 'selectedCategory', function () {
      return this.get('categoryOptions').filter(option => option.value == this.get('selectedCategory.id')).get('firstObject');
    }),
    timeWindowClarification: computed('selectedTimeWindow', function () {
      let timeWindow = this.get('selectedTimeWindow');

      if (timeWindow) {
        let startTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').subtract(timeWindow.value, 'minutes').format('h:mmA');
        let endTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').add(timeWindow.value, 'minutes').format('h:mmA');
        return `${startTime} - ${endTime}`;
      } else {
        return;
      }
    }),
    sortedCancellationFeePolicies: computed('selectedCategory', 'selectedCategory.cancellation_fee_policies.[]', 'selectedCategory.cancellation_fee_policies.@each.{time,withinMinutes,method,days_before,isDeleted}', function () {
      let policies = this.get('selectedCategory.cancellation_fee_policies').filter(policy => !policy.get('isDeleted'));
      return policies.sort((x, y) => {
        if (x.get('method') == 'after' && y.get('method') == 'within') {
          return -1;
        }

        if (x.get('method') == 'within' && y.get('method') == 'after') {
          return 1;
        }

        if (x.get('method') == 'after') {
          if (x.get('days_before') < y.get('days_before')) {
            return -1;
          } else if (x.get('days_before') > y.get('days_before')) {
            return 1;
          } else {
            if (moment(x.get('time')) < moment(y.get('time'))) {
              return 1;
            }

            return -1;
          }
        } else {
          if (x.get('within_minutes') < y.get('within_minutes')) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    }),
    checkInReminderToggleActivated: computed('selectedCategory.checkInAlertTimeInMinutes', function () {
      return this.get('selectedCategory.checkInAlertTimeInMinutes') > 0;
    }),
    checkOutReminderToggleActivated: computed('selectedCategory.checkOutAlertTimeInMinutes', function () {
      return this.get('selectedCategory.checkOutAlertTimeInMinutes') > 0;
    }),
    timingOptions: [{
      label: '15 minutes',
      value: '15'
    }, {
      label: '30 minutes',
      value: '30'
    }, {
      label: '45 minutes',
      value: '45'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '2 hours',
      value: '120'
    }],
    selectedCheckInAlertTiming: computed('selectedCategory.checkInAlertTimeInMinutes', function () {
      return this.get('timingOptions').filter(option => option.value == this.get('selectedCategory.checkInAlertTimeInMinutes')).get('firstObject');
    }),
    selectedCheckOutAlertTiming: computed('selectedCategory.checkOutAlertTimeInMinutes', function () {
      return this.get('timingOptions').filter(option => option.value == this.get('selectedCategory.checkOutAlertTimeInMinutes')).get('firstObject');
    }),
    lateArrivalTooltip: "Get an alert if you or your team haven't started a visit within a certain time period of the scheduled appointment time. No late arrival alerts are sent for all-day services.",
    lateCheckoutTooltip: "Get an alert if you or your team haven't ended a visit within a certain time period after the appointment should have ended. No late check out alerts are sent for all-day services or services with a 0-minute duration.",
    actions: {
      toggleSelectingCategories() {
        this.toggleProperty('selectingCategories');
      },

      selectCategory(category) {
        this.set('categoryId', category.get('id'));
        this.set('showUpdatingError', false);
      },

      updateSelectedCategory(categoryId) {
        this.set('categoryId', categoryId);
        this.set('showUpdatingError', false);
      },

      clickUpdateHoursButton() {
        if (this.get('isUpdating')) {
          this.set('showUpdatingError', true);
        } else {
          this.set('showUpdatingTimeFramesModal', true);
        }
      },

      closeUpdatingTimeFramesModal() {
        this.get('selectedCategory').rollbackAttributes();
        this.get('selectedCategory.time_frames').filterBy('markedForDeletion', true).forEach(a => a.set('markedForDeletion', false));
        this.get('selectedCategory.time_frames').forEach(timeFrame => {
          timeFrame.rollbackAttributes();
        });
        this.set('showUpdatingTimeFramesModal', false);
      },

      updateCategoryHours(updateAppointmentsMethod) {
        let category = this.get('selectedCategory');
        let activeTimeFrames = category.get('time_frames').filter(tf => !tf.get('markedForDeletion'));
        activeTimeFrames = activeTimeFrames.map(tf => {
          return {
            start_time: tf.get('start_time'),
            end_time: tf.get('end_time'),
            is_off_hours: tf.get('is_off_hours'),
            days_of_week: tf.get('days_of_week')
          };
        });
        this.set('showUpdatingTimeFramesModal', false);
        this.set('loading', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/service_categories/${category.id}/update_hours`,
          method: 'POST',
          data: {
            use_time_blocks: category.get('use_time_blocks'),
            time_frames: activeTimeFrames,
            time_window_in_minutes: category.get('time_window_in_minutes'),
            update_appointments_method: updateAppointmentsMethod
          }
        }).then(() => {
          category.get('time_frames').forEach(tf => {
            if (tf) {
              tf.rollbackAttributes();
            }
          });
          this.send('refreshModel');
        });
      },

      changeTab(tab) {
        this.transitionToRoute('companies.category-settings', this.get('company.id'), tab, {
          queryParams: {
            categoryId: this.get('categoryId')
          }
        });
      },

      toggleCancellationFeePolicy() {
        if (this.get('selectedCategory.hasCancellationFeePolicies')) {
          this.set('deletingAllCancellationPolices', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/cancellation_fee_policies/delete_all`,
            method: 'POST'
          }).then(() => {
            this.get('selectedCategory.cancellation_fee_policies').forEach(policy => {
              policy.deleteRecord();
            });
            this.set('deletingAllCancellationPolices', false);
          });
        } else {
          this.send('createCancellationFeePolicy');
        }
      },

      toggleWeekendPolicy() {
        if (!this.get('selectedCategory.weekend_surcharge_policy')) {
          this.store.createRecord('weekend-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            days_of_week: [0, 6]
          });
          this.set('showWeekendPoliciesModal', true);
        } else {
          this.get('selectedCategory.weekend_surcharge_policy').destroyRecord();
        }
      },

      toggleOffHoursPolicy() {
        if (!this.get('selectedCategory.off_hours_surcharge_policy')) {
          let time_frames = this.get('selectedCategory.time_frames').filter(tf => tf.get('is_off_hours'));
          this.store.createRecord('off-hours-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            time_frames
          });
          this.set('showOffHoursPoliciesModal', true);
        } else {
          this.get('selectedCategory.off_hours_surcharge_policy').destroyRecord();
        }
      },

      toggleCutOffPolicy() {
        if (!this.get('selectedCategory.request_cut_off_policy')) {
          this.store.createRecord('request_cut_off_policy', {
            service_category: this.get('selectedCategory'),
            method: 'after',
            days_before: 0,
            within_minutes: 60
          });
          this.set('showCutOffPoliciesModal', true);
        } else {
          this.get('selectedCategory.request_cut_off_policy').destroyRecord();
        }
      },

      toggleCheckInReminderToggle() {
        if (!this.get('selectedCategory.checkInAlertTimeInMinutes')) {
          this.set('selectedCategory.checkInAlertTimeInMinutes', 15);
          this.send('showLateNotificationsModal', 'arrival');
          this.set('lateNotificationsNewRecord', true);
        } else {
          this.set('selectedCategory.checkInAlertTimeInMinutes', null);
          this.get('selectedCategory').updateCheckInAlertTiming();
        }
      },

      toggleCheckOutReminderToggle() {
        if (!this.get('selectedCategory.checkOutAlertTimeInMinutes')) {
          this.set('selectedCategory.checkOutAlertTimeInMinutes', 15);
          this.send('showLateNotificationsModal', 'check out');
          this.set('lateNotificationsNewRecord', true);
        } else {
          this.set('selectedCategory.checkOutAlertTimeInMinutes', null);
          this.get('selectedCategory').updateCheckOutAlertTiming();
        }
      },

      showCutOffPolicyModal() {
        this.set('showCutOffPoliciesModal', true);
      },

      closeCutOffPolicyModal() {
        this.set('showCutOffPoliciesModal', false);
      },

      showWeekendPoliciesModal() {
        this.set('showWeekendPoliciesModal', true);
      },

      closeWeekendPolicyModal() {
        this.set('showWeekendPoliciesModal', false);
      },

      showOffHoursPoliciesModal() {
        this.set('showOffHoursPoliciesModal', true);
      },

      closeOffHoursPolicyModal() {
        this.set('showOffHoursPoliciesModal', false);
      },

      showLateNotificationsModal(notificationType) {
        this.set('showLateNotificationsModal', true);
        this.set('lateNotificationsSelectedType', notificationType);

        if (notificationType == 'arrival') {
          this.set('originalLateNotificationTime', this.get('selectedCategory.checkInAlertTimeInMinutes'));
        } else {
          this.set('originalLateNotificationTime', this.get('selectedCategory.checkOutAlertTimeInMinutes'));
        }
      },

      closeLateNotificationsModal() {
        let rollback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.set('showLateNotificationsModal', false);
        this.set('lateNotificationsNewRecord', false);

        if (rollback) {
          if (this.get('lateNotificationsSelectedType') == 'arrival') {
            this.set('selectedCategory.checkInAlertTimeInMinutes', this.get('originalLateNotificationTime'));
          } else {
            this.set('selectedCategory.checkOutAlertTimeInMinutes', this.get('originalLateNotificationTime'));
          }
        }
      },

      toggleLateBookingPolicy() {
        if (!this.get('selectedCategory.late_booking_surcharge_policy')) {
          this.store.createRecord('late-booking-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            method: 'after',
            days_before: 0,
            within_minutes: 60
          });
          this.set('showLateBookingPolicyModal', true);
        } else {
          this.get('selectedCategory.late_booking_surcharge_policy').destroyRecord();
        }
      },

      showLateBookingPolicyModal() {
        this.set('showLateBookingPolicyModal', true);
      },

      closeLateBookingPolicyModal() {
        this.set('showLateBookingPolicyModal', false);
      },

      toggleHolidayPolicy() {
        if (!this.get('selectedCategory.holiday_surcharge_policy')) {
          this.store.createRecord('holiday-surcharge-policy', {
            service_category: this.get('selectedCategory'),
            amount_type: 'fixed',
            dates: []
          });
          this.set('showHolidayPolicyModal', 'true');
        } else {
          this.get('selectedCategory.holiday_surcharge_policy').destroyRecord();
        }
      },

      showHolidayPolicyModal() {
        this.set('showHolidayPolicyModal', 'true');
      },

      closeHolidayPolicyModal() {
        this.set('showHolidayPolicyModal', 'false');
      },

      createCancellationFeePolicy() {
        let newCancellationFee = this.store.createRecord('cancellation-fee-policy', {
          service_category: this.get('selectedCategory'),
          amount_type: 'percentage',
          method: 'after',
          days_before: 0,
          within_minutes: 60,
          employee_compensation_type: 'percentage'
        });
        this.set('selectedCancellationFeePolicy', newCancellationFee);
        this.set('showCancellationFeePolicyModal', true);
      },

      saveCancellationFeePolicy() {
        this.get('selectedCancellationFeePolicy').save().then(() => {
          this.store.query('service-category', {}, {
            reload: true
          }).then(serviceCategories => {
            this.set('model', serviceCategories);
            this.set('showCancellationFeePolicyModal', false);
            this.set('selectedCancellationFeePolicy', null);
          });
        });
      },

      toggleCancellationFeePolicyModal() {
        this.get('selectedCancellationFeePolicy').rollbackAttributes();
        this.toggleProperty('showCancellationFeePolicyModal');
      },

      selectCancellationFeePolicy(policy) {
        this.set('selectedCancellationFeePolicy', policy);
        this.set('showCancellationFeePolicyModal', true);
      },

      removeCancellationFeePolicy(policy) {
        this.set('cancellationFeePolicyToRemove', policy);
        this.set('showRemoveCancellationFeePolicyModal', true);
        this.set('showCancellationFeePolicyModal', false);
      },

      denyRemoveCancellationPolicy() {
        this.set('cancellationFeePolicyToRemove', null);
        this.set('showRemoveCancellationFeePolicyModal', false);
      },

      confirmRemoveCancellationPolicy() {
        this.get('cancellationFeePolicyToRemove').destroyRecord().then(() => {
          this.set('cancellationFeePolicyToRemove', null);
          this.set('showRemoveCancellationFeePolicyModal', false);
        });
      },

      toggleReportCardsRequired() {
        this.set('selectedCategory.show_report_card_on_completion', !this.get('selectedCategory.show_report_card_on_completion'));
        this.get('selectedCategory').updateReportCard();
      },

      toggleCheckInCheckOutRequired() {
        this.set('selectedCategory.show_check_in_check_out_appointment', !this.get('selectedCategory.show_check_in_check_out_appointment'));
        this.get('selectedCategory').updateCheckInCheckOut();
      },

      toggleGps() {
        this.set('selectedCategory.enable_gps_on_check_in', !this.get('selectedCategory.enable_gps_on_check_in'));
        this.get('selectedCategory').updateGps();
      }

    }
  });

  _exports.default = _default;
});