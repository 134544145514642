define("frontend/helpers/format-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPhone = formatPhone;

  function formatPhone(_ref) {
    let [phone, phoneType, country] = _ref;

    if (phone && phone.trim() !== '') {
      if (country == 'Australia') {
        if (phoneType == 'Mobile') {
          return phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
        } else {
          return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2 $3');
        }
      } else {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
    }

    return '';
  }

  var _default = Ember.Helper.helper(formatPhone);

  _exports.default = _default;
});