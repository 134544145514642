define("frontend/components/invoice-receipt/add-on-appointment-group", ["exports", "ember-data", "moment", "ember-computed-change-gate/change-gate", "jquery"], function (_exports, _emberData, _moment, _changeGate, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group', 'add-on-appointment-group'],
    collapsed: true,
    showGroup: computed.not('collapsed'),
    groupedAddOnService: null,
    incompleteAppointments: computed.filterBy('groupedAddOnService.info.groupedAppointments', 'completed', false),
    completeAppointments: computed.filterBy('groupedAddOnService.info.groupedAppointments', 'completed', true),
    household: null,
    overriddenCost: false,
    groupedAppointments: computed.alias('groupedAddOnService.info.groupedAppointments'),
    offeredService: computed.alias('groupedAddOnService.info.offeredService'),
    pets: computed.alias('groupedAddOnService.info.pets'),

    willInsertElement() {
      this._super(...arguments);

      this.notifyPropertyChange('editingInvoice');
    },

    allAppointments: computed('groupedAppointments.@each.appointments.[]', function () {
      let appts = [];
      this.get('groupedAppointments').forEach(groupedAppointment => {
        groupedAppointment.get('appointments').forEach(appt => {
          appts.push(appt);
        });
      });
      return appts;
    }),
    editingInvoiceChanged: observer('editingInvoice', function () {
      this.set('collapsed', !this.get('editingInvoice'));
    }),
    cost: computed('completeAppointments.[]', 'totalPrice', function () {
      return this.get('completeAppointments.length') * this.get('totalPrice');
    }),
    // Even though the appointments aren't changing, this CP is forcing effective price to recompute
    firstAppointment: (0, _changeGate.default)('sortedGroupedAppointments.@each.completed', function () {
      let firstCompletedAppt = this.get('sortedGroupedAppointments').find(appt => appt.get('completed'));

      if (firstCompletedAppt) {
        return firstCompletedAppt;
      } else {
        return this.get('sortedGroupedAppointments.firstObject');
      }
    }),
    effectivePrice: computed('invoice.id', 'invoice.household.id', 'offeredService.id', 'firstAppointment.id', function () {
      let offeredServiceId = this.get('offeredService.id');
      let householdId = this.get('invoice.household.id');
      let firstCompleted = this.get('firstAppointment');
      let invoiceId = this.get('invoice.id');
      let date = (0, _moment.default)(firstCompleted.get('time')).format('YYYY-MM-DD');

      let promise = _jquery.default.ajax({
        url: `/api/v2/effective_offered_service_prices?offered_service_id=${offeredServiceId}&household_id=${householdId}&date=${date}&invoice_id=${invoiceId}`,
        method: 'GET'
      });

      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    totalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', 'pets.[]', function () {
      let price = parseFloat(this.get('effectivePrice.price'));
      let incPrice = parseFloat(this.get('effectivePrice.incremental_price'));
      let petCount = this.get('pets').length;
      return price + (petCount - 1) * incPrice;
    }),
    hasIncrementalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', function () {
      return this.get('effectivePrice.incremental_price') != this.get('effectivePrice.price') && this.get('pets').length > 1;
    }),
    appointmentsSorting: ['time'],
    sortedGroupedAppointments: computed.sort('groupedAppointments', 'appointmentsSorting')
  });

  _exports.default = _default;
});