define("frontend/routes/portal/invoices-summary/company/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    media: inject.service(),

    model() {
      let {
        company
      } = this._super(...arguments);

      let invoiceListItems = this.store.query('invoice-list-item', {
        company_id: company.get('id')
      });
      return Ember.RSVP.hash({
        company,
        invoiceListItems
      });
    },

    afterModel(model) {
      let {
        company,
        invoiceListItems
      } = model;

      if (this.get('media.isMobile')) {
        return;
      }

      if (invoiceListItems.get('length') > 0) {
        let invoices = invoiceListItems.filter(item => item.get('invoice') != null).map(listItem => listItem.get('invoice'));
        let firstId = invoices.get('firstObject.id');

        if (firstId) {
          return this.transitionTo('portal.invoices-summary.company.invoice-detail', company.get('id'), firstId);
        }
      }
    }

  });

  _exports.default = _default;
});