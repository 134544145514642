define("frontend/controllers/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    queryParams: ['signContract', 'apptRqstCtgryID', 'apptRqstCmpnyID', 'apptRqstPtIDs'],
    signContract: null,
    signContractParam: computed.alias('signContract'),
    showSignContractsModal: false,
    filterSignatureRequestsWithCategoryID: null,
    apptRqstCtgryID: null,
    appointmentRequestCategoryID: computed.alias('apptRqstCtgryID'),
    apptRqstCmpnyID: null,
    appointmentRequestCompanyID: computed.alias('apptRqstCmpnyID'),
    apptRqstPtIDs: null,
    appointmentRequestPetIDs: computed.alias('apptRqstPtIDs'),
    onAppointmentRequestRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName').split('.')[1] == 'appointment-request';
    }),
    inhouseSignaturesObserver: observer('model.inhouseSignatures', function () {
      const signatureRequests = this.get('model.inhouseSignatures') || [];
      let allCategoryIDs = [];
      signatureRequests.forEach(request => {
        if (request && request.category_ids_required_for) {
          allCategoryIDs.push(...request.category_ids_required_for.map(id => id.toString()));
        }
      });
      const uniqueIDs = Array.from(new Set(allCategoryIDs));

      if (uniqueIDs.length) {
        this.get('currentUser').set('serviceCategoriesWithPendingAgreements', uniqueIDs);
      }

      this.get('currentUser').set('allCategoriesRequireSigning', this.get('model.allCategoriesRequireSigning'));

      if (signatureRequests.length) {
        this.get('currentUser').set('hasAgreementsToReview', true);
      }
    }),
    signatureRequests: computed('model.inhouseSignatures', 'filterSignatureRequestsWithCategoryID', 'signContractParam', 'appointmentRequestCategoryID', function () {
      const signatureRequests = this.get('model.inhouseSignatures') || [];
      const idOfRequestToSign = this.get('signContractParam');

      if (idOfRequestToSign) {
        this.set('showSignContractsModal', true);
        return signatureRequests.filter(request => request.id === parseInt(idOfRequestToSign));
      } //The appointmentRequestCategoryID comes from URL param when user is directed here to sign from appt request flow in React


      const filterID = this.get('filterSignatureRequestsWithCategoryID') || parseInt(this.get('appointmentRequestCategoryID'));

      if (filterID) {
        this.set('showSignContractsModal', true);
        const filteredRequests = signatureRequests.filter(request => request.category_ids_required_for.includes(filterID));
        return filteredRequests;
      }

      return signatureRequests;
    }),

    init() {
      this._super(...arguments);

      this.needToPromptWithSignatureRequests = () => {
        const storedData = JSON.parse(localStorage.getItem("dismissedSignatureRequests")) || {};
        const storedTime = storedData.time || 0;
        const dismissedSignatureRequestIDs = storedData.dismissedIDs || [];
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - storedTime;
        const hasBeen4Hours = timeDifference >= 14400000;
        const signatureRequests = this.get('signatureRequests') || [];
        const currentSignatureRequestIDs = signatureRequests.map(request => request.id);
        const hasNewSignatureRequests = currentSignatureRequestIDs.some(id => !dismissedSignatureRequestIDs.includes(id));
        return hasBeen4Hours || hasNewSignatureRequests;
      }, this.signatureRequestsPromptCheck = () => {
        const hasRequests = (this.get('signatureRequests') || []).length;
        const shouldPrompt = this.needToPromptWithSignatureRequests();
        const modalIsNotAlreadyShowing = !this.get('showSignContractsModal');

        if (hasRequests && shouldPrompt && modalIsNotAlreadyShowing) {
          this.set("showSignContractsModal", true);
        }
      };
      setTimeout(() => {
        const hasRequests = (this.get('signatureRequests') || []).length;
        const hasBeen4Hours = this.needToPromptWithSignatureRequests();

        if (hasRequests) {
          if (hasBeen4Hours) {
            this.set("showSignContractsModal", true);
          } else {
            this.promptCheckInterval = setInterval(this.signatureRequestsPromptCheck, 60000);
          }
        }
      }, 2000);
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.promptCheckInterval) {
        clearInterval(this.promptCheckInterval);
      }
    },

    actions: {
      reloadCurrentUser(option) {
        this.get('currentUser').reload().then(() => {
          if (option == 'accepted') {
            this.transitionToRoute('portal.billing', {
              queryParams: {
                addedClient: true
              }
            });
          } else {
            this.send('refreshModel');
          }
        });
      },

      reviewContractsLater() {
        this.set('showSignContractsModal', false);
        const signatureRequests = this.get('signatureRequests') || [];
        const dismissedSignatureRequestIDs = signatureRequests.map(request => request.id);
        const dismissedData = {
          time: new Date().getTime(),
          dismissedIDs: dismissedSignatureRequestIDs
        };
        localStorage.setItem("dismissedSignatureRequests", JSON.stringify(dismissedData));
      },

      reShowContractsModal() {
        this.set('showSignContractsModal', true);
      },

      completedSigningForSpecificCategory() {
        const categoryID = this.get('filterSignatureRequestsWithCategoryID');
        const serviceCategoriesWithPendingAgreements = this.get('currentUser.serviceCategoriesWithPendingAgreements');
        const updatedServiceCategoriesWithPendingAgreements = serviceCategoriesWithPendingAgreements.filter(id => parseInt(id) !== categoryID);
        this.get('currentUser').set('serviceCategoriesWithPendingAgreements', updatedServiceCategoriesWithPendingAgreements);
        this.get('currentUser').notifyPropertyChange('serviceCategoriesWithPendingAgreements');
        this.set('filterSignatureRequestsWithCategoryID', null);
      }

    }
  });

  _exports.default = _default;
});