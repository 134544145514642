define("frontend/controllers/edit-appt-weekday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disabled: Ember.computed('model', 'model.enabled', function () {
      return !this.get('model.enabled');
    }),
    actions: {
      toggleWeekdayEnabled() {
        this.set('model.enabled', !this.get('model.enabled'));
      }

    }
  });

  _exports.default = _default;
});