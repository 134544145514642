define("frontend/models/recurring-appointment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    start_date: _emberData.default.attr(),
    offered_service_id: _emberData.default.attr(),
    special_instructions: _emberData.default.attr(),
    street: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    zipcode: _emberData.default.attr(),
    day_of_week: _emberData.default.attr(),
    preferred_start_time: _emberData.default.attr(),
    time_frame_start_time: _emberData.default.attr(),
    time_frame_end_time: _emberData.default.attr(),
    set_by_custom_window: _emberData.default.attr('boolean'),
    end_date: _emberData.default.attr(),
    frequency: _emberData.default.attr(),
    recurrence_count: _emberData.default.attr(),
    recurrence_ends_on_type: _emberData.default.attr(),
    recurring_appointment_group_id: _emberData.default.attr(),
    position: _emberData.default.attr(),
    positions_previous_pets: _emberData.default.attr(),
    pet: _emberData.default.belongsTo('pet', {
      async: false
    }),
    care_provider: _emberData.default.belongsTo('care-provider', {
      async: false
    }),
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: false
    }),
    // This is for ugly RA logic which references this instead of care_provider
    careProvider: computed.alias('care_provider'),
    timeFrameStart: computed.alias('time_frame_start_time'),
    timeFrameEnd: computed.alias('time_frame_end_time'),
    add_on_service_ids: _emberData.default.attr(),
    recurring_appointment_add_on_services: _emberData.default.hasMany('recurring-appointment-add-on-service', {
      async: false
    }),
    time: computed('preferred_start_time', function () {
      return _moment.default.parseZone(this.get('preferred_start_time')).format('h:mm A');
    }),
    pickupWindow: computed('time', 'offered_service', 'offered_service.service_category_id', function () {
      let time = this.get('time');

      if (!time) {
        return;
      }

      let serviceCategory = this.get('offered_service.service_category');

      if (!serviceCategory.get('use_time_blocks')) {
        return;
      }

      let timeFrames = serviceCategory.get('time_frames');
      let startTimeMoment = (0, _moment.default)(time, 'hh:mm a');
      let dayOfWeek = this.get('day_of_week');
      let potentialTimeFrames = timeFrames.filter(timeFrame => {
        let start = (0, _moment.default)(timeFrame.get('start_time'), 'hh:mm a');
        let end = (0, _moment.default)(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      let showStart = null;
      let showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        let timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = (0, _moment.default)(time, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = (0, _moment.default)(time, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        return `${showStart} - ${showEnd}`;
      }
    })
  });

  _exports.default = _default;
});