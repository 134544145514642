define("frontend/services/inbox-has-unread-messages", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Service.extend({
    router: service(),
    hasUnreadMessages: false,
    currentUser: service(),
    firestore: service(),
    flashMessage: service(),
    firestoreListener: null,
    lastMessageTime: null,

    startTracking() {
      if (this.get('currentUser.user.isStaff')) {
        return;
      }

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      let collectionName = `${_environment.default.environment}_staff_has_unread_messages`;
      let docName = `${this.get('currentUser.user.company.id')}_${this.get('currentUser.user.id')}`;

      if (this.get('currentUser.user.isPetParent')) {
        collectionName = `${_environment.default.environment}_pet_parent_has_unread_messages`;
        docName = `${this.get('currentUser.user.id')}`;
      }

      let self = this;
      let collection = this.get('firestore').getCollection(collectionName);

      if (collection) {
        let firestoreListener = collection.doc(docName).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set('hasUnreadMessages', doc.data().unread_messages);
            let lastMessageTime = doc.data().last_message;
            let storedTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              if (storedTime && storedTime < lastMessageTime) {
                let routeName = self.get('router.currentRouteName');

                if (!routeName.includes('inbox') && !routeName.includes('portal.messages')) {
                  self.get('flashMessage').newMessage(() => {
                    if (self.get('currentUser.user.isPetParent')) {
                      window.location.href = '/portal/messages';
                    } else {
                      window.location.href = '/inbox';
                    }
                  }, () => {
                    /* noop */
                  }, 'You have a new message!', 'Go to inbox', 'Dismiss');
                }
              }

              self.set('lastMessageTime', lastMessageTime);
            } else {
              self.set('lastMessageTime', moment().unix());
            }
          } else {
            self.set('hasUnreadMessages', false);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    }

  });

  _exports.default = _default;
});