define("frontend/components/custom-pricing-modal/offered-service-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    store: inject.service(),
    offeredServices: computed('currentUser.user.company', function () {
      return this.get('store').query('offered-service', {}, {
        reload: true
      });
    }),
    sortedOfferedServices: computed.sort('offeredServices', function (a, b) {
      if (a.get('service_category_position') > b.get('service_category_position')) {
        return 1;
      } else if (a.get('service_category_position') < b.get('service_category_position')) {
        return -1;
      } else {
        if (a.get('position') > b.get('position')) {
          return 1;
        } else if (a.get('position') < b.get('position')) {
          return -1;
        }

        return 0;
      }
    }),
    offeredServicePrices: computed('offeredServiceClientPrices.@each.price', 'sortedOfferedServices.[]', function () {
      return this.get('sortedOfferedServices').map(offeredService => {
        let offeredServiceClientPrice = this.findCustomPrice(this.get('offeredServiceClientPrices'), offeredService);
        return Ember.Object.create({
          offeredServiceClientPrice,
          offeredService
        });
      });
    }),

    findCustomPrice(offeredServiceClientPrices, offeredService) {
      return offeredServiceClientPrices.find(oscp => {
        return oscp.get('offered_service.id') === offeredService.get('id');
      });
    },

    actions: {
      onClose() {
        this.get('onClose')();
      },

      onEditPrice(price) {
        this.get('onEditPrice')(price);
      },

      resetAllPrices() {
        this.get('resetAllPrices')();
      }

    }
  });

  _exports.default = _default;
});