define("frontend/controllers/add-appointment/index", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    addAppointmentData: inject.service(),
    currentUser: inject.service(),
    selectedPetValues: computed.alias('addAppointmentData.selectedPetValues'),
    queryParams: ['selectedHouseholdId', 'filter'],
    selectedHouseholdId: null,
    showErrorFlash: false,
    filter: '',
    loading: false,
    usersValueLabel: computed('model', 'model.@each.fullName', function () {
      return this.get('model').map(user => {
        return {
          value: user.get('id'),
          label: user.get('fullName')
        };
      });
    }),
    selectedHouseholdPetOptions: computed('selectedHousehold.pets.@each.{name,avatarOrPlaceholder,id}', function () {
      let household = this.get('selectedHousehold');

      if (Ember.isEmpty(household)) {
        return [];
      }

      return household.get('pets').map(pet => {
        let label = pet.get('name');
        let thumbnail = pet.get('avatarOrPlaceholder');
        let value = pet;
        return {
          label,
          thumbnail,
          value
        };
      });
    }),

    removeNewRecords() {
      this.store.peekAll('address').filter(a => a.get('id') == undefined).forEach(a => a.deleteRecord());
      this.store.peekAll('pet').filter(p => p.get('id') == undefined).forEach(p => p.deleteRecord());
    },

    reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(a => a.reload());
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(p => p.reload());
    },

    actions: {
      selectedPetValuesChanged(values) {
        this.set('selectedPetValues', values);
        this.set('nextDisabled', Ember.isEmpty(this.get('selectedPetValues')));
      },

      focusSearchInput() {
        (0, _jquery.default)('.search-input input').focus();
      },

      selectHousehold(household) {
        this.set('loading', true);
        this.set('showPets', false);
        this.set('showAddresses', false);
        this.set('selectedHousehold', household);

        if (!household) {
          this.set('loading', false);
          return;
        }

        let numPets = household.get('pets.length');
        let numAddresses = household.get('owner.addresses.length');

        if (numPets == 0 || numAddresses == 0) {
          if (numPets == 0) {
            this.send('addPet');
            this.set('showPets', true);
          }

          if (numAddresses == 0) {
            let state = this.get('currentUser.user.company.state');
            household.get('owner.addresses').pushObject(this.store.createRecord('address', {
              state
            }));
            this.set('showAddresses', true);
          }

          this.set('showAppointmentModal', true);
        } else if (numPets == 1) {
          this.set('selectedPetValues', household.get('pets'));
          this.send('next');
        } else {
          this.set('loading', false);
          Ember.run.scheduleOnce('afterRender', () => {
            window.scrollTo(0, 0);
          });
        }
      },

      addPet() {
        let household = this.get('selectedHousehold');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.get('id')}/get_companies`,
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(response => {
          let pet = this.store.createRecord('pet');
          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },

      closeAppointmentInfoModal() {
        this.removeNewRecords.call(this);
        this.set('selectedHousehold', null);
        this.set('showAppointmentModal', false);
        this.set('loading', false);
      },

      saveHousehold() {
        let userPromise = this.get('selectedHousehold.owner').save();
        let petPromises = this.get('selectedHousehold.pets').map(pet => pet.save());
        let promises = [userPromise, ...petPromises];
        return Ember.RSVP.Promise.all(promises).then(() => {
          this.removeNewRecords.call(this);
          this.reloadDirtyRecords.call(this);
          this.send('selectHousehold', this.get('selectedHousehold'));
        });
      },

      clearHouseholdId() {
        this.set('selectedHouseholdId', null);
      },

      transitionToAddClient() {
        this.transitionToRoute('add-client.client-search');
      }

    }
  });

  _exports.default = _default;
});