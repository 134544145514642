define("frontend/components/late-notifications-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    notificationType: null,
    selectedTimingOption: computed('notificationType', 'arrivalTiming', 'checkoutTiming', function () {
      let options = this.get('timingOptions');
      let selectedValue = this.get('notificationType') === 'arrival' ? this.get('arrivalTiming') : this.get('checkoutTiming');
      let selected = options.filter(option => selectedValue == option.value);
      return selected.get('firstObject');
    }),
    exampleAlertTime: computed('notificationType', 'arrivalTiming', 'checkoutTiming', function () {
      let startTime = moment('9AM', 'hA');
      let selectedValue = this.get('notificationType') === 'arrival' ? this.get('arrivalTiming') : this.get('checkoutTiming');
      let alertTime = moment(startTime).add(selectedValue, 'minutes');

      if (this.get('notificationType') != 'arrival') {
        alertTime = alertTime.add(30, 'minutes');
      }

      return alertTime.format('h:mmA');
    }),
    headerIcon: computed('notificationType', function () {
      return this.get('notificationType') === 'arrival' ? "assets/images/modal-icons/icon-alarmclock.svg" : "assets/images/modal-icons/icon-timer.svg";
    }),
    actions: {
      cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
      },

      changeTiming(option) {
        if (this.get('notificationType') === 'arrival') {
          this.set('arrivalTiming', option.value);
        } else {
          this.set('checkoutTiming', option.value);
        }
      },

      save() {
        if (this.get('notificationType') === 'arrival') {
          this.get('serviceCategory').updateCheckInAlertTiming();
        } else {
          this.get('serviceCategory').updateCheckOutAlertTiming();
        }

        this.get('onClose')(false);
      }

    }
  });

  _exports.default = _default;
});