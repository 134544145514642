define("frontend/helpers/date-or-time-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;

  function formatDate(_ref) {
    let [date] = _ref;

    if (!date) {
      return;
    }

    let dateTime = (0, _moment.default)(date);
    let today = (0, _moment.default)().startOf('day');
    let yesterday = (0, _moment.default)().subtract(1, 'day').startOf('day');
    let startOfWeek = (0, _moment.default)().startOf('week');

    if (dateTime.isSameOrAfter(today)) {
      return dateTime.format('h:mma');
    } else if (dateTime.isSameOrAfter(yesterday)) {
      return `Yesterday ${dateTime.format('h:mma')}`;
    } else if (dateTime.isSameOrAfter(startOfWeek)) {
      return dateTime.format('dddd');
    } else {
      return dateTime.format('MMM D');
    }
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});