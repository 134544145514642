define("frontend/services/country-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Service.extend({
    countries: ['United States', 'Canada', 'Australia'],
    usStates: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
    defaultUsState: 'MA',
    caProvinces: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon'],
    defaultProvince: 'Alberta',
    auStates: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
    defaultAuState: 'NSW',
    currentUser: Ember.inject.service(),
    country: null,
    currentUserCountry: computed('currentUser.user.company', 'currentUser.user.company.country', function () {
      let country = this.get('currentUser.user.company.country');

      if (country) {
        return country;
      } else {
        return this.get('currentUser.user.client_accounts.firstObject.country');
      }
    }),
    states: computed('usStates.[]', 'caProvinces.[]', 'auStates.[]', 'country', 'currentUserCountry', function () {
      let country = this.get('country') || this.get('currentUserCountry');

      if (country === 'Canada') {
        return this.get('caProvinces');
      } else if (country === 'Australia') {
        return this.get('auStates');
      } else {
        return this.get('usStates');
      }
    }),
    statesAndProvinces: computed('usStates.[]', 'caProvinces.[]', 'auStates.[]', function () {
      return this.get('usStates').concat(this.get('caProvinces')).concat(this.get('auStates')).sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) {
          return 1;
        }

        return -1;
      });
    }),
    defaultState: computed('defaultUsState', 'defaultProvince', 'defaultAuState', 'country', 'currentUserCountry', function () {
      let country = this.get('country') || this.get('currentUserCountry');

      if (country === 'Canada') {
        return this.get('defaultProvince');
      } else if (country === 'Australia') {
        return this.get('defaultAuState');
      } else {
        return this.get('defaultUsState');
      }
    })
  });

  _exports.default = _default;
});