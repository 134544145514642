define("frontend/components/accept-company-request", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    company: null,
    household: null,
    buttonsDisabled: false,
    flashMessage: inject.service(),
    companyLogo: computed('company', function () {
      let logo = this.get('company.company_logo');

      if (logo) {
        return logo;
      } else {
        return '/assets/images/settings/company-placeholder.png';
      }
    }),

    didInsertElement() {
      this.set('buttonsDisabled', false);
    },

    actions: {
      confirmRequest() {
        this.set('buttonsDisabled', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/accept_request`,
          method: 'POST',
          data: {
            token: this.get('company.token')
          }
        }).then(() => {
          this.get('onSave')('accepted');
        }).fail(() => {
          this.set('buttonsDisabled', false);
        });
      },

      denyRequest() {
        this.set('buttonsDisabled', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/deny_request`,
          method: 'POST',
          data: {
            token: this.get('company.token'),
            id: this.get('household.id')
          }
        }).then(() => {
          this.get('flashMessage').sendFlashMessage(`Thanks for letting us know! We've alerted ${this.get('company.company_name')} that they got the wrong person`, 'success');
          this.get('onSave')('denied');
          this.set('buttonsDisabled', false);
        }).fail(() => {
          this.set('buttonsDisabled', false);
        });
      },

      onClose() {}

    }
  });

  _exports.default = _default;
});