define("frontend/controllers/staff/index", ["exports", "ember-data", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _emberData, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'switchEmailAddress': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['searchFilter', 'employeeFilter'],
    serachFilter: '',
    employeeFilter: 'all',
    startPay: null,
    endPay: null,
    compensationExportDisabled: false,
    flashMessage: inject.service(),
    staff: inject.controller(),
    showingStaffList: computed.alias('staff.showingStaffList'),
    headerText: computed.alias('staff.headerText'),
    hasSelectedEmployee: computed.alias('staff.hasSelectedEmployee'),
    apptComps: computed.mapBy('appointmentCompensations', 'compensation'),
    maxCompensation: computed.max('apptComps'),
    tipComps: computed.mapBy('tipCompensations', 'tip'),
    maxTip: computed.max('tipComps'),
    holidaySurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'holiday_compensation'),
    maxHolidaySurchargeFeeComp: computed.max('holidaySurchargeFeeComps'),
    weekendSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'weekend_compensation'),
    maxWeekendSurchargeFeeComp: computed.max('weekendSurchargeFeeComps'),
    offHoursSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'off_hours_compensation'),
    maxOffHoursSurchargeFeeComp: computed.max('offHoursSurchargeFeeComps'),
    lateBookingSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'late_booking_compensation'),
    maxLateBookingSurchargeFeeComp: computed.max('lateBookingSurchargeFeeComps'),
    cancellationSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'cancellation_compensation'),
    maxCancellationSurchargeFeeComp: computed.max('cancellationSurchargeFeeComps'),
    totalCompensations: computed.mapBy('compensations', 'totalCompensation'),
    maxTotalCompensation: computed.max('totalCompensations'),
    apptsCompleted: computed.mapBy('appointmentCompensations', 'num_visits'),
    maxApptsCompleted: computed.max('apptsCompleted'),
    // users: computed.mapBy('model', 'user'),
    // TODO: investigate, with new versions of ember data do we need this
    savedUsers: computed.filterBy('users', 'isNew', false),
    activeEmployees: computed.filterBy('model', 'deleted_at', null),
    numActiveStaff: computed.readOnly('savedUsers.length'),
    currentUser: inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    users: computed('activeEmployees', 'activeEmployees.@each.deactivated_at', function () {
      return this.get('activeEmployees').map(employee => {
        let user = employee.get('user');
        user.set('deactivated', employee.get('deactivated'));
        return user;
      });
    }),
    selectedEmployee: computed('employeeFilter', 'model.[]', function () {
      let employeeFilter = this.get('employeeFilter');
      let employees = this.get('model');

      if (employeeFilter && employees) {
        this.set('resendDisabled', false);
        let employee = employees.filter(employee => employee.get('user.id') === employeeFilter).get('firstObject');

        if (employee) {
          Ember.run.next(() => {
            if (!this.isDestroyed) {
              this.set('hasSelectedEmployee', true);
              let text = this.get('currentUser.user.isOwner') ? employee.get('user.fullName') : 'Payroll';
              this.set('headerText', text);
            }
          });
        }

        return employee;
      }

      return null;
    }),
    allAppointmentsCompleted: computed('appointmentCompensations.[]', function () {
      let ap = this.get('appointmentCompensations');
      return ap.reduce((a, b) => a + b.get('num_completed') - b.get('num_additional_dogs'), 0);
    }),
    allStaffCompensation: computed('allStaffApptCompensation', 'maxTip', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp', function () {
      let totalCompensation = this.get('allStaffApptCompensation');

      if (this.get('maxTip') && this.get('maxTip') > 0) {
        totalCompensation += this.get('maxTip');
      }

      ['maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp'].forEach(feeLabel => {
        if (this.get(feeLabel) && this.get(feeLabel) > 0) {
          totalCompensation += this.get(feeLabel);
        }
      });
      return totalCompensation;
    }),
    allStaffApptCompensation: computed('appointmentCompensations.[]', 'selectedEmployee.compensation_type', function () {
      let ap = this.get('appointmentCompensations');
      let totalCompensation = ap.reduce((a, b) => a + b.get('compensation') + b.get('additional_dog_compensation'), 0);

      if (this.get('selectedEmployee') !== 'all') {
        // Set the min amount to pay on staff profile pages
        if (this.get('selectedEmployee.min_amount_to_pay') > totalCompensation) {
          totalCompensation = this.get('selectedEmployee.min_amount_to_pay');
        } // Hack for salary compensation types


        if (this.get('selectedEmployee.compensation_type') === 'salary') {
          totalCompensation = ap.get('firstObject.compensation');
        }
      }

      return totalCompensation;
    }),
    maxSelectedStaffComp: computed('maxTip', 'maxCompensation', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp', function () {
      let max = this.get('maxCompensation');
      ['maxTip', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp'].forEach(compLabel => {
        if (this.get(compLabel) > max) {
          max = this.get(compLabel);
        }
      });
      return max;
    }),
    // TODO Move this to route`
    appointmentCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      let start_pay = this.get('startPay');
      let end_pay = this.get('endPay');
      let user_id = this.get('employeeFilter');
      let promise;

      if (!start_pay || !end_pay) {
        return [];
      }

      if (user_id === 'all') {
        promise = this.store.query('appointment-compensation', {
          start_pay,
          end_pay,
          api_version: 2
        }).then(aptCompensations => {
          // Set the min amount to pay on the staff index page
          aptCompensations.forEach(aptCompensation => {
            let minPay = aptCompensation.get('user.min_amount_to_pay');

            if (minPay && minPay > aptCompensation.get('compensation') + 0.0) {
              aptCompensation.set('compensation', parseFloat(minPay));
            }
          });
          return aptCompensations;
        });
      } else {
        promise = this.store.query('appointment-compensation', {
          start_pay,
          end_pay,
          user_id,
          api_version: 2
        });
      }

      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    surchargeFeeCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      let start_pay = this.get('startPay');
      let end_pay = this.get('endPay');
      let user_id = this.get('employeeFilter');

      if (!start_pay || !end_pay) {
        return 0;
      }

      let promise = this.store.query('surcharge-fee-compensation', {
        start_pay,
        end_pay,
        user_id
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    allStaffSurchargeFeeCompensation: computed('surchargeFeeCompensations.[]', function () {
      return this.get('surchargeFeeCompensations').reduce((a, b) => a + b.get('totalCompensation'), 0);
    }),
    allStaffTips: computed('tipCompensations.[]', function () {
      return this.get('tipCompensations').reduce((a, b) => a + b.get('tip'), 0);
    }),
    tipCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      let start_pay = this.get('startPay');
      let end_pay = this.get('endPay');
      let user_id = this.get('employeeFilter');

      if (!start_pay || !end_pay) {
        return 0;
      }

      let promise = this.store.query('tip-compensation', {
        start_pay,
        end_pay,
        user_id
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    unallocatedTips: computed('employeeFilter', function () {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/tips/unallocated`,
        method: 'GET'
      }).done(resp => {
        this.set('unallocatedTips', resp.amount);
      });
    }),
    showUnallocatedTips: Ember.computed('unallocatedTips', 'startPay', 'endPay', function () {
      let startDate = this.get('startPay');
      let endDate = this.get('endPay');
      let unallocated = this.get('unallocatedTips');

      if (startDate && endDate && unallocated) {
        let now = moment();

        if (moment(startDate, 'YYYY-MM-DD') < now && moment(endDate, 'YYYY-MM-DD') > now) {
          if (unallocated > 0) {
            return true;
          }
        }
      }

      return false;
    }),
    showNoAppointments: computed('appointmentCompensations.[]', 'tipCompensations.[]', 'surchargeFeeCompensations.[]', function () {
      return this.get('appointmentCompensations.length') == 0 && this.get('tipCompensations.length') == 0 && this.get('surchargeFeeCompensations.length') == 0;
    }),
    compensations: computed('appointmentCompensations.[]', 'tipCompensations.[]', 'surchargeFeeCompensations.[]', function () {
      let compensations = {};
      this.get('appointmentCompensations').forEach(apptComp => {
        let employeeId = apptComp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].apptComp = apptComp;
          compensations[employeeId].totalCompensation += apptComp.get('compensation');
          compensations[employeeId].totalApptComp += apptComp.get('compensation');
        } else {
          compensations[employeeId] = {
            employee: apptComp.get('employee'),
            fullName: apptComp.get('employee_name'),
            totalCompensation: apptComp.get('compensation'),
            totalApptComp: apptComp.get('compensation'),
            apptComp
          };
        }
      });
      this.get('tipCompensations').forEach(tipComp => {
        let employeeId = tipComp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].tipComp = tipComp;
          compensations[employeeId].totalCompensation += tipComp.get('tip');
        } else {
          compensations[employeeId] = {
            employee: tipComp.get('employee'),
            fullName: tipComp.get('employee_name'),
            totalCompensation: tipComp.get('tip'),
            tipComp
          };
        }
      });
      this.get('surchargeFeeCompensations').forEach(comp => {
        let employeeId = comp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].surchargeFeeComp = comp;
          compensations[employeeId].totalCompensation += comp.get('totalCompensation');
          compensations[employeeId].totalApptComp += comp.get('totalCompensation');
        } else {
          compensations[employeeId] = {
            employee: comp.get('employee'),
            fullName: comp.get('employee_name'),
            totalCompensation: comp.get('totalCompensation'),
            totalApptComp: comp.get('totalCompensation'),
            surchargeFeeComp: comp
          };
        }
      });
      return $.map(compensations, v => v).sort(this.sortCompensations);
    }),

    sortCompensations(a, b) {
      let fullNameA = a.fullName.toLowerCase();
      let fullNameB = b.fullName.toLowerCase();

      if (fullNameA < fullNameB) {
        return -1;
      } else if (fullNameA > fullNameB) {
        return 1;
      } else {
        return 0;
      }
    },

    actions: {
      addEmployee() {
        this.transitionToRoute('staff-search');
      },

      clickStaff(staff) {
        this.set('employeeFilter', staff.get('id'));
        this.set('showingStaffList', false);
        this.set('compensationExportDisabled', false);
      },

      staffListLoaded(allStaff) {
        this.set('allStaff', allStaff);
      },

      editStaff(staff) {
        this.transitionToRoute('edit-staff', staff);
      },

      transitionToRemoveStaff(staff) {
        this.transitionToRoute('companies.remove-staff', this.get('currentUser.user.company.id'), staff.id);
      },

      goToSchedulePage(care_provider_id) {
        this.transitionToRoute('schedule', {
          queryParams: {
            care_provider_id
          }
        });
      },

      changePayPeriod(startDate, endDate) {
        this.set('startPay', startDate);
        this.set('endPay', endDate);
        this.set('compensationExportDisabled', false);
      },

      requestHousehold(email) {
        this.set('resendDisabled', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/employees/add_company`,
          method: 'POST',
          data: {
            email
          }
        }).done(() => {
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: `Your request to connect was sent to ${email}`,
            type: 'success'
          });
        });
      },

      clickReactivate() {
        this.set('showReactivateFlash', true);
      },

      hideReactivate() {
        this.set('showReactivateFlash', false);
      },

      confirmReactivate() {
        this.set('showReactivateFlash', false);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/employees/${this.get('selectedEmployee.id')}/reactivate`,
          method: 'POST'
        }).done(() => {
          this.get('flashMessage').sendFlashMessage(`You’ve successfully reactivated ${this.get('selectedEmployee.user.getFirstName')}'s account`, 'success-love');
          this.set('selectedEmployee.deactivated_at', null);
        });
      },

      exportData() {
        let employeeId = this.get('selectedEmployee.id') || 'all';
        this.set('compensationExportDisabled', true);

        _jquery.default.ajax({
          url: `/api/v2/employee_compensation?employee_id=${employeeId}&start=${this.get('startPay')}&end=${this.get('endPay')}`,
          method: 'GET'
        }).done(response => {
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: `This may take a few minutes. Your data export will be sent to ${response.email}`,
            type: 'success'
          });
        });
      },

      switchEmail() {
        if (this.get('validations.isValid')) {
          this.set('emailError', false);
          let email = this.get('switchEmailAddress');
          let employeeId = this.get('selectedEmployee.id');

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/employees/${employeeId}/switch_email`,
            method: 'POST',
            data: {
              email,
              id: employeeId
            }
          }).done(response => {
            if (response.already_staff) {
              this.set('staffDuplicate', true);
            } else {
              this.send('refresh');
              this.set('switchEmailAddress', null);
              this.set('emailError', false);
              this.set('staffDuplicate', false);
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: `Your request to connect was sent to ${email}`,
                type: 'success'
              });
              this.transitionToRoute('staff', {
                queryParams: {
                  employeeFilter: response.staff_id
                }
              }).then(() => {
                window.location.reload();
              });
            }
          });
        } else {
          this.set('emailError', true);
        }
      }

    }
  });

  _exports.default = _default;
});