define("frontend/components/send-documents-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    company: null,
    household: null,
    careProviderAddedNewClient: null,
    clientContracts: null,
    leftButtonText: computed('careProviderAddedNewClient', function () {
      return this.get('careProviderAddedNewClient') ? 'skip' : 'cancel';
    }),
    clientContracts: computed('company.client_contracts.[]', 'signatureRequests.[]', 'careProviderAddedNewClient', function () {
      let signatureRequests = this.get('signatureRequests');
      let careProviderAddedNewClient = this.get('careProviderAddedNewClient');
      let clientContracts = this.get('company.client_contracts').map(contract => {
        return Ember.Object.create({
          id: contract.get('id'),
          document_name: contract.get('document_name'),
          created_at: contract.get('created_at'),
          selected: careProviderAddedNewClient ? true : false,
          sent: signatureRequests.isAny('client_contract_id', parseInt(contract.get('id')))
        });
      });
      let numChoices = clientContracts.filterBy('sent', false).length;

      if (numChoices === 1) {
        clientContracts.filterBy('sent', false).firstObject.set('selected', true);
      }

      return clientContracts;
    }),
    description: computed('clientContracts.length', 'household.owner.last_name', 'careProviderAddedNewClient', function () {
      let clientContractsLength = this.get('clientContracts.length');
      let lastName = this.get('household.owner.last_name');
      let careProviderAddedNewClient = this.get('careProviderAddedNewClient');

      if (careProviderAddedNewClient) {
        return `Choose documents that you’d like to send to the ${lastName} family with your invite. All templates are selected by default.`;
      } else if (clientContractsLength === 1) {
        return `Are you sure you want to send the below document to the ${lastName} family?`;
      } else {
        return `Select one or more documents that you’d like to send to the ${lastName} family.`;
      }
    }),
    canSendDocuments: computed('clientContracts.@each.selected', function () {
      return this.get('clientContracts').isAny('selected', true);
    }),
    selectedContractIds: computed('clientContracts.@each.selected', function () {
      return this.get('clientContracts').filterBy('selected').mapBy('id');
    })
  });

  _exports.default = _default;
});