define("frontend/routes/admin/companies", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/companies`,
        method: 'GET'
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('filter', '');
    }

  });

  _exports.default = _default;
});