define("frontend/helpers/capitalize-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeText = capitalizeText;
  _exports.default = void 0;

  function capitalizeText(_ref) {
    let [st] = _ref;

    if (st === '' || st == null) {
      return '';
    }

    return st.capitalize();
  }

  var _default = Ember.Helper.helper(capitalizeText);

  _exports.default = _default;
});