define("frontend/components/request-or-approve-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    appointmentCount: 0,
    approveText: Ember.computed('appointmentCount', function () {
      return this.get('appointmentCount') > 1 ? 'approve appointments' : 'approve appointment';
    }),
    actions: {
      requestAppointments() {
        this.get('requestAppointments')();
      },

      approveAppointments() {
        this.get('approveAppointments')();
      }

    }
  });

  _exports.default = _default;
});