define("frontend/controllers/add-appointment", ["exports", "frontend/mixins/add-appointment", "moment"], function (_exports, _addAppointment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend(_addAppointment.default, {
    media: inject.service(),
    router: inject.service(),
    saving: computed.readOnly('addAppointmentData.saving'),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    queryParams: ['date', 'backRoute', 'backHouseholdId', 'backId', 'defaultCareProviderId', 'defaultTime', 'preselectDate'],
    date: (0, _moment.default)().format('YYYY-MM-DD'),
    selectedOneTimeDates: computed.alias('addAppointmentData.selectedOneTimeDates'),
    backRoute: computed.alias('addAppointmentData.backRoute'),
    backHouseholdId: null,
    defaultCareProviderId: computed.alias('addAppointmentData.defaultCareProviderId'),
    defaultTime: computed.alias('addAppointmentData.defaultTime'),
    preselectDate: false,
    fillTime: null,
    timesController: inject.controller('add-appointment.times'),
    timesSidePanelOpen: computed.readOnly('timesController.sidePanelOpen'),
    hasClose: computed('media.isMobile', 'saving', 'currentRouteName', 'timesSidePanelOpen', function () {
      let isNotMobile = !this.get('media.isMobile');
      let notSaving = !this.get('saving');
      let shouldShow = isNotMobile && notSaving;

      if (this.get('currentRouteName') == 'add-appointment.times') {
        return shouldShow && !this.get('timesSidePanelOpen');
      } else {
        return shouldShow;
      }
    })
  });

  _exports.default = _default;
});