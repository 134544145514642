define("frontend/mixins/admin/approvable-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Mixin.create({
    filter: '',
    sortBy: null,
    sortAsc: true,
    ajax: inject.service(),
    infinity: inject.service(),
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modelOptions: {},
    totalUnapproved: null,

    submitReplace(url, replace, old) {
      this.set('editingReplace', null);
      this.set('savedReplace', null);
      this.get('ajax').request(url, {
        method: 'PUT',
        data: {
          new_id: replace.get('id')
        }
      }).then(() => {
        old.deleteRecord();
      }).catch(() => {
        this.get('flashMessage').error('Unable to replace!');
      });
    },

    filteredRecords: computed('filter', function () {
      let options = {
        perPage: 25,
        filter: this.get('filter')
      };
      options = { ...options,
        ...this.get('modelOptions')
      };
      const records = this.get('infinity').model(this.get('modelName'), options);
      records.then(res => {
        const unapprovedCount = res.meta.total_unapproved;
        if (unapprovedCount) this.set('totalUnapproved', ` - ${unapprovedCount} unapproved`);
      });
      return records;
    }),
    sortedRecords: computed.sort('filteredRecords', ['filteredRecords.[]', 'sortBy', 'sortAsc'], function (x, y) {
      if (this.sortBy) {
        let first, second;

        if (this.sortBy == 'vet_names') {
          first = x.get(this.sortBy).map(vet => vet.toLowerCase());
          second = y.get(this.sortBy).map(vet => vet.toLowerCase());
        } else {
          first = x.get(this.sortBy);

          if (first) {
            first = first.toLowerCase();
          }

          second = y.get(this.sortBy);

          if (second) {
            second = second.toLowerCase();
          }
        }

        if (this.sortAsc) {
          return first < second ? -1 : 1;
        } else {
          return first < second ? 1 : -1;
        }
      } else {
        return 0;
      }
    }),
    approvedRecords: computed('modelName', function () {
      return this.get('store').query(this.get('modelName'), {});
    }),
    actions: {
      approve(record) {
        record.set('approved_at', moment());
        record.save();
      },

      search(e) {
        Ember.run.debounce(() => {
          this.set('filter', e.target.value);
        }, 10);
      },

      onCloseReplace() {
        this.set('savedReplace', null);
        this.set('editingReplace', null);
      },

      unapprove(record) {
        record.set('approved_at', null);
        record.save();
      },

      save(record) {
        record.set('editing', false);
        record.save().catch(() => {
          this.get('flashMessage').error('Unable to save!');
        });
      },

      remove(record) {
        record.destroyRecord().then(() => {
          this.get('flashMessage').success(`You've removed ${record.get('name')}`);
        });
      },

      rollback(record) {
        record.set('editing', false);
        record.rollbackAttributes();
      },

      sort(key) {
        if (this.sortBy == key) {
          this.toggleProperty('sortAsc');
        } else {
          this.set('sortBy', key);
          this.set('sortAsc', true);
        }
      }

    }
  });

  _exports.default = _default;
});