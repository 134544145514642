define("frontend/components/add-client/pet-inputs", ["exports", "frontend/utils/scroll-util", "frontend/models/pet", "frontend/mixins/autocomplete/highlight-first-match", "ember-data", "frontend/utils/email-type"], function (_exports, _scrollUtil, _pet, _highlightFirstMatch, _emberData, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;
  const HEADER_HEIGHT = 84;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    router: inject.service(),
    classNames: ['inputs-container'],
    ajax: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    pet: null,
    vetFilter: null,
    genderOptions: ['male', 'female'],
    currentSection: 'basic',
    onBasic: computed.equal('currentSection', 'basic'),
    onMedical: computed.equal('currentSection', 'medical'),
    onBehavioral: computed.equal('currentSection', 'behavioral'),
    onNotes: computed.equal('currentSection', 'notes'),
    isDog: computed.equal('pet.pet_type', 'Dog'),
    isCat: computed.equal('pet.pet_type', 'Cat'),
    isHorse: computed.equal('pet.pet_type', 'Horse'),
    dogOrCat: computed.or('isDog', 'isCat'),
    showBreed: computed.or('dogOrCat', 'isHorse'),
    hasEmergencyContacts: computed.gt('pet.emergency_contacts.length', 0),
    hasPrescriptions: computed.gt('pet.pet_prescriptions.length', 0),
    pronoun: computed('pet.gender', function () {
      if (this.get('pet.gender') == 'male') {
        return 'he';
      }

      return 'she';
    }),
    clarificationNotes: computed('pet.name', function () {
      let pronoun = this.get('pronoun');
      let name = this.get('pet.name');
      return {
        gets_along_with_extra_notes: `If there is any type of human or animal that ${name} doesn’t get along with, let us know!`,
        phobias: `Is ${name} afraid of loud noises? Or spiders? Does ${pronoun} have separation anxiety or is ${pronoun} overly protective?`,
        potty_habits: `Does ${name} only poop downhill? Will ${pronoun} not pee if it’s raining out?`,
        diet: `What do you feed ${name}? How much and when? Is ${pronoun} allergic to chicken?`,
        house_rules: `Does ${name} need to be crated? Is ${pronoun} allowed on furniture?`,
        off_leash_notes: `How does ${name} behave off leash? How is their recall?`,
        commands: `Sit. Down. Play dead! What does ${name} know and what is ${pronoun} learning?`,
        translations: `Does ${name} paw at the door when ${pronoun} needs to go potty? What else can ${pronoun} communicate?`,
        behavioral_extra_notes: `What else should we know about ${name}?`,
        client_notes: 'If you have instructions for your walkers that generally apply for all appointments, add them here!',
        internal_notes: 'This will be visible only to you and your staff!'
      };
    }),
    getsAlongWithOptions: ['Yes', 'Sometimes', 'No', 'Not Sure'],
    statusOptions: computed('pet.gender', function () {
      if (!this.get('pet.gender')) {
        return [];
      } else {
        return _pet.STATUS[this.get('pet.gender')];
      }
    }),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    store: inject.service(),

    init() {
      this._super(...arguments);

      let url = '/api/v2/pet_types';
      this.get('ajax').request(url).then(response => {
        this.set('petTypeOptions', response);
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.handleQueryParam();
    },

    handleQueryParam() {
      let currentURL = this.get('router.currentURL');
      let queryParams = new URLSearchParams(currentURL.split('?')[1]);
      let editSection = queryParams.get('editSection');

      if (editSection) {
        this.send('scrollTo', `#${editSection}`);
      }
    },

    selectedStatus: computed('pet.spayed_or_neutered', 'statusOptions.[]', function () {
      return this.get('statusOptions').filterBy('value', this.get('pet.spayed_or_neutered'))[0];
    }),
    breedOptions: computed('pet.pet_type_id', function () {
      let pet_type = this.get('pet.pet_type_id');

      if (pet_type == 0) {
        pet_type = 1;
      }

      return this.get('store').query('breed', {
        pet_type
      });
    }),
    fakeVetOptions: [{
      name: 'Loading'
    }],
    vetOptions: computed('vetFilter', function () {
      const filter = this.get('vetFilter');
      let promise = this.get('store').query('veterinarian-clinic', {
        filter
      }, {
        reload: true
      }).then(clinics => {
        let mappedClinics = clinics.map(vet => {
          return {
            vet
          };
        });

        if (this.get('vetFilter')) {
          mappedClinics.pushObject({
            name: 'Create a new vet?'
          });
        } else {
          mappedClinics.pushObject({
            name: 'Can’t find your vet? Try searching'
          });
        }

        return mappedClinics;
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    insuranceOptions: computed(function () {
      let promise = this.get('store').query('insurance', {}, {
        reload: true
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    emergencyContactOptions: computed('pet.household.emergency_contacts.[]', 'pet.emergency_contacts.[]', function () {
      let currentEcIds = this.get('pet.emergency_contacts').map(ec => ec.get('id'));
      return this.get('pet.household.emergency_contacts').filter(ec => !currentEcIds.includes(ec.get('id')));
    }),
    selectedBreed: computed('breedOptions.[]', 'pet.breed.id', function () {
      return this.get('breedOptions').filterBy('id', this.get('pet.breed.id'));
    }),
    selectedPetType: computed('pet.{pet_type,pet_type_id}', function () {
      return {
        name: this.get('pet.pet_type'),
        id: this.get('pet.pet_type_id')
      };
    }),
    breedClasses: computed('notFoundBreed', function () {
      let dropdownClasses = ['breed-autocomplete'];

      if (this.get('notFoundBreed')) {
        dropdownClasses.push('not-found-breed');
      }

      return dropdownClasses.join(' ');
    }),

    newEmergencyContact(fullName) {
      if (!fullName) {
        fullName = ' ';
      }

      let parts = fullName.split(' ');
      let [first_name] = parts;
      let last_name = undefined;

      if (parts.length > 1) {
        last_name = parts[parts.length - 1];
      }

      let pet = this.get('pet');
      let household = this.get('pet.household');
      let emergencyContact = this.get('store').createRecord('emergency-contact', {
        first_name,
        last_name,
        pet,
        household
      });
      let email = this.get('store').createRecord('emergency-contact-email', {
        email_type: _emailType.default.PREFERRED
      });
      emergencyContact.get('emails').pushObject(email);
      let phone = this.get('store').createRecord('emergency-contact-phone-number');
      emergencyContact.get('phone_numbers').pushObject(phone);
      let address = this.get('store').createRecord('emergency-contact-address');
      emergencyContact.get('addresses').pushObject(address);
      return emergencyContact;
    },

    setVetSearchFilter(args) {
      this.set('vetFilter', args.term);
    },

    actions: {
      scroll() {
        if (_scrollUtil.default.isScrolledIntoView('#basic', HEADER_HEIGHT)) {
          this.set('currentSection', 'basic');
        } else if (_scrollUtil.default.isScrolledIntoView('#behavioral', HEADER_HEIGHT)) {
          this.set('currentSection', 'behavioral');
        } else if (_scrollUtil.default.isScrolledIntoView('#medical', HEADER_HEIGHT)) {
          this.set('currentSection', 'medical');
        } else if (_scrollUtil.default.isScrolledIntoView('#notes', HEADER_HEIGHT)) {
          this.set('currentSection', 'notes');
        }
      },

      submit() {
        if (this.get('pet.breed.id') == null && !Ember.isEmpty(this.get('breedName')) && this.get('dogOrCat')) {
          let matchExistingBreed = this.get('breedOptions').filter(breed => breed.get('name').toLowerCase() === this.get('breedName').toLowerCase()).get('firstObject');

          if (matchExistingBreed) {
            this.set('pet.breed', matchExistingBreed);
            this.get('onSubmit')();
            return;
          }

          let name = this.get('breedName');
          let pet_type_id = this.get('pet.pet_type_id');
          let breed = this.get('store').createRecord('breed', {
            name,
            pet_type_id
          });
          this.set('pet.breed', breed);
          breed.save().then(() => {
            this.get('onSubmit')();
          });
        } else {
          this.get('onSubmit')();
        }
      },

      changeVetClinic(option) {
        this.set('pet.doctor', null);

        if (option == null) {
          this.set('pet.veterinarian_clinic', null);
        } else if (option.vet) {
          this.set('pet.veterinarian_clinic', option.vet);
        } else if (option.name.includes('Create')) {
          this.send('createVet', this.get('vetTerm'));
        }
      },

      createDoctor(name) {
        let nameParts = name.split(' ');
        let [first_name] = nameParts;
        let last_name = null;
        let veterinarian_clinic = this.get('pet.veterinarian_clinic');

        if (nameParts.length > 1) {
          last_name = nameParts[nameParts.length - 1];
        }

        let editingDoctor = this.get('store').createRecord('doctor', {
          first_name,
          last_name,
          veterinarian_clinic
        });
        veterinarian_clinic.notifyPropertyChange('doctors');
        this.set('editingDoctor', editingDoctor);
      },

      changePetStatus(status) {
        this.set('pet.spayed_or_neutered', status.value);
      },

      searchVets(term) {
        Ember.run.debounce(this, this.setVetSearchFilter, {
          term
        }, 500);
      },

      searchBreeds(term) {
        this.set('breedName', term);
        let pet_type = this.get('pet.pet_type_id');

        if (pet_type == 0) {
          pet_type = 1;
        }

        return this.get('store').query('breed', {
          pet_type,
          filter: term
        });
      },

      scrollTo(selector) {
        const pageWrapValue = $('.page-wrap').scrollTop();
        const selectorValue = $(selector).offset() ? $(selector).offset().top : 0;
        let topOfElement = pageWrapValue + selectorValue - HEADER_HEIGHT;
        document.querySelector('.page-wrap').scrollTo({
          top: topOfElement
        });
      },

      createVet(name) {
        let editingVet = this.get('store').createRecord('veterinarian-clinic', {
          name
        });
        this.notifyPropertyChange('vetOptions');
        this.set('editingVet', editingVet);
        this.set('showVetModal', false);
      },

      saveVet(vet) {
        vet.save();
        this.set('pet.veterinarian_clinic', vet);
        this.set('editingVet', null);
      },

      setVetForPet(vet) {
        this.set('pet.veterinarian_clinic', vet);
        this.set('showVetModal', false);
      },

      createInsurance(name) {
        let editingInsurance = this.get('store').createRecord('insurance', {
          name
        });
        this.notifyPropertyChange('insuranceOptions');
        this.set('editingInsurance', editingInsurance);
      },

      saveInsurance(insurance) {
        let existingInsurance = this.get('insuranceOptions.content').filter(loadedInsurance => {
          if (loadedInsurance.insurance && insurance.get('name').toLowerCase() === loadedInsurance.insurance.get('name').toLowerCase()) {
            return loadedInsurance.insurance;
          }
        }).get('firstObject');

        if (existingInsurance) {
          this.set('pet.insurance', existingInsurance);
          this.set('editingInsurance', null);
          return;
        }

        insurance.save();
        this.set('pet.insurance', insurance);
        this.set('editingInsurance', null);
      },

      addEmergencyContact(emergencyContact) {
        if (emergencyContact.get) {
          this.get('pet.emergency_contacts').pushObject(emergencyContact);
        } else {
          this.set('editingEmergencyContact', this.newEmergencyContact(emergencyContact.fullName));
        }
      },

      removeEmergencyContact(emergencyContact) {
        this.get('pet.emergency_contacts').removeObject(emergencyContact);
      },

      rollbackEmergencyContact() {
        this.get('editingEmergencyContact').rollbackAttributes();
        this.get('editingEmergencyContact.emails').forEach(email => {
          email.rollbackAttributes();
        });
        this.get('editingEmergencyContact.phone_numbers').forEach(number => {
          number.rollbackAttributes();
        });
        this.get('editingEmergencyContact.addresses').forEach(address => {
          address.rollbackAttributes();
        });
        this.set('editingEmergencyContact', null);
      },

      editEmergencyContact(emergencyContact) {
        if (emergencyContact.get('emails.length') == 0) {
          let email = this.get('store').createRecord('emergency-contact-email', {
            email_type: _emailType.default.PREFERRED
          });
          emergencyContact.get('emails').pushObject(email);
        }

        if (emergencyContact.get('phone_numbers.length') == 0) {
          let phone = this.get('store').createRecord('emergency-contact-phone-number');
          emergencyContact.get('phone_numbers').pushObject(phone);
        }

        if (emergencyContact.get('addresses.length') == 0) {
          let address = this.get('store').createRecord('emergency-contact-address');
          emergencyContact.get('addresses').pushObject(address);
        }

        this.set('editingEmergencyContact', emergencyContact);
      },

      saveEmergencyContact(emergencyContact) {
        this.set('editingEmergencyContact', null);
        this.get('pet.emergency_contacts').pushObject(emergencyContact);
      },

      addPrescription(prescription) {
        let newPrescription = this.get('store').createRecord('pet-prescription', {
          name: prescription.name
        });
        this.set('editingPrescription', newPrescription);
      },

      rollbackPrescription() {
        this.get('editingPrescription').rollbackAttributes();
        this.set('editingPrescription', null);
      },

      savePrescription(prescription) {
        this.set('editingPrescription', null);
        this.get('pet.pet_prescriptions').pushObject(prescription);
      },

      removePrescription(prescription) {
        this.get('pet.pet_prescriptions').removeObject(prescription);
      },

      editPrescriptions(prescription) {
        this.set('editingPrescription', prescription);
      },

      rollbackVet() {
        this.get('editingVet').rollbackAttributes();
        this.set('editingVet', null);
      },

      rollbackInsurance() {
        this.get('editingInsurance').rollbackAttributes();
        this.set('editingInsurance', null);
      },

      saveDoctor(doctor) {
        doctor.save();
        let veterinarian_clinic = this.get('pet.veterinarian_clinic');
        veterinarian_clinic.notifyPropertyChange('doctors');
        this.set('pet.doctor', doctor);
        this.set('editingDoctor', null);
      },

      rollbackDoctor() {
        this.get('editingDoctor').rollbackAttributes();
        this.set('editingDoctor', null);
      },

      changePetType(petType) {
        if (petType.id === this.get('pet.pet_type_id')) {
          return;
        } // This should get added back in and or changed when we add in the tax
        // exempt toggle for non-dog pet types
        // if (petType.name != 'Dog') {
        //   this.set('pet.service_animal', false);
        // }


        this.set('pet.pet_type', petType.name);
        this.set('pet.pet_type_id', petType.id);
        this.set('pet.breed', null);
      },

      changeGender(gender) {
        if (_pet.STATUS[gender].indexOf(this.get('pet.status')) === -1) {
          this.set('pet.status', null);
        }

        this.set('pet.gender', gender);
      },

      changeGetsAlongWithDogs(option) {
        this.set('pet.gets_along_with_dogs', option);
      },

      changeGetsAlongWithCats(option) {
        this.set('pet.gets_along_with_cats', option);
      },

      changeGetsAlongWithHumans(option) {
        this.set('pet.gets_along_with_humans', option);
      },

      onInvalid() {
        if ($('.name-input input').hasClass('ng-invalid')) {
          this.send('scrollTo', '.name-input');
        } else {
          this.send('scrollTo', '.gender-input');
        }
      }

    }
  });

  _exports.default = _default;
});