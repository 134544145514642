define("frontend/components/payment-method-modal/add-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['payment-method-modal'],
    saving: false,
    flashMessage: inject.service(),
    actions: {
      setCard(card, stripe) {
        this.setProperties({
          card,
          stripe
        });
      },

      saveCard() {
        this.set('saving', true);
        this.get('stripe').createToken(this.get('card')).then(_ref => {
          let {
            token,
            error
          } = _ref;

          if (error) {
            this.get('flashMessage').error(error.message);
            return;
          }

          this.get('saveCard')(token);
        }).finally(() => {
          if (!this.get('isDestroyed')) {
            this.set('saving', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});