define("frontend/controllers/add-appointment/details", ["exports", "moment", "frontend/utils/generate-dates", "frontend/mixins/add-appointment"], function (_exports, _moment, _generateDates, _addAppointment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Controller.extend(_addAppointment.default, {
    // Data set upstream
    selectedPetValues: computed.readOnly('addAppointmentData.selectedPetValues'),
    // Data edited in this controller
    startDate: computed.alias('addAppointmentData.startDate'),
    endDate: computed.alias('addAppointmentData.endDate'),
    selectedRepeatOption: computed.alias('addAppointmentData.selectedRepeatOption'),
    selectedOneTimeDates: computed.alias('addAppointmentData.selectedOneTimeDates'),
    selectedRepeatWeekDays: computed.alias('addAppointmentData.selectedRepeatWeekDays'),
    selectedEndDateOption: computed.alias('addAppointmentData.selectedEndDateOption'),
    // Specific to this controller CPs
    hasSelectedRepeatOption: computed.notEmpty('selectedRepeatOption'),
    endDateEndsOnDate: computed.equal('selectedEndDateOption', 'date'),
    showWeekSelect: computed.or('repeatsWeekly', 'repeatsBiweekly'),
    hideWeekSelect: computed.not('showWeekSelect'),
    startSidePanelOpen: false,
    endSidePanelOpen: false,
    anySidePanelOpen: computed.or('startSidePanelOpen'),
    showErrorFlash: false,
    displayErrorFlash: computed.and('showErrorFlash', 'nextDisabled'),
    endDateOptions: computed('endDate', 'endDateEndsOnDate', function () {
      let endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD').format('dddd, MMM D, YYYY');

      if (this.get('endDateEndsOnDate')) {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: endDate
        }];
      } else {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: 'On date'
        }];
      }
    }),
    repeatsOptions: [{
      value: 'never',
      label: 'Never'
    }, {
      value: 'weekdays',
      label: 'Weekdays (M-F)'
    }, {
      value: 'daily',
      label: 'Daily (M-Su)'
    }, {
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'biweekly',
      label: 'Every other week'
    }, {
      value: 'monthly',
      label: 'Monthly'
    }],
    weekDays: computed(function () {
      let start = (0, _moment.default)().startOf('week').add(1, 'day');
      let end = (0, _moment.default)().endOf('week').add(1, 'day');

      if (this.get('startDate')) {
        start = (0, _moment.default)(this.get('startDate')).startOf('week').add(1, 'day');
        end = (0, _moment.default)(this.get('startDate')).endOf('week').add(1, 'day');
      }

      let days = (0, _generateDates.default)(start, end);
      return days;
    }),
    nextDisabled: computed('selectedRepeatWeekDays.[]', 'selectedOneTimeDates.[]', 'showWeekSelect', 'repeatsNever', 'selectedRepeatOption', 'repeatsDailyOrWeekDays', 'repeatsMonthly', function () {
      if (this.get('showWeekSelect') && this.get('selectedRepeatWeekDays.length') > 0) {
        return false;
      }

      if (this.get('repeatsNever') && this.get('selectedOneTimeDates.length') > 0) {
        return false;
      }

      if (this.get('repeatsDailyOrWeekDays')) {
        return false;
      }

      if (this.get('repeatsMonthly')) {
        return false;
      }

      return true;
    }),

    equalArrayLengths(a1, a2) {
      return a1.get('length') === a2.get('length');
    },

    actions: {
      changeDates(dates) {
        let selectedDates = this.get('selectedOneTimeDates');

        if (!this.equalArrayLengths(selectedDates, dates)) {
          this.set('selectedOneTimeDates', dates);
        }
      },

      clickDay(day) {
        if (this.get('selectedRepeatWeekDays').includes(day)) {
          this.get('selectedRepeatWeekDays').removeObject(day);
        } else {
          this.get('selectedRepeatWeekDays').pushObject(day);
        }
      },

      changeStartDate(date) {
        this.send('closeStartSidePanel');
        let startDate = (0, _moment.default)(date).format('YYYY-MM-DD');
        this.set('startDate', startDate);
        let start = (0, _moment.default)(startDate).startOf('week').add(1, 'day');
        let end = (0, _moment.default)(startDate).endOf('week').add(1, 'day');
        let days = (0, _generateDates.default)(start, end);
        this.set('weekDays', days);
        let oldDates = this.get('selectedRepeatWeekDays');
        this.set('selectedRepeatWeekDays', []);
        oldDates.forEach(oldDay => {
          days.forEach(newDay => {
            if ((0, _moment.default)(oldDay).day() == (0, _moment.default)(newDay).day()) {
              this.send('clickDay', newDay);
            }
          });
        });
      },

      changeEndDate(date) {
        this.send('closeEndSidePanel');
        this.set('endDate', (0, _moment.default)(date).format('YYYY-MM-DD'));
      },

      closeStartSidePanel() {
        this.set('startSidePanelOpen', false);
      },

      openStartSidePanel() {
        this.set('startSidePanelOpen', true);
      },

      selectEndDate(value) {
        let onDateSelected = value === 'date';
        this.set('endSidePanelOpen', onDateSelected);
      },

      closeEndSidePanel() {
        this.set('endSidePanelOpen', false);
      },

      openEndSidePanel() {
        this.set('endSidePanelOpen', true);
      }

    }
  });

  _exports.default = _default;
});