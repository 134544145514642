define("frontend/components/appointment-requests-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-requests-list'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    appointmentRequests: [],
    totalRequestCount: null,
    per_page: 10,
    upcomingPage: 1,
    pastPage: 1,
    loadedOnce: false,
    upcomingReachedInfinity: false,
    pastReachedInfinity: false,
    upcomingCount: 0,
    pastCount: 0,
    household: null,
    isPetParent: false,
    showDropdown: false,
    requestType: null,
    selectedDate: null,
    onRequestsPage: false,
    dateSetByComponent: false,
    showingPending: computed.equal('requestType', 'pending'),

    didInsertElement() {
      this.get('setRequestListChild')(this);

      if (!this.get('currentUser.user.isStaff') && this.get('currentUser.user.company.staff_appointment_approval')) {
        this.set('requestType', 'all');
      } else {
        this.set('requestType', 'pending');
      }

      this.send('resetElement');
    },

    dateObserver: observer('selectedDate', function () {
      if (this.get('dateSetByComponent')) {
        this.toggleProperty('dateSetByComponent');
      } else {
        this.send('resetElement');
      }
    }),
    requestTypeOptions: computed('currentUser.user.company.staff_appointment_approval', 'currentUser.user.isStaff', 'media.isMobile', function () {
      if (!this.get('currentUser.user.isStaff') && this.get('currentUser.user.company.staff_appointment_approval')) {
        return {
          'pending': ['all', 'unassigned'],
          'replied': ['approved', 'declined', 'expired']
        };
      } else {
        return ['pending', 'approved', 'declined', 'expired'];
      }
    }),
    requestTypesAreGrouped: computed('requestTypeOptions.[]', function () {
      return !Array.isArray(this.get('requestTypeOptions'));
    }),
    approvedRequestCount: computed('appointmentRequests.@each.isDeleted', function () {
      return this.get('appointmentRequests').filter(request => request.get('isDeleted')).length;
    }),
    groupedAppointmentRequests: computed('appointmentRequests.[]', 'appointmentRequests.@each.isDeleted', 'selectedDate', function () {
      if (!this.get('loadedOnce')) {
        return;
      }

      let groupedAppointmentRequests = {};
      let appointmentRequests = this.get('appointmentRequests').filter(request => !request.get('isDeleted'));
      appointmentRequests.forEach(request => {
        let date = moment.parseZone(request.get('starts_at')).format('YYYY-MM-DD');

        if (!groupedAppointmentRequests[date]) {
          groupedAppointmentRequests[date] = [];
        }

        groupedAppointmentRequests[date].push(request);
      });
      let selectedDate = this.get('selectedDate');

      if (selectedDate && !groupedAppointmentRequests[selectedDate]) {
        groupedAppointmentRequests[selectedDate] = [];
      }

      let dates = Object.keys(groupedAppointmentRequests).sort((a, b) => {
        let timeOne = moment.parseZone(a, 'YYYY-MM-DD');
        let timeTwo = moment.parseZone(b, 'YYYY-MM-DD');

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
      return dates.map(date => {
        let requests = groupedAppointmentRequests[date].sort((a, b) => {
          let timeOne = moment.parseZone(a.get('starts_at'));
          let timeTwo = moment.parseZone(b.get('starts_at'));

          if (timeOne.isAfter(timeTwo)) {
            return 1;
          } else if (timeOne.isBefore(timeTwo)) {
            return -1;
          } else {
            let requestedTimeOne = moment(a.get('created_at'));
            let requestedTimeTwo = moment(b.get('created_at'));

            if (requestedTimeOne.isAfter(timeTwo)) {
              return -1;
            } else if (requestedTimeTwo.isBefore(timeTwo)) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        return {
          date,
          requests
        };
      });
    }),
    selectedDateAppointments: computed('selectedDate', 'groupedAppointmentRequests.[]', function () {
      let groupedRequests = this.get('groupedAppointmentRequests');

      if (groupedRequests && groupedRequests.length > 0) {
        let today = groupedRequests.filter(groupedRequest => groupedRequest.date == this.get('selectedDate'));

        if (today.length > 0) {
          return today.get('firstObject').requests.length;
        }
      }

      return 0;
    }),
    selectedDateDayOfWeek: computed('selectedDate', function () {
      return moment(this.get('selectedDate'), 'YYYY-MM-DD').format('dddd');
    }),
    noPendingRequests: computed('upcomingReachedInfinity', 'totalRequestCount', 'approvedRequestCount', 'requestType', function () {
      return this.get('upcomingReachedInfinity') && this.get('totalRequestCount') - this.get('approvedRequestCount') == 0 && this.get('requestType') == 'pending';
    }),
    requestCount: computed('totalRequestCount', 'approvedRequestCount', function () {
      return this.get('totalRequestCount') - this.get('approvedRequestCount');
    }),

    showFilterOptions() {
      this.get('headerReference').showFilterOptions();
    },

    actions: {
      resetElement() {
        // We're deleting approved pending requests to remove them from the list
        // without having to reload the entire list. We need to rollback that
        // flag when we switch to another section since we're reloading the entire
        // list anyway
        this.get('appointmentRequests').forEach(request => request.rollbackAttributes());
        this.set('appointmentRequests', []);
        this.set('upcomingPage', 1);
        this.set('pastPage', 1);
        this.set('loadedOnce', false);
        this.set('upcomingReachedInfinity', false);
        this.set('pastReachedInfinity', false);
        this.set('upcomingCount', 0);
        this.set('pastCount', 0);

        if (this.get('isFetching')) {
          this.set('reseting', true);
        } else {
          this.send('loadAppointmentRequests');
        }
      },

      loadAppointmentRequests(appointmentType) {
        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        let per_page = this.get('per_page');
        let past = appointmentType == 'past';
        let page = past ? this.get('pastPage') : this.get('upcomingPage');
        let type = this.get('requestType');
        let date = this.get('selectedDate');

        if (past) {
          this.set('loadingPastAppointments', true);
        }

        this.get('store').query('appointment-request', {
          per_page,
          page,
          past,
          type,
          date
        }).then(appointmentRequests => {
          if (this.get('reseting')) {
            this.set('reseting', false);
            this.set('isFetching', false);
            this.set('loadingPastAppointments', false);
            this.send('loadAppointmentRequests');
            return;
          }

          this.set('totalRequestCount', appointmentRequests.meta.total_count);

          if (type == 'pending' && this.get('setPendingRequestsCount')) {
            this.get('setPendingRequestsCount')(appointmentRequests.meta.total_count);
          }

          let scrollTop = $('.requests-list').scrollTop();
          let apptRequestIds = this.get('appointmentRequests').map(apptRequest => parseInt(apptRequest.get('id')));
          appointmentRequests.forEach(apptRequest => {
            if (!apptRequestIds.includes(parseInt(apptRequest.get('id')))) {
              this.get('appointmentRequests').pushObject(apptRequest);
            }
          });
          this.notifyPropertyChange('appointmentRequests');
          let apptsLength = appointmentRequests.get('length');
          let reachedInfinity = apptsLength < per_page;

          if (past) {
            this.incrementProperty('pastPage');
            this.set('pastReachedInfinity', reachedInfinity);
            this.set('pastCount', this.get('pastCount') + apptsLength);
          } else {
            this.incrementProperty('upcomingPage');
            this.set('upcomingReachedInfinity', reachedInfinity);
            this.set('upcomingCount', this.get('upcomingCount') + apptsLength);
          } // If we're loading for the first time we want to scroll to the date
          // otherwise leave it scrolled to where the user scrolled


          let loadedAlready = this.get('loadedOnce');

          if (type == 'pending' || type == 'all') {
            Ember.run.scheduleOnce('afterRender', () => {
              if (loadedAlready) {
                document.querySelector('.requests-list').scroll({
                  top: scrollTop
                });
              } else {
                let ele = document.getElementById(date);

                if (ele) {
                  ele.scrollIntoView();
                }
              }
            });
          }

          this.set('loadedOnce', true);
          this.set('isFetching', false);
          this.set('loadingPastAppointments', false);
        });
      },

      modifyAppointmentRequest(appointmentRequest) {
        this.get('modifyAppointmentRequest')(appointmentRequest);
      },

      selectHeaderOption(requestType) {
        this.set('requestType', requestType);
        this.send('resetElement');
      },

      goToRequests() {
        this.get('goToRequests')();
        let date = this.get('selectedDate');
        Ember.run.scheduleOnce('afterRender', () => {
          let ele = document.getElementById(date);

          if (ele) {
            ele.scrollIntoView();
          }
        });
      },

      switchDate(date) {
        this.set('dateSetByComponent', true);
        this.set('selectedDate', date);
      },

      startDraggingRequest(appointmentRequest) {
        this.get('startDraggingRequest')(appointmentRequest);
      },

      endDraggingRequest() {
        this.get('endDraggingRequest')();
      },

      setHeaderChild(headerReference) {
        this.set('headerReference', headerReference);
      }

    }
  });

  _exports.default = _default;
});