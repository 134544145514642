define("frontend/components/dismiss-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    ajax: inject.service(),
    currentUser: inject.service(),
    messageName: null,
    actions: {
      onDismissMessage() {
        let url = '/api/v2/user_dismissed_messages';
        let data = {
          user_dismissed_message: {
            message_name: this.get('messageName')
          }
        };
        this.get('ajax').request(url, {
          method: 'POST',
          data
        }).then(() => {
          this.onDismissedMessage();
        });
      }

    }
  });

  _exports.default = _default;
});