define("frontend/controllers/add-client/primary-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['householdId', 'searchedEmail'],
    householdId: null,
    valid: computed.readOnly('model.validations.isValid'),
    nextDisabled: computed.not('valid'),
    application: Ember.inject.controller(),
    session: inject.service(),
    accountSession: inject.service(),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    title: computed('isPetParent', function () {
      if (this.get('isPetParent')) {
        return 'Set up profile';
      } else {
        return 'Add new client';
      }
    }),
    actions: {
      transitionToClientList() {
        this.transitionToRoute('client-list');
      },

      uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      }

    }
  });

  _exports.default = _default;
});