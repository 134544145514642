define("frontend/services/service-actions", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessage: Ember.inject.service(),

    removeAllInDateRange(startDate, endDate, careProviderId) {
      let start_date = startDate;
      let end_date = endDate;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: '/api/v2/services/destroy_all',
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            care_provider_id: careProviderId,
            start_date,
            end_date
          })
        }).then(() => {
          resolve();
        }).fail(err => {
          this.get('flashMessage').error('Unable to remove all, internal server error');
          reject(err);
        });
      });
    },

    removeAllFor(date, careProviderId) {
      return this.removeAllInDateRange(date, date, careProviderId);
    },

    removeAllInWeek(date, careProviderId) {
      let startOfWeek = (0, _moment.default)(date).startOf('week').format('YYYY-MM-DD');
      let endOfWeek = (0, _moment.default)(date).endOf('week').format('YYYY-MM-DD');
      return this.removeAllInDateRange(startOfWeek, endOfWeek, careProviderId);
    }

  });

  _exports.default = _default;
});