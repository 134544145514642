define("frontend/utils/generate-dates", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateDates;

  function generateDates(start, end) {
    start = (0, _moment.default)(start);
    end = (0, _moment.default)(end);
    let days = [];

    while (start.isBefore(end)) {
      days.push(start.clone().format('YYYY-MM-DD'));
      start.add(1, 'day');
    }

    return days;
  }
});