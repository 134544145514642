define("frontend/models/invoice-list-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    invoice: _emberData.default.belongsTo('invoice', {
      async: false
    }),
    invoice_payment: _emberData.default.belongsTo('invoice-payment', {
      async: false
    }),
    invoice_payment_refund: _emberData.default.belongsTo('invoice-payment-refund', {
      async: false
    }),
    isInvoice: computed.notEmpty('invoice'),
    isPayment: computed.notEmpty('invoice_payment'),
    isRefund: computed.notEmpty('invoice_payment_refund')
  });

  _exports.default = _default;
});