define("frontend/controllers/admin/doctors", ["exports", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'doctor',
    modelOptions: {
      all: true
    },
    selectedDoctor: null,
    actions: {
      submitReplace(replace) {
        let url = `/api/v2/doctors/${this.get('editingReplace.id')}/replace`;
        this.submitReplace(url, replace, this.get('editingReplace'));
      },

      saveDoctor(doctor) {
        this.send('save', doctor);
        this.set('editingDoctor', null);
      },

      showRemove(doctor) {
        this.set('selectedDoctor', doctor);
      },

      confirmRemove() {
        this.send('remove', this.get('selectedDoctor'));
        this.set('selectedDoctor', null);
      },

      denyRemove() {
        this.set('selectedDoctor', null);
      },

      rollbackDoctor(doctor) {
        doctor.rollbackAttributes();
        this.set('editingDoctor', null);
      }

    }
  });

  _exports.default = _default;
});