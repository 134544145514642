define("frontend/components/edit-categories-time-frame-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    store: inject.service(),
    selectedCategory: null,
    addingCategory: false,
    timeFrames: computed.alias('selectedCategory.time_frames'),
    activeTimeFrames: computed.filterBy('timeFrames', 'markedForDeletion', false),
    changeAppointmentMethod: 'future',
    windowOptions: [{
      label: '15 min',
      value: '15'
    }, {
      label: '30 min',
      value: '30'
    }, {
      label: '45 min',
      value: '45'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '1 hour 30min',
      value: '90'
    }, {
      label: '2 hours',
      value: '120'
    }, {
      label: '2 hours 30min',
      value: '150'
    }, {
      label: '3 hours',
      value: '180'
    }],
    selectedTimeWindow: computed('selectedCategory', 'selectedCategory.time_window_in_minutes', function () {
      let options = this.get('windowOptions');
      let selected = options.filter(option => this.get('selectedCategory.time_window_in_minutes') == option.value);
      return selected.get('firstObject');
    }),
    timeWindowClarification: computed('selectedTimeWindow', function () {
      let timeWindow = this.get('selectedTimeWindow');

      if (timeWindow) {
        let startTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').subtract(timeWindow.value, 'minutes').format('h:mmA');
        let endTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').add(timeWindow.value, 'minutes').format('h:mmA');
        return `${startTime} - ${endTime}`;
      } else {
        return;
      }
    }),
    sortedTimeFrames: computed.sort('activeTimeFrames', function (x, y) {
      if (x.get('start_time') == y.get('start_time')) {
        if (moment(x.get('end_time'), 'hh:mm a') > moment(y.get('end_time'), 'hh:mm a')) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (moment(x.get('start_time'), 'hh:mm a') > moment(y.get('start_time'), 'hh:mm a')) {
          return 1;
        } else {
          return -1;
        }
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('changeAppointmentMethod', 'future'); // Do not switch this to a forEach loop because it will change the array
      // while you're working on it

      let tfs = this.get('selectedCategory.time_frames').map(tf => tf);

      for (let i = 0; i < tfs.length; i++) {
        tfs[i].rollbackAttributes();
      }
    },

    actions: {
      cancel() {
        this.get('cancel')();
      },

      addCategory() {
        this.get('addCategory')(this.get('timeFrames'), this.get('selectedCategory.time_window_in_minutes'), this.get('selectedCategory.use_time_blocks'), this.get('selectedCategory.show_to_pet_parents'));
      },

      toggleAppointmentMethodModal() {
        this.toggleProperty('showChangeAppointmentMethodModal');
      },

      openHeadsUpModal() {
        if (this.get('changeAppointmentMethod') == 'none') {
          this.send('updateCategoryHours');
        } else {
          this.set('showHeadsUpModal', true);
          this.send('toggleAppointmentMethodModal');
        }
      },

      closeHeadsUpModal() {
        this.set('showHeadsUpModal', false);
      },

      updateCategoryHours() {
        this.get('updateCategoryHours')(this.get('changeAppointmentMethod'));
      },

      toggleUsingTimeBlocks() {
        this.toggleProperty('selectedCategory.use_time_blocks');
      },

      toggleShowToPetParents() {
        this.toggleProperty('selectedCategory.show_to_pet_parents');
      },

      createTimeFrame() {
        let newTimeFrame = this.get('store').createRecord('time-frame', {
          service_category: this.get('selectedCategory'),
          days_of_week: [0, 1, 2, 3, 4, 5, 6]
        });
        this.set('selectedTimeFrame', newTimeFrame);
        this.set('showTimeBlockModal', true);
        this.set('newTimeFrame', true);
      },

      selectTimeFrame(timeFrame) {
        this.set('newTimeFrame', false);
        this.set('selectedTimeFrame', timeFrame);
        this.set('selectedTimeFrameOriginalStart', timeFrame.get('start_time'));
        this.set('selectedTimeFrameOriginalEnd', timeFrame.get('end_time'));
        this.set('selectedTimeFrameOriginalDows', timeFrame.get('days_of_week'));
        this.set('showTimeBlockModal', true);
      },

      removeTimeFrame(timeFrame) {
        timeFrame.set('markedForDeletion', true);
      },

      saveTimeFrame() {
        if (this.get('newTimeFrame')) {
          let timeFrame = this.get('selectedTimeFrame');
          this.get('timeFrames').pushObject(timeFrame);
        }

        this.set('showTimeBlockModal', false);
      },

      closeTimeBlockModal() {
        if (this.get('newTimeFrame')) {
          this.get('selectedTimeFrame').rollbackAttributes();
        } else {
          let timeFrame = this.get('selectedTimeFrame');
          timeFrame.set('start_time', this.get('selectedTimeFrameOriginalStart'));
          timeFrame.set('end_time', this.get('selectedTimeFrameOriginalEnd'));
          timeFrame.set('days_of_week', this.get('selectedTimeFrameOriginalDows'));
        }

        this.set('showTimeBlockModal', false);
      },

      changeTimeWindow(option) {
        this.set('selectedCategory.time_window_in_minutes', option.value);
      }

    }
  });

  _exports.default = _default;
});