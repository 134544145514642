define("frontend/services/schedule-drag-action-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Service.extend({
    showingCancel: false,
    showingArchive: false,
    showingAnyDropZone: computed.or('showingCancel', 'showingArchive'),
    showingOneDropZone: computed('showingCancel', 'showingArchive', function () {
      let showingCancel = this.get('showingCancel');
      let showingArchive = this.get('showingArchive');
      return showingCancel && !showingArchive || showingArchive && !showingCancel;
    }),
    showingBothDropZones: computed.and('showingCancel', 'showingArchive'),

    stopDragging() {
      this.set('showingCancel', false);
      this.set('showingArchive', false);
    }

  });

  _exports.default = _default;
});