define("frontend/components/setup-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    companyInfo: null,
    companyLogo: Ember.computed('companyInfo', 'companyInfo.company_logo', function () {
      let logo = null;
      logo = this.get('companyInfo.company_logo');

      if (logo) {
        return logo;
      } else {
        return '/assets/images/settings/company-placeholder.png';
      }
    }),
    actions: {
      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});