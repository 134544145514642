define("frontend/helpers/env-is", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.envIs = envIs;

  function envIs(_ref) {
    let [env] = _ref;
    return _environment.default.environment === env;
  }

  var _default = Ember.Helper.helper(envIs);

  _exports.default = _default;
});