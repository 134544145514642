define("frontend/routes/edit-client/primary-contact", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),

    model() {
      let household = this._super(...arguments);

      return household;
    },

    setupController(controller, model) {
      this._super(...arguments);

      let owner = model.get('owner');
      let phoneNumbers = owner.get('phone_numbers');

      if (phoneNumbers.get('length') == 0) {
        let defaultPhone = this.store.createRecord('phone-number');
        owner.get('phone_numbers').pushObject(defaultPhone);
      }

      let addresses = owner.get('addresses');

      if (addresses.get('length') == 0) {
        let user = this.get('currentUser.user');
        let state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        let country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        let defaultAddress = this.store.createRecord('address', {
          state,
          country
        });
        owner.get('addresses').pushObject(defaultAddress);
      }

      let emails = owner.get('emails');
      owner.set('emails', emails.sortBy('email_type').reverse());
    }

  });

  _exports.default = _default;
});