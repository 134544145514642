define("frontend/components/document-list/signature-request-list-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['swipedLeft', 'isPending:pending'],
    media: inject.service(),
    showMenu: false,
    isHovering: false,
    isPending: computed.equal('signatureRequest.status', 'pending'),

    handleMouseEnter() {
      this.set('isHovering', true);
    },

    handleMouseLeave() {
      this.set('isHovering', false);
    },

    didInsertElement() {
      this._super(...arguments);

      let offClickHandler = this.get('clickedOutsideElement').bind(this);
      this.set('offClickHandler', offClickHandler);
      (0, _jquery.default)(document).on('click', offClickHandler);
      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(document).off('click', this.get('offClickHandler'));
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },

    clickedOutsideElement(event) {
      let $element = (0, _jquery.default)(this.element);
      let $target = (0, _jquery.default)(event.target);

      if (!$target.closest($element).length) {
        this.set('showMenu', false);
        this.set('isHovering', false);
      }
    },

    formattedDate: computed('signatureRequest.last_activity_date', function () {
      return moment.parseZone(this.get('signatureRequest.last_activity_date')).format('MMM D, YYYY');
    }),
    lastActivityAction: computed('signatureRequest.status', function () {
      switch (this.get('signatureRequest.status')) {
        case 'pending':
          return 'sent';

        case 'signed':
          return 'signed';

        case 'viewed':
          return 'viewed';

        case 'declined':
          return 'declined';

        case 'agreed':
          return 'agreed';
      }
    }),
    actions: {
      toggleMenu() {
        this.toggleProperty('showMenu');
      },

      downloadDocument() {
        window.open(this.get('signatureRequest.signed_pdf_url'), this.get('target'));
        this.toggleProperty('showMenu');
      },

      viewPendingDocument() {
        window.open(this.get('signatureRequest.pdf_url'), this.get('target'));
        this.toggleProperty('showMenu');
      },

      cancelRequest() {
        this.remove(...arguments);
        this.toggleProperty('showMenu');
      }

    }
  });

  _exports.default = _default;
});