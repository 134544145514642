define("frontend/components/custom-pricing-modal/edit-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    household: null,
    offeredService: computed.readOnly('offeredServicePrice.offeredService'),
    clientPrice: computed.alias('offeredServicePrice.offeredServiceClientPrice'),
    hasIncrementalPrice: computed.notEmpty('clientPrice.incremental_price'),
    hasPrice: computed.notEmpty('clientPrice.price'),
    showDollarIncrementalPrice: computed.or('focusIncrementalPrice', 'hasIncrementalPrice'),
    showDollarPrice: computed.or('focusPrice', 'hasPrice'),
    effectiveDates: computed('currentUser.user', function () {
      let start_date = moment(this.get('household.billing_period_start_date')).format('M/D');

      if (start_date == 'Invalid date') {
        return [];
      }

      let end_date = moment(this.get('household.billing_period_end_date')).format('M/D');
      let next_start_date = moment(this.get('household.next_billing_period_start_date')).format('M/D');
      let next_end_date = moment(this.get('household.next_billing_period_end_date')).format('M/D');
      return [{
        value: 'current',
        label: `Current Bill (${start_date} - ${end_date})`
      }, {
        value: 'next',
        label: `Next Bill (${next_start_date} - ${next_end_date})`
      }];
    }),
    effectiveDateClarify: computed('selectedEffectiveDate', function () {
      if (this.get('selectedEffectiveDate.value') == 'next') {
        let start_date = this.get('household.next_billing_period_start_date');
        let end_date = this.get('household.next_billing_period_end_date');
        return `Your new prices will apply starting the next billing period ${moment(start_date).format('M/D')} - ${moment(end_date).format('M/D')}. The current bill will still be on your old prices.`;
      } else {
        let end_date = this.get('household.billing_period_end_date');
        return `Your new prices will apply starting with your current bill scheduled to close on ${moment(end_date).format('M/D')}.`;
      }
    }),
    selectedEffectiveDate: computed('effectiveDates.[]', 'clientPrice.effective_date', function () {
      return this.get('effectiveDates').filterBy('value', this.get('clientPrice.effective_date')).get('firstObject');
    }),
    actions: {
      changePrice(price) {
        this.set('clientPrice.price', price.replace('$', ''));
      },

      changeIncrementalPrice(incrementalPrice) {
        this.set('clientPrice.incremental_price', incrementalPrice.replace('$', ''));
      },

      changeEffectiveDate(effectiveDate) {
        this.set('clientPrice.effective_date', effectiveDate.value);
        this.set('selectedEffectiveDate', effectiveDate);
      },

      onClose() {
        this.get('onClose')();
      },

      submit() {
        this.get('onSubmit')();
      },

      resetPrices() {
        this.get('resetPrices')();
      }

    }
  });

  _exports.default = _default;
});