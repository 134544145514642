define("frontend/components/sales-tax-policy-modal", ["exports", "frontend/mixins/money-formater", "ember-cp-validations"], function (_exports, _moneyFormater, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'salesTaxPolicy.name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(_moneyFormater.default, Validations, {
    salesTaxPolicy: null,
    disableDefault: false,
    amountError: computed('salesTaxPolicy.rate', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('salesTaxPolicy.rate');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      cancel() {
        this.get('salesTaxPolicy').rollbackAttributes();
        this.get('onClose')();
      },

      save() {
        this.set('triedToSave', true);
        this.set('didValidate', true);

        if (this.get('amountError') || !this.get('validations.isValid')) {
          return;
        }

        this.get('onSave')();
      }

    }
  });

  _exports.default = _default;
});