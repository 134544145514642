define("frontend/components/invoice-receipt/appointments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointments-list'],
    isPetParent: false,
    appointmentComponent: computed('isPetParent', function () {
      let isPetParent = this.get('isPetParent');

      if (isPetParent) {
        return 'invoice-receipt/client-appointment-group';
      } else {
        return 'invoice-receipt/appointment-group';
      }
    }),
    groupedAppointmentsByOfferedService: computed('groupedAppointments.@each.offered_service', function () {
      let apptsByOfferedService = {};
      this.get('groupedAppointments').forEach(appointment => {
        let offeredService = appointment.get('offered_service.id');
        let petCount = appointment.get('appointments.length');
        let key = `${offeredService}-${petCount}`;

        if (apptsByOfferedService[key] == null) {
          apptsByOfferedService[key] = {
            offeredServiceId: offeredService,
            groupedAppointments: []
          };
        }

        apptsByOfferedService[key].groupedAppointments.push(appointment);
      }); // Turn the dictionary into an array of dictionaries so we can sort it
      // and still access each element by dot notation

      let entries = Object.entries(apptsByOfferedService).sort((x, y) => x[0].localeCompare(y[0]));
      entries = entries.map(entry => {
        return {
          key: entry[0],
          info: entry[1]
        };
      });
      return entries;
    })
  });

  _exports.default = _default;
});