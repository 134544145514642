define("frontend/components/appointment-details", ["exports", "frontend/config/environment", "moment", "jquery"], function (_exports, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    run: {
      debounce
    }
  } = Ember;

  var _default = Ember.Component.extend({
    ajax: inject.service(),
    media: inject.service(),
    polling: inject.service(),
    classNames: ['appointment-details'],
    appointment: null,
    appointmentDetails: computed.readOnly('appointment.appointment_detail'),
    updatingAppointment: computed.readOnly('appointment.updatingCompleteStatus'),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    store: inject.service(),
    isDog: computed.equal('appointment.pet.pet_type_id', '1'),
    isCat: computed.equal('appointment.pet.pet_type_id', '2'),
    archiveIconUrl: 'assets/images/archive-icon.svg',
    modifyIconUrl: 'assets/images/edit-icon-red.svg',
    reportCardIconUrl: computed('appointment.grouped_report_card.draft', function () {
      if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
        return 'assets/images/report-card/finish-report-card.svg';
      }

      return 'assets/images/report-card/view-report-card-icon.svg';
    }),
    reportCardActionLabel: computed('appointment.grouped_report_card.draft', function () {
      if (!this.get('appointment.grouped_report_card')) {
        return 'Start';
      }

      return this.get('appointment.grouped_report_card.draft') ? 'Finish' : 'View';
    }),
    month: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('ddd');
    }),
    startEndTime: computed('appointment.service.starts_at', 'appointment.offered_service.duration_in_minutes', function () {
      let start = _moment.default.parseZone(this.get('appointment.service.starts_at'));

      let duration = this.get('appointment.offered_service.duration_in_minutes');

      let end = _moment.default.parseZone(this.get('appointment.service.starts_at')).add(duration, 'minutes');

      return `${start.format('h:mmA')} - ${end.format('h:mmA')}`;
    }),
    petImageStyle: computed('appointment.offered_service_color_hex', function () {
      let colorHex = this.get('appointment.offered_service_color_hex');
      return Ember.String.htmlSafe(`border: 4px solid #${colorHex}; border-radius: 100px;`);
    }),
    currentTime: (0, _moment.default)(),
    showCanceledAppointmentControls: computed.and('currentUser.user.canAddEditAppointments', 'appointment.canceled_at'),
    showPrimaryAppointmentControls: computed.not('appointment.canceled_at'),
    notUpdatingCompleteStatus: computed.not('appointment.updatingCompleteStatus'),
    appointmentNotStarted: computed.empty('appointment.started_at'),
    appointmentNotFinished: computed.empty('appointment.finished_at'),
    appointmentStartedNotFinished: computed.and('appointment.started_at', 'appointmentNotFinished'),
    petAvatarBorderWidth: computed('appointmentStartedNotFinished', function () {
      return this.get('appointmentStartedNotFinished') ? '0px' : '4px';
    }),
    showReportCardButton: computed('appointment.updatingCompleteStatus', 'appointment.started_at', 'appointment.completed', function () {
      if (this.get('appointment.updatingCompleteStatus')) {
        return false;
      }

      if (this.get('appointment.completed') || this.get('appointment.started_at')) {
        return true;
      }

      return false;
    }),
    showEditAppointmentActions: computed('currentUser.user.canAddEditAppointments', 'appointment.started_at', 'appointment.completed', function () {
      if (!this.get('currentUser.user.canAddEditAppointments')) {
        return false;
      }

      return !(this.get('appointment.started_at') && !this.get('appointment.completed')) || this.get('appointment.started_at') && this.get('appointment.finished_at') && !this.get('appointment.completed');
    }),
    durationTime: computed('appointment.started_at', 'currentTime', function () {
      let now = this.get('currentTime');
      let start = (0, _moment.default)(this.get('appointment.started_at'));

      let duration = _moment.default.duration(now.diff(start));

      let hours = duration.hours();

      if (hours < 10) {
        hours = `0${hours}`;
      }

      let minutes = duration.minutes();

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      let seconds = duration.seconds();

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('currentTime', (0, _moment.default)());
      this.get('polling').add('time update', this, () => {
        this.set('currentTime', (0, _moment.default)());
        this.updateDurationProgress();
      });
    },

    didRender() {
      this._super(...arguments);

      debounce(this, 'updateDurationProgress', 150, true);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('polling').stop('time update');
    },

    updateDurationProgress() {
      if (this.get('appointmentStartedNotFinished')) {
        let start = (0, _moment.default)(this.get('appointment.started_at'));
        let diff = (0, _moment.default)().diff(start, 'minutes'); // duration can be zero - don't allow start/finish on 0 duration appointments
        // how does this work for all day appointments? - ????

        let duration = this.get('appointment.offered_service.duration_in_minutes');

        if (duration > 0) {
          let percentageComplete = diff / duration;

          if (percentageComplete > 1) {
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', 0);
          } else {
            let pixelsCompleted = percentageComplete * 275;
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', 275 - pixelsCompleted);
          }
        }
      }
    },

    actions: {
      edit() {
        this.edit();
      },

      clickPet() {
        this.clickPet(this.get('appointment.pet'));
      },

      delete() {
        this.delete();
      },

      cancel() {
        this.cancel();
      },

      restore() {
        this.restore();
      },

      addInstructions() {
        this.addInstructions();
      },

      closeAppointmentDetails() {
        this.closeAppointmentDetails();
      },

      markComplete() {
        this.markComplete(this.get('appointment'));
      },

      markUncomplete() {
        this.markUncomplete(this.get('appointment'));
      },

      requestHousehold() {
        let householdId = this.get('appointment.pet.household.id');
        let email = this.get('appointment.pet.household.request_sent_to');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/add_company`,
          method: 'POST',
          data: {
            id: householdId,
            email
          }
        }).done(() => {
          this.get('store').findRecord('household', householdId).then(household => {
            household.set('client_status', 'pending');
          });
          this.get('flashMessage').sendFlashMessage(`Your request to connect was sent to ${email}`, 'Success');
        });
      },

      archiveHovering(hovering) {
        if (hovering) {
          this.set('archiveIconUrl', 'assets/images/archive-icon-hover.svg');
        } else {
          this.set('archiveIconUrl', 'assets/images/archive-icon.svg');
        }
      },

      modifyHovering(hovering) {
        if (hovering) {
          this.set('modifyIconUrl', 'assets/images/edit-icon-red-hover.svg');
        } else {
          this.set('modifyIconUrl', 'assets/images/edit-icon-red.svg');
        }
      },

      reportCardHovering(hovering) {
        if (hovering) {
          if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
            this.set('reportCardIconUrl', 'assets/images/report-card/finish-report-card-hover.svg');
          } else {
            this.set('reportCardIconUrl', 'assets/images/report-card/view-report-card-hover.svg');
          }
        } else {
          if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
            this.set('reportCardIconUrl', 'assets/images/report-card/finish-report-card.svg');
          } else {
            this.set('reportCardIconUrl', 'assets/images/report-card/view-report-card-icon.svg');
          }
        }
      },

      handleReportCard() {
        if (this.get('appointment.grouped_report_card')) {
          this.openReportCard(this.get('appointment.grouped_report_card'));
        } else {
          let grc = this.get('store').createRecord('grouped_report_card', {
            appt_id: this.get('appointment.id')
          });
          grc.save().then(() => {
            this.get('appointment.grouped_appointment.appointments').forEach(appt => {
              appt.set('grouped_report_card', grc);
            });
            this.openReportCard(grc);
          });
        }
      },

      startAppointment() {
        this.startAppointment(this.get('appointment'));
        this.set('currentTime', (0, _moment.default)());
      },

      undoStartAppointment() {
        this.undoStartAppointment(this.get('appointment'));
      }

    }
  });

  _exports.default = _default;
});