define("frontend/components/phone-picker-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      textPhone(phone) {
        this.get('textPhone')(phone);
      },

      callPhone(phone) {
        this.get('callPhone')(phone);
      },

      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});