define("frontend/routes/add-appointment/details", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    addAppointmentData: inject.service(),

    redirect() {
      this._super(...arguments);

      if (this.get('addAppointmentData.selectedPetValues.length') === 0) {
        this.transitionTo('add-appointment.index', {
          queryParams: {
            selectedHouseholdId: null
          }
        });
      }
    },

    model() {
      return this.store.query('offered-service', {}, {
        reload: true
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('showErrorFlash', false);
      controller.set('startSidePanelOpen', false);
    },

    actions: {
      next() {
        this.transitionTo('add-appointment.times');
      },

      back() {
        this.transitionTo('add-appointment.index', {
          queryParams: {
            selectedHouseholdId: null
          }
        });
      }

    }
  });

  _exports.default = _default;
});