define("frontend/components/payment-method-modal/bank-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showBankAccountModal: false,
    plaidRedirect: false,
    oauthEnabled: false,
    accountHolderName: null,
    actions: {
      saveBankAccount(token, account_id) {
        this.get('saveBankAccount')({
          token,
          account_id
        });
      },

      toggleBankAccountModal() {
        this.toggleProperty('showBankAccountModal'); // This is needed for mobile safari since they don't like modals on top of
        // each other for some reason so we need to get rid of the overlay

        if (this.get('showBankAccountModal')) {
          Ember.run.scheduleOnce('afterRender', function () {
            $('.ember-modal-overlay').css('display', 'none');
          });
        } else {
          Ember.run.scheduleOnce('afterRender', function () {
            $('.ember-modal-overlay').css('display', 'block');
          });
        }
      }

    }
  });

  _exports.default = _default;
});