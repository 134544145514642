define("frontend/components/message-thread", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['messages-thread'],
    classNameBindings: ['showingCompanySelection', 'hasSidePanel'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    flashMessage: inject.service(),
    messages: [],
    isPetParent: computed.alias('currentUser.user.isPetParent'),
    noMessages: computed.equal('messages.length', 0),
    typedMessage: '',
    household: null,
    company: null,
    showingCompanySelection: false,
    firestore: inject.service(),
    firestoreListener: null,
    hasSidePanel: true,
    isTypingTimer: null,
    typingMessages: [],
    isInDistributionListThread: false,
    showMenu: false,
    showHeader: false,
    datedMessages: computed('messages.[]', function () {
      let datedMessages = {};
      let messages = this.get('messages');
      messages.forEach(message => {
        let date = moment(message.get('created_at')).startOf('day').format('YYYY-MM-DD');

        if (!datedMessages[date]) {
          datedMessages[date] = [];
        }

        datedMessages[date].push(message);
      });
      let messageObjects = [];
      let sortedDates = Object.keys(datedMessages).sort(this.sortDates);
      sortedDates.forEach(date => {
        let sortedMessages = datedMessages[date].sortBy('created_at');
        sortedMessages.forEach((message, index) => {
          let nextMessage = sortedMessages[index + 1];

          if (index !== sortedMessages.length && nextMessage) {
            if (moment(nextMessage.get('created_at')).minute() === moment(message.get('created_at')).minute() && nextMessage.get('sent_by_id') === message.get('sent_by_id')) {
              message.set('combined', true);
            }
          }
        });
        messageObjects.push(Ember.Object.create({
          date,
          messages: sortedMessages
        }));
      });
      return messageObjects;
    }),
    lastMessageTime: computed('messages.[]', function () {
      let messages = this.get('messages').sortBy('created_at');
      let lastMessage = messages.get('lastObject');

      if (lastMessage) {
        return lastMessage.get('created_at');
      }
    }),
    firstMessageTime: computed('messages.[]', function () {
      let messages = this.get('messages').sortBy('created_at');
      let firstMessage = messages.get('firstObject');

      if (firstMessage) {
        return firstMessage.get('created_at');
      }
    }),
    householdAndCompanyObserver: observer('household.id', 'company.id', function () {
      this.scrollToBottomOfThread();
      this.recordLastSeen();
      this.subscribeToFirestore();
      this.subscribeToTypingEvents();
    }),
    whileTyping: observer('typedMessage', function () {
      if (this.get('typedMessage').length > 0) {
        if (!this.get('isTypingTimer')) {
          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/is_typing`,
            method: 'POST',
            data: {
              household_id: this.get('household.id'),
              company_id: this.get('company.id'),
              is_typing: true
            }
          });
        }

        if (this.get('isTypingTimer')) {
          clearTimeout(this.get('isTypingTimer'));
        }

        this.set('isTypingTimer', setTimeout(() => {
          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/is_typing`,
            method: 'POST',
            data: {
              household_id: this.get('household.id'),
              company_id: this.get('company.id'),
              is_typing: false
            }
          });

          this.set('isTypingTimer', null);
        }, 5000));
      }
    }),

    subscribeToTypingEvents() {
      if (this.get('firestoreTypingListener')) {
        this.get('firestoreTypingListener')();
      }

      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_user_is_typing`);
      let self = this;

      if (collection) {
        let firestoreTypingListener = collection.doc(`${this.get('company.id')}_${this.get('household.id')}`).onSnapshot(function (doc) {
          if (doc.exists) {
            let store = self.get('store');
            let typingMessages = Object.entries(doc.data()).filter(_ref => {
              let [id, isTyping] = _ref;
              return id !== self.get('currentUser.user.id') && isTyping;
            }).map(_ref2 => {
              let [id] = _ref2;
              return store.findRecord('user', id).then(user => {
                return store.createRecord('message', {
                  message_type: 'TypingIndicatorMessage',
                  sent_by_id: id,
                  created_at: moment().toString(),
                  sent_by_name: user.get('fullName'),
                  sent_by_avatar: user.get('avatar_url'),
                  sent_by_staff: !Ember.isBlank(user.get('company.id'))
                });
              });
            });
            Ember.RSVP.Promise.all(typingMessages).then(resolvedMessages => {
              self.set('typingMessages', resolvedMessages);
              self.scrollToBottomOfThread();
            });
          }
        });
        this.set('firestoreTypingListener', firestoreTypingListener);
      }
    },

    didInsertElement() {
      this.scrollToBottomOfThread();
      this.recordLastSeen();
      this.subscribeToFirestore();
      this.subscribeToTypingEvents();
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },

    sortDates(a, b) {
      let momentA = moment(a);
      let momentB = moment(b);

      if (momentA.isBefore(momentB)) {
        return -1;
      }

      return 1;
    },

    scrollToBottomOfThread() {
      Ember.run.scheduleOnce('afterRender', () => {
        let messageThread = document.getElementById('messages-area');

        if (messageThread) {
          messageThread.scrollTop = messageThread.scrollHeight;
        }
      });
    },

    recordLastSeen() {
      let inDistThread = this.get('isInDistributionListThread');

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/messages/record_last_seen`,
        method: 'POST',
        data: {
          viewing_distribution_list: inDistThread,
          company_id: this.get('company.id'),
          household_id: this.get('household.id')
        }
      }).then(response => {
        setTimeout(() => {
          if (inDistThread) {
            this.set('currentUser.user.distribution_list_last_seen', response.last_seen);
          } else {
            this.set('household.user_last_seen_message_thread', response.last_seen);
          }
        }, 3000);
      });
    },

    subscribeToFirestore() {
      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      let self = this;
      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_message_times`);

      if (collection) {
        let firestoreListener = collection.doc(`${self.get('company.id')}_${self.get('household.id')}`).onSnapshot(function (doc) {
          if (doc.exists) {
            _jquery.default.ajax({
              url: `${_environment.default.APP.host}/api/v2/messages`,
              method: 'GET',
              data: {
                household_id: self.get('household.id'),
                company_id: self.get('company.id'),
                last_message_time: self.get('lastMessageTime')
              }
            }).then(response => {
              let hasNewMessages = false;
              response.messages.forEach(message => {
                let messageIds = self.get('messages').map(message => parseInt(message.get('id')));

                if (!messageIds.includes(message.id)) {
                  let store = self.get('store');
                  self.get('messages').pushObject(store.createRecord('message', message));
                  hasNewMessages = true;
                }
              });

              if (hasNewMessages) {
                self.scrollToBottomOfThread();
                self.recordLastSeen();
              }
            });
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    },

    actions: {
      sendMessage() {
        let message = this.get('typedMessage');

        if (!message) {
          return;
        }

        this.set('typedMessage', '');
        let data = {
          message,
          household_id: this.get('household.id'),
          sent_by_staff: !this.get('isPetParent'),
          company_id: this.get('company.id'),
          show_in_grouped_messages: false
        };

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/plain_text`,
          method: 'POST',
          data
        }).then(response => {
          if (this.get('callbackAfterSentMessage')) {
            this.get('callbackAfterSentMessage')();
          }

          let store = this.get('store');
          this.get('messages').pushObject(store.createRecord('message', response));
          this.scrollToBottomOfThread();
          this.get('household').set('user_last_seen_message_thread', moment());
        });
      },

      sendImageMessage(file, finishedUploadingCallback) {
        this.set('uploadingPhoto', true);
        let fileType = file.type;
        let splitFileType = fileType.split('/');
        let fileName = file.name;

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/create_upload_url`,
          method: 'POST',
          data: {
            file_type: fileType
          }
        }).then(response => {
          let formData = new FormData();

          for (let key in response.fields) {
            let value = response.fields[key];
            formData.set(key, value);
          }

          return file.upload(response.url, {
            data: response.fields
          }).then(() => {
            return response.fields.key;
          });
        }).then(key => {
          let data = {
            image_url: key,
            household_id: this.get('household.id'),
            sent_by_staff: !this.get('isPetParent'),
            company_id: this.get('company.id'),
            show_in_grouped_messages: false,
            file_name: fileName,
            file_type: splitFileType[0] == 'application' ? splitFileType[1] : splitFileType[0]
          };
          return _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/image`,
            method: 'POST',
            data
          }).then(response => {
            if (this.get('callbackAfterSentMessage')) {
              this.get('callbackAfterSentMessage')();
            } else {
              let store = this.get('store');
              this.get('messages').pushObject(store.createRecord('message', response));
              this.scrollToBottomOfThread();
              this.get('household').set('user_last_seen_message_thread', moment());
            }

            Ember.run.schedule('afterRender', () => {
              if (finishedUploadingCallback) {
                finishedUploadingCallback();
              }
            });
            this.set('uploadingPhoto', false);
          });
        });
      },

      loadPastMessages() {
        let messageThread = document.getElementById('messages-area');
        let {
          scrollHeight
        } = messageThread;

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages`,
          method: 'GET',
          data: {
            household_id: this.get('household.id'),
            company_id: this.get('company.id'),
            first_message_time: this.get('firstMessageTime')
          }
        }).then(response => {
          if (response.messages.length == 0) {
            this.get('messages').set('reachedInfinity', true);
          } else {
            response.messages.forEach(message => {
              let messageIds = this.get('messages').map(message => parseInt(message.get('id')));

              if (!messageIds.includes(message.id)) {
                let store = this.get('store');
                this.get('messages').pushObject(store.createRecord('message', message));
              }
            });
            Ember.run.scheduleOnce('afterRender', () => {
              let messageThread = document.getElementById('messages-area');

              if (messageThread) {
                messageThread.scrollTop = messageThread.scrollHeight - scrollHeight;
              }
            });
          }
        });
      },

      viewReportCard(message) {
        this.set('showReportCard', true);
        this.set('loadingReportCard', true);
        this.get('store').findRecord('appointment', message.get('appointment_id')).then(appointment => {
          this.set('selectedReportCard', appointment.get('grouped_report_card'));
          this.set('loadingReportCard', false);
        });
      },

      closeReportCard() {
        this.set('showReportCard', false);
        this.set('selectedReportCard', null);
      },

      viewAppointmentDetails(message) {
        this.set('showAppointmentDetails', true);
        this.set('loadingAppointmentDetails', true);
        this.get('store').findRecord('appointment-request', message.get('appointment_request_id')).then(request => {
          this.set('selectedAppointmentRequest', request);
          this.set('loadingAppointmentDetails', false);
        });
      },

      closeAppointmentDetails() {
        this.set('showAppointmentDetails', false);
        this.set('selectedAppointmentRequest', null);
      },

      goToAppointmentRequest(message) {
        this.get('goToAppointmentRequest')(message.get('appointment_request_id'), message.get('company_id'), this.get('household').get('id'));
      },

      clickMoreOptions() {
        this.toggleProperty('showMenu');
      },

      transitionToHousehold() {
        this.get('transitionToHousehold')(this.get('household.id'));
        this.send('clickMoreOptions');
      },

      clickedArchiveThreadButton() {
        this.set('showArchiveThreadModal', true); // let message = "Archiving this thread will hide it from your inbox. When you receive a new message from this family, the thread will reappear along with your full conversation history.";
        // let no = (() => new Ember.RSVP.Promise((resolve) => resolve()));
        // this.get('flashMessage').confirm(this.archiveThread.bind(this), no, message, 'archive', 'just kidding');
      },

      closeArchiveThreadModal() {
        this.send('clickMoreOptions');
        this.set('showArchiveThreadModal', false);
      },

      archiveThread() {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/messages/archive_thread`,
          method: 'POST',
          data: {
            household_id: this.get('household.id')
          }
        }).then(() => {
          this.send('clickMoreOptions');
          this.get('transitionBackToInbox')().then(() => {
            window.location.reload();
          });
        });
      }

    }
  });

  _exports.default = _default;
});