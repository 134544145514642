define("frontend/components/choose-action-modal/choose-action-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['choose-action-modal'],
    firstActionStyle: 'red-white-border',
    secondActionStyle: 'red-white-border',
    thirdActionStyle: 'green',
    showCancel: true,
    disabled: false,
    loading: false,
    actions: {
      onClose() {
        if (this.onClose) {
          this.onClose();
        }
      }

    }
  });

  _exports.default = _default;
});