define("frontend/components/header-week-date-picker", ["exports", "frontend/components/header-date-picker", "moment"], function (_exports, _headerDatePicker, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _headerDatePicker.default.extend({
    classNames: ['header-date-picker', 'header-week-date-picker'],
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    momentStartDate: computed('date', 'company.weekType', function () {
      let weekType = this.get('company.weekType');
      return (0, _moment.default)(this.get('date')).startOf(weekType);
    }),
    momentEndDate: computed('date', 'company.weekType', function () {
      let weekType = this.get('company.weekType');
      return (0, _moment.default)(this.get('date')).endOf(weekType);
    })
  });

  _exports.default = _default;
});