define("frontend/components/sign-contracts-modal", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    observer,
    inject
  } = Ember;

  const redirectUserTo = path => {
    const {
      hostname,
      origin
    } = window.location;
    let url = `${origin}${path}`;

    if (hostname.includes("localhost")) {
      url = `http://${hostname}:4200${path}`;
    }

    window.location.replace(url);
  };

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    reactApp: inject.service(),
    media: inject.service(),
    router: inject.service(),
    isMobile: computed.alias('media.isMobile'),
    signatureRequests: [],
    signatureRequestsCopy: [],
    companyName: computed.alias('signatureRequests.firstObject.company'),
    signatureUrl: computed.alias('signatureRequests.firstObject.signature_url'),
    currentSignatureRequest: null,
    isIPad: computed(function () {
      //Older iPads have a user agent of iPad while newer ones use Macintosh
      return /iPad/i.test(navigator.userAgent) || /Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints > 1;
    }),
    pdfURL: computed.alias('currentSignatureRequest.pdf_document_url'),
    pdfURLForMobile: computed('pdfURL', function () {
      let encodedUrl = encodeURIComponent(this.get('pdfURL'));
      return `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
    }),
    urlForIFrame: computed('isMobile', 'isIPad', 'pdfURL', 'pdfURLForMobile', function () {
      const useGoogleViewer = this.get('isMobile') || this.get('isIPad');
      return useGoogleViewer ? this.get('pdfURLForMobile') : this.get('pdfURL');
    }),
    signatureName: null,
    signing: false,
    attemptedSign: false,
    vetLimitAmount: null,
    signContractParam: null,
    householdId: null,
    useSharedResourceLanguage: computed('signatureRequests', 'signContractParam', function () {
      const signatureRequests = this.get('signatureRequests');
      const isSharedResource = signatureRequests.firstObject.document_type === 'shared resource';
      const signContractParam = this.get('signContractParam');
      return signContractParam && isSharedResource || signatureRequests.length === 1 && isSharedResource;
    }),
    loadingIframe: false,
    filterSignatureRequestsWithCategoryID: null,
    appointmentRequestCategoryID: null,
    appointmentRequestCompanyID: null,
    appointmentRequestPetIDs: null,
    companyNames: computed('signatureRequests.@each.company', function () {
      let companies = this.get('signatureRequests').mapBy('company');
      let uniqueCompanies = [...new Set(companies)];
      return _stringUtil.default.joinWithAnd(uniqueCompanies);
    }),
    requestsToSignMessage: computed('signatureRequests', 'companyNames', function () {
      const requests = this.get('signatureRequests');
      const companyNames = this.get('companyNames');
      const defaultMessage = `${companyNames} has a document for you to review!`;

      if (requests.length === 1) {
        const requestType = requests.firstObject.document_type;
        const messages = {
          'service agreement': `${companyNames} has a service agreement for you to review and sign!`,
          'vet release & waiver': `${companyNames} has a vet release & waiver for you to review and sign!`,
          'optional release': `${companyNames} has a release for you to review and sign!`,
          'shared resource': defaultMessage
        };
        return messages[requestType] || defaultMessage;
      }

      return `${companyNames} has a few documents for you to review and sign before proceeding!`;
    }),

    didInsertElement() {
      this._super(...arguments);

      const idOfRequestToSign = this.get('signContractParam');
      const apptRqstCtgryID = this.get('appointmentRequestCategoryID');

      if ((idOfRequestToSign || apptRqstCtgryID) && this.get('isMobile')) {
        setTimeout(() => {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }

      const requests = this.get('signatureRequests');

      if (idOfRequestToSign && requests.length === 1) {
        this.set('currentSignatureRequest', requests.firstObject);
      }

      if (apptRqstCtgryID) {
        this.set('currentSignatureRequest', requests.firstObject);
      }
    },

    observeCurrentSignatureRequest: observer('currentSignatureRequest', function () {
      if (this.get('currentSignatureRequest')) {
        const urlForIFrame = this.get('urlForIFrame');
        const pdfURL = this.get('pdfURL');
        this.set('loadingIframe', true);

        if (urlForIFrame && pdfURL) {
          (0, _jquery.default)('#iframeContainer').empty();
          (0, _jquery.default)('.iframe-loader').css('display', 'block');

          const loadIFrame = () => {
            let stuff = document.evaluate('//*[@id="myIframe"]', document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            if (stuff && stuff.contentWindow && stuff.contentWindow.length) {
              (0, _jquery.default)('.iframe-loader').css('display', 'none');
              clearInterval(loadIFrameInterval);
            } else {
              const iframe = `<iframe id="myIframe" src="${urlForIFrame}" style="width:100%; height:100%; border: none;"></iframe>`;
              (0, _jquery.default)('#iframeContainer').html(iframe);
              count++;
            }
          };

          let count = 0;
          const loadIFrameInterval = setInterval(loadIFrame, 200);

          if (count > 15) {
            clearInterval(loadIFrameInterval);
            (0, _jquery.default)('.iframe-loader').css('display', 'none');
          }
        }
      }
    }),
    signContractParamObserver: observer('signContractParam', 'appointmentRequestCategoryID', function () {
      if ((this.get('signContractParam') || this.get('appointmentRequestCategoryID')) && this.get('isMobile')) {
        setTimeout(() => {
          (0, _jquery.default)('.portal-appointments-page').css('height', 0);
        }, 1000);
      }
    }),
    vetAmountErrorObserver: observer('vetLimitAmount', 'attemptedSign', function () {
      let show = this.get('attemptedSign') && (this.get('vetLimitAmount') == null || this.get('vetLimitAmount') <= 0);
      this.set('showVetAmountError', show);
    }),
    actions: {
      viewDocument() {
        let url = this.get('pdfURL');

        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
        }
      },

      close() {
        this.get('onClose')();
      },

      toggleVetLimitSwitch() {
        this.toggleProperty('vetLimitEnabled');

        if (!this.get('vetLimitEnabled')) {
          this.set('vetLimitAmount', null);
        }
      },

      reviewContractsLater() {
        this.get('reviewContractsLater')();
      },

      completedSigningForSpecificCategory() {
        this.get('completedSigningForSpecificCategory')();
        this.send('reviewContractsLater');
      },

      openSignatureRequest() {
        this.set('currentSignatureRequest', this.get('signatureRequests.firstObject'));
      },

      signDocument() {
        let agreedToRelease = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.get('signing')) {
          return;
        }

        this.set('attemptedSign', true);
        let index = this.get('signatureRequests').indexOf(this.get('currentSignatureRequest'));
        this.set('signing', true);

        _jquery.default.ajax({
          url: `/api/v2/client_contracts/sign_contract`,
          method: 'POST',
          data: {
            signature_request_id: this.get('currentSignatureRequest.id'),
            signature_name: this.get('signatureName'),
            agreed_to_release: agreedToRelease,
            vet_limit_amount: this.get('vetLimitAmount')
          }
        }).then(() => {
          this.set('signing', false);
          this.set('attemptedSign', false);

          if (index < this.get('signatureRequests.length') - 1) {
            this.set('currentSignatureRequest', this.get('signatureRequests').objectAt(index + 1));
            this.set('signatureName', null);
          } else {
            this.get('currentUser').set('hasAgreementsToReview', false);
            const signContractParam = this.get("signContractParam");
            const apptRqstCtgryID = this.get("appointmentRequestCategoryID");
            const householdId = this.get("householdId");

            if (signContractParam) {
              const reactPortalDocsPath = `/react/portal/${householdId}/documents`;
              redirectUserTo(reactPortalDocsPath);
            } else if (apptRqstCtgryID) {
              const apptRqstCmpnyID = this.get('appointmentRequestCompanyID');
              const apptRqstPtIDs = this.get('appointmentRequestPetIDs').split(',');
              const apptRqstCtgryID = this.get('appointmentRequestCategoryID');
              window.location.href = this.get('reactApp').getAppointmentRequestUrl(apptRqstCmpnyID, null, null, apptRqstPtIDs, apptRqstCtgryID);
            } else {
              if (this.get('filterSignatureRequestsWithCategoryID')) {
                this.send('completedSigningForSpecificCategory');
              } else {
                window.location.reload();
              }
            }
          }
        }).catch(e => {
          console.log('IN CATCH BLOCK', e);
        });
      }

    }
  });

  _exports.default = _default;
});