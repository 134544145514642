define("frontend/helpers/format-date-with-today", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDateWithToday = formatDateWithToday;

  function formatDateWithToday(_ref) {
    let [date] = _ref;
    let today = (0, _moment.default)().startOf('day');
    date = (0, _moment.default)(date, 'YYYY-MM-DD');

    if (date.diff(today, 'days') === 0) {
      return `today, ${date.format('MMM D')}`;
    } else if (date.diff(today, 'days') === -1) {
      return `yesterday, ${date.format('MMM D')}`;
    }

    return date.format('dddd, MMM D, YYYY');
  }

  var _default = Ember.Helper.helper(formatDateWithToday);

  _exports.default = _default;
});