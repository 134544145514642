define("frontend/components/invoice-receipt/adjustment-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['adjustment-list-item'],
    classNameBindings: ['editing', 'indentAdjustments', 'showEdit'],
    emptyDescription: computed.empty('adjustment.description'),
    salesTaxRate: computed('adjustment.sales_tax_rate', function () {
      const rate = this.adjustment.sales_tax_rate;

      if (typeof rate === 'number') {
        return rate.toFixed(3).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1');
      }

      return rate;
    })
  });

  _exports.default = _default;
});