define("frontend/controllers/profile/edit-about-me", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.first_name': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'This is required'
    })],
    'model.last_name': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'This is required'
    })],
    'model.emails': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'This is required'
    })],
    'model.phone_numbers': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'This is required'
    })],
    'model.addresses': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true,
      message: 'This is required'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    hasAddress: computed.notEmpty('model.zipcode'),
    profileController: Ember.inject.controller('profile'),
    saving: computed.alias('profileController.saving'),
    canSave: computed.readOnly('validations.isValid'),
    saveDisabled: computed.readOnly('validations.isInvalid'),
    defaultScheduleOptions: [{
      value: 'self',
      label: 'My schedule'
    }, {
      value: 'all',
      label: 'All staff'
    }],
    selectedDefaultSchedule: computed('model.default_schedule', 'defaultScheduleOptions.[]', function () {
      return this.get('defaultScheduleOptions').filterBy('value', this.get('model.default_schedule')).get('firstObject');
    }),
    actions: {
      save(model) {
        if (!this.get('canSave')) {
          return;
        }

        this.set('saving', true);
        model.save().then(() => this.transitionToRoute('schedule', {
          queryParams: {
            care_provider_id: null
          }
        })).finally(() => this.set('saving', false));
      },

      deactivateSidePanel() {
        this.set('anySidePanelActive', false);
      },

      activateSidePanel() {
        this.set('anySidePanelActive', true);
      }

    }
  });

  _exports.default = _default;
});