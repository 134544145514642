define("frontend/helpers/multiple-pet-names", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.multiplePetNames = multiplePetNames;

  function multiplePetNames(_ref) {
    let [pets] = _ref;
    let names = pets.map(pet => pet.name);
    return _stringUtil.default.joinWithAnd(names);
  }

  var _default = Ember.Helper.helper(multiplePetNames);

  _exports.default = _default;
});