define("frontend/components/dismissable-message-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    media: inject.service(),
    customContainerClassNames: computed('containerClass', function () {
      let containerClass = this.get('containerClass');
      let className = 'dismissable-message-modal';

      if (containerClass) {
        className = `${className} ${containerClass}`;
      }

      return className;
    }),
    overlayClassNames: computed('media.isMobile', function () {
      return this.get('media.isMobile') ? 'ember-modal-overlay--small-mobile' : '';
    }),
    actions: {
      onClose() {
        this.onClose();
      },

      onDismissedMessage() {
        this.onDismissedMessage();
      }

    }
  });

  _exports.default = _default;
});