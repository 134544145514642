define("frontend/models/household", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    users: _emberData.default.hasMany('household-user', {
      async: true
    }),
    pets: _emberData.default.hasMany('pets', {
      async: true
    }),
    emails: _emberData.default.hasMany('emails', {
      async: true
    }),
    account_balance: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    bill_recipient_emails: _emberData.default.hasMany('email', {
      async: true
    }),
    emergency_contacts: _emberData.default.hasMany('emergency-contact'),
    preferred_staff: _emberData.default.hasMany('preferred-staff'),
    anyBillDeliveryRecipients: computed.notEmpty('bill_recipient_emails'),
    noBillDeliveryRecipients: computed.not('anyBillDeliveryRecipients'),
    offered_service_client_prices: _emberData.default.hasMany('offered-service-client-price', {
      async: true
    }),
    client_status: _emberData.default.attr(),
    owner_id: _emberData.default.attr('number'),
    billing_period_start_date: _emberData.default.attr(),
    billing_period_end_date: _emberData.default.attr(),
    next_billing_period_start_date: _emberData.default.attr(),
    next_billing_period_end_date: _emberData.default.attr(),
    active: _emberData.default.attr(),
    show_in_client_list: _emberData.default.attr('boolean'),
    locked: _emberData.default.attr('boolean'),
    request_sent_to: _emberData.default.attr(),
    last_message_text: _emberData.default.attr(),
    last_message_time: _emberData.default.attr(),
    user_last_seen_message_thread: _emberData.default.attr(),
    pending_request_category: _emberData.default.attr(),
    esignature_status: _emberData.default.attr(),
    owner: computed('owner_id', 'users.[]', function () {
      return this.get('users').filterBy('id', `${this.get('owner_id')}`).get('firstObject');
    }),
    alphaSortedPets: computed('pets.@each.name', function () {
      return this.get('pets').sortBy('name');
    }),
    petNames: computed('alphaSortedPets.[]', function () {
      return _stringUtil.default.joinWithAnd(this.get('alphaSortedPets').map(pet => pet.get('name')));
    }),
    secondaryContact: computed('users.[]', 'owner', function () {
      return this.get('users').filter(user => {
        return this.get('owner.id') != user.get('id');
      })[0];
    }),
    firstNames: computed('users.[]', 'owner', 'secondaryContact', function () {
      let owner = this.get('owner');
      let secondaryContact = this.get('secondaryContact');

      if (secondaryContact) {
        return `${owner.get('getFirstName')} & ${secondaryContact.get('getFirstName')}`;
      } else {
        return owner.get('getFirstName');
      }
    }),
    householdNames: computed('owner', 'secondaryContact', function () {
      const primaryHuman = this.get('owner');
      const secondaryHuman = this.get('secondaryContact');

      if (secondaryHuman) {
        const primaryLastName = primaryHuman.last_name;
        const secondaryLastName = secondaryHuman.last_name;

        if (primaryLastName === secondaryLastName) {
          return `${primaryHuman.getFirstName} & ${secondaryHuman.getFirstName} ${primaryHuman.last_name}`;
        } else {
          return `${primaryHuman.getFirstName} ${primaryHuman.last_name} & ${secondaryHuman.getFirstName} ${secondaryHuman.last_name}`;
        }
      }

      return `${primaryHuman.getFirstName} ${primaryHuman.last_name}`;
    }),
    isPendingStatus: computed('client_status', function () {
      return this.get('client_status') == 'pending' || this.get('client_status') == 'denied';
    }),
    isPendingSignatures: computed('esignature_status', function () {
      return this.get('esignature_status') == 'none' || this.get('esignature_status') == 'sent';
    })
  });

  _exports.default = _default;
});