define("frontend/components/avatar-images-with-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['avatar-images-with-names'],
    avatarables: [],
    sortedAvatarables: computed('avatarables.@each.getFirstName', function () {
      return this.get('avatarables').sortBy('getFirstName');
    }),
    names: computed('sortedAvatarables.@each.{fullName,getFirstName}', function () {
      let avatarables = this.get('sortedAvatarables');

      if (avatarables.get('length') === 1) {
        return avatarables.get('firstObject.fullName');
      }

      return avatarables.mapBy('getFirstName').join(', ');
    })
  });

  _exports.default = _default;
});