define("frontend/components/household-pet", ["exports", "frontend/utils/scroll-util"], function (_exports, _scrollUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    activeSection: 'basics',
    classNames: [''],
    currentUser: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    media: inject.service(),
    notesActive: false,
    medicalActive: false,
    behavioralActive: false,
    pet: null,
    selectedSection: 'basics',
    test: null,
    haveStoredPositions: false,
    basicsPosition: null,
    medicalPosition: null,
    behavioralPosition: null,
    notesPosition: null,
    showTabBar: false,
    companyNotes: computed('pet.company_notes', function () {
      const notes = this.get('pet.company_notes') || [];
      return notes.filter(note => note.notes).map(_ref => {
        let {
          company_logo,
          company_initial,
          notes,
          company_name
        } = _ref;
        return {
          companyLogo: company_logo,
          companyInitial: company_initial,
          notes: notes,
          companyName: company_name
        };
      });
    }),
    showBasicInfoSection: computed.or('pet.gender', 'pet.birthday', 'pet.microchip_number', 'pet.rabies_tag_number'),
    showMedicalInfoSection: computed('pet.insurance.name', 'pet.veterinarian_clinic', 'pet.vaccinations', 'pet.pet_prescriptions', 'pet.allergies', 'pet.past_injuries', 'pet.medical_extra_notes', function () {
      return !!(this.get('pet.insurance.name') || this.get('pet.veterinarian_clinic') || this.get('pet.vaccinations') || this.get('pet.pet_prescriptions') || this.get('pet.allergies') || this.get('pet.past_injuries') || this.get('pet.medical_extra_notes'));
    }),
    showBehavioralInfoSection: computed.or('pet.gets_along_with_cats', 'pet.gets_along_with_dogs', 'pet.gets_along_with_humans', 'pet.phobias', 'pet.potty_habits', 'pet.diet', 'pet.house_rules', 'pet.off_leash_notes', 'pet.commands', 'pet.translations', 'pet.behavioral_extra_notes'),
    getsAlongWithCatsImgSource: computed('pet.gets_along_with_cats', function () {
      const getsAlongWithCats = this.get('pet.gets_along_with_cats');

      if (getsAlongWithCats === 'Yes') {
        return 'assets/images/gets-along-with-yes.svg';
      } else if (getsAlongWithCats === 'No') {
        return 'assets/images/gets-along-with-no.svg';
      } else {
        return 'assets/images/gets-along-with-question.svg';
      }
    }),
    getsAlongWithDogsImgSource: computed('pet.gets_along_with_dogs', function () {
      const getsAlongWithDogs = this.get('pet.gets_along_with_dogs');

      if (getsAlongWithDogs === 'Yes') {
        return 'assets/images/gets-along-with-yes.svg';
      } else if (getsAlongWithDogs === 'No') {
        return 'assets/images/gets-along-with-no.svg';
      } else {
        return 'assets/images/gets-along-with-question.svg';
      }
    }),
    getsAlongWithHumansImgSource: computed('pet.gets_along_with_humans', function () {
      const getsAlongWithHumans = this.get('pet.gets_along_with_humans');

      if (getsAlongWithHumans === 'Yes') {
        return 'assets/images/gets-along-with-yes.svg';
      } else if (getsAlongWithHumans === 'No') {
        return 'assets/images/gets-along-with-no.svg';
      } else {
        return 'assets/images/gets-along-with-question.svg';
      }
    }),
    genderValue: computed("pet.spayed_or_neutered", "pet.gender", function () {
      const spayedOrNeutered = this.get("pet.spayed_or_neutered");
      const gender = this.get("pet.gender");
      let genderPrefix = "intact ";

      if (spayedOrNeutered) {
        genderPrefix = gender === "male" ? "neutered " : "spayed ";
      }

      return `${genderPrefix}${gender}`;
    }),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    showMedical: computed('medicalActive', 'media.isMobile', 'pet.hasMedical', function () {
      if (this.get('media.isMobile')) {
        return this.get('pet.hasMedical') && this.get('medicalActive');
      } else {
        return this.get('pet.hasMedical');
      }
    }),
    showBehavioral: computed('behavioralActive', 'media.isMobile', 'pet.hasBehavioral', function () {
      if (this.get('media.isMobile')) {
        return this.get('pet.hasBehavioral') && this.get('behavioralActive');
      } else {
        return this.get('pet.hasBehavioral');
      }
    }),
    showNotes: computed('isPetParent', 'notesActive', 'media.isMobile', 'pet.hasNotes', 'pet.hasCompanyNotes', function () {
      if (this.get('media.isMobile')) {
        if (this.get('isPetParent')) {
          return this.get('pet.hasCompanyNotes') && this.get('notesActive');
        } else {
          return this.get('pet.hasNotes') && this.get('notesActive');
        }
      } else {
        if (this.get('isPetParent')) {
          return this.get('pet.hasCompanyNotes');
        } else {
          return this.get('pet.hasNotes');
        }
      }
    }),
    emergencyContacts: computed('pet.emergency_contacts.@each', function () {
      const contacts = this.get('pet.emergency_contacts');

      if (!contacts) {
        return [];
      }

      return contacts.map((contact, index) => {
        let contactProperties = contact.getProperties('fullName', 'addresses', 'phone_numbers', 'emails');
        return { ...contactProperties,
          lastItem: index === contacts.length - 1
        };
      });
    }),
    actions: {
      clickSection(section) {
        this.get('clickSection')(section);
      },

      goToPetInsurance() {
        const insuranceWebsite = this.get('pet.insurance.website');

        if (insuranceWebsite) {
          window.open(insuranceWebsite, '_blank');
        }
      },

      scrollTo(selectorID) {
        this.set('selectedSection', selectorID);
        let topOfElement = $('.page-wrap').scrollTop() + $(`#${selectorID}`).offset().top - 180;
        document.querySelector('.page-wrap').scrollTo({
          top: topOfElement
        });
      },

      handleOnScroll(isMobile) {
        if (!isMobile) return;
        const topOffset = 180;
        let petNameElement = $('.pet-or-user-information__full-name').offset().top;

        if (petNameElement <= 75) {
          this.set('showTabBar', true);

          if (this.setMobileNavText) {
            //Only passed in from pet parent context
            if (this.get('pet.name')) {
              this.setMobileNavText(`about ${this.get('pet.name')}`);
            } else {
              this.setMobileNavText('Family Profile');
            }
          }
        } else {
          if (this.setMobileNavText) {
            //Only passed in from pet parent context
            this.setMobileNavText('Family Profile');
          }

          this.set('showTabBar', false);
        }

        const showBasicsSection = this.get('showBasicInfoSection') && $('#basics').length;
        const showMedicalSection = this.get('showMedicalInfoSection') && $('#medical').length;
        const showBehavioralSection = this.get('showBehavioralInfoSection') && $('#behavioral').length;
        const showNotesSection = (this.get('pet.internal_user_notes') || this.get('pet.client_notes') || this.get('showNotes')) && $('#notes').length;
        const basicsInitialPosition = this.get('basicsPosition');
        const medicalInitialPosition = this.get('medicalPosition');
        const behavioralInitialPosition = this.get('behavioralPosition');
        const notesInitialPosition = this.get('notesPosition');

        if (!this.get('haveStoredPositions')) {
          if (showBasicsSection) this.set('basicsPosition', $('#basics').offset().top);
          if (showMedicalSection) this.set('medicalPosition', $('#medical').offset().top);
          if (showBehavioralSection) this.set('behavioralPosition', $('#behavioral').offset().top);
          if (showNotesSection) this.set('notesPosition', $('#notes').offset().top);
          this.set('haveStoredPositions', true);
        }

        let currentBasicsPosition = undefined;
        let currentMedicalPosition = undefined;
        let currentBehavioralPosition = undefined;
        let currentNotesPosition = undefined;
        if (showBasicsSection) currentBasicsPosition = $('#basics').offset().top;
        if (showMedicalSection) currentMedicalPosition = $('#medical').offset().top;
        if (showBehavioralSection) currentBehavioralPosition = $('#behavioral').offset().top;
        if (showNotesSection) currentNotesPosition = $('#notes').offset().top;

        if (showBasicsSection) {
          let nextSectionPosition = showMedicalSection ? medicalInitialPosition : showBehavioralSection ? behavioralInitialPosition : showNotesSection ? notesInitialPosition : undefined;

          if (nextSectionPosition !== undefined) {
            let basicsDif = basicsInitialPosition - nextSectionPosition;
            if (currentBasicsPosition >= basicsDif + topOffset && currentBasicsPosition <= topOffset) this.set('selectedSection', 'basics');
          } else {
            if (currentBasicsPosition <= topOffset) this.set('selectedSection', 'basics');
          }
        }

        if (showMedicalSection) {
          let nextSectionPosition = showBehavioralSection ? behavioralInitialPosition : showNotesSection ? notesInitialPosition : undefined;

          if (nextSectionPosition !== undefined) {
            let medicalDif = medicalInitialPosition - nextSectionPosition;
            if (currentMedicalPosition >= medicalDif + topOffset && currentMedicalPosition <= topOffset) this.set('selectedSection', 'medical');
          } else {
            if (currentMedicalPosition <= topOffset) this.set('selectedSection', 'medical');
          }
        }

        if (showBehavioralSection) {
          let nextSectionPosition = showNotesSection ? notesInitialPosition : undefined;

          if (nextSectionPosition !== undefined) {
            let behavioralDif = behavioralInitialPosition - nextSectionPosition;
            if (currentBehavioralPosition >= behavioralDif + topOffset && currentBehavioralPosition <= topOffset) this.set('selectedSection', 'behavioral');
          } else {
            if (currentBehavioralPosition <= topOffset) this.set('selectedSection', 'behavioral');
          }
        }

        if (showNotesSection) {
          if (currentNotesPosition <= topOffset) this.set('selectedSection', 'notes');
        }
      }

    }
  });

  _exports.default = _default;
});