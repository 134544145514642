define("frontend/components/add-client/client-inputs", ["exports", "frontend/utils/phone-number-type", "frontend/utils/email-type"], function (_exports, _phoneNumberType, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;
  const EMAIL_REGEX = /.+@.+\..+/;
  const DUPLICATE_EMAIL_ERROR = 'This email already exists!';
  const MUST_HAVE_PREFERRED_EMAIL_ERROR = 'You must have a preferred email!';
  const DUPLICATE_EMAILS_IN_FORM = 'Emails must not be duplicated!';
  const HEADER_HEIGHT = 84;

  var _default = Ember.Component.extend({
    classNames: ['inputs-container'],
    householdUser: null,
    household: null,
    isOnAboutMePage: false,
    store: inject.service(),
    currentUser: inject.service(),
    pronounOptions: ['he | him', 'she | her', 'they | them', 'rather not say'],
    scheduleOptions: [{
      value: 'self',
      label: 'My schedule'
    }, {
      value: 'all',
      label: 'All staff'
    }],
    phoneTypes: [{
      label: 'Mobile',
      value: _phoneNumberType.default.MOBILE
    }, {
      label: 'Work',
      value: _phoneNumberType.default.WORK
    }, {
      label: 'Home',
      value: _phoneNumberType.default.HOME
    }, {
      label: 'Other',
      value: _phoneNumberType.default.OTHER
    }],
    emailTypes: [{
      label: 'Preferred',
      value: _emailType.default.PREFERRED
    }, {
      label: 'Alternate',
      value: _emailType.default.ALTERNATE
    }],
    duplicateEmail: inject.service(),
    emailValidation: [{
      message: '*must be a valid email',
      validate: inputValue => {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    emailErrors: [],
    emails: computed('householdUser.emails.@each.isDeleted', function () {
      return this.get('householdUser.emails').filterBy('isDeleted', false);
    }),
    addresses: computed('householdUser.addresses.@each.isDeleted', function () {
      return this.get('householdUser.addresses').filterBy('isDeleted', false);
    }),
    phoneNumbers: computed('householdUser.phone_numbers.@each.isDeleted', function () {
      return this.get('householdUser.phone_numbers').filterBy('isDeleted', false);
    }),
    preferredNameLabel: computed('isOnAboutMePage', function () {
      return this.get('isOnAboutMePage') ? 'I prefer to go by' : 'prefers to go by';
    }),
    genderLabel: computed('isOnAboutMePage', function () {
      return this.get('isOnAboutMePage') ? 'I identify as' : 'identifies as';
    }),
    actions: {
      scrollTo(selector) {
        let topOfElement = document.querySelector(selector).offsetTop - HEADER_HEIGHT;
        document.querySelector('.page-wrap').scrollTo({
          top: topOfElement
        });
      },

      deleteRecord(record) {
        record.deleteRecord();
      },

      deleteEmail(email) {
        email.deleteRecord();

        if (email.get('email_type') === _emailType.default.PREFERRED) {
          this.get('emails').get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },

      submit() {
        let promises = [];
        this.set('duplicateEmails', false);
        this.set('preferredEmailIsBlank', false);
        this.set('duplicateEmailsInForm', false);
        this.get('householdUser.emails').forEach(email => {
          if (!email.get('isDeleted')) {
            promises.pushObject(this.get('duplicateEmail').check(email.get('address'), this.get('household')).then(response => {
              let emails = this.get('emails').getEach('address');
              let isDuplicateInForm = emails.map(address => address.toLowerCase()).uniq().length !== emails.length;
              let emailTypes = this.get('emails').getEach('email_type');

              if (response.duplicate && !response.part_of_household) {
                email.set('errs', [DUPLICATE_EMAIL_ERROR]);
                this.set('duplicateEmails', true);
              } else if (Ember.isEqual(email.get('email_type'), _emailType.default.PREFERRED) && Ember.isBlank(email.get('address')) || !emailTypes.includes(_emailType.default.PREFERRED)) {
                email.set('errs', [MUST_HAVE_PREFERRED_EMAIL_ERROR]);
                this.set('preferredEmailIsBlank', true);
              } else if (isDuplicateInForm) {
                this.get('emails').forEach(email => email.set('errs', []));
                email.set('errs', [DUPLICATE_EMAILS_IN_FORM]);
                this.set('duplicateEmailsInForm', true);
              } else {
                email.set('errs', []);
              }

              email.set('isTouched', true);
            }));
          }
        });
        Ember.RSVP.Promise.all(promises).then(() => {
          if (!this.get('duplicateEmails') && !this.get('preferredEmailIsBlank') && !this.get('duplicateEmailsInForm')) {
            this.get('onSubmit')(this.get('householdUser'));
          } else {
            this.send('onInvalid');
          }
        });
      },

      checkDuplicateEmail(email) {
        if (email.get('hasDirtyAttributes')) {
          if (!EMAIL_REGEX.test(email.get('address'))) {
            return;
          }

          this.set('checkingEmail', true);
          this.get('duplicateEmail').check(email.get('address'), this.get('household')).then(response => {
            if (response.duplicate && !response.part_of_household) {
              email.set('errs', [DUPLICATE_EMAIL_ERROR]);
            } else {
              email.set('errs', []);
            }
          }).finally(() => {
            this.set('checkingEmail', false);
          });
        } else {
          email.set('errs', []);
        }
      },

      addPhone() {
        let newPhone = this.get('store').createRecord('phone-number');
        this.get('householdUser.phone_numbers').pushObject(newPhone);
      },

      addEmail() {
        let newEmail = this.get('store').createRecord('email');
        this.get('householdUser.emails').pushObject(newEmail);
      },

      addAddress() {
        let state = this.get('currentUser.user.company.state');
        let country = this.get('currentUser.user.company.country');
        let newAddress = this.get('store').createRecord('address', {
          state,
          country
        });
        this.get('householdUser.addresses').pushObject(newAddress);
      },

      rollbackAddress() {
        if (!this.get('editingAddress.isNew')) {
          this.get('editingAddress').rollbackAttributes();
        }

        this.set('editingAddress', null);

        if (this.get('householdUser.addresses.length') === 0) {
          this.send('addAddress');
        }
      },

      submitAddress() {
        this.set('editingAddress', null);
      },

      changePhoneType(phone, phoneType) {
        phone.set('phone_type_id', phoneType.value);
      },

      changeEmailType(email, emailType) {
        let hasPreferredEmail = this.get('emails').some(email => email.get('email_type') === _emailType.default.PREFERRED);

        if (hasPreferredEmail && emailType.value === _emailType.default.PREFERRED) {
          this.get('emails').forEach(email => email.set('email_type', _emailType.default.ALTERNATE));
        }

        email.set('email_type', emailType.value);
        hasPreferredEmail = this.get('emails').some(email => email.get('email_type') === _emailType.default.PREFERRED);

        if (!hasPreferredEmail) {
          email.set('errs', []);
          this.get('emails').filter(filteredEmail => filteredEmail.get('address') !== email.get('address')).get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },

      // Were calling submit in onInvalid because ember forms has some issues
      // with errors and customValidations being used together with rendering
      // timing
      onInvalid() {
        setTimeout(() => {
          if ($('.first-name-input input').hasClass('ng-invalid')) {
            this.send('scrollTo', '.first-name-input');
          } else if ($('.last-name-input input').hasClass('ng-invalid')) {
            this.send('scrollTo', '.last-name-input');
          } else {
            let phoneError = false;
            $('.phone-inputs input').each((index, input) => {
              if ($(input).hasClass('ng-invalid')) {
                phoneError = true;
              }
            });

            if (phoneError) {
              this.send('scrollTo', '.phone-inputs');
            } else {
              let emailError = false;
              $('.email-inputs input').each((index, input) => {
                if ($(input).hasClass('ng-invalid')) {
                  emailError = true;
                }
              });

              if (emailError) {
                this.send('scrollTo', '.email-inputs');
              } else {
                let addressError = false;
                $('.address-container input').each((index, input) => {
                  if ($(input).hasClass('ng-invalid')) {
                    addressError = true;
                  }
                });

                if (addressError) {
                  this.send('scrollTo', '.address-container');
                } else {
                  this.send('submit');
                }
              }
            }
          }
        }, 500);
      },

      changeGender(gender) {
        this.set('householdUser.gender', gender);
      },

      changeDefaultSchedule(schedule) {
        this.set('householdUser.default_schedule', schedule.value);
      }

    }
  });

  _exports.default = _default;
});