define("frontend/components/calendar-options", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['calendar-options'],
    calendarOptions: Ember.inject.service('calendarOptions'),
    hidingInactiveStaff: computed.alias('calendarOptions.hidingInactiveStaff'),
    store: Ember.inject.service(),
    careProviders: computed(function () {
      return this.get('store').peekAll('care-provider');
    }),
    actions: {
      close() {
        this.get('close')();
      },

      sortEndAction() {
        this.get('careProviders').forEach((careProvider, index) => {
          careProvider.set('calendar_order', index);
        });
        let sorted_care_provider_ids = this.get('careProviders').mapBy('id');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/care_providers/reposition`,
          method: 'PUT',
          data: {
            sorted_care_provider_ids
          }
        });
      }

    }
  });

  _exports.default = _default;
});