define("frontend/components/pet-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: 'img',
    attributeBindings: ['src'],
    placeholder: 'assets/images/pet-placeholder@3x.png',
    src: computed('avatarUrl', 'placeholder', function () {
      return this.get('avatarUrl') || this.get('placeholder');
    })
  });

  _exports.default = _default;
});