define("frontend/mixins/transition-back-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    deactivate() {
      this._super(...arguments);

      this.set('controller.backRoute', null);
      this.set('controller.backHouseholdId', null);
      this.set('controller.backId', null);
    },

    transitionBack(backRoute) {
      if (backRoute == null) {
        return this.transitionTo('index');
      } else if (/http/.test(backRoute)) {
        window.location.href = backRoute;
      } else {
        let nonNilArgs = Array.prototype.slice.call(arguments).filter(arg => !!arg && arg !== 'null');
        return this.transitionTo(...nonNilArgs);
      }
    }

  });

  _exports.default = _default;
});