define("frontend/components/ppb-time-autocomplete-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-times-autocomplete'],
    media: inject.service(),
    stepMinutes: 15,
    startingHour: 6,
    options: {
      interval: 30,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      minTime: '6:00AM',
      maxTime: '11:30PM',
      startTime: '6:00AM',
      timeFormat: 'h:mm p',
      momentFormat: 'h:mm A'
    },
    placeholder: computed('media.isMobile', function () {
      if (this.get('media.isMobile')) {
        return 'Starts at';
      }

      return '';
    }),
    actions: {
      onChange() {
        this.get('onChange')(...arguments);
      }

    }
  });

  _exports.default = _default;
});