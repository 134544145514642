define("frontend/components/new-user-login-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      reset() {
        this.get('transitionToReset')();
      },

      login() {
        this.get('transitionToLogin')();
      }

    }
  });

  _exports.default = _default;
});