define("frontend/components/holiday-surcharge-holiday-list-item", ["exports", "frontend/mixins/dates-to-ranges"], function (_exports, _datesToRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend(_datesToRanges.default, {
    classNames: ['holiday-list-item'],
    showSwitches: true,
    holidayName: null,
    shortenedName: null,
    icon: null,
    holidayDate: null,
    defaultSelectedDates: [],
    toggleIsOn: computed('holidaySurchargePolicy.dates.[]', 'datesToShow.[]', function () {
      let dates = this.get('datesToShow');
      let selectedDates = this.get('holidaySurchargePolicy.dates');

      if (selectedDates) {
        return dates.any(date => {
          return selectedDates.includes(date.format('YYYY-MM-DD'));
        });
      }
    }),
    iconUrl: computed('icon', function () {
      return `assets/images/holiday-icons/${this.get('icon')}`;
    }),
    holidayDisplayName: computed('holidayName', 'shortenedName', function () {
      return this.get('shortenedName') || this.get('holidayName');
    }),
    datesToShow: computed('holidayDate', function () {
      let datesToShow = [];
      let middleDate = moment(this.get('holidayDate'));
      datesToShow.push(middleDate);

      for (let i = 1; i <= 3; i++) {
        datesToShow.push(middleDate.clone().add(i, 'day'));
        datesToShow.unshift(middleDate.clone().subtract(i, 'day'));
      }

      return datesToShow;
    }),
    switchLabel: computed('holidayName', 'datesToShow.[]', 'holidaySurchargePolicy.dates.[]', function () {
      let selectedMomentDates = this.get('datesToShow').filter(date => {
        return this.get('holidaySurchargePolicy.dates').includes(date.format('YYYY-MM-DD'));
      });
      let selectedDates = selectedMomentDates.map(date => date.format('YYYY-MM-DD'));
      let label = this.get('holidayName');

      if (selectedDates.length > 0) {
        label += ` <span class="dates-range">(${this.datesToRanges(selectedDates).join(', ')})</span>`;
      }

      return label;
    }),
    actions: {
      toggleHoliday() {
        if (this.get('toggleIsOn')) {
          let dates = this.get('datesToShow');
          dates.forEach(date => {
            this.get('holidaySurchargePolicy.dates').removeObject(date.format('YYYY-MM-DD'));
          });
        } else {
          this.get('defaultSelectedDates').forEach(date => {
            this.get('holidaySurchargePolicy.dates').pushObject(date);
          });
        }
      }

    }
  });

  _exports.default = _default;
});