define("frontend/services/validations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;

  var _default = Ember.Service.extend({
    init: function () {
      set(this, 'cache', {});
    }
  });

  _exports.default = _default;
});