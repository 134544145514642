define("frontend/models/emergency-contact-phone-number", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: false,
      ignoreBlank: true
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    emergency_contact: _emberData.default.belongsTo('emergency-contact'),
    number: _emberData.default.attr('string'),
    phone_type: _emberData.default.attr('string', {
      defaultValue: 'Mobile'
    }),
    isMobile: computed.equal('phone_type', 'Mobile')
  });

  _exports.default = _default;
});