define("frontend/routes/edit-single-appointment", ["exports", "frontend/routes/base-route", "frontend/mixins/transition-back-route", "frontend/config/environment", "moment", "jquery"], function (_exports, _baseRoute, _transitionBackRoute, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      ids: {
        replace: true
      },
      requestId: {
        replace: true
      }
    },
    wasUnassigned: false,

    model(_ref) {
      let {
        ids,
        requestId
      } = _ref;

      this._super(...arguments);

      let offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      let careProviders = this.store.query('care-provider', {}, {
        reload: true
      });
      let modelHash = {
        offeredServices,
        careProviders
      };

      if (requestId) {
        modelHash.appointmentRequest = this.store.findRecord('appointment-request', requestId);
      } else {
        modelHash.appointments = Ember.RSVP.Promise.all(ids.split(',').map(id => this.store.findRecord('appointment', id, {
          reload: true
        })));
      }

      return Ember.RSVP.hash(modelHash);
    },

    afterModel(model) {
      this._super(...arguments);

      if (model.appointmentRequest) {
        let preferredStaff = model.appointmentRequest.get('household.preferred_staff.firstObject');
        let preferredStaffId = preferredStaff ? preferredStaff.get('user_id') : this.get('currentUser.user.id');
        let appointments = model.appointmentRequest.get('pets').map(pet => {
          if (!model.appointmentRequest.get('care_provider.id')) {
            this.set('wasUnassigned', true);
          } else {
            this.set('wasUnassigned', false);
          }

          let careProviderId = model.appointmentRequest.get('care_provider.id') || preferredStaffId;
          let care_provider = model.careProviders.filter(careProvider => careProvider.get('id') == careProviderId).get('firstObject');
          let add_on_services = model.appointmentRequest.get('add_on_services');
          let pet_add_on_services = add_on_services.filter(aos => aos.pets.mapBy('id').includes(parseInt(pet.get('id'))));
          pet_add_on_services = pet_add_on_services.map(add_on_service => {
            return this.store.createRecord('appointment-add-on-service', {
              offered_service: model.offeredServices.find(os => os.get('id') == add_on_service.offered_service.id)
            });
          });
          return this.store.createRecord('appointment', {
            service_starts_at: model.appointmentRequest.get('starts_at'),
            time_frame_start_time: model.appointmentRequest.get('time_frame_start_time'),
            time_frame_end_time: model.appointmentRequest.get('time_frame_end_time'),
            offered_service: model.appointmentRequest.get('offered_service'),
            care_provider,
            appointment_add_on_services: pet_add_on_services,
            set_by_custom_window: false,
            pet
          });
        });
        model.appointments = appointments;
      }

      let firstAppointment = model.appointments.get('firstObject');

      let momentTime = _moment.default.parseZone(firstAppointment.get('service_starts_at'));

      let hour = momentTime.hour();
      let minute = momentTime.minute();

      let newTime = _moment.default.parseZone(firstAppointment.get('service_starts_at')).hour(hour).minute(minute).format();

      model.appointments.forEach(appt => appt.set('service_starts_at', newTime));
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('redirecting', false);
      controller.set('saving', false);
      controller.set('wasUnassigned', this.get('wasUnassigned'));
      let firstAppointment = model.appointments.get('firstObject');

      if (firstAppointment) {
        controller.set('originalCareProviderId', firstAppointment.get('care_provider.id'));
        controller.set('originalStartsAt', firstAppointment.get('service_starts_at'));
        controller.set('originalOfferedServiceId', firstAppointment.get('offered_service.id'));
      }

      model.appointments.forEach(appointment => {
        appointment.set('selectedCareProviderId', appointment.get('care_provider.id'));
        appointment.set('selectedOfferedServiceId', appointment.get('offered_service.id'));
        appointment.set('selectedPosition', appointment.get('grouped_appointment.position'));
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('requestId', null);
        controller.set('ids', null);
      }
    },

    updateAppointments(appointments) {
      let firstAppointment = appointments.get('firstObject');
      let care_provider_id = firstAppointment.get('selectedCareProviderId');
      let offered_service_id = firstAppointment.get('selectedOfferedServiceId');
      let time_frame_start_time = firstAppointment.get('time_frame_start_time');
      let time_frame_end_time = firstAppointment.get('time_frame_end_time');
      let set_by_custom_window = firstAppointment.get('set_by_custom_window');
      let position = firstAppointment.get('selectedPosition');
      let special_instructions = {};
      appointments.forEach(appt => {
        let id = appt.get('id');
        special_instructions[id] = appt.get('special_instructions');
      });

      let date = _moment.default.parseZone(firstAppointment.get('service_starts_at')).format('YYYY-MM-DD');

      let time = _moment.default.parseZone(firstAppointment.get('service_starts_at')).format('h:mm A');

      let add_on_services = [];
      appointments.forEach(appt => {
        appt.get('appointment_add_on_services').forEach(addOnService => {
          if (!addOnService.isDeleted) {
            add_on_services.push({
              offered_service_id: addOnService.get('offered_service.id'),
              pet_id: appt.get('pet.id')
            });
          }
        });
      });
      let scheduling = {
        care_provider_id,
        offered_service_id,
        date,
        time,
        time_frame_start_time,
        time_frame_end_time,
        set_by_custom_window,
        position,
        add_on_services
      };
      let ids = appointments.map(appt => appt.get('id'));
      let url = `${_environment.default.APP.host}/api/v1/appointment_schedulings`;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url,
          method: 'PUT',
          data: {
            ids,
            appointment_scheduling: scheduling,
            special_instructions
          }
        }).then(response => {
          resolve(response);
        }).fail(e => {
          reject(e);
        });
      });
    },

    actions: {
      cancel(appointments) {
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId);
        appointments.forEach(appt => {
          let addOns = appt.get('appointment_add_on_services').map(aos => aos);

          for (let i = 0; i < addOns.length; i++) {
            addOns.objectAt(i).rollbackAttributes();
          }

          appt.rollbackAttributes();
        });
      },

      remove(appointments) {
        let controller = this.get('controller');
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');
        let backCareProviderId = this.get('controller.backCareProviderId');
        let backDate = this.get('controller.backDate');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        let apptPromises = appointments.map(appt => {
          appt.destroyRecord({
            adapterOptions: {
              method: 'remove button in edit single appointment'
            }
          });
        });
        Ember.RSVP.Promise.all(apptPromises).then(() => {
          if (backRoute == 'schedule.index') {
            return window.location = `/schedule?care_provider_id=${backCareProviderId}&date=${backDate}`;
          } else if (backRoute == 'schedule.calendar') {
            return window.location = `/schedule/calendar?care_provider_id=${backCareProviderId}&date=${backDate}&schedule_type=calendar`;
          } else {
            return this.transitionBack(backRoute, backHouseholdId, backId);
          }
        }).catch(() => {
          appointments.forEach(appt => appt.rollbackAttributes());
          return;
        }).finally(() => {
          controller.set('saving', false);
        });
      },

      save(appointments, position) {
        let controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        this.updateAppointments(appointments, position).then(() => {
          appointments.forEach(appointment => {
            let addOns = appointment.get('appointment_add_on_services');

            for (let i = 0; i < addOns.length; i++) {
              addOns.objectAt(i).rollbackAttributes();
            }

            return appointment.reload();
          });
          return this.transitionBack(backRoute, backHouseholdId, backId);
        });
      },

      decline(appointmentRequest) {
        let controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/decline`,
          method: 'POST',
          data: {
            declined_note: appointmentRequest.get('declined_note')
          }
        }).then(() => {
          appointmentRequest.set('declined_at', (0, _moment.default)());
          return this.transitionBack(backRoute, backHouseholdId, backId);
        });
      },

      approve(appointmentRequest, appointments) {
        let controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        let appt = appointments.get('firstObject');
        let care_provider_id = appt.get('selectedCareProviderId');
        let offered_service_id = appt.get('selectedOfferedServiceId');
        let time_frame_start_time = appt.get('time_frame_start_time');
        let time_frame_end_time = appt.get('time_frame_end_time');
        let set_by_custom_window = appt.get('set_by_custom_window');
        let date_time = appt.get('service_starts_at');
        let special_instructions = appointmentRequest.get('special_instructions');
        let position = appt.get('selectedPosition');
        let add_on_services = {};
        appointments.forEach(appt => {
          appt.get('appointment_add_on_services').forEach(add_on_service => {
            if (!add_on_services[add_on_service.get('offered_service.id')]) {
              add_on_services[add_on_service.get('offered_service.id')] = {
                offered_service_id: add_on_service.get('offered_service.id'),
                pet_ids: []
              };
            }

            add_on_services[add_on_service.get('offered_service.id')].pet_ids.push(appt.get('pet.id'));
          });
        });
        add_on_services = Object.values(add_on_services);
        controller.set('saving', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/approve`,
          method: 'POST',
          data: {
            care_provider_id,
            offered_service_id,
            time_frame_start_time,
            time_frame_end_time,
            set_by_custom_window,
            date_time,
            special_instructions,
            position,
            add_on_services
          }
        }).then(() => {
          appointmentRequest.set('approved_at', (0, _moment.default)());
          return this.transitionBack(backRoute, backHouseholdId, backId);
        });
      },

      sendRequestAppointmentToStaff(appointments, requestId) {
        let controller = Ember.getOwner(this).lookup('controller:edit-single-appointment');
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');

        if (controller.get('saving')) {
          return;
        }

        let appt = appointments.get('firstObject');
        let appointments_to_modify = appointments.map(appt => appt.get('id'));
        let pet_ids = appointments.map(appt => appt.get('pet.id'));
        let care_provider_id = appt.get('selectedCareProviderId');
        let offered_service_id = appt.get('selectedOfferedServiceId');
        let time_frame_start_time = appt.get('time_frame_start_time');
        let time_frame_end_time = appt.get('time_frame_end_time');
        let date_time = appt.get('service_starts_at');

        let date = _moment.default.parseZone(date_time).format('YYYY-MM-DD');

        let time_frame = {
          time: _moment.default.parseZone(date_time).format('H:mmA'),
          start_time: time_frame_start_time,
          end_time: time_frame_end_time
        };
        let special_instructions = appt.get('special_instructions');
        let add_on_services = {};
        appointments.forEach(appt => {
          appt.get('appointment_add_on_services').forEach(add_on_service => {
            if (!add_on_services[add_on_service.get('offered_service.id')]) {
              add_on_services[add_on_service.get('offered_service.id')] = {
                offered_service_id: add_on_service.get('offered_service.id'),
                pet_ids: []
              };
            }

            add_on_services[add_on_service.get('offered_service.id')].pet_ids.push(appt.get('pet.id'));
          });
        });
        add_on_services = Object.values(add_on_services);
        let details = {
          auto_approve: false,
          request_to_modify: requestId,
          business_request: true,
          appointments_to_modify,
          special_instructions,
          appointments: [{
            pet_ids,
            offered_service_id,
            care_provider_id,
            date,
            time_frame,
            add_on_services
          }]
        };

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests`,
          method: 'POST',
          data: {
            details
          }
        }).then(() => {
          this.set('saving', false);
          return this.transitionBack(backRoute, backHouseholdId, backId);
        }).catch(e => {
          if (e.message != 'TransitionAborted') {
            this.get('errorHandler').handle(e);
            this.set('saving', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});