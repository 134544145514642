define("frontend/routes/client-list", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "jquery"], function (_exports, _baseRoute, _cleanupOnTransition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      activity_log_open: false,
      search_term: null
    },
    filtered: false,
    autoShowActivityLog: false,
    activityLogs: inject.service(),
    infinity: inject.service(),
    currentUser: inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      if (transition.targetName == 'client-list.index' && !this.get('currentUser.user.canViewFullClientInfo')) {
        this.transitionTo('index');
      }

      if (this.get('controller')) {
        this.set('controller.loading', true);
      }
    },

    model(params) {
      if (params.activity_log_open == 'true') {
        if (params.search_term) {
          this.set('activityLogs.filter', params.search_term);
        }

        this.set('autoShowActivityLog', true);
      }

      if (params.filter !== '') {
        this.set('filtered', true);
        return this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter
        });
      }

      if (this.get('households') && !this.get('filtered')) {
        return this.get('households');
      }

      this.set('filtered', false);
      return this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('loading', false);
      controller.set('autoShowActivityLog', this.get('autoShowActivityLog'));
      this.set('households', model);
      Ember.run.scheduleOnce('afterRender', () => {
        let [ele] = (0, _jquery.default)('.household-select-option.selected');

        if (!ele) {
          return;
        }

        ele.scrollIntoView({
          block: 'center'
        });
      });
    },

    _doUpdate(newObjects) {
      let infinityModel = this.get('households');
      return infinityModel.pushObjects(newObjects.get('content'));
    },

    actions: {
      transitionToAddClient() {
        this.transitionTo('add-client.client-search');
      },

      loading() {
        return !this.get('controller');
      },

      parentInfinityLoad() {
        this._infinityLoad();
      }

    }
  });

  _exports.default = _default;
});