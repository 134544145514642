define("frontend/components/client-invoices-list/invoice-payment-item", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    classNames: ['invoice-payment-item'],
    classNameBindings: ['failed', 'pending', 'swipedLeft', 'hasStatus', 'firstItem'],
    media: inject.service(),
    currentUser: inject.service(),
    isPetParent: computed.alias('currentUser.user.isPetParent'),
    failed: computed.alias('invoicePayment.failed'),
    pending: computed.alias('invoicePayment.pending'),
    hasNotes: computed.notEmpty('invoicePayment.description'),
    hasStatus: computed.alias('invoicePayment.is_bank_transfer'),
    swipedLeft: false,
    index: null,
    active: false,
    firstItem: computed.equal('index', 0),
    feeTooltipPosition: computed('index', function () {
      return this.get('firstItem') ? 'bottom' : 'top';
    }),
    tipAndFeeAmount: computed('invoicePayment.tip_amount', 'invoicePayment.tip_service_fee', 'invoicePayment.service_fee', function () {
      return (parseFloat(this.get('invoicePayment.tip_amount')) || 0) + this.get('totalServiceFee');
    }),
    totalServiceFee: computed('invoicePayment.service_fee', 'invoicePayment.tip_service_fee', function () {
      return (parseFloat(this.get('invoicePayment.tip_service_fee')) || 0) + (parseFloat(this.get('invoicePayment.service_fee')) || 0);
    }),
    feesTooltipText: computed('invoicePayment.tip_amount', 'invoicePayment.service_fee', function () {
      let text = `Tip: ${this.formatMoney(this.get('invoicePayment.tip_amount'))}\r\n\r\nService fee: ${this.formatMoney(this.get('totalServiceFee'))}`;
      let feePercentage = 5;
      let coverText = '';

      if (this.get('invoicePayment.company_service_fee_split_percentage')) {
        feePercentage *= this.get('invoicePayment.company_service_fee_split_percentage');
        coverText += `${feePercentage}%`;
      }

      let feeCap = this.get('invoicePayment.company_service_fee_cap');

      if (this.get('totalServiceFee') > 0) {
        if (this.get('invoicePayment.payment_date') > '2023-08-08') {
          if (this.get('isPetParent')) {
            if (this.get('invoicePayment.payment_date') > '2024-04-08' && feeCap != null && feeCap >= 0) {
              if (feeCap == 0) {
                text += `\r\nThis non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('invoicePayment.company_name')}, this fee is waived.`;
              } else {
                coverText += coverText === '' ? `$${feeCap}` : ` or $${feeCap}, whichever is less`;
                text += `\r\nThis non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('invoicePayment.company_name')}, you’ll never pay more than ${coverText}.`;
              }
            } else {
              if (coverText !== '') {
                text += `\r\nThis non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('invoicePayment.company_name')}, you’ll never pay more than ${coverText}.`;
              } else {
                text += `\r\nThis non-refundable 5% fee (minimum $1.00) helps us cover operating costs.`;
              }
            }
          } else {
            text += `\r\nThis non-refundable 5% fee (minimum $1.00) helps us cover operating costs.`;
          }
        } else {
          text += '\r\nThis non-refundable 5% fee helps us cover operating costs.';
        }
      }

      return text;
    }),
    tooltipColor: computed('failed', 'pending', function () {
      if (this.get('pending')) {
        return 'yellow';
      } else if (this.get('failed')) {
        return 'red';
      } else {
        return 'green';
      }
    }),
    actions: {
      deletePayment() {
        this.get('deletePayment')(...arguments);
      }

    }
  });

  _exports.default = _default;
});