define("frontend/components/remove-invoice-frequency-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,
    readableFrequency: {
      'daily': 'daily',
      'weekly': 'weekly',
      'biweekly': 'every other week',
      'semimonthly': '1st and 15th',
      'monthly': 'monthly'
    },
    invoiceFrequencyList: Ember.computed('removedInvoiceFrequencies.[]', function () {
      let readableFrequency = this.get('readableFrequency');
      let invoiceFrequencies = this.get('removedInvoiceFrequencies').map(rif => readableFrequency[rif]);
      let last = invoiceFrequencies.pop();

      if (invoiceFrequencies.length == 0) {
        return last;
      } else {
        return `${invoiceFrequencies.join(', ')} and ${last}`;
      }
    }),
    defaultInvoiceFrequency: Ember.computed('company.default_invoice_frequency', function () {
      return this.get('readableFrequency')[this.get('company.default_invoice_frequency.name')];
    }),
    actions: {
      selectOption(option) {
        this.get('onClose')(option);
      }

    }
  });

  _exports.default = _default;
});