define("frontend/components/user-search-list", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: [''],
    tagName: 'ul',
    searchTerm: null,
    selectedStaff: null,
    filteredUsers: null,
    page: 0,
    perPage: 10,
    searchNow: false,
    searched: false,
    staff: false,

    didReceiveAttrs() {
      this._super(...arguments);

      let searchTerm = this.get('searchTerm');
      let searchNow = this.get('searchNow');

      if (searchNow) {
        this.set('page', 0);
        this.send('loadHouseholds', searchTerm, true);
        this.set('searched', true);
      }
    },

    actions: {
      loadMore() {
        this.set('page', this.get('page') + 1);
        this.send('loadHouseholds', this.get('searchTerm'), false);
      },

      transitionToPrimary() {
        this.get('transitionToPrimary')(this.get('searchTerm'));
      },

      showInviteClient() {
        if (this.get('showInviteClient')) {
          this.get('showInviteClient')();
        }
      },

      loadHouseholds(searchTerm, reload) {
        let page = this.get('page');
        let perPage = this.get('perPage');

        if (!this.get('filteredHouseholds')) {
          this.set('loading', true);
        }

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households_search?term=${encodeURIComponent(searchTerm)}&page=${page}&per_page=${perPage}`,
          method: 'GET'
        }).done(response => {
          // Check to make sure we got the response before the searchTerm
          // was changed
          this.set('searchNow', false);

          if (searchTerm == this.get('searchTerm')) {
            this.set('loading', false);

            if (reload) {
              this.set('filteredHouseholds', response.households);
            } else {
              let households = this.get('filteredHouseholds');
              this.set('filteredHouseholds', [...households, ...response.households]);
            }
          }
        });
      }

    }
  });

  _exports.default = _default;
});