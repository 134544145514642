define("frontend/controllers/schedule/index", ["exports", "moment", "frontend/utils/appointment-searcher", "jquery"], function (_exports, _moment, _appointmentSearcher, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Controller.extend({
    showAddInstructionsModal: false,
    removingAll: false,
    showRemovingAllFlash: false,
    timeZone: inject.service(),
    serviceActions: inject.service(),
    schedule: inject.controller(),
    date: computed.readOnly('schedule.date'),
    care_provider_id: computed.alias('schedule.care_provider_id'),
    currentUser: inject.service(),
    selectedAppointment: computed.alias('schedule.selectedAppointment'),
    staff: computed.readOnly('model.staff'),
    staffOptions: computed.readOnly('schedule.staff'),
    searchValue: computed.alias('schedule.searchValue'),
    searching: computed.alias('schedule.searching'),
    services: computed.alias('schedule.services'),
    dragAndDropActivated: computed.alias('schedule.dragAndDropActivated'),
    highlightWindowStart: computed.alias('schedule.highlightWindowStart'),
    highlightWindowEnd: computed.alias('schedule.highlightWindowEnd'),
    // TODO AppointmentSearcher should also filter services (possibly in a service)
    numAppointments: computed('filteredServices.@each.appointments', 'filteredServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', 'care_provider_id', function () {
      let services = this.get('filteredServices');
      let petNameSearchValue = this.get('searchValue');
      let countCanceledAppointments = this.get('care_provider_id') == 'cancellations';
      return _appointmentSearcher.default.numVisitsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    numPets: computed('filteredServices.@each.appointments', 'filteredServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', 'care_provider_id', function () {
      let services = this.get('filteredServices');
      let petNameSearchValue = this.get('searchValue');
      let countCanceledAppointments = this.get('care_provider_id') == 'cancellations';
      return _appointmentSearcher.default.numPetsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    selectedCareProvider: computed('care_provider_id', 'staff.[]', function () {
      if (this.get('care_provider_id') === 'all') {
        return 'all';
      } else if (this.get('care_provider_id') === 'cancellations') {
        return 'cancellations';
      } else {
        return this.get('staff').filterBy('id', this.get('care_provider_id')).get('firstObject');
      }
    }),
    prettyDate: computed('date', function () {
      return (0, _moment.default)(this.get('date'), 'YYYY-MM-DD').format('ddd, MMM D YYYY');
    }),
    servicesObserver: observer('services.[]', function () {
      window.scrollTo(0, 0);
    }),
    filteredServices: computed('services.[]', 'searchValue', function () {
      let services = this.store.peekAll('service');

      if (!services) {
        return [];
      }

      let filter = this.get('searchValue').toLowerCase();

      if (filter.trim() === '') {
        return services;
      }

      return services.filter(service => {
        let groupedAppts = this.get('care_provider_id') == 'cancellations' ? service.get('sortedCanceledGroupedAppointments') : service.get('sortedGroupedAppointmentsWithoutCanceled');
        return groupedAppts.any(groupedAppt => {
          return groupedAppt.get('appointments').any(appointment => {
            return appointment.get('pet.name').slice(0, filter.length).toLowerCase().indexOf(filter) >= 0;
          });
        });
      });
    }),
    servicesIsEmpty: computed('services.content', 'services.content.@each.appointments', function () {
      let services = this.get('services.content');
      return !services || services.get('length') === 0 || services.isEvery('appointments.length', 0);
    }),
    searchServicesIsEmpty: computed('filteredServices', 'filteredServices.@each.appointments', function () {
      let services = this.get('filteredServices');
      return !services || services.get('length') === 0 || services.isEvery('appointments.length', 0);
    }),
    actions: {
      startDraggingPet(groupedAppointment) {
        let firstAppt = groupedAppointment.get('appointments.firstObject');

        if (firstAppt.get('time_frame_start_time') && firstAppt.get('time_frame_end_time')) {
          this.set('highlightWindowStart', firstAppt.get('time_frame_start_time'));
          this.set('highlightWindowEnd', firstAppt.get('time_frame_end_time'));
        }

        Ember.run.later(() => {
          this.set('dragScrollActivated', true);
        });
      },

      stopDraggingPet() {
        this.set('highlightWindowStart', null);
        this.set('highlightWindowEnd', null);
        this.set('dragScrollActivated', false);
      },

      confirmRemoveAll() {
        let serviceActions = this.get('serviceActions');

        if (this.get('removingAll')) {
          return;
        }

        this.set('showRemovingAllFlash', false);
        this.set('removingAll', true);
        serviceActions.removeAllFor(this.get('date'), this.get('care_provider_id')).then(() => {
          this.notifyPropertyChange('services');
        }).finally(() => {
          window.location.reload();
          this.set('removingAll', false);
        });
      },

      denyRemoveAll() {
        this.set('showRemovingAllFlash', false);
      },

      removeAll() {
        this.set('showRemovingAllFlash', true);
      },

      transitionToNew() {
        let selectedHouseholdId = null;
        let filter = '';
        let date = this.get('date');
        let backRoute = 'schedule.index';
        let queryParams = {
          selectedHouseholdId,
          filter,
          date,
          backRoute
        };
        this.transitionToRoute('add-appointment.index', {
          queryParams
        });
      },

      clickAppointment(appointment) {
        if (appointment.get('appointment_detail.isLoaded')) {
          this.store.peekAll('appointment_detail').filterBy('id', appointment.get('appointment_detail.id'))[0].reload();
        }

        this.set('selectedAppointment', appointment);
        this.get('schedule').send('showSidePanel');
      },

      selectCareProvider() {
        this.send('closeAppointmentDetails');
      },

      clickSearch() {
        this.toggleProperty('searching');
        this.set('searchValue', '');
        Ember.run.scheduleOnce('afterRender', () => {
          (0, _jquery.default)('.search-input').focus();
        });
      },

      changeCareProvider() {
        this.notifyPropertyChange('services');
      },

      queueGroupedAppointment(groupedAppointment) {
        this.get('schedule').queueGroupedAppointment(groupedAppointment);
      },

      startGroupedReportCardQueue() {
        this.get('schedule').startGroupedReportCardQueue();
      }

    }
  });

  _exports.default = _default;
});