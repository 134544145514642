define("frontend/components/activity-log-item", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const LOG_ICON_PREFIX = 'assets/images/activity_log_icons';
  const PET_PLACEHOLDER_PREFIX = 'assets/images/placeholders';
  const HUMAN_PLACEHOLDER = 'assets/images/staff/placeholder-human-image@3x.png';
  const COMPANY_PLACEHOLDER = 'assets/images/settings/company-placeholder.png';

  var _default = Ember.Component.extend({
    placeholder: HUMAN_PLACEHOLDER,
    companyPlaceholder: COMPANY_PLACEHOLDER,
    showChangedFields: false,

    didInsertElement() {
      this._super(...arguments);

      if (this.get('log.currently_updating')) {
        this.set('intervalId', setInterval(() => this.pullUpdatingStatus(), 5000));
      }

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },

    handleMouseEnter(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },

    logTypeIcon: computed('log.event_type', function () {
      return `${LOG_ICON_PREFIX}/${this.get('log.event_type')}.svg`;
    }),
    miniAvatarUrl: computed('log.mini_avatar_url', function () {
      let avatar_url = this.get('log.mini_avatar_url');

      if (avatar_url == 'user_placeholder') {
        return HUMAN_PLACEHOLDER;
      } else if (avatar_url == 'pet_placeholder') {
        return `${PET_PLACEHOLDER_PREFIX}/${this.get('log.pet_type')}.svg`;
      } else {
        return avatar_url;
      }
    }),
    timeCreated: computed('log', 'log.created_at', function () {
      let time = moment(this.get('log.created_at'));

      if (time.isValid()) {
        return time.format('h:mm A');
      }
    }),
    unread: computed('log', 'log.created_at', function () {
      return this.get('log.created_at') > this.get('unreadAfter');
    }),

    pullUpdatingStatus() {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/activity_logs/check_updating_status?id=${this.get('log.id')}`,
        method: 'GET'
      }).then(response => {
        if (!response.still_updating) {
          this.set('log.currently_updating', false);
          clearInterval(this.get('intervalId'));
        }
      });
    },

    actions: {
      toggleFieldChanges() {
        this.set('showChangedFields', !this.get('showChangedFields'));
      }

    }
  });

  _exports.default = _default;
});