define("frontend/components/auto-bill/auto-bill-sidepanel", ["exports", "frontend/utils/invoice-frequency-options", "ember-cp-validations", "moment"], function (_exports, _invoiceFrequencyOptions, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const ALWAYS_AUTOBILL = 'always';
  const NEVER_AUTOBILL = 'never';
  const Validations = (0, _emberCpValidations.buildValidations)({
    companyInvoiceFrequencyOptions: [(0, _emberCpValidations.validator)('collection', true), (0, _emberCpValidations.validator)('presence', true)]
  });

  var _default = Ember.Component.extend(Validations, {
    companyInvoiceFrequencies: [],
    invoiceFrequencies: null,
    displayError: computed.and('showError', 'validations.isInvalid'),
    showError: false,
    showAutobillDropdown: false,
    timeZone: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    autoBillOptions: [{
      label: 'Always',
      value: ALWAYS_AUTOBILL
    }, {
      label: 'Never',
      value: NEVER_AUTOBILL
    }],
    alwaysAutoBill: computed.equal('selectedAutoBillOption', ALWAYS_AUTOBILL),

    didInsertElement() {
      this._super(...arguments);

      if (this.get('companyInvoiceFrequencies.length') > 0) {
        this.set('selectedAutoBillOption', ALWAYS_AUTOBILL);
      } else {
        this.set('selectedAutoBillOption', NEVER_AUTOBILL);
      }
    },

    companyInvoiceFrequencyOptions: computed('companyInvoiceFrequencies.[]', function () {
      return this.get('companyInvoiceFrequencies').map(this.invoiceFrequencySelectOptions).sort((a, b) => a.value.id - b.value.id);
    }),
    invoiceFrequencyOptions: computed('invoiceFrequencies.[]', function () {
      return this.get('invoiceFrequencies').map(this.invoiceFrequencySelectOptions).sort((a, b) => a.value.id - b.value.id);
    }),

    invoiceFrequencySelectOptions(invoiceFrequency) {
      return {
        label: invoiceFrequency.get('displayName'),
        value: invoiceFrequency
      };
    },

    billingPeriodOptions: computed('billingPeriod', function () {
      let options;
      let startDate = (0, _moment.default)().startOf('isoweek');
      let endDate = (0, _moment.default)().endOf('isoweek');
      endDate.add(1, 'week');
      options = [{
        label: `${startDate.format('M/D')} - ${endDate.format('M/D')}`,
        value: startDate.format('YYYY-MM-DD')
      }];
      startDate.subtract(1, 'week');
      endDate.subtract(1, 'week');
      options.push({
        label: `${startDate.format('M/D')} - ${endDate.format('M/D')}`,
        value: startDate.format('YYYY-MM-DD')
      });
      return options;
    }),
    billingPeriod: computed('biweeklyBillingPeriod', 'timeZone', {
      set(key, value) {
        // This has to be timezone aware because starts_at dates are time zone aware.
        let offset = this.get('timeZone').offsetDSTAware((0, _moment.default)(value));
        this.set('biweeklyBillingPeriod', (0, _moment.default)(value).utcOffset(offset).format());
        return (0, _moment.default)(this.get('biweeklyBillingPeriod')).format('YYYY-MM-DD');
      },

      get() {
        if (!this.get('biweeklyBillingPeriod')) {
          return null;
        }

        return (0, _moment.default)(this.get('biweeklyBillingPeriod')).format('YYYY-MM-DD');
      }

    }),
    selectedBiWeekly: computed('companyInvoiceFrequencies.[]', function () {
      return this.get('companyInvoiceFrequencies').mapBy('name').includes(_invoiceFrequencyOptions.default.BIWEEKLY);
    }),
    actions: {
      changeSelectedAutoBillOption(option) {
        if (option === NEVER_AUTOBILL) {
          this.get('companyInvoiceFrequencies').clear();
          this.set('defaultInvoiceFrequency', null);
        }
      },

      selectedCompanyInvoiceFrequenciesChanged(invoiceFrequencies) {
        if (invoiceFrequencies.indexOf(this.get('defaultInvoiceFrequency')) === -1) {
          this.set('defaultInvoiceFrequency', invoiceFrequencies.get('firstObject'));
        }
      },

      close() {
        if (this.get('companyInvoiceFrequencies.length') === 0 && this.get('alwaysAutoBill')) {
          this.set('showError', true);
          return;
        }

        this.close();
      },

      deactivateSidePanel() {}

    }
  });

  _exports.default = _default;
});