define("frontend/components/add-address-modal", ["exports", "frontend/utils/address-type", "ember-cp-validations"], function (_exports, _addressType, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'address.street': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.zipcode': (0, _emberCpValidations.validator)('international-postal-code', {
      dependentKeys: ['model.address.country']
    })
  });

  var _default = Ember.Component.extend(Validations, {
    countryInfo: inject.service(),
    showAccessInstructions: true,
    addressTypes: [{
      label: 'Home',
      value: _addressType.default.HOME
    }, {
      label: 'Work',
      value: _addressType.default.WORK
    }, {
      label: 'Other',
      value: _addressType.default.OTHER
    }],
    stateOptions: computed('address.country', function () {
      let country = this.get('address.country') || this.get('currentUserCountry');

      if (country === 'Canada') {
        return this.get('countryInfo.caProvinces');
      } else if (country === 'Australia') {
        return this.get('countryInfo.auStates');
      } else {
        return this.get('countryInfo.usStates');
      }
    }),
    countryOptions: computed('countryInfo.countries.[]', function () {
      return this.get('countryInfo.countries').map(country => {
        return {
          value: country,
          label: country
        };
      });
    }),
    stateOrProvince: computed('address.country', function () {
      if (this.get('address.country') == 'Canada') {
        return 'province';
      }

      return 'state';
    }),
    zipOrPostal: computed('address.country', function () {
      if (this.get('address.country') == 'Canada') {
        return 'postal code';
      }

      if (this.get('countryInfo.currentUserCountry') == 'Australia') {
        return 'postcode';
      }

      return 'zip code';
    }),
    countrySelected: computed('countryOptions.[]', 'address.country', function () {
      let options = this.get('countryOptions');
      let selected = options.filter(option => this.get('address.country') == option.value);
      return selected.get('firstObject');
    }),
    actions: {
      onSubmit() {
        if (this.get('validations.isValid')) {
          this.get('onSubmit')();
        }
      },

      onClose() {
        this.get('onClose')();
      },

      changeAddressType(addressType) {
        this.set('address.address_type', addressType.value);
      },

      changeState(state) {
        this.set('address.state', state);
      },

      changeCountry(country) {
        this.set('address.country', country.value);
      },

      highlightFirstStateMatch(api) {
        if (api && api.results && api.results.length) {
          return api.results[0];
        }

        return null;
      }

    }
  });

  _exports.default = _default;
});