define("frontend/components/tooltip-question-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tooltip-container'],
    classNameBindings: ['color'],
    tooltipText: null,
    position: 'top',
    color: 'yellow',
    openWithClick: false,
    htmlToRender: '',

    didRender() {
      this._super(...arguments);

      this.initializeTooltip();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.updateTooltipContent();
    },

    initializeTooltip() {
      let elementId = this.get('elementId');
      const htmlToRender = this.get('htmlToRender');
      const tooltipText = this.get('tooltipText');
      $(`#${elementId}`).tooltip({
        title: htmlToRender ? htmlToRender : tooltipText,
        placement: this.get('position'),
        template: `<div class="tooltip ${this.get('color')}" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`,
        trigger: 'manual',
        container: 'body',
        html: true
      });
      this.setupEventListeners();
    },

    updateTooltipContent() {
      let elementId = this.get('elementId');
      const htmlToRender = this.get('htmlToRender');
      const tooltipText = this.get('tooltipText');
      const $element = $(`#${elementId}`);

      if ($element.data('bs.tooltip')) {
        $element.attr('data-original-title', htmlToRender ? htmlToRender : tooltipText);
      } else {
        this.initializeTooltip();
      }
    },

    setupEventListeners() {
      let elementId = this.get('elementId');

      if (this.get('openWithClick')) {
        $(`#${elementId}`).on('click', () => {
          $(`#${elementId}`).tooltip('toggle');
          $(document).on('click', event => {
            if (!$(event.target).closest(`#${elementId}`).length) {
              $(`#${elementId}`).tooltip('hide');
            }
          });
        });
      } else {
        $(`#${elementId}`).on('mouseenter', () => {
          $(`#${elementId}`).tooltip('show');
        }).on('mouseleave', () => {
          $(`#${elementId}`).tooltip('hide');
        });
      }
    }

  });

  _exports.default = _default;
});