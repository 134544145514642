define("frontend/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncateText = truncateText;

  function truncateText(_ref) {
    let [str, len] = _ref;

    if (!str) {
      return;
    }

    if (str.length > len && str.length > 0) {
      let new_str = `${str} `;
      new_str = str.substr(0, len);
      new_str = str.substr(0, new_str.lastIndexOf(' '));
      new_str = new_str.length > 0 ? new_str : str.substr(0, len);
      return Ember.String.htmlSafe(`${new_str}...`);
    }

    return str;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});