define("frontend/controllers/admin/insurances", ["exports", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'insurance',
    modelOptions: {
      all: true
    },
    actions: {
      submitReplace(replace) {
        let url = `/api/v2/insurances/${this.get('editingReplace.id')}/replace`;
        this.submitReplace(url, replace, this.get('editingReplace'));
      },

      saveInsurance(insurance) {
        this.send('save', insurance);
        this.set('editingInsurance', null);
      },

      showRemove(insurance) {
        this.set('selectedInsurance', insurance);
      },

      confirmRemove() {
        this.send('remove', this.get('selectedInsurance'));
        this.set('selectedInsurance', null);
      },

      denyRemove() {
        this.set('selectedInsurance', null);
      },

      rollbackInsurance(insurance) {
        insurance.rollbackAttributes();
        this.set('editingInsurance', null);
      }

    }
  });

  _exports.default = _default;
});