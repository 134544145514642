define("frontend/components/message-thread/household-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--household-select'],
    households: [],
    selectedHousehold: null,
    actions: {
      infinityLoad() {
        this.infinityLoad(...arguments);
      },

      selectHousehold(household) {
        this.selectHousehold(household);
      }

    }
  });

  _exports.default = _default;
});