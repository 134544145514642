define("frontend/routes/companies", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model(params) {
      return this.store.findRecord('company', params.id);
    },

    actions: {
      cancel() {
        this.get('controller.model').rollbackAttributes();
        let routeName = this.controllerFor('application').get('currentRouteName');

        if (routeName == 'companies.remove-service') {
          this.transitionTo('companies.pricing');
        } else if (routeName == 'companies.remove-staff') {
          this.transitionTo('staff');
        } else if (routeName == 'companies.category-settings') {
          this.transitionTo('companies.pricing');
        } else {
          this.transitionTo('index');
        }
      }

    }
  });

  _exports.default = _default;
});