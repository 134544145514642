define("frontend/components/add-edit-staff", ["exports", "frontend/utils/user-role"], function (_exports, _userRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['add-edit-staff-container'],
    currentUser: Ember.inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    accessLevelOptions: [{
      label: _userRole.default.employee.label,
      value: _userRole.default.employee.id
    }, {
      label: _userRole.default.senior_staff.label,
      value: _userRole.default.senior_staff.id
    }, {
      label: _userRole.default.manager.label,
      value: _userRole.default.manager.id
    }, {
      label: _userRole.default.owner.label,
      value: _userRole.default.owner.id
    }],
    compensationTypeOptions: [{
      label: '% Commission',
      value: 'per_commission'
    }, {
      label: 'Fixed $ per appointment',
      value: 'fixed_dollar'
    }, {
      label: 'Salary',
      value: 'salary'
    }],
    addMinimumPayOptions: [{
      label: 'Yes',
      value: 'yes'
    }, {
      label: 'No',
      value: 'no'
    }],
    varyByServiceOptions: [{
      label: 'Yes',
      value: 'yes'
    }, {
      label: 'No',
      value: 'no'
    }],
    showFirstPayPeriod: false,
    timeZone: Ember.inject.service(),
    saveDisabled: computed.not('valid'),
    firstNameActivated: false,
    lastNameActivated: false,
    emailActivated: false,
    accessActivated: false,
    dateActivated: false,
    saving: false,
    offeredServiceAmounts: null,
    user: computed.alias('model.user'),
    valid: computed.readOnly('user.validations.isValid'),
    editing: false,

    didInsertElement() {
      this._super(...arguments);

      this.set('savedAmountToPayByService', this.get('hasVariablePrices'));
    },

    showAmountToPay: computed('model.compensation_type', 'showVariesByServiceOption', 'variesByService', function () {
      return this.get('model.compensation_type') != null && this.get('showVariesByServiceOption') && this.get('variesByService') != null && this.get('model.compensation_type') !== 'salary' || this.get('model.compensation_type') === 'salary';
    }),
    showVariesByServiceOption: computed('model.compensation_type', function () {
      return this.get('model.compensation_type') != null && this.get('model.compensation_type') !== 'salary';
    }),
    showAddGuaranteedMin: computed('model.compensation_type', function () {
      return this.get('model.compensation_type') === 'per_commission' || this.get('model.compensation_type') === 'fixed_dollar';
    }),
    compensationType: computed('model.compensation_type', {
      set(key, value) {
        if (value === 'salary') {
          this.set('showGuaranteedMin', 'no');
          this.set('variesByService', 'no');
        }

        this.set('model.compensation_type', value);
        return value;
      },

      get() {
        return this.get('model.compensation_type');
      }

    }),
    hideAmountToPayByService: computed.not('editingAmountToPayByService'),
    editingAmountToPayByService: computed('variesByService', 'savedAmountToPayByService', function () {
      return this.get('variesByService') === 'yes' && !this.get('savedAmountToPayByService');
    }),
    variesByService: computed({
      set(key, value) {
        if (value === 'no') {
          this.set('model.amount', undefined);
          this.set('savedAmountToPayByService', false);
          this.get('offeredServiceAmounts').forEach(offerdServiceAmount => {
            offerdServiceAmount.set('amount', undefined);
          });
          return 'no';
        } else {
          return 'yes';
        }
      },

      get() {
        let value = null;

        if (this.get('hasVariablePrices')) {
          value = 'yes';
        }

        if (this.get('model.amount') && !value) {
          value = 'no';
        }

        return value;
      }

    }),
    showGuaranteedMin: computed({
      set(key, value) {
        if (value === 'no') {
          this.set('model.min_amount_to_pay', undefined);
        }

        if (value === 'yes') {
          this.set('model.min_amount_to_pay', 0);
        }

        return value;
      },

      get() {
        let value = null;

        if (this.get('model.min_amount_to_pay') >= 0) {
          value = 'yes';
        }

        if (this.get('model.min_amount_to_pay') == null) {
          value = 'no';
        }

        return value;
      }

    }),
    showMinimumAmountToPay: computed('showGuaranteedMin', function () {
      return this.get('showGuaranteedMin') === 'yes' ? true : false;
    }),
    variablePrices: computed.mapBy('offeredServiceAmounts', 'amount'),
    minVariablePrice: computed.min('variablePrices'),
    maxVariablePrice: computed.max('variablePrices'),
    showVariablePrices: computed.and('hasVariablePrices', 'savedAmountToPayByService'),
    hasVariablePrices: computed('offeredServiceAmounts.@each.amount', function () {
      let offeredServiceAmounts = this.get('offeredServiceAmounts');

      if (!offeredServiceAmounts) {
        return false;
      }

      return offeredServiceAmounts.every(osAmount => osAmount.get('amount') != null) && offeredServiceAmounts.get('length') > 0;
    }),
    actions: {
      deactivateSidePanel() {
        this.set('anySidePanelActive', false);
      },

      activateSidePanel() {
        this.set('anySidePanelActive', true);
      },

      uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      },

      cancelAddEmployee() {
        if (this.get('saving')) {
          return;
        }

        this.get('model').rollbackAttributes();

        if (this.onCancel) {
          this.onCancel();
        }
      },

      saveAmountToPayByService(offeredServiceAmounts) {
        this.set('savedAmountToPayByService', true);
        this.set('model.offered_service_amounts', offeredServiceAmounts);
      },

      closeAmountToPayByService() {
        if (!this.get('hasVariablePrices')) {
          this.set('variesByService', 'no');
        } else {
          this.set('savedAmountToPayByService', true);
        }
      },

      editAmountToPayByService() {
        this.set('savedAmountToPayByService', false);
      },

      saveUser() {
        let model = this.get('model');

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);

        if (this.get('variesByService') === 'no') {
          this.get('offeredServiceAmounts').forEach(offeredServiceAmount => {
            offeredServiceAmount.set('amount', undefined);
          });
        }

        this.set('model.offered_service_amounts', this.get('offeredServiceAmounts'));
        model.set('company', this.get('company'));
        model.save().then(employee => {
          return this.onSaveUser(employee.get('user').content.id);
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});