define("frontend/routes/invoices-summary", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),

    model(_ref) {
      let {
        household_id
      } = _ref;
      let household = this.store.findRecord('household', household_id);
      let invoiceListItems = this.get('infinity').model('invoice-list-item', {
        household_id,
        perPage: 10,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        household,
        invoiceListItems
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('deletingPayment', null);
    },

    actions: {
      cancel() {
        this.set('controller.transitioning', true);
        this.transitionTo('invoices').finally(() => this.set('controller.transitioning', false));
      },

      clickInvoice(invoice) {
        this.transitionTo('invoices-summary.invoice-detail', invoice.id);
      },

      clickPayment(payment) {
        this.transitionTo('invoices-summary.payment-detail', payment.id);
      },

      refresh() {
        this.get('controller.model.household').reload();
        this.refresh();
      },

      goBack() {
        if (this.get('controller.backRoute')) {
          this.transitionTo(this.get('controller.backRoute'), this.get('controller.backHouseholdId'), this.get('controller.backId'));
        } else {
          let queryParams = {
            clickedIntoSearch: false
          };
          this.transitionTo('invoices', {
            queryParams
          });
        }
      },

      clickGear() {
        this.transitionTo('client-list.household.billing', this.get('controller.model.household'));
      }

    }
  });

  _exports.default = _default;
});