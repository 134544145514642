define("frontend/helpers/is-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isNone = isNone;

  function isNone(_ref) {
    let [object] = _ref;
    return Ember.isNone(object);
  }

  var _default = Ember.Helper.helper(isNone);

  _exports.default = _default;
});