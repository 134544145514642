define("frontend/components/assign-staff-side-panel", ["exports", "frontend/config/environment", "ember-data", "ember-cp-validations", "frontend/helpers/truncate-text", "jquery"], function (_exports, _environment, _emberData, _emberCpValidations, _truncateText, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'selectedCareProvider': (0, _emberCpValidations.validator)('presence', true)
  });

  function getOfferedServiceOption(offeredService) {
    let label = `${(0, _truncateText.truncateText)([offeredService.get('name'), 40])} (${offeredService.get('durationDisplay')})`;
    return {
      value: offeredService,
      label
    };
  }

  function getCareProviderOption(careProvider) {
    return {
      value: careProvider,
      label: careProvider.get('fullName')
    };
  }

  var _default = Ember.Component.extend(Validations, {
    classNames: ['assign-staff-side-panel'],
    careProviders: [],
    defaultCareProviderId: null,
    offeredServices: [],
    serviceCategories: [],
    appointmentTimesService: inject.service('appointment-times'),

    /**
     * While this does handle whether or not special notes are shown it also is overloaded with the concept of recurring
     * appointments showing up when this is set to false and one-time appointments showing up when this is set to true.
     *
     * A better name should be created to describe this concept without the need for this comment. Additionally when this
     * is done the conditional logic to show the {{appointment-group-select}} components in the template could be greatly
     * simplified.
     */
    showSpecialNotes: false,
    store: inject.service(),
    showSelectedCareProviderError: computed.and('showErrors', 'selectedCareProviderEmpty'),
    sidePanelAppointment: null,
    date: computed.readOnly('sidePanelAppointment.date'),
    selectedCareProvider: computed.alias('sidePanelAppointment.careProvider'),
    selectedOfferedService: computed.alias('sidePanelAppointment.offered_service'),
    selectedStartTime: computed.alias('sidePanelAppointment.time'),
    specialInstructions: computed.alias('sidePanelAppointment.special_instructions'),
    timeFrameStart: computed.alias('sidePanelAppointment.timeFrameStart'),
    timeFrameEnd: computed.alias('sidePanelAppointment.timeFrameEnd'),
    setByCustomWindow: computed.alias('sidePanelAppointment.setByCustomWindow'),
    selectedPosition: computed.alias('sidePanelAppointment.position'),
    selectedPositionsPreviousPets: computed.alias('sidePanelAppointment.positionsPreviousPets'),
    chargeForLateBooking: computed.alias('sidePanelAppointment.chargeForLateBooking'),
    addOnServices: computed.alias('sidePanelAppointment.addOnServices'),
    selectedCareProviderEmpty: computed.empty('selectedCareProvider'),
    hasErrors: computed.or('selectedCareProviderEmpty', 'selectedStartTimeInvalid'),
    showingRecurring: computed.not('showSpecialNotes'),
    showErrors: false,
    editing: false,
    headerText: null,
    existingAppointment: null,
    showSelectedStartTimeError: computed.and('showErrors', 'selectedStartTimeInvalid'),

    didInsertElement() {
      let appointment = this.get('sidePanelAppointment');

      if (appointment && appointment.offered_service) {
        this.set('selectedOfferedServiceOption', getOfferedServiceOption(appointment.offered_service));
      }

      if (appointment && appointment.careProvider) {
        this.set('selectedCareProviderOption', getCareProviderOption(appointment.careProvider));
      }

      if (appointment && appointment.time && appointment.careProvider) {
        this.set('originalStartTime', appointment.time);
        this.set('originalCareProvider', appointment.careProvider);
      }
    },

    selectedStartTimeInvalid: computed('selectedStartTime', function () {
      let strictParsing = true;
      let format = 'h:mm A';
      return Ember.isBlank(this.get('selectedStartTime')) || !moment(this.get('selectedStartTime'), format, strictParsing).isValid();
    }),
    selectedStartTimeObserver: observer('selectedStartTime', 'selectedOfferedService', function () {
      let startTime = this.get('selectedStartTime');

      if (!startTime) {
        return;
      }

      this.set('selectedPositionsPreviousPets', 'first');
      let serviceCategory = this.get('selectedOfferedService.service_category');

      if (!serviceCategory.get('use_time_blocks')) {
        return;
      }

      let startTimeMoment = moment(startTime, 'hh:mm a');

      if (this.get('setByCustomWindow')) {
        let timeFrameStart = moment(this.get('timeFrameStart'), 'h:mmA');
        let timeFrameEnd = moment(this.get('timeFrameEnd'), 'h:mmA');

        if (startTimeMoment >= timeFrameStart && startTimeMoment < timeFrameEnd) {
          return;
        }
      }

      let timeFrames = serviceCategory.get('time_frames');
      let dayOfWeek = moment(this.get('date'), 'YYYY-MM-DD').day();
      let potentialTimeFrames = timeFrames.filter(timeFrame => {
        let start = moment(timeFrame.get('start_time'), 'hh:mm a');
        let end = moment(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      let showStart = null;
      let showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        let timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = moment(startTime, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = moment(startTime, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        this.set('timeFrameStart', showStart);
        this.set('timeFrameEnd', showEnd);
        this.set('setByCustomWindow', false);
      }
    }),
    preferredStaffSorting: ['position'],
    sortedPreferredStaff: computed.sort('preferredStaff', 'preferredStaffSorting'),
    careProviderOptions: computed.map('careProviders', getCareProviderOption),
    groupedCareProviderOptions: computed('careProviders.[]', 'preferredStaff.[]', function () {
      if (this.get('sortedPreferredStaff.length') > 0) {
        let preferred = [];
        let others = [];
        this.get('sortedPreferredStaff').forEach(preferredStaff => {
          if (!preferredStaff.get('isNew')) {
            this.get('careProviderOptions').forEach(careProvider => {
              if (careProvider.label == preferredStaff.get('staff_name')) {
                preferred.pushObject(careProvider);
              }
            });
          }
        });
        this.get('careProviderOptions').forEach(careProvider => {
          let found = false;
          this.get('sortedPreferredStaff').forEach(preferredStaff => {
            found = found || careProvider.label == preferredStaff.get('staff_name');
          });

          if (!found) {
            others.pushObject(careProvider);
          }
        });
        return [{
          groupName: 'Preferred',
          options: preferred
        }, {
          groupName: 'Others',
          options: others
        }];
      } else {
        return this.get('careProviders').map(getCareProviderOption);
      }
    }),
    nonAddOnOfferedServices: computed('offeredServices.[]', function () {
      return this.get('offeredServices').filter(offeredService => !offeredService.get('is_add_on'));
    }),
    addOnOfferedServices: computed('selectedOfferedService.service_category', function () {
      return this.get('selectedOfferedService.service_category.addOnOfferedServices').filter(offeredService => offeredService.get('is_add_on'));
    }),
    offeredServiceOptions: computed.map('nonAddOnOfferedServices', getOfferedServiceOption),
    addOnOfferedServiceOptions: computed.map('addOnOfferedServices', getOfferedServiceOption),
    startTimeOptions: computed('selectedCareProvider', 'date', function () {
      return this.get('appointmentTimesService').allTimes();
    }),
    oneTimeAppointmentGroups: computed('date', 'selectedCareProvider.id', function () {
      let care_provider_id = this.get('selectedCareProvider.id');
      let start_date = this.get('date');
      let end_date = this.get('date');
      let promise = this.get('store').query('service', {
        care_provider_id,
        start_date,
        end_date
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    shownOneTimeAppointmentGroups: computed('oneTimeAppointmentGroups', 'selectedOfferedService.all_day_service', function () {
      if (this.get('selectedOfferedService.all_day_service')) {
        return this.get('oneTimeAppointmentGroups').filter(group => group.get('all_day_service'));
      } else {
        return this.get('oneTimeAppointmentGroups').filter(group => !group.get('all_day_service'));
      }
    }),
    selectedAppointmentGroup: computed('oneTimeAppointmentGroups.[]', 'selectedStartTime', function () {
      let ags = this.get('oneTimeAppointmentGroups');
      let group = ags.filter(ag => ag.get('starts_at_time') == this.get('selectedStartTime')).get('firstObject');

      if (group) {
        let originalTime = this.get('originalStartTime') == this.get('selectedStartTime');
        let originalProvider = this.get('originalCareProvider') == this.get('selectedCareProvider');
        let previousPets = this.get('selectedPositionsPreviousPets');

        if (previousPets && originalTime && originalProvider) {
          if (previousPets == 'first') {
            this.set('selectedPosition', 0);
          } else {
            let groupedAppts = group.get('sortedGroupedAppointmentsWithoutCanceled');
            let found = false;

            for (let i = 0; i < groupedAppts.length; i++) {
              let groupedAppt = groupedAppts.objectAt(i);
              let petNames = groupedAppt.get('sortedAppointments').mapBy('pet.name').join('/');

              if (petNames == this.get('selectedPositionsPreviousPets')) {
                this.set('selectedPosition', i + 1);
                found = true;
              }
            }

            if (!found) {
              this.set('selectedPosition', 0);
            }
          }
        } else {
          this.set('selectedPosition', 0);
        }
      }

      return group;
    }),
    positionOptions: computed('selectedAppointmentGroup', function () {
      let group = this.get('selectedAppointmentGroup');

      if (!group) {
        return;
      }

      let groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');
      let options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        for (let i = 0; i < groupedAppointments.length - 1; i++) {
          let appt = groupedAppointments.objectAt(i);
          let apptPetNames = appt.get('sortedAppointments').map(appt => appt.get('pet.name')).join('/');
          options.push({
            label: `After ${apptPetNames}`,
            value: i + 1
          });
        }
      }

      options.push({
        label: 'Last',
        value: group.get('sortedGroupedAppointmentsWithoutCanceled').length
      });
      return options;
    }),
    selectedPositionLabelForMobile: computed('selectedPosition', 'positionOptions', function () {
      const positionOptions = this.get('positionOptions');
      const selectedPosition = this.get('selectedPosition');
      const foundObject = positionOptions.find(option => option.value === selectedPosition);
      return foundObject;
    }),
    recurringAppointmentGroups: computed('date', 'selectedCareProvider.id', 'selectedOfferedService.all_day_service', function () {
      let care_provider_id = this.get('selectedCareProvider.id');
      let start_date = this.get('date');
      let end_date = this.get('date');
      let all_day_service = this.get('selectedOfferedService.all_day_service');

      let promise = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/services/?care_provider_id=${care_provider_id}&recurring=true&start_date=${start_date}&end_date=${end_date}&all_day_service=${all_day_service}`,
        method: 'GET'
      });

      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    selectedRecurringGroup: computed('recurringAppointmentGroups.[]', 'selectedStartTime', 'existingAppointment', function () {
      let rgs = this.get('recurringAppointmentGroups');
      let group = rgs.filter(rg => rg.starts_at_time == this.get('selectedStartTime')).get('firstObject');

      if (group) {
        let originalTime = this.get('originalStartTime') == this.get('selectedStartTime');
        let originalProvider = this.get('originalCareProvider') == this.get('selectedCareProvider');
        let previousPets = this.get('selectedPositionsPreviousPets');
        let existingAppt = this.get('existingAppointment');

        if (previousPets) {
          if (previousPets == 'first') {
            this.set('selectedPosition', 0);
          } else if (previousPets == 'last') {
            this.set('selectedPosition', group.grouped_appointments.length);
          } else {
            let groupedAppts = group.grouped_appointments;
            let found = false;

            for (let i = 0; i < groupedAppts.length; i++) {
              let groupedAppt = groupedAppts.objectAt(i);
              let petNames = groupedAppt.mapBy('pet.name').join('/');

              if (petNames == this.get('selectedPositionsPreviousPets')) {
                if (i == groupedAppts.length - 1) {
                  this.set('selectedPosition', i);
                } else {
                  this.set('selectedPosition', i + 1);
                }

                found = true;
              }
            }

            if (!found) {
              this.set('selectedPosition', 0);
              this.set('selectedPositionsPreviousPets', 'first');
            }
          }
        } else if (originalTime && originalProvider && existingAppt) {
          let groupedAppts = group.grouped_appointments;
          let found = false;

          for (let i = 0; i < groupedAppts.length; i++) {
            let groupedAppt = groupedAppts.objectAt(i);
            let ids = groupedAppt.map(appt => appt.recurring_appointment_id);

            if (ids.includes(parseInt(existingAppt.get('id')))) {
              this.set('selectedPosition', i);
              found = true;
            }
          }

          if (!found) {
            this.set('selectedPosition', 0);
            this.set('selectedPositionsPreviousPets', 'first');
          }
        } else {
          this.set('selectedPosition', 0);
          this.set('selectedPositionsPreviousPets', 'first');
        }
      }

      return group;
    }),
    recurringPositionOptions: computed('selectedRecurringGroup', 'existingAppointment', function () {
      let group = this.get('selectedRecurringGroup');

      if (!group) {
        return;
      }

      let groupedAppointments = group.grouped_appointments;
      let existingAppt = this.get('existingAppointment');

      if (existingAppt) {
        let existingApptId = parseInt(existingAppt.get('id'));

        let getApptIds = appts => appts.map(appt => appt.recurring_appointment_id);

        groupedAppointments = groupedAppointments.reject(ga => getApptIds(ga).includes(existingApptId));
      }

      let options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        for (let i = 0; i < groupedAppointments.length - 1; i++) {
          let appts = groupedAppointments.objectAt(i);
          let apptPetNames = appts.map(appt => appt.pet.name).join('/');
          options.push({
            label: `After ${apptPetNames}`,
            value: i + 1
          });
        }
      }

      options.push({
        label: 'Last',
        value: groupedAppointments.length
      });
      return options;
    }),

    appointment() {
      let date = this.get('date');
      let careProvider = this.get('selectedCareProvider');
      let time = this.get('selectedStartTime');
      let special_instructions = this.get('specialInstructions');
      let offered_service = this.get('selectedOfferedService');
      let timeFrameStart = this.get('timeFrameStart');
      let timeFrameEnd = this.get('timeFrameEnd');
      let setByCustomWindow = this.get('setByCustomWindow');
      let positionsPreviousPets = this.get('selectedPositionsPreviousPets');
      let chargeForLateBooking = this.get('chargeForLateBooking');
      let addOnServices = this.get('addOnServices').filter(aos => aos.get('offered_service.id'));
      return {
        date,
        careProvider,
        time,
        special_instructions,
        offered_service,
        timeFrameStart,
        timeFrameEnd,
        setByCustomWindow,
        positionsPreviousPets,
        chargeForLateBooking,
        addOnServices
      };
    },

    checkForErrors() {
      if (this.get('hasErrors')) {
        this.set('showErrors', true);
        Ember.run.scheduleOnce('afterRender', () => {
          let [ele] = (0, _jquery.default)(this.element.querySelectorAll('.red-validation-error'));

          if (ele) {
            ele.scrollIntoView(false);
          }
        });
        return true;
      }

      return false;
    },

    showTimeWindowText: computed.or('hasAppointmentGroups', 'timeFrameStart'),
    hasAppointmentGroups: computed('showSpecialNotes', 'shownOneTimeAppointmentGroups', 'oneTimeAppointmentGroups.isFulfilled', 'recurringAppointmentGroups.isFulfilled', function () {
      if (this.get('showSpecialNotes') && this.get('oneTimeAppointmentGroups.isFulfilled')) {
        return this.get('shownOneTimeAppointmentGroups.length') > 0;
      } else if (!this.get('showSpecialNotes') && this.get('recurringAppointmentGroups.isFulfilled')) {
        return this.get('recurringAppointmentGroups.length') > 0;
      } else {
        return false;
      }
    }),
    useTimeHelperClass: computed.and('hasAppointmentGroups', 'timeFrameStart'),

    saveAppointmentCallback(createOrUpdate, appt) {
      if (createOrUpdate == "create") {
        this.createAppointment(appt);
      } else {
        this.updateAppointment(appt);
      }
    },

    showAddAddOnServiceButton: computed('addOnServices.[]', 'selectedOfferedService.service_category', function () {
      return this.get('addOnServices.length') == 0 && this.get('selectedOfferedService.service_category.hasAddOnServices');
    }),
    showAddAnotherServiceButton: computed('addOnServices.[]', 'selectedOfferedService.service_category', function () {
      return this.get('addOnServices.length') > 0 && this.get('selectedOfferedService.service_category.hasAddOnServices') && this.get('addOnServices.length') < this.get('addOnOfferedServiceOptions.length');
    }),
    addOnServicesSelected: computed('addOnServices.@each.offered_service', function () {
      return this.get('addOnServices').mapBy('offered_service.id');
    }),
    actions: {
      close() {
        this.close();
      },

      clickCreateAddOnService() {
        if (this.get('pets.length') > 1) {
          this.set('showPickPetsModal', true);
        } else {
          this.send('createNewAddOnService', this.get('pets'));
        }
      },

      closePickPetsModal() {
        this.set('showPickPetsModal', false);
      },

      createNewAddOnService(pets) {
        this.set('showPickPetsModal', false);
        let addOnService = this.get('createNewStoreAddOnService')(pets);

        if (this.get('addOnOfferedServiceOptions.length') == 1) {
          addOnService.set('offered_service', this.get('addOnOfferedServiceOptions.firstObject.value'));
        }

        this.get('addOnServices').pushObject(addOnService);
      },

      selectAddOnServiceOption(addOnService, option) {
        let os = option.value ? option.value : option;
        addOnService.set('offered_service', os);
      },

      selectServiceOption(option) {
        if (option.value.get('service_category_id') != this.get('selectedOfferedService.service_category_id')) {
          this.get('addOnServices').clear();
        }

        this.set('selectedOfferedService', option.value);
        this.set('selectedOfferedServiceOption', option);

        if (this.get('selectedOfferedService.all_day_service')) {
          this.set('selectedStartTime', '12:00 AM');
        } else {
          this.set('selectedStartTime', null);
        }
      },

      selectCareProviderOption(option) {
        if (option.value) {
          this.set('selectedCareProvider', option.value);
        } else {
          this.set('selectedCareProvider', option);
        }

        this.set('selectedCareProviderOption', option);
      },

      openCustomizeTimeWindowModal() {
        this.set('originalTimeWindowStart', this.get('timeFrameStart'));
        this.set('originalTimeWindowEnd', this.get('timeFrameEnd'));
        this.set('showTimeWindowModal', true);
      },

      closeCustomizeTimeWindowModal(shouldUpdate) {
        if (shouldUpdate) {
          this.set('setByCustomWindow', true);
        } else {
          this.set('timeFrameStart', this.get('originalTimeWindowStart'));
          this.set('timeFrameEnd', this.get('originalTimeWindowEnd'));
        }

        this.set('originalTimeWindowStart', null);
        this.set('originalTimeWindowEnd', null);
        this.set('showTimeWindowModal', false);
      },

      removeAppointment() {
        this.removeAppointment(this.appointment());
      },

      removeAddOnService(addOnService) {
        this.get('addOnServices').removeObject(addOnService);
      },

      saveAppointment() {
        let createOrUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "create";

        if (!this.checkForErrors()) {
          this.set('saving', true);
          this.set('createOrUpdateAppointment', createOrUpdate);
          let appt = this.appointment();

          if (this.get('showingRecurring')) {
            this.saveAppointmentCallback(createOrUpdate, appt);
          } else {
            _jquery.default.ajax({
              url: `${_environment.default.APP.host}/api/v2/appointments/late_booking_fee?starts_at=${appt.time}&date=${appt.date}&os_id=${appt.offered_service.id}`,
              method: 'GET'
            }).then(response => {
              this.set('saving', false);

              if (response.is_late_booking) {
                this.set('lateBookingPolicy', response.policy);
                this.set('showLateBookingAlertModal', true);
              } else {
                this.saveAppointmentCallback(createOrUpdate, appt);
              }
            });
          }
        }
      },

      closeLateBookingAlertModal(chargeFee) {
        this.set('chargeForLateBooking', chargeFee);
        this.set('showLateBookingAlertModal', false);
        let createOrUpdate = this.get('createOrUpdateAppointment');
        let appt = this.appointment();
        this.set('createOrUpdateAppointment', null);

        if (createOrUpdate == "create") {
          this.createAppointment(appt);
        } else {
          this.updateAppointment(appt);
        }
      },

      selectOfferedService(offeredService, previousService) {
        if (offeredService.get('service_category_id') != previousService.get('service_category_id')) {
          this.get('addOnServices').clear();
        }

        if (offeredService.get('all_day_service') && !previousService.get('all_day_service')) {
          this.set('selectedStartTime', '12:00 AM');
        }

        if (!offeredService.get('all_day_service') && previousService.get('all_day_service')) {
          this.set('selectedStartTime', null);
        }
      },

      updateSelectedTime(selectedTime) {
        // The customize time window modal uses timepickers with a different
        // format than the one we're using in this component but when the modal
        // is inserted it somehow overrides this components timepicker's format.
        // This will check to make sure that we are always using the expected
        // format for start times
        let badFormat = 'h:mmA';
        let badMoment = moment(selectedTime, badFormat, true);

        if (badMoment.isValid()) {
          selectedTime = badMoment.format('h:mm A');
        }

        this.set('selectedStartTime', selectedTime);
      },

      selectNewPosition(selected) {
        let selectedPosition = selected;
        if (typeof selected !== 'number') selectedPosition = selected.value;
        let group = this.get('selectedAppointmentGroup');
        let recurringGroup = this.get('selectedRecurringGroup');
        let showingRecurring = this.get('showingRecurring');
        let groupLength = showingRecurring ? recurringGroup.grouped_appointments.length : group.get('sortedGroupedAppointmentsWithoutCanceled').length;

        if (selectedPosition == 0) {
          this.set('selectedPositionsPreviousPets', 'first');
        } else if (selectedPosition == groupLength) {
          this.set('selectedPositionsPreviousPets', 'last');
        } else {
          let petNames = '';

          if (showingRecurring) {
            let groupedAppts = recurringGroup.grouped_appointments;
            let existingAppt = this.get('existingAppointment');

            if (existingAppt) {
              let existingApptId = parseInt(existingAppt.get('id'));

              let getApptIds = appts => appts.map(appt => appt.recurring_appointment_id);

              groupedAppts = groupedAppts.reject(ga => getApptIds(ga).includes(existingApptId));
            }

            let previousAppt = groupedAppts.objectAt(selectedPosition - 1);
            petNames = previousAppt.mapBy('pet.name').sort().join('/');
          } else {
            let previousAppt = group.get('sortedGroupedAppointmentsWithoutCanceled').objectAt(selectedPosition - 1);
            petNames = previousAppt.get('sortedAppointments').mapBy('pet.name').join('/');
          }

          this.set('selectedPositionsPreviousPets', petNames);
        }

        this.set('selectedPosition', selectedPosition);
        const positionOptions = this.get('positionOptions');
        const foundObject = positionOptions.find(option => option.value === selectedPosition);
        this.set('selectedPositionLabelForMobile', foundObject);
      }

    }
  });

  _exports.default = _default;
});