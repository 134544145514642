define("frontend/routes/schedule", ["exports", "frontend/routes/base-route", "moment"], function (_exports, _baseRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    currentUser: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    queryParams: {
      schedule_type: {
        refreshModel: true,
        replace: true
      },
      care_provider_id: {
        refreshModel: true,
        replace: true
      },
      date: {
        refreshModel: true,
        replace: true
      }
    },

    beforeModel() {
      this._super(...arguments); // We have to use peekAll in the controller to allow live updates of the
      // services so we need to unload all services and only load those from the
      // query return


      this.store.peekAll('grouped_appointment').map(appt => appt.unloadRecord());
      return this.store.peekAll('service').map(service => service.unloadRecord());
    },

    model(params, transition) {
      if (Ember.isEmpty(params.care_provider_id) || params.care_provider_id == 'requests' && !this.get('media.isMobile')) {
        let user = this.get('currentUser').get('user'); // User won't be there on login but default schedule will be in session data

        if (!user && this.get('session.data.authenticated.tokenData.default_schedule') == 'all' || user && user.get('canViewAllSchedule') && user.get('default_schedule') == 'all') {
          this.set('care_provider_id', 'all');
        } else {
          let care_provider_id = `${this.get('session.data.authenticated.tokenData.user_id')}`;
          this.set('care_provider_id', care_provider_id);
        }
      } else {
        this.set('care_provider_id', params.care_provider_id);
      }

      let staff = this.store.findAll('care_provider');

      if (Ember.isEmpty(params.date)) {
        let today = (0, _moment.default)().format('YYYY-MM-DD');
        params.date = today;
        this.set('date', today);
      }

      let care_provider_id = this.get('care_provider_id');
      let start_date = (0, _moment.default)(params.date, 'YYYY-MM-DD');
      let end_date = (0, _moment.default)(params.date, 'YYYY-MM-DD');
      let currentRouteName = transition.targetName;

      if (currentRouteName == 'schedule.calendar' && care_provider_id != 'all') {
        let weekType = this.get('session.data.authenticated.tokenData.company_week_type') || this.get('currentUser.user.company_week_type');
        start_date = start_date.startOf(weekType);
        end_date = end_date.endOf(weekType);
      }

      if (Ember.isEmpty(params.schedule_type)) {
        let type = currentRouteName == 'schedule.calendar' ? 'calendar' : 'list';
        params.schedule_type = type;
        this.set('schedule_type', type);
      }

      if (care_provider_id == 'cancellations') {
        if (this.get('currentUser.user.canAddEditAppointments')) {
          care_provider_id = 'all';
        } else {
          care_provider_id = this.get('currentUser.user.id');
        }
      }

      let services = [];

      if (care_provider_id != 'requests') {
        services = this.store.query('service', {
          care_provider_id,
          start_date: start_date.format('YYYY-MM-DD'),
          end_date: end_date.format('YYYY-MM-DD')
        }, {
          reload: true
        });
      }

      return Ember.RSVP.hash({
        staff,
        services
      });
    },

    setupController(controller) {
      this._super(...arguments);

      if (!controller.get('selectedStaff')) {
        controller.set('selectedStaff', this.get('session.data.authenticated.tokenData.user_id'));
      }

      controller.send('closeAppointmentDetails');
      controller.set('care_provider_id', this.get('care_provider_id'));

      if (Ember.isEmpty(controller.get('date'))) {
        controller.set('date', (0, _moment.default)().format('YYYY-MM-DD'));
      }

      if (controller.get('hidingSidePanel')) {
        $('.ember-application').addClass('side-panel-hidden');
      }

      controller.set('dragAndDropActivated', !this.get('media.isMobile'));
      controller.send('updateCalendarHighlight');
    }

  });

  _exports.default = _default;
});