define("frontend/controllers/portal/appointments", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCHEDULE_TYPE = 'schedule';
  const PENDING_TYPE = 'pending';
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    reactApp: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    queryParams: ['appointmentType', 'appointmentId', 'showReportCard'],
    showRequestAppointmentModal: false,
    appointmentType: SCHEDULE_TYPE,
    appointmentId: null,
    showReportCard: false,
    onSchedule: computed.equal('appointmentType', SCHEDULE_TYPE),
    onPending: computed.equal('appointmentType', PENDING_TYPE),
    selectedReportCard: computed.oneWay('model.reportCardAppointment.report_card'),
    selectedReportCardAppointment: computed.oneWay('model.reportCardAppointment'),
    allCategoriesRequireSigning: computed.readOnly('currentUser.allCategoriesRequireSigning'),

    sendModifyEmail(groupedAppointment) {
      let petNames = this.get('currentUser.user.household.petNames');
      let companyEmail = groupedAppointment.get('service.company_email');
      let lastName = this.get('currentUser.user.household.owner.last_name');
      let subject = `Request to modify appointment for ${petNames} ${lastName}`;
      let userName = this.get('currentUser.user.fullName');
      let service = groupedAppointment.get('offered_service.nameWithDuration');
      let apptDate = moment(groupedAppointment.get('service.starts_at')).format('dddd, MMMM Do');
      let pickupWindow = groupedAppointment.get('pickup_window') ? `${groupedAppointment.get('pickup_window')} arrival` : groupedAppointment.get('service.starts_at_time');
      let body = `Hi!%0D%0A%0D%0AI'd like to modify the ${service} for ${petNames} on ${apptDate}, currently scheduled for ${pickupWindow}.%0D%0A%0D%0AI'd like to change:%0D%0A%0D%0APlease let me know if you can accommodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A${userName}`;
      window.location.href = `mailto:${companyEmail}?subject=${subject}&body=${body}`;
    },

    showTabs: computed('currentUser.user.client_accounts.[]', function () {
      let clientAccounts = this.get('currentUser.user.client_accounts');

      if (clientAccounts) {
        return clientAccounts.any(acct => acct.parent_in_app_booking);
      } else {
        return false;
      }
    }),

    removeNewRecords() {
      this.store.peekAll('address').filter(a => a.get('id') == undefined).forEach(a => a.deleteRecord());
      this.store.peekAll('pet').filter(p => p.get('id') == undefined).forEach(p => p.deleteRecord());
    },

    reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(a => a.reload());
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(p => p.reload());
    },

    actions: {
      requestAppointment() {
        if (this.get('allCategoriesRequireSigning')) {
          this.send('showSignContractsModalForSpecificCategory');
        } else {
          let pets = this.get('household.pets');
          let addresses = this.get('household.owner.addresses');

          if (pets.get('length') == 0 || addresses.get('length') == 0) {
            if (pets.get('length') == 0) {
              this.send('addPet');
            }

            if (addresses.get('length') == 0) {
              let state = this.get('currentUser.user.company.state');
              this.get('household').get('owner.addresses').pushObject(this.store.createRecord('address', {
                state
              }));
            }

            this.set('showMissingInfoModal', true);
            return;
          }

          let clientAccounts = this.get('currentUser.user.client_accounts');
          let activeClientAccounts = clientAccounts.filter(account => !account.company_deactivated && !account.denied_request && account.pet_parents_activated || account.staff_status == 'Owner');

          if (activeClientAccounts.length == 1) {
            this.send('checkAppointmentRequestFeature', activeClientAccounts[0]);
          } else {
            this.set('showRequestAppointmentModal', true);
          }
        }
      },

      checkAppointmentRequestFeature(clientAccount) {
        if (clientAccount.parent_in_app_booking) {
          window.location.href = this.get('reactApp').getAppointmentRequestUrl(clientAccount.company_id);
        } else {
          this.send('sendRequestAppointmentEmail', clientAccount.company_email);
        }
      },

      sendRequestAppointmentEmail(companyEmail) {
        this.send('closeRequestAppointmentModal');
        let petNames = this.get('household.petNames');
        let lastName = this.get('household.owner.last_name');
        let subject = `Appointment request for ${petNames} ${lastName}`;
        let userName = this.get('currentUser.user.fullName');
        let body = `Hi!%0D%0A%0D%0AI'd like to request an appointment for ${petNames} for the following:%0D%0A%0D%0AService:%0D%0A%0D%0AAppointment date(s) and time frame:%0D%0A%0D%0AAdditional notes:%0D%0A%0D%0APlease let me know if you can accommodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A${userName}`;
        window.location.href = `mailto:${companyEmail}?subject=${subject}&body=${body}`;
      },

      modifyAppointment(groupedAppointment) {
        let clientAccounts = this.get('currentUser.user.client_accounts');
        let clientAccount = clientAccounts.filter(acct => acct.company_id == groupedAppointment.get('company_id')).get('firstObject');

        if (clientAccount.parent_in_app_booking) {
          if (groupedAppointment.get('offered_service.all_day_service') || !groupedAppointment.get('offered_service.show_to_pet_parents')) {
            this.set('selectedClientAccount', clientAccount);
            this.set('showAllDayServiceModal', true);
          } else {
            _jquery.default.ajax({
              url: `${_environment.default.APP.host}/api/v2/grouped_appointments/${groupedAppointment.get('id')}/check_if_before_cut_off`,
              method: 'GET'
            }).then(response => {
              if (response.before_cut_off) {
                let ids = groupedAppointment.get('appointments').map(appt => appt.get('id'));
                window.location = this.get('reactApp').getAppointmentRequestUrl(groupedAppointment.get('company_id'), null, ids);
              } else {
                this.set('showTooSoonModal', true);
              }
            });
          }
        } else {
          this.sendModifyEmail(groupedAppointment);
        }
      },

      modifyAppointmentRequest(appointmentRequest) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/check_if_approved`,
          method: 'GET'
        }).then(response => {
          let {
            approved
          } = response;

          if (approved) {
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message: 'This request has already been approved. Please go to your appointments tab to modify this appointment.',
              type: 'user-error'
            });
          } else {
            window.location = this.get('reactApp').getAppointmentRequestUrl(appointmentRequest.get('company_id'), appointmentRequest.get('id'));
          }
        });
      },

      closeTooSoonModal() {
        this.set('showTooSoonModal', false);
      },

      goToMessenger() {
        this.transitionToRoute('portal.messages.company', this.get('selectedClientAccount.company_id'));
        this.set('selectedClientAccount', null);
        this.set('showAllDayServiceModal', false);
      },

      closeAllDayServiceModal() {
        this.set('selectedClientAccount', null);
        this.set('showAllDayServiceModal', false);
      },

      closeRequestAppointmentModal() {
        this.set('showRequestAppointmentModal', false);
      },

      clickSchedule() {
        this.set('appointmentType', SCHEDULE_TYPE);
      },

      clickPending() {
        this.set('appointmentType', PENDING_TYPE);
      },

      closeMissingInfoModal() {
        this.removeNewRecords.call(this);
        this.set('showMissingInfoModal', false);
      },

      addPet() {
        let household = this.get('currentUser.user.household');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.get('id')}/get_companies`,
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(response => {
          let pet = this.store.createRecord('pet');
          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },

      saveHousehold() {
        let petPromises = this.get('currentUser.user.household.pets').map(pet => pet.save());
        let userPromise = this.get('currentUser.user.household.owner').save();
        let promises = [userPromise, ...petPromises];
        return Ember.RSVP.Promise.all(promises).then(() => {
          this.removeNewRecords.call(this);
          this.reloadDirtyRecords.call(this);
          this.send('requestAppointment');
          this.set('showMissingInfoModal', false);
        });
      },

      showReportCard(groupedReportCard) {
        this.set('selectedReportCard', groupedReportCard);
      },

      closeReportCard() {
        this.set('selectedReportCard', null);
      }

    }
  });

  _exports.default = _default;
});