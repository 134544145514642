define("frontend/components/pick-a-day", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    picker: null,

    didInsertElement() {
      // eslint-disable-next-line no-undef
      this.set('picker', new Pikaday({
        field: (0, _jquery.default)(this.element.querySelectorAll('input'))[0]
      }));
    },

    willDestroyElement() {
      this.get('picker').destroy();
    }

  });

  _exports.default = _default;
});