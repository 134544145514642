define("frontend/controllers/inbox/household", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    inbox: inject.controller(),
    selectedHousehold: computed.alias('inbox.selectedHousehold'),
    selectedHouseholds: computed.alias('inbox.selectedHouseholds'),
    showingNewMessagePage: computed.alias('inbox.showingNewMessagePage'),
    household: computed.alias('model.household'),
    firstNames: computed('household.owner', 'household.secondaryContact', function () {
      if (this.get('household.secondaryContact')) {
        return `${this.get('household.owner.getFirstName')} & ${this.get('household.secondaryContact.getFirstName')}`;
      } else {
        return this.get('household.owner.getFirstName');
      }
    }),
    actions: {
      callbackAfterSentMessage() {
        let householdId = this.get('selectedHouseholds.firstObject.id');
        this.transitionToRoute('inbox.household', householdId).then(() => {
          this.set('showingNewMessagePage', false);
          this.set('selectedHouseholds', []);
          this.send('parentRefresh');
        });
      },

      transitionBackToInbox() {
        this.send('parentRefresh');
        this.send('transitionToInbox');
      }

    }
  });

  _exports.default = _default;
});