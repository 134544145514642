define("frontend/routes/edit-recurring-appointment-group/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      return this._super(...arguments);
    },

    getRepeatWeekDays(recurringAppointments, datesInPast) {
      let recurringStartDate = null;

      if (datesInPast) {
        recurringStartDate = moment().startOf('week');
      } else {
        recurringStartDate = moment(recurringAppointments.sortBy('start_date').get('firstObject').get('start_date')).startOf('week');
      }

      return recurringAppointments.map(recurringAppointment => {
        let startDate = moment(recurringAppointment.get('start_date'));
        let day = startDate.day();
        return recurringStartDate.day(day).format('YYYY-MM-DD');
      }).uniq();
    },

    setupController(controller, model) {
      this._super(...arguments);

      let {
        recurringAppointmentGroup
      } = model;

      if (!recurringAppointmentGroup) {
        return;
      }

      controller.set('showErrors', false);
      controller.set('changesStartInFuture', true);
      controller.set('appointmentDateSidePanelOpen', false);
      controller.set('endSidePanelOpen', false);
      controller.set('startSidePanelOpen', false);
      controller.set('sidePanelAppointment', null);
      controller.set('activeAppointment', null);
      controller.set('deletedRecurringAppointments', []);
      controller.set('editingExistingAppointment', false);
      controller.set('originalRecurringAppointmentCount', recurringAppointmentGroup.get('recurring_appointments.length'));
      let firstRecurringAppointment = recurringAppointmentGroup.get('recurring_appointments').sortBy('start_date').get('firstObject');

      if (!firstRecurringAppointment) {
        return;
      }

      if (this.anyDateStartsInPast(recurringAppointmentGroup.get('recurring_appointments').mapBy('start_date'))) {
        controller.set('startDate', null);
        let selectedRepeatWeekDays = this.getRepeatWeekDays(recurringAppointmentGroup.get('recurring_appointments'), true);
        controller.set('selectedRepeatWeekDays', selectedRepeatWeekDays);
        controller.set('originalDaysOfWeek', selectedRepeatWeekDays.map(day => moment(day).day()).sort());
      } else {
        controller.set('changesStartInFuture', false);
        controller.set('startDate', moment(firstRecurringAppointment.get('start_date')).format('YYYY-MM-DD'));
        let selectedRepeatWeekDays = this.getRepeatWeekDays(recurringAppointmentGroup.get('recurring_appointments'), false);
        controller.set('selectedRepeatWeekDays', selectedRepeatWeekDays);
        controller.set('originalDaysOfWeek', selectedRepeatWeekDays.map(day => moment(day).day()).sort());
      }

      let endDate = firstRecurringAppointment.get('end_date');

      if (endDate) {
        controller.set('selectedEndDateOption', 'date');
        controller.set('endDate', endDate);
        controller.set('originalEndDate', endDate);
      } else {
        controller.set('selectedEndDateOption', 'never');
        controller.set('endDate', moment().format('YYYY-MM-DD'));
      }

      controller.set('selectedRepeatOption', firstRecurringAppointment.get('frequency'));
    },

    anyDateStartsInPast(dates) {
      return dates.any(this.startsInPast);
    },

    startsInPast(date) {
      return moment(date, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day'));
    }

  });

  _exports.default = _default;
});