define("frontend/components/text-input-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputActivated: false,
    classNames: ['text-input-callout'],
    classNameBindings: ['dollar:dollar-callout-input', 'percent:percent-callout-input'],
    tabindex: 0,
    dollar: false,
    percent: false,
    hint: null,
    hover: false,
    hasError: false,
    type: 'text',
    borderTop: true,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).hover(() => {
        this.set('hover', true);
      }, () => {
        this.set('hover', false);
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).off('hover');
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.send('resetActivated');
    },

    actions: {
      focusIn() {
        this.set('focused', true);
      },

      focusOut() {
        this.set('focused', false);
        this.send('resetActivated');
      },

      clickCallout() {
        if (this.click) {
          this.click();
        }

        this.set('inputActivated', true);
        Ember.run.scheduleOnce('afterRender', () => {
          (0, _jquery.default)(this.element.querySelectorAll('input')).trigger('focus');
        });
      },

      resetActivated() {
        if (this.get('focused')) {
          return;
        }

        this.set('focused', false);
        this.set('inputActivated', this.get('value.length') > 0 || this.get('value') > 0);
      }

    }
  });

  _exports.default = _default;
});