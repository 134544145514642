define("frontend/components/message-thread/new-message-household-select/selecting-households-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    selectedRecipients: null,
    selectedServiceCategories: [],
    selectedDates: [],
    step: 1,
    showMultidateMessage: false,
    shownMultidateMessage: false,
    specificDatesSelected: false,
    currentUser: inject.service(),
    actions: {
      nextStep() {
        this.set('step', this.get('step') + 1);
      },

      prevStep() {
        let currentStep = this.get('step'); // Skip the date selection step if the user hasn't
        // selected specific dates

        if (currentStep === 3) {
          if (this.get('selectedRecipients') === 'specific-dates') {
            this.set('step', currentStep - 1);
          } else {
            this.set('step', currentStep - 2);
          }
        } else if (currentStep > 1) {
          this.set('step', currentStep - 1);
        } else {
          this.get('onClose')();
        }
      },

      hideMultiDateMessage() {
        this.set('showMultiDateMessage', false);
        this.set('shownMultidateMessage', true);
      },

      dismissedMultiDateMessage() {
        this.send('hideMultiDateMessage');
        this.get('currentUser.user').reload();
      },

      changeDates(dateArray) {
        let formattedDates = dateArray.map(date => (0, _moment.default)(date).format('YYYY-MM-DD'));
        this.set('selectedDates', formattedDates);

        if (this.get('selectedDates.length') === 1) {
          if (this.get('currentUser.user').shouldSeeMessage('request-multi-date') && !this.get('shownMultidateMessage')) {
            this.set('showMultiDateMessage', true);
          }
        }
      },

      changeSingularDate(momentDate) {
        let newDate = momentDate.format('YYYY-MM-DD');
        this.set('selectedDates', [newDate]);
      },

      updateSelectedRecipientDate(selection) {
        this.set('selectedRecipients', selection);

        if (selection === 'specific-dates') {
          this.set('step', this.get('step') + 1);
          this.set('specificDatesSelected', true);
        } else {
          this.set('step', this.get('step') + 2);
          this.set('specificDatesSelected', false);
        }
      },

      onClose() {
        this.get('onClose')();
      },

      selectAppointments() {
        this.get('selectAppointments')();
      },

      setSelectedServiceCategories(category) {
        let selectedCategories = this.get('selectedServiceCategories');

        if (selectedCategories.indexOf(category) > -1) {
          this.set('selectedServiceCategories', selectedCategories.without(category));
        } else {
          this.set('selectedServiceCategories', [...selectedCategories, category]);
        }
      },

      selectAllServiceCategories() {
        let serviceCategories = this.get('serviceCategories');
        let selectedServiceCategories = this.get('selectedServiceCategories');

        if (serviceCategories === selectedServiceCategories) {
          this.set('selectedServiceCategories', []);
        } else {
          this.set('selectedServiceCategories', serviceCategories);
        }
      }

    }
  });

  _exports.default = _default;
});