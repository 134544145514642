define("frontend/controllers/edit-recurring-appointment-group/index", ["exports", "frontend/utils/scroll-util", "frontend/utils/generate-dates", "moment"], function (_exports, _scrollUtil, _generateDates, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    nonAddOnOfferedServices: computed('model.offeredServices.[]', function () {
      return this.get('model.offeredServices').filter(offeredService => !offeredService.get('is_add_on'));
    }),
    sortedOfferedServices: computed.sort('nonAddOnOfferedServices', function (a, b) {
      if (a.get('service_category_position') > b.get('service_category_position')) {
        return 1;
      } else if (a.get('service_category_position') < b.get('service_category_position')) {
        return -1;
      } else {
        if (a.get('position') > b.get('position')) {
          return 1;
        } else if (a.get('position') < b.get('position')) {
          return -1;
        }

        return 0;
      }
    }),
    recurringAppointmentGroup: computed.alias('model.recurringAppointmentGroup'),
    recurringAppointments: computed.alias('recurringAppointmentGroup.recurring_appointments'),
    monthlyRecurringAppointment: computed.equal('recurringAppointments.firstObject.frequency', 'monthly'),
    endDateEndsOnDate: computed.equal('selectedEndDateOption', 'date'),
    editingExistingAppointment: false,
    sidePanelOpen: computed.notEmpty('sidePanelAppointment'),
    deletedRecurringAppointments: [],
    editRecurringAppointmentGroup: inject.controller(),
    backRoute: computed.readOnly('editRecurringAppointmentGroup.backRoute'),
    backHouseholdId: computed.readOnly('editRecurringAppointmentGroup.backHouseholdId'),
    uniquePets: computed.readOnly('recurringAppointmentGroup.pets'),
    saving: computed.readOnly('editRecurringAppointmentGroup.saving'),
    showErrors: false,
    requireDateError: false,
    currentUser: Ember.inject.service(),
    invalidAppointments: computed('sortedRepeatWeekDays.[]', function () {
      let noDates = this.get('sortedRepeatWeekDays.length') === 0;
      let noAppointmentsOnDate = this.get('sortedRepeatWeekDays').any(sortedRepeatWeekDay => sortedRepeatWeekDay.allAppointments.length === 0);
      return noDates || noAppointmentsOnDate;
    }),
    validAppointments: computed.not('invalidAppointments'),
    repeatsOptions: [{
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'biweekly',
      label: 'Every other week'
    }],
    weekDays: computed('startDate', function () {
      let start = (0, _moment.default)().startOf('week');
      let end = (0, _moment.default)().endOf('week');

      if (this.get('startDate')) {
        start = (0, _moment.default)(this.get('startDate')).startOf('week');
        end = (0, _moment.default)(this.get('startDate')).endOf('week');
      }

      let days = (0, _generateDates.default)(start, end);
      return days;
    }),
    sortedWeekDays: computed('weekDays.[]', function () {
      let weekDays = this.get('weekDays');
      return weekDays.sort((x, y) => {
        if ((0, _moment.default)(x).day() == 0) {
          return 1;
        } else if ((0, _moment.default)(y).day() == 0) {
          return -1;
        } else {
          if (x > y) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    }),
    assignStaffSidePanelHeader: computed('sidePanelAppointment.date', function () {
      return `${(0, _moment.default)(this.get('sidePanelAppointment.date')).format('dddd')} appointment options`;
    }),
    headerDateFormat: computed('monthlyRecurringAppointment', function () {
      if (this.get('monthlyRecurringAppointment')) {
        return '[1st of each month]';
      }

      return 'dddd[s]';
    }),

    sortDateAsc(firstDate, secondDate) {
      let momentFirstDate = (0, _moment.default)(firstDate).day();
      let momentSecondDate = (0, _moment.default)(secondDate).day();

      if (momentFirstDate == 0) {
        momentFirstDate = 7;
      }

      if (momentSecondDate == 0) {
        momentSecondDate = 7;
      }

      if (momentFirstDate > momentSecondDate) {
        return 1;
      } else if (momentFirstDate < momentSecondDate) {
        return -1;
      } else {
        return 0;
      }
    },

    dateWithAppointments(date) {
      let appointments = this.get('recurringAppointments').filter(appointment => {
        return (0, _moment.default)(appointment.get('start_date')).day() === (0, _moment.default)(date).day();
      });
      let appointmentsByPet = {};
      appointments.forEach(appointment => {
        let petId = appointment.get('pet.id');

        if (!appointmentsByPet[petId]) {
          appointmentsByPet[petId] = [];
        }

        appointmentsByPet[petId].pushObject(appointment);
      });
      let firstPetId = appointments.get('firstObject.pet.id');
      return {
        date,
        appointments: appointmentsByPet[firstPetId],
        allAppointments: appointments
      };
    },

    startDateFieldText: computed('startDate', function () {
      let startDate = this.get('startDate');

      if (startDate) {
        return (0, _moment.default)(startDate, 'YYYY-MM-DD').format('dddd, MMM D, YYYY');
      }

      return null;
    }),
    sidePanelStartDate: computed('startDate', function () {
      let startDate = this.get('startDate');

      if (startDate) {
        return startDate;
      }

      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    sortedRepeatWeekDays: computed('selectedRepeatWeekDays.[]', 'recurringAppointments.[]', 'recurringAppointments.@each.recurring_appointment_add_on_services.[]', function () {
      return this.get('selectedRepeatWeekDays').sort(this.sortDateAsc).map(this.dateWithAppointments.bind(this));
    }),
    selectedDaysOfWeek: computed('selectedRepeatWeekDays.[]', function () {
      return this.get('selectedRepeatWeekDays').map(day => (0, _moment.default)(day).day()).sort();
    }),
    endDateOptions: computed('endDate', 'endDateEndsOnDate', function () {
      let endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD').format('dddd, MMM D, YYYY');

      if (this.get('endDateEndsOnDate')) {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: endDate
        }];
      } else {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: 'On date'
        }];
      }
    }),
    offeredServiceOptions: computed.map('sortedOfferedServices', function (offeredService) {
      return {
        label: offeredService.get('nameWithDuration'),
        value: offeredService.get('id')
      };
    }),
    allActiveAppointments: computed('activeAppointment', 'recurringAppointments.[]', function () {
      let activeAppointment = this.get('activeAppointment');
      let groupAppointments = this.get('recurringAppointments').filterBy('recurring_appointment_group_id', activeAppointment.get('recurring_appointment_group_id'));
      return groupAppointments.filter(recurringAppointment => {
        return recurringAppointment.get('offered_service_id') === activeAppointment.get('offered_service_id') && recurringAppointment.get('careProvider.id') === activeAppointment.get('careProvider.id') && recurringAppointment.get('start_date') === activeAppointment.get('start_date') && recurringAppointment.get('preferred_start_time') === activeAppointment.get('preferred_start_time');
      });
    }),
    madeChanges: computed('selectedRepeatOption', 'selectedDaysOfWeek.[]', 'recurringAppointments.[]', 'recurringAppointments.@each.{offered_service_id,careProvider.id,start_date,preferred_start_time,frequency}', 'startDate', function () {
      let originalDoWs = this.get('originalDaysOfWeek');
      let selectedDoWs = this.get('selectedDaysOfWeek');

      if (originalDoWs.length !== selectedDoWs.length || !originalDoWs.every((value, index) => value === selectedDoWs[index])) {
        return true;
      }

      let recurringAppointments = this.get('recurringAppointments');

      if (recurringAppointments.get('length') != this.get('originalRecurringAppointmentCount')) {
        return true;
      }

      if (recurringAppointments.toArray().some(appt => appt.get('frequency') !== this.get('selectedRepeatOption'))) {
        return true;
      }

      let startDate = this.get('startDate');

      if (startDate && recurringAppointments.sortBy('start_date').get('firstObject.start_date') !== startDate) {
        return true;
      }

      let hasChanges = false;
      recurringAppointments.forEach(ra => hasChanges = hasChanges || ra.get('hasDirtyAttributes'));
      return hasChanges;
    }),
    actions: {
      clickDay(day) {
        if (this.get('selectedRepeatWeekDays').includes(day)) {
          let {
            allAppointments
          } = this.dateWithAppointments(day);
          allAppointments.forEach(appointment => {
            this.get('deletedRecurringAppointments').pushObject(appointment);
            appointment.deleteRecord();
          });
          this.get('selectedRepeatWeekDays').removeObject(day);
        } else {
          let {
            allAppointments
          } = this.dateWithAppointments(day);
          allAppointments.forEach(appointment => {
            this.get('deletedRecurringAppointments').removeObject(appointment);
          });
          this.get('selectedRepeatWeekDays').pushObject(day);
        }
      },

      closeAppointmentDateSidePanel() {
        this.set('appointmentDateSidePanelOpen', false);
      },

      openAppointmentDateSidePanel() {
        this.set('appointmentDateSidePanelOpen', true);
      },

      selectEndDate(value) {
        let onDateSelected = value === 'date';
        this.set('endSidePanelOpen', onDateSelected);

        if (!onDateSelected) {
          this.set('endDate', (0, _moment.default)().format('YYYY-MM-DD'));
        }
      },

      addNewAppointment(date) {
        let careProvider = this.get('model.careProviders').filterBy('id', this.get('currentUser.user.id')).get('firstObject');
        let offered_service = this.get('sortedOfferedServices.firstObject');
        this.set('activeAppointment', null);
        this.set('editingExistingAppointment', false);
        let time = offered_service.get('all_day_service') ? '12:00 AM' : null;
        let addOnServices = [];
        this.set('sidePanelAppointment', {
          date,
          careProvider,
          time,
          offered_service,
          addOnServices
        });
      },

      editExistingAppointment(appointment, date) {
        this.set('editingExistingAppointment', true);
        this.set('activeAppointment', appointment);
        let careProviderId = appointment.get('careProvider.id');
        let careProvider = this.get('model.careProviders').filterBy('id', careProviderId).get('firstObject');

        if (!date) {
          date = appointment.get('start_date');
        }

        let time = appointment.get('time');
        let offered_service = appointment.get('offered_service');
        let timeFrameStart = appointment.get('timeFrameStart');
        let timeFrameEnd = appointment.get('timeFrameEnd');
        let setByCustomWindow = appointment.get('set_by_custom_window');
        let positionsPreviousPets = appointment.get('positions_previous_pets');
        let ras = this.get('recurringAppointments').filter(ra => {
          return (0, _moment.default)(appointment.get('start_date')).day() === (0, _moment.default)(ra.get('start_date')).day() && appointment.get('offered_service.id') === ra.get('offered_service.id') && appointment.get('careProvider.id') === ra.get('careProvider.id') && appointment.get('preferred_start_time') === ra.get('preferred_start_time') && appointment.get('timeFrameStart') === ra.get('timeFrameStart') && appointment.get('timeFrameEnd') === ra.get('timeFrameEnd');
        });
        let addOnServices = {};
        ras.forEach(ra => {
          ra.get('recurring_appointment_add_on_services').forEach(raaos => {
            let osId = raaos.get('offered_service.id');

            if (!addOnServices[osId]) {
              addOnServices[osId] = Ember.Object.create({
                offered_service: raaos.get('offered_service'),
                petIds: [],
                petNames: []
              });
            }

            addOnServices[osId].petIds.pushObject(ra.get('pet.id'));
            addOnServices[osId].petNames.pushObject(ra.get('pet.name'));
          });
        });
        addOnServices = Object.values(addOnServices);
        this.set('sidePanelAppointment', {
          date,
          careProvider,
          time,
          offered_service,
          timeFrameStart,
          timeFrameEnd,
          setByCustomWindow,
          positionsPreviousPets,
          addOnServices
        });
      },

      removeAppointment() {
        let allActiveAppointments = this.get('allActiveAppointments');
        allActiveAppointments.forEach(appointment => {
          this.get('deletedRecurringAppointments').pushObject(appointment);
          this.get('recurringAppointments').removeObject(appointment);
          appointment.deleteRecord();
        });
        this.send('hideSidePanel');
      },

      changeEndDate(date) {
        this.send('closeEndSidePanel');
        this.set('endDate', (0, _moment.default)(date).format('YYYY-MM-DD'));
      },

      closeEndSidePanel() {
        this.set('endSidePanelOpen', false);
      },

      openEndSidePanel() {
        this.set('endSidePanelOpen', true);
      },

      changeStartDate(date) {
        this.set('requireDateError', false);
        this.send('closeStartSidePanel');
        let startDate = (0, _moment.default)(date).format('YYYY-MM-DD');
        this.set('startDate', startDate);
        let start = (0, _moment.default)(startDate).startOf('week').add(1, 'day');
        let end = (0, _moment.default)(startDate).endOf('week').add(1, 'day');
        let days = (0, _generateDates.default)(start, end);
        this.set('weekDays', days);
        let oldDates = this.get('selectedRepeatWeekDays');
        this.set('selectedRepeatWeekDays', []);
        oldDates.forEach(oldDay => {
          days.forEach(newDay => {
            if ((0, _moment.default)(oldDay).day() == (0, _moment.default)(newDay).day()) {
              this.send('clickDay', newDay);
            }
          });
        });
      },

      closeStartSidePanel() {
        this.set('startSidePanelOpen', false);
      },

      openStartSidePanel() {
        this.set('startSidePanelOpen', true);
      },

      updateAppointment(appointmentProps) {
        let preferred_start_time = _moment.default.utc(appointmentProps.time, 'h:mm A');

        let props = {
          preferred_start_time,
          offered_service: appointmentProps.offered_service,
          careProvider: appointmentProps.careProvider,
          timeFrameStart: appointmentProps.timeFrameStart,
          timeFrameEnd: appointmentProps.timeFrameEnd,
          set_by_custom_window: appointmentProps.setByCustomWindow,
          positions_previous_pets: appointmentProps.positionsPreviousPets
        };
        let allActiveAppointments = this.get('allActiveAppointments');
        allActiveAppointments.forEach(appointment => {
          appointment.setProperties(props);
          let petAddOns = appointmentProps.addOnServices.filter(addOnService => addOnService.petIds.includes(appointment.get('pet.id')));
          let newAddOns = petAddOns.map(addOnService => {
            return this.store.createRecord('recurring-appointment-add-on-service', {
              offered_service: addOnService.offered_service,
              recurring_appointment: appointment
            });
          });
          appointment.setProperties({
            recurring_appointment_add_on_services: newAddOns
          });
        });
        this.send('hideSidePanel');
      },

      createRecurringAddOnService(pets) {
        let petIds = pets.map(pet => pet.get('id'));
        let petNames = pets.map(pet => pet.get('name'));
        return Ember.Object.create({
          offered_service: null,
          petIds,
          petNames
        });
      },

      createAppointment(appointmentProps) {
        let {
          careProvider,
          date,
          time,
          timeFrameStart,
          timeFrameEnd,
          setByCustomWindow,
          positionsPreviousPets,
          addOnServices
        } = appointmentProps;
        date = (0, _moment.default)(date).format('YYYY-MM-DD');
        time = _moment.default.utc(time, 'h:mm A').format();
        let {
          offered_service
        } = appointmentProps;
        let day_of_week = (0, _moment.default)(date).day();
        this.get('uniquePets').forEach(pet => {
          let recurringAppointment = this.store.createRecord('recurring-appointment', {
            careProvider,
            preferred_start_time: time,
            offered_service,
            day_of_week,
            recurrence_ends_on_type: this.get('selectedEndDateOption'),
            frequency: this.get('selectedRepeatOption'),
            start_date: date,
            pet,
            timeFrameStart,
            timeFrameEnd,
            set_by_custom_window: setByCustomWindow,
            positions_previous_pets: positionsPreviousPets
          });
          let petAddOns = addOnServices.filter(addOnService => addOnService.petIds.includes(pet.get('id')));
          petAddOns.forEach(addOnService => {
            let apptAddOnService = this.store.createRecord('recurring-appointment-add-on-service', {
              offered_service: addOnService.offered_service,
              recurring_appointment: recurringAppointment
            });
            recurringAppointment.get('recurring_appointment_add_on_services').pushObject(apptAddOnService);
          });
          this.get('recurringAppointments').pushObject(recurringAppointment);
        });
        this.send('hideSidePanel');
      },

      hideSidePanel() {
        this.set('editingExistingAppointment', false);
        this.set('sidePanelAppointment', null);
        this.set('activeAppointment', null);
      },

      save() {
        this.set('showErrors', true);
        this.set('requireDateError', false);
        let valid = this.get('validAppointments');
        let madeChanges = this.get('madeChanges');
        let madeChangesToEndDate = this.get('endDate') != this.get('originalEndDate');

        if (!madeChanges && !madeChangesToEndDate) {
          this.send('cancel', this.get('model.recurringAppointmentGroup'), this.get('backRoute'), this.get('backHouseholdId'));
          return;
        }

        if (valid && (madeChanges && this.get('startDate') || !madeChanges)) {
          this.get('editRecurringAppointmentGroup').send('save', ...arguments);
        } else {
          if (!this.get('startDate')) {
            this.set('requireDateError', true);
          }

          _scrollUtil.default.scrollTopWithHeader('.red-validation-error, .assign-staff-date--error');
        }
      }

    }
  });

  _exports.default = _default;
});