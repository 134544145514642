define("frontend/components/staff-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['staff-item'],
    staff: null,
    classNameBindings: ['active'],
    active: false,

    click() {
      this.clickStaff(this.get('staff'));
    }

  });

  _exports.default = _default;
});