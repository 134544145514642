define("frontend/utils/address-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HOME = 'home';
  const WORK = 'work';
  const OTHER = 'other';
  var _default = {
    HOME,
    WORK,
    OTHER
  };
  _exports.default = _default;
});