define("frontend/routes/companies/edit", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    setupController(controller) {
      this._super(...arguments);

      controller.set('addressSidePanelActivated', false);
    },

    actions: {
      willTransition() {
        this.get('controller.model').rollbackAttributes();
      }

    }
  });

  _exports.default = _default;
});