define("frontend/components/payments-widget/payments-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['payments-widget-payments-table'],
    tagName: 'header',
    extraHeaders: [],
    paymentHeaders: [],
    sortBy: 'date',
    sortAsc: false,
    showProcessingFee: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('processing fee');
    }),
    showNetSales: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('net sales');
    }),
    showAddTip: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('add tip');
    }),
    showNetTip: computed('extraHeaders.[]', function () {
      return this.get('showAddTip') && this.get('extraHeaders').includes('processing fee');
    }),
    actions: {
      clickSort(header) {
        let sortBy = this.get('sortBy');

        if (sortBy == header) {
          this.set('sortAsc', !this.get('sortAsc'));
        } else {
          this.set('sortBy', header);
          this.set('sortAsc', false);
        }
      },

      toggleCollapsed(header) {
        if (header.collapsed) {
          header.set('collapsed', false);
        } else {
          header.set('collapsed', true);
        }
      }

    }
  });

  _exports.default = _default;
});