define("frontend/controllers/companies/pricing", ["exports", "frontend/config/environment", "frontend/services/flash-message", "jquery"], function (_exports, _environment, _flashMessage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    sidePanelOpen: false,
    selectedOfferedService: null,
    companies: inject.controller(),
    saving: computed.alias('companies.saving'),
    overrideSavingMessage: computed.alias('companies.overrideSavingMessage'),
    newService: computed.empty('selectedOfferedService.id'),
    addingNewService: computed.and('sidePanelOpen', 'newService'),
    currentUser: Ember.inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    flashMessage: Ember.inject.service(),
    dragAndDropActivated: false,
    serviceCategoriesWithServices: computed('model.serviceCategories.[]', 'model.offeredServices.[]', function () {
      let offeredServices = this.get('model.offeredServices');
      return this.get('model.serviceCategories').map(category => {
        let services = offeredServices.filter(service => service.get('service_category_id') == category.get('id'));
        services = services.sortBy('position');
        Ember.set(category, 'services', services);
        return category;
      });
    }),
    addCategoryWidth: computed('serviceCategoriesWithServices.[]', function () {
      let totalCategories = this.get('serviceCategoriesWithServices.length');

      if (totalCategories == 8) {
        return false;
      } else {
        if (totalCategories % 2 == 0) {
          return 'full-width';
        } else {
          return 'half-width';
        }
      }
    }),
    actions: {
      dragStart() {
        // The run later is in case you're trying to drag a service blocked by the scroll area
        Ember.run.later(() => {
          this.set('dragScrollActivated', true);
        }, 0);
      },

      dragEnd() {
        this.set('dragScrollActivated', false);
      },

      clickDragAndDrop() {
        this.toggleProperty('dragAndDropActivated');
        this.set('showDragAndDropFlashNote', true);
        Ember.run.later(() => {
          this.set('showDragAndDropFlashNote', false);
        }, 2000);
      },

      createOfferedService(serviceCategory) {
        let company_id = this.get('company.id');
        let newOfferedService = this.store.createRecord('offered-service', {
          capacity: 30,
          company_id,
          service_category: serviceCategory,
          show_to_pet_parents: this.get('company.pet_parent_appointment_requests_enabled')
        });
        this.set('selectedOfferedService', newOfferedService);
        this.set('sidePanelOpen', true);
      },

      editOfferedService(offeredService) {
        if (this.get('selectedOfferedService')) {
          this.get('selectedOfferedService').rollbackAttributes();
        }

        this.set('selectedOfferedService', offeredService);
        let price_start_date = offeredService.get('price_start_date');

        if (price_start_date && moment(price_start_date) > moment()) {
          this.set('selectedOfferedService.effectiveDate', moment.utc(price_start_date).format('MMMM D, YYYY'));
          this.set('selectedOfferedService.price', offeredService.get('latest_price'));
          this.set('selectedOfferedService.incremental_price', offeredService.get('latest_incremental_price'));
        }

        if (this.get('selectedOfferedService.all_day_service') && !this.get('selectedOfferedService.has_appointments')) {
          this.set('offeredServiceLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/offered_services/${this.get('selectedOfferedService.id')}/has_any_appointments`,
            method: 'GET'
          }).then(response => {
            this.set('selectedOfferedService.has_any_appointments', response.has_any_appointments);
            this.set('offeredServiceLoading', false);
          });
        }

        this.set('sidePanelOpen', true);
      },

      cancelSidePanel() {
        this.get('selectedOfferedService').rollbackAttributes();
        this.set('sidePanelOpen', false);
        this.set('selectedOfferedService', null);
      },

      repositionOfferedServices() {
        let sorted_offered_service_ids = this.get('model.offeredServices').mapBy('id').filter(id => id != null);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/offered_services/reposition`,
          method: 'PUT',
          data: {
            sorted_offered_service_ids
          }
        }).then(offeredServices => {
          this.store.pushPayload(offeredServices);
        });
      },

      deleteOfferedService(offeredService) {
        this.set('sidePanelOpen', false);
        this.set('selectedOfferedService', null);
        this.set('overrideSavingMessage', 'Loading...');
        this.set('saving', true);
        this.transitionToRoute('companies.remove-service', offeredService.id).then(() => {
          this.set('overrideSavingMessage', null);
          this.set('saving', false);
        });
      },

      saveOfferedService(offeredService) {
        offeredService.save().then(() => {
          this.store.query('offered-service', {}, {
            reload: true
          }).then(offeredServices => {
            this.set('model.offeredServices', offeredServices);
            this.set('selectedOfferedService', null);
            this.set('sidePanelOpen', false);
          });
        }).catch(err => {
          let hasExpectedError = err.errors != null && err.errors.length > 0 && err.errors[0].status !== '500';

          if (hasExpectedError) {
            this.get('flashMessage').errorWithRefresh(err.errors[0].detail);
          } else {
            this.get('flashMessage').error(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
          }

          this.set('saving', false);
        });
      },

      toggleSelectingCategories() {
        this.toggleProperty('selectingCategories');
      },

      redirectToSettings(categoryId) {
        return this.transitionToRoute('companies.category-settings', this.get('company.id'), 'time-blocks', {
          queryParams: {
            categoryId
          }
        });
      },

      toggleRequestsModal() {
        this.toggleProperty('petParentRequestsModal');
      },

      saveRequestsModal() {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/companies/${this.get('company.id')}/enable_pet_parent_requests`,
          method: 'POST',
          data: {
            enabled: this.get('company.pet_parent_appointment_requests_enabled')
          }
        }).then(() => {
          window.location.reload();
        });
      }

    }
  });

  _exports.default = _default;
});