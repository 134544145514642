define("frontend/components/staff-header", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOM = 'custom';
  const {
    computed,
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['staff-header'],
    payFrequency: null,
    payPeriod: null,
    timeZone: service(),
    customStartDate: null,
    customEndDate: null,
    hasCustomDate: computed.notEmpty('customStartDate'),
    setInitialPayPeriod: false,
    selectedPayPeriod: computed('payFrequencyOptions.firstObject.value', function () {
      return this.get('payFrequencyOptions.firstObject.value');
    }),

    didReceiveAttrs() {
      this._super(...arguments); // Make sure we don't set the initial pay period until we've gotten the
      // company's pay frequency otherwise it will set it to the last week


      if (!this.get('setInitialPayPeriod') && this.get('payFrequency') && this.get('payFrequencyOptions').length > 0) {
        this.send('changePayPeriod', this.get('selectedPayPeriod'));
        this.set('setInitialPayPeriod', true);
      }
    },

    payFrequencyWeeks(currentMoment) {
      let startWeek = currentMoment.clone().startOf('isoweek');
      let endWeek = currentMoment.clone().endOf('isoweek');
      let twoWeeksAgo = startWeek.clone().subtract('week', 2);
      let options = [];

      while (startWeek.diff(twoWeeksAgo, 'weeks') > 0) {
        let startFormat = startWeek.format('M/D');
        let endFormat = endWeek.format('M/D');
        let value = `${startWeek.format('YYYY-MM-DD')}.${endWeek.format('YYYY-MM-DD')}`;

        if (startWeek.diff(twoWeeksAgo, 'weeks') === 2) {
          options.push({
            label: `Current pay period ${startFormat} - ${endFormat}`,
            value
          });
        } else if (startWeek.diff(twoWeeksAgo, 'weeks') === 1) {
          options.push({
            label: `Previous pay period ${startFormat} - ${endFormat}`,
            value
          });
        }

        startWeek.subtract(1, 'week');
        endWeek.subtract(1, 'week');
      }

      return options;
    },

    payFrequencyBiWeekly(currentMoment, payPeriod) {
      let startWeek = currentMoment.clone().startOf('isoweek');
      let endWeek = currentMoment.clone().add(1, 'week').endOf('isoweek');
      let numWeeksPast = currentMoment.clone().startOf('isoweek').diff((0, _moment.default)(payPeriod), 'weeks'); // If the current week is not the end of the pay period

      if (numWeeksPast % 2 != 0) {
        startWeek = currentMoment.clone().subtract(1, 'week').startOf('isoweek');
        endWeek = currentMoment.clone().endOf('isoweek');
      } else {
        startWeek = currentMoment.clone().startOf('isoweek');
        endWeek = currentMoment.clone().add(1, 'week').endOf('isoweek');
      }

      let fourWeeksAgo = startWeek.clone().subtract(4, 'weeks');
      let options = [];

      while (startWeek.diff(fourWeeksAgo, 'weeks') > 0) {
        let startFormat = startWeek.format('M/D');
        let endFormat = endWeek.format('M/D');
        let value = `${startWeek.format('YYYY-MM-DD')}.${endWeek.format('YYYY-MM-DD')}`;

        if (startWeek.diff(fourWeeksAgo, 'weeks') === 4) {
          options.push({
            label: `Current pay period ${startFormat} - ${endFormat}`,
            value
          });
        } else if (startWeek.diff(fourWeeksAgo, 'weeks') === 2) {
          options.push({
            label: `Previous pay period ${startFormat} - ${endFormat}`,
            value
          });
        }

        startWeek.subtract(2, 'week');
        endWeek.subtract(2, 'week');
      }

      return options;
    },

    payFrequencySemiMonthly(currentMoment) {
      let startMonth = currentMoment.clone().startOf('month');
      let midMonth = currentMoment.clone().date(15);
      let endMonth = currentMoment.clone().endOf('month');
      let options = [];

      if (currentMoment.date() <= 14) {
        midMonth.subtract(1, 'day');
        let value = `${startMonth.format('YYYY-MM-DD')}.${midMonth.format('YYYY-MM-DD')}`;
        options.push({
          label: `Current pay period ${startMonth.format('M/D')} - ${midMonth.format('M/D')}`,
          value
        });
        let pastMidMonth = currentMoment.clone().subtract(1, 'month').date(15);
        let pastEndMonth = currentMoment.clone().subtract(1, 'month').endOf('month');
        value = `${pastMidMonth.format('YYYY-MM-DD')}.${pastEndMonth.format('YYYY-MM-DD')}`;
        options.push({
          label: `Previous pay period ${pastMidMonth.format('M/D')} - ${pastEndMonth.format('M/D')}`,
          value
        });
      } else {
        let value = `${midMonth.format('YYYY-MM-DD')}.${endMonth.format('YYYY-MM-DD')}`;
        options.push({
          label: `Current pay period ${midMonth.format('M/D')} - ${endMonth.format('M/D')}`,
          value
        });
        midMonth.subtract(1, 'day');
        value = `${startMonth.format('YYYY-MM-DD')}.${midMonth.format('YYYY-MM-DD')}`;
        options.push({
          label: `Previous pay period ${startMonth.format('M/D')} - ${midMonth.format('M/D')}`,
          value
        });
      }

      return options;
    },

    payFrequencyMonthly(currentMoment) {
      let startMonth = currentMoment.clone().startOf('month');
      let endMonth = currentMoment.clone().endOf('month');
      let oneMonthAgoStart = startMonth.clone().subtract('month', 1);
      let oneMonthAgoEnd = startMonth.clone().subtract('month', 1).endOf('month');
      let options = [];
      let value = `${startMonth.format('YYYY-MM-DD')}.${endMonth.format('YYYY-MM-DD')}`;
      options.push({
        label: `Current pay period ${startMonth.format('M/D')} - ${endMonth.format('M/D')}`,
        value
      });
      value = `${oneMonthAgoStart.format('YYYY-MM-DD')}.${oneMonthAgoEnd.format('YYYY-MM-DD')}`;
      options.push({
        label: `Previous pay period ${oneMonthAgoStart.format('M/D')} - ${oneMonthAgoEnd.format('M/D')}`,
        value
      });
      return options;
    },

    customLabel: computed('customStartDate', 'customEndDate', function () {
      let momentStart = (0, _moment.default)(this.get('customStartDate'), 'YYYY-MM-DD');
      let momentEnd = (0, _moment.default)(this.get('customEndDate'), 'YYYY-MM-DD');

      if (this.get('customStartDate')) {
        return `Custom (${momentStart.format('M/D')} - ${momentEnd.format('M/D')})`;
      }

      return 'Custom';
    }),
    payFrequencyOptions: computed('payFrequency', 'payPeriod', 'customLabel', function () {
      let currentMoment = this.get('timeZone.companyMoment');
      let payFrequency = this.get('payFrequency');
      let payPeriod = this.get('payPeriod');
      let periods;

      switch (payFrequency) {
        case 'semi_monthly':
          periods = this.payFrequencySemiMonthly(currentMoment);
          break;

        case 'bi_weekly':
          periods = this.payFrequencyBiWeekly(currentMoment, payPeriod);
          break;

        case 'monthly':
          periods = this.payFrequencyMonthly(currentMoment);
          break;

        default:
          periods = this.payFrequencyWeeks(currentMoment);
      }

      let start = currentMoment.clone().subtract(4, 'weeks').startOf('day');
      let value = `${start.format('YYYY-MM-DD')}.${currentMoment.endOf('day').format('YYYY-MM-DD')}`;
      periods.push({
        label: 'Last 4 weeks',
        value
      });
      start = currentMoment.clone().subtract(3, 'months');
      value = `${start.format('YYYY-MM-DD')}.${currentMoment.endOf('day').format('YYYY-MM-DD')}`;
      periods.push({
        label: 'Last 3 months',
        value
      });
      value = CUSTOM;
      let customLabel = this.get('customLabel');
      periods.push({
        label: customLabel,
        value
      });
      return periods;
    }),
    exportButtonClassNames: computed('showSearch', function () {
      return this.get('showSearch') ? 'export-button' : 'export-button without-search';
    }),
    actions: {
      addEmployee() {
        this.addEmployee();
      },

      showCustomDateRangeModal() {
        this.set('customStartDateCopy', this.get('customStartDate'));
        this.set('customEndDateCopy', this.get('customEndDate'));
        this.set('showingCustomDateRangeModal', true);
      },

      submitCustomDateRange(startDate, endDate) {
        let momentStart = (0, _moment.default)(startDate, 'YYYY-MM-DD');
        let momentEnd = (0, _moment.default)(endDate, 'YYYY-MM-DD');
        this.set('customStartDate', startDate);
        this.set('customEndDate', endDate);
        this.changePayPeriod(momentStart.format('YYYY-MM-DD'), momentEnd.format('YYYY-MM-DD'));
        this.set('showingCustomDateRangeModal', false);
        this.set('previousPayPeriod', 'custom');
      },

      closeCustomDateRangeModal() {
        this.set('showingCustomDateRangeModal', false);
        this.set('selectedPayPeriod', this.get('previousPayPeriod'));
      },

      exportData() {
        this.get('exportData')();
      },

      changePayPeriod(value, previousValue) {
        if (value === CUSTOM) {
          this.set('previousPayPeriod', previousValue);
          this.set('showingCustomDateRangeModal', true);
        } else {
          let [startDate, endDate] = value.split('.');
          this.set('customStartDate', null);
          this.set('customEndDate', null);
          this.changePayPeriod(startDate, endDate);
        }
      }

    }
  });

  _exports.default = _default;
});