define("frontend/components/pet-parent-requests-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    saving: false,

    didInsertElement() {
      this._super(...arguments);

      this.set('saving', false);
    },

    actions: {
      onClose() {
        this.set('saving', true);
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});