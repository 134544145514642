define("frontend/components/collapse-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['collapse-button'],
    collapsed: false,
    expanded: computed.not('collapsed')
  });

  _exports.default = _default;
});