define("frontend/routes/staff-search", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "frontend/mixins/cleanup-on-transition", "frontend/routes/base-route"], function (_exports, _authenticatedRouteMixin, _cleanupOnTransition, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    actions: {
      transitionToStaff() {
        let employeeFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';
        this.transitionTo('staff.index', {
          queryParams: {
            employeeFilter
          }
        });
      },

      redirectToAddedUser(_, employeeId) {
        this.store.findAll('employee', {
          reload: true
        }).then(employees => {
          employees.forEach(employee => {
            if (employee.id == employeeId) {
              this.transitionTo('edit-staff', employee);
            }
          });
        });
      },

      redirectToUser(_, userId) {
        this.store.findAll('employee').then(employees => {
          employees.forEach(employee => {
            if (employee.get('user').get('id') == userId) {
              this.transitionTo('staff.index', {
                queryParams: {
                  employeeFilter: userId
                }
              });
              return;
            }
          });
        });
      }

    }
  });

  _exports.default = _default;
});