define("frontend/components/grouped-appointment-list-item", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['grouped-appointment-list-item'],
    classNameBindings: ['swipedLeft', 'swipedLeftOneButton', 'swipedLeftThreeButton', 'groupedAppointment.completed:completed', 'hasHoverButtons:has-hover-buttons', 'showCanceledAppointments:showing-canceled-appts'],
    checkStartedAppointment: inject.service(),
    media: inject.service(),
    groupedAppointment: null,
    showCanceledAppointments: false,
    canceledAt: computed.alias('groupedAppointment.appointments.firstObject.canceled_at'),
    canceledBecauseOfRequest: computed.alias('groupedAppointment.appointments.firstObject.canceled_because_of_request'),
    showReportCardActions: computed('groupedAppointment.appointments.[]', function () {
      let appointments = this.get('groupedAppointment.appointments');
      let showReportCard = false;
      appointments.toArray().some(appointment => {
        if (appointment.get('grouped_report_card.id') && !appointment.get('grouped_report_card.draft')) {
          showReportCard = true;
          return true;
        }
      });
      return showReportCard;
    }),
    swipedLeftOneButton: computed.and('swipedLeft', 'showReportCardActions'),
    swipedLeftThreeButton: computed('swipedLeft', 'showReportCardActions', 'isPetParent', 'showCanceledAppointments', 'cancellationNote', function () {
      let swipedLeft = this.get('swipedLeft');

      if (!swipedLeft || this.get('showReportCardActions')) {
        return false;
      } else if (this.get('showCanceledAppointments') && this.get('cancellationNote')) {
        return true;
      }

      return !this.get('isPetParent') && !this.get('showCanceledAppointments');
    }),
    doNotShowReportCardActions: computed.not('showReportCardActions'),
    petNames: computed('groupedAppointment.petNames', function () {
      if (this.get('groupedAppointment.petNames').length >= 30 && this.get('media.isMobile')) {
        return `all ${this.get('familyName')} family pets`;
      } else {
        return this.get('groupedAppointment.petNames');
      }
    }),
    isStartedButNotFinished: computed('groupedAppointment.appointments.[]', function () {
      let isStarted = false;
      this.get('groupedAppointment.appointments').forEach(appt => {
        if (appt.get('started_at') && !appt.get('finished_at')) {
          isStarted = true;
        }
      });
      return isStarted;
    }),
    addOnServices: computed('groupedAppointment.appointments.@each.add_on_services.[]', function () {
      let appts = this.get('groupedAppointment.appointments'); // eslint-disable-next-line no-undef

      let addOnServices = {};
      appts.forEach(appt => {
        appt.get('appointment_add_on_services').forEach(service => {
          if (!addOnServices[service.get('offered_service.id')]) {
            addOnServices[service.get('offered_service.id')] = {
              offered_service: service.get('offered_service'),
              petNames: []
            };
          }

          addOnServices[service.get('offered_service.id')].petNames.pushObject(appt.get('pet.name'));
        });
      });
      return Object.values(addOnServices);
    }),
    addOnServicesTooltipText: computed('addOnServices.[]', function () {
      let addOns = this.get('addOnServices');
      let text = '<div class="tooltip-header">add-ons:</div>';
      addOns.forEach(addOn => {
        text += `<div class="tooltip-add-on-service">+ ${addOn.offered_service.get('nameWithDuration')}`;

        if (addOn.petNames.length != this.get('groupedAppointment.appointments.length')) {
          text += ` for <span class="bold">${_stringUtil.default.joinWithAnd(addOn.petNames)}</span>`;
        }

        text += '</div>';
      });
      return text;
    }),

    click(event) {
      event.stopPropagation();

      if (this.get('swipedLeft')) {
        this.closeMobileButtons();
      } else {
        this.openMobileButtons();
      }
    },

    openMobileButtons() {
      if (this.get('media.isMobile') && this.get('hasHoverButtons') && !this.get('bulkEditing')) {
        this.set('swipedLeft', true);
      }
    },

    closeMobileButtons() {
      if (this.get('media.isMobile') && this.get('hasHoverButtons')) {
        this.set('swipedLeft', false);
      }
    },

    shouldBeBlue: computed('groupedAppointment.service.starts_at', function () {
      let today = moment().tz(this.get('groupedAppointment.time_zone')).startOf('day');
      let date = moment(this.get('groupedAppointment.service.starts_at')).startOf('day');
      return [0, 1].includes(date.diff(today, 'days'));
    }),
    isUpcoming: computed('groupedAppointment.service.starts_at', function () {
      let today = moment().tz(this.get('groupedAppointment.time_zone')).startOf('day');
      let date = moment(this.get('groupedAppointment.service.starts_at'));
      return today.isBefore(date);
    }),
    isPastDue: computed('groupedAppointment.service.starts_at', 'groupedAppointment.service.starts_at_time', function () {
      let now = moment().tz(this.get('groupedAppointment.time_zone'));

      if (this.get('groupedAppointment.offered_service.all_day_service')) {
        return now > moment(this.get('groupedAppointment.service.starts_at')).add(1, 'day');
      }

      let day = moment(this.get('groupedAppointment.service.starts_at')).format('YYYY-MM-DD');
      let pickupWindow = this.get('groupedAppointment.pickup_window');

      if (pickupWindow) {
        let [, endTime] = pickupWindow.split(' - ');
        return now > moment(`${day} ${endTime}`, 'YYYY-MM-DD h:mmA').add(this.get('groupedAppointment.offered_service.duration_in_minutes'), 'minutes').add(1, 'hour');
      } else {
        let time = this.get('groupedAppointment.service.starts_at_time');
        return now > moment(`${day} ${time}`, 'YYYY-MM-DD h:mm A').add(this.get('groupedAppointment.offered_service.duration_in_minutes'), 'minutes').add(1, 'hour');
      }
    }),
    hasHoverButtons: computed('bulkEditing', 'readOnly', 'isPetParent', 'groupedAppointment.can_delete', 'groupedAppointment.completed', 'isUpcoming', 'groupedAppointment.showCancellations', 'showReportCardActions', function () {
      if (this.get('readOnly')) {
        return false;
      }

      if (!this.get('isPetParent') && this.get('groupedAppointment.can_delete')) {
        return true;
      }

      let isCompleted = this.get('groupedAppointment.completed');
      let hasReportCard = this.get('showReportCardActions');
      return (this.get('showCanceledAppointments') || this.get('groupedAppointment.showRequests')) && (isCompleted && hasReportCard || !isCompleted && this.get('isUpcoming'));
    }),
    pets: computed('groupedAppointment.appointments.@each.pets.[]', function () {
      return this.get('groupedAppointment.appointments').map(appt => {
        return appt.get('pet');
      });
    }),
    hasStartedAppointments: computed('groupedAppointment.appointments.[]', function () {
      return this.get('checkStartedAppointment').hasStartedAppointment(this.get('groupedAppointment'));
    }),
    doesNotHaveStartedAppointments: computed.not('hasStartedAppointments'),
    specialInstructions: computed('groupedAppointment.special_instructions.[]', 'pets.[]', function () {
      let instructions = this.get('groupedAppointment.special_instructions');
      instructions = instructions.filter(instruction => {
        return instruction.note;
      });
      let apptCount = this.get('pets.length');

      if (apptCount > 1) {
        let uniqueNotes = {};
        instructions.forEach(instruction => {
          if (uniqueNotes[instruction.note]) {
            uniqueNotes[instruction.note].pushObject(instruction.name);
          } else {
            uniqueNotes[instruction.note] = [instruction.name];
          }
        });
        let notes = '';
        Object.keys(uniqueNotes).forEach(key => {
          notes += `For ${_stringUtil.default.joinWithAnd(uniqueNotes[key])}: ${key}\n`;
        });
        return notes;
      } else if (instructions.length == 1) {
        return instructions[0].note;
      }
    }),
    cancellationNote: computed('groupedAppointment.cancellation_notes.[]', 'pets.[]', function () {
      let notes = this.get('groupedAppointment.cancellation_notes');
      notes = notes.filter(note => {
        return note.note;
      });
      let apptCount = this.get('pets.length');

      if (apptCount > 1) {
        let uniqueNotes = {};
        notes.forEach(note => {
          if (uniqueNotes[note.note]) {
            uniqueNotes[note.note].pushObject(note.name);
          } else {
            uniqueNotes[note.note] = [note.name];
          }
        });

        if (uniqueNotes.length > 1) {
          let noteString = '';
          Object.keys(uniqueNotes).forEach(key => {
            noteString += `For ${_stringUtil.default.joinWithAnd(uniqueNotes[key])}: ${key}\n`;
          });
          return noteString;
        } else {
          return Object.keys(uniqueNotes)[0];
        }
      } else if (notes.length == 1) {
        return notes[0].note;
      }
    }),
    startsAtDate: computed('groupedAppointment.time_zone', 'groupedAppointment.service.starts_at', function () {
      return moment(this.get('groupedAppointment.service.starts_at')).tz(this.get('groupedAppointment.time_zone'));
    }),
    startsAtTime: computed('groupedAppointment.time_zone', 'groupedAppointment.service.starts_at', function () {
      return moment(this.get('groupedAppointment.service.starts_at')).tz(this.get('groupedAppointment.time_zone')).format('h:mm A');
    }),
    isNotPetParent: computed.not('isPetParent'),
    canArchiveAppointment: computed.and('isNotPetParent', 'doesNotHaveStartedAppointments'),
    actions: {
      cancelAppointment() {
        this.cancelAppointment(this.get('groupedAppointment'));
      },

      modifyAppointment() {
        this.modifyAppointment(this.get('groupedAppointment'));
      },

      archiveAppointment() {
        this.archiveAppointment(this.get('groupedAppointment'));
      },

      restoreAppointment() {
        this.restoreAppointment(this.get('groupedAppointment'));
      },

      selectAppointment() {
        this.selectAppointment(this.get('groupedAppointment'));
      },

      showReportCard() {
        this.get('groupedAppointment.appointments').toArray().some(appointment => {
          if (appointment.get('grouped_report_card')) {
            this.showReportCard(appointment.get('grouped_report_card'));
            this.set('swipedLeft', false);
            return true;
          }
        });
      },

      toggleCancellationNotes() {
        this.toggleProperty('showCancellationNoteModal');
      }

    }
  });

  _exports.default = _default;
});