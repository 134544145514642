define("frontend/components/scroll-listener", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ROOT_ELEMENT = '.page-wrap';

  var _default = Ember.Component.extend({
    enabled: true,
    classToListenTo: null,

    didInsertElement() {
      this._super(...arguments);

      if (this.get('enabled')) {
        let element = this.get('classToListenTo') ? `.${this.get('classToListenTo')}` : ROOT_ELEMENT;
        (0, _jquery.default)(element).on('scroll', () => {
          this.get('onScroll')();
        });
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('enabled')) {
        let element = this.get('classToListenTo') ? `.${this.get('classToListenTo')}` : ROOT_ELEMENT;
        (0, _jquery.default)(element).off('scroll');
      }
    }

  });

  _exports.default = _default;
});