define("frontend/models/invoice-payment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PaymentMethods = void 0;
  const {
    computed
  } = Ember;
  let PaymentMethods = {
    BANK_TRANSFER: 'bank_transfer',
    OLD_BANK_TRANSFER: 'bank-transfer',
    BANK_ACCOUNT: 'bank_account',
    CASH: 'cash',
    CHECK: 'check',
    PAYPAL: 'paypal',
    VENMO: 'venmo',
    ZELLE: 'zelle',
    OTHER: 'other'
  };
  _exports.PaymentMethods = PaymentMethods;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    amount_minus_refunds: _emberData.default.attr('number'),
    household: _emberData.default.belongsTo('household'),
    payment_date: _emberData.default.attr('string', {
      defaultValue: (0, _moment.default)().format('YYYY-MM-DD')
    }),
    payment_method: _emberData.default.attr('string'),
    payment_method_name: _emberData.default.attr('string'),
    payment_brand: _emberData.default.attr('string'),
    payment_last_four: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    formatted_description: _emberData.default.attr('string'),
    is_manual: _emberData.default.attr('boolean'),
    transaction_id: _emberData.default.attr('string'),
    pending: _emberData.default.attr('boolean'),
    failed: _emberData.default.attr('boolean'),
    is_bank_transfer: _emberData.default.attr('boolean'),
    tip_amount: _emberData.default.attr('number'),
    processing_fee: _emberData.default.attr('number'),
    service_fee: _emberData.default.attr('number'),
    net_sales: _emberData.default.attr('number'),
    net_tip: _emberData.default.attr('number'),
    avatar_url: _emberData.default.attr(),
    client_name: _emberData.default.attr(),
    pet_names: _emberData.default.attr(),
    net_amount: _emberData.default.attr(),
    household_id: _emberData.default.attr(),
    company_charges_service_fee: _emberData.default.attr('boolean'),
    company_service_fee_cap: _emberData.default.attr('number'),
    company_service_fee_split_percentage: _emberData.default.attr('number'),
    company_name: _emberData.default.attr(),
    invoice_payment_invoices: _emberData.default.attr(),
    refunds: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    amount_left: _emberData.default.attr(),
    tip_is_separate_payment: _emberData.default.attr(),
    tip_payment_brand: _emberData.default.attr(),
    tip_payment_last_four: _emberData.default.attr(),
    tip_created_at: _emberData.default.attr(),
    tip_is_bank_transfer: _emberData.default.attr(),
    tip_is_stripe_payment: _emberData.default.attr('boolean'),
    tip_service_fee: _emberData.default.attr('number'),
    whodunnit: _emberData.default.attr(),
    invoice_payment_refunds: _emberData.default.hasMany('invoice-payment-refund', {
      async: false
    }),
    paymentMethod: computed('payment_method', function () {
      let payment_method = this.get('payment_method');

      if (payment_method === PaymentMethods.CARD) {
        return this.get('payment_brand');
      } else if (payment_method === PaymentMethods.BANK_TRANSFER || payment_method === PaymentMethods.OLD_BANK_TRANSFER) {
        return 'bank transfer';
      } else if (payment_method === PaymentMethods.BANK_ACCOUNT) {
        return 'bank account';
      } else {
        return this.get('payment_method');
      }
    }),
    hasRefunds: computed.notEmpty('invoice_payment_refunds'),
    payment_amount: computed.alias('amount')
  });

  _exports.default = _default;
});