define("frontend/components/assign-time-side-panel", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['assign-staff-side-panel', 'assign-time-side-panel'],
    store: inject.service(),
    media: inject.service(),
    dateTime: null,
    careProviderId: null,
    offeredService: null,
    timeFrameStart: null,
    timeFrameEnd: null,
    setByCustomWindow: null,
    selectedPosition: null,
    appointmentsBeingEdited: [],

    didInsertElement() {
      this._super(...arguments);

      this.set('originalStartTime', this.get('selectedStartTime'));
    },

    showErrors: false,
    showSelectedStartTimeError: computed.and('showErrors', 'selectedStartTimeInvalid'),
    selectedStartTimeInvalid: computed('selectedStartTime', function () {
      let strictParsing = true;
      let format = 'h:mm A';
      return Ember.isBlank(this.get('selectedStartTime')) || !(0, _moment.default)(this.get('selectedStartTime'), format, strictParsing).isValid();
    }),
    oneTimeAppointmentGroups: computed('dateTime', 'careProviderId', function () {
      let care_provider_id = this.get('careProviderId');

      let date = _moment.default.parseZone(this.get('dateTime')).format('YYYY-MM-DD');

      let start_date = date;
      let end_date = date;
      let promise = this.get('store').query('service', {
        care_provider_id,
        start_date,
        end_date
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    shownOneTimeAppointmentGroups: computed('oneTimeAppointmentGroups', function () {
      return this.get('oneTimeAppointmentGroups').filter(service => !service.get('all_day_service'));
    }),
    selectedStartTime: computed('dateTime', function () {
      return _moment.default.parseZone(this.get('dateTime')).format('h:mm A');
    }),
    headerText: computed('dateTime', function () {
      if (this.get('media.isMobile')) {
        return _moment.default.parseZone(this.get('dateTime')).format('ddd, MMM D');
      } else {
        return _moment.default.parseZone(this.get('dateTime')).format('dddd, MMMM D');
      }
    }),
    selectedAppointmentGroup: computed('oneTimeAppointmentGroups.[]', 'selectedStartTime', 'appointmentsBeingEdited.[]', function () {
      let ags = this.get('oneTimeAppointmentGroups');
      let group = ags.filter(ag => ag.get('starts_at_time') == this.get('selectedStartTime')).get('firstObject');

      if (group) {
        if (this.get('originalStartTime') == this.get('selectedStartTime')) {
          let groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');
          let apptsBeingEditedIds = this.get('appointmentsBeingEdited').map(appt => appt.get('id'));
          let found = false;

          for (let i = 0; i < groupedAppointments.length; i++) {
            let groupedAppt = groupedAppointments.objectAt(i);

            if (groupedAppt.get('appointments').any(appt => apptsBeingEditedIds.includes(appt.get('id')))) {
              this.set('selectedPosition', i);
              found = true;
            }
          }

          if (!found) {
            this.set('selectedPosition', 0);
          }
        } else {
          this.set('selectedPosition', 0);
        }
      }

      return group;
    }),
    showPickupDropdown: computed('selectedAppointmentGroup', 'appointmentsBeingEdited.[]', function () {
      let group = this.get('selectedAppointmentGroup');
      let appts = this.get('appointmentsBeingEdited');

      if (group && appts) {
        let groupedAppts = group.get('sortedGroupedAppointmentsWithoutCanceled');

        if (groupedAppts.length > 1) {
          return true;
        }

        let apptIds = groupedAppts.get('firstObject.appointments').map(appt => appt.get('id'));
        return !apptIds.any(apptId => apptId == appts.get('firstObject.id'));
      }

      return group;
    }),
    positionOptions: computed('selectedAppointmentGroup', 'appointmentsBeingEdited.[]', function () {
      let group = this.get('selectedAppointmentGroup');

      if (!group) {
        return;
      }

      let apptsBeingEditedIds = this.get('appointmentsBeingEdited').map(appt => appt.get('id'));
      let groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');
      groupedAppointments = groupedAppointments.reject(groupedAppointment => {
        return groupedAppointment.get('appointments').any(appt => apptsBeingEditedIds.includes(appt.get('id')));
      });
      let options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        for (let i = 0; i < groupedAppointments.length - 1; i++) {
          let appt = groupedAppointments.objectAt(i);
          let nextAppt = groupedAppointments.objectAt(i + 1);
          let apptPetNames = appt.get('sortedAppointments').map(appt => appt.get('pet.name')).join('/');
          let skipOption = this.get('appointmentsBeingEdited').any(apptBeingEdited => {
            let editingAppt = appt.get('sortedAppointments').any(appt => appt.get('id') == apptBeingEdited.get('id'));
            let editingNextAppt = nextAppt.get('sortedAppointments').any(appt => appt.get('id') == apptBeingEdited.get('id'));
            return editingAppt || editingNextAppt;
          });

          if (!skipOption) {
            options.push({
              label: `After ${apptPetNames}`,
              value: i + 1
            });
          }
        }
      }

      options.push({
        label: 'Last',
        value: group.get('sortedGroupedAppointmentsWithoutCanceled').length
      });
      return options;
    }),

    pickupWindow() {
      let startTime = this.get('selectedStartTime');

      if (!startTime) {
        return {};
      }

      let serviceCategory = this.get('offeredService.serviceCategory');

      if (!serviceCategory.get('use_time_blocks')) {
        return {};
      }

      let timeFrames = serviceCategory.get('time_frames');
      let startTimeMoment = (0, _moment.default)(startTime, 'hh:mm a');
      let dayOfWeek = (0, _moment.default)(this.get('dateTime')).day();
      let potentialTimeFrames = timeFrames.filter(timeFrame => {
        let start = (0, _moment.default)(timeFrame.get('start_time'), 'hh:mm a');
        let end = (0, _moment.default)(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      let showStart = null;
      let showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        let timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = (0, _moment.default)(startTime, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = (0, _moment.default)(startTime, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        return {
          timeFrameStart: showStart,
          timeFrameEnd: showEnd
        };
      }
    },

    actions: {
      close() {
        this.close();
      },

      openCustomizeTimeWindowModal() {
        this.set('originalTimeWindowStart', this.get('timeFrameStart'));
        this.set('originalTimeWindowEnd', this.get('timeFrameEnd'));
        this.set('showTimeWindowModal', true);
      },

      closeCustomizeTimeWindowModal(shouldUpdate) {
        if (shouldUpdate) {
          this.set('setByCustomWindow', true);
        } else {
          this.set('timeFrameStart', this.get('originalTimeWindowStart'));
          this.set('timeFrameEnd', this.get('originalTimeWindowEnd'));

          if (this.get('originalStartTime')) {
            this.set('selectedStartTime', this.get('originalStartTime'));
          }
        }

        this.set('originalTimeWindowStart', null);
        this.set('originalTimeWindowEnd', null);
        this.set('showTimeWindowModal', false);
      },

      updateSelectedStartTime(time) {
        // The customize time window modal uses timepickers with a different
        // format than the one we're using in this component but when the modal
        // is inserted it somehow overrides this components timepicker's format.
        // This will check to make sure that we are always using the expected
        // format for start times
        let badFormat = 'h:mmA';
        let badMoment = (0, _moment.default)(time, badFormat, true);

        if (badMoment.isValid()) {
          time = badMoment.format('h:mm A');
        }

        this.set('selectedStartTime', time);
      },

      closeOutOfTimeWindowModal(updateTime) {
        if (updateTime) {
          this.send('openCustomizeTimeWindowModal');
          let {
            timeFrameStart,
            timeFrameEnd
          } = this.pickupWindow();
          this.set('timeFrameStart', timeFrameStart);
          this.set('timeFrameEnd', timeFrameEnd);
        } else {
          this.set('selectedStartTime', this.get('originalStartTime'));
        }

        this.set('showOutOfTimeWindowModal', false);
      },

      updateTime() {
        if (this.get('selectedStartTimeInvalid')) {
          this.set('showErrors', true);
        } else {
          let startTimeMoment = (0, _moment.default)(this.get('selectedStartTime'), 'hh:mm a');
          let timeFrameStart = (0, _moment.default)(this.get('timeFrameStart'), 'h:mmA');
          let timeFrameEnd = (0, _moment.default)(this.get('timeFrameEnd'), 'h:mmA');

          if (startTimeMoment.isBefore(timeFrameStart) || startTimeMoment.isSameOrAfter(timeFrameEnd)) {
            this.set('showOutOfTimeWindowModal', true);
          } else {
            this.update(this.get('selectedStartTime'), this.get('selectedPosition'));
          }
        }
      }

    }
  });

  _exports.default = _default;
});