define("frontend/components/dragged-out-of-time-window-modal", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    groupedAppointment: null,
    service: null,
    serviceAppointmentsOutOfWindow: [],
    whoseAppointments: computed('groupedAppointment', 'serviceAppointmentsOutOfWindow.[]', function () {
      let appointments = [];

      if (this.get('groupedAppointment')) {
        appointments = this.get('groupedAppointment.appointments');
      } else {
        appointments = this.get('serviceAppointmentsOutOfWindow');
      }

      if (appointments.get('length') == 1) {
        return `${appointments.get('firstObject.pet.name')}'s appointment`;
      } else {
        return `the appointments for ${_stringUtil.default.joinWithAnd(appointments.map(appt => appt.get('pet.name')))}`;
      }
    }),
    actions: {
      denyUpdate() {
        this.get('denyUpdate')();
      },

      confirmUpdate() {
        this.get('confirmUpdate')();
      }

    }
  });

  _exports.default = _default;
});