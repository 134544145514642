define("frontend/components/calendar-list-week", ["exports", "frontend/components/calendar-list", "frontend/utils/calendar-util", "moment"], function (_exports, _calendarList, _calendarUtil, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _calendarList.default.extend({
    careProvider: null,
    canDrag: computed.readOnly('currentUser.user.canAddEditAppointments'),
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    timeBoxes: computed('services.@each.starts_at', 'daysOfWeek', 'date', function () {
      let daysOfWeek = this.get('daysOfWeek');

      _calendarUtil.default.initHeaders();

      return daysOfWeek.map(momentDay => {
        let isToday = _moment.default.tz(this.get('company.time_zone_mapping')).isSame(momentDay, 'day');

        let isWeekend = momentDay.day() === 0 || momentDay.day() === 6;
        return {
          isWeekend,
          isToday,
          day: momentDay.format(),
          timeBoxes: this.timeBoxesForDay(momentDay)
        };
      });
    }),
    daysOfWeek: computed('date', 'company.weekType', 'company.time_zone_mapping', function () {
      let weekType = this.get('company.weekType');
      let timeZone = this.get('company.time_zone_mapping');

      if (!timeZone || !weekType) {
        return [];
      }

      let startOfWeek = (0, _moment.default)(this.get('date')).tz(timeZone).startOf(weekType);
      let endOfWeek = (0, _moment.default)(this.get('date')).tz(timeZone).endOf(weekType);
      let daysOfWeek = [];

      while (startOfWeek.isBefore(endOfWeek)) {
        daysOfWeek.push(startOfWeek.clone());
        startOfWeek.add(1, 'day');
      }

      this.scrollToFirstService();
      return daysOfWeek;
    }),
    timesInDay: computed(function () {
      // IMPORTANT this is a non DST switch day so hours don't repeat
      // TODO: Move to util
      let FIXED_DAY = '2017-01-01';
      let startTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      let endTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      let times = [];

      while (startTime < endTime) {
        times.push({
          hour: startTime.format('h A'),
          hidden: false,
          faded: false
        });
        startTime.add(1, 'hour');
      }

      return times;
    }),

    servicesWithAppointments(services) {
      return services.filter(service => {
        if (this.get('onCancelationSchedule')) {
          return service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    },

    servicesInDay(services, day) {
      return services.filter(service => {
        return !service.get('all_day_service') && parseInt(_moment.default.parseZone(service.get('starts_at')).get('day')) === parseInt(day);
      });
    },

    allDayServicesInDay(services, day) {
      return services.filter(service => {
        return service.get('all_day_service') && parseInt(_moment.default.parseZone(service.get('starts_at')).get('day')) === parseInt(day);
      });
    },

    servicesAtHour(services, hour) {
      return services.filter(service => {
        return parseInt(_moment.default.parseZone(service.get('starts_at')).get('hour')) === parseInt(hour);
      });
    },

    servicesInFifteenMinuteRange(services, startMinute) {
      return services.filter(service => {
        let serviceMinute = parseInt(_moment.default.parseZone(service.get('starts_at')).get('minute'));
        let roundedMinute = serviceMinute + 7;

        if (roundedMinute >= 60) {
          roundedMinute = 59;
        }

        return roundedMinute >= startMinute && roundedMinute < parseInt(startMinute) + 15;
      });
    },

    timeBoxesForDay(momentDay) {
      let FIXED_DAY = '2017-01-01';
      let startOfDayTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      let endOfDayTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      let incrementingTime = startOfDayTime.clone();
      let timeBoxes = [];
      let servicesWithAppointments = this.servicesWithAppointments(this.get('services'));
      let servicesInDay = this.servicesInDay(servicesWithAppointments, momentDay.get('day'));
      let allDayServicesInDay = this.allDayServicesInDay(servicesWithAppointments, momentDay.get('day'));
      timeBoxes.push({
        services: allDayServicesInDay
      });

      while (incrementingTime < endOfDayTime) {
        let servicesAtHour = this.servicesAtHour(servicesInDay, incrementingTime.get('hour'));
        let zeroMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 0);
        let fifteenMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 15);
        let thirtyMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 30);
        let fourtyFiveMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 45);
        let hour = incrementingTime.get('hour');
        timeBoxes.push({
          hour,
          zeroMinuteServices,
          fifteenMinuteServices,
          thirtyMinuteServices,
          fourtyFiveMinuteServices
        });
        incrementingTime.add(1, 'hour');
      }

      return timeBoxes;
    }

  });

  _exports.default = _default;
});