define("frontend/routes/edit-client/pet", ["exports", "frontend/routes/base-route", "jquery", "frontend/config/environment"], function (_exports, _baseRoute, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    flashMessage: inject.service(),
    currentUser: inject.service(),
    isNotPetParent: computed.not('currentUser.user.isPetParent'),

    model(params) {
      return this.store.findRecord('pet', params.pet_id);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('currentSection', 'basic');

      if (this.get('isNotPetParent')) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_check/future_appointments_with_another_care_provider?pet_id=${controller.model.id}`,
          method: 'GET'
        }).done(response => {
          const {
            futureAppointmentsWithAnotherCareProvider
          } = response;

          if (futureAppointmentsWithAnotherCareProvider !== undefined && futureAppointmentsWithAnotherCareProvider === false) {
            controller.set("canRemove", true);
          } else {
            controller.set("canRemove", false);
          }
        }).fail(() => {
          this.get('flashMessage').sendFlashMessage("We were unable to load some client information. Please refresh the page.", 'error');
        });
      } else {
        controller.set("canRemove", true);
      }
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName == 'client-list.household.pet') {
          this.get('controller.model.household.pets').forEach(pet => {
            pet.get('vaccinations').forEach(vaccination => {
              // sometimes this is undefined and it is unknown why. we are adding this extra check here to make
              // sure it exists to fix a potential JS error
              if (vaccination && vaccination.get('hasDirtyAttributes')) {
                vaccination.rollbackAttributes();
              }
            });
            pet.get('emergency_contacts').forEach(contact => {
              if (contact && contact.get('hasDirtyAttributes')) {
                contact.rollbackAttributes();
              }
            });
            pet.get('pet_prescriptions').forEach(prescription => {
              if (prescription && prescription.get('hasDirtyAttributes')) {
                prescription.rollbackAttributes();
              }
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});