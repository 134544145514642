define("frontend/components/client-bill-delivery-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['add-with-side-panel', 'bill-delivery-options'],
    classNameBindings: ['noBillRecipients:empty'],
    flashMessage: inject.service(),
    household: null,
    householdEmails: [],
    noBillRecipients: computed.equal('householdEmails.length', 0),
    actions: {
      addEmail(email) {
        this.get('householdEmails').pushObject(email);
        this.set('showingBillDeliveryModal', false);
        this.get('save')('add', email.get('address'));
      },

      removeEmail(email) {
        if (this.get('householdEmails.length') == 1) {
          this.get('flashMessage').sendFlashMessage('Sorry! You must have at least 1 email set to receive notifications for your bills!', 'user-error');
          return;
        }

        this.get('householdEmails').removeObject(email);
        this.get('save')('remove', email.address);
      }

    }
  });

  _exports.default = _default;
});