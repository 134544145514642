define("frontend/routes/add-appointment/times", ["exports", "frontend/mixins/add-appointment", "frontend/mixins/transition-back-route", "frontend/config/environment", "frontend/utils/scroll-util", "frontend/routes/base-route", "moment", "jquery"], function (_exports, _addAppointment, _transitionBackRoute, _environment, _scrollUtil, _baseRoute, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, _addAppointment.default, {
    saving: computed.alias('addAppointmentData.saving'),
    errorHandler: inject.service(),

    redirect() {
      this._super(...arguments);

      if (this.get('addAppointmentData.selectedPetValues.length') === 0) {
        this.transitionTo('add-appointment.index', {
          queryParams: {
            selectedHouseholdId: null,
            filter: ''
          }
        });
      }
    },

    model() {
      let care_providers = this.store.findAll('care-provider', {
        reload: true
      }).then(cps => {
        return cps.sortBy('getFirstName', 'last_name');
      });
      let household = null;

      if (this.get('addAppointmentData.selectedPetValues.length') > 0) {
        household = this.store.findRecord('household', this.get('addAppointmentData.selectedPetValues.firstObject.household.id'));
      }

      return Ember.RSVP.hash({
        household,
        care_providers,
        offered_services: this.store.query('offered-service', {
          filter: {
            active: true
          }
        }, {
          reload: true
        }),
        service_categories: this.store.query('service-category', {}, {
          reload: true
        })
      });
    },

    saveSingleAppointments() {
      let pet_ids = this.get('addAppointmentData.selectedPetValues').mapBy('id');
      let dateAppointments = this.get('addAppointmentData.dateAppointments');
      let frequency = 'onetime';
      let promises = [];
      let url = `${_environment.default.APP.host}/api/v1/appointment_schedulings`;
      let appointments = [];
      dateAppointments.forEach(dateAppointment => {
        let {
          careProvider,
          offered_service,
          date,
          time,
          special_instructions,
          timeFrameStart,
          timeFrameEnd,
          setByCustomWindow,
          positionsPreviousPets,
          chargeForLateBooking,
          addOnServices
        } = dateAppointment;
        let care_provider_id = careProvider.get('id');
        let offered_service_id = offered_service.get('id');
        let add_on_services = addOnServices.map(addOnService => {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        let appointment = {
          pet_ids,
          care_provider_id,
          frequency,
          offered_service_id,
          date,
          positionsPreviousPets,
          time,
          time_frame_start_time: timeFrameStart,
          time_frame_end_time: timeFrameEnd,
          set_by_custom_window: setByCustomWindow,
          special_instructions,
          charge_for_late_booking: chargeForLateBooking,
          add_on_services
        };
        appointments.pushObject(appointment);
      });
      promises.push(_jquery.default.ajax({
        url,
        method: 'POST',
        data: {
          appointments
        }
      }));
      return Ember.RSVP.all(promises).then(this.postSaveSingleAppointments.bind(this));
    },

    postSaveSingleAppointments() {
      return new Ember.RSVP.Promise(resolve => {
        let firstAppointment = this.get('addAppointmentData.dateAppointments').sortBy('start_date').get('firstObject');
        let date = firstAppointment.get('date');
        let careProviderId = firstAppointment.get('careProvider.id');
        resolve({
          careProviderId,
          date
        });
      });
    },

    saveRecurringAppointments() {
      let recurringAppointmentGroup = this.store.createRecord('recurring-appointment-group');
      let dateAppointments = this.get('addAppointmentData.dateAppointments');
      let pets = this.get('addAppointmentData.selectedPetValues');
      dateAppointments.forEach(dateAppointment => {
        let day_of_week = (0, _moment.default)(dateAppointment.date, 'YYYY-MM-DD').day();
        let start_date = null;

        if (this.get('repeatsMonthly')) {
          start_date = this.firstOfMonthFromDate(this.get('addAppointmentData.startDate'));
        } else {
          start_date = this.firstWeekdayFromDate(day_of_week, this.get('addAppointmentData.startDate'));
        }

        let end_date = this.get('addAppointmentData.selectedEndDateOption') === 'never' ? 'never' : this.get('addAppointmentData.endDate');
        let frequency = this.get('repeatsBiweekly') ? 'biweekly' : this.get('repeatsMonthly') ? 'monthly' : 'weekly';
        let positions_previous_pets = dateAppointment.positionsPreviousPets;
        let add_on_services = dateAppointment.addOnServices.map(addOnService => {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        pets.forEach(pet => {
          let add_on_service_ids = add_on_services.filter(addOnService => addOnService.pet_ids.includes(pet.id)).map(addOnService => addOnService.offered_service_id);
          let recurringAppointment = this.store.createRecord('recurring-appointment', {
            start_date,
            careProvider: dateAppointment.careProvider,
            offered_service: dateAppointment.offered_service,
            preferred_start_time: dateAppointment.time,
            time_frame_start_time: dateAppointment.timeFrameStart,
            time_frame_end_time: dateAppointment.timeFrameEnd,
            set_by_custom_window: dateAppointment.setByCustomWindow,
            recurrence_ends_on_type: this.get('addAppointmentData.selectedEndDateOption'),
            end_date,
            frequency,
            day_of_week,
            positions_previous_pets,
            pet,
            add_on_service_ids
          });
          recurringAppointmentGroup.get('recurring_appointments').pushObject(recurringAppointment);
        });
      });
      return recurringAppointmentGroup.save().then(this.postSaveRecurringAppointments.bind(this));
    },

    postSaveRecurringAppointments(response) {
      return new Ember.RSVP.Promise(resolve => {
        let firstRecurringAppointment = response.get('recurring_appointments').sortBy('start_date').get('firstObject');
        let date = firstRecurringAppointment.get('start_date');
        let careProviderId = firstRecurringAppointment.get('careProvider.id');
        response.get('recurring_appointments').filterBy('id', null).forEach(r => r.deleteRecord());
        resolve({
          date,
          careProviderId
        });
      });
    },

    firstWeekdayFromDate(dayOfWeek, date) {
      let startDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      let momentDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      let daysDiff = dayOfWeek - momentDate.day();

      if (daysDiff < 0 && !this.get('repeatsBiweekly')) {
        daysDiff += 7;
      }

      let weekDay = momentDate.add(daysDiff, 'days');
      let SUNDAY = 0;

      if (weekDay.isBefore(startDate.startOf('day')) && this.get('repeatsBiweekly')) {
        if (dayOfWeek === SUNDAY) {
          weekDay.add(1, 'week');
        } else {
          weekDay.add(2, 'weeks');
        }
      }

      return weekDay.format('YYYY-MM-DD');
    },

    firstOfMonthFromDate(date) {
      let startDate = (0, _moment.default)(date, 'YYYY-MM-DD');

      if (startDate.date() == '1') {
        return date;
      } else {
        return startDate.add(1, 'month').startOf('month').format('YYYY-MM-DD');
      }
    },

    save(savePromiseFn) {
      if (this.get('saving')) {
        return;
      }

      this.set('saving', true);
      return savePromiseFn();
    },

    setupController(controller) {
      this._super(...arguments);

      this.get('addAppointmentData').set('dateAppointments', []);
      controller.set('activeAppointment', null);
      controller.set('editingExistingAppointment', false);
      controller.set('firstAppointmentCreated', false);
      controller.set('sidePanelAppointment', null);
      controller.set('showErrors', false);
      controller.set('saving', false);
      controller.set('showRequestOrApproveModal', false);
    },

    validate(controller) {
      controller.set('showErrors', true);

      if (controller.get('missingAppointments')) {
        _scrollUtil.default.scrollTopWithHeader('.assign-staff-date--error');

        return false;
      }

      return true;
    },

    requestAppointment() {
      let autoApprove = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let isValid = this.validate(this.get('controller'));

      if (!isValid || this.get('saving')) {
        return;
      }

      this.set('saving', true);
      let pet_ids = this.get('addAppointmentData.selectedPetValues').mapBy('id');
      let dateAppointments = this.get('addAppointmentData.dateAppointments');
      let details = {
        auto_approve: autoApprove,
        special_instructions: '',
        appointments: []
      };
      dateAppointments.forEach(dateAppointment => {
        let {
          careProvider,
          offered_service,
          date,
          time,
          timeFrameStart,
          timeFrameEnd,
          special_instructions,
          setByCustomWindow,
          positionsPreviousPets,
          chargeForLateBooking,
          addOnServices
        } = dateAppointment;
        let care_provider_id = careProvider.get('id');
        let offered_service_id = offered_service.get('id');
        let time_frame = {
          time,
          start_time: timeFrameStart,
          end_time: timeFrameEnd
        };
        let add_on_services = addOnServices.map(addOnService => {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        details['special_instructions'] = special_instructions;
        details['appointments'].push({
          pet_ids,
          offered_service_id,
          care_provider_id,
          date,
          time_frame,
          previous_pets: positionsPreviousPets,
          set_by_custom_window: setByCustomWindow,
          charge_for_late_booking: chargeForLateBooking,
          add_on_services
        });
      });
      let addAppointmentController = Ember.getOwner(this).lookup('controller:add-appointment');
      let backRoute = addAppointmentController.get('backRoute');
      let backHouseholdId = addAppointmentController.get('backHouseholdId');
      let backId = addAppointmentController.get('backId');

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/appointment_requests`,
        method: 'POST',
        data: {
          details
        }
      }).then(() => {
        this.set('saving', false);
        this.transitionBack(backRoute, backHouseholdId, backId);
      }).catch(e => {
        if (e.message != 'TransitionAborted') {
          this.get('errorHandler').handle(e);
          this.set('saving', false);
        }
      });
    },

    actions: {
      finish() {
        let isValid = this.validate(this.get('controller'));

        if (!isValid) {
          return;
        }

        let saveFn = this.get('repeatsNever') ? this.saveSingleAppointments : this.saveRecurringAppointments;
        let addAppointmentController = Ember.getOwner(this).lookup('controller:add-appointment');
        let backRoute = addAppointmentController.get('backRoute');
        let backHouseholdId = addAppointmentController.get('backHouseholdId');
        let backId = addAppointmentController.get('backId');
        this.save(saveFn.bind(this)).then(() => this.transitionBack(backRoute, backHouseholdId, backId)).catch(e => {
          if (e.message != 'TransitionAborted') {
            this.get('errorHandler').handle(e);
            this.set('saving', false);
          }
        });
      },

      requestAndApprove() {
        this.requestAppointment(true);
      },

      request() {
        this.requestAppointment(false);
      },

      back() {
        this.transitionTo('add-appointment.details');
      }

    }
  });

  _exports.default = _default;
});