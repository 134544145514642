define("frontend/components/flash-message-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    yesText: 'Yes',
    noText: 'No',
    color: 'yellow',
    actions: {
      yes() {
        this.yes();
      },

      no() {
        this.no();
      }

    }
  });

  _exports.default = _default;
});