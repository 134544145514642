define("frontend/helpers/fancy-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fancyDate = fancyDate;

  function fancyDate(_ref) {
    let [date, format] = _ref;
    let today = (0, _moment.default)().clone();
    date = (0, _moment.default)(date, 'YYYY-MM-DD');

    if (date.diff(today.startOf('day'), 'days') === 0) {
      return 'today';
    } else if (date.diff(today.startOf('day'), 'days') <= 1 && date.diff((0, _moment.default)().startOf('day'), 'days') > 0) {
      return 'tomorrow';
    } else if (date.diff(today.startOf('day'), 'days') >= -1 && date.diff((0, _moment.default)().startOf('day'), 'days') < 0) {
      return 'yesterday';
    } else if (date.isSame((0, _moment.default)(), 'week')) {
      return `on ${date.format('dddd')}`;
    }

    return `on ${date.format(format)}`;
  }

  var _default = Ember.Helper.helper(fancyDate);

  _exports.default = _default;
});