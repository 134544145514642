define("frontend/components/bill-reminders", ["exports", "frontend/utils/payment-reminder-options"], function (_exports, _paymentReminderOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['bill-reminders'],
    companyInvoiceFrequencies: null,
    anySidePanelActive: false,
    editingBillReminders: false,
    frequencyName: computed('companyInvoiceFrequencies.@each.payment_reminder_frequency', function () {
      let firstFrequency = this.get('companyInvoiceFrequencies.firstObject.payment_reminder_frequency');
      let allSame = this.get('companyInvoiceFrequencies').every(invoiceFrequency => {
        return invoiceFrequency.get('payment_reminder_frequency') === firstFrequency;
      });

      if (allSame) {
        return _paymentReminderOptions.default.lookupByValue(firstFrequency).label;
      } else {
        return 'Custom';
      }
    }),
    actions: {
      closeSidePanel() {
        this.closeSidePanel();
      },

      openSidePanel() {
        if (this.get('anySidePanelActive')) {
          return;
        }

        this.openSidePanel();
      }

    }
  });

  _exports.default = _default;
});