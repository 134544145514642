define("frontend/routes/add-staff", ["exports", "frontend/mixins/cleanup-on-transition", "frontend/utils/email-type", "frontend/routes/base-route", "moment"], function (_exports, _cleanupOnTransition, _emailType, _baseRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    model(params) {
      let {
        searchedEmail
      } = params;
      let offered_service_amounts = [];
      let user = this.store.createRecord('user');
      let start_date = (0, _moment.default)().format('YYYY-MM-DD');

      if (searchedEmail) {
        let defaultEmail = this.store.createRecord('email', {
          address: searchedEmail,
          email_type: _emailType.default.PREFERRED
        });
        user.get('emails').pushObject(defaultEmail);
      }

      return this.store.createRecord('employee', {
        offered_service_amounts,
        user,
        start_date
      });
    },

    actions: {
      transitionToStaff() {
        let employeeFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';
        this.transitionTo('staff.index', {
          queryParams: {
            employeeFilter
          }
        });
      },

      redirectToAddedUser(_, staffId) {
        this.store.findAll('employee').then(employees => {
          employees.forEach(employee => {
            if (employee.id == staffId) {
              this.transitionTo('edit-staff', employee);
            }
          });
        });
      },

      redirectToUser(_, userId) {
        this.store.findAll('employee').then(employees => {
          employees.forEach(employee => {
            if (employee.get('user').get('id') == userId) {
              this.transitionTo('staff.index', {
                queryParams: {
                  employeeFilter: userId
                }
              });
              return;
            }
          });
        });
      },

      redirectToSearch(email) {
        this.transitionTo('staff-search', {
          queryParams: {
            email
          }
        });
      }

    }
  });

  _exports.default = _default;
});