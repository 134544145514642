define("frontend/models/service-category", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    ajax: inject.service(),
    name: _emberData.default.attr(),
    shortened_name: _emberData.default.attr(),
    offered_services: _emberData.default.hasMany('offered-service', {
      async: false
    }),
    time_frames: _emberData.default.hasMany('time-frame', {
      async: true
    }),
    weekend_surcharge_policy: _emberData.default.belongsTo('weekend-surcharge-policy', {
      async: false
    }),
    off_hours_surcharge_policy: _emberData.default.belongsTo('off-hours-surcharge-policy', {
      async: false
    }),
    late_booking_surcharge_policy: _emberData.default.belongsTo('late-booking-surcharge-policy', {
      async: false
    }),
    holiday_surcharge_policy: _emberData.default.belongsTo('holiday-surcharge-policy', {
      async: false
    }),
    request_cut_off_policy: _emberData.default.belongsTo('request-cut-off-policy', {
      async: false
    }),
    expanded: _emberData.default.attr(),
    time_window_in_minutes: _emberData.default.attr(),
    use_time_blocks: _emberData.default.attr('boolean'),
    hours_setup: _emberData.default.attr('boolean'),
    show_report_card_on_completion: _emberData.default.attr('boolean'),
    show_check_in_check_out_appointment: _emberData.default.attr('boolean'),
    supports_report_cards: _emberData.default.attr('boolean'),
    supports_gps: _emberData.default.attr('boolean'),
    supports_check_in_out: _emberData.default.attr('boolean'),
    should_show_report_card_buttons: _emberData.default.attr('boolean'),
    enable_gps_on_check_in: _emberData.default.attr('boolean'),
    cancellation_fee_policies: _emberData.default.hasMany('cancellation-fee-policy', {
      async: true
    }),
    activeCancellationFeePolicies: computed.filterBy('cancellation_fee_policies', 'isDeleted', false),
    checkInAlertTimeInMinutes: _emberData.default.attr('number'),
    checkOutAlertTimeInMinutes: _emberData.default.attr('number'),
    hasCancellationFeePolicies: computed.notEmpty('activeCancellationFeePolicies'),
    hasWeekendPolicies: computed.notEmpty('weekend_surcharge_policy'),
    hasOffHoursPolicies: computed.notEmpty('off_hours_surcharge_policy'),
    hasLateBookingPolicies: computed.notEmpty('late_booking_surcharge_policy'),
    hasHolidayPolicies: computed.notEmpty('holiday_surcharge_policy'),
    hasCutOffPolicies: computed.notEmpty('request_cut_off_policy'),
    hasAddOnServices: computed('offered_services.@each.{is_add_on}', function () {
      return this.get('offered_services').any(service => service.get('is_add_on'));
    }),
    addOnOfferedServices: computed('offered_services.@each.{is_add_on}', function () {
      return this.get('offered_services').filter(service => service.get('is_add_on'));
    }),
    numberedWeekdays: computed('time_frames.[]', 'time_frames.@each.dayOfWeekDisplay', function () {
      let timeFrames = this.get('time_frames'); // eslint-disable-next-line no-undef

      let dows = new Set();
      timeFrames.forEach(timeFrame => {
        // Trigger for intermediary computed property
        timeFrame.get('dayOfWeekDisplay');
        timeFrame.get('days_of_week').forEach(dow => {
          dows.add(dow);
        });
      });
      return dows;
    }),
    weekdays: computed('numberedWeekdays.[]', function () {
      let dows = this.get('numberedWeekdays');

      if (dows.size == 7) {
        return 'Daily (M-Su)';
      } else if (dows.size === 5 && Array.from(dows).sort().every((value, index) => value === [1, 2, 3, 4, 5][index])) {
        return 'Weekdays (M-F)';
      } else {
        let dayMap = [['M', 1], ['T', 2], ['W', 3], ['Th', 4], ['F', 5], ['Sa', 6], ['Su', 0]];
        let selectedDays = [];
        dayMap.forEach(day => {
          if (dows.has(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    }),

    updateReportCard() {
      return this.get('ajax').request(`/api/v2/service_categories/${this.get('id')}/update_report_card`, {
        method: 'PUT',
        data: {
          show_report_card_on_completion: this.get('show_report_card_on_completion')
        }
      });
    },

    updateCheckInCheckOut() {
      return this.get('ajax').request(`/api/v2/service_categories/${this.get('id')}/update_check_in_check_out`, {
        method: 'PUT',
        data: {
          show_check_in_check_out_appointment: this.get('show_check_in_check_out_appointment')
        }
      });
    },

    updateGps() {
      return this.get('ajax').request(`/api/v2/service_categories/${this.get('id')}/update_gps`, {
        method: 'PUT',
        data: {
          enable_gps_on_check_in: this.get('enable_gps_on_check_in')
        }
      });
    },

    updateCheckInAlertTiming() {
      return this.get('ajax').request(`/api/v2/service_categories/${this.get('id')}/update_check_in_alert_timing`, {
        method: 'PUT',
        data: {
          check_in_alert_timing: this.get('checkInAlertTimeInMinutes')
        }
      });
    },

    updateCheckOutAlertTiming() {
      return this.get('ajax').request(`/api/v2/service_categories/${this.get('id')}/update_check_out_alert_timing`, {
        method: 'PUT',
        data: {
          check_out_alert_timing: this.get('checkOutAlertTimeInMinutes')
        }
      });
    }

  });

  _exports.default = _default;
});