define("frontend/routes/account-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Route.extend({
    accountSession: inject.service(),
    currentUser: inject.service(),

    model() {
      this._super(...arguments);

      let user = this.get('currentUser.user');

      if (!this.get('accountSession').hasCompany()) {
        let companies = user.get('staff_companies');

        if (companies.length == 0) {
          this.get('accountSession').switchAccounts(0);
          return {
            loading: true
          };
        } else if (companies.length == 1) {
          this.get('accountSession').switchAccounts(companies.get('firstObject.id'));
          return {
            loading: true
          };
        }
      }
    }

  });

  _exports.default = _default;
});