define("frontend/components/mobile-select", ["exports", "frontend/components/mobile-text-input"], function (_exports, _mobileTextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _mobileTextInput.default.extend({
    classNames: ['mobile-text-input', 'mobile-select'],
    classNameBindings: ['disabled'],
    options: [],
    value: null,
    passPreviousSelected: false,
    passFullOptionBack: false,
    setValueOnChange: true,
    defaultChooseText: computed('label', function () {
      return `Choose ${this.get('label').toLowerCase()}`;
    }),
    selectedLabel: computed('value', 'options.[]', function () {
      let selectedOption = {};
      this.get('options').forEach(opt => {
        if (opt.groupName === undefined) {
          if (opt.value == this.get('value')) {
            selectedOption = opt;
          }
        } else {
          let innerFound = opt.options.find(innerOpt => innerOpt.value == this.get('value'));

          if (innerFound) {
            selectedOption = innerFound;
          }
        }
      });
      return selectedOption.label;
    }),
    actions: {
      openSelect() {
        if (!this.get('disabled')) {
          if (this.openSelect) {
            this.openSelect();
          } else {
            this.set('selectOpen', true);
          }
        }
      },

      clickOption(option) {
        let previousSelection = this.get('value');
        this.set('selectOpen', false);

        if (this.get('setValueOnChange')) {
          this.set('value', option.value);
        }

        let valueToPassBack = this.get('passFullOptionBack') ? option : option.value;

        if (this.selectOption) {
          if (this.get('passPreviousSelected')) {
            this.selectOption(valueToPassBack, previousSelection);
          } else {
            this.selectOption(valueToPassBack);
          }
        }
      }

    }
  });

  _exports.default = _default;
});