define("frontend/components/pet-item", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const PET_PLACEHOLDER_PREFIX = 'assets/images/placeholders';

  var _default = Ember.Component.extend({
    classNames: ['pet-item'],
    classNameBindings: ['completed', 'draggedOver:dragged-over', 'draggable', 'appointmentStartedButNotFinished:no-drag-cancel', 'appointmentStartedButNotFinished:no-drag-archive', 'showProgressBarSmall:progress-bar-small'],
    attributeBindings: ['data-appointment-id', 'data-height'],
    'data-appointment-id': computed.readOnly('appointment.id'),
    'data-height': computed.readOnly('appointment.offered_service.duration'),
    appointment: null,
    completed: false,
    colorHex: null,
    draggedOver: false,
    position: null,
    droppable: false,
    appointmentStarted: computed.notEmpty('appointment.started_at'),
    appointmentNotFinished: computed.empty('appointment.finished_at'),
    appointmentStartedButNotFinished: computed.and('appointmentStarted', 'appointmentNotFinished'),
    showProgressBar: false,
    showProgressBarSmall: false,
    normalProgressBarWidthHeight: 52,
    smallProgressBarWidthHeight: 40,
    normalProgressBarRadius: 24,
    smallProgressBarRadius: 18,
    normalProgressBarCircumference: 26,
    smallProgressBarCircumference: 20,
    showAnyProgressBar: computed.or('showProgressBar', 'showProgressBarSmall'),
    progressBarWidthHeight: computed('showProgressBar', 'showProgressBarSmall', function () {
      if (this.get('showProgressBarSmall')) {
        return this.get('smallProgressBarWidthHeight');
      } else {
        return this.get('normalProgressBarWidthHeight');
      }
    }),
    progressBarRadius: computed('showProgressBar', 'showProgressBarSmall', function () {
      if (this.get('showProgressBarSmall')) {
        return this.get('smallProgressBarRadius');
      } else {
        return this.get('normalProgressBarRadius');
      }
    }),
    progressBarCircumference: computed('showProgressBar', 'showProgressBarSmall', function () {
      if (this.get('showProgressBarSmall')) {
        return this.get('smallProgressBarCircumference');
      } else {
        return this.get('normalProgressBarCircumference');
      }
    }),
    showIncompleteReportCardIcon: computed('completed', 'appointment.grouped_report_card.draft', function () {
      const appointmentUsesReportCards = this.get('appointment.show_report_card_on_completion');
      const appointmentIsComplete = this.get("completed");
      const reportCardIsDraft = this.get('appointment.grouped_report_card.draft');
      return appointmentUsesReportCards && appointmentIsComplete && reportCardIsDraft;
    }),

    didInsertElement() {
      if (this.get('appointmentStartedButNotFinished') && this.get('showAnyProgressBar')) {
        let start = (0, _moment.default)(this.get('appointment.started_at'));
        let diff = (0, _moment.default)().diff(start, 'minutes'); // duration can be zero - don't allow start/finish on 0 duration appointments
        // how does this work for all day appointments? - ????

        let duration = this.get('appointment.offered_service.duration_in_minutes');

        if (duration > 0) {
          let percentageComplete = diff / duration;

          if (percentageComplete > 1) {
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', 0);
          } else {
            let width = this.get('showProgressBarSmall') ? 101 : 150;
            let pixelsCompleted = percentageComplete * width;
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', width - pixelsCompleted);
          }
        }
      }
    },

    click() {
      if (this.get('clickAppointment')) {
        this.get('clickAppointment')(this.get('appointment'));
      }
    },

    petAvatar: computed('appointment.pet.avatar_url', function () {
      let avatar_url = this.get('appointment.pet.avatar_url');

      if (avatar_url) {
        return avatar_url;
      } else {
        return `${PET_PLACEHOLDER_PREFIX}/${this.get('appointment.pet.pet_type')}.svg`;
      }
    }),
    isBirthday: computed('appointment.pet.birthday', 'appointment.service.starts_at', function () {
      let birthday = this.get('appointment.pet.birthday');
      let date = this.get('appointment.service.starts_at');

      if (birthday && date) {
        return (0, _moment.default)(birthday).format('MM-DD') == (0, _moment.default)(date).format('MM-DD');
      }
    }),
    actions: {
      draggedOver() {
        this.set('draggedOver', true);
      },

      draggedOut() {
        this.set('draggedOver', false);
      },

      dropItem(petItem) {
        let position = this.get('position');

        if (this.dropItem) {
          this.dropItem(petItem, position);
        }
      }

    }
  });

  _exports.default = _default;
});