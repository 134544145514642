define("frontend/components/client-list/pet-output/medical-output", ["exports", "frontend/components/client-list/pet-output"], function (_exports, _petOutput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _petOutput.default.extend({
    classNames: ['medical-output'],
    emptyVet: computed.not('vet.id'),
    emptyInsurance: computed.not('insurance.id'),
    active: false,
    hasVet: computed.not('emptyVet'),
    hasInsurance: computed.not('emptyInsurance'),
    hasVaccinations: computed.gt('vaccinations.length', 0),
    hasPrescriptions: computed.gt('petPrescriptions.length', 0),
    hasEmergencyContacts: computed.gt('emergencyContacts.length', 0),
    actions: {
      clickAddress(address) {
        let encodedAddress = encodeURIComponent(address);
        window.location.href = `https://maps.google.com?q=${encodedAddress}`;
      },

      callPhoneNumber(phone) {
        window.location.href = `tel:${phone}`;
      },

      textPhoneNumber(phone) {
        window.location.href = `sms:${phone}`;
      },

      clickEmail(email) {
        window.location.href = `mailto:${email}`;
      }

    }
  });

  _exports.default = _default;
});