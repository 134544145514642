define("frontend/controllers/remove-recurring-appointment-group", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    recurringAppointments: computed.readOnly('model.recurring_appointments'),
    saving: false,
    pet: computed.readOnly('recurringAppointments.firstObject.pet'),
    queryParams: ['backRoute', 'backId', 'backHouseholdId'],
    backHouseholdId: null,
    backRoute: null,
    backId: null,
    recurringAppointmentPets: computed.map('recurringAppointments', function (recurringAppointment) {
      return recurringAppointment.get('pet');
    }),
    uniquePets: computed('recurringAppointmentPets.[]', function () {
      let pets = {};
      this.get('recurringAppointmentPets').forEach(pet => {
        pets[pet.get('id')] = pet;
      });

      let values = _jquery.default.map(pets, val => val);

      return values;
    }),
    uniquePetNames: computed('uniquePets.[]', function () {
      return this.get('uniquePets').mapBy('name').uniq();
    }),
    uniqueDays: computed('recurringAppointments.[]', function () {
      let days = this.get('recurringAppointments').map(recurringAppointment => {
        return (0, _moment.default)().day(recurringAppointment.get('day_of_week')).format('dddd');
      }).uniq();
      days.sort((a, b) => (0, _moment.default)(a, 'dddd').isoWeekday() > (0, _moment.default)(b, 'dddd').isoWeekday());
      return days;
    })
  });

  _exports.default = _default;
});