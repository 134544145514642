define("frontend/helpers/has-services-for-care-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasServicesForCareProvider = hasServicesForCareProvider;

  function hasServicesForCareProvider(_ref) {
    let [careProvider, services] = _ref;
    return services.any(service => service.get('care_provider.id') == careProvider.id);
  }

  var _default = Ember.Helper.helper(hasServicesForCareProvider);

  _exports.default = _default;
});