define("frontend/controllers/client-list/household/appointments", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REPEATING_TYPE = 'repeating';
  const SCHEDULE_TYPE = 'schedule';
  const CANCELLATION_TYPE = 'canceled';
  const PREFERENCES_TYPE = 'preferences';
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    media: inject.service(),
    removingAppointment: null,
    removingRecurringAppointmentGroup: null,
    queryParams: ['appointmentType'],
    appointmentType: SCHEDULE_TYPE,
    onRepeating: computed.equal('appointmentType', REPEATING_TYPE),
    onSchedule: computed.equal('appointmentType', SCHEDULE_TYPE),
    onCancellations: computed.equal('appointmentType', CANCELLATION_TYPE),
    onPreferences: computed.equal('appointmentType', PREFERENCES_TYPE),
    owner: computed.readOnly('model.household.owner'),
    showPetNames: computed.gt('model.household.pets.length', 1),
    notMobile: computed.not('media.isMobile'),
    readOnly: computed.not('currentUser.user.canAddEditAppointments'),
    currentUser: Ember.inject.service(),
    selectedGroupedAppointments: [],
    groupedAppointmentsToCancel: [],
    showPets: false,
    showAddresses: false,
    bulkEditing: false,
    hasSelectedGroupedAppointments: computed.notEmpty('selectedGroupedAppointments'),
    showBulkEditingMenu: computed.and('bulkEditing', 'hasSelectedGroupedAppointments'),
    selectedReportCard: null,
    currentGroupedAppointmentToCancel: computed('groupedAppointmentsToCancel.[]', function () {
      return this.get('groupedAppointmentsToCancel.firstObject');
    }),

    removeNewRecords() {
      this.store.peekAll('address').filter(a => a.get('id') == undefined).forEach(a => a.deleteRecord());
      this.store.peekAll('pet').filter(p => p.get('id') == undefined).forEach(p => p.deleteRecord());
    },

    reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(a => a.reload());
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(p => p.reload());
    },

    actions: {
      denyRemoveAppointment() {
        this.set('removingAppointment', null);
      },

      denyRemoveMultipleAppointments() {
        this.set('removingMultipleAppointments', false);
      },

      denyCancelMultipleAppointments() {
        this.set('cancelingMultipleAppointments', false);
      },

      clickBulkRemove(method) {
        if (this.get('hasSelectedGroupedAppointments')) {
          if (method == 'archive') {
            this.set('removingMultipleAppointments', true);
          } else {
            this.set('cancelingMultipleAppointments', true);
          }
        } else {
          this.set('showNoAppointmentsSelectedModal', true);
        }
      },

      clickSchedule() {
        this.set('appointmentType', SCHEDULE_TYPE);
      },

      clickRepeating() {
        this.set('appointmentType', REPEATING_TYPE);
      },

      clickCancellations() {
        this.set('appointmentType', CANCELLATION_TYPE);
      },

      clickPreferences() {
        this.set('appointmentType', PREFERENCES_TYPE);
      },

      showModifyApptModal(groupedAppointment) {
        if (groupedAppointment.get('appointments.length') === 1) {
          let ids = [groupedAppointment.get('appointments.firstObject.id')];
          this.send('modifyAppointment', ids);
        } else {
          this.set('selectedGroup', groupedAppointment);
          this.set('showModifyApptModal', true);
        }
      },

      closeModifyAppointmentModal() {
        this.set('showModifyApptModal', false);
        this.set('selectedGroup', null);
      },

      modifyAppointment(appointmentIds) {
        this.send('transitionToEditSingleAppointment', appointmentIds);
        this.set('showModifyApptModal', false);
        this.set('selectedGroup', null);
      },

      confirmRemoveAppointment() {
        if (this.get('destroying')) {
          return;
        }

        this.set('destroying', true);
        this.get('removingAppointment').destroyRecord({
          adapterOptions: {
            method: 'remove button in upcoming appointments list'
          }
        }).finally(() => {
          this.set('removingAppointment', null);
          this.set('destroying', false);
        });
      },

      confirmRemoveMultipleAppointments() {
        this.set('removingMultipleAppointments', false);
        let ids = this.get('selectedGroupedAppointments').map(groupedAppt => {
          return groupedAppt.get('appointments').map(appt => {
            return appt.get('id');
          });
        });
        ids = ids.flat();

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v1/appointments/delete_multiple`,
          method: 'DELETE',
          data: {
            appointment_ids: ids
          }
        }).done(() => {
          window.location.reload();
        });
      },

      confirmCancelMultipleAppointments(cancellationNote) {
        let appointment_ids = this.get('selectedGroupedAppointments').map(groupedAppt => {
          return groupedAppt.get('appointments').map(appt => {
            return appt.get('id');
          });
        });
        appointment_ids = appointment_ids.flat();

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/cancel_multiple_grouped_appointments`,
          method: 'POST',
          data: {
            appointment_ids,
            cancellation_note: cancellationNote
          }
        }).then(response => {
          let apptsWithFees = response.appointments_with_cancellation_fees;

          if (apptsWithFees.length == 0) {
            window.location.reload();
            return;
          }

          let groupedAppointments = this.get('selectedGroupedAppointments');
          let groupedAppointmentsWithFees = groupedAppointments.filter(groupedAppt => {
            let apptIds = groupedAppt.get('appointments').map(appt => appt.get('id'));
            let found = false;
            apptsWithFees.forEach(apptId => {
              found = found || apptIds.includes(apptId.toString());
            });
            return found;
          });
          this.set('groupedAppointmentsToCancel', groupedAppointmentsWithFees);
          this.set('bulkCancellationNote', cancellationNote);
          this.get('groupedAppointmentList').openCancelAppointmentModal(groupedAppointmentsWithFees.get('firstObject'), this.get('bulkCancellationNote'));
          this.set('cancelingMultipleAppointments', false);
        });
      },

      removeAppointment(appointment) {
        if (this.get('removingRecurringAppointmentGroup') === null && this.get('removingAppointment') === null) {
          this.set('removingAppointment', appointment);
        }
      },

      selectAppointment(groupedAppointment) {
        let selectedAppointments = this.get('selectedGroupedAppointments');
        let found = false;
        selectedAppointments.forEach(selectedAppt => {
          if (selectedAppt.get('id') == groupedAppointment.get('id')) {
            selectedAppointments.removeObject(groupedAppointment);
            found = true;
          }
        });

        if (!found) {
          selectedAppointments.pushObject(groupedAppointment);
        }
      },

      clickAddAppointment() {
        let household = this.get('model.household');
        let numPets = household.get('pets.length');
        let numAddresses = household.get('owner.addresses.length');

        if (numPets == 0 || numAddresses == 0) {
          if (numPets == 0) {
            this.send('addPet');
            this.set('showPets', true);
          }

          if (numAddresses == 0) {
            let state = this.get('currentUser.user.company.state');
            household.get('owner.addresses').pushObject(this.store.createRecord('address', {
              state
            }));
            this.set('showAddresses', true);
          }

          this.set('showAppointmentModal', true);
        } else {
          this.send('transitionToAddAppointment', household);
        }
      },

      addPet() {
        let household = this.get('model.household');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.get('id')}/get_companies`,
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(response => {
          let pet = this.store.createRecord('pet');
          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },

      closeAppointmentInfoModal() {
        this.removeNewRecords.call(this);
        this.set('showAppointmentModal', false);
        this.set('loading', false);
      },

      saveHousehold() {
        let userPromise = this.get('model.household.owner').save();
        let petPromises = this.get('model.household.pets').map(pet => pet.save());
        let promises = [userPromise, ...petPromises];
        return Ember.RSVP.Promise.all(promises).then(() => {
          this.removeNewRecords.call(this);
          this.reloadDirtyRecords.call(this);
          this.send('transitionToAddAppointment', this.get('model.household'));
        });
      },

      nextGroupedAppointmentToCancel() {
        let groupedAppointmentsToCancel = this.get('groupedAppointmentsToCancel');

        if (groupedAppointmentsToCancel.length == 0) {
          return;
        }

        groupedAppointmentsToCancel.removeAt(0, 1);
        this.set('groupedAppointmentsToCancel', groupedAppointmentsToCancel);

        if (groupedAppointmentsToCancel.length > 0) {
          this.get('groupedAppointmentList').openCancelAppointmentModal(groupedAppointmentsToCancel.get('firstObject'), this.get('bulkCancellationNote'));
        } else {
          window.location.reload();
        }
      },

      setGroupedAppointmentListChild(childReference) {
        this.set('groupedAppointmentList', childReference);
      },

      cancelAppointmentWithFee() {
        this.send('nextGroupedAppointmentToCancel');
      },

      clickBulkEditing() {
        this.toggleProperty('bulkEditing');
      },

      closeNoAppointmentsSelectedModal() {
        this.set('showNoAppointmentsSelectedModal', false);
      },

      showReportCard(groupedReportCard) {
        this.set('selectedReportCard', groupedReportCard);
      },

      closeReportCard() {
        this.set('selectedReportCard', null);
      }

    }
  });

  _exports.default = _default;
});