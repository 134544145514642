define("frontend/helpers/format-rolling-date-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatRollingDateTime = formatRollingDateTime;

  function formatRollingDateTime(_ref) {
    let [datetime, dateFormat, timeFormat, timeZone = null] = _ref;
    let today = (0, _moment.default)().startOf('day');

    if (timeZone) {
      today = (0, _moment.default)().tz(timeZone).startOf('day');
    }

    let date = _moment.default.parseZone(datetime).startOf('day');

    let dateFormatted = _moment.default.parseZone(datetime).clone().format(dateFormat);

    let timeFormatted = _moment.default.parseZone(datetime).clone().format(timeFormat);

    if (date.diff(today, 'days') === 0) {
      return `today at ${timeFormatted}`;
    } else if (date.diff(today, 'days') === 1) {
      return `tomorrow at ${timeFormatted}`;
    } else if (date.diff(today, 'days') === -1) {
      return `yesterday at ${timeFormatted}`;
    }

    return `${dateFormatted} at ${timeFormatted}`;
  }

  var _default = Ember.Helper.helper(formatRollingDateTime);

  _exports.default = _default;
});