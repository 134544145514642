define("frontend/components/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-input'],
    classNameBindings: ['style'],
    searchButton: false,
    debounce: false,
    debounceTime: 1000,
    actions: {
      search() {
        if (this.search) {
          this.search(this.get('value'));
        }
      },

      onInput(e) {
        let {
          target
        } = e;
        Ember.run.debounce(() => {
          this.set('value', target.value);
        }, this.get('debounceTime'));
      }

    }
  });

  _exports.default = _default;
});