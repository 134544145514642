define("frontend/components/add-payment-sidepanel", ["exports", "ember-cp-validations", "frontend/models/invoice-payment"], function (_exports, _emberCpValidations, _invoicePayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'payment.amount': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0
    })],
    'payment.payment_method': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'payment.tip_amount': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      allowBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    paymentMethodOptions: [{
      label: 'Bank transfer',
      value: _invoicePayment.PaymentMethods.BANK_TRANSFER
    }, {
      label: 'Cash',
      value: _invoicePayment.PaymentMethods.CASH
    }, {
      label: 'Check',
      value: _invoicePayment.PaymentMethods.CHECK
    }, {
      label: 'PayPal',
      value: _invoicePayment.PaymentMethods.PAYPAL
    }, {
      label: 'Venmo',
      value: _invoicePayment.PaymentMethods.VENMO
    }, {
      label: 'Zelle',
      value: _invoicePayment.PaymentMethods.ZELLE
    }, {
      label: 'Other',
      value: _invoicePayment.PaymentMethods.OTHER
    }],
    store: inject.service(),
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    showDatepickerSidePanel: false,
    isInvalid: computed.not('validations.isValid'),
    payment: computed(function () {
      return this.get('store').createRecord('invoice-payment');
    }),
    actions: {
      close() {
        this.get('payment').rollbackAttributes();
        this.get('close')();
      },

      changePaidOnDate(momentNewDate) {
        let newDate = momentNewDate.format('YYYY-MM-DD');
        this.set('showDatepickerSidePanel', false);
        this.set('payment.payment_date', newDate);
      },

      clickDone() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.get('save')(this.get('payment'));
        }
      }

    }
  });

  _exports.default = _default;
});