define("frontend/components/client-list/pet-output/medical-mobile-action-bar-with-multiples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['medical-mobile-action-bar'],
    emails: [],
    phoneNumbers: [],

    populateContactInfo(contactAction, contactPrompt, models, modelMarshaller) {
      this.set('showContactPickerModal', true);
      this.set('contactAction', contactAction);
      this.set('contactPrompt', contactPrompt);
      let contacts = [];
      models.forEach(model => {
        contacts.push(modelMarshaller(model));
      });
      this.set('contactPickerInfo', contacts);
    },

    actions: {
      clickEmailPrompt() {
        let emails = this.get('emails');
        let emailsCount = emails.get('length');

        if (emailsCount === 1) {
          this.get('clickEmail')(emails.get('firstObject.address'));
        } else if (emailsCount > 1) {
          this.populateContactInfo('clickEmail', 'Which email would you like to reach?', emails, model => {
            let type = model.get('email_type');
            let address = model.get('address');
            return {
              type,
              address,
              model
            };
          });
        }
      },

      clickPhoneNumberPrompt() {
        let phones = this.get('phoneNumbers');
        let phonesCount = phones.get('length');
        let mobilePhones = phones.filter(phone => phone.get('isMobile'));

        if (phonesCount > 1 || mobilePhones.get('length') > 0) {
          this.set('showPhonePickerModal', true);
        } else {
          this.get('callPhoneNumber')(phones.get('firstObject.number'));
        }
      },

      clickEmail() {
        this.get('clickEmail')(...arguments);
      },

      callPhoneNumber() {
        this.get('callPhoneNumber')(...arguments);
      },

      textPhoneNumber() {
        this.get('textPhoneNumber')(...arguments);
      },

      clickAddress() {
        this.get('clickAddress')(...arguments);
      },

      onClose() {
        this.set('showContactPickerModal', false);
        this.set('contactPrompt', null);
      },

      closePhonePickerModal() {
        this.set('showPhonePickerModal', false);
      }

    }
  });

  _exports.default = _default;
});