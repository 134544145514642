define("frontend/controllers/add-client/billing-settings", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    session: inject.service(),
    accountSession: inject.service(),
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    companyBillingSettings: computed('isPetParent', 'model', function () {
      if (this.get('isPetParent')) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/get_client_companies`,
          method: 'GET'
        }).then(response => {
          if (response.settings.length == 1) {
            this.set('companyBillingSettings', response.settings.get('firstObject'));
          } else {
            this.transitionToRoute('portal.billing');
          }
        });
      }
    }),
    rightButtonText: computed('isPetParent', function () {
      if (this.get('isPetParent')) {
        return 'Finish';
      } else {
        return 'Next';
      }
    }),
    actions: {
      goNext(user) {
        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(savedUser => {
          if (this.get('isPetParent')) {
            return this.transitionToRoute('portal.profile', {
              queryParams: {
                addedClient: this.get('companyBillingSettings.accepted')
              }
            });
          } else {
            return this.transitionToRoute('add-client.documents', savedUser.get('household.id'));
          }
        }).finally(() => {
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});