define("frontend/components/view-report-card/photos", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    media: inject.service(),
    selectedPhoto: null,
    selectedMapThumbnail: false,
    selectedPhotoUrl: computed('selectedPhoto', 'gpsMapUrl', 'selectedMapThumbnail', function () {
      if (this.get('selectedPhoto') && !this.get('selectedMapThumbnail')) {
        return this.get('selectedPhoto.url');
      } else {
        return this.get('gpsMapUrl');
      }
    }),
    mediaIsNotMobile: computed.not('media.isMobile'),
    hasPhotos: computed.notEmpty('reportCard.reportCardImages'),
    hasAdditionalPhotos: computed.notEmpty('reportCard.reportCardImagesWithoutCoverPhoto'),
    showMobilePhotoCarouselAction: computed.and('media.isMobile', 'hasPhotos'),
    showDesktopPhotoCarouselAction: computed.and('mediaIsNotMobile', 'hasAdditionalPhotos'),
    inflectedPhotoText: computed('reportCard.reportCardImages.length', function () {
      let length = this.get('reportCard.reportCardImages.length');

      if (length === 0) {
        return '';
      } else if (length === 1) {
        return 'See photo';
      } else {
        return `See ${length} photos`;
      }
    }),
    showSavePhotoButton: computed.and('mediaIsNotMobile', 'selectedPhoto'),
    notShowingGpsMap: computed('selectedPhotoUrl', 'gpsMapUrl', function () {
      return this.get('selectedPhotoUrl') !== this.get('gpsMapUrl');
    }),
    gpsMapUrl: null,
    previousPhotoIconUrl: 'assets/images/report-card/scroll-left@3x.png',
    nextPhotoIconUrl: 'assets/images/report-card/scroll-right@3x.png',

    didInsertElement() {
      this._super(...arguments);

      this.set('selectedPhoto', this.get('reportCard.coverPhoto'));

      if (this.get('media.isMobile')) {
        let deviceRatio = window.devicePixelRatio || 1;
        let width = screen.width * deviceRatio;
        let height = 280 * deviceRatio;
        let id = this.get('appointment.id');
        let url = `${_environment.default.APP.host}/api/v2/appointments/${id}/check_in_gps_map?width=${width}&height=${height}`;

        _jquery.default.ajax({
          url,
          method: 'GET'
        }).then(response => {
          this.set('gpsMapUrl', response.url);
        });
      } else {
        this.set('gpsMapUrl', this.get('appointment.gps_markers_report_card_view_desktop_url'));
      }
    },

    previousButtonHoverObserver: observer('hasPreviousPhoto', function () {
      if (!this.get('hasPreviousPhoto')) {
        this.set('previousPhotoIconUrl', 'assets/images/report-card/scroll-left@3x.png');
      }
    }),
    nextPhotoButtonHoverObserver: observer('hasNextPhoto', function () {
      if (!this.get('hasNextPhoto')) {
        this.set('nextPhotoIconUrl', 'assets/images/report-card/scroll-right@3x.png');
      }
    }),
    hasPreviousPhoto: computed('selectedPhoto.id', 'reportCard.coverPhoto.id', 'reportCard.reportCardImagesWithoutCoverPhoto.[]', 'selectedMapThumbnail', function () {
      let selectedId = this.get('selectedPhoto.id');
      let coverPhotoId = this.get('reportCard.coverPhoto.id');
      let reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
      let currentPhotoIndex = reportCardImages.findIndex(rci => rci.get('id') === selectedId);

      if (this.get('selectedMapThumbnail') || selectedId === coverPhotoId) {
        return false;
      } else if (currentPhotoIndex === 0) {
        // deal with cover photo
        return true;
      } else {
        let previousPhotoIndex = currentPhotoIndex - 1;
        return typeof reportCardImages[previousPhotoIndex] !== 'undefined';
      }
    }),
    hasNextPhoto: computed('selectedPhoto.id', 'reportCard.coverPhoto.id', 'reportCard.reportCardImagesWithoutCoverPhoto.[]', 'selectedMapThumbnail', function () {
      let selectedId = this.get('selectedPhoto.id');
      let coverPhotoId = this.get('reportCard.coverPhoto.id');

      if (this.get('selectedMapThumbnail')) {
        return false;
      } else if (selectedId === coverPhotoId) {
        return true;
      } else {
        let reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        let currentPhotoIndex = reportCardImages.findIndex(rci => rci.get('id') === selectedId);
        let nextPhotoIndex = currentPhotoIndex + 1;
        return typeof reportCardImages[nextPhotoIndex] !== 'undefined';
      }
    }),
    actions: {
      showMobilePhotoCarousel() {
        if (this.showMobilePhotoCarousel) {
          this.showMobilePhotoCarousel();
        }
      },

      hoverPreviousPhoto(hovering) {
        if (hovering) {
          this.set('previousPhotoIconUrl', 'assets/images/scroll-left-hover@3x.png');
        } else {
          this.set('previousPhotoIconUrl', 'assets/images/report-card/scroll-left@3x.png');
        }
      },

      hoverNextPhoto(hovering) {
        if (hovering) {
          this.set('nextPhotoIconUrl', 'assets/images/scroll-right-hover@3x.png');
        } else {
          this.set('nextPhotoIconUrl', 'assets/images/report-card/scroll-right@3x.png');
        }
      },

      goToPreviousPhoto() {
        let selectedId = this.get('selectedPhoto.id');
        let reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        let currentPhotoIndex = reportCardImages.findIndex(rci => rci.get('id') === selectedId);
        let previousPhotoIndex = currentPhotoIndex - 1;

        if (previousPhotoIndex === -1) {
          this.set('selectedPhoto', this.get('reportCard.coverPhoto'));
        } else {
          if (typeof reportCardImages[previousPhotoIndex] !== 'undefined') {
            this.set('selectedPhoto', reportCardImages[previousPhotoIndex]);
          }
        }
      },

      goToNextPhoto() {
        let selectedId = this.get('selectedPhoto.id');
        let reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        let currentPhotoIndex = reportCardImages.findIndex(rci => rci.get('id') === selectedId);

        if (currentPhotoIndex === -1) {
          // dealing with the cover photo
          this.set('selectedPhoto', reportCardImages.get('firstObject'));
        } else {
          let nextPhotoIndex = currentPhotoIndex + 1;

          if (typeof reportCardImages[nextPhotoIndex] !== 'undefined') {
            this.set('selectedPhoto', reportCardImages[nextPhotoIndex]);
          }
        }
      },

      clickMapThumbnail() {
        this.set('selectedMapThumbnail', true);
      },

      clickCoverPhotoThumbnail() {
        this.set('selectedMapThumbnail', false);
        this.set('selectedPhoto', this.get('reportCard.coverPhoto'));
      }

    }
  });

  _exports.default = _default;
});