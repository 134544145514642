define("frontend/utils/appointment-searcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    numVisitsInServices(petNameSearchValue, services) {
      let countCanceled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return services.reduce((allAppointmentsCount, service) => {
        let appointmentsCount = this.numVisitsWithPetNameService(petNameSearchValue, service, countCanceled);
        return allAppointmentsCount += appointmentsCount;
      }, 0);
    },

    numVisitsWithPetNameService(petNameSearchValue, service, countCanceled) {
      return service.get('grouped_appointments').reduce((appointmentsCount, groupedAppointment) => {
        let includesPet = false;
        groupedAppointment.get('appointments').forEach(appt => {
          let petName = appt.get('pet.name');
          let petNamePart = petName.slice(0, petNameSearchValue.length);

          if (countCanceled && !appt.get('canceled_at') || !countCanceled && appt.get('canceled_at')) {
            return appointmentsCount;
          }

          if (petNamePart.toLowerCase().indexOf(petNameSearchValue.toLowerCase()) >= 0) {
            includesPet = true;
          }
        });
        return includesPet ? appointmentsCount + 1 : appointmentsCount;
      }, 0);
    },

    numPetsInServices(petNameSearchValue, services) {
      let countCanceled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let apptIds = [];
      services.forEach(service => {
        service.get('appointments').forEach(appt => {
          if (countCanceled && appt.get('canceled_at') || !countCanceled && !appt.get('canceled_at')) {
            apptIds.push(appt.get('id'));
          }
        });
      });
      return apptIds.length;
    }

  };
  _exports.default = _default;
});