define("frontend/components/date-range-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    editingDate: computed.or('editingStartDate', 'editingEndDate'),
    startDateInvalid: computed('startDate', function () {
      return !moment(this.get('startDate'), 'MM/DD/YYYY', true).isValid();
    }),
    endDateInvalid: computed('endDate', function () {
      return !moment(this.get('endDate'), 'MM/DD/YYYY', true).isValid();
    }),
    actions: {
      onSubmit() {
        this.get('onSubmit')(this.get('startDate'), this.get('endDate'));
      },

      changeDate(date) {
        if (this.get('editingStartDate')) {
          this.set('startDate', moment(date, 'YYYY-MM-DD'));
          this.set('editingStartDate', false);
        } else {
          this.set('endDate', moment(date, 'YYYY-MM-DD'));
          this.set('editingEndDate', false);
        }
      },

      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});