define("frontend/components/header-date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['header-date-picker'],
    momentDate: computed('date', function () {
      return (0, _moment.default)(this.get('date'), 'YYYY-MM-DD');
    }),
    dayOfWeek: computed('momentDate', function () {
      if (this.get('momentDate').isSame((0, _moment.default)(), 'day')) {
        return 'Today';
      }

      return this.get('momentDate').format('ddd');
    }),
    month: computed('momentDate', function () {
      return this.get('momentDate').format('MMM');
    }),
    day: computed('momentDate', function () {
      return this.get('momentDate').format('D');
    }),
    year: computed('momentDate', function () {
      return this.get('momentDate').format('YYYY');
    }),
    actions: {
      previous() {
        this.previous();
      },

      next() {
        this.next();
      }

    }
  });

  _exports.default = _default;
});