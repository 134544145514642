define("frontend/components/message-thread/new-message-household-select/distribution-list-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      allClientFamiliesClick() {
        if (Ember.isEqual(this.get('selectedDistributionList'), 'allHouseholds')) {
          this.set('selectedDistributionList', '');
          this.get('deselectAllHouseholds')();
        } else {
          this.set('selectedDistributionList', 'allHouseholds');
          this.get('selectAllHouseholds')();
          this.set('specificDatesSelected', false);
        }
      },

      familiesWithAppointmentsClick() {
        this.get('deselectAllHouseholds')();

        if (Ember.isEqual(this.get('selectedDistributionList'), 'selectedAppointments')) {
          this.set('selectedDistributionList', '');
        } else {
          this.set('selectedDistributionList', 'selectedAppointments');
          this.get('openSelectingHouseholdsModal')();
        }
      },

      changeButtonClick() {
        this.get('changeSelectingHouseholdsModal')();
        this.set('selectedDistributionList', 'selectedAppointments');
      }

    }
  });

  _exports.default = _default;
});