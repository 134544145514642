define("frontend/controllers/admin/import-clients", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Controller.extend(_highlightFirstMatch.default, {
    flashMessage: inject.service(),
    selectedCompany: null,
    errorTitle: null,
    errorMessages: [],
    showSuccessModal: false,
    actions: {
      uploadDocument(doc, fileName) {
        if (!this.get('selectedCompany')) {
          return;
        }

        this.set('uploading', true);
        Ember.$.ajax({
          url: `/api/v2/companies/${this.get('selectedCompany.id')}/import_clients`,
          method: 'POST',
          data: {
            document: doc,
            filename: fileName
          }
        }).then(() => {
          this.set('showSuccessModal', true);
        }).fail(response => {
          if (response.status == 422) {
            this.set('errorTitle', response.responseJSON.message);
            this.set('errorMessages', response.responseJSON.errors);
            this.set('uploading', false);
          } else {
            this.get('flashMessage').error('Unknown error: Please let engineering know');
          }
        });
      },

      closeErrorModal() {
        this.set('errorTitle', null);
        this.set('errorMessages', []);
      },

      closeSuccessModal() {
        window.location.reload();
        this.set('showSuccessModal', false);
      }

    }
  });

  _exports.default = _default;
});