define("frontend/controllers/admin/veterinarian-clinics", ["exports", "ember-data", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _emberData, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  const escapeRegExp = input => {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'veterinarian-clinic',
    modelOptions: {
      all: true
    },
    store: inject.service(),
    vetGroupOptions: computed(function () {
      let promise = this.get('store').query('vet-group', {
        all: true
      }, {
        reload: true
      }).then(groups => {
        let mappedClients = groups.map(vetGroup => {
          return {
            vetGroup
          };
        });
        mappedClients.pushObject({
          name: 'Create a new group?'
        });
        return mappedClients;
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    selectedReplace: computed('savedReplace', function () {
      let vet = this.get('savedReplace');

      if (vet) {
        return `${vet.name} (${vet.id})`;
      }
    }),

    debouncedSearchVets(_ref) {
      let {
        vetName
      } = _ref;
      let vets = this.get('store').query('veterinarian_clinic', {
        filter: vetName
      }, {
        reload: true
      });
      this.set('approvedVets', vets);
    },

    actions: {
      submitReplace(replace) {
        let url = `/api/v2/veterinarian_clinics/${this.get('editingReplace.id')}/replace`;
        this.submitReplace(url, replace, this.get('editingReplace'));
      },

      saveVet(vet) {
        this.send('save', vet);
        this.set('editingVet', null);
        this.set('editingVetGroup', null);
      },

      rollbackVet(vet) {
        vet.rollbackAttributes();
        this.set('editingVet', null);
      },

      showRemove(vet) {
        this.set('selectedVetForRemoval', vet);
      },

      confirmRemove() {
        this.send('remove', this.get('selectedVetForRemoval'));
        this.set('selectedVetForRemoval', null);
      },

      denyRemove() {
        this.set('selectedVetForRemoval', null);
      },

      showEditingVetGroupModal(vet) {
        this.set('selectedVet', vet);
        this.set('editingVetGroup', true);
      },

      closeEditingVetGroupModal() {
        this.set('selectedVet', null);
        this.set('editingVetGroup', false);
      },

      closeNewVetGroupModal() {
        this.get('creatingVetGroup').rollbackAttributes();
        this.set('creatingVetGroup', null);
      },

      createVetGroup(name) {
        let editingVetGroup = this.get('store').createRecord('vet-group', {
          name
        });
        this.notifyPropertyChange('vetGroupOptions');
        this.set('creatingVetGroup', editingVetGroup);
      },

      uploadedVetGroupPhoto(photo) {
        this.set('creatingVetGroup.logo_url', photo);
      },

      changeVetGroup(option) {
        if (option == null) {
          this.set('selectedVet.vet_group', null);
        } else if (option.vetGroup) {
          this.set('selectedVet.vet_group', option.vetGroup);
        } else {
          this.send('createVetGroup', this.get('vetTerm'));
        }
      },

      searchVetGroups(term) {
        let nameRegExp = new RegExp(escapeRegExp(`${term}`), 'i', 'g');
        let response = this.get('vetGroupOptions.content').filter(c => !c.vetGroup || nameRegExp.exec(c.vetGroup.get('name')));
        this.set('vetTerm', term);
        return response || [];
      },

      saveVetGroup() {
        this.get('creatingVetGroup').save().then(vetGroup => {
          this.set('selectedVet.vet_group', vetGroup);
          this.set('creatingVetGroup', null);
          this.notifyPropertyChange('vetGroupOptions');
        });
      },

      searchVets(vetName) {
        Ember.run.debounce(this, this.debouncedSearchVets, {
          vetName
        }, 500);
      }

    }
  });

  _exports.default = _default;
});