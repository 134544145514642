define("frontend/components/late-booking-alert-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    policy: null,
    actions: {
      waiveFee() {
        this.get('onClose')(false);
      },

      chargeFee() {
        this.get('onClose')(true);
      }

    }
  });

  _exports.default = _default;
});