define("frontend/controllers/admin/email-look-up", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    petParentVerificationModal: false,
    searchEmail: '',
    searchFirstName: '',
    searchLastName: '',
    selectedPetParentEmail: null,
    session: inject.service(),
    currentUser: inject.service(),
    accountSession: inject.service(),
    userInfos: null,
    foundUsers: null,
    actions: {
      closeDeletePetParentVerificationModal() {
        this.set('petParentVerificationModal', false);
        this.set('selectedPetParentEmail', null);
      },

      deletePetParent() {
        const email = this.get('selectedPetParentEmail');

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/admin/delete_pet_parent`, {
          email
        }).then(() => {
          window.location.reload();
        }).catch(() => {
          alert(`Something went wrong deleting ${email}`);
        });
      },

      impersonate(user_email) {
        let store = this.get('session.store');

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/impersonate_session`, {
          user: {
            user_email
          }
        }).then(response => {
          store.clear().then(() => {
            store.persist(response).then(() => {
              this.get('accountSession').clearCompany();
              window.location.href = '/';
            });
          });
        });
      },

      search() {
        let email = this.get('searchEmail');
        let first = this.get('searchFirstName');
        let last = this.get('searchLastName');

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/users/user_search`, {
          email,
          first,
          last
        }).then(response => {
          this.set('foundUsers', response.found);
          this.set('userInfos', response.user_infos);
        });
      },

      triggerDeletePetParentVerification(user_email) {
        this.set('petParentVerificationModal', true);
        this.set('selectedPetParentEmail', user_email);
      },

      clickedIssueRefund(user_email) {
        this.set('showPaymentRefundModal', true);
        this.set('selectedPetParentEmail', user_email);
      },

      closeRefundModal() {
        this.set('showPaymentRefundModal', false);
        this.set('selectedPetParentEmail', null);
      }

    }
  });

  _exports.default = _default;
});