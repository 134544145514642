define("frontend/controllers/edit-client/add-pet", ["exports", "frontend/controllers/edit-client/edit-client-page-base"], function (_exports, _editClientPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _editClientPageBase.default.extend({
    valid: computed.readOnly('model.validations.isValid'),
    saveDisabled: computed.not('valid'),
    application: inject.controller(),
    queryParams: ['petIndex'],
    avatarPlaceholder: computed('model', 'model.pet_type', function () {
      let pet_type = this.get('model.pet_type');

      if (!pet_type) {
        pet_type = 'Dog';
      }

      return `assets/images/placeholders/${pet_type}.svg`;
    }),
    actions: {
      uploadedPhoto(photo) {
        this.set('model.avatar_url', photo);
      }

    }
  });

  _exports.default = _default;
});