define("frontend/routes/add-appointment", ["exports", "frontend/mixins/add-appointment", "frontend/mixins/transition-back-route", "frontend/routes/base-route"], function (_exports, _addAppointment, _transitionBackRoute, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, _addAppointment.default, {
    setupController(controller) {
      this._super(...arguments);

      controller.set('redirecting', false);

      if (controller.get('preselectDate')) {
        controller.set('selectedOneTimeDates', [controller.get('date')]);
      }
    },

    deactivate() {
      this._super(...arguments);

      this.get('addAppointmentData').setup();
    },

    actions: {
      cancel() {
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId);
      }

    }
  });

  _exports.default = _default;
});