define("frontend/mixins/holiday-names-from-dates", ["exports", "frontend/mixins/dates-to-ranges"], function (_exports, _datesToRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_datesToRanges.default, {
    holidayDatesService: Ember.inject.service(),

    createHolidayNameMapping(country, stateOrProvince) {
      let holidayNameMapping = {};
      this.get('holidayDatesService').getHolidays(country, stateOrProvince).forEach(holiday => {
        let middleDate = moment(holiday.date);
        holidayNameMapping[middleDate.format('YYYY-MM-DD')] = holiday.label;

        for (let i = 1; i <= 3; i++) {
          holidayNameMapping[middleDate.clone().add(i, 'day').format('YYYY-MM-DD')] = holiday.label;
          holidayNameMapping[middleDate.clone().subtract(i, 'day').format('YYYY-MM-DD')] = holiday.label;
        }
      });
      return holidayNameMapping;
    },

    holidayNamesFromDates(dateArray, country, stateOrProvince) {
      let holidayNameMapping = this.createHolidayNameMapping(country, stateOrProvince);
      let sortedDates = dateArray.sort().map(date => moment(date));
      let holidayNames = sortedDates.map(date => {
        return holidayNameMapping[date.format('YYYY-MM-DD')];
      });
      return holidayNames.uniq();
    },

    holidayDatesFromSelectedDates(dateArray, holidayPolicy, country, stateOrProvince) {
      let holidayNameMapping = this.createHolidayNameMapping(country, stateOrProvince);
      let holidayDateMapping = {};
      holidayPolicy.get('dates').forEach(date => {
        let holiday = holidayNameMapping[date];

        if (!holidayDateMapping[holiday]) {
          holidayDateMapping[holiday] = [];
        }

        holidayDateMapping[holiday].push(date);
      });
      let holidayNames = this.holidayNamesFromDates(dateArray, country, stateOrProvince);
      return holidayNames.map(holidayName => {
        return this.datesToRanges(holidayDateMapping[holidayName], 'dddd, MMMM D', 'through');
      });
    }

  });

  _exports.default = _default;
});