define("frontend/transforms/capitalized-string", ["exports", "frontend/transforms/trimmed-string"], function (_exports, _trimmedString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trimmedString.default.extend({
    deserialize(serialized) {
      serialized = this._super(...arguments);
      return Ember.isNone(serialized) ? null : serialized.capitalize();
    },

    serialize(deserialized) {
      deserialized = this._super(...arguments);
      return Ember.isNone(deserialized) ? null : deserialized.capitalize();
    }

  });

  _exports.default = _default;
});