define("frontend/routes/invoices", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      }
    },
    infinity: inject.service(),

    beforeModel() {
      this._super(...arguments);

      if (this.get('controller')) {
        this.set('controller.loadingRightSidePanel', true);
      }
    },

    model(params) {
      return this.get('infinity').model('household', {
        perPage: 10,
        startingPage: 1,
        filter: params.filter,
        include_account_balance: true
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('loadingRightSidePanel', false);
    },

    actions: {
      loading() {
        return !this.get('controller');
      },

      selectHousehold(household) {
        this.transitionTo('invoices-summary', household.id);
      },

      selectHouseholdById(householdId) {
        this.transitionTo('invoices-summary', householdId);
      },

      clickInvoice(invoice) {
        this.transitionTo('invoices-summary.invoice-detail', invoice.get('household.id'), invoice.id);
      },

      clickPayment(payment) {
        this.transitionTo('invoices-summary.payment-detail', payment.get('household_id'), payment.id);
      },

      parentInfinityLoad() {
        this._infinityLoad();
      }

    }
  });

  _exports.default = _default;
});