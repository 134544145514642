define("frontend/services/check-duplicate-appointment-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    // Look through all appointments on a date for a pet id at this time
    isDuplicate(appointment, time) {
      let services = this.get('store').peekAll('service').filterBy('starts_at', time);
      return services.any(service => {
        return service.get('appointments').any(otherAppointment => {
          return otherAppointment.get('pet.id') === appointment.get('pet.id') && otherAppointment.get('id') !== appointment.get('id');
        });
      });
    },

    isGroupedAppointmentDuplicate(groupedAppointment, time) {
      let services = this.get('store').peekAll('service').filterBy('starts_at', time);
      return services.any(service => {
        return service.get('appointments').any(otherAppointment => {
          return groupedAppointment.get('appointments').any(appointment => {
            return !otherAppointment.get('canceled_at') && otherAppointment.get('pet.id') === appointment.get('pet.id') && otherAppointment.get('id') !== appointment.get('id');
          });
        });
      });
    }

  });

  _exports.default = _default;
});