define("frontend/routes/companies/remove-service", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      this._super(...arguments);

      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/offered_services/${params.offeredServiceId}/has_appointments`,
        method: 'GET'
      });
    }

  });

  _exports.default = _default;
});