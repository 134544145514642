define("frontend/routes/index", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    accountSession: Ember.inject.service(),

    redirect() {
      this._super(...arguments);

      if (!this.get('session.isAuthenticated')) {
        if (_environment.default.unstack_enabled) {
          window.location.replace('/home/index');
        }
      }
    },

    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        if (this.get('accountSession').isPetParent()) {
          this.transitionTo('portal');
        } else {
          this.transitionTo('schedule');
        }
      }
    }

  });

  _exports.default = _default;
});