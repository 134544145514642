define("frontend/components/popout-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['popout-dropdown'],
    classNameBindings: ['searchEnabled'],
    attributeBindings: ['tabIndex'],
    media: inject.service(),
    tabIndex: 1,
    options: [],
    selectedValue: null,
    passPreviousSelected: false,
    hasManyOptions: computed.gt('options.length', 5),
    notMobile: computed.not('media.isMobile'),
    searchEnabled: computed.and('hasManyOptions', 'notMobile'),
    dropdownClass: 'search-enabled',
    disabled: false,
    selectedOption: computed('selectedValue', 'options.@each.value', {
      get() {
        let found = null;
        this.get('options').forEach(opt => {
          if (opt.groupName === undefined) {
            if (opt.value == this.get('selectedValue')) {
              found = opt;
            }
          } else {
            let innerFound = opt.options.find(innerOpt => innerOpt.value == this.get('selectedValue'));

            if (innerFound) {
              found = innerFound;
            }
          }
        });
        return found;
      },

      set(key, _ref) {
        let {
          value
        } = _ref;
        this.set('selectedValue', value);
        let found = null;
        this.get('options').forEach(opt => {
          if (opt.groupName === undefined) {
            if (opt.value == value) {
              found = opt;
            }
          } else {
            let innerFound = opt.options.find(innerOpt => innerOpt.value == value);

            if (innerFound) {
              found = innerFound;
            }
          }
        });
        return found;
      }

    }),

    willDestroyElement() {
      (0, _jquery.default)(this.element).off('focusout');
      (0, _jquery.default)(this.element).off('focus');
    },

    actions: {
      selectOption(option) {
        let previousSelected = null;

        if (this.get('selectedOption')) {
          previousSelected = this.get('selectedOption').value;
        }

        this.set('selectedOption', option);

        if (this.selectOption) {
          if (this.get('passPreviousSelected')) {
            this.selectOption(option.value, previousSelected);
          } else {
            this.selectOption(option.value);
          }
        }
      },

      open() {
        if (this.get('open')) {
          this.get('open')(...arguments);
        }
      }

    }
  });

  _exports.default = _default;
});