define("frontend/components/stripe-dropin", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    integration: null,
    user: null,
    reloadAfterSave: false,
    company: false,
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    saving: false,

    didInsertElement() {
      // eslint-disable-next-line new-cap
      let stripe = Stripe(_environment.default.stripe_api_key);
      let elements = stripe.elements();
      let style = {
        base: {
          fontFamily: "'Gotham A', 'Gotham B', helvetica, arial, sans-serif",
          fontSize: '16px',
          color: '#32325d'
        }
      };
      let card = elements.create('card', {
        hidePostalCode: true,
        style
      });
      let errorDiv = (0, _jquery.default)('#card-errors');
      card.mount('#card-container');
      card.addEventListener('change', _ref => {
        let {
          error
        } = _ref;

        if (error) {
          errorDiv.text(error.message);
        } else {
          errorDiv.text('');
        }
      });
      let form = document.getElementById('creditCardForm');
      form.addEventListener('submit', event => {
        event.preventDefault();

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);

        if (this.get('onSaving')) {
          this.get('onSaving')();
        }

        stripe.createToken(card).then(_ref2 => {
          let {
            token,
            error
          } = _ref2;

          if (error) {
            errorDiv.text(error.message);

            if (this.get('onFinishedSaving')) {
              this.get('onFinishedSaving')();
            }

            this.set('saving', false);
          } else {
            let url = '';

            if (this.get('company')) {
              url = `${_environment.default.APP.host}/api/v2/companies/${this.get('currentUser.user.company.id')}/create_stripe_payment_method`;
            } else {
              url = `${_environment.default.APP.host}/api/v2/users/${this.get('user.id')}/create_stripe_payment_method`;
            }

            _jquery.default.ajax({
              url,
              method: 'POST',
              data: {
                stripe_token: token,
                payment_method: 'card',
                id: this.get('user.id'),
                set_auto_pay: true
              }
            }).done(() => {
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: 'Payment info saved',
                type: 'success'
              });
              (0, _jquery.default)('#submitButton').prop('disabled', false);

              if (this.get('reloadAfterSave')) {
                location.reload();
              }
            }).fail(data => {
              if (data && data.responseText) {
                let parsed = JSON.parse(data.responseText);

                if (parsed.message) {
                  this.get('flashMessage').error(parsed.message);
                  (0, _jquery.default)('#submitButton').prop('disabled', false);
                } else {
                  this.get('flashMessage').errorWithRefresh('Unexpected error paying invoice');
                }
              } else {
                this.get('flashMessage').errorWithRefresh('Unexpected error paying invoice');
              }
            }).always(() => {
              if (this.get('onFinishedSaving')) {
                this.get('onFinishedSaving')();
              }

              this.set('saving', false);
            });
          }
        });
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      let integration = this.get('integration');

      if (this.get('integration')) {
        integration.teardown(() => {
          this.set('integration', null);
        });
      }
    }

  });

  _exports.default = _default;
});