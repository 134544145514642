define("frontend/components/auto-pay-with-recurring-tip", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ["auto-pay-with-recurring-tip"],
    store: inject.service(),
    media: inject.service(),
    currentUser: inject.service(),
    cookies: inject.service(),
    router: inject.service(),
    isPetParentUser: computed("cookies", function () {
      return this.get("cookies").read("ccontrol_id") === "0";
    }),
    settings: null,
    household: null,
    clientAccountBalance: computed("household.account_balance", "settings.account_balance", "isPetParentUser", function () {
      if (this.get("isPetParentUser")) {
        return parseFloat(this.get("settings.account_balance")).toFixed(2);
      }

      return parseFloat(this.get("household.account_balance")).toFixed(2);
    }),
    clientHasAccountBalance: computed("clientAccountBalance", function () {
      return this.get("clientAccountBalance") > 0;
    }),
    companyId: null,
    showAutoPayModal: false,
    autoPayIsOn: computed.readOnly("settings.auto_pay"),
    useAutoPayRecurringTip: computed.notEmpty("settings.auto_pay_tip_percentage"),
    autoPayTipPercentageClarification: computed("autoPayRecurringTipInputValue", "household.pets", "settings.service_fees_on", function () {
      const percentage = this.get("autoPayRecurringTipInputValue");
      const companyIsUsingServiceFees = this.get("settings.service_fees_on");

      if (percentage) {
        return `We will automatically include a ${percentage}% tip to your care professional when charging your payment method on file for new invoices.`;
      }

      const petClarification = this.get("household.pets").length > 1 ? "pets" : "pet";
      return `Include a set % in tip when your payment method on file is automatically charged with each invoice. ${companyIsUsingServiceFees ? "100%" : "This"} will go to the professionals who cared for your ${petClarification}.`;
    }),
    showPercentageInputError: false,
    autoPayRecurringTipInputValue: computed("settings.auto_pay_tip_percentage", function () {
      return this.get("settings.auto_pay_tip_percentage");
    }),
    showAutoPayTipPercentageInput: computed("autoPayRecurringTipInputValue", function () {
      const val = this.get("autoPayRecurringTipInputValue");
      return val !== null && val !== 0;
    }),
    resetInputValidationError: observer("autoPayRecurringTipInputValue", function () {
      this.set("showPercentageInputError", false);
    }),
    disableRecurringTipToggle: computed.not("settings.auto_pay"),
    isMobile: computed.readOnly("media.isMobile"),
    invoiceFrequencyClarification: computed("settings.invoice_frequency", function () {
      switch (this.get("settings.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    autoPayClarification: computed("invoiceFrequencyClarification", "autoPayIsOn", "settings.service_fees_on", "isPetParentUser", function () {
      const invoiceFrequencyClarification = this.get("invoiceFrequencyClarification");
      const autoPayIsOn = this.get("autoPayIsOn");
      let beginningOfMsg = autoPayIsOn ? "We will" : "Enabling AutoPay will allow us to";
      let endOfMsg = `automatically charge the payment method on file for the full outstanding balance ${invoiceFrequencyClarification} and send an itemized receipt via email.`;
      const companyIsUsingServiceFees = this.get("settings.service_fees_on");
      const isPetParentUser = this.get("isPetParentUser");

      if (companyIsUsingServiceFees && isPetParentUser) {
        endOfMsg += " A 5% service will be added to help us cover operating costs.";
      }

      return `${beginningOfMsg} ${endOfMsg}`;
    }),
    baseAutoPayModalMessage: computed("isPetParentUser", "invoiceFrequencyClarification", function () {
      const isPetParentUser = this.get("isPetParentUser");
      const invoiceFrequencyClarification = this.get("invoiceFrequencyClarification");
      const whoseBalance = isPetParentUser ? "your" : "the";
      return `We will automatically charge the payment method on file for ${whoseBalance} full outstanding balance ${invoiceFrequencyClarification} and send an itemized receipt.`;
    }),
    secondaryAutoPayModalMessage: computed("clientHasAccountBalance", "isPetParentUser", "clientAccountBalance", "household.owner.last_name", function () {
      const clientHasAccountBalance = this.get("clientHasAccountBalance");
      const isPetParentUser = this.get("isPetParentUser");
      const clientAccountBalance = this.get("clientAccountBalance");
      const clientLastName = this.get("household.owner.last_name");
      let baseMessage = "AutoPay only runs when bills are scheduled to go out.";

      if (clientHasAccountBalance) {
        if (isPetParentUser) {
          return `${baseMessage} You currently have an outstanding balance of $${clientAccountBalance}. Would you like to pay this now?`;
        }

        return `${baseMessage} The ${clientLastName} family's current outstanding balance of $${clientAccountBalance} will not be processed until their next bill closes. If you'd like for them to pay earlier, they will need to manually complete payment in their pet parent portal.`;
      }

      return "";
    }),

    willDestroyElement() {
      const percentageInputIsInvalid = this.get("showPercentageInputError");

      if (percentageInputIsInvalid) {
        this.send("updateAutoPayTipPercentage", null);
      }
    },

    updateTipPercentage(householdId, autoPayTipPercentage, data) {
      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/households/${householdId}/update_auto_pay_tip_percentage`,
        method: "POST",
        data: data
      }).then(() => {
        this.set("useAutoPayRecurringTip", !!autoPayTipPercentage);
        this.set("settings.auto_pay_tip_percentage", autoPayTipPercentage);
      }).fail(response => {
        if (response.status == 422) {
          this.get("flashMessage").error(response.responseJSON.error);
        }

        this.set("settings.auto_pay_tip_percentage", null);
        this.set("useAutoPayRecurringTip", false);
      });
    },

    actions: {
      validateTipValue() {
        const value = this.get("autoPayRecurringTipInputValue");
        const regex = /^(?:[5-9]|[1-9][0-9]|100)$/;
        const isValid = regex.test(value);
        this.set("showPercentageInputError", !isValid);
      },

      setUseAutoPayRecurringTip(useAutoPayRecurringTip) {
        if (useAutoPayRecurringTip) {
          this.send("updateAutoPayTipPercentage", 15);
        } else {
          this.send("updateAutoPayTipPercentage", null);
        }
      },

      setAutoPayTipPercentage(autoPayTipPercentage) {
        this.set("autoPayTipPercentage", autoPayTipPercentage);
      },

      closeAutoPayModal() {
        this.set("showAutoPayModal", false);
      },

      updateAutoPay(autoPay) {
        let householdId = this.get("household.id");
        let companyId = this.get("companyId");

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/update_autopay`,
          method: "POST",
          data: {
            auto_pay: autoPay,
            company_id: companyId
          }
        }).then(() => {
          this.set("settings.auto_pay", autoPay);

          if (autoPay) {
            this.set("showAutoPayModal", true);
          } else {
            this.send("updateAutoPayTipPercentage", null);
          }
        }).fail(response => {
          if (response.status == 422) {
            this.get("flashMessage").error(response.responseJSON.error);
          }
        });
      },

      updateAutoPayTipPercentage(valueOrEvent) {
        let autoPayTipPercentage = valueOrEvent;

        if (valueOrEvent?.target) {
          autoPayTipPercentage = autoPayTipPercentage.target.value;
        }

        this.set("settings.auto_pay_tip_percentage", autoPayTipPercentage);
        const regex = /^(?:[5-9]|[1-9][0-9]|100)$/;
        const valueIsValid = regex.test(autoPayTipPercentage);

        if (valueIsValid || autoPayTipPercentage === null) {
          this.set("showPercentageInputError", false);
          let householdId = this.get("household.id");
          let companyId = this.get("companyId");
          let data = {
            company_id: companyId
          };

          if (autoPayTipPercentage) {
            data["autopay_percentage"] = parseFloat(autoPayTipPercentage);
          }

          Ember.run.debounce(this, this.updateTipPercentage, householdId, autoPayTipPercentage, data, 500);
        } else {
          this.set("showPercentageInputError", true);
        }
      }

    }
  });

  _exports.default = _default;
});