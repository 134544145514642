define("frontend/models/vet-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;
  const LOGO_PLACEHOLDER = 'assets/images/company-image.svg';

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    logo_url: _emberData.default.attr('string'),
    veterinarian_clinics: _emberData.default.hasMany('veterinarian-clinics'),
    logoOrPlaceholder: computed('logo_url', function () {
      return this.get('logo_url') || LOGO_PLACEHOLDER;
    })
  });

  _exports.default = _default;
});