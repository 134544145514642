define("frontend/models/invoice-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    appointments: _emberData.default.hasMany('appointment', {
      async: true
    }),
    grouped_appointments: _emberData.default.hasMany('grouped-appointment', {
      async: true
    }),
    invoice_adjustments: _emberData.default.hasMany('invoice-adjustment', {
      async: false
    }),
    reminder_last_sent_date: _emberData.default.attr('date'),
    last_sent_date: _emberData.default.attr('date'),
    sales_tax_rate: _emberData.default.attr('number'),
    total_credit: _emberData.default.attr('number'),
    hasSalesTax: computed.gt('salesTaxTotal', 0),
    servicesTotals: Ember.computed("appointments.[]", function () {
      const appointments = this.get("appointments");
      let taxable = 0;
      let nonTaxable = 0;
      let combined = 0;
      appointments.forEach(appointment => {
        const coreServiceIsTaxable = appointment.get('offered_service.apply_sales_tax');
        const appointmentCost = appointment.get("cost");

        if (coreServiceIsTaxable) {
          taxable += appointmentCost;
        } else {
          nonTaxable += appointmentCost;
        }

        combined += appointmentCost;
        appointment.appointment_add_on_services.forEach(addOnService => {
          const addOnServiceIsTaxable = addOnService.get('offered_service.apply_sales_tax');
          const addOnCost = addOnService.get('cost');

          if (addOnServiceIsTaxable) {
            taxable += addOnCost;
          } else {
            nonTaxable += addOnCost;
          }

          combined += addOnCost;
        });
      });
      return {
        taxable,
        nonTaxable,
        combined
      };
    }),
    adjustmentsSubtotals: computed('invoice_adjustments.@each.amount', function () {
      let combined = 0;
      let taxable = 0;
      this.get('invoice_adjustments').forEach(a => {
        if (a.get('is_sales_tax')) return;

        if (a.get('apply_sales_tax') || a.get('isDiscount')) {
          taxable += a.get('amount');
        }

        combined += a.get('amount');
      });
      return {
        taxable,
        combined
      };
    }),
    subtotals: computed('adjustmentsSubtotals', 'servicesTotals', function () {
      const taxable = this.get('adjustmentsSubtotals.taxable') + this.get('servicesTotals.taxable');
      const combined = this.get('adjustmentsSubtotals.combined') + this.get('servicesTotals.combined');
      return {
        taxable,
        combined
      };
    }),
    salesTaxTotal: computed('invoice_adjustments.@each.{amount,is_sales_tax}', function () {
      return this.get('invoice_adjustments').filterBy('is_sales_tax', true).get('firstObject.amount') || 0;
    }),
    salesTaxRate: computed('invoice_adjustments.@each.{amount,is_sales_tax}', function () {
      return this.get('invoice_adjustments').filterBy('is_sales_tax', true).get('firstObject.sales_tax_rate') || 0;
    }),
    total: computed('subtotals', 'salesTaxTotal', function () {
      let combined = parseFloat(this.get('subtotals.combined')) || 0;
      let salesTax = parseFloat(this.get('salesTaxTotal')) || 0;
      return combined + salesTax;
    })
  });

  _exports.default = _default;
});