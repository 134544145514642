define("frontend/components/cancel-appointment-modal", ["exports", "frontend/config/environment", "moment", "jquery"], function (_exports, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    cancellationNotes: null,
    groupedAppointment: null,

    willInsertElement() {
      this._super(...arguments);

      this.set('cancelingAppointment', false);
      this.set('triedToCancel', false);
      let ga = this.get('groupedAppointment');
      ga.notifyPropertyChange('pending');
      ga.notifyPropertyChange('sortedAppointments');
    },

    appointments: computed('groupedAppointment.sortedAppointments.[]', function () {
      let appointments = this.get('groupedAppointment.sortedAppointments').map(appt => {
        return {
          id: appt.get('id'),
          avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
          pet_name: appt.get('pet').get('name'),
          selected: true
        };
      });
      return appointments;
    }),
    cancellationFeeObserver: observer('groupedAppointment', 'groupedAppointment.pending', function () {
      if (this.get('groupedAppointment.pending')) {
        return;
      }

      this.set('loadingCancellationFee', true);
      let appointment_ids = this.get('groupedAppointment.sortedAppointments').map(appt => appt.get('id'));

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/appointments/cancellation_fee`,
        method: 'GET',
        data: {
          appointment_ids
        }
      }).then(response => {
        this.set('cancellationFeePrice', response.cancellation_fee);
        this.set('cancellationFeeAdditionalPrice', response.additional_pet_fee);
        this.set('cancellationPolicyDescription', response.description);
        this.set('cancellationFeeAmountType', response.amount_type);
        this.set('cancellationFeePercentage', parseInt(response.percentage));
        this.set('loadingCancellationFee', false);
        this.set('cancelingAppointment', false);
      });
    }),
    cancellationFee: computed('cancellationFeePrice', 'cancellationFeeAdditionalPrice', 'appointments.@each.selected', {
      get() {
        let fee = parseFloat(this.get('cancellationFeePrice'));
        let additionalFee = parseFloat(this.get('cancellationFeeAdditionalPrice'));
        let appts = this.get('appointments');
        let selectedAppts = appts.filter(appt => appt.selected);
        let cancellationFee = 0;

        if (fee && fee > 0) {
          if (!isNaN(additionalFee)) {
            if (additionalFee == 0) {
              cancellationFee = fee;
            } else if (selectedAppts.length == appts.length) {
              cancellationFee = fee + (selectedAppts.length - 1) * additionalFee;
            } else {
              cancellationFee = selectedAppts.length * additionalFee;
            }
          } else {
            if (this.get('cancellationFeeAmountType') == 'fixed') {
              cancellationFee = fee;
            } else {
              cancellationFee = selectedAppts.length * fee;
            }
          }
        }

        return cancellationFee.toFixed(2);
      },

      set(key, value) {
        return value;
      }

    }),
    household: computed('groupedAppointment.appointments.[]', function () {
      return this.get('groupedAppointment.appointments.firstObject.pet.household');
    }),
    pickupWindow: computed('groupedAppointment.pickup_window', function () {
      return this.get('groupedAppointment.pickup_window');
    }),
    offeredService: computed('groupedAppointment.offered_service', function () {
      return this.get('groupedAppointment.offered_service');
    }),
    service: computed('groupedAppointment.service', function () {
      return this.get('groupedAppointment.service');
    }),
    month: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('ddd');
    }),
    showSelectionError: computed('appointments.@each.selected', function () {
      let selected = this.get('appointments').filter(appt => appt.selected);
      return selected.length == 0;
    }),
    showCancellationAmountError: computed('cancellationFeePrice', 'cancellationFee', 'triedToCancel', function () {
      if (this.get('triedToCancel')) {
        if (this.get('cancellationFeePrice') > 0 && this.get('cancellationFee') <= 0) {
          return true;
        }

        return false;
      }

      return false;
    }),
    actions: {
      cancelAppointment(cancellationFee) {
        this.set('triedToCancel', true);

        if (this.get('showSelectionError') || cancellationFee && this.get('showCancellationAmountError')) {
          return;
        }

        let selectedAppts = this.get('appointments').filter(appt => appt.selected);
        let apptIds = selectedAppts.map(appt => appt.id);
        this.set('cancelingAppointment', true);
        this.set('triedToCancel', false);
        let groupedAppointment = this.get('groupedAppointment');
        this.get('cancelGroupedAppointment')(groupedAppointment, apptIds, cancellationFee, this.get('cancellationNotes'));
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});