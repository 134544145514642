define("frontend/components/weekend-surcharge-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    weekendSurchargePolicy: null,
    percentageType: computed.equal('weekendSurchargePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('weekendSurchargePolicy.amount_type', 'fixed'),
    chargePerDay: computed.readOnly('weekendSurchargePolicy.charge_per_day'),
    chargePerVisit: computed.not('chargePerDay'),
    percentageCompensation: computed.equal('weekendSurchargePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('weekendSurchargePolicy.employee_compensation_type', 'fixed'),
    surchargeTypeOptions: [{
      label: 'fixed $ per visit',
      value: 'fixed'
    }, {
      label: '% of service cost',
      value: 'percentage'
    }],
    selectedAmountType: computed('weekendSurchargePolicy.amount_type', function () {
      let options = this.get('surchargeTypeOptions');
      let selected = options.filter(option => this.get('weekendSurchargePolicy.amount_type') == option.value);
      return selected.get('firstObject');
    }),

    didInsertElement() {
      this.set('loading', false);
      this.set('originalDow', [...this.get('weekendSurchargePolicy.days_of_week')]);

      if (!this.get('newWeekendSurchargePolicy') && this.get('fixedType')) {
        let fee = this.get('weekendSurchargePolicy.amount');
        this.set('weekendSurchargePolicy.amount', this.formatMoney(fee, ''));
      }
    },

    amountTypeObserver: observer('weekendSurchargePolicy.amount_type', function () {
      if (this.get('weekendSurchargePolicy.amount_type') === 'percentage') {
        this.set('weekendSurchargePolicy.charge_per_day', false);
      }
    }),
    amountError: computed('percentageType', 'weekendSurchargePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('weekendSurchargePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'weekendSurchargePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('weekendSurchargePolicy.employee_compensation_amount');

        if (!amount && amount != 0 || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      toggleChargePerDay() {
        this.toggleProperty('weekendSurchargePolicy.charge_per_day');
      },

      cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
        this.get('weekendSurchargePolicy').rollbackAttributes();

        if (this.get('weekendSurchargePolicy.id')) {
          this.set('weekendSurchargePolicy.days_of_week', this.get('originalDow'));
        }
      },

      changeAmountType(option) {
        this.set('weekendSurchargePolicy.amount_type', option.value);
      },

      save() {
        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('compensationAmountError') || this.get('weekendSurchargePolicy.days_of_week.length') == 0) {
          return;
        }

        this.set('loading', true);

        if (this.get('newWeekendSurchargePolicy')) {
          this.get('weekendSurchargePolicy').save().then(() => {
            this.get('onClose')();
          });
        } else {
          // Updates will destroy the record and create a new one which Ember data
          // doesn't like
          let policy = this.get('weekendSurchargePolicy');
          Ember.$.ajax({
            url: `/api/v2/weekend_surcharge_policies/${this.get('weekendSurchargePolicy.id')}`,
            method: 'PUT',
            data: {
              weekend_surcharge_policy: {
                service_category_id: policy.get('service_category.id'),
                amount: policy.get('amount'),
                amount_type: policy.get('amount_type'),
                employee_compensation_type: policy.get('employee_compensation_type'),
                employee_compensation_amount: policy.get('employee_compensation_amount'),
                days_of_week: policy.get('days_of_week'),
                charge_per_day: policy.get('charge_per_day')
              }
            }
          }).then(response => {
            this.get('weekendSurchargePolicy').deleteRecord();
            this.get('store').findRecord('weekend-surcharge-policy', response.weekend_surcharge_policy.id).then(newPolicy => {
              newPolicy.set('service_category', this.get('serviceCategory'));
              this.set('serviceCategory.weekend_surcharge_policy', newPolicy);
              this.get('onClose')();
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});