define("frontend/models/emergency-contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('capitalized-string'),
    last_name: _emberData.default.attr('capitalized-string'),
    preferred_name: _emberData.default.attr('capitalized-string'),
    emails: _emberData.default.hasMany('emergency-contact-emails', {
      async: true
    }),
    phone_numbers: _emberData.default.hasMany('emergency-contact-phone-numbers'),
    addresses: _emberData.default.hasMany('emergency-contact-addresses'),
    household: _emberData.default.belongsTo('household'),
    avatar_url: _emberData.default.attr('string'),
    fullName: computed('first_name', 'last_name', 'preferred_name', function () {
      return `${this.get('preferred_name') || this.get('first_name') || ''} ${this.get('last_name') || ''}`;
    })
  });

  _exports.default = _default;
});