define("frontend/controllers/client-list/household/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    household: computed.alias('model.household'),
    firstNames: computed('household.owner', 'household.secondaryContact', function () {
      if (this.get('household.secondaryContact')) {
        return `${this.get('household.owner.getFirstName')} & ${this.get('household.secondaryContact.getFirstName')}`;
      } else {
        return this.get('household.owner.getFirstName');
      }
    })
  });

  _exports.default = _default;
});