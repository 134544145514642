define("frontend/components/user-search-result", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PET_PLACEHOLDER_PREFIX = 'assets/images/placeholders';
  const {
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: [''],
    tagName: 'ul',
    household: null,
    emailSearched: null,
    staff: false,
    flashMessage: inject.service(),
    parsedPets: Ember.computed('household', 'household.pets.[]', function () {
      let pets = this.get('household.pets');
      let parsedPets = [];
      pets.forEach(pet => {
        parsedPets.push({
          name: pet.name,
          avatar_url: pet.avatar.url ? pet.avatar.url : `${PET_PLACEHOLDER_PREFIX}/${pet.pet_type}.svg`
        });
      });
      return parsedPets;
    }),
    showAdd: Ember.computed('household.client_status', 'household.staff_status', 'staff', function () {
      if (this.get('staff')) {
        let staff_status = this.get('household.staff_status');

        if (staff_status == 'none' || staff_status == 'client') {
          return true;
        }
      } else {
        let client_status = this.get('household.client_status');

        if (client_status != 'client' && client_status != 'denied' && client_status != 'pending') {
          return true;
        }
      }

      return false;
    }),
    currentConnection: Ember.computed('staff', 'household.client_status', 'household.staff_status', function () {
      let staff_status = this.get('household.staff_status');
      let client_status = this.get('household.client_status');

      if (this.get('staff')) {
        if (staff_status == 'none') {
          return null;
        }

        return staff_status;
      } else {
        if (client_status == 'none') {
          return null;
        }

        return client_status;
      }
    }),
    showResend: Ember.computed('staff', 'household.client_status', 'household.staff_status', function () {
      if (this.get('staff')) {
        if (this.get('household.staff_status') == 'pending') {
          return true;
        }
      } else {
        if (this.get('household.client_status') == 'pending') {
          return true;
        }
      }

      return false;
    }),
    showReadd: Ember.computed('staff', 'household.staff_status', function () {
      if (this.get('staff')) {
        if (this.get('household.staff_status') == 'former staff') {
          return true;
        }
      }

      return false;
    }),
    actions: {
      addHousehold() {
        if (this.get('adding')) {
          return;
        }

        this.set('adding', true);
        let household = this.get('household');
        let email = this.get('emailSearched');
        let url = '';

        if (this.get('staff')) {
          url = `${_environment.default.APP.host}/api/v2/employees/add_company`;
        } else {
          url = `${_environment.default.APP.host}/api/v2/households/${household.id}/add_company`;
        }

        _jquery.default.ajax({
          url,
          method: 'POST',
          data: {
            id: household.id,
            email
          }
        }).done(response => {
          this.get('flashMessage').sendFlashMessage(`Your request to connect was sent to ${email}`, 'success');
          this.redirectToAddedUser(response.household_id, response.owner_id);
        });
      },

      redirectToUser() {
        this.redirectToUser(this.get('household.id'), this.get('household.owner_id'), this.get('staff'));
      }

    }
  });

  _exports.default = _default;
});