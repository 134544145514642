define("frontend/components/input-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    validationObject: null,
    classNames: ['input-validation'],
    classNameBindings: ['showError:red-validation-error'],
    selector: 'input',
    showError: false,
    didValidate: false,
    didBlur: false,
    validateOnFocusOut: true,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('didValidate') || this.get('didBlur')) {
        this.set('showError', this.get('isInvalid'));
      }

      if ($(this.get('fullSelector')).length === 0) {
        return;
      }

      if (this.get('validateOnFocusOut') && !this.get('didBlur')) {
        $(this.get('fullSelector')).off('blur');
        $(this.get('fullSelector')).blur(() => {
          this.set('didBlur', true);
          this.set('showError', this.get('isInvalid'));
        });
      }
    },

    errorMessage: computed('validationObject.message', function () {
      return this.get('validationObject.message');
    }),
    fullSelector: computed('selector', 'elementId', function () {
      let selector = this.get('selector');
      return `#${this.get('elementId')} ${selector}`;
    }),
    isInvalid: computed('validationObject.isInvalid', function () {
      return this.get('validationObject.isInvalid');
    }),

    willDestroyElement() {
      this._super(...arguments);

      $(this.get('fullSelector')).off('blur');
    }

  });

  _exports.default = _default;
});