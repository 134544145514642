define("frontend/routes/portal/appointments", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    currentUser: inject.service(),
    infinity: inject.service(),

    model(params) {
      let reportCardAppointment = null;

      if (params.showReportCard) {
        reportCardAppointment = this.store.findRecord('appointment', params.appointmentId);
      }

      return Ember.RSVP.hash({
        reportCardAppointment
      });
    }

  });

  _exports.default = _default;
});