define("frontend/components/mobile-action-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-action-bar'],
    householdUser: null,
    showContactPickerModal: false,
    contactPrompt: null,
    contactPickerInfo: [],

    clickEmail() {
      let emails = this.get('householdUser.emails');
      let emailsCount = emails.get('length');

      if (emailsCount === 1) {
        this.onEmailSelected(emails.get('firstObject'));
      } else if (emailsCount > 1) {
        this.populateContactInfo('onEmailSelected', 'Which email would you like to reach?', emails, model => {
          let type = model.get('email_type');
          let address = model.get('address');
          return {
            type,
            address,
            model
          };
        });
      }
    },

    clickPhone() {
      let phones = this.get('householdUser.phone_numbers');
      let phonesCount = phones.get('length');
      let mobilePhones = phones.filter(phone => phone.get('isMobile'));

      if (phonesCount > 1 || mobilePhones.get('length') > 0) {
        this.set('showPhonePickerModal', true);
      } else {
        this.callPhone(phones.get('firstObject'));
      }
    },

    clickMessage() {
      this.get('onMessageSelected')();
    },

    populateContactInfo(contactAction, contactPrompt, models, modelMarshaller) {
      this.set('showContactPickerModal', true);
      this.set('contactAction', contactAction);
      this.set('contactPrompt', contactPrompt);
      let contacts = [];
      models.forEach(model => {
        contacts.push(modelMarshaller(model));
      });
      this.set('contactPickerInfo', contacts);
    },

    actions: {
      onClose() {
        this.set('showContactPickerModal', false);
        this.set('contactPrompt', null);
      },

      closePhonePickerModal() {
        this.set('showPhonePickerModal', false);
      },

      clickAddress() {
        this.clickAddress();
      },

      onEmailSelected(email) {
        this.get('onEmailSelected')(email);
      },

      textPhone(phone) {
        this.get('textPhone')(phone);
      },

      callPhone(phone) {
        this.get('callPhone')(phone);
      },

      onMessageSelected(message) {
        this.get('onMessageSelected')(message);
      }

    }
  });

  _exports.default = _default;
});