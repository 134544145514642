define("frontend/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPetGrammaticalDetails = void 0;

  const getPetGrammaticalDetails = pet => {
    const name = pet.name;
    const gender = pet.gender;
    const pronoun = gender ? gender === 'male' ? 'he' : 'she' : 'they';
    const possesivePronoun = gender ? gender === 'male' ? 'his' : 'her' : 'their';
    const subject = name || 'this pet';
    const verb = gender ? 'has' : 'have';
    return {
      subject,
      subjectUpper: subject.charAt(0).toUpperCase() + subject.slice(1),
      pronoun,
      verb,
      possesivePronoun,
      possesivePronounUpper: possesivePronoun.charAt(0).toUpperCase() + possesivePronoun.slice(1)
    };
  };

  _exports.getPetGrammaticalDetails = getPetGrammaticalDetails;
});