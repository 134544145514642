define("frontend/components/avatar-images-with-names/avatar-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['avatar-images__images'],
    showTooltip: false,
    avatars: computed.map('avatarables', function (avatarable) {
      if (!avatarable) {
        return;
      }

      return {
        image: avatarable.get('avatarOrPlaceholder'),
        name: avatarable.get('name')
      };
    })
  });

  _exports.default = _default;
});