define("frontend/components/client-billing-inputs", ["exports", "frontend/utils/invoice-frequency-options", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _invoiceFrequencyOptions, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ["billing-inputs"],
    store: inject.service(),
    stripeInfo: inject.service(),
    flashMessage: inject.service(),
    media: inject.service(),
    isMobile: computed.readOnly("media.isMobile"),
    noBillDeliveryRecipients: computed.readOnly("household.noBillDeliveryRecipients"),
    saving: false,
    showCardPanel: false,
    currentUser: inject.service(),
    settings: null,
    household: null,
    fetchingCustomerInfo: computed.readOnly("paymentMethods.isPending"),
    plaidRedirect: false,
    company: computed("settings.company_id", function () {
      let id = this.get("settings.company_id");

      if (id) {
        let promise = this.get("store").find("company", id);
        return _emberData.default.PromiseObject.create({
          promise
        });
      }
    }),
    companyId: computed.readOnly("settings.company_id"),
    ownerUserId: computed("household", "household.owner", function () {
      if (this.get("household.owner")) {
        return this.get("household.owner").get("id");
      }

      return null;
    }),
    paymentMethods: computed("settings", "settings.company_id", function () {
      if (this.get("settings.company_id")) {
        let userId = this.get("ownerUserId");
        let companyId = this.get("settings.company_id");

        if (userId) {
          let promise = this.get("stripeInfo").fetchCustomerInfo(userId, companyId);
          return _emberData.default.PromiseObject.create({
            promise
          });
        }
      }

      return null;
    }),
    invoiceFrequencyOptions: computed("company.invoice_frequencies.[]", "settings.invoice_frequency", "settings.biweeklyStart", "settings.biweeklyEnd", function () {
      if (!this.get("company.invoice_frequencies")) {
        return [];
      }

      let invoiceFrequencies = this.get("company.invoice_frequencies").map(invoiceFrequency => {
        let label = invoiceFrequency.get("displayName");
        let value = invoiceFrequency.get("name");
        let biweeklyStart = this.get("company.biweeklyStart");
        let biweeklyEnd = this.get("company.biweeklyEnd");
        let id = invoiceFrequency.get("id");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += ` (${biweeklyStart} - ${biweeklyEnd})`;
        }

        return {
          value,
          label,
          id
        };
      }).sort((a, b) => a.id - b.id);

      if (this.get("hasCustomInvoiceFrequency")) {
        let currentFrequency = this.get("settings.invoice_frequency");
        let invoiceFrequency = this.get("store").createRecord("invoice-frequency", {
          name: currentFrequency
        });
        let label = invoiceFrequency.get("displayName");
        let value = invoiceFrequency.get("name");
        let biweeklyStart = this.get("company.biweeklyStart");
        let biweeklyEnd = this.get("company.biweeklyEnd");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += ` (${biweeklyStart} - ${biweeklyEnd})`;
        }

        invoiceFrequencies = [{
          value,
          label
        }];
      }

      return invoiceFrequencies;
    }),
    hasCustomInvoiceFrequency: computed("company.invoice_frequencies.[]", "settings.invoice_frequency", function () {
      let currentFrequency = this.get("settings.invoice_frequency");

      if (!currentFrequency) {
        return false;
      }

      let companyFrequencies = this.get("company.invoice_frequencies");

      if (!companyFrequencies) {
        return true;
      }

      let found = companyFrequencies.filter(invoiceFrequency => invoiceFrequency.get("name") == currentFrequency);
      return found.length == 0;
    }),
    selectedInvoiceFrequency: computed("invoiceFrequencyOptions", "settings.invoice_frequency", function () {
      let options = this.get("invoiceFrequencyOptions");
      let settings = this.get("settings");

      if (settings) {
        return options.find(opt => {
          return opt.value == settings.invoice_frequency;
        });
      }
    }),
    customInvoiceFrequencyTooltipText: computed("company.displayName", function () {
      return `You are on a custom billing frequency. Please contact ${this.get("company.displayName")} if you have any questions!`;
    }),
    timePeriodClarify: computed("settings.invoice_frequency", function () {
      switch (this.get("settings.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    invoiceFrequencyClarify: computed("timePeriodClarify", function () {
      let timePeriodClarify = this.get("timePeriodClarify");

      if (timePeriodClarify) {
        return `Invoice emails will be sent ${timePeriodClarify} to:`;
      } else {
        return "You have selected not to accrue how much this client owes you. No bills will be generated.";
      }
    }),
    savedCardInfo: computed("paymentMethods.card", function () {
      let customer_info = this.get("paymentMethods");

      if (customer_info.get("card")) {
        return {
          brand: customer_info.get("card.brand"),
          last_four: customer_info.get("card.last_four")
        };
      } else {
        return null;
      }
    }),
    savedBankAccountInfo: computed("paymentMethods.bank_account", function () {
      let customer_info = this.get("paymentMethods");

      if (customer_info.get("bank_account")) {
        return {
          brand: customer_info.get("bank_account.bank_name"),
          last_four: customer_info.get("bank_account.last_four"),
          verified: customer_info.get("bank_account.verified")
        };
      } else {
        return null;
      }
    }),
    showSavedCard: computed("savedCardInfo", "paymentMethods.default", function () {
      return this.get("paymentMethods.default") === "card" && this.get("savedCardInfo");
    }),
    showSavedBankAccount: computed("savedBankAccountInfo", "paymentMethods.default", function () {
      return this.get("paymentMethods.default") === "bank_account" && this.get("savedBankAccountInfo");
    }),
    actions: {
      setSaving() {
        if (this.setSaving) {
          this.setSaving();
        }
      },

      closePaymentMethodModal() {
        this.set("showingPaymentMethodModal", false);
        this.set("plaidRedirect", false);
      },

      changeInvoiceFrequency(invoiceFrequency) {
        this.set("previousInvoiceFrequency", this.get("settings.invoice_frequency"));
        this.set("settings.invoice_frequency", invoiceFrequency.value);
        let householdId = this.get("household.id");
        let companyId = this.get("company.id");
        this.set("loadingStartBillingDate", true);
        this.set("showInvoiceFrequencyEffectiveDate", true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/billing_start_date?company_id=${companyId}&invoice_frequency=${invoiceFrequency.value}`,
          method: "GET"
        }).done(response => {
          this.set("newBillingPeriodStartDate", moment(response.start_date));
          this.set("loadingStartBillingDate", false);
        });
      },

      closeInvoiceFrequencyEffectiveDate() {
        let successful = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (!successful) {
          this.set("settings.invoice_frequency", this.get("previousInvoiceFrequency"));
        }

        this.set("showInvoiceFrequencyEffectiveDate", false);
      },

      activateSidePanel() {
        this.set("anySidePanelActive", true);
      },

      deactivateSidePanel() {
        this.set("anySidePanelActive", false);

        if (this.reloadOwner) {
          this.reloadOwner();
        }
      },

      saveBillDeliveryOptions(add_or_remove, email) {
        let householdId = this.get("household.id");
        let company_id = this.get("company.id");
        let method = add_or_remove == "add" ? "POST" : "DELETE";

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/bill_recipient`,
          method,
          data: {
            email,
            company_id
          }
        });
      },

      savePaymentMethod(token, paymentMethod) {
        this.set("plaidRedirect", false);
        this.set("showingPaymentMethodModal", false);
        let userId = this.get("ownerUserId");
        this.set("updatingPaymentMethod", true);
        this.get("stripeInfo").createPaymentMethod(userId, token, paymentMethod).then(() => {
          this.notifyPropertyChange("paymentMethods");
          this.set("updatingPaymentMethod", false);
        }).catch(result => {
          this.get("flashMessage").sendFlashMessage(result.payload.message, "user-error");
          this.set("showingPaymentMethodModal", true);
          this.set("updatingPaymentMethod", false);
        });
      },

      showRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", true);
      },

      redirectToMicrodeposits() {
        this.get("redirectToMicrodeposits")();
      },

      confirmRemovePaymentMethod() {
        let householdId = this.get("household.id");
        let defaultMethod = this.get("paymentMethods.default");
        let payment_method_id = this.get(`paymentMethods.${defaultMethod}.id`);
        let brand = this.get(`paymentMethods.${defaultMethod}.brand`);
        let lastFour = this.get(`paymentMethods.${defaultMethod}.last_four`);
        this.set("showRemovePaymentMethod", false);
        this.set("updatingPaymentMethod", true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/stripe_payment_method`,
          method: "DELETE",
          data: {
            payment_method_id
          }
        }).then(() => {
          this.set("updatingPaymentMethod", false);
          this.notifyPropertyChange("paymentMethods");
          this.get("flashMessage").success(`You've removed ${brand} ending ${lastFour} from your account`);
        });
      },

      cancelRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", false);
      }

    }
  });

  _exports.default = _default;
});