define("frontend/components/appointment-all-day-service-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    companyName: null,
    companyId: null,
    actions: {
      close() {
        this.get('onClose')();
      },

      goToMessenger() {
        this.get('goToMessenger')();
      }

    }
  });

  _exports.default = _default;
});