define("frontend/components/bill-reminders/bill-reminders-sidepanel", ["exports", "frontend/utils/payment-reminder-options"], function (_exports, _paymentReminderOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EVERY_DAY,
    EVERY_2_DAYS,
    EVERY_3_DAYS,
    EVERY_4_DAYS,
    EVERY_5_DAYS,
    WEEKLY,
    BIWEEKLY,
    NEVER
  } = _paymentReminderOptions.default;

  var _default = Ember.Component.extend({
    companyInvoiceFrequencies: [],
    reminderFrequencyOptions: [{
      value: EVERY_DAY.value,
      label: EVERY_DAY.label
    }, {
      value: EVERY_2_DAYS.value,
      label: EVERY_2_DAYS.label
    }, {
      value: EVERY_3_DAYS.value,
      label: EVERY_3_DAYS.label
    }, {
      value: EVERY_4_DAYS.value,
      label: EVERY_4_DAYS.label
    }, {
      value: EVERY_5_DAYS.value,
      label: EVERY_5_DAYS.label
    }, {
      value: WEEKLY.value,
      label: WEEKLY.label
    }, {
      value: BIWEEKLY.value,
      label: BIWEEKLY.label
    }, {
      value: NEVER.value,
      label: NEVER.label
    }],
    actions: {
      close() {
        this.close(...arguments);
      }

    }
  });

  _exports.default = _default;
});