define("frontend/controllers/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    currentUser: inject.service(),
    activityLogs: inject.service(),
    hasUnreadLogs: computed.alias('activityLogs.hasUnreadLogs'),
    inboxHasUnreadMessages: inject.service(),
    hasUnreadMessages: computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    showRedDot: computed.or('hasUnreadLogs', 'hasUnreadMessages'),
    actions: {
      clickHamburger() {
        Ember.run.scheduleOnce('afterRender', () => {
          $('body').toggleClass('push-active');
        });
      }

    }
  });

  _exports.default = _default;
});