define("frontend/components/schedule/index/service-item", ["exports", "frontend/config/environment", "frontend/utils/date-util", "moment", "jquery"], function (_exports, _environment, _dateUtil, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const {
    inFuture
  } = _dateUtil.default;

  var _default = Ember.Component.extend({
    classNames: ['service-item', 'col-xs-12'],
    classNameBindings: ['isComplete:complete', 'highlighted'],
    service: null,
    showChangeCareProvider: false,
    scheduleDragActionService: inject.service(),
    store: inject.service(),
    timeZone: inject.service(),
    checkDuplicateAppointmentTimes: inject.service(),
    checkStartedAppointment: inject.service(),
    draggedOver: false,
    showCanceledAppointments: false,
    currentUser: inject.service(),
    flashMessage: inject.service(),
    media: inject.service(),
    highlightWindowStart: null,
    highlightWindowEnd: null,
    packReportCards: [],
    showStartedAppointmentMessage: false,

    didInsertElement() {
      this._super(...arguments);

      this.disableRightClickForMobile();
    },

    disableRightClickForMobile() {
      if (this.isMobile()) {
        (0, _jquery.default)(this.element).on('contextmenu', () => {
          return false;
        });
        (0, _jquery.default)(this.element.querySelectorAll('.pet-item')).on('contextmenu', () => {
          return false;
        });
      }
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    isAndroid() {
      return /Android/i.test(navigator.userAgent);
    },

    willDestroyElement() {
      (0, _jquery.default)(this.element).off('contextmenu');
      (0, _jquery.default)(this.element.querySelectorAll('.pet-item')).off('contextmenu');
    },

    highlighted: computed('highlightWindowStart', 'highlightWindowEnd', function () {
      let startTime = this.get('highlightWindowStart');
      let endTime = this.get('highlightWindowEnd');

      if (!startTime || !endTime || this.get('time') == 'all-day') {
        return false;
      }

      let time = (0, _moment.default)(`${this.get('time')}${this.get('ampm')}`, 'h:mmA');
      startTime = (0, _moment.default)(startTime, 'h:mmA');
      endTime = (0, _moment.default)(endTime, 'h:mmA');
      return time.isSameOrAfter(startTime) && time.isBefore(endTime);
    }),
    sortedGroupedAppointments: computed('service.sortedGroupedAppointmentsWithoutCanceled.[]', 'service.sortedCanceledGroupedAppointments.[]', function () {
      if (this.get('showCanceledAppointments')) {
        return this.get('service.sortedCanceledGroupedAppointments');
      } else {
        return this.get('service.sortedGroupedAppointmentsWithoutCanceled');
      }
    }),
    careProviderId: computed('service.care_provider.id', function () {
      return this.get('service.care_provider.id');
    }),
    time: computed('service.starts_at', 'service.all_day_service', function () {
      if (this.get('service.all_day_service')) {
        return 'all-day';
      }

      let startsAt = this.get('service.starts_at');
      return _moment.default.parseZone(startsAt).format('h:mm');
    }),
    ampm: computed('service.starts_at', 'service.all_day_service', function () {
      if (this.get('service.all_day_service')) {
        return;
      }

      let startsAt = this.get('service.starts_at');
      return _moment.default.parseZone(startsAt).format('A');
    }),
    isComplete: computed('service.sortedGroupedAppointmentsWithoutCanceled.@each.completed', 'showCanceledAppointments', function () {
      if (this.get('showCanceledAppointments')) {
        return false;
      }

      return this.get('service.sortedGroupedAppointmentsWithoutCanceled').isEvery('completed', true);
    }),

    showScheduleTrash() {
      this.set('scheduleDragActionService.showingCancel', true);
      this.set('scheduleDragActionService.showingArchive', true);
    },

    hideScheduleTrash() {
      this.set('scheduleDragActionService.showingCancel', false);
      this.set('scheduleDragActionService.showingArchive', false);
    },

    confirmRequest(appointmentRequest, position) {
      let service = this.get('service');

      let startsAtString = _moment.default.parseZone(service.get('starts_at')).format('h:mm A');

      let startTime = (0, _moment.default)(startsAtString, 'h:mm A');
      let timeFrameStart = (0, _moment.default)(appointmentRequest.get('time_frame_start_time'), 'h:mmA');
      let timeFrameEnd = (0, _moment.default)(appointmentRequest.get('time_frame_end_time'), 'h:mmA');
      let serviceDate = (0, _moment.default)(service.get('starts_at')).startOf('day');
      let startsAtDate = (0, _moment.default)(appointmentRequest.get('starts_at')).startOf('day');

      if (startTime.isBefore(timeFrameStart) || startTime.isSameOrAfter(timeFrameEnd) || !serviceDate.isSame(startsAtDate)) {
        appointmentRequest.set('movingInFlight', false);
        this.set('appointmentRequestBeingConfirmed', appointmentRequest);
        this.set('selectedPosition', position);

        if (!serviceDate.isSame(startsAtDate)) {
          this.set('movedToWrongDate', true);
        }

        this.set('selectedStartTime', startsAtString);
        this.set('showOutOfTimeWindowModal', true);
        return;
      }

      this.handleConfirmingRequest(appointmentRequest, position);
    },

    handleConfirmingRequest(appointmentRequest, position) {
      if (this.get('currentUser.user').shouldSeeMessage('drag-drop-appointment-request')) {
        this.set('showDragDropRequestMessage', true);
      }

      let care_provider_id = this.get('service.care_provider.id');
      let offered_service_id = appointmentRequest.get('offered_service.id');
      let time_frame_start_time = appointmentRequest.get('time_frame_start_time');
      let time_frame_end_time = appointmentRequest.get('time_frame_end_time');
      let date_time = this.get('service.starts_at');
      let special_instructions = appointmentRequest.get('special_instructions');

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/approve`,
        method: 'POST',
        data: {
          care_provider_id,
          offered_service_id,
          time_frame_start_time,
          time_frame_end_time,
          date_time,
          special_instructions,
          position
        }
      }).then(() => {
        this.get('service').reload();
        appointmentRequest.deleteRecord();
      });
    },

    moveAppointment(groupedAppointment, position) {
      // let service = this.get('service');
      let previousService = groupedAppointment.get('service');
      let previousPosition = groupedAppointment.get('position');

      if (this.get('service.all_day_service') != groupedAppointment.get('service.all_day_service')) {
        groupedAppointment.set('movingInFlight', false);
        groupedAppointment.set('service', previousService);
        groupedAppointment.set('position', previousPosition);
        return;
      } // let startsAtString = moment.parseZone(service.get('starts_at')).format('h:mm A');
      // let startTime = moment(startsAtString, 'h:mm A');
      // let firstAppointment = groupedAppointment.get('appointments.firstObject');
      // let timeFrameStart = moment(firstAppointment.get('time_frame_start_time'), 'h:mmA');
      // let timeFrameEnd = moment(firstAppointment.get('time_frame_end_time'), 'h:mmA');
      //
      // if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //   this.finishMovingAppointment(groupedAppointment, previousService, previousPosition);
      //   this.set('groupedAppointmentBeingMoved', groupedAppointment);
      //   this.set('selectedPosition', position);
      //   this.set('showOutOfTimeWindowModal', true);
      //   this.set('selectedStartTime', startsAtString);
      //   return;
      // }


      this.handleMovingAppointment(groupedAppointment, position);
    },

    handleMovingAppointment(groupedAppointment, position) {
      let service = this.get('service');
      let previousService = groupedAppointment.get('service');
      let previousPosition = groupedAppointment.get('position');
      let care_provider_id = service.get('care_provider.id');

      let starts_at = _moment.default.parseZone(service.get('starts_at')).format();

      let groupedAppts = this.get('service.grouped_appointments');
      groupedAppts.sortBy('position').forEach((appt, index) => {
        appt.set('position', index);
      });
      groupedAppts.forEach(groupedAppt => {
        if (groupedAppt.get('position') >= position && groupedAppt.get('id') != groupedAppointment.get('id')) {
          groupedAppt.set('position', groupedAppt.get('position') + 1);
        }
      });
      let oldPosition = groupedAppointment.get('position');
      let oldServiceId = groupedAppointment.get('service.id');
      groupedAppointment.set('service', this.get('service'));
      groupedAppointment.set('position', position); // This is a quirk in our system. If you are dragging a pet in the same service,
      // the position you're dragging goes away since we hide the pet-item when dragging.

      if (oldPosition <= position && oldServiceId === this.get('service.id')) {
        position -= 1;
      }

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/grouped_appointments/${groupedAppointment.get('id')}/move`,
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          position,
          care_provider_id,
          starts_at
        })
      }).done(response => {
        Ember.run.scheduleOnce('afterRender', () => {
          this.get('store').pushPayload(response);
          groupedAppts.forEach(appt => appt.rollbackAttributes());
          groupedAppointment.get('appointments').forEach(appt => {
            appt.set('time', _moment.default.parseZone(starts_at).format());
          });
          this.resizeAppointmentsList();
        });

        if (this.isAndroid()) {
          Ember.run.later(() => {
            this.finishMovingAppointment(groupedAppointment, null, null);
          }, 1000);
        } else {
          this.finishMovingAppointment(groupedAppointment, null, null);
        }
      }).fail(data => {
        this.finishMovingAppointment(groupedAppointment, previousService, previousPosition);

        if (data && data.responseText) {
          let parsed = JSON.parse(data.responseText);

          if (parsed.errors) {
            this.get('flashMessage').error(parsed.errors.service);
          } else if (parsed.message) {
            this.get('flashMessage').error(parsed.message);
          } else {
            this.get('flashMessage').error('Unexpected error moving appointment. Please try again');
          }
        } else {
          this.get('flashMessage').error('Unexpected error moving appointment. Please try again');
        }
      });
    },

    finishMovingAppointment(groupedAppointment, previousService, previousPosition) {
      if (previousService) {
        groupedAppointment.set('service', previousService);
        groupedAppointment.get('appointments').forEach(appt => {
          appt.set('service', previousService);
        });
      }

      if (previousPosition) {
        groupedAppointment.set('position', previousPosition);
      }

      groupedAppointment.set('movingInFlight', false);
      this.clearAppointmentBeingMoved();
    },

    clearAppointmentBeingMoved() {
      this.set('groupedAppointmentBeingMoved', null);
      this.set('appointmentRequestBeingConfirmed', null);
      this.set('selectedPosition', null);
      this.set('selectedStartTime', null);
    },

    showCompleteAllButton: computed('sortedGroupedAppointments.@each.hasStartedAppointments', 'isComplete', 'media.isDesktop', 'media.isMobile', 'media.isTablet', function () {
      let isComplete = this.get('isComplete');

      if (this.get('showCanceledAppointments')) {
        return false; // on the desktop, we're showing the mark complete button, which is always available
      } else if (this.get('media.isDesktop') && !isComplete) {
        return true; // check to make sure there are no started appointments for the uncomplete button
      } else if (this.get('media.isDesktop') && isComplete) {
        return !this.get('sortedGroupedAppointments').toArray().any(groupedAppt => groupedAppt.get('hasStartedAppointments')); // this else statement will handle mobile and tablet
      } else {
        let showCompleteAll = true;
        this.get('sortedGroupedAppointments').forEach(groupedAppt => {
          if (groupedAppt.get('offered_service.service_category.show_check_in_check_out_appointment') || groupedAppt.get('hasStartedAppointments')) {
            showCompleteAll = false;
          }
        });
        return showCompleteAll;
      }
    }),
    actions: {
      markAllComplete() {
        if (inFuture(this.get('service.starts_at'))) {
          Ember.getOwner(this).lookup('controller:schedule').set('showAppointmentInFutureFlash', true);
          return;
        }

        let appts = [];
        this.get('service.sortedGroupedAppointmentsWithoutCanceled').forEach(groupedAppt => {
          groupedAppt.get('appointments').forEach(appt => {
            appts.push(appt);
          });
        });
        let completed = !this.get('isComplete');
        let appointments = appts.map(appt => {
          let showReportCard = appt.get('offered_service.service_category.show_report_card_on_completion');

          if (completed && showReportCard) {
            this.queueGroupedAppointment(appt.get('grouped_appointment'));
          }

          return {
            id: appt.get('id'),
            completed
          };
        });
        this.set('updatingAppointments', true);

        let lazyPromise = () => {
          return _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/appointments/complete`,
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              appointments
            })
          }).then(response => {
            let reloadPromises = [];
            response.appointments.forEach(appt => {
              let filteredAppt = appts.filterBy('id', `${appt.id}`).get('firstObject');

              if (appt.error) {
                Ember.getOwner(this).lookup('controller:application').send('notify', {
                  message: appt.error,
                  type: 'user-error'
                });
              }

              reloadPromises.push(filteredAppt.reload());
            });
            Ember.RSVP.Promise.all(reloadPromises).then(() => {
              if (completed) {
                this.startGroupedReportCardQueue();
              }
            });
          }).fail(error => {
            let message = 'Unexpected error marking appointments complete. Please try again';
            let type = 'error';

            if (error && error.responseText) {
              let parsed = JSON.parse(error.responseText);

              if (parsed.errors) {
                message = parsed.errors.appointments;
              } else if (parsed.message) {
                message = parsed.message;
              }
            }

            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message,
              type
            });
          }).always(() => {
            this.set('updatingAppointments', false);
          });
        };

        lazyPromise();
      },

      dropItemAtLastPosition(groupedAppointment) {
        this.send('dropItem', groupedAppointment, this.get('service.grouped_appointments.length'));
      },

      dragPetStart(groupedAppointment) {
        let startedAppointments = [];
        groupedAppointment.get('appointments').forEach(appt => {
          if (appt.get('started_at') && !appt.get('finished_at')) {
            startedAppointments.push(appt);
          }
        });

        if (startedAppointments.length === 0) {
          this.showScheduleTrash();
        }

        this.get('startDraggingPet')(groupedAppointment);

        if (!this.isAndroid()) {
          setTimeout(() => {
            (0, _jquery.default)(this.element.querySelectorAll('.is-dragging-object')).hide();
            this.resizeAppointmentsList();
          }, 0);
        }
      },

      dragPetEnd() {
        this.hideScheduleTrash();
        this.get('stopDraggingPet')();
        setTimeout(() => {
          if (!this.isAndroid()) {
            (0, _jquery.default)(this.element.querySelectorAll('.draggable-object')).show();
          }

          this.resizeAppointmentsList();
        }, 0);
      },

      dropItem(appointmentOrRequest, position) {
        if (appointmentOrRequest.get('movingInFlight')) {
          return;
        }

        this.get('stopDraggingPet')();
        appointmentOrRequest.set('movingInFlight', true);
        let service = this.get('service');

        let starts_at = _moment.default.parseZone(service.get('starts_at')).format();

        this.get('scheduleDragActionService').stopDragging();
        let isAppointment = appointmentOrRequest.get('constructor.modelName') == 'grouped-appointment';
        let dropMethod = isAppointment ? this.moveAppointment : this.confirmRequest;

        let yes = () => dropMethod.bind(this)(appointmentOrRequest, position);

        let no = () => {};

        if (isAppointment && this.get('checkDuplicateAppointmentTimes').isGroupedAppointmentDuplicate(appointmentOrRequest, starts_at)) {
          let message = `${appointmentOrRequest.get('petNames')} already has an appointment at this time.`;
          this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
          appointmentOrRequest.set('movingInFlight', false);
          no();
        } else if (isAppointment && this.get('checkStartedAppointment').hasStartedAppointment(appointmentOrRequest) && !appointmentOrRequest.get('completed')) {
          if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
            this.set('showStartedAppointmentMessage', true);
          }

          appointmentOrRequest.set('movingInFlight', false);
          no();
        } else {
          yes();
        }
      },

      clickAppointment(appointment) {
        this.clickAppointment(appointment);
      },

      changeCareProvider(care_provider_id) {
        this.set('service.care_provider_id', care_provider_id);
        let starts_at = this.get('service.starts_at');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/services/${this.get('service.id')}`,
          method: 'PUT',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            care_provider_id,
            starts_at
          })
        }).then(response => {
          let store = this.get('store');
          store.pushPayload(response);
          this.changeCareProvider(care_provider_id);
        }).fail(data => {
          if (data && data.responseText) {
            let parsed = JSON.parse(data.responseText);

            if (parsed.errors) {
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: parsed.errors.service,
                type: 'error'
              });
            } else if (parsed.message) {
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: parsed.message,
                type: 'error'
              });
            } else {
              Ember.getOwner(this).lookup('controller:application').send('notify', {
                message: 'Unexpected error updating care professional',
                type: 'error'
              });
            }
          } else {
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message: 'Unexpected error updating care professional',
              type: 'error'
            });
          }
        });
      },

      denyUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentBeingMoved();
      },

      confirmUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);

        if (this.get('groupedAppointmentBeingMoved')) {
          this.handleMovingAppointment(this.get('groupedAppointmentBeingMoved'), this.get('selectedPosition'));
        } else {
          this.handleConfirmingRequest(this.get('appointmentRequestBeingConfirmed'), this.get('selectedPosition'));
        }
      },

      hideDragDropRequestMessage() {
        this.set('showDragDropRequestMessage', false);
      },

      dismissedDragDropRequestMessage() {
        this.send('hideDragDropRequestMessage');
        this.get('currentUser.user').reload();
      },

      hideStartedAppointmentMessage() {
        this.set('showStartedAppointmentMessage', false);
      },

      dismissedStartedAppointmentMessage() {
        this.send('hideStartedAppointmentMessage');
        this.get('currentUser.user').reload();
      }

    }
  });

  _exports.default = _default;
});