define("frontend/components/view-appointment-request-modal", ["exports", "moment", "frontend/utils/string-util"], function (_exports, _moment, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    appointmentRequest: null,
    offeredService: computed.alias('appointmentRequest.offered_service'),
    pickupWindow: computed.alias('appointmentRequest.pickupWindow'),

    willInsertElement() {
      this._super(...arguments);

      this.set('cancelingAppointment', false);
      this.set('triedToCancel', false);
    },

    petNames: computed('appointmentRequest.pets.[]', function () {
      return _stringUtil.default.joinWithAnd(this.get('appointmentRequest.pets').map(pet => pet.get('name')));
    }),
    month: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('ddd');
    }),
    actions: {
      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});