define("frontend/components/appointment-list-item", ["exports", "frontend/helpers/format-rolling-date", "frontend/utils/string-util"], function (_exports, _formatRollingDate, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const {
    htmlSafe
  } = Ember.String;

  var _default = Ember.Component.extend({
    media: inject.service(),

    /**
     * The appointment, recurring appointment, or appointment request that is being listed in this item.
     */
    record: null,

    /**
     * A string representing the type of record being passed.
     *
     * Should be a string value of either 'appointment', 'recurring-appointment', or 'appointment-request'
     */
    recordType: null,

    /**
     * An action passed that will handle when this item was selected from the list.
     *
     * The action will receive a single argument, the record that was passed to this component.
     */
    onSelectedItem: null,

    /**
     * An action passed that will handle when a user wants to edit the item.
     *
     * The action will receive a single argument, the record that was passed to this component.
     */
    onEditItem: null,

    /**
     * An action passed that will handle when a user wants to archive the record.
     *
     * The action will receive a single argument, the record that was passed to this component.
     */
    onRemoveItem: null,

    /**
     * Used to override when the checkbox is selected; useful when all items have been selected
     */
    selected: false,
    hovering: false,
    swipedLeft: false,
    isRecurringAppointment: computed.equal('recordType', 'recurring-appointment'),
    isRecurringAddOn: computed.equal('recordType', 'recurring-add-on'),
    isRecurring: computed.or('isRecurringAppointment', 'isRecurringAddOn'),
    editIcon: 'assets/images/edit-icon-red.svg',
    archiveIcon: computed('media.isMobile', function () {
      if (this.get('media.isMobile')) {
        return 'assets/images/archive-icon-mobile.svg';
      } else {
        return 'assets/images/archive-icon.svg';
      }
    }),
    recordId: computed('record', 'recordType', function () {
      let recordType = this.get('recordType');

      if (recordType === 'recurring-appointment' || recordType === 'recurring-add-on') {
        return [].concat(...this.get('record.ids')).join('');
      } else if (recordType === 'appointment') {
        return this.get('record.grouped_appointment.id');
      } else if (recordType === 'appointment-request') {
        return this.get('record.appointment_request_limited.id');
      }
    }),
    formattedDate: computed('record', 'recordType', function () {
      let recordType = this.get('recordType');

      if (recordType === 'recurring-appointment' || recordType === 'recurring-add-on') {
        return this.get('record.formatted_weekdays');
      } else if (recordType === 'appointment') {
        return htmlSafe((0, _formatRollingDate.formatRollingDate)([this.get('record.services.0.starts_at'), 'ddd, MMM D']));
      } else if (recordType === 'appointment-request') {
        return htmlSafe((0, _formatRollingDate.formatRollingDate)([this.get('record.appointment_request_limited.starts_at'), 'ddd, MMM D']));
      }
    }),
    formattedTimeFrame: computed('record', 'recordType', function () {
      let recordType = this.get('recordType');

      if (recordType === 'recurring-appointment' || recordType === 'recurring-add-on') {
        return this.get('record.formatted_timeframe');
      } else if (recordType === 'appointment' && !this.get('record.appointments.0.all_day_service')) {
        return `${this.get('record.appointments.firstObject.pickup_window')} arrival`;
      } else if (recordType === 'appointment-request' && this.get('record.appointment_request_limited.time_frame_start_time')) {
        return `${this.get('record.appointment_request_limited.time_frame_start_time')} - ${this.get('record.appointment_request_limited.time_frame_end_time')} arrival`;
      }
    }),
    serviceName: computed('record', 'recordType', 'offeredService.name', function () {
      let recordType = this.get('recordType');

      switch (recordType) {
        case 'recurring-appointment':
        case 'recurring-add-on':
          return this.get('record.offered_service_name');

        case 'appointment':
        case 'appointment-request':
          return this.get('offeredService.name');
      }
    }),
    serviceDuration: computed('record', 'recordType', 'offeredService.durationDisplay', function () {
      let recordType = this.get('recordType');

      switch (recordType) {
        case 'recurring-appointment':
        case 'recurring-add-on':
          return this.get('record.offered_service_duration');

        case 'appointment':
        case 'appointment-request':
          return this.get('offeredService.durationDisplay');
      }
    }),
    petNames: computed('record', function () {
      let names = this.get('record.pets').map(pet => pet.name).sort();
      return _stringUtil.default.joinWithAnd(names);
    }),
    petAvatars: computed('record', function () {
      return this.get('record.pets').map(pet => {
        if (pet.avatar_url) {
          return pet.avatar_url;
        } else {
          return `assets/images/placeholders/${pet.pet_type}.svg`;
        }
      });
    }),
    showActions: computed.or('hovering', 'swipedLeft'),
    actions: {
      selectAppointmentListItem() {
        this.onSelectedItem(this.get('record'), this.get('isAddOn'));
      },

      editAppointmentListItem() {
        this.onEditItem(this.get('record'));
      },

      archiveAppointmentListItem() {
        if (this.get('recordType') === 'appointment') {
          let ids = [this.get('record.grouped_appointment.id')];
          let petNames = this.get('petNames');
          this.onRemoveItem('single', ids, petNames);
        } else if (this.get('recordType') === 'recurring-appointment') {
          let petNames = this.get('petNames');
          this.onRemoveItem('recurring', this.get('record.ids'), petNames, this.get('record.formatted_weekdays'));
        } else if (this.get('recordType') === 'appointment-request') {
          this.onRemoveItem(this.get('record.appointment_request_limited.id'), this.get('record.pets'));
        } else if (this.get('recordType') === 'recurring-add-on') {
          let petNames = this.get('petNames');
          this.onRemoveItem('recurringAddOn', this.get('record.ids'), petNames, this.get('record.formatted_weekdays'));
        }
      },

      hovering(isHovering) {
        this.set('hovering', isHovering && !this.get('media.isMobile'));
      },

      hoveringEdit(isHovering) {
        if (isHovering) {
          this.set('editIcon', 'assets/images/edit-icon-red-hover.svg');
        } else {
          this.set('editIcon', 'assets/images/edit-icon-red.svg');
        }
      },

      hoveringArchive(isHovering) {
        if (isHovering) {
          this.set('archiveIcon', 'assets/images/archive-icon-hover.svg');
        } else {
          this.set('archiveIcon', 'assets/images/archive-icon.svg');
        }
      },

      mobileClicked() {
        if (this.get('media.isMobile')) {
          this.toggleProperty('swipedLeft');
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});