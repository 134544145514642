define("frontend/components/flash-message", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dismissAfter: 5,

    didInsertElement() {
      if (_environment.default.environment === 'test') {
        this.dismiss();
        return;
      }

      if (this.dismissAfter > 0) {
        Ember.run.later(this, 'dismiss', this.dismissAfter * 1000);
      }
    },

    dismiss() {
      Ember.getOwner(this).lookup('controller:application').set('notification', '');
      return (0, _jquery.default)(`#${this.elementId}`).children().alert('close');
    }

  });

  _exports.default = _default;
});