define("frontend/models/service", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _emberData.default.Model.extend({
    timeZone: inject.service(),
    starts_at: _emberData.default.attr('string'),
    all_day_service: _emberData.default.attr('boolean'),
    care_provider: _emberData.default.belongsTo('care-provider', {
      async: true
    }),
    appointments: _emberData.default.hasMany('appointment', {
      async: true
    }),
    grouped_appointments: _emberData.default.hasMany('grouped-appointment', {
      async: true
    }),
    company_name: _emberData.default.attr('string'),
    company_email: _emberData.default.attr('string'),
    appointmentsWithRecurrence: computed('sortedGroupedAppointmentsWithoutCanceled.@each.appointmentIds', function () {
      let appts = [];
      this.get('sortedGroupedAppointmentsWithoutCanceled').forEach(groupedAppt => {
        // Trigger for intermediary computed property
        groupedAppt.get('appointmentIds');
        groupedAppt.get('appointments').forEach(appt => {
          if (appt.get('isRecurring')) {
            appts.push(appt);
          }
        });
      });
      return appts;
    }),
    max_offered_service_duration: computed('sortedGroupedAppointmentsWithoutCanceled.@each.offeredServiceDuratonInMinutes', function () {
      let durations = this.get('sortedGroupedAppointmentsWithoutCanceled').map(ga => {
        return ga.get('offered_service.duration_in_minutes');
      });
      return Math.max(...durations);
    }),
    sortedGroupedAppointments: computed('grouped_appointments.[]', 'grouped_appointments.@each.position', 'grouped_appointments.@each.hasUndeletedAppts', function () {
      let groupedAppts = this.get('grouped_appointments').filter(groupedAppt => groupedAppt.get('hasUndeletedAppts') == true);
      return groupedAppts.sortBy('position', 'id');
    }),
    sortedGroupedAppointmentsWithoutCanceled: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(groupedAppt => !groupedAppt.get('canceled'));
    }),
    sortedCanceledGroupedAppointments: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(groupedAppt => groupedAppt.get('canceled'));
    }),
    // Intermediary computed property for nested arrays of services
    sortedGroupedAppointmentsWithoutCanceledIds: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.id', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(groupedAppt => !groupedAppt.get('canceled')).mapBy('id').join(', ');
    }),
    // Intermediary computed property for nested arrays of services
    sortedCanceledGroupedAppointmentsIds: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.id', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(groupedAppt => groupedAppt.get('canceled')).mapBy('id').join(', ');
    }),
    pixelHeight: computed('max_offered_service_duration', function () {
      let duration = this.get('max_offered_service_duration');

      if (duration < 30 || !duration) {
        duration = 30;
      }

      let heightCalc = duration / 60.0 * 128 - 3;
      return heightCalc;
    }),
    pixelStartsAt: computed('starts_at', function () {
      let minutes = parseInt((0, _moment.default)(this.get('starts_at')).format('mm')) / 60.0;
      let startsAtCalc = minutes * 128.0 + 2;
      return `${startsAtCalc}px`;
    }),
    starts_at_time: computed('starts_at', function () {
      return _moment.default.parseZone(this.get('starts_at')).format('h:mm A');
    }),
    starts_at_date: Ember.computed('starts_at', function () {
      return _moment.default.parseZone(this.get('starts_at')).format('YYYY-MM-DD');
    })
  });

  _exports.default = _default;
});