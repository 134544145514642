define("frontend/components/ppb-button", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['ppb-button'],
    classNameBindings: ['color', 'style', 'active'],
    attributeBindings: ['disabled', 'tabIndex', 'type'],
    disabled: false,
    active: false,
    type: 'button',
    backgroundImage: null,
    backgroundImageSize: 'cover',

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));

      if (this.get('backgroundImage')) {
        (0, _jquery.default)(this.element).css({
          'background-image': `url('${this.get('backgroundImage')}')`,
          'background-size': this.get('backgroundImageSize')
        });
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },

    handleMouseEnter() {
      if (this.onMouseEnter) {
        this.onMouseEnter();
      }
    },

    handleMouseLeave() {
      if (this.onMouseLeave) {
        this.onMouseLeave();
      }
    },

    backgroundImageObserver: Ember.observer('backgroundImage', 'backgroundImageSize', function () {
      if (this.get('backgroundImage')) {
        (0, _jquery.default)(this.element).css({
          'background-image': `url('${this.get('backgroundImage')}')`,
          'background-size': this.get('backgroundImageSize')
        });
      }
    }),

    click() {
      if (this.onclick) {
        this.onclick();
      }
    }

  });

  _exports.default = _default;
});