define("frontend/components/invoice-type-select", ["exports", "frontend/utils/invoice-type"], function (_exports, _invoiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['invoice-type-select'],
    options: [_invoiceType.default.SENT, _invoiceType.default.DUE, _invoiceType.default.PAID, _invoiceType.default.DRAFT],
    actions: {
      select() {
        this.get('select')(...arguments);
      }

    }
  });

  _exports.default = _default;
});