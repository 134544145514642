define("frontend/components/delete-appointment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    month: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('ddd');
    }),
    startEndTime: computed('appointment.service.starts_at', 'appointment.offered_service.duration_in_minutes', function () {
      let start = moment.parseZone(this.get('appointment.service.starts_at'));
      let duration = this.get('appointment.offered_service.duration_in_minutes');
      let end = moment.parseZone(this.get('appointment.service.starts_at')).add(duration, 'minutes');
      return `${start.format('h:mmA')} - ${end.format('h:mmA')}`;
    }),
    actions: {
      confirm() {
        this.get('confirm')();
      },

      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});