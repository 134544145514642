define("frontend/controllers/users/forgot-password", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'email': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['invalidToken'],
    session: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    email: '',
    invalidToken: false,
    actions: {
      resetPassword() {
        this.set('emailError', false);
        this.set('email', this.get('email').trim());

        if (!this.get('validations.attrs.email.isValid')) {
          this.set('emailError', true);
          return;
        }

        let email = this.get('email');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/reset_password`,
          method: 'POST',
          data: {
            email
          }
        }).done(() => {
          this.transitionToRoute('users.login').then(() => {
            this.get('flashMessage').sendFlashMessage('Check your email for a link to finish resetting your password');
          });
        });
      },

      redirectToRequestAccess() {
        this.transitionToRoute('sign-up.index');
      },

      redirectToSignIn() {
        this.transitionToRoute('users.login');
      }

    }
  });

  _exports.default = _default;
});