define("frontend/components/vaccinations-modal", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    classNames: ['vaccinations-modal'],
    store: Ember.inject.service(),
    vaccinations: null,
    allVaccinations: null,
    vaccineSearchText: '',
    nameInvalid: false,
    hasVaccinations: computed("vaccinations.@each.dirtyType", function () {
      const vaccinations = this.get("vaccinations");
      const nonDeletedVaccinations = vaccinations.filter(v => v.dirtyType !== "deleted");
      return nonDeletedVaccinations.get("length");
    }),
    vaccinationsNotYetAdded: computed("vaccinations", function () {
      const vaccinations = this.get("vaccinations");

      if (vaccinations.get("length") === 1) {
        const vaccine = vaccinations.get("firstObject");

        if (vaccine.expires_on === null && vaccine.name === null) {
          return true;
        }
      }

      return false;
    }),
    modalTitle: computed("vaccinationsNotYetAdded", function () {
      const vaccinationsNotYetAdded = this.get("vaccinationsNotYetAdded");
      return vaccinationsNotYetAdded ? "Add vaccinations" : "Update vaccinations";
    }),
    primaryButtonText: computed("vaccinationsNotYetAdded", function () {
      const vaccinationsNotYetAdded = this.get("vaccinationsNotYetAdded");
      return vaccinationsNotYetAdded ? "add" : "update";
    }),

    rollbackInvalid() {
      let rollback = [];
      this.get('vaccinations').forEach(v => {
        if (!v.get('name') || v.get('expiresOnInvalid')) {
          rollback.push(v);
        }
      });
      rollback.forEach(v => v.rollbackAttributes());
    },

    actions: {
      onClose() {
        this.rollbackInvalid();
        this.get('onClose')();
      },

      vaccineOnSearchTextChange(newSearchText) {
        this.set('searchText', newSearchText);
      },

      vaccineSearchOnClose(vaccinationRecord) {
        const searchText = this.get('searchText')?.toLowerCase();
        let match = this.allVaccinations.content.find(vaccine => vaccine.name.toLowerCase() === searchText);

        if (searchText && !match) {
          vaccinationRecord.set('nameErrors', ['* invalid vaccine']);
        } else if (match) {
          vaccinationRecord.set('name', match.name);
          vaccinationRecord.set('nameErrors', []);
          this.send('addVaccination', vaccinationRecord, match);
        } else {
          vaccinationRecord.set('nameErrors', []);
        }

        this.set('searchText', '');
      },

      addVaccination(vaccinationRecord, vaccinationItem) {
        vaccinationRecord.set('nameErrors', []);

        if (!vaccinationItem) {
          vaccinationRecord.set('name', undefined);
        } else {
          vaccinationRecord.set('name', vaccinationItem.name);
        }
      },

      addAnotherVaccination() {
        this.get('addAnotherVaccination')();
      },

      deleteVaccination(vaccination) {
        vaccination.deleteRecord();
      },

      onSubmit() {
        this.rollbackInvalid();
        this.get('onSubmit')();
      }

    }
  });

  _exports.default = _default;
});