define("frontend/components/birthday-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['layout-row', 'birthday-input'],
    media: inject.service(),
    useDateType: computed.readOnly('media.isMobile'),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('birthday') && !this.get('useDateType')) {
        let year = moment(this.get('birthday')).format('YYYY');
        let month = moment(this.get('birthday')).format('MMMM');
        let day = moment(this.get('birthday')).format('D');
        this.set('selectedYear', year);
        this.set('selectedMonth', month);
        this.set('selectedDay', day);
      }
    },

    yearOptions: computed(function () {
      let start = moment();
      let end = moment().subtract(30, 'years');
      let options = [];

      while (start > end) {
        options.push(start.format('YYYY'));
        start.subtract(1, 'year');
      }

      return options;
    }),
    monthOptions: computed(function () {
      let start = moment().startOf('year');
      let end = moment().endOf('year');
      let options = [];

      while (start < end) {
        options.push(start.format('MMMM'));
        start.add(1, 'month');
      }

      return options;
    }),
    dayOptions: computed('selectedMonth', 'selectedYear', function () {
      let selectedMonth = this.get('selectedMonth') || moment().format('MMMM');
      let selectedYear = this.get('selectedYear') || moment().format('YYYY');
      let momentMonth = moment(selectedMonth, 'MMMM').year(selectedYear);
      let start = momentMonth.clone().startOf('month');
      let end = momentMonth.clone().endOf('month');
      let options = [];

      while (start < end) {
        options.push(start.format('D'));
        start.add(1, 'day');
      }

      return options;
    }),

    setDate() {
      if (!this.get('selectedMonth') || !this.get('selectedDay') || !this.get('selectedYear')) {
        this.get('onChange')(null);
        return;
      }

      let dateString = `${this.get('selectedMonth')} ${this.get('selectedDay')} ${this.get('selectedYear')}`;
      let formattedDate = moment(dateString, 'MMMM DD YYYY').format('MM/DD/YYYY');
      this.get('onChange')(formattedDate);
    },

    actions: {
      changeDay(day) {
        this.set('selectedDay', day);
        this.setDate();
      },

      changeMonth(month) {
        this.set('selectedMonth', month);

        if (this.get('dayOptions').indexOf(this.get('selectedDay')) === -1) {
          this.set('selectedDay', null);
        }

        this.setDate();
      },

      changeYear(year) {
        this.set('selectedYear', year);

        if (this.get('dayOptions').indexOf(this.get('selectedDay')) === -1) {
          this.set('selectedDay', null);
        }

        this.setDate();
      }

    }
  });

  _exports.default = _default;
});