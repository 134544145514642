define("frontend/components/staff-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['staff-list'],
    tagName: 'ul',
    filter: '',
    selectedStaff: null,
    searchFilter: '',
    allStaff: [],

    sortStaff(a, b) {
      let fullNameA = `${a.get('getFirstName')} ${a.get('last_name')}`.toLowerCase();
      let fullNameB = `${b.get('getFirstName')} ${b.get('last_name')}`.toLowerCase();

      if (fullNameA < fullNameB) {
        return -1;
      } else if (fullNameA > fullNameB) {
        return 1;
      } else {
        return 0;
      }
    },

    filteredStaff: computed('searchFilter', 'allStaff', 'allStaff.@each.getFirstName', 'allStaff.@each.last_name', function () {
      return this.get('allStaff').filter(staff => {
        let filter = this.get('searchFilter') || '';
        filter = filter.toLowerCase().trim();
        let firstName = `${staff.get('getFirstName')}}`.toLowerCase().slice(0, filter.length);
        let lastName = `${staff.get('last_name')}`.toLowerCase().slice(0, filter.length);
        let fullName = `${staff.get('getFirstName')} ${staff.get('last_name')}`.toLowerCase().slice(0, filter.length);
        return firstName.indexOf(filter) >= 0 || lastName.indexOf(filter) >= 0 || fullName.indexOf(filter) >= 0;
      }).sort(this.sortStaff);
    }),
    actions: {
      clickStaff(staff) {
        this.clickStaff(staff);
      },

      addEmployee() {
        this.addEmployee();
      }

    }
  });

  _exports.default = _default;
});