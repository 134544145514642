define("frontend/components/ppb-file-upload-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['file-upload-container'],
    classNameBindings: ['isUploading'],
    isHovering: false,
    iconUrl: 'assets/images/report-card/add-photo-icon.svg',
    hoveredIconUrl: 'assets/images/report-card/add-photo-icon-hover.svg',
    buttonText: 'Add photo',
    shownIconUrl: computed('isHovering', function () {
      if (this.get('isHovering')) {
        return this.get('hoveredIconUrl');
      }

      return this.get('iconUrl');
    }),

    handleMouseEnter() {
      this.set('isHovering', true);

      if (this.onMouseEnter) {
        this.onMouseEnter();
      }
    },

    handleMouseLeave() {
      this.set('isHovering', false);

      if (this.onMouseLeave) {
        this.onMouseLeave();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },

    actions: {
      uploadFile(file) {
        this.get('afterUploadCallback')(file);
      }

    }
  });

  _exports.default = _default;
});