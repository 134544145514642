define("frontend/routes/edit-client/remove-household-user", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    currentUser: inject.service(),
    saving: computed.alias('addClientSaving.saving'),

    model(params) {
      return this.store.findRecord('household-user', params.household_user_id);
    },

    transitionToList(household) {
      return this.transitionTo('client-list.household.household-user', household.get('id'), household.get('owner.id'));
    },

    transitionToUser() {
      let household = this.get('controller.model.household');
      this.transitionTo('edit-client.secondary-contact', household);
    },

    actions: {
      remove() {
        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        let householdUser = this.get('controller.model');
        let household = this.get('controller.model.household');
        householdUser.destroyRecord().then(() => {
          if (this.get('currentUser.user.isPetParent')) {
            window.location = '/portal/profile';
          } else {
            window.location = `/client-list/${household.get('id')}/household-user/${household.get('owner.id')}`;
          }
        });
      },

      cancel() {
        this.transitionToUser();
      }

    }
  });

  _exports.default = _default;
});