define("frontend/controllers/add-edit-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    searchClientsStep: 'client-search',
    primaryContactStep: 'primary-contact',
    secondaryContactStep: 'secondary-contact',
    petStep: 'pet',
    billingStep: 'billing-settings',
    documentsStep: 'documents'
  });

  _exports.default = _default;
});