define("frontend/routes/client-list/household", ["exports", "frontend/config/environment", "frontend/routes/base-route", "jquery"], function (_exports, _environment, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    queryParams: {
      backDate: {
        refreshModel: true
      },
      backCareProviderId: {
        refreshModel: true
      },
      backToMessages: {
        refreshModel: true
      }
    },

    model(params) {
      this.set('backDate', params.backDate);
      this.set('backCareProviderId', params.backCareProviderId);
      this.set('backToMessages', params.backToMessages);
      return this.store.findRecord('household', params.household_id);
    },

    afterModel(model) {
      this._super(...arguments);

      if (model.get('active') == false) {
        this.send('error');
      }
    },

    deactivate() {
      this._super(...arguments);

      this.set('controller.model', null);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('moreMenuOpen', false);
      controller.set('removingAppointment', null);
      controller.set('editingUpcomingAppointments', false);
    },

    backQueryParams() {
      let backRoute = this.get('controller.currentRouteName');
      let backHouseholdId = this.get('controller.model.id');
      let backId = this.get('controller.selectedUser.id');
      return {
        backHouseholdId,
        backId,
        backRoute
      };
    },

    actions: {
      error() {
        this.transitionTo('client-list.household-error');
      },

      goBack() {
        if (this.get('backCareProviderId') && this.get('backDate')) {
          this.transitionTo('schedule', {
            queryParams: {
              care_provider_id: this.get('backCareProviderId'),
              date: this.get('backDate')
            }
          });
        } else if (this.get('backToMessages')) {
          this.transitionTo('inbox.household', this.get('controller.model.id'));
        } else {
          this.transitionTo('client-list');
        }
      },

      cancelRequest(householdId) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/remove_request`,
          method: 'POST',
          data: {
            id: householdId
          }
        }).done(() => {
          this.store.findRecord('household', householdId).then(household => {
            household.deleteRecord();
          });
          this.transitionTo('client-list');
        });
      },

      requestHousehold(householdId, email) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/add_company`,
          method: 'POST',
          data: {
            id: householdId,
            email
          }
        }).done(() => {
          this.store.findRecord('household', householdId).then(household => {
            household.set('client_status', 'pending');
          });
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: `Your request to connect was sent to ${email}`,
            type: 'success'
          });
        });
      },

      transitionToAddAppointment() {
        let queryParams = this.backQueryParams.call(this);
        queryParams.selectedHouseholdId = this.get('controller.model.id');
        queryParams.filter = this.get('controller.model.owner.fullName');
        this.transitionTo('add-appointment.index', {
          queryParams
        });
      },

      transitionToEditSingleAppointment(appointmentIds) {
        let queryParams = this.backQueryParams.call(this);
        queryParams.ids = appointmentIds;
        this.transitionTo('edit-single-appointment.index', {
          queryParams
        });
      },

      transitionToEditRecurringAppointmentGroup(group) {
        let queryParams = this.backQueryParams.call(this);
        this.transitionTo('edit-recurring-appointment-group.index', group.get('id'), {
          queryParams
        });
      },

      transitionToRemoveRecurringAppointmentGroup(group) {
        let queryParams = this.backQueryParams.call(this);
        this.transitionTo('remove-recurring-appointment-group', group.get('id'), {
          queryParams
        });
      },

      transitionToHouseholdUser(household) {
        this.transitionTo('client-list.household.household-user', household, household.get('owner'));
      },

      removeHousehold(household) {
        this.transitionTo('edit-client.remove-household', household);
      },

      transitionToInvoices(household) {
        let queryParams = this.backQueryParams.call(this);
        this.transitionTo('invoices-summary.index', household.get('id'), {
          queryParams
        });
      },

      transitionToMessages(household) {
        let queryParams = this.backQueryParams.call(this);
        this.transitionTo('client-list.household.messages', household, {
          queryParams
        });
      },

      transitionToEditHousehold(household, petOrUser, sectionToEdit) {
        let self = this;
        household.get('users').forEach(function (user) {
          let phoneNumbers = user.get('phone_numbers');

          if (phoneNumbers.get('length') == 0) {
            let defaultPhone = self.store.createRecord('phone-number');
            user.get('phone_numbers').pushObject(defaultPhone);
          }
        });

        if (petOrUser.get('modelName') === 'household-user') {
          let queryParams = {
            backRoute: 'client-list.household.household-user',
            backHouseholdId: household.get('id'),
            backId: petOrUser.get('id')
          };

          if (petOrUser.get('isHouseholdOwner')) {
            this.transitionTo('edit-client.primary-contact', household, {
              queryParams
            });
          } else {
            this.transitionTo('edit-client.secondary-contact', household, {
              queryParams
            });
          }
        } else {
          let queryParams = {
            backRoute: 'client-list.household.pet',
            backHouseholdId: household.get('id'),
            backId: petOrUser.get('id'),
            currentSection: undefined
          };

          if (sectionToEdit && typeof sectionToEdit === 'string') {
            queryParams['editSection'] = sectionToEdit;
          }

          this.transitionTo('edit-client.pet', household, petOrUser, {
            queryParams
          });
        }
      }

    }
  });

  _exports.default = _default;
});