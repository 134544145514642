define("frontend/components/insurance-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isOnAdmin: false,
    actions: {
      onClose() {
        this.get('onClose')();
      },

      onSubmit() {
        this.get('onSubmit')(this.get('insurance'));
      }

    }
  });

  _exports.default = _default;
});