define("frontend/helpers/add-highlight-to-time-box", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addHighlightToTimeBox = addHighlightToTimeBox;
  _exports.default = void 0;

  function addHighlightToTimeBox(_ref) {
    let [windowStartTime, windowEndTime, hour, minute, calendarDate, apptDate] = _ref;

    if (!windowStartTime || !windowEndTime) {
      return '';
    }

    if (calendarDate && apptDate) {
      if ((0, _moment.default)(calendarDate).startOf('day').format() != apptDate.startOf('day').format()) {
        return '';
      }
    }

    let time = (0, _moment.default)(`${hour}:${minute}`, 'H:m');
    let startTime = (0, _moment.default)(windowStartTime, 'h:mmA');
    let endTime = (0, _moment.default)(windowEndTime, 'h:mmA');

    if (time.isSameOrAfter(startTime) && time.isBefore(endTime)) {
      return 'highlighted';
    }
  }

  var _default = Ember.Helper.helper(addHighlightToTimeBox);

  _exports.default = _default;
});