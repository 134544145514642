define("frontend/routes/portal/messages/company", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    currentUser: inject.service(),

    model(_ref) {
      let {
        company_id
      } = _ref;
      let company = this.store.findRecord('company', company_id);

      let settings = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/users/get_messaging_companies`,
        method: 'GET'
      });

      let household = this.get('currentUser.user.household');
      let messages = this.get('infinity').model('message', {
        household_id: household.get('id'),
        company_id,
        perPage: 20,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        company,
        settings,
        messages
      });
    },

    actions: {
      goToAppointmentRequest(requestId, companyId) {
        let queryParams = {
          cid: companyId,
          ids: null,
          requestId,
          redirectedFromMessage: true
        };
        this.transitionTo('portal.appointment-request.summary', {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});