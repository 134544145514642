define("frontend/components/percentage-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: 'percentage-bar',
    label: '',
    value: 0,
    total: 0,
    attributeBindings: ['style'],
    style: computed('value', 'total', function () {
      return `width: ${this.get('value') / this.get('total') * 100}%;`;
    })
  });

  _exports.default = _default;
});