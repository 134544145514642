define("frontend/controllers/edit-staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    loadingAmountToPayByService: false,
    offeredServiceAmounts: computed('model.offered_service_amounts.[]', function () {
      let amounts = this.get('model.offered_service_amounts').map(offeredServiceAmount => {
        return Ember.Object.create(offeredServiceAmount);
      });
      return amounts;
    }),
    actions: {
      cancelEditEmployee() {
        this.send('transitionToStaff', this.get('model.user.id'));
      }

    }
  });

  _exports.default = _default;
});