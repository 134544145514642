define("frontend/components/appointment-list", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group'],
    flashMessage: inject.service(),
    editDisabled: computed.not('showButtonFooter'),
    recurringAppointments: null,
    groupedAppointments: null,
    selectedAppointments: [],
    selectedRecurringAppointments: [],
    selectedAppointmentRequests: [],
    selectedRecurringAddOns: [],
    selectAllRecurringAppointments: false,
    selectAllAppointments: false,
    selectedAllAppointmentRequests: false,
    newOptionId: null,
    recLoading: false,
    apptLoading: false,
    showDeleteConfirmationFlash: false,
    deleteConfirmationMessage: null,
    confirmDeleteAction: null,
    cancelDeleteAction: null,
    appointmentCheckersSelected: {},
    recurringAppointmentCheckersSelected: {},
    appointmentRequestCheckersSelected: {},
    recurringAddOnCheckersSelected: {},
    removeStaff: computed.equal('removeType', 'staff'),
    removeService: computed.equal('removeType', 'service'),
    showLoading: computed('recLoading', 'apptLoading', function () {
      return this.get('recLoading') || this.get('apptLoading');
    }),
    showButtonFooter: computed('selectedRecurringAddOns', 'selectedAppointments', 'selectedRecurringAppointments', 'selectedAppointmentRequests', 'showEditSidePanel', function () {
      let appts = this.get('selectedAppointments');
      let recAppts = this.get('selectedRecurringAppointments');
      let recAddOns = this.get('selectedRecurringAddOns');
      let appointmentRequests = this.get('selectedAppointmentRequests');
      let sidePanel = this.get('showEditSidePanel');
      return (appts.length > 0 || recAppts.length > 0 || appointmentRequests.length > 0 || recAddOns.length > 0) && !sidePanel;
    }),
    editSidePanelSaveDisabled: computed('newOptionId', function () {
      if (this.get('newOptionId')) {
        return false;
      }

      return true;
    }),
    serviceOrAddOn: computed('isAddOn', function () {
      return this.get('isAddOn') ? 'add-on' : 'service';
    }),
    dropdownSelectLabelText: computed('isAddOn', function () {
      if (this.get('isAddOn')) {
        return 'Switch add-on to';
      } else {
        return 'Switch service to';
      }
    }),
    dropdownPlaceholderText: computed('isAddOn', function () {
      if (this.get('isAddOn')) {
        return 'Select add-on';
      } else {
        return 'Select service';
      }
    }),

    showDeleteFlash(message, yesConfirmFunc, noConfirmFunc) {
      this.set('deleteConfirmationMessage', message);
      this.set('confirmDeleteAction', yesConfirmFunc);
      this.set('cancelDeleteAction', noConfirmFunc);
      this.set('showDeleteConfirmationFlash', true);
    },

    hideDeleteFlash() {
      this.set('deleteConfirmationMessage', null);
      this.set('deleteConfirmationAction', null);
      this.set('cancelConfirmationAction', null);
      this.set('showDeleteConfirmationFlash', false);
    },

    actions: {
      selectRecurringAppointment(recurringAppointment) {
        let addOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        let name = addOn ? 'recurring-add-on' : 'recurring-appointment';

        if (recurringAppointment == 'all') {
          let selected = !this.get('selectAllRecurringAppointments');
          this.set('selectAllRecurringAppointments', selected);
          $(`.${name}-checker`).each((_, value) => {
            value.checked = selected;
          });
          let appt_ids = [];

          if (addOn) {
            let recurringAddOns = this.get('recurringAppointmentAddOns');
            recurringAddOns.forEach(appt => {
              appt_ids = appt_ids.concat(appt.ids);
              this.set(`recurringAddOnCheckersSelected.${appt_ids.join('')}`, selected);
            });
            this.set('selectedRecurringAddOns', selected ? appt_ids : []);
          } else {
            let recurring_appts = this.get('recurringAppointments');
            recurring_appts.forEach(appt => {
              appt_ids = appt_ids.concat(appt.ids);
              this.set(`recurringAppointmentCheckersSelected.${appt_ids.join('')}`, selected);
            });
            this.set('selectedRecurringAppointments', selected ? appt_ids : []);
          }
        } else {
          let appt_ids = recurringAppointment.ids;
          let selected;

          if (addOn) {
            selected = !this.get(`recurringAddOnCheckersSelected.${appt_ids.join('')}`);
            let selected_appt_ids = this.get('selectedRecurringAddOns');
            this.set(`recurringAddOnCheckersSelected.${appt_ids.join('')}`, selected);

            if (selected) {
              this.set('selectedRecurringAddOns', selected_appt_ids.concat(appt_ids));
            } else {
              this.set('selectedRecurringAddOns', $.grep(selected_appt_ids, function (value) {
                return $.inArray(value, appt_ids) < 0;
              }));
            }
          } else {
            selected = !this.get(`recurringAppointmentCheckersSelected.${appt_ids.join('')}`);
            let selected_appt_ids = this.get('selectedRecurringAppointments');
            this.set(`recurringAppointmentCheckersSelected.${appt_ids.join('')}`, selected);

            if (selected) {
              this.set('selectedRecurringAppointments', selected_appt_ids.concat(appt_ids));
            } else {
              this.set('selectedRecurringAppointments', $.grep(selected_appt_ids, function (value) {
                return $.inArray(value, appt_ids) < 0;
              }));
            }
          }

          $(`#appointment-list-item--${name}-${appt_ids.join('')}`).each((_, value) => {
            value.checked = selected;
          });
        }
      },

      selectAppointment(selectedAppointment) {
        if (selectedAppointment == 'all') {
          let selected = !this.get('selectAllAppointments');
          this.set('selectAllAppointments', selected);
          $('.appointment-checker').each((_, value) => {
            value.checked = selected;
          });
          let appt_ids = [];
          let appts = this.get('groupedAppointments');
          [].concat(...appts).forEach(appt => {
            if (selected) {
              appt_ids = appt_ids.concat([appt.grouped_appointment.id]);
            }

            this.set(`appointmentCheckersSelected.${appt.grouped_appointment.id}`, selected);
          });
          this.set('selectedAppointments', appt_ids);
        } else {
          let selected = !this.get(`appointmentCheckersSelected.${selectedAppointment.grouped_appointment.id}`);
          let selected_appt_ids = this.get('selectedAppointments');
          let appt_id = selectedAppointment.grouped_appointment.id;
          this.set(`appointmentCheckersSelected.${appt_id}`, selected);

          if (selected) {
            this.set('selectedAppointments', selected_appt_ids.concat([appt_id]));
          } else {
            this.set('selectedAppointments', $.grep(selected_appt_ids, function (value) {
              return value != appt_id;
            }));
          }

          $(`#appointment-list-item--appointment-${appt_id}`).each((_, value) => {
            value.checked = selected;
          });
        }
      },

      selectAppointmentRequest(appointmentRequest) {
        if (appointmentRequest == 'all') {
          let selected = !this.get('selectAllAppointmentRequests');
          this.set('selectAllAppointmentRequests', selected);
          $('.appointment-request-checker').each((_, value) => {
            value.checked = selected;
          });
          let ids = [];
          let requests = this.get('appointmentRequests');
          requests.forEach(request => {
            if (selected) {
              ids.push(request.appointment_request_limited.id);
            }

            this.set(`appointmentRequestCheckersSelected.${request.appointment_request_limited.id}`, selected);
          });
          this.set('selectedAppointmentRequests', ids);
        } else {
          let selected = !this.get(`appointmentRequestCheckersSelected.${appointmentRequest.appointment_request_limited.id}`);
          let selected_appt_ids = this.get('selectedAppointmentRequests');
          let appt_id = appointmentRequest.appointment_request_limited.id;
          this.set(`appointmentRequestCheckersSelected.${appointmentRequest.appointment_request_limited.id}`, selected);

          if (selected) {
            this.set('selectedAppointmentRequests', selected_appt_ids.concat([appt_id]));
          } else {
            this.set('selectedAppointmentRequests', $.grep(selected_appt_ids, function (value) {
              return value != appt_id;
            }));
          }

          $(`#appointment-list-item--appointment-request-${appt_id}`).each((_, value) => {
            value.checked = selected;
          });
        }
      },

      transitionToSingleEdit(groupedAppointment) {
        let ids = groupedAppointment.appointments.map(ga => ga.id).join(',');
        this.transitionToSingleEdit(ids);
      },

      transitionToRecurringEdit(recurringAppointment) {
        let id = recurringAppointment.group_id;
        this.transitionToRecurringEdit(id);
      },

      transitionToRequestEdit(appointmentRequest) {
        this.transitionToRequestEdit(appointmentRequest.appointment_request_limited.id);
      },

      hideDeleteSingleAppointmentFlash() {
        this.hideDeleteFlash();
        this.set('selectedAppointment', null);
        this.set('deleteSingleType', null);
      },

      showDeleteSingleAppointmentFlash(apptType, ids, petName, occurrence) {
        let message;
        this.set('deleteSingleType', apptType);

        if (apptType == 'single') {
          message = `Are you sure you’d like to remove ${petName}'s appointment?`;
        } else {
          message = `Are you sure you’d like to remove ${petName}'s ${occurrence} appointments?`;
        }

        this.set('selectedAppointment', {
          ids,
          petName,
          occurrence
        });
        this.showDeleteFlash(message, 'confirmDeleteSingleAppointment', 'hideDeleteSingleAppointmentFlash');
      },

      showDeleteSingleAppointmentRequestFlash(id, pets) {
        let petNames = _stringUtil.default.joinWithAnd(pets.map(pet => pet.name));

        this.set('selectedAppointmentRequest', {
          id,
          pets
        });
        this.showDeleteFlash(`Are you sure you'd like to remove appointment request for ${petNames}?`, 'confirmDeleteSingleAppointmentRequest', 'hideDeleteAppointments');
      },

      confirmDeleteSingleAppointment() {
        let apptType = this.get('deleteSingleType');
        let selectedAppt = this.get('selectedAppointment');
        this.set('loadingMessage', 'Removing...');

        if (apptType == 'recurring') {
          this.send('deleteRecurringAppointments', selectedAppt.ids);
          this.get('flashMessage').sendFlashMessage(`You’ve successfully removed ${selectedAppt.petName}’s ${selectedAppt.occurrence} appointments`, 'success-love');
        } else if (apptType == 'recurringAddOn') {
          this.send('deleteRecurringAddOns', selectedAppt.ids);
          this.get('flashMessage').sendFlashMessage(`You’ve successfully removed ${selectedAppt.petName}’s ${selectedAppt.occurrence} appointments`, 'success-love');
        } else {
          this.send('deleteMultipleAppointments', this.get('selectedAppointment').ids);
          this.get('flashMessage').sendFlashMessage(`You’ve successfully removed ${selectedAppt.petName}’s  appointment`, 'success-love');
        }

        this.set('selectedAppointment', null);
        this.hideDeleteFlash();
      },

      confirmDeleteSingleAppointmentRequest() {
        let selectedRequest = this.get('selectedAppointmentRequest');

        let petNames = _stringUtil.default.joinWithAnd(selectedRequest.pets.map(pet => pet.name));

        this.set('loadingMessage', 'Removing...');
        this.send('deleteAppointmentRequests', [selectedRequest.id]);
        this.get('flashMessage').sendFlashMessage(`You've successfully removed appointment requests for ${petNames}`, 'success-love');
        this.set('selectedAppointmentRequest', null);
        this.hideDeleteFlash();
      },

      confirmDeleteAppointments() {
        this.set('loadingMessage', 'Removing...');

        if (this.get('isAddOn')) {
          this.send('deleteRecurringAddOns', this.get('selectedRecurringAddOns'));
          this.set('selectedRecurringAddOns', []);
        } else {
          this.send('deleteRecurringAppointments', this.get('selectedRecurringAppointments'));
          this.set('selectedRecurringAppointments', []);
        }

        this.send('deleteMultipleAppointments', this.get('selectedAppointments'));
        this.set('selectedAppointments', []);
        this.send('deleteAppointmentRequests', this.get('selectedAppointmentRequests'));
        this.set('selectedAppointmentRequests', []);
        this.hideDeleteFlash();
        this.set('showEditSidePanel', false);
      },

      confirmEditAppointments() {
        this.set('loadingMessage', 'Hold on!');
        let optionId = this.get('newOptionId');
        let options = this.get('editOptions');
        let [chosenOption] = options.filter(option => option.value == optionId);

        if (this.get('offeredService') && !chosenOption.all_day_service && this.get('offeredService.all_day_service')) {
          this.set('showAllDayErrorMessage', true);
          return;
        }

        this.send('editRecurringAppointments', this.get('selectedRecurringAppointments'), optionId, chosenOption.label);
        this.set('selectedRecurringAppointments', []);
        this.send('editMultipleAppointments', this.get('selectedAppointments'), optionId, chosenOption.label);
        this.set('selectedAppointments', []);
        this.send('editMultipleAppointmentRequests', this.get('selectedAppointmentRequests'), optionId, chosenOption.label);
        this.set('selectedAppointmentRequests', []);
        this.send('editRecurringAddOns', this.get('selectedRecurringAddOns'), optionId, chosenOption.label);
        this.set('selectedRecurringAddOns', []);
        this.set('showEditSidePanel', false);
      },

      deleteRecurringAppointments(ids) {
        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/recurring_appointments/delete_multiple`,
            method: 'DELETE',
            data: {
              ids
            }
          }).done(() => {
            this.send('removeRecurringAppointments', ids);
            this.set('recLoading', false);
            this.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },

      deleteRecurringAddOns(ids) {
        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/recurring_appointments/delete_multiple`,
            method: 'DELETE',
            data: {
              ids,
              offered_service_id: this.get('offeredService.id'),
              is_add_on: true
            }
          }).done(() => {
            this.send('removeRecurringAddOns', ids);
            this.set('recLoading', false);
            this.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },

      deleteMultipleAppointments(ids) {
        if (ids.length > 0) {
          this.set('apptLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v1/appointments/delete_multiple`,
            method: 'DELETE',
            data: {
              grouped_appointment_ids: ids,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(() => {
            this.send('removeAppointments', ids);
            this.set('apptLoading', false);
            this.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },

      deleteAppointmentRequests(ids) {
        if (ids.length > 0) {
          this.set('apptRequestLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/appointment_requests/delete_multiple`,
            method: 'DELETE',
            data: {
              ids,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(() => {
            this.send('removeAppointmentRequests', ids);
            this.set('apptRequestLoading', false);
            this.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointment requests', 'success-love');
          });
        }
      },

      editRecurringAppointments(ids, optionId, optionLabel) {
        if (ids.length > 0) {
          this.set('recLoading', true);
          let url_suffix = '';

          if (this.get('removeStaff')) {
            url_suffix = 'update_multiple_employees';
          } else if (this.get('removeService')) {
            url_suffix = 'update_multiple_offered_services';
          }

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/recurring_appointments/${url_suffix}`,
            method: 'POST',
            data: {
              ids,
              optionId
            }
          }).done(() => {
            this.send('removeRecurringAppointments', ids);
            this.set('recLoading', false);
            this.get('flashMessage').sendFlashMessage(`Well done! You just switched a bunch of appointments to ${optionLabel}`, 'success-love');
          });
        }
      },

      editRecurringAddOns(ids, optionId, optionLabel) {
        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/recurring_appointments/update_multiple_offered_services`,
            method: 'POST',
            data: {
              ids,
              optionId,
              is_add_on: true
            }
          }).done(() => {
            this.send('removeRecurringAddOns', ids);
            this.set('recLoading', false);
            this.get('flashMessage').sendFlashMessage(`Well done! You just switched a bunch of appointments to ${optionLabel}`, 'success-love');
          });
        }
      },

      editMultipleAppointments(ids, optionId, optionLabel) {
        if (ids.length > 0) {
          this.set('apptLoading', true);
          let url_suffix = '';

          if (this.get('removeStaff')) {
            url_suffix = 'update_multiple_employees';
          } else if (this.get('removeService')) {
            url_suffix = 'update_multiple_offered_services';
          }

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v1/appointments/${url_suffix}`,
            method: 'POST',
            data: {
              ids,
              optionId,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(() => {
            this.send('removeAppointments', ids);
            this.set('apptLoading', false);
            this.get('flashMessage').sendFlashMessage(`Well done! You just switched a bunch of appointments to ${optionLabel}`, 'success-love');
          });
        }
      },

      editMultipleAppointmentRequests(ids, optionId, optionLabel) {
        if (ids.length > 0) {
          this.set('apptRequestLoading', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/appointment_requests/update_multiple_offered_services`,
            method: 'POST',
            data: {
              ids,
              optionId,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(() => {
            this.send('removeAppointmentRequests', ids);
            this.get('flashMessage').sendFlashMessage(`Well done! You just switched a bunch of appointment requests to ${optionLabel}`, 'success-love');
            this.set('apptRequestLoading', false);
          });
        }
      },

      removeAppointments(ids) {
        let appts = this.get('groupedAppointments');
        let result = appts.filter(appt => $.inArray(appt.grouped_appointment.id, ids) < 0);
        this.set('groupedAppointments', result);
      },

      removeAppointmentRequests(ids) {
        let requests = this.get('appointmentRequests');
        let result = requests.filter(apptRequest => $.inArray(apptRequest.appointment_request_limited.id, ids) < 0);
        this.set('appointmentRequests', result);
      },

      removeRecurringAppointments(ids) {
        let appts = this.get('recurringAppointments');
        let result = appts.filter(appt => {
          let found_ids = $.grep(appt.ids, function (value) {
            return $.inArray(value, ids) >= 0;
          });
          return found_ids.length == 0;
        });
        this.set('recurringAppointments', result);
      },

      removeRecurringAddOns(ids) {
        let appts = this.get('recurringAppointmentAddOns');
        let result = appts.filter(appt => {
          let found_ids = $.grep(appt.ids, function (value) {
            return $.inArray(value, ids) >= 0;
          });
          return found_ids.length == 0;
        });
        this.set('recurringAppointmentAddOns', result);
      },

      deleteAppointment(id) {
        this.send('deleteMultipleAppointments', [id]);
      },

      hideSidePanel() {
        this.set('showEditSidePanel', false);
      },

      showSidePanel() {
        this.set('showEditSidePanel', true);
      },

      hideDeleteAppointments() {
        this.hideDeleteFlash();
      },

      showDeleteAppointments() {
        let appointmentCount = this.get('selectedAppointments.length') + this.get('selectedRecurringAppointments.length') + this.get('selectedRecurringAddOns.length');
        let requestCount = this.get('selectedAppointmentRequests.length');
        let inflectedText;

        if (appointmentCount > 0) {
          inflectedText = requestCount > 0 ? 'appointments and requests' : 'appointments';
        } else {
          inflectedText = 'requests';
        }

        this.showDeleteFlash(`Are you sure you'd like to remove all selected ${inflectedText}?`, 'confirmDeleteAppointments', 'hideDeleteAppointments');
      }

    }
  });

  _exports.default = _default;
});