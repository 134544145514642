define("frontend/helpers/service-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formattedServiceDate = formattedServiceDate;

  function formattedServiceDate(_ref) {
    let [serviceDate] = _ref;
    return Ember.String.htmlSafe(_moment.default.parseZone(serviceDate).format('M/D'));
  }

  var _default = Ember.Helper.helper(formattedServiceDate);

  _exports.default = _default;
});