define("frontend/components/user-search-searchbar", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'filter': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    filter: null,
    defaultEmail: null,
    staff: false,

    init() {
      this._super(...arguments);

      if (this.get('defaultEmail')) {
        this.set('filter', this.get('defaultEmail'));
        this.send('search');
      }
    },

    didInsertElement() {
      $('#user-search input').focus();
    },

    didDestroyElement() {
      this.set('filter', null);
      this.set('defaultEmail', null);
      this.setEmail(null);
    },

    willRender() {
      this._super(...arguments);

      let filter = this.get('filter');

      if (filter) {
        this.setEmail(filter.trim());
      } else {
        this.setEmail(null);
      }
    },

    placeholder: Ember.computed('staff', function () {
      if (this.get('staff')) {
        return "enter your staff's email";
      } else {
        return "enter your client's email";
      }
    }),
    actions: {
      search() {
        if (this.get('filter')) {
          this.set('filter', this.get('filter').trim());
        }

        if (this.get('validations.isValid')) {
          this.set('searchInputError', null);
          this.search();
        } else {
          this.set('searchInputError', 'search-error');
        }
      }

    }
  });

  _exports.default = _default;
});