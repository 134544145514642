define("frontend/helpers/format-amount", ["exports", "accounting/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatAmount = formatAmount;

  function formatAmount(_ref) {
    let [amount, is_percent] = _ref;

    if (is_percent === true) {
      return `${parseFloat(amount)}%`;
    }

    if (amount % 1 === 0) {
      return (0, _formatMoney.default)(amount, '$', 0);
    }

    return (0, _formatMoney.default)(amount);
  }

  var _default = Ember.Helper.helper(formatAmount);

  _exports.default = _default;
});