define("frontend/components/offered-service-modal", ["exports", "frontend/config/environment", "jquery", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _environment, _jquery, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    classNames: ["add-with-side-panel"],
    offeredService: null,
    colorLines: [["BEDBF3", "FAC9C8", "DBDCDE", "FFE4B4", "A4D9D0", "DEC7FF", "FFC7F2", "FAD8C8"], ["27A9E1", "F05860", "9D9FA1", "FEC23B", "51C1B0", "AB70FF", "FF70D9", "F09958"], ["0077B2", "D31436", "484849", "B77F00", "009D87", "6227B6", "B6278D", "E26503"]],
    capacityTypes: [{
      label: "families at once",
      value: "per_family"
    }, {
      label: "pets at once",
      value: "per_pet"
    }],
    dogPlaceholder: "assets/images/placeholders/Dog.svg",
    appointmentDurationsInfo: inject.service(),
    durationOptions: computed.readOnly("appointmentDurationsInfo.durations"),
    company: null,
    capacitySwitch: computed.notEmpty("offeredService.capacity_type"),

    willDestroyElement() {
      this.set("loading", false);
    },

    didInsertElement() {
      this._super(...arguments);

      const offeredService = this.get("offeredService");
      const isNew = offeredService.isNew;
      this.set("startingAllDaySwitch", this.get("offeredService.all_day_service"));
      const isNonMisc = offeredService.service_category.get("name") !== "Miscellaneous";

      if (isNew && isNonMisc) {
        this.set("offeredService.apply_sales_tax", true);
      }
    },

    priceHasChanged(price) {
      if (price == null) {
        return false;
      }

      let [oldPrice, newPrice] = price;

      if (Ember.isEmpty(oldPrice) && Ember.isEmpty(newPrice)) {
        return false;
      }

      oldPrice = `${oldPrice}`;
      newPrice = `${newPrice}`;
      return oldPrice != newPrice;
    },

    isInvalid: computed.or("offeredService.validations.attrs.price.isInvalid", "offeredService.validations.attrs.duration_in_minutes.isInvalid"),
    durationInMinutes: computed("offeredService.duration_in_minutes", function () {
      if (this.get("offeredService.duration_in_minutes") || this.get("offeredService.duration_in_minutes") == 0) {
        return this.get("durationOptions").filter(duration => duration.value == this.get("offeredService.duration_in_minutes")).get("firstObject");
      }
    }),
    effectiveDateFieldTouched: false,
    showIncrementalPriceError: computed("validated", "offeredService.incremental_price", function () {
      return this.get("validated") && (this.get("offeredService.incremental_price") == null || this.get("offeredService.validations.attrs.incremental_price.isInvalid"));
    }),
    effectiveDateValue: computed("offeredService.price_start_date", function () {
      const momentifiedStartDate = moment(this.get("offeredService.price_start_date"));
      const isFutureDate = momentifiedStartDate > moment();
      if (isFutureDate) return momentifiedStartDate.format("MMMM D, YYYY");
      return '';
    }),
    effectiveDateHelperText: computed("effectiveDateValue", function () {
      const firstPart = "Your new prices will apply starting with each client's first bill that starts on or after";
      const endPart = "Clients with custom pricing for this service will not be affected.";
      const effectiveDateValue = this.get("effectiveDateValue");

      if (effectiveDateValue) {
        const formattedDate = moment(effectiveDateValue).format('MMM D');

        if (!(moment(effectiveDateValue) > moment())) {
          return `${firstPart} ${formattedDate}. We noticed that you selected a date in the past. Please note that your price changes will not affect any bills that have already been sent.`;
        }

        return `${firstPart} ${formattedDate}. ${endPart}`;
      }

      return `${firstPart} your selected date. ${endPart}`;
    }),
    showEffectiveDateField: computed("offeredService.price", "offeredService.incremental_price", "offeredService.isNew", "offeredService.price_start_date", function () {
      const isExistingService = !this.get("offeredService.isNew");
      const changedAttributes = this.get("offeredService").changedAttributes();
      const pricesHaveChanged = this.priceHasChanged(changedAttributes.price) || this.priceHasChanged(changedAttributes.incremental_price);
      const isFutureDate = moment(this.get("offeredService.price_start_date")) > moment();
      if (isFutureDate) return true;
      if (isExistingService && pricesHaveChanged) return true;
      return false;
    }),
    formattedEffectiveDate: computed("offeredService.effectiveDate", function () {
      let effectiveDate = this.get("offeredService.effectiveDate");

      if (effectiveDate) {
        return moment(effectiveDate).format("YYYY-MM-DD");
      }

      return null;
    }),
    userShouldHaveChosenAnEffectiveDateButDidNot: computed("showEffectiveDateField", "effectiveDateValue", function () {
      const showEffectiveDateField = this.get("showEffectiveDateField");
      const effectiveDateValue = this.get("effectiveDateValue");
      return showEffectiveDateField && !effectiveDateValue;
    }),
    effectiveDateBeforeToday: computed("offeredService.effectiveDate", function () {
      let effectiveDate = this.get("offeredService.effectiveDate");

      if (effectiveDate) {
        return moment(effectiveDate) < moment().startOf("day");
      }

      return false;
    }),
    selectedCapacityType: computed("offeredService.capacity_type", function () {
      return this.get("capacityTypes").filter(type => type.value == this.get("offeredService.capacity_type")).get("firstObject");
    }),
    chargeAdditionalRateClassName: computed("offeredService.show_to_pet_parents", function () {
      return this.get("offeredService.show_to_pet_parents") ? "middle-switch" : "bottom-switch";
    }),
    capacitySwitchClassName: computed("capacitySwitch", function () {
      return this.capacitySwitch ? "bottom-switch no-bottom-border" : "bottom-switch";
    }),
    tagToSalesTaxSettings: computed("company.id", function () {
      const id = this.get("company.id");
      return `<a href="companies/${id}/billing/getting-paid">Tap here</a> to update the sales tax % that you need to collect`;
    }),
    actions: {
      cancel() {
        if (this.get("showEffectiveDateCalendar")) {
          this.send("closeEffectiveDateSidePanel");
        } else if (this.get("showSwitchToAllDayModal")) {
          this.send("closeSwitchToAllDayModal");
        } else if (this.get("showAllDayErrorModal")) {
          this.send("closeAllDayErrorModal");
        } else {
          this.cancel();
        }
      },

      save() {
        const effectiveDateValue = this.get('effectiveDateValue');

        if (effectiveDateValue) {
          this.set("offeredService.effectiveDate", effectiveDateValue);
          this.set("offeredService.price_start_date", moment(effectiveDateValue).toDate());
        }

        if (this.get("userShouldHaveChosenAnEffectiveDateButDidNot")) {
          this.set("effectiveDateFieldTouched", true);
        }

        this.set("validated", true);

        if (this.get("isInvalid") || this.get("showIncrementalPriceError") || this.get('userShouldHaveChosenAnEffectiveDateButDidNot')) {
          return;
        }

        if (this.get("offeredService.capacity_type")) {
          let cap = this.get("offeredService.capacity_cap");

          if (!cap) {
            return;
          }

          cap = parseInt(cap);

          if (cap < 1) {
            return;
          }
        }

        if (!this.get("offeredService.isNew") && this.get("offeredService.all_day_service") && !this.get("startingAllDaySwitch")) {
          this.set("loading", true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/offered_services/${this.get("offeredService.id")}/has_any_appointments`,
            method: "GET"
          }).then(response => {
            if (response.has_any_appointments) {
              this.set("showSwitchToAllDayModal", true);
            } else {
              this.save(this.get("offeredService"));
            }

            this.set("loading", false);
          });
        } else {
          this.set("loading", true);
          this.save(this.get("offeredService"));
        }
      },

      delete() {
        this.delete(this.get("offeredService"));
      },

      changeDates(date) {
        this.set("effectiveDateValue", date.format("MMMM D, YYYY"));
        this.set("showEffectiveDateCalendar", false);
      },

      openEffectiveDateSidePanel() {
        this.set("showEffectiveDateCalendar", true);
      },

      closeEffectiveDateSidePanel() {
        this.set("effectiveDateFieldTouched", true);
        this.set("showEffectiveDateCalendar", false);
      },

      setColorHex(colorHex) {
        this.set("offeredService.color_hex", colorHex);
      },

      changeShowToPetParentsSwitch() {
        this.toggleProperty("offeredService.show_to_pet_parents");

        if (!this.get("offeredService.show_to_pet_parents")) {
          this.set("offeredService.capacity_type", null);
        }
      },

      changeApplySalesTaxSwitch() {
        this.toggleProperty("offeredService.apply_sales_tax");
      },

      changeAddOnSwitch() {
        this.toggleProperty("offeredService.is_add_on");
      },

      changeAllDaySwitch() {
        if (this.get("offeredService.all_day_service") && this.get("offeredService.has_any_appointments")) {
          this.set("showAllDayErrorModal", true);
        } else {
          this.toggleProperty("offeredService.all_day_service");

          if (this.get("offeredService.all_day_service")) {
            this.set("offeredService.show_to_pet_parents", false);

            if (!this.get("offeredService.duration_in_minutes")) {
              this.set("offeredService.duration_in_minutes", 0);
              this.set("durationInMinutes", this.get("durationOptions.firstObject"));
            }
          }
        }
      },

      updateSelectedStartTime(time) {
        this.set("offeredService.new_start_time", time);

        if (time) {
          this.set("showSelectedStartTimeError", false);
        }
      },

      closeAllDayErrorModal(turnOff) {
        if (turnOff) {
          if (!this.get("offeredService.new_start_time")) {
            this.set("showSelectedStartTimeError", true);
          } else {
            this.set("offeredService.all_day_service", false);
            this.toggleProperty("showAllDayErrorModal");
          }
        } else {
          this.toggleProperty("showAllDayErrorModal");
        }
      },

      closeSwitchToAllDayModal() {
        this.toggleProperty("offeredService.all_day_service");
        this.toggleProperty("showSwitchToAllDayModal");
      },

      saveFromSwitchAllDay() {
        this.save(this.get("offeredService"));
      },

      updateDuration(duration) {
        if (duration) {
          this.set("offeredService.duration_in_minutes", duration.value);
        } else {
          this.set("offeredService.duration_in_minutes", null);
        }

        this.set("durationInMinutes", duration);
      },

      searchDurations(term) {
        return this.get("durationOptions").filter(duration => {
          return duration.value == term || duration.label.includes(term);
        });
      },

      changeCategory(category) {
        this.set("offeredService.service_category", category);
        this.set("offeredService.show_to_pet_parents", category.get("show_to_pet_parents"));
      },

      changeCapacityType(capacityType) {
        this.set("offeredService.capacity_type", capacityType.value);
      },

      changeCapacitySwitch() {
        if (this.get("offeredService.capacity_type")) {
          this.set("offeredService.capacity_type", null);
        } else {
          let defaultType = this.get("capacityTypes.firstObject.value");
          this.set("offeredService.capacity_type", defaultType);
        }
      }

    }
  });

  _exports.default = _default;
});