define("frontend/routes/edit-client/add-pet", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _cleanupOnTransition, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),

    model() {
      let household = this._super(...arguments);

      return this.store.createRecord('pet', {
        household
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/households/${model.get('household').get('id')}/get_companies`,
        method: 'GET',
        data: {
          id: model.get('household').get('id')
        }
      }).done(response => {
        model.set('company_notes', response.company_notes);
      });
    },

    actions: {
      willTransition() {
        this.rollbackHasMany('controller.model.emergency_contacts');
        this.rollbackHasMany('controller.model.vaccinations');
        this.rollbackHasMany('controller.model.pet_prescriptions');
      },

      save(pet) {
        this.set('saving', true);
        this.get('addClientSaving').savePet(pet).then(() => {
          return this.modelFor('edit-client').reload();
        }).then(() => {
          this.set('saving', false);
          return this.send('transitionBack');
        });
      }

    }
  });

  _exports.default = _default;
});