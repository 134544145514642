define("frontend/controllers/companies/remove-staff", ["exports", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    companies: inject.controller(),
    store: inject.service(),
    router: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('companies.saving'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    showReactivateContainer: true,
    employee: computed('model.employee_id', function () {
      let id = this.get('model.employee_id');
      let promise = this.get('store').find('employee', id);
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    employeeName: computed('model.employee_user', function () {
      let employee = this.get('model.employee_user');
      return `${employee.first_name} ${employee.last_name}`;
    }),
    employeeAvatar: computed('model.employee_user.avatar.thumb.url', function () {
      let avatarUrl = this.get('model.employee_user.avatar.thumb.url');
      return avatarUrl ? avatarUrl : 'assets/images/staff/placeholder-human-image@3x.png';
    }),
    employeeOptions: computed('model.employees', function () {
      let employees = this.get('model.employees');
      employees = employees.filter(employee => employee.id != this.get('model.employee_id'));
      return $.map(employees, function (employee) {
        return {
          label: employee.name,
          value: employee.id
        };
      });
    }),
    hasAppointments: computed('model.grouped_appointments', 'model.recurring_appointments', function () {
      let appts = this.get('model.grouped_appointments');
      let rec_appts = this.get('model.recurring_appointments');
      return appts.length > 0 || rec_appts.length > 0;
    }),
    actions: {
      transitionToStaff() {
        this.transitionToRoute('staff').then(() => {
          window.location.reload();
        });
      },

      transitionToSingleEdit(id) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.employee_id'),
          ids: [id]
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams
        });
      },

      transitionToRecurringEdit(id) {
        let queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.employee_id')
        };
        this.transitionToRoute('edit-recurring-appointment-group.index', id, {
          queryParams
        });
      },

      remove() {
        this.set('saving', true);
        this.get('employee').get('content').destroyRecord().then(() => {
          return this.transitionToRoute('staff').then(() => {
            this.set('saving', false);
            this.get('flashMessage').sendFlashMessage(`You’ve successfully removed ${this.get('employeeName')} as a member of your team`, 'success-love');
            window.location.reload();
          });
        });
      },

      cancel() {
        this.transitionToRoute('staff');
      },

      clickDeactivate() {
        this.set('showDeactivateFlash', true);
      },

      hideDeactivate() {
        this.set('showDeactivateFlash', false);
      },

      clickReactivate() {
        this.set('showReactivateFlash', true);
      },

      hideReactivate() {
        this.set('showReactivateFlash', false);
      },

      closeReactivateContainer() {
        this.set('showReactivateContainer', false);
      },

      confirmReactivate() {
        this.set('showReactivateFlash', false);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/employees/${this.get('model.employee_id')}/reactivate`,
          method: 'POST'
        }).done(() => {
          this.get('flashMessage').sendFlashMessage(`You’ve successfully reactivated ${this.get('employeeName')}'s account`, 'success-love');
          this.set('model.employee_deactivated', false);
        });
      },

      confirmDeactivate() {
        this.set('showDeactivateFlash', false);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/employees/${this.get('model.employee_id')}/deactivate`,
          method: 'POST'
        }).done(() => {
          this.get('flashMessage').sendFlashMessage(`You’ve successfully deactivated ${this.get('employeeName')}'s account`, 'success-love');
          this.set('model.employee_deactivated', true);
        });
      }

    }
  });

  _exports.default = _default;
});