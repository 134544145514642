define("frontend/components/bill-delivery-modal", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    actions: {
      onClose() {
        this.get('onClose')();
      },

      onSubmit() {
        this.get('onSubmit')(this.get('selectedEmail'));
      }

    }
  });

  _exports.default = _default;
});