define("frontend/routes/edit-client/remove-household", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    router: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    actions: {
      remove() {
        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        let household = this.get('controller.model');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.id}/remove_company`,
          method: 'POST'
        }).always(() => {
          window.location.href = '/client-list';
        });
      },

      cancel() {
        this.transitionTo('client-list.household.household-user', this.get('controller.model'), this.get('controller.model.owner'));
      }

    }
  });

  _exports.default = _default;
});