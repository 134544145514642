define("frontend/transforms/date", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize(serialized) {
      return serialized;
    },

    serialize(deserialized) {
      deserialized = deserialized || '';
      let matchDate = deserialized.match(/\d{2}\/\d{2}\/\d{4}/);

      if (matchDate) {
        return deserialized;
      } else {
        return null;
      }
    }

  });

  _exports.default = _default;
});