define("frontend/routes/portal/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    currentUser: inject.service(),

    redirect() {
      this.transitionTo('portal.appointments');
    }

  });

  _exports.default = _default;
});