define("frontend/controllers/add-client/client-search", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['householdId', 'email'],
    flashMessage: inject.service(),
    currentUser: inject.service(),
    householdId: null,
    email: null,
    showInviteClient: false,
    actions: {
      setEmail(email) {
        this.set('filter', email);
      },

      search() {
        this.set('searchNow', true);
      },

      transitionToPrimary(searchedEmail) {
        this.transitionToRoute('add-client.primary-contact', {
          queryParams: {
            householdId: null,
            searchedEmail
          }
        });
      },

      transitionToClientList() {
        this.transitionToRoute('client-list');
      },

      triggerInviteClient() {
        this.set('searchedEmail', this.get('filter'));
        this.set('showInviteClient', true);
      },

      closeInviteClient() {
        this.set('showInviteClient', false);
      },

      inviteClient(email, firstName, lastName, emailType) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/invite_client`,
          method: 'POST',
          data: {
            email,
            firstName,
            lastName,
            emailType
          }
        }).done(response => {
          const directToDocumentsPage = this.get('currentUser.user.company.client_contracts').content.length > 0;
          this.send('redirectToAddedUser', response.household_id, directToDocumentsPage);
          this.set('showInviteClient', false);
          this.get('flashMessage').success(`Invite sent to ${email}`);
        });
      }

    }
  });

  _exports.default = _default;
});