define("frontend/routes/sign-up/index", ["exports", "frontend/mixins/scroll-top"], function (_exports, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTop.default, {
    setupController(controller) {
      controller.set('request', {});
      controller.set('submitted', false);
    }

  });

  _exports.default = _default;
});