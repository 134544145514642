define("frontend/models/email", ["exports", "ember-data", "frontend/utils/email-type"], function (_exports, _emberData, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    errs: _emberData.default.attr(),
    address: _emberData.default.attr('trimmed-string'),
    email_type: _emberData.default.attr('string', {
      defaultValue: _emailType.default.ALTERNATE
    }),
    displayType: computed('email_type', function () {
      if (this.get('isAlternate')) {
        return 'Alternate';
      }

      if (this.get('isPreferred')) {
        return 'Preferred';
      }
    }),
    isAlternate: computed.equal('email_type', _emailType.default.ALTERNATE),
    isPreferred: computed.equal('email_type', _emailType.default.PREFERRED)
  });

  _exports.default = _default;
});