define("frontend/routes/client-list/household/pet", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model(params) {
      let {
        pet_id
      } = params;
      return this.store.findRecord('pet', pet_id);
    },

    setupController(controller) {
      this._super(...arguments);

      if (controller.get('hasNotes')) {
        controller.set('selectedSection', 'notes');
      } else if (controller.get('hasBehavioral')) {
        controller.set('selectedSection', 'behavioral');
      } else {
        controller.set('selectedSection', 'medical');
      }
    }

  });

  _exports.default = _default;
});