define("frontend/components/document-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['document-list'],
    tagName: 'table',
    combinedDocuments: computed('documents.[]', 'signatureRequests.[]', function () {
      let documents = this.get('documents');
      let signatureRequests = this.get('signatureRequests');
      let pendingSignatureRequests = signatureRequests.filterBy('status', 'pending');
      let completedSignatureRequests = signatureRequests.reject(request => request.status == 'pending');
      let combined = documents.concat(completedSignatureRequests);
      let sorted = combined.sortBy('last_activity_date').reverse();
      return pendingSignatureRequests.concat(sorted);
    }),
    actions: {
      remove() {
        this.remove(...arguments);
      }

    }
  });

  _exports.default = _default;
});