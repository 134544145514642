define("frontend/components/invoice-frequency-effective-date-modal", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    timePeriodClarify: '',
    billOptions: computed('billingPeriodStartDate', 'billingPeriodEndDate', 'nextBillingPeriodStartDate', 'nextBillingPeriodEndDate', function () {
      let currentStartDate = moment(this.get('billingPeriodStartDate')).format('M/D');
      let currentEndDate = moment(this.get('billingPeriodEndDate')).format('M/D');
      let currentBillingPeriod = `Current bill (${currentStartDate} - ${currentEndDate})`;
      let nextStartDate = moment(this.get('nextBillingPeriodStartDate')).format('M/D');
      let nextEndDate = moment(this.get('nextBillingPeriodEndDate')).format('M/D');
      let nextBillingPeriod = `Next bill (${nextStartDate} - ${nextEndDate})`;
      return [{
        label: currentBillingPeriod,
        value: 'current'
      }, {
        label: nextBillingPeriod,
        value: 'next'
      }];
    }),
    selectedBillOption: computed('billOptions', function () {
      return this.get('billOptions.firstObject');
    }),
    helperText: computed('selectedBillOption', 'loadingStartBillingDate', function () {
      if (this.get('loadingStartBillingDate')) {
        return;
      }

      if (this.get('selectedBillOption').value == 'current') {
        let startDate = this.get('newBillingPeriodStartDate');
        let endDate = this.endBillingDate(moment());
        let currentEndDate = moment(this.get('billingPeriodEndDate')).format('M/D');

        if (endDate == currentEndDate) {
          return `Your current billing period will still end on ${moment(this.get('billingPeriodEndDate')).format('dddd M/D')}, and future bills will be issued ${this.get('timePeriodClarify')}.`;
        } else {
          return `Your current billing period will change to ${startDate.format('M/D')} - ${this.endBillingDate(moment())}, and future bills will close ${this.get('timePeriodClarify')}.`;
        }
      } else {
        let startDate = moment(this.get('nextBillingPeriodStartDate'));
        return `Your next billing period will change to ${startDate.format('M/D')} - ${this.endBillingDate(startDate)}, and future bills will close ${this.get('timePeriodClarify')}. The current bill will not be affected.`;
      }
    }),

    endBillingDate(startDate) {
      switch (this.get('invoiceFrequency')) {
        case 'monthly':
          return startDate.endOf('month').format('M/D');

        case 'semimonthly':
          if (parseInt(startDate.format('D')) <= 14) {
            return startDate.startOf('month').add('13', 'days').format('M/D');
          }

          return startDate.endOf('month').format('M/D');

        case 'biweekly':
          {
            let endDate = moment(this.get('company.billing_period')).add('2', 'weeks').subtract('1', 'day');

            if (endDate < startDate) {
              return endDate.add('2', 'weeks').format('M/D');
            }

            return endDate.format('M/D');
          }

        case 'weekly':
          return startDate.endOf('isoweek').format('M/D');

        case 'daily':
          return startDate.endOf('day').format('M/D');

        default:
          return null;
      }
    },

    actions: {
      changeBillOption(billOption) {
        this.set('selectedBillOption', billOption);
      },

      onClose() {
        if (this.get('saving')) {
          return;
        }

        this.get('onClose')();
      },

      onSubmit() {
        this.set('saving', true);
        let householdId = this.get('household.id');
        let company_id = this.get('company.id');
        let invoice_frequency = this.get('invoiceFrequency');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/update_invoice_frequency`,
          method: 'POST',
          data: {
            invoice_frequency,
            company_id,
            billing_period: this.get('selectedBillOption.value')
          }
        }).then(() => {
          this.set('saving', false);
          this.get('onClose')(true);
        }).fail(() => {
          this.get('flashMessage').error('Oops! Something went wrong.');
          this.set('saving', false);
        });
      }

    }
  });

  _exports.default = _default;
});