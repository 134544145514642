define("frontend/helpers/get-offered-service-option", ["exports", "frontend/helpers/truncate-text"], function (_exports, _truncateText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getOfferedServiceOption = getOfferedServiceOption;

  function getOfferedServiceOption(_ref) {
    let [offeredService] = _ref;

    if (!offeredService) {
      return;
    }

    let label = `${(0, _truncateText.truncateText)([offeredService.get('name'), 40])} (${offeredService.get('durationDisplay')})`;
    return {
      value: offeredService,
      label
    };
  }

  var _default = Ember.Helper.helper(getOfferedServiceOption);

  _exports.default = _default;
});