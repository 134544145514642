define("frontend/controllers/edit-client/pet", ["exports", "frontend/controllers/edit-client/edit-client-page-base", "frontend/utils/helpers"], function (_exports, _editClientPageBase, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _editClientPageBase.default.extend({
    application: inject.controller(),
    flashMessage: inject.service(),
    appointmentsController: inject.controller('client-list.household'),
    currentSection: 'basic',
    household: computed.readOnly('model.household'),
    canRemove: false,
    actions: {
      redirectToRemovePet(pet) {
        this.transitionToRoute('edit-client.remove-pet', pet);
      },

      notifyUserPetCannotBeRemoved() {
        const {
          subjectUpper,
          pronoun,
          verb
        } = (0, _helpers.getPetGrammaticalDetails)(this.model);
        const message = `${subjectUpper} can't be removed because ${pronoun} ${verb} appointments with another care provider`;
        this.get('flashMessage').sendFlashMessage(message, 'user-error');
      },

      uploadedPhoto(photo) {
        this.set('model.pet.avatar_url', photo);
      }

    }
  });

  _exports.default = _default;
});