define("frontend/helpers/has-custom-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasCustomPrice = hasCustomPrice;

  function hasCustomPrice(_ref) {
    let [household, offeredService] = _ref;
    return household.get('offered_service_client_prices').find(oscp => {
      return oscp.get('offered_service.id') === offeredService.get('id');
    });
  }

  var _default = Ember.Helper.helper(hasCustomPrice);

  _exports.default = _default;
});