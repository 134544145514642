define("frontend/components/custom-pricing-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    offeredService: computed.readOnly('editingPrice.offeredService'),
    clientPrice: computed.alias('editingPrice.offeredServiceClientPrice'),
    actions: {
      onClose() {
        if (this.get('clientPrice')) {
          this.get('clientPrice').rollbackAttributes();
        }

        this.get('onClose')();
      },

      editPrice(offeredServicePrice) {
        this.set('editingPrice', offeredServicePrice); // existingPrice is used to destroy when we reset prices

        this.set('existingPrice', this.get('clientPrice'));
        let price = this.get('offeredService.price');
        let incremental_price = this.get('offeredService.incremental_price');
        let effective_date = 'current';

        if (this.get('clientPrice')) {
          price = this.get('clientPrice.price');
          incremental_price = this.get('clientPrice.incremental_price');
          effective_date = this.get('clientPrice.effective_date');
        }

        offeredServicePrice.set('offeredServiceClientPrice', this.get('store').createRecord('offered-service-client-price', {
          price,
          incremental_price,
          effective_date,
          offered_service: this.get('offeredService'),
          household: this.get('household'),
          existingPrice: this.get('existingPrice')
        }));
      },

      closeEditPrice() {
        this.get('clientPrice').rollbackAttributes();
        this.set('editingPrice', null);
      },

      resetPrices() {
        this.set('saving', true);
        this.get('clientPrice').rollbackAttributes();
        this.get('existingPrice').destroyRecord().then(() => {
          return this.get('household.content').reload();
        }).finally(() => {
          this.set('saving', false);
        });
        this.set('editingPrice', null);
      },

      resetAllPrices() {
        this.set('saving', true);
        let promises = [];
        this.get('household.offered_service_client_prices').forEach(price => {
          promises.pushObject(price.destroyRecord());
        });
        Ember.RSVP.Promise.all(promises).then(() => {
          return this.get('household.content').reload();
        }).finally(() => {
          this.set('saving', false);
        });
      },

      savePrice() {
        let clientPrice = this.get('clientPrice'); // Don't allow creation of a new custom price for the current offered service price

        if (this.get('clientPrice.price') === this.get('offeredService.price') && this.get('clientPrice.incremental_price') === this.get('offeredService.incremental_price') && this.get('clientPrice.isNew')) {
          clientPrice.rollbackAttributes();
        } else {
          this.set('saving', true);
          clientPrice.save().then(() => {
            return this.get('household.content').reload();
          }).finally(() => {
            this.set('saving', false);
          });
        }

        this.set('editingPrice', null);
      }

    }
  });

  _exports.default = _default;
});