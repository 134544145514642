define("frontend/components/invite-client-modal", ["exports", "frontend/utils/email-type"], function (_exports, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;
  const EMAIL_REGEX = /.+@.+\..+/;
  const DUPLICATE_EMAIL_ERROR = 'This email already exists!';

  var _default = Ember.Component.extend({
    firstName: null,
    lastName: null,
    email: null,
    duplicateEmail: inject.service(),
    emailErrors: [],
    emailType: _emailType.default.PREFERRED,
    emailValidation: [{
      message: '*must be a valid email',
      validate: inputValue => {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    actions: {
      onClose() {
        this.get('onClose')();
      },

      onSubmit() {
        this.set('checkingEmail', true);
        let email = this.get('email');
        let firstName = this.get('firstName');
        let lastName = this.get('lastName');
        let emailType = this.get('emailType');
        this.get('duplicateEmail').check(email, null).then(response => {
          if (response.duplicate) {
            this.set('emailErrors', [DUPLICATE_EMAIL_ERROR]);
            this.set('emailIsTouched', true);
          } else {
            this.set('emailErrors', []);
            this.get('onSubmit')(email, firstName, lastName, emailType);
          }
        }).finally(() => {
          this.set('checkingEmail', false);
        });
      }

    }
  });

  _exports.default = _default;
});