define("frontend/components/img-inner-border", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['img-inner-border'],
    src: '',
    borderHex: 'FFF',
    tooltipText: null,
    tooltipColor: 'yellow',
    borderWidth: '2px',
    myStyle: computed('borderWidth', 'borderHex', function () {
      return Ember.String.htmlSafe(`border: ${this.get('borderWidth')} solid #${this.get('borderHex')};`);
    })
  });

  _exports.default = _default;
});