define("frontend/mixins/age-calculator", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    calculateAge(birthday) {
      let years = (0, _moment.default)().diff((0, _moment.default)(birthday), 'years');

      if (years <= 0) {
        let months = (0, _moment.default)().diff(birthday, 'months');

        if (months === 1) {
          return '1 month old';
        }

        return `${months} months old`;
      } else {
        if (years === 1) {
          return '1 year old';
        }

        return `${years} years old`;
      }
    }

  });

  _exports.default = _default;
});