define("frontend/controllers/edit-client/remove-pet", ["exports", "frontend/utils/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    familyName: computed.readOnly('model.household.owner.last_name'),
    currentUser: inject.service(),
    futureAppointments: null,
    title: computed('futureAppointments', 'model', function () {
      const {
        subject,
        subjectUpper
      } = (0, _helpers.getPetGrammaticalDetails)(this.get('model'));

      if (this.get('futureAppointments')) {
        return `${subjectUpper} still has upcoming appointments!`;
      } else {
        return `Are you sure you would like to remove ${subject}?`;
      }
    }),
    clarification: computed('currentUser.user.isPetParent', 'futureAppointments', 'model.name', 'familyName', function () {
      const {
        subject,
        possesivePronounUpper,
        possesivePronoun
      } = (0, _helpers.getPetGrammaticalDetails)(this.get('model'));
      const futureAppointments = this.get('futureAppointments');
      const petName = this.get('model.name');
      const familyName = this.get('familyName');

      if (this.get('currentUser.user.isPetParent')) {
        if (futureAppointments) {
          return `Removing ${petName} from your family profile will also <b class="bold">remove all of ${possesivePronoun} future appointments</b>. Are you sure you'd like to proceed?`;
        } else {
          return `This will remove ${subject} from your family profile, but past appointments will remain on your schedule and in your billing history.`;
        }
      } else {
        const base = `This will remove ${subject} from the ${familyName} family profile`;

        if (futureAppointments) {
          return `${base} as well as <b class="bold">all future appointments for ${subject}</b>. ${possesivePronounUpper} past appointments will remain on your schedule and in your billing history. Are you sure you’d like to proceed?`;
        } else {
          return `${base}, but past appointments will remain on your schedule and in your billing history.`;
        }
      }
    }),
    removeButtonText: computed('futureAppointments', function () {
      return this.get('futureAppointments') ? 'remove anyway' : 'remove';
    })
  });

  _exports.default = _default;
});