define("frontend/utils/date-util", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    inFuture(date) {
      return (0, _moment.default)(date).startOf('day').isAfter((0, _moment.default)().endOf('day'));
    }

  };
  _exports.default = _default;
});