define("frontend/components/schedule/index/services-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showChangeCareProvider: false,
    staff: [],
    classNameBindings: ['showChangeCareProvider'],
    searchFilter: null,
    showCanceledAppointments: false,
    highlightWindowStart: null,
    highlightWindowEnd: null,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).resize(() => {
        this.setLastElement();
      });
      Ember.run.scheduleOnce('afterRender', () => {
        this.setLastElement();
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).off('resize');
    },

    // This is a hack for drag and drop. When you drag a dog over another dog it causes an appointment
    // to flow over to a new line sometimes. If you're trying to drag over the appointment that went to
    // the new line drag and drop will spaz out. This sets a class on the last element in the service
    // line so that it does not flow to a new line.
    // It's called when:
    // - An appointment is moved successfully
    // - The screen is resized
    // - An appointment starts to move
    setLastElement() {
      let petItems = (0, _jquery.default)(this.element.querySelectorAll('.pet-item'));

      if (!petItems) {
        return;
      }

      petItems.each((index, petItem) => {
        petItem = (0, _jquery.default)(petItem);

        if (petItem.offset().left > (0, _jquery.default)('.pets').offset().left + (0, _jquery.default)('.pets').width() - 100) {
          petItem.addClass('last-element-on-line');
        } else {
          petItem.removeClass('last-element-on-line');
        }
      });
    },

    allDayServices: Ember.computed('services.[]', 'services.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', function () {
      let services = this.get('services').sortBy('starts_at', 'care_provider.fullName');
      return services.filter(service => {
        // Trigger for ember computed
        service.get('sortedCanceledGroupedAppointmentsIds');
        service.get('sortedGroupedAppointmentsWithoutCanceledIds');
        let allDayService = service.get('all_day_service');

        if (this.get('showCanceledAppointments')) {
          return allDayService && service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return allDayService && service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    }),
    timedServices: Ember.computed('services.[]', 'services.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', function () {
      let services = this.get('services').sortBy('starts_at', 'care_provider.fullName');
      return services.filter(service => {
        // Trigger for ember computed
        service.get('sortedCanceledGroupedAppointmentsIds');
        service.get('sortedGroupedAppointmentsWithoutCanceledIds');
        let allDayService = service.get('all_day_service');

        if (this.get('showCanceledAppointments')) {
          return !allDayService && service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return !allDayService && service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    }),
    actions: {
      clickAppointment(appointment) {
        this.get('clickAppointment')(appointment);
      },

      startDraggingPet(groupedAppointment) {
        this.get('startDraggingPet')(groupedAppointment);
      },

      stopDraggingPet() {
        this.get('stopDraggingPet')();
      },

      setLastElement() {
        this.setLastElement();
      },

      changeCareProvider() {
        this.get('changeCareProvider')();
      },

      queueGroupedAppointment(groupedAppointment) {
        this.queueGroupedAppointment(groupedAppointment);
      },

      startGroupedReportCardQueue() {
        this.startGroupedReportCardQueue();
      }

    }
  });

  _exports.default = _default;
});