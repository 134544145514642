define("frontend/services/account-session", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Service.extend({
    currentUser: inject.service(),
    session: inject.service(),
    cookies: inject.service(),

    switchAccounts(new_company_id) {
      let redirectHref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      this.get('cookies').write('ccontrol_id', new_company_id, {
        path: '/',
        maxAge: 31536000
      });
      let store = this.get('session.store');
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/users/${this.get('currentUser.user.id')}/change_current_company`,
        method: 'POST',
        data: {
          new_company_id
        }
      }).done(response => {
        store.clear().then(() => {
          return store.persist(response).then(() => window.location.href = redirectHref);
        });
      });
    },

    hasCompany() {
      return this.get('cookies').exists('ccontrol_id');
    },

    getCurrentCompanyId() {
      return this.get('cookies').read('ccontrol_id');
    },

    clearCompany() {
      this.get('cookies').clear('ccontrol_id', {
        path: '/'
      });
    },

    clearCompanyWithoutRefresh() {
      sessionStorage.setItem('cancelRefreshOnCookieChange', true);
      this.clearCompany();
    },

    isPetParent() {
      return this.get('cookies').read('ccontrol_id') == '0';
    },

    signOut() {
      this.get('cookies').clear('ccontrol_id', {
        path: '/'
      });
      return this.get('session').invalidate();
    }

  });

  _exports.default = _default;
});