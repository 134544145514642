define("frontend/routes/portal", ["exports", "frontend/routes/base-route", "jquery"], function (_exports, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    session: inject.service(),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    queryParams: {
      signContract: {
        refreshModel: true
      },
      apptRqstCtgryID: {
        refreshModel: true
      }
    },

    model(params) {
      if (this.get('currentUser.user')) {
        let url = `/api/v2/households/${this.get('currentUser.user.household.id')}/in_house_signature_requests`;

        if (params.signContract) {
          url += `?request_to_sign=${params.signContract}`;
        }

        return _jquery.default.ajax({
          url: url,
          method: 'GET'
        }).then(response => {
          return Ember.RSVP.hash({
            inhouseSignatures: response.signature_requests,
            allCategoriesRequireSigning: response.all_categories_require_signing
          });
        });
      }
    },

    redirect() {
      this._super(...arguments);

      let user = this.get('currentUser.user');

      if (!user) {
        this.transitionTo('users.login');
      }
    },

    actions: {
      refreshModel() {
        this.refresh();
      },

      showSignContractsModalForSpecificCategory(categoryID) {
        this.set('signatureRequestCategoryID', categoryID);
        let controller = this.controllerFor('portal');
        controller.set('showSignContractsModal', true);
        controller.set('filterSignatureRequestsWithCategoryID', parseInt(categoryID));
      },

      showSignContractsModal() {
        controller.set('showSignContractsModal', true);
      }

    }
  });

  _exports.default = _default;
});