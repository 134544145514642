define("frontend/helpers/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;

  function formatDate(_ref) {
    let [date, format, single] = _ref;

    if (single === true) {
      return _moment.default.parseZone(date).clone().format(format).charAt(0);
    } else {
      return _moment.default.parseZone(date).clone().format(format);
    }
  }

  var _default = Ember.Helper.helper(formatDate);

  _exports.default = _default;
});