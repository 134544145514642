define("frontend/controllers/edit-client/primary-contact", ["exports", "frontend/controllers/edit-client/edit-client-page-base"], function (_exports, _editClientPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _editClientPageBase.default.extend({
    session: inject.service(),
    accountSession: inject.service(),
    valid: computed.readOnly('model.validations.isValid'),
    anySidePanelActive: computed.alias('editClient.anySidePanelActive'),
    application: Ember.inject.controller(),
    editingNotificationSettings: false,
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    actions: {
      uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      },

      clickEditNotifications() {
        this.set('editingNotificationSettings', true);
      },

      closeEditNotifications() {
        this.set('editingNotificationSettings', false);
      }

    }
  });

  _exports.default = _default;
});