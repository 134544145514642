define("frontend/services/duplicate-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Service.extend({
    ajax: inject.service(),

    check(emailAddress, household) {
      let url = '/api/v2/email/duplicate_check';
      let data = {
        email: emailAddress
      };

      if (household && household.get('id')) {
        data['household'] = household.get('id');
      }

      return this.get('ajax').request(url, {
        method: 'POST',
        data
      });
    }

  });

  _exports.default = _default;
});