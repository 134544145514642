define("frontend/controllers/portal/billing", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['addedClient', 'oauth_state_id'],
    oauth_state_id: null,
    oauthStatePresent: computed.notEmpty('oauth_state_id'),
    plaidCookiePresent: computed.notEmpty('plaidCookie'),
    plaidRedirect: computed.and('oauthStatePresent', 'plaidCookiePresent'),
    addedClient: false,
    currentUser: inject.service(),
    stripeInfo: inject.service(),
    flashMessage: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    cookies: inject.service(),
    plaidCookie: computed('cookies', function () {
      return this.get('cookies').read('plaidLinkToken');
    }),
    ownerUserId: computed('household', 'household.owner', function () {
      if (this.get('household.owner')) {
        return this.get('household.owner').get('id');
      }

      return null;
    }),
    companyBillingSettings: computed('ownerUserId', function () {
      let userId = this.get('ownerUserId');

      if (userId) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/get_client_companies`,
          method: 'GET'
        }).then(response => {
          this.set('companyBillingSettings', response);
        });
      }

      return null;
    }),
    companiesWithSettings: computed('activeCompanies.[]', function () {
      let billingSettings = this.get('activeCompanies');

      if (billingSettings) {
        return $.map(billingSettings, companySettings => {
          return companySettings.company_name;
        });
      }

      return [];
    }),
    selectedCompanySettings: computed('activeCompanies.[]', function () {
      let companySettings = this.get('activeCompanies');

      if (companySettings && companySettings.length > 0) {
        let settings = companySettings.sortBy('added_on').reverse();
        return settings.get('firstObject');
      }

      return null;
    }),
    activeCompanies: computed('companyBillingSettings.[]', function () {
      let companySettings = this.get('companyBillingSettings.settings');

      if (companySettings) {
        return companySettings.filter(settings => !settings.company_deactivated && !settings.denied_request && settings.pet_parents_activated || settings.staff_status == 'Owner');
      }

      return [];
    }),
    actions: {
      changeCompany(companyName) {
        let companySettings = this.get('companyBillingSettings');
        let settings = companySettings.settings.find(s => {
          return s.company_name == companyName;
        });
        this.set('selectedCompanySettings', settings);
      },

      closeSuccessModal() {
        this.set('addedClient', false);
      },

      redirectToMicrodeposits() {
        this.transitionToRoute('microdeposits', this.get('currentUser.user.emd5'));
      }

    }
  });

  _exports.default = _default;
});