define("frontend/helpers/invoice-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formattedInvoiceDate = formattedInvoiceDate;

  function formattedInvoiceDate(_ref) {
    let [date] = _ref;
    return Ember.String.htmlSafe(_moment.default.parseZone(date).format('MMM D'));
  }

  var _default = Ember.Helper.helper(formattedInvoiceDate);

  _exports.default = _default;
});