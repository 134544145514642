define("frontend/services/error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessage: Ember.inject.service(),

    handle(errorObj) {
      if (errorObj.errors) {
        let message = errorObj.errors.map(error => {
          if (error.source) {
            let {
              source: {
                pointer
              }
            } = error;
            let [,,, modelType] = pointer.split('/');

            if (modelType === 'summary') {
              return error.detail;
            } else {
              return `${modelType} ${error.detail}`;
            }
          } else {
            return error.title;
          }
        }).join(', ');

        if (message === 'Error connecting to the server.') {
          this.get('flashMessage').errorWithRefresh(message);
        } else {
          this.get('flashMessage').error(message);
        }
      }
    }

  });

  _exports.default = _default;
});