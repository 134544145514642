define("frontend/components/google-map", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['google-map'],
    classNameBindings: ['hidden'],
    googleMapsGeocode: Ember.inject.service(),
    zoom: 15,
    disableDefaultUI: false,
    lat: null,
    lng: null,
    markerLat: null,
    markerLng: null,
    addressUrl: null,
    href: Ember.computed('address', function () {
      if (this.get('address')) {
        let address = encodeURIComponent(this.get('address'));
        return `https://maps.google.com?q=${address}`;
      }
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (_environment.default.environment === 'development') {
        return;
      }

      if (this.get('addressUrl')) {
        return;
      }

      if (this.get('lat') != null && this.get('lng') != null) {
        Ember.run.scheduleOnce('afterRender', () => {
          this.renderMap(this.get('lat'), this.get('lng'), this.get('markerLat'), this.get('markerLng'));
        });
      } else if (this.get('address')) {
        this.renderMapWithAddress(this.get('address'));
      }
    },

    renderMapWithAddress(address) {
      this.set('hidden', true);
      this.get('googleMapsGeocode').geocode(address).then(response => {
        Ember.run.scheduleOnce('afterRender', () => {
          if (this.isDestroyed) {
            return;
          }

          let {
            location
          } = response;
          let lat = location.lat();
          let lng = location.lng();
          this.renderMap(lat, lng);
        });
        this.set('hidden', false);
      });
    },

    renderMap(lat, lng) {
      let markerLat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let markerLng = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let center = new google.maps.LatLng(lat, lng);
      let map = new google.maps.Map((0, _jquery.default)(this.element)[0], {
        zoom: this.get('zoom'),
        center,
        zoomControl: false,
        scrollwheel: false,
        disableDefaultUI: this.get('disableDefaultUI')
      });
      let position = center;

      if (markerLat && markerLng) {
        position = new google.maps.LatLng(markerLat, markerLng);
      }

      new google.maps.Marker({
        map,
        position
      });
    }

  });

  _exports.default = _default;
});