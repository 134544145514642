define("frontend/components/remove-service-category-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      proceed() {
        this.get('save')();
      },

      cancel() {
        this.get('cancel')();
      },

      onClose() {}

    }
  });

  _exports.default = _default;
});