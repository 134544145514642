define("frontend/controllers/client-list/household/pet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    clientPending: computed.alias('model.household.isPendingStatus'),
    householdController: inject.controller('client-list.household'),
    notesActive: computed.readOnly('householdController.notesActive'),
    medicalActive: computed.readOnly('householdController.medicalActive'),
    behavioralActive: computed.readOnly('householdController.behavioralActive'),
    hasBehavioral: computed.readOnly('model.hasBehavioral'),
    hasNotes: computed.readOnly('model.hasNotes'),
    hasMedical: computed.readOnly('model.hasMedical'),
    hasAnyInfo: computed.or('hasNotes', 'hasMedical', 'hasBehavioral'),
    selectedSection: 'notes',
    actions: {
      clickSection(section) {
        this.set('selectedSection', section);
      }

    }
  });

  _exports.default = _default;
});