define("frontend/components/vet-search-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    store: inject.service(),
    infinity: inject.service(),
    filter: null,
    vetGroup: null,
    vetOrGroupList: computed('filter', function () {
      const filter = this.get('filter');
      return this.get('infinity').model('veterinarian-clinic-list-item', {
        filter,
        per_page: 10
      });
    }),
    vetGroupVets: computed('vetGroup', function () {
      let vet_group_id = this.get('vetGroup.id');
      return this.get('store').query('veterinarian-clinic', {
        vet_group_id
      });
    }),
    filteredVetGroupVets: computed('vetGroupVets.[]', 'filter', function () {
      let vets = this.get('vetGroupVets');

      if (this.get('filter')) {
        return vets.filter(vet => {
          try {
            return vet.get('fullAddress').toLowerCase().match(this.get('filter').toLowerCase());
          } catch (e) {
            Bugsnag.notify(`Vet clinic missing full address - vet clinic id: ${vet.get('id')}`);
          }
        });
      } else {
        return vets;
      }
    }),
    searchbarPlaceholder: computed('vetGroup', function () {
      if (this.get('vetGroup')) {
        return 'search by city, state, or zip';
      } else {
        return 'search by name';
      }
    }),
    actions: {
      clickedVet(vet) {
        this.get('clickedVet')(vet);
      },

      clickedVetGroup(vetGroup) {
        this.set('filter', null);
        this.set('vetGroup', vetGroup);
      },

      backToVetSearch() {
        this.set('filter', null);
        this.set('vetGroup', null);
      },

      addNewVet(vetName) {
        this.get('addNewVet')(vetName);
      },

      focusSeachBar() {
        (0, _jquery.default)('.search-bar input').focus();
        (0, _jquery.default)('.search-bar').addClass('blinking');
        Ember.run.later(() => {
          (0, _jquery.default)('.search-bar').removeClass('blinking');
        }, 3000);
      },

      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});