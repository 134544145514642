define("frontend/utils/scroll-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    isScrolledIntoView(elementSelector, headerHeight) {
      let [element] = $(elementSelector);

      if (!element) {
        return false;
      }

      let rect = element.getBoundingClientRect();
      return rect.top >= headerHeight && rect.bottom <= window.innerHeight;
    },

    scrollTopWithHeader(selector) {
      let wrap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.page-wrap';
      let HEADER_HEIGHT = 54;
      return this.scrollTopWithTopPadding(selector, HEADER_HEIGHT, wrap);
    },

    scrollTopWithTopPadding(selector) {
      let padding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let wrap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.page-wrap';
      Ember.run.scheduleOnce('afterRender', () => {
        let ele = $(selector);

        if (ele.length > 0) {
          document.querySelector(wrap).scrollTo({
            top: ele.offset().top - padding
          });
        }
      });
    }

  };
  _exports.default = _default;
});