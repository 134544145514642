define("frontend/controllers/client-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    clientListHousehold: inject.controller('client-list.household'),
    selectedHousehold: computed.readOnly('clientListHousehold.model'),
    application: inject.controller(),
    currentUser: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    queryParams: ['filter'],
    filter: '',
    onHouseholdRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName').indexOf('household') > -1;
    }),
    actions: {
      selectHousehold(household) {
        if (this.get('currentRouteName') === 'client-list.household.appointments') {
          let repeating = Ember.getOwner(this).lookup('controller:client-list.household.appointments').get('repeating');
          let queryParams = {
            repeating
          };
          this.transitionToRoute('client-list.household.appointments', household, {
            queryParams
          });
        } else if (this.get('currentRouteName') === 'client-list.household.documents') {
          this.transitionToRoute('client-list.household.documents', household);
        } else if (this.get('currentRouteName') === 'client-list.household.billing') {
          this.transitionToRoute('client-list.household.billing', household);
        } else if (this.get('currentRouteName') === 'client-list.household.messages') {
          this.transitionToRoute('client-list.household.messages', household);
        } else {
          // Include queryParams to trigger the model hook so old params aren't
          // cached
          this.transitionToRoute('client-list.household', household.get('id'), {
            queryParams: {
              backDate: null,
              backCareProviderId: null,
              backToMessages: null
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});