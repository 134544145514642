define("frontend/components/payment-receipt", ["exports", "frontend/models/invoice-payment"], function (_exports, _invoicePayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['payment-receipt'],
    store: inject.service(),
    showDescriptionModal: false,
    editing: false,
    paymentMethodOptions: [{
      label: 'Bank transfer',
      value: _invoicePayment.PaymentMethods.BANK_TRANSFER
    }, {
      label: 'Cash',
      value: _invoicePayment.PaymentMethods.CASH
    }, {
      label: 'Check',
      value: _invoicePayment.PaymentMethods.CHECK
    }, {
      label: 'PayPal',
      value: _invoicePayment.PaymentMethods.PAYPAL
    }, {
      label: 'Venmo',
      value: _invoicePayment.PaymentMethods.VENMO
    }, {
      label: 'Zelle',
      value: _invoicePayment.PaymentMethods.ZELLE
    }, {
      label: 'Other',
      value: _invoicePayment.PaymentMethods.OTHER
    }],
    tipIsStripePayment: Ember.computed.alias('payment.tip_is_stripe_payment'),
    selectedPaymentMethod: computed('payment.payment_method', function () {
      let options = this.get('paymentMethodOptions');
      let method = this.get('payment.payment_method');

      if (method) {
        return options.find(opt => {
          return opt.value == method;
        });
      }
    }),
    fullPaymentAmount: computed('payment.amount', 'payment.service_fee', 'payment.tip_amount', function () {
      return this.get('payment.amount') + this.get('payment.service_fee') + this.get('payment.tip_amount') + this.get('payment.tip_service_fee');
    }),
    paymentAmountWithoutTip: computed('payment.amount', 'payment.service_fee', function () {
      return this.get('payment.amount') + this.get('payment.service_fee');
    }),
    serviceFeeAmount: computed('payment.service_fee', 'payment.tip_service_fee', function () {
      return this.get('payment.service_fee') + this.get('payment.tip_service_fee');
    }),
    tipPaymentAmount: computed('payment.tip_amount', 'payment.tip_service_fee', function () {
      return this.get('payment.tip_amount') + this.get('payment.tip_service_fee');
    }),
    tipIsSamePaymentMethod: computed('payment.payment_brand', 'payment.payment_last_four', 'payment.tip_payment_brand', 'payment.tip_payment_last_four', function () {
      return this.get('payment.payment_brand') == this.get('payment.tip_payment_brand') && this.get('payment.payment_last_four') == this.get('payment.tip_payment_last_four');
    }),
    showExtraTipInfo: computed('tipIsSamePaymentMethod', 'payment.tip_amount', 'payment.tip_is_separate_payment', function () {
      return this.get('payment.tip_is_separate_payment') && parseFloat(this.get('payment.tip_amount')) > 0;
    }),
    tipIsSameDayAsPayment: computed('payment.tip_created_at', 'payment.created_at', function () {
      return moment(this.get('payment.created_at')).format('YYYY-MM-DD') == moment(this.get('payment.tip_created_at')).format('YYYY-MM-DD');
    }),
    formattedDescription: computed('payment.description', function () {
      return this.get('payment.description').replaceAll('\n', '<br />');
    }),
    paymentBrandName: computed('payment.payment_brand', function () {
      const brand = this.get('payment.payment_brand');

      if (brand) {
        return brand[0].toUpperCase() + brand.substr(1).toLowerCase();
      }
    }),
    tipPaymentBrandName: computed('payment.tip_payment_brand', function () {
      const brand = this.get('payment.tip_payment_brand');

      if (brand) {
        return brand[0].toUpperCase() + brand.substr(1).toLowerCase();
      }
    }),
    serviceFeeTooltipText: computed('payment.payment_date', function () {
      let feePercentage = 5;
      let coverText = '';

      if (this.get('payment.company_service_fee_split_percentage')) {
        feePercentage *= this.get('payment.company_service_fee_split_percentage');
        coverText += `${feePercentage}%`;
      }

      let fee_cap = this.get('payment.company_service_fee_cap'); // $1 Service fee minimum implemented on this date

      if (this.get('payment.payment_date') > '2023-08-08') {
        if (this.get('isPetParent')) {
          if (this.get('payment.payment_date') > '2024-04-08' && this.get('payment.company_service_fee_cap') != null && this.get('payment.company_service_fee_cap') >= 0) {
            if (this.get('payment.company_service_fee_cap') == 0) {
              return `This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('payment.company_name')}, this fee is waived.`;
            } else {
              coverText += coverText === '' ? `$${fee_cap}` : ` or $${fee_cap}, whichever is less`;
              return `This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('payment.company_name')}, you’ll never pay more than ${coverText}.`;
            }
          } else {
            if (coverText !== '') {
              return `This non-refundable 5% fee (minimum $1.00) helps us cover operating costs. With ${this.get('payment.company_name')}, you’ll never pay more than ${coverText}.`;
            } else {
              return `This non-refundable 5% fee (minimum $1.00) helps us cover operating costs.`;
            }
          }
        } else {
          return `This non-refundable 5% fee (minimum $1.00) helps us cover operating costs`;
        }
      }

      return "This non-refundable 5% fee helps us cover operating costs";
    }),
    actions: {
      addTip() {
        this.get('addTip')();
      },

      openDescriptionModal() {
        this.set('showDescriptionModal', true);
      },

      closeDescriptionModal() {
        this.get('payment').rollbackAttributes();
        this.set('showDescriptionModal', false);
      },

      changePaidOnDate(momentNewDate) {
        let newDate = momentNewDate.format('YYYY-MM-DD');
        this.set('showDatepickerSidePanel', false);
        this.set('payment.payment_date', newDate);
      },

      changePaymentMethod(paymentMethod) {
        this.set('payment.payment_method', paymentMethod.value);
      },

      updateDescription() {
        this.get('updateDescription')();
        this.set('showDescriptionModal', false);
      }

    }
  });

  _exports.default = _default;
});