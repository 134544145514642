define("frontend/components/ppb-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: 'img',
    classNames: ['ppb-logo'],
    classNameBindings: ['small'],
    attributeBindings: ['src', 'alt'],
    small: false,
    src: computed('small', function () {
      if (this.get('small')) {
        return 'assets/images/sidebar/logo-small@3x.png';
      } else {
        return 'assets/images/sidebar/logo@3x.png';
      }
    }),
    alt: 'PetPocketbook'
  });

  _exports.default = _default;
});