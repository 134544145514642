define("frontend/components/invoice-receipt/surcharge-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['adjustment-list-item', 'surcharge-adjustment'],
    classNameBindings: ['editing', 'multipleSurcharges', 'shouldIndent:indent-adjustments'],
    flashMessage: inject.service(),
    multipleSurcharges: computed.gt('groupedSurcharges.length', 1),
    surchargeSorting: ['appointment_sortable_date'],
    sortedGroupedSurcharges: computed.sort('groupedSurcharges', 'surchargeSorting'),
    sortedSurcharges: computed.sort('surcharges', 'surchargeSorting'),
    collapsed: true,
    selectedAdjustment: null,
    shouldIndent: computed('indentAdjustments', 'multipleSurcharges', 'showEdit', function () {
      return this.get('indentAdjustments') && !this.get('multipleSurcharges') && !this.get('showEdit');
    }),
    totalSurcharge: computed('isCancellationFee', 'surcharges.@each.amount', 'groupedSurcharges.[]', 'groupedSurcharges.@each.amount', function () {
      if (this.get('isCancellationFee')) {
        return this.get('surcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
      } else {
        return this.get('groupedSurcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
      }
    }),

    confirmRemoveSurcharge() {
      let surcharge = this.get('selectedSurcharge');
      surcharge.get('adjustments').forEach(adjustment => {
        adjustment.set('markedForDeletion', true);
      });
      this.set('selectedSurcharge.selected', false);
      this.set('selectedSurcharge', null);
    },

    rejectRemoveSurcharge() {
      this.set('selectedSurcharge.selected', false);
      this.set('selectedSurcharge', null);
    },

    actions: {
      removeSurcharge(surcharge) {
        surcharge.set('selected', true);
        this.set('selectedSurcharge', surcharge);

        let yes = () => this.confirmRemoveSurcharge.bind(this)();

        let no = () => this.rejectRemoveSurcharge.bind(this)();

        let message = `Are you sure you’d like to remove the $${surcharge.get('amount')} ${this.get('surchargeType').toLowerCase()} for your ${surcharge.get('categoryName')} on ${surcharge.get('appointment_date')}?`;
        this.get('flashMessage').confirm(yes, no, message, 'Remove', 'Cancel');
      }

    }
  });

  _exports.default = _default;
});