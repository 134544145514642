define("frontend/models/company", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject: {
      service
    }
  } = Ember;
  const RESTRICTED_PAYMENTS_MODULE = ['4', '12', '17', '58'];
  const CAN_DOWNLOAD_DRAFTS = ['39'];

  var _default = _emberData.default.Model.extend({
    ajax: service(),
    name: _emberData.default.attr(),
    street: _emberData.default.attr(),
    country: _emberData.default.attr('string', {
      defaultValue: 'United States'
    }),
    city: _emberData.default.attr(),
    state: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    zipcode: _emberData.default.attr(),
    website: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    phone_type_id: _emberData.default.attr(),
    description: _emberData.default.attr(),
    logo_url: _emberData.default.attr('string'),
    invoice_frequencies: _emberData.default.hasMany('invoice-frequency', {
      async: false
    }),
    default_invoice_frequency: _emberData.default.belongsTo('invoice-frequency', {
      async: false
    }),
    client_contracts: _emberData.default.hasMany('client-contract'),
    update_all_households: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    update_all_households_default_sales_tax: _emberData.default.attr('string', {
      defaultValue: 'none'
    }),
    invoice_note: _emberData.default.attr('string'),
    payment_reminder_frequency: _emberData.default.attr(),
    check_payable_to_override: _emberData.default.attr(),
    legal_entity: _emberData.default.attr(),
    preferred_name: _emberData.default.attr('string'),
    tax_id: _emberData.default.attr(),
    ssn: _emberData.default.attr(),
    bank_user_id: _emberData.default.attr('string'),
    bank_account_nickname: _emberData.default.attr('string'),
    bank_account_number: _emberData.default.attr(),
    bank_routing_number: _emberData.default.attr(),
    last_four_ssn: _emberData.default.attr(),
    birthday: _emberData.default.attr(),
    stripe_account_verified: _emberData.default.attr(),
    has_stripe_account: _emberData.default.attr(),
    token: _emberData.default.attr(),
    manual_token: _emberData.default.attr(),
    payment_method: _emberData.default.attr(),
    account_id: _emberData.default.attr(),
    created_at: _emberData.default.attr(),
    epayments: _emberData.default.attr(),
    paypalme_link: _emberData.default.attr('paypalme-link'),
    venmo_name: _emberData.default.attr(),
    time_zone: _emberData.default.attr(),
    time_zone_mapping: _emberData.default.attr(),
    countryInfo: service(),
    free_trial_ends: _emberData.default.attr(),
    pay_frequency: _emberData.default.attr('string'),
    auto_pay: _emberData.default.attr('string'),
    sales_tax: _emberData.default.attr('null-if-zero'),
    sales_tax_policies: _emberData.default.hasMany('sales-tax-policy', {
      async: false
    }),
    // pay_period and billing_period are both only releveant when bi-weekly is selected.
    pay_period: _emberData.default.attr('string'),
    billing_period: _emberData.default.attr('string'),
    offered_services: _emberData.default.hasMany('offered-service', {
      async: true
    }),
    accepts_tips: _emberData.default.attr('boolean'),
    week_starts_on: _emberData.default.attr(),
    service_categories: _emberData.default.attr(),
    feature_rollouts: _emberData.default.attr(),
    pet_parent_appointment_requests_enabled: _emberData.default.attr('boolean'),
    allows_ach_transfer: _emberData.default.attr('boolean'),
    country_iso_code: _emberData.default.attr('string'),
    country_currency: _emberData.default.attr('string'),
    allows_plaid: _emberData.default.attr('boolean'),
    staff_appointment_approval: _emberData.default.attr('boolean'),
    requires_last_four_ssn: _emberData.default.attr('boolean'),
    deactivated_at: _emberData.default.attr(),
    charge_service_fee: _emberData.default.attr(),
    has_contracts: _emberData.default.attr(),
    pet_parents_activated: _emberData.default.attr('boolean'),
    quickbooks_realm_id: _emberData.default.attr(),
    quickbooks_last_synced_at: _emberData.default.attr(),
    quickbooks_initial_sync_email: _emberData.default.attr(),
    quickbooks_initial_sync_at: _emberData.default.attr(),
    quickbooks_effective_date: _emberData.default.attr(),
    pet_parent_service_fee_cap: _emberData.default.attr('number'),
    service_fee_split_percentage: _emberData.default.attr('number'),
    shortAddress: computed('street', 'city', function () {
      return `${this.get('street')} (${this.get('city')})`;
    }),
    biweeklyStart: computed('billing_period', function () {
      return (0, _moment.default)(this.get('billing_period')).format('M/D');
    }),
    biweeklyEnd: computed('billing_period', function () {
      return (0, _moment.default)(this.get('billing_period')).add(2, 'weeks').subtract(1, 'day').format('M/D');
    }),
    shouldShowPayments: computed('id', function () {
      return RESTRICTED_PAYMENTS_MODULE.indexOf(this.get('id')) === -1;
    }),
    canDownloadDrafts: computed('id', function () {
      return CAN_DOWNLOAD_DRAFTS.indexOf(this.get('id')) !== -1;
    }),
    quickbooksAccess: computed('feature_rollouts.[]', function () {
      return this.get('feature_rollouts').indexOf('quickbooks') !== -1;
    }),
    weekType: computed('week_starts_on', function () {
      let weekType = null;

      if (this.get('week_starts_on') == 'Monday') {
        weekType = 'isoWeek';
      } else if (this.get('week_starts_on') == 'Sunday') {
        weekType = 'week';
      }

      return weekType;
    }),
    selectedCountry: computed('country', 'countryInfo.states.[]', 'countryInfo.defaultUsState', 'countryInfo.defaultProvince', {
      set(key, value) {
        this.set('country', value);
        return this.get('country');
      },

      get() {
        let states = this.get('countryInfo.states');

        if (states.indexOf(this.get('state')) === -1) {
          if (this.get('country') === 'Canada') {
            this.set('state', this.get('countryInfo.defaultProvince'));
          } else {
            this.set('state', this.get('countryInfo.defaultUsState'));
          }
        }

        return this.get('country');
      }

    }),
    requiresAutoPay: computed('auto_pay', function () {
      return this.get('auto_pay') == 'required';
    }),
    acceptsEpayments: computed('epayments.[]', function () {
      let accepted = this.get('epayments');
      return ['cards', 'ach', 'both'].includes(accepted);
    }),
    acceptsCards: computed('epayments.[]', 'acceptsBoth', function () {
      let accepted = this.get('epayments');
      return this.get('acceptsBoth') || accepted.indexOf('cards') > -1;
    }),
    acceptsAch: computed('epayments.[]', 'acceptsBoth', function () {
      let accepted = this.get('epayments');
      return this.get('acceptsBoth') || accepted.indexOf('ach') > -1;
    }),
    acceptsBoth: computed('epayments.[]', function () {
      let accepted = this.get('epayments');
      return accepted.indexOf('both') > -1;
    }),
    displayName: computed('name', 'preferred_name', function () {
      if (this.get('preferred_name') !== null && this.get('preferred_name') !== undefined && this.get('preferred_name') !== '') {
        return this.get('preferred_name');
      } else {
        return this.get('name');
      }
    }),
    checkPayableToOverride: computed('display_name', 'check_payable_to_override', {
      set(key, value) {
        this.set('check_payable_to_override', value);
        return value;
      },

      get() {
        if (this.get('check_payable_to_override') !== null && this.get('check_payable_to_override') !== '') {
          return this.get('check_payable_to_override');
        } else {
          return this.get('displayName');
        }
      }

    }),

    requestCountryChange() {
      let url = `/api/v2/companies/${this.get('id')}/change_country`;
      return this.get('ajax').request(url);
    }

  });

  _exports.default = _default;
});