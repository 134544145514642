define("frontend/helpers/file-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fileType = fileType;
  const DEFAULT_FILE_TYPE = 'other';

  function fileType(_ref) {
    let [filename] = _ref;

    if (/\./.test(filename)) {
      let fileParts = filename.split('.');
      return fileParts[fileParts.length - 1].toLowerCase();
    } else {
      return DEFAULT_FILE_TYPE;
    }
  }

  var _default = Ember.Helper.helper(fileType);

  _exports.default = _default;
});