define("frontend/components/adaptive-navigation-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['adaptive-navigation-buttons'],
    media: inject.service(),
    rightText: 'Next',
    leftText: 'Back',
    middleText: '',
    showRight: true,
    showLeft: true,
    showMiddle: false,
    overrideLeftColor: null,
    rightColor: computed('media.isMobile', function () {
      return this.get('media.isMobile') ? 'red-white' : 'red';
    }),
    leftColor: computed('media.isMobile', 'overrideLeftColor', function () {
      if (this.get('overrideLeftColor')) {
        return this.get('overrideLeftColor');
      }

      return this.get('media.isMobile') ? 'red-white' : 'gray';
    }),
    rightDisabled: false,
    middleDisabled: false
  });

  _exports.default = _default;
});