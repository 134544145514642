define("frontend/routes/client-list/household/documents", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),
    cookies: Ember.inject.service(),

    model() {
      let household = this._super(...arguments);

      let documents = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/households/${household.get('id')}/user_documents`,
        method: 'GET'
      });

      let signatureRequests = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/client_contracts/household_signature_requests?household_id=${household.get('id')}`,
        method: 'GET'
      });

      return Ember.RSVP.hash({
        household,
        documents,
        signatureRequests
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('uploading', false);
      controller.set('clickedContractButton', false);
      controller.set('sendingContracts', false);

      if (this.get('cookies').exists('clientListAddedUser')) {
        this.get('cookies').clear('clientListAddedUser', {
          path: '/'
        });

        if (controller.clientContractsAvailableToSend) {
          controller.send('toggleSendDocumentsModal');
          controller.send('toggleCareProviderAddedNewClient');
        }
      }
    },

    actions: {
      reload() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});