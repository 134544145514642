define("frontend/components/week-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['week-select'],
    selectedDays: [],
    startDate: null,
    lastStartDate: null,
    selectedDows: computed('selectedDays.[]', function () {
      return this.get('selectedDays').map(day => moment(day, 'YYYY-MM-DD').format('e'));
    }),
    weekDaysWithSelected: computed('selectedDows.[]', 'weekDays.[]', function () {
      return this.get('weekDays').map(date => {
        let dow = moment(date, 'YYYY-MM-DD').format('e');
        let isSelected = this.get('selectedDows').indexOf(dow) > -1;
        return {
          isSelected,
          date
        };
      });
    }),
    actions: {
      clickDay(day) {
        this.clickDay(day);
      }

    }
  });

  _exports.default = _default;
});