define("frontend/routes/portal/invoices-summary/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      let {
        settings
      } = this._super(...arguments);

      if (settings.get('length') > 0) {
        let activeSettings = settings.filter(s => s.active && !s.company_deactivated && !s.denied_request && s.pet_parents_activated);

        if (activeSettings.length > 0) {
          settings = activeSettings;
        }

        settings = settings.sortBy('added_on').reverse();
        let firstId = settings.get('firstObject.company_id');

        if (firstId) {
          return this.transitionTo('portal.invoices-summary.company', firstId);
        }
      }
    }

  });

  _exports.default = _default;
});