define("frontend/controllers/client-list/household", ["exports", "frontend/utils/scroll-util", "frontend/config/environment"], function (_exports, _scrollUtil, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;
  const EDIT_PROFILES = "edit-profiles";
  const ADD_APPOINTMENT = "add-appointment";
  const VIEW_BILLING_SETTINGS = "view-billing-settings";
  const UPLOAD_DOCUMENTS = "upload-documents";
  const NOTES_SCROLL = ".notes-output header";
  const MEDICAL_SCROLL = ".medical-output header";
  const BEHAVIORAL_SCROLL = ".behavioral-output header";
  const TEXT_HEIGHT = 20;
  const MARGIN_TOP = 40;

  var _default = Ember.Controller.extend({
    householdUserController: inject.controller("client-list.household.household-user"),
    petController: inject.controller("client-list.household.pet"),
    clientList: inject.controller(),
    filter: computed.alias("clientList.filter"),
    application: inject.controller(),
    firestore: inject.service(),
    firestoreListener: null,
    router: inject.service(),
    media: inject.service(),
    hasMedical: computed.readOnly("petController.hasMedical"),
    hasBehavioral: computed.readOnly("petController.hasBehavioral"),
    hasNotes: computed.readOnly("petController.hasNotes"),
    hasAnyInfo: computed.readOnly("petController.hasAnyInfo"),
    currentRouteName: computed.readOnly("router.currentRouteName"),
    onHouseholdUserRoute: computed.equal("currentRouteName", "client-list.household.household-user"),
    onPetRoute: computed.equal("currentRouteName", "client-list.household.pet"),
    onAboutRoute: computed.or("onPetRoute", "onHouseholdUserRoute"),
    onDocumentRoute: computed.equal("currentRouteName", "client-list.household.documents"),
    onBillingRoute: computed.equal("currentRouteName", "client-list.household.billing"),
    onAppointmentsRoute: computed.equal("currentRouteName", "client-list.household.appointments"),
    onMessageRoute: computed.equal("currentRouteName", "client-list.household.messages"),
    selectedSection: computed.alias("petController.selectedSection"),
    notesActive: computed.equal("selectedSection", "notes"),
    medicalActive: computed.equal("selectedSection", "medical"),
    behavioralActive: computed.equal("selectedSection", "behavioral"),
    moreMenuOpen: false,
    showLoadMore: true,
    removingAppointment: null,
    mobileTabBarIsShowing: false,
    currentUser: Ember.inject.service(),
    moreOptions: computed("currentUser.user.isAdmin", function () {
      let options = [];

      if (this.get("currentUser.user.isAdmin")) {
        options = [{
          label: "Add appointment",
          value: ADD_APPOINTMENT
        }, {
          label: "Edit profiles",
          value: EDIT_PROFILES
        }, {
          label: "View billing settings",
          value: VIEW_BILLING_SETTINGS
        }];
      } else if (this.get("currentUser.user.canAddEditAppointments")) {
        options.push({
          label: "Add appointment",
          value: ADD_APPOINTMENT
        });
      }

      options.push({
        label: "See documents",
        value: UPLOAD_DOCUMENTS
      });
      return options;
    }),
    selectedUser: computed("currentRouteName", "householdUserController.model", "petController.model", function () {
      if (this.get("currentRouteName") === "client-list.household.household-user") {
        return this.get("householdUserController.model");
      } else {
        return this.get("petController.model");
      }
    }),
    mobileNavText: computed("model.owner.last_name", "mobileTabBarIsShowing", "selectedUser", function () {
      const tabIsShowing = this.get("mobileTabBarIsShowing");
      const selectedUser = this.get("selectedUser");
      const familyNameText = `${this.get("model.owner.last_name")} family`;
      if (!selectedUser) return familyNameText;
      const petName = this.get("selectedUser").name;
      const aboutPetText = `about ${petName}`;
      return tabIsShowing && aboutPetText ? aboutPetText : familyNameText;
    }),
    selectedUserEmail: computed("selectedUser", function () {
      let user = this.get("selectedUser");

      if (user.get("modelName") == "household-user") {
        return user.get("email");
      }

      return this.get("model.owner.email");
    }),
    clientPending: computed.alias("model.isPendingStatus"),
    clientNotLocked: computed.not("model.locked"),
    showAppointments: computed.not("currentUser.user.isStaff"),
    showEditIcon: computed.and("currentUser.user.canAddEditClients", "clientNotLocked"),
    showMenu: false,
    showAppointmentsMenuItem: computed.alias("currentUser.user.canViewFullClientInfo"),
    showBillingMenuItem: computed.alias("currentUser.user.canViewInvoicesTab"),
    showRemoveFamilyMenuItem: computed.alias("currentUser.user.isAdmin"),
    menuSpacing: computed("showAppointmentsMenuItem", "showBillingMenuItem", "showRemoveFamilyMenuItem", function () {
      const dynamicMenuItems = [this.get("showAppointmentsMenuItem"), this.get("showBillingMenuItem"), this.get("showRemoveFamilyMenuItem")].filter(Boolean).length;
      return Ember.String.htmlSafe(`bottom: -${dynamicMenuItems * 38 + 89}px`);
    }),

    scrollTo(selector) {
      let headerHeight = document.querySelector(".flex-header").clientHeight - MARGIN_TOP;
      let topOfElement = document.querySelector(selector).offsetTop - headerHeight;
      document.querySelector(".page-wrap").scroll({
        top: topOfElement
      });
    },

    modelObserver: observer("model.id", function () {
      if (this.get("firestoreListener")) {
        this.get("firestoreListener")();
      }

      let self = this;
      let collection = this.get("firestore").getCollection(`${_environment.default.environment}_message_times`);

      if (collection) {
        let firestoreListener = collection.doc(`${self.get("currentUser.user.company.id")}_${self.get("model.id")}`).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set("lastMessageTime", doc.data().staff_last_message);
          } else {
            self.set("lastMessageTime", null);
          }
        });
        this.set("firestoreListener", firestoreListener);
      }
    }),
    hasNewMessage: computed("model.user_last_seen_message_thread", "lastMessageTime", function () {
      if (!this.get("lastMessageTime")) {
        return false;
      }

      let lastSeen = this.get("model.user_last_seen_message_thread");
      let lastMessage = this.get("lastMessageTime");

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage && !this.get("onMessageRoute");
    }),
    actions: {
      clickPetOrUser(petOrUser) {
        if (petOrUser.get("modelName") === "household-user") {
          this.transitionToRoute("client-list.household.household-user", this.get("model.id"), petOrUser);
        } else {
          this.transitionToRoute("client-list.household.pet", this.get("model.id"), petOrUser);
        }
      },

      goToEditProfile() {
        this.toggleProperty("showMenu");
        this.send("transitionToEditHousehold", this.get("model"), this.get("selectedUser"));
      },

      goToAppointments() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("client-list.household.appointments", this.get("model"));
      },

      goToBilling() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("invoices-summary", this.get("model.id"));
      },

      goToDocuments() {
        this.toggleProperty("showMenu");
        this.transitionToRoute("client-list.household.documents", this.get("model"));
      },

      removeFamily(model) {
        this.toggleProperty("showMenu");
        this.send("removeHousehold", model);
      },

      clickBack() {
        this.set("showMenu", false);
        this.send("goBack");
      },

      scroll() {
        if (this.get("media.isMobile")) {
          let petNameElement = $(".pet-or-user-information__full-name").offset().top;

          if (petNameElement <= 75 && this.get("onPetRoute")) {
            this.set("mobileTabBarIsShowing", true);
          } else {
            this.set("mobileTabBarIsShowing", false);
          }

          return;
        }

        let headerHeight = document.querySelector(".flex-header").clientHeight - TEXT_HEIGHT;

        if (_scrollUtil.default.isScrolledIntoView(NOTES_SCROLL, headerHeight)) {
          this.set("selectedSection", "notes");
        } else if (_scrollUtil.default.isScrolledIntoView(BEHAVIORAL_SCROLL, headerHeight)) {
          this.set("selectedSection", "behavioral");
        } else if (_scrollUtil.default.isScrolledIntoView(MEDICAL_SCROLL, headerHeight)) {
          this.set("selectedSection", "medical");
        }
      },

      clickMedical() {
        this.scrollTo(MEDICAL_SCROLL);
        let self = this; // scrollTo will trigger scroll that overrides selected section if
        // multiple sections are in view so set selected section on a delay

        setTimeout(function () {
          self.set("selectedSection", "medical");
        }, 50);
      },

      clickBehavioral() {
        this.scrollTo(BEHAVIORAL_SCROLL);
        let self = this;
        setTimeout(function () {
          self.set("selectedSection", "behavioral");
        }, 50);
      },

      clickNotes() {
        this.scrollTo(NOTES_SCROLL);
        let self = this;
        setTimeout(function () {
          self.set("selectedSection", "notes");
        }, 50);
      },

      openMoreMenu() {
        this.set("moreMenuOpen", true);
      },

      clickMoreOption(option) {
        let {
          value
        } = option;

        switch (value) {
          case EDIT_PROFILES:
            this.send("transitionToEditHousehold", this.get("model"), this.get("selectedUser"));
            break;

          case VIEW_BILLING_SETTINGS:
            this.transitionToRoute("client-list.household.billing", this.get("model")).then(() => {
              this.set("moreMenuOpen", false);
            });
            break;

          case UPLOAD_DOCUMENTS:
            this.transitionToRoute("client-list.household.documents", this.get("model")).then(() => {
              this.set("moreMenuOpen", false);
            });
            break;

          case ADD_APPOINTMENT:
            this.send("transitionToAddAppointment");
            break;
        }
      },

      clickMoreBack() {
        this.set("moreMenuOpen", false);
      }

    }
  });

  _exports.default = _default;
});