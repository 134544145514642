define("frontend/routes/token-login-redirect", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Route.extend({
    session: inject.service(),
    accountSession: inject.service(),
    currentUser: inject.service(),
    queryParams: {
      token: null,
      redirect_to: null,
      cid: null,
      hid: null,
      pid: null,
      iid: null,
      email_token: null,
      accept_token: null,
      appointmentId: null
    },

    model(params) {
      if (this.get('session.isAuthenticated')) {
        this.redirectFromParams(params);
      } else {
        let store = this.get('session.store');
        return _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/token_login`,
          method: 'GET',
          data: {
            token: params.token
          }
        }).then(response => {
          sessionStorage.setItem('cancelRefreshOnCookieChange', true);
          store.clear().then(() => {
            if (response.redirect) {
              window.location.href = '/users/login';
            } else {
              store.persist(response.cookie).then(() => {
                if (!this.get('accountSession').hasCompany()) {
                  if (['invoice', 'payment', 'report_card', 'appointments'].includes(params.redirect_to)) {
                    this.get('accountSession').switchAccounts(0);
                  } else if (['payment-detail', 'invoice-detail'].includes(params.redirect_to)) {
                    let redirectHref = `/invoices-summary/${params.hid}/${params.redirect_to}/${params.pid}`;
                    this.get('accountSession').switchAccounts(params.cid, redirectHref);
                  } else if (['edit-draft-invoice'].includes(params.redirect_to)) {
                    let redirectHref = `/invoices-summary/${params.hid}/invoice-detail/${params.iid}?editing=true`;
                    this.get('accountSession').switchAccounts(params.cid, redirectHref);
                  }
                }

                this.redirectFromParams(params);
              });
            }
          });
        });
      }
    },

    redirectFromParams(params) {
      switch (params.redirect_to) {
        case 'invoice':
          window.location.href = `/portal/invoices-summary/company/${params.cid}`;
          break;

        case 'payment':
          window.location.href = `/portal/invoices-summary/company/${params.cid}/payment-detail/${params.pid}?showAddTip=true`;
          break;

        case 'accept_client':
          window.location.href = `/accept-company/${params.accept_token}?email=${params.email_token}`;
          break;

        case 'accept_staff':
          window.location.href = `/accept-staff/${params.accept_token}?email=${params.email_token}`;
          break;

        case 'report_card':
          window.location.href = `/portal/messages/company/${params.cid}?appointmentId=${params.appointmentId}&showReportCard=true`;
          break;

        case 'appointments':
          window.location.href = `/portal/appointments`;
          break;

        case 'payment-detail':
          window.location.href = `/invoices-summary/${params.hid}/payment-detail/${params.pid}`;
          break;

        case 'edit-draft-invoice':
          window.location.href = `/invoices-summary/${params.hid}/invoice-detail/${params.iid}?editing=true`;
          break;

        default:
          window.location.href = '/users/login';
      }
    }

  });

  _exports.default = _default;
});