define("frontend/controllers/admin/breeds", ["exports", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'breed',
    modelOptions: {
      pet_type: 'all'
    },
    breedOptions: Ember.computed('editingReplace.pet_type_id', function () {
      let pet_type = this.get('editingReplace.pet_type_id');

      if (pet_type == 0) {
        pet_type = 1;
      }

      return this.get('store').query('breed', {
        pet_type
      });
    }),
    actions: {
      submitReplace(replace) {
        let url = `/api/v2/breeds/${this.get('editingReplace.id')}/replace`;
        this.submitReplace(url, replace, this.get('editingReplace'));
      },

      showRemove(breed) {
        this.set('selectedBreed', breed);
      },

      confirmRemove() {
        this.send('remove', this.get('selectedBreed'));
        this.set('selectedBreed', null);
      },

      denyRemove() {
        this.set('selectedBreed', null);
      }

    }
  });

  _exports.default = _default;
});