define("frontend/validators/tax-id", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TaxId = _base.default.extend({
    // eslint-disable-next-line no-unused-vars
    validate(value, options, model, attribute) {
      if (model.model.company.get('country') == 'Australia') {
        return [9, 11].includes(value.length) ? true : 'This field must be 9 or 11 digits long';
      } else {
        return value.length == 9 ? true : 'This field must be 9 digits long';
      }
    }

  });

  TaxId.reopenClass({
    // eslint-disable-next-line no-unused-vars
    getDependentsFor(attribute, options) {
      return [];
    }

  });
  var _default = TaxId;
  _exports.default = _default;
});