define("frontend/components/appointment-times/recurring-appointment-group", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    media: inject.service(),
    classNames: ['recurring-appointment'],
    classNameBindings: ['readOnly', 'swipedLeft'],
    showPetNames: true,
    readOnly: false,
    editing: false,
    recurringAppointmentGroup: null,
    recurringAppointments: computed.readOnly('recurringAppointmentGroup.recurring_appointments'),
    careProviders: computed.map('recurringAppointments', function (ra) {
      return ra.get('careProvider');
    }),
    uniqueCareProviders: computed('careProviders.[]', function () {
      return this.get('careProviders').uniq();
    }),
    pets: computed('recurringAppointments.@each.pet', function () {
      return this.recurringAppointments.mapBy('pet');
    }),
    petNames: computed('pets.@each.name', function () {
      return this.pets.mapBy('name').uniq().join(', ');
    }),

    click(e) {
      if (this.get('readOnly')) {
        return;
      }

      if (e.target.tagName === 'DIV' || e.target.tagName === 'IMG') {
        if (this.get('media.isMobile')) {
          this.toggleProperty('swipedLeft');
        } else {
          this.clickAppointment();
        }
      }
    },

    startsInFuture: computed('recurringAppointmentGroup.start_date', function () {
      let startDate = this.get('recurringAppointmentGroup.start_date');
      return (0, _moment.default)(startDate).startOf('day').isAfter((0, _moment.default)().startOf('day'));
    }),
    neverEnds: computed.equal('recurringAppointmentGroup.end_date', null),
    ends: computed.not('neverEnds'),
    actions: {
      removeAppointment() {
        this.removeAppointment(...arguments);
      },

      editAppointment() {
        this.clickAppointment(...arguments);
      }

    }
  });

  _exports.default = _default;
});