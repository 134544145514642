define("frontend/components/user-selection-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['user-selection-wheel'],
    classNameBindings: ['showingAlertBox:showing-alert-box', 'petIconsAtTop:pet-icons-at-top'],
    currentUser: inject.service(),
    selectedUser: null,
    household: null,
    petsAndUsers: computed.union('usersSortedByRoleAndId', 'household.pets'),
    showAdditionalPetInfo: false,
    hasSecondaryContact: computed.gt('household.users.length', 1),
    petIconsAtTop: computed.gt('petsAndUsers.length', 5),
    createPetOrUserOptions: [{
      label: 'add pet',
      value: 'pet'
    }, {
      label: 'add secondary contact',
      value: 'user'
    }],
    usersSortedByRoleAndId: computed('household.users', 'household.owner', function () {
      let household = this.get('household');
      let users = [household.get('owner')];
      users.pushObjects(household.get('users').rejectBy('id', household.get('owner.id')).sortBy('id'));
      return users;
    }),
    showingAlertBox: computed('currentUser.user.isAdmin', 'household.isPendingStatus', 'household.esignature_status', 'household.isPendingSignatures', function () {
      if (!this.get('currentUser.user.isAdmin')) {
        return false;
      }

      return this.get('household.isPendingStatus') || (this.get('household.esignature_status') === 'none' || this.get('household.isPendingSignatures')) && this.get('currentUser.user').shouldSeeHouseholdContractAlert(parseInt(this.get('household.id')));
    }),
    actions: {
      clickPetOrUser(petOrUser) {
        this.clickPetOrUser(petOrUser);
      },

      clickAdd() {
        if (!this.get('hasSecondaryContact')) {
          this.set('showPopout', true);
        } else {
          this.send('createPetOrUser', 'pet');
        }
      },

      closeAdd() {
        this.set('showPopout', false);
      },

      createPetOrUser(selection) {
        if (selection.value) {
          selection = selection.value;
        }

        this.get('onCreatePetOrUser')(selection);
      },

      uploadedPhoto(photo) {
        this.set('selectedUser.avatar_url', photo);
      }

    }
  });

  _exports.default = _default;
});