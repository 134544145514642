define("frontend/components/message-thread/upload-image", ["exports", "frontend/components/photo-upload"], function (_exports, _photoUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _photoUpload.default.extend({
    iconUrl: 'assets/images/report-card/add-photo-icon.svg',
    actions: {
      hoveringPhoto(hovering) {
        if (hovering) {
          this.set('iconUrl', 'assets/images/report-card/add-photo-icon-hover.svg');
        } else {
          this.set('iconUrl', 'assets/images/report-card/add-photo-icon.svg');
        }
      }

    }
  });

  _exports.default = _default;
});