define("frontend/routes/admin/doctors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      this._super(...arguments);

      controller.set('filter', '');
    }

  });

  _exports.default = _default;
});