define("frontend/components/paper-input-resizable", ["exports", "frontend/components/paper-input", "jquery"], function (_exports, _paperInput, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    growTextarea() {
      if (this.get('textarea')) {
        let inputElement = (0, _jquery.default)(this.element.querySelectorAll('input, textarea'));
        inputElement.addClass('md-no-flex').attr('rows', 1);
        let minRows = this.get('passThru.rows');
        let height = this.getHeight(inputElement.get(0));

        if (minRows) {
          this.lineHeight = 30;

          if (this.lineHeight) {
            height = Math.max(height, this.lineHeight * minRows);
          }

          let proposedHeight = Math.ceil(height / this.lineHeight);
          let maxRows = this.get('passThru.maxRows') || Number.MAX_VALUE;
          let rowsToSet = Math.min(proposedHeight, maxRows);
          inputElement.css('height', `${this.lineHeight * rowsToSet}px`).attr('rows', rowsToSet).toggleClass('md-textarea-scrollable', proposedHeight >= maxRows);
        } else {
          inputElement.css('height', 'auto');
          inputElement.get(0).scrollTop = 0;
          let height = this.getHeight(inputElement.get(0));

          if (height) {
            inputElement.css('height', `${height}px`);
          }
        }

        inputElement.removeClass('md-no-flex');
      }
    }

  });

  _exports.default = _default;
});