define("frontend/components/paper-input-readonly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['paper-input-readonly'],
    actions: {
      onChange() {}

    }
  });

  _exports.default = _default;
});