define("frontend/helpers/format-line-breaks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatLineBreaks = formatLineBreaks;

  function formatLineBreaks(_ref) {
    let [str] = _ref;

    if (str) {
      return Ember.String.htmlSafe(str.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
    } else {
      return str;
    }
  }

  var _default = Ember.Helper.helper(formatLineBreaks);

  _exports.default = _default;
});