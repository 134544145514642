define("frontend/controllers/portal/reset-password", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'confirm_password': [(0, _emberCpValidations.validator)('confirmation', {
      on: 'new_password',
      message: 'Passwords do not match'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    currentUser: Ember.inject.service(),
    actions: {
      resetPassword() {
        if (!this.get('validations.isValid')) {
          return;
        }

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v1/users/${this.get('currentUser.user.id')}/reset_password`,
          method: 'PUT',
          data: {
            user: {
              password: this.get('new_password'),
              old_password: this.get('old_password'),
              password_confirmation: this.get('confirm_password')
            }
          }
        }).done(() => {
          this.set('didValidate', false);
          this.set('old_password', '');
          this.set('new_password', '');
          this.set('confirm_password', '');
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: 'Your password has been successfully reset!',
            type: 'success'
          });
        }).fail(data => {
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: JSON.parse(data.responseText).errors[0],
            type: 'user-error'
          });
        });
      }

    }
  });

  _exports.default = _default;
});