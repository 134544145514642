define("frontend/routes/application", ["exports", "logrocket", "frontend/config/environment"], function (_exports, _logrocket, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    needs: ['application'],
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    accountSession: Ember.inject.service(),
    firestore: Ember.inject.service(),
    activityLogs: Ember.inject.service(),
    inboxHasUnreadMessages: Ember.inject.service(),
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    beacon: Ember.inject.service(),
    reactApp: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.router.on('routeDidChange', () => {
        let loc = window.location;
        let path = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;

        if (!Ember.isEmpty(_environment.default.integrations.mixpanel.token)) {
          window.mixpanel.track('visit', {
            path
          });
        }

        Ember.run.scheduleOnce('afterRender', () => {
          this.get('beacon').recordPageView();

          if (window.Appcues) {
            Appcues.start();
          }
        });
      });
    },

    async beforeModel() {
      this._super(...arguments);

      await this.get('session').setup();
      let parser = new UAParser();
      let browserName = parser.getResult().browser.name;

      if (browserName == 'IE' || browserName == 'Edge') {
        this.transitionTo('invalid-browser', {
          queryParams: {
            link: window.location.href
          }
        });
      }
    },

    model() {
      return this.loadCurrentUser().then(() => {
        this.setupTrackers();
        this.setupDeployListener();
        this.setupScheduleListener();
        this.setupCookieListener();
        this.setupCssProperties();
        this.get('inboxHasUnreadMessages').startTracking();
        return this.get('currentUser.user');
      });
    },

    redirect(model, transition) {
      this._super(...arguments);

      let user = this.get('currentUser.user');

      if (user && user.get('changed_password') == false) {
        if (!['terms', 'accept-company', 'accept-staff'].includes(transition.targetName)) {
          this.transitionTo('change-password');
        }
      }

      if (user && !this.getCookieValue('ccontrol_id')) {
        if (!['terms', 'accept-company', 'accept-staff'].includes(transition.targetName)) {
          this.transitionTo('account-select');
        }
      }
    },

    setupDeployListener() {
      let self = this;
      let collection = this.get('firestore').getCollection('deployments');

      if (collection) {
        collection.doc(`${_environment.default.environment}_api`).onSnapshot(function (doc) {
          if (doc.exists) {
            let lastDeployedAt = sessionStorage.getItem('last_deployed_at');
            let newDeployedAt = doc.data().last_deployed.seconds;

            if (lastDeployedAt && lastDeployedAt < newDeployedAt) {
              self.get('flashMessage').sendFlashMessage('PetPocketbook just got better! Refresh your browser for the latest version!', 'deploy-refresh');
            }

            sessionStorage.setItem('last_deployed_at', newDeployedAt);
          }
        });
      }
    },

    setupScheduleListener() {
      if (!this.get('currentUser.user')) {
        return;
      }

      let self = this;
      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_users`);

      if (collection) {
        collection.doc(self.get('currentUser.user.id')).onSnapshot(function (doc) {
          if (doc.exists) {
            let lastUpdatedAt = sessionStorage.getItem('schedule_updated_at');
            let newUpdatedAt = doc.data().schedule_updated_at.seconds;

            if (lastUpdatedAt && lastUpdatedAt < newUpdatedAt) {
              self.get('flashMessage').sendFlashMessage('Your schedule today has been updated!', 'schedule-refresh');
            }

            sessionStorage.setItem('schedule_updated_at', newUpdatedAt);
          }
        });
      }
    },

    setupTrackers() {
      try {
        this.setupBugsnag();
        this.setupLogRocket();
        this.setupAppcues();
        this.get('beacon').setupBeacons();
      } catch (err) {
        if (Bugsnag) {
          Bugsnag.notifyException(err);
        }
      }
    },

    setupBugsnag() {
      let user = this.get('currentUser.user');

      if (Bugsnag && user) {
        Bugsnag.metaData = {
          account: {
            first_name: user.getFirstName,
            last_name: user.last_name,
            email: user.email
          }
        };
      }
    },

    setupLogRocket() {
      let user = this.get('currentUser.user');
      let session = this.get('session');
      let trackUsers = _environment.default.track_user_ids || [];

      if (session) {
        let userId = session.get('data.authenticated.tokenData.user_id');

        if (trackUsers.includes(userId)) {
          _logrocket.default.identify(userId, {
            name: user.get('fullName'),
            email: user.get('email')
          });

          _logrocket.default.init('oxvvkr/petpocketbook');
        }
      }
    },

    setupAppcues() {
      let user = this.get('currentUser.user');

      if (!user) {
        return;
      }

      if (!window.Appcues) {
        return;
      }

      Appcues.identify(user.get('id'), {
        // Replace with unique identifier for current user
        name: user.get('fullName'),
        email: user.get('email')
      });
    },

    getCookieValue(name) {
      let result = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
      return result ? result.pop() : '';
    },

    setupCookieListener() {
      let self = this;

      let checkCookie = function () {
        let authData = JSON.parse(decodeURIComponent(self.getCookieValue('ember_simple_auth-session')));
        let cachedIsPetParent = authData.authenticated.tokenData ? authData.authenticated.tokenData.is_pet_parent : null;
        let cachedUserId = authData.authenticated.tokenData ? authData.authenticated.tokenData.user_id : null;
        let cachedCompanyId = self.getCookieValue('ccontrol_id');
        return function () {
          let cookie, decodedCookie, authData;

          try {
            cookie = self.getCookieValue('ember_simple_auth-session');
            decodedCookie = decodeURIComponent(cookie);
            authData = JSON.parse(decodedCookie);
            let currentIsPetParent = authData.authenticated.tokenData ? authData.authenticated.tokenData.is_pet_parent : null;
            let currentUserId = authData.authenticated.tokenData ? authData.authenticated.tokenData.user_id : null;
            let cancelRefreshOnCookieChange = sessionStorage.getItem('cancelRefreshOnCookieChange');
            let currentCompanyId = self.getCookieValue('ccontrol_id');
            sessionStorage.setItem('cancelRefreshOnCookieChange', false);

            if ((cachedUserId !== currentUserId || cachedIsPetParent !== currentIsPetParent || cachedCompanyId !== currentCompanyId) && cancelRefreshOnCookieChange != 'true') {
              window.location.href = '/';
            }

            cachedIsPetParent = currentIsPetParent;
            cachedUserId = currentUserId;
          } catch (e) {
            if (Bugsnag) {
              Bugsnag.notify({
                error: e,
                cookie,
                decodedCookie
              });
            }
          }
        };
      }();

      window.setInterval(checkCookie, 500);
    },

    setDocHeight() {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    },

    setupCssProperties() {
      let self = this;
      window.addEventListener('resize', () => {
        self.setDocHeight();
      });
      window.addEventListener('orientationchange', () => {
        self.setDocHeight();
      });
      this.setDocHeight();
    },

    loadCurrentUser() {
      this.get('activityLogs').load();
      return this.get('currentUser').load().catch(() => this.get('accountSession').signOut());
    },

    actions: {
      showSideNav() {
        $('body').addClass('push-active');
      },

      hideSideNav() {
        $('body').removeClass('push-active');
      },

      expandSettings() {
        Ember.getOwner(this).lookup('controller:application').set('settingsExpanded', true);
        return true;
      },

      collapseSettings() {
        Ember.getOwner(this).lookup('controller:application').set('settingsExpanded', false);
        return true;
      },

      openDocuments() {
        const householdId = this.get('currentUser.user.household.id');
        window.open(this.get('reactApp').getPetParentDocumentsUrl(householdId), '_self');
      },

      error(error) {
        if (error.status === 403 || error.status === 401) {
          this.get('accountSession').signOut().finally(() => {
            window.location.href = '/users/login';
          });
        } else if (error.status === 404 || error.errors && error.errors.get('firstObject').status === '404') {
          this.get('flashMessage').error('Oops! Something went wrong.');
          this.transitionTo('index');
        } else {
          if (Bugsnag) {
            Bugsnag.notifyException(error);
          }
          /* eslint no-console: ["error", { allow: ["error"] }] */


          this.get('flashMessage').errorWithRefresh('Oops! Something went wrong.');
        }
      },

      authorizationFailed() {
        this.transitionTo('users.login');
      },

      signOut() {
        this.get('accountSession').signOut();
      },

      redirectToAnalytics() {
        window.location = this.get('reactApp').reportsUrl;
      },

      openSupportBeacon() {
        this.get('beacon').openSupportBeacon();
      },

      redirectToMicrodeposits(emd5, isStandalone) {
        if (Ember.getOwner(this).lookup('controller:application').currentPath === 'microdeposits') {
          window.location.reload();
        } else {
          this.transitionTo('microdeposits', emd5, {
            queryParams: {
              isStandalone
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});