define("frontend/controllers/confirm-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    canConnect: Ember.computed('model.payments_accepted', function () {
      let payments_accepted = this.get('model.payments_accepted');

      if (payments_accepted == 'ach' || payments_accepted == 'both') {
        return true;
      }

      return false;
    }),
    actions: {
      redirectToHooray() {
        this.transitionToRoute('verified-bank-account', this.get('model.emd5'));
      }

    }
  });

  _exports.default = _default;
});