define("frontend/routes/sign-up", ["exports", "frontend/mixins/scroll-top"], function (_exports, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTop.default, {
    activate() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', () => {
        $('.ember-application').addClass('no-body-padding');
      });
    },

    deactivate() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', () => {
        $('.ember-application').removeClass('no-body-padding');
      });
    }

  });

  _exports.default = _default;
});