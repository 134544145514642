define("frontend/routes/base-route", ["exports", "frontend/mixins/scroll-top"], function (_exports, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTop.default, {
    session: Ember.inject.service(),
    authenticationRoute: 'users.login',
    accountSession: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    rollbackHasMany(path) {
      this.get(path).forEach(m => {
        if (m.get('hasDirtyAttributes')) {
          m.rollbackAttributes();
        }
      });
    },

    beforeModel() {
      this._super(...arguments);

      let routeName = this.get('routeName');

      if (this.get('currentUser.user')) {
        if (this.get('accountSession').isPetParent()) {
          let notAllowedRoute = !routeName.startsWith('portal') && !routeName.startsWith('edit-client') && !routeName.startsWith('add-client');

          if (notAllowedRoute) {
            this.handlePetParentAccessingStaffApp();
          }
        } else {
          if (routeName.startsWith('portal')) {
            this.handleStaffAccessingPetParentApp();
          }
        }
      }
    },

    handlePetParentAccessingStaffApp() {
      if (this.get('currentUser.user.hasMultipleAccounts')) {
        let redirectHref = window.location.href;
        let notNow = Ember.String.htmlSafe('<span class="flash-notification--one-line-button">Not now</span>');
        this.get('flashMessage').confirm(() => {
          let companyId = this.get('currentUser.user.staff_companies.firstObject.id');
          this.get('accountSession').switchAccounts(companyId, redirectHref);
        }, () => {
          /* noop */
        }, 'You need to switch to your business account to access what you\'re trying to view!', 'Switch', notNow);
        this.transitionTo('portal');
      } else {
        this.get('flashMessage').error('Sorry! You don\'t have access to this account!');
        this.transitionTo('portal');
      }
    },

    handleStaffAccessingPetParentApp() {
      if (this.get('currentUser.user.hasMultipleAccounts')) {
        let redirectHref = window.location.href;
        let notNow = Ember.String.htmlSafe('<span class="flash-notification--one-line-button">Not now</span>');
        this.get('flashMessage').confirm(() => {
          this.get('accountSession').switchAccounts(0, redirectHref);
        }, () => {
          /* noop */
        }, 'You need to switch to your pet parent account to access what you\'re trying to view!', 'Switch', notNow);
        this.transitionTo('schedule');
      } else {
        this.get('flashMessage').error('Sorry! You don\'t have access to this account!');
        this.transitionTo('schedule');
      }
    }

  });

  _exports.default = _default;
});