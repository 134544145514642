define("frontend/components/quickbooks-effective-date-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    effectiveDate: null,
    passThruParams: computed(function () {
      let date = moment().format('YYYY-MM-DD');
      return {
        max: date
      };
    }),
    actions: {
      close() {
        this.get('onClose')();
      },

      connect() {
        this.get('connect')(this.get('effectiveDate'));
      }

    }
  });

  _exports.default = _default;
});