define("frontend/components/invoices-widget/invoices-group", ["exports", "frontend/utils/invoice-type"], function (_exports, _invoiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['invoices-group'],
    tagName: 'section',
    store: inject.service(),
    collapsed: false,
    classNameBindings: ['collapsed'],
    isDraft: computed.equal('invoiceType', _invoiceType.default.DRAFT),
    isSent: computed.equal('invoiceType', _invoiceType.default.SENT),
    isDue: computed.equal('invoiceType', _invoiceType.default.DUE),
    isPaid: computed.equal('invoiceType', _invoiceType.default.PAID),
    page: 1,
    per: 10,
    reachedInfinity: false,
    isFetching: false,

    didInsertElement() {
      this._super(...arguments);

      this.set('invoices', []);
    },

    queryInvoices() {
      let invoicesGroup = this.get('invoicesGroup');
      let date = invoicesGroup.get('end_date');
      let invoice_type = this.get('invoiceType');
      let page = this.get('page');
      let per = this.get('per');
      return this.get('store').query('invoice', {
        page,
        per,
        date,
        invoice_type
      });
    },

    actions: {
      toggleCollapsed() {
        this.toggleProperty('collapsed');
      },

      clickInvoice() {
        this.get('clickInvoice')(...arguments);
      },

      infinityLoad() {
        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        this.queryInvoices().then(invoices => {
          this.set('reachedInfinity', this.get('page') >= invoices.get('meta.total_pages'));
          this.get('invoices').pushObjects(invoices.toArray());
        }).finally(() => {
          this.set('isFetching', false);
          this.incrementProperty('page');
        });
      }

    }
  });

  _exports.default = _default;
});