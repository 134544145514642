define("frontend/components/add-email-side-panel", ["exports", "frontend/config/environment", "ember-cp-validations", "frontend/utils/email-type", "jquery"], function (_exports, _environment, _emberCpValidations, _emailType, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'email.email_type': (0, _emberCpValidations.validator)('presence', true),
    'trimmedEmailAddress': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    emailOptions: [{
      label: 'Preferred',
      value: _emailType.default.PREFERRED
    }, {
      label: 'Alternate',
      value: _emailType.default.ALTERNATE
    }],
    isInvalid: computed.not('validations.isValid'),
    email: null,
    householdUser: null,
    household: null,
    media: inject.service(),
    trimmedEmailAddress: computed('email.address', function () {
      return this.get('email.address').trim();
    }),
    canRemove: computed('householdUser', 'householdUser.emails.[]', function () {
      let emails = this.get('householdUser.emails');
      let count = 0;

      if (!emails || emails.length == 0) {
        return false;
      }

      emails.forEach(email => {
        if (!email.get('isDeleted')) {
          count += 1;
        }
      });
      return count > 1;
    }),
    actions: {
      close() {
        if (this.close) {
          this.close(this.get('email'));
        }
      },

      remove() {
        if (this.remove) {
          this.remove(this.get('email'));
        }
      },

      redirectToSearch() {
        if (this.redirectToSearch) {
          this.redirectToSearch(this.get('email').address);
        }
      },

      removeErrorDisplay() {
        this.set('emailError', null);
      },

      save() {
        this.set('email.address', this.get('trimmedEmailAddress'));
        let email = this.get('email').address;
        let household = this.get('household');
        let url = `${_environment.default.APP.host}/api/v2/email/duplicate_check`;
        let data = {
          email
        };

        if (this.get('householdUser.id')) {
          data['user_id'] = this.get('householdUser.id');
        }

        if (household) {
          data['household_id'] = household.get('id');
        }

        _jquery.default.ajax({
          url,
          method: 'POST',
          data
        }).done(response => {
          if (response.duplicate) {
            let errorClass = '';

            if (this.get('media.isMobile')) {
              errorClass += 'mobile-error-email-container';
            } else {
              errorClass += 'error-email-container';
            }

            if (response.part_of_household) {
              errorClass += ' part-of-household-duplicate';
            } else {
              errorClass += ' user-email-duplicate';
            }

            this.set('emailError', errorClass);
          } else {
            if (this.save) {
              this.save(this.get('email'));
            }
          }
        });
      }

    }
  });

  _exports.default = _default;
});