define("frontend/components/grouped-appointment-request-list-item", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['grouped-appointment-list-item'],
    classNameBindings: ['swipedLeft', 'hasHoverButtons:has-hover-buttons'],
    appointmentRequest: null,
    media: inject.service(),
    isExpired: computed('appointmentRequest.starts_at', 'appointmentRequest.starts_at_time', function () {
      let now = moment().tz(this.get('appointmentRequest.time_zone'));

      if (this.get('appointmentRequest.offered_service.all_day_service')) {
        return now > moment(this.get('appointmentRequest.starts_at')).add(1, 'day');
      }

      let day = moment(this.get('appointmentRequest.starts_at')).format('YYYY-MM-DD');
      let pickupWindow = this.get('appointmentRequest.pickupWindow');

      if (pickupWindow) {
        // eslint-disable-next-line no-unused-vars
        let [startTime, endTime] = pickupWindow.split(' - ');
        return now > moment(`${day} ${startTime}`, 'YYYY-MM-DD h:mmA');
      } else {
        let time = this.get('appointmentRequest.starts_at_time');
        return now > moment(`${day} ${time}`, 'YYYY-MM-DD h:mm A');
      }
    }),
    hasHoverButtons: computed.not('isExpired'),
    companyLogoOrPlaceholder: computed('appointmentRequest.company_logo', function () {
      if (this.get('appointmentRequest.company_logo')) {
        return this.get('appointmentRequest.company_logo');
      } else {
        return 'assets/images/company-image.svg';
      }
    }),
    addOnServices: computed('appointmentRequest.add_on_services.[]', function () {
      let appts = this.get('appointmentRequest.add_on_services');
      let addOnServices = [];
      appts.forEach(appt => {
        addOnServices.pushObject(appt);
      });
      return addOnServices;
    }),
    addOnServicesTooltipText: computed('appointmentRequest.add_on_services.@each.offered_service.name', function () {
      let addOns = this.get('appointmentRequest.add_on_services');
      let text = '<div class="tooltip-header">add-ons:</div>';
      addOns.forEach(addOn => {
        text += `<div class="tooltip-add-on-service">+ ${addOn.offered_service.name} (${addOn.offered_service.duration_display})`;

        if (!addOn.includes_all_pets) {
          text += ` for <span class="bold">${_stringUtil.default.joinWithAnd(addOn.pets.mapBy('name'))}</span>`;
        }

        text += '</div>';
      });
      return text;
    }),
    actions: {
      cancelAppointmentRequest() {
        this.get('cancelAppointmentRequest')(this.get('appointmentRequest'));
      },

      modifyAppointmentRequest() {
        this.get('modifyAppointmentRequest')(this.get('appointmentRequest'));
      },

      mobileClickAppointment() {
        if (this.get('media.isMobile') && this.get('hasHoverButtons')) {
          this.toggleProperty('swipedLeft');
        }
      }

    }
  });

  _exports.default = _default;
});