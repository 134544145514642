define("frontend/components/billing-inputs", ["exports", "frontend/utils/invoice-frequency-options", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _invoiceFrequencyOptions, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ["billing-inputs"],
    householdUser: null,
    household: computed.alias("householdUser.household"),
    noBillDeliveryRecipients: computed.readOnly("householdUser.household.noBillDeliveryRecipients"),
    saving: false,
    showCardPanel: false,
    currentUser: inject.service(),
    showingAutoPayModal: false,
    company: computed.readOnly("currentUser.user.company"),
    stripeInfo: inject.service(),
    flashMessage: inject.service(),
    plaidRedirect: false,
    hasPaymentMethod: computed.or("showSavedCard", "showSavedBankAccount"),
    disableBillingInfo: computed.and("household.isPendingStatus", "household.locked"),
    hasSentRequest: false,
    allInvoiceFrequencies: [],
    companyId: computed.readOnly("currentUser.user.company.id"),
    showConfirmationModalToTurnOffAutoBill: false,

    didRender() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll("#re-send")).on("click", () => {
        if (!this.get("hasSentRequest")) {
          let householdId = this.get("household.id");
          let email = this.get("household.request_sent_to");
          this.set("hasSentRequest", true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/households/${householdId}/add_company`,
            method: "POST",
            data: {
              id: householdId,
              email
            }
          }).done(() => {
            this.get("flashMessage").sendFlashMessage(`Your request to connect was sent to ${email}`, "Success");
          });
        }
      });
    },

    autoPayClarificationNote: computed("householdUser.household.isPendingStatus", "hasSentRequest", function () {
      let isPending = this.get("household.isPendingStatus");
      let isExistingUser = this.get("household.locked");
      let requiresAutoPay = this.get("company.auto_pay") == "required";
      let hasSentRequest = this.get("hasSentRequest");

      if (isPending && isExistingUser && requiresAutoPay) {
        return `<div>AutoPay will be enabled automatically when the ${this.get("householdUser.household.owner.last_name")} family confirms you as their care provider! ${hasSentRequest ? "" : '<span class="re-send-request" id="re-send">re-send request</span>'}</div>`;
      }

      if (isPending && isExistingUser && !requiresAutoPay) {
        return `<div>You won’t be able to enable AutoPay until the ${this.get("householdUser.household.owner.last_name")} family confirms you as their care provider! ${hasSentRequest ? "" : '<span class="re-send-request" id="re-send">re-send request</span>'}</div>`;
      }
    }),
    fetchingCustomerInfo: computed.readOnly("stripeCustomerInfo.isPending"),
    stripeCustomerInfo: computed("householdUser.id", "currentUser.user.company.id", function () {
      let userId = this.get("householdUser.id");
      let companyId = this.get("currentUser.user.company.id");
      let promise = this.get("stripeInfo").fetchCustomerInfo(userId, companyId);
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    savedCardInfo: computed("stripeCustomerInfo.card", function () {
      let customer_info = this.get("stripeCustomerInfo");

      if (customer_info.get("card")) {
        return {
          brand: customer_info.get("card.brand"),
          last_four: customer_info.get("card.last_four")
        };
      } else {
        return null;
      }
    }),
    savedBankAccountInfo: computed("stripeCustomerInfo.bank_account", function () {
      let customer_info = this.get("stripeCustomerInfo");

      if (customer_info.get("bank_account")) {
        return {
          brand: customer_info.get("bank_account.bank_name"),
          last_four: customer_info.get("bank_account.last_four"),
          verified: customer_info.get("bank_account.verified")
        };
      } else {
        return null;
      }
    }),
    showSavedCard: computed("savedCardInfo", "stripeCustomerInfo.default", function () {
      return this.get("stripeCustomerInfo.default") === "card" && this.get("savedCardInfo");
    }),
    showSavedBankAccount: computed("savedBankAccountInfo", "stripeCustomerInfo.default", function () {
      return this.get("stripeCustomerInfo.default") === "bank_account" && this.get("savedBankAccountInfo");
    }),
    invoiceFrequencyOptions: computed("allInvoiceFrequencies.[]", "company.biweeklyStart", "company.biweeklyEnd", function () {
      if (!this.get("allInvoiceFrequencies")) {
        return [];
      }

      let invoiceFrequencies = this.get("allInvoiceFrequencies").map(invoiceFrequency => {
        let label = invoiceFrequency.get("displayName");
        let value = invoiceFrequency.get("name");
        let biweeklyStart = this.get("company.biweeklyStart");
        let biweeklyEnd = this.get("company.biweeklyEnd");
        let id = invoiceFrequency.get("id");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += ` (${biweeklyStart} - ${biweeklyEnd})`;
        }

        return {
          value,
          label,
          id
        };
      }).sort((a, b) => a.id - b.id);
      return invoiceFrequencies;
    }),
    pricingOptions: [{
      value: false,
      label: "Standard"
    }, {
      value: true,
      label: "Custom"
    }],
    anySidePanelActive: false,
    autoPayClarify: computed("timePeriodClarify", function () {
      let timePeriodClarify = this.get("timePeriodClarify");
      return `We will automatically charge the payment method on file for the full outstanding balance ${timePeriodClarify} and send an itemized receipt instead of an invoice.`;
    }),
    timePeriodClarify: computed("householdUser.invoice_frequency", function () {
      switch (this.get("householdUser.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    invoiceFrequencyClarify: computed("timePeriodClarify", function () {
      let timePeriodClarify = this.get("timePeriodClarify");

      if (timePeriodClarify) {
        return `Invoice emails will be sent ${timePeriodClarify} to:`;
      } else {
        return "You have selected not to accrue how much this client owes you. No bills will be generated.";
      }
    }),

    updatePaymentMethod(userId, companyId, token) {
      let paymentMethod = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "bank_account";
      this.set("updatingPaymentMethod", true);
      this.get("stripeInfo").createPaymentMethod(this.get("householdUser.user.id"), token, paymentMethod).then(() => {
        this.notifyPropertyChange("stripeCustomerInfo");
        this.set("updatingPaymentMethod", false);
      }).catch(result => {
        this.get("flashMessage").sendFlashMessage(result.payload.message, "user-error");
        this.set("showingAutoPayModal", true);
        this.set("updatingPaymentMethod", false);
      });
    },

    autoBillSwitch: computed("householdUser.invoice_frequency", function () {
      return this.get("householdUser.invoice_frequency") !== null;
    }),
    salesTaxSwitch: computed("householdUser.sales_tax_policy_id", function () {
      return this.get('householdUser.sales_tax_policy_id') !== null;
    }),
    selectedInvoiceFrequency: computed("householdUser.invoice_frequency", "invoiceFrequencyOptions.[]", function () {
      return this.get("invoiceFrequencyOptions").filterBy("value", this.get("householdUser.invoice_frequency")).get("firstObject");
    }),
    selectedSalesTaxPolicy: computed("householdUser.sales_tax_policy_id", "company.sales_tax_policies.[]", function () {
      if (!this.get("householdUser.sales_tax_policy_id")) {
        return null;
      }

      return this.get('company.sales_tax_policies').filterBy("id", this.get("householdUser.sales_tax_policy_id").toString()).get("firstObject");
    }),
    actions: {
      setSaving() {
        if (this.setSaving) {
          this.setSaving();
        }
      },

      closePaymentMethodModal() {
        this.set("showingAutoPayModal", false);
        this.set("plaidRedirect", false);
      },

      savePaymentMethod(token, paymentMethod) {
        this.set("plaidRedirect", false);
        this.set("showingAutoPayModal", false);
        let userId = this.get("householdUser.user.id");
        let companyId = this.get("currentUser.user.company.id");
        this.updatePaymentMethod(userId, companyId, token, paymentMethod);
      },

      activateSidePanel() {
        this.set("anySidePanelActive", true);
      },

      deactivateSidePanel() {
        this.set("anySidePanelActive", false);

        if (this.reloadOwner) {
          this.reloadOwner();
        }
      },

      activateCustomPricesSidePanel() {
        this.set("customPricesSidePanelActive", true);
        this.send("activateSidePanel");
      },

      deactivateCustomPricesSidePanel() {
        this.set("customPricesSidePanelActive", false);
        this.send("deactivateSidePanel");
      },

      redirectToMicrodeposits() {
        this.get("redirectToMicrodeposits")();
      },

      changeInvoiceFrequency(invoiceFrequency) {
        this.set("previousInvoiceFrequency", this.get("householdUser.invoice_frequency"));
        this.set("householdUser.invoice_frequency", invoiceFrequency.value);
        let householdId = this.get("householdUser.household.id");
        let companyId = this.get("currentUser.user.company.id");
        this.set("loadingStartBillingDate", true);
        this.set("showInvoiceFrequencyEffectiveDate", true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/billing_start_date?company_id=${companyId}&invoice_frequency=${invoiceFrequency.value}`,
          method: "GET"
        }).done(response => {
          this.set("newBillingPeriodStartDate", moment(response.start_date));
          this.set("loadingStartBillingDate", false);
        });
      },

      closeInvoiceFrequencyEffectiveDate() {
        let successful = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (!successful) {
          this.set("householdUser.invoice_frequency", this.get("previousInvoiceFrequency"));
        }

        this.set("showInvoiceFrequencyEffectiveDate", false);
      },

      handleSalesTaxSwitch(newSwitchValue) {
        let defaultSalesTaxPolicy = this.get("company.sales_tax_policies").find(stp => stp.get('is_default'));

        if (!newSwitchValue) {
          defaultSalesTaxPolicy = null;
        }

        this.send('changeSalesTaxPolicy', defaultSalesTaxPolicy);
      },

      changeSalesTaxPolicy(salesTaxPolicy) {
        this.set("householdUser.sales_tax_policy_id", salesTaxPolicy?.id);

        if (this.get('saveHouseholdUser')) {
          this.get('saveHouseholdUser')();
        }
      },

      handleAutoBillSwitch(autoBill) {
        if (autoBill) {
          this.send('changeAutoBillSwitch', autoBill);
        } else {
          this.set("showConfirmationModalToTurnOffAutoBill", true);
        }
      },

      turnOffAutoBillSwitch() {
        this.send('changeAutoBillSwitch', false);
        this.send('hideConfirmationModalToTurnOffAutoBill');
      },

      hideConfirmationModalToTurnOffAutoBill() {
        this.set("showConfirmationModalToTurnOffAutoBill", false);
      },

      changeAutoBillSwitch(autoBill) {
        let invoiceFrequencies = this.get("company.invoice_frequencies");

        if (!autoBill || invoiceFrequencies.get("length") === 0) {
          this.set("householdUser.invoice_frequency", null);
        } else {
          this.set("householdUser.invoice_frequency", this.get("company.default_invoice_frequency.name"));
        }

        if (this.get("saveHouseholdUser")) {
          this.get("saveHouseholdUser")(true);
        }
      },

      updateAutoPay(autoPay) {
        if (this.get("company.auto_pay") == "required") {
          this.get("flashMessage").error(`Sorry! ${this.get("company.displayName")} requires all clients to be on AutoPay!`);
          return;
        }

        this.set("householdUser.auto_pay", autoPay);

        if (this.get("saveHouseholdUser")) {
          this.get("saveHouseholdUser")();
        }
      },

      savedOfferedServicePrice() {
        if (this.savedOfferedServicePrice) {
          this.savedOfferedServicePrice(...arguments);
        }
      },

      saveHouseholdUser() {
        if (this.saveHouseholdUser) {
          this.saveHouseholdUser(...arguments);
        }
      },

      saveBillDeliveryOptions(household) {
        if (household.get("content.save")) {
          household = household.get("content");
        }

        household.save();
      },

      showRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", true);
      },

      confirmRemovePaymentMethod() {
        let householdId = this.get("household.id");
        let defaultMethod = this.get("stripeCustomerInfo.default");
        let payment_method_id = this.get(`stripeCustomerInfo.${defaultMethod}.id`);
        let brand = this.get(`stripeCustomerInfo.${defaultMethod}.brand`);
        let lastFour = this.get(`stripeCustomerInfo.${defaultMethod}.last_four`);
        this.set("showRemovePaymentMethod", false);
        this.set("updatingPaymentMethod", true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${householdId}/stripe_payment_method`,
          method: "DELETE",
          data: {
            payment_method_id
          }
        }).then(() => {
          this.set("updatingPaymentMethod", false);
          this.notifyPropertyChange("stripeCustomerInfo");
          this.get("flashMessage").success(`You've removed ${brand} ending ${lastFour} from the ${this.get("householdUser.household.owner.last_name")} family`);
        });
      },

      cancelRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", false);
      }

    }
  });

  _exports.default = _default;
});