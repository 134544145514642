define("frontend/components/country-autocomplete", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    countryInfo: inject.service(),
    countries: computed.readOnly('countryInfo.countries')
  });

  _exports.default = _default;
});