define("frontend/services/appointment-times", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;
  const STEP_MINUTES = 15;

  var _default = Ember.Service.extend({
    timeZone: service(),

    allTimes() {
      let start = (0, _moment.default)().startOf('day').add(6, 'hours');
      let end = (0, _moment.default)().endOf('day');
      let times = [];

      while (end.diff(start, 'minutes') > STEP_MINUTES) {
        let time = start.format('h:mm A');
        times.push({
          value: time,
          label: `${time}`
        });
        start = start.add(STEP_MINUTES, 'minutes');
      }

      return times;
    }

  });

  _exports.default = _default;
});