define("frontend/components/payment-form/stripe-card", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    elementId: 'card-info',
    classNames: ['pay-container', 'center-block'],
    showSavedCard: computed.and('savedCardInfo', 'notChangingCard'),
    notChangingCard: computed.not('changingCard'),
    changingCard: false,
    savedCardInfo: null,

    didInsertElement() {
      // eslint-disable-next-line new-cap
      let stripe = Stripe(_environment.default.stripe_api_key);
      let elements = stripe.elements();
      let style = {
        base: {
          fontSize: '16px',
          color: '#32325d'
        }
      };
      let card = elements.create('card', {
        hidePostalCode: true,
        style
      });
      this.set('card', card);
      card.mount('#card-container');
      card.addEventListener('change', response => {
        let {
          error
        } = response;

        if (error) {
          this.set('error', error.message);
        } else {
          this.set('error', null);
        }
      });
      this.get('setCard')(card, stripe);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('card').removeEventListener('change');
    }

  });

  _exports.default = _default;
});