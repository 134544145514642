define("frontend/routes/edit-recurring-appointment-group", ["exports", "frontend/routes/base-route", "moment", "frontend/mixins/transition-back-route"], function (_exports, _baseRoute, _moment, _transitionBackRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    errorHandler: Ember.inject.service(),
    flashMessage: Ember.inject.service(),

    model(params) {
      this._super(...arguments);

      let recurringAppointmentGroup = this.store.findRecord('recurring-appointment-group', params.id, {
        reload: true
      });
      let offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      let careProviders = this.store.findAll('care-provider');
      let serviceCategories = this.store.query('service-category', {}, {
        reload: true
      });
      return Ember.RSVP.hash({
        recurringAppointmentGroup,
        offeredServices,
        careProviders,
        serviceCategories
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('redirecting', false);
      controller.set('saving', false);
    },

    firstWeekdayFromDate(dayOfWeek, date, repeatsBiweekly) {
      let momentDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      let daysDiff = dayOfWeek - momentDate.day();

      if (daysDiff < 0 && !repeatsBiweekly) {
        daysDiff += 7;
      }

      let weekDay = momentDate.add(daysDiff, 'days');
      let SUNDAY = 0;

      if (weekDay.isBefore((0, _moment.default)().startOf('day')) && repeatsBiweekly) {
        if (dayOfWeek === SUNDAY) {
          weekDay.add(1, 'week');
        } else {
          weekDay.add(2, 'weeks');
        }
      }

      return weekDay.format('YYYY-MM-DD');
    },

    actions: {
      cancel(recurringAppointmentGroup) {
        let indexController = Ember.getOwner(this).lookup('controller:edit-recurring-appointment-group.index');
        let deletedRecurringAppointments = indexController.get('deletedRecurringAppointments');
        let recurringAppointments = recurringAppointmentGroup.get('recurring_appointments');
        recurringAppointments.pushObjects(deletedRecurringAppointments);
        recurringAppointments.forEach(ra => ra.rollbackAttributes());
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId).then(() => window.location.reload());
      },

      remove(recurringAppointmentGroup, backHouseholdId) {
        let queryParams = {
          backRoute: 'edit-recurring-appointment-group.index',
          backHouseholdId
        };
        return this.transitionTo('remove-recurring-appointment-group', recurringAppointmentGroup.get('id'), {
          queryParams
        });
      },

      save(recurringAppointmentGroup) {
        let indexController = Ember.getOwner(this).lookup('controller:edit-recurring-appointment-group.index');

        if (this.get('controller.saving')) {
          return false;
        }

        let changesStartOn = indexController.get('startDate');
        let frequency = indexController.get('selectedRepeatOption');
        let end_date = indexController.get('selectedEndDateOption') === 'never' ? 'never' : indexController.get('endDate');
        let made_changes = indexController.get('madeChanges');
        recurringAppointmentGroup.set('changes_start_on', changesStartOn);
        recurringAppointmentGroup.set('end_date', end_date);
        recurringAppointmentGroup.set('made_changes', made_changes);
        this.set('controller.saving', true);
        recurringAppointmentGroup.get('recurring_appointments').forEach(recurringAppointment => {
          let startDate = recurringAppointment.get('start_date');
          let dayOfWeek = (0, _moment.default)(startDate, 'YYYY-MM-DD').day();

          if (!recurringAppointment.get('isDeleted')) {
            if (changesStartOn) {
              let newStartDate = this.firstWeekdayFromDate(dayOfWeek, changesStartOn, frequency === 'biweekly');
              recurringAppointment.set('start_date', newStartDate);
            }

            recurringAppointment.set('end_date', end_date);
            recurringAppointment.set('frequency', frequency);
            let addOnServiceIds = recurringAppointment.get('recurring_appointment_add_on_services').map(raaos => raaos.get('offered_service.id'));
            recurringAppointment.set('add_on_service_ids', addOnServiceIds);
          }
        });
        recurringAppointmentGroup.save().then(() => {
          let backRoute = this.get('controller.backRoute');
          let backHouseholdId = this.get('controller.backHouseholdId');
          let backId = this.get('controller.backId');

          if (backRoute == 'companies.remove-staff') {
            window.location.href = `companies/${backHouseholdId}/remove-staff/${backId}`;
          } else if (backRoute == 'companies.remove-service') {
            window.location.href = `companies/${backHouseholdId}/remove-service/${backId}`;
          } else {
            window.location.href = `client-list/${backHouseholdId}/appointments?appointmentType=repeating`;
          }
        }).catch(error => {
          this.set('controller.saving', false);
          const unknownError = "An unknown error occurred";
          const errorMessage = error?.errors?.[0]?.detail?.detail ?? unknownError;
          this.get('flashMessage').sendFlashMessage(errorMessage, 'user-error');
        });
      }

    }
  });

  _exports.default = _default;
});