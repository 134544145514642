define("frontend/routes/profile/edit-about-me", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition"], function (_exports, _baseRoute, _cleanupOnTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    currentUser: Ember.inject.service(),

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('addressSidePanelActivated', false);
      let phoneNumbers = model.get('phone_numbers');

      if (phoneNumbers.get('length') == 0) {
        let defaultPhone = this.store.createRecord('phone-number');
        model.get('phone_numbers').pushObject(defaultPhone);
      }

      let addresses = model.get('addresses');

      if (addresses.get('length') == 0) {
        let user = this.get('currentUser.user');
        let state = user.get('company.state');
        let country = user.get('company.country');
        let defaultAddress = this.store.createRecord('address', {
          state,
          country
        });
        model.get('addresses').pushObject(defaultAddress);
      }
    },

    actions: {
      willTransition() {
        this._super(...arguments);

        this.get('controller.model.phone_numbers').filterBy('hasDirtyAttributes', true).forEach(p => p.rollbackAttributes());
        this.get('controller.model.addresses').filterBy('hasDirtyAttributes', true).forEach(a => a.rollbackAttributes());
        this.get('controller.model.emails').filterBy('hasDirtyAttributes', true).forEach(e => e.rollbackAttributes());
      },

      redirectToSearch(email) {
        this.transitionTo('staff-search', {
          queryParams: {
            email
          }
        });
      }

    }
  });

  _exports.default = _default;
});