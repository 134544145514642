define("frontend/components/message-thread/new-message-household-select", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--new-message-household-select'],
    households: [],
    selectedHouseholds: [],
    isSelectingHouseholds: false,
    familiesWithAppointmentsText: '',
    familiesWithAppointmentsTitle: '',
    selectedRecipients: null,
    selectedServiceCategories: [],
    selectedDates: [],
    specificDatesSelected: false,
    step: 1,
    currentUser: inject.service(),
    isLoadingAppts: false,
    actions: {
      closeSelectingHouseholdsModal() {
        this.set('isSelectingHouseholds', false);

        if (!Ember.isBlank(this.get('selectedServiceCategories'))) {
          this.set('selectedDistributionList', 'selectedAppointments');
        } else {
          this.set('selectedDistributionList', '');
        }
      },

      openSelectingHouseholdsModal() {
        if (!Ember.isBlank(this.get('selectedServiceCategories')) && !Ember.isBlank(this.get('selectedRecipients'))) {
          this.send('selectAppointmentsOnDates');
        } else {
          this.set('isSelectingHouseholds', true);
        }
      },

      changeSelectingHouseholdsModal() {
        this.set('selectedDistributionList', 'selectedAppointments');

        if (this.get('selectedRecipients') === 'specific-dates') {
          this.set('step', 2);
        } else {
          this.set('step', 1);
        }

        this.set('isSelectingHouseholds', true);
      },

      infinityLoad() {
        this.infinityLoad(...arguments);
      },

      clickedHousehold(household) {
        if (this.get('selectedDistributionList') === '') {
          this.clickedHousehold(household);
        }
      },

      selectAllHouseholds() {
        this.set('selectedHouseholds', this.get('households'));
        this.set('selectedDistributionList', 'allHouseholds');
      },

      deselectAllHouseholds() {
        this.set('selectedHouseholds', []);
        this.set('selectedDistributionList', '');
      },

      selectAppointmentsOnDates() {
        // Fixes an issue with the change button also triggering
        // the regular button toggle
        this.set('selectedDistributionList', 'selectedAppointments');
        this.set('isLoadingAppts', true);
        this.set('isSelectingHouseholds', false);
        let serviceCategoryIds = this.get('selectedServiceCategories').map(_ref => {
          let {
            id
          } = _ref;
          return id;
        });
        let dateSelection = this.get('selectedRecipients');
        let serviceCategoryNames = this.get('selectedServiceCategories').map(serviceCategory => serviceCategory.get('name'));
        let serviceCategoryShortenedNames = serviceCategoryNames.map(name => {
          switch (name) {
            case 'Dog Walking':
              return 'walks';

            case 'Dog Hiking':
              return 'hikes';

            case 'Pet Sitting':
              return 'drop-ins';

            case 'Overnights':
              return 'overnights';

            case 'Spa & Grooming':
              return 'grooming appointments';

            case 'Miscellaneous':
              return 'other services';

            default:
              return name.toLowerCase();
          }
        });

        if (dateSelection === 'specific-dates') {
          let dates = this.get('selectedDates');

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/household_ids_by_dates`,
            method: 'GET',
            data: {
              company_id: this.get('currentUser.user.company.id'),
              dates,
              service_category_ids: serviceCategoryIds
            }
          }).then(ids => {
            if (dates.length > 1) {
              let formattedDates = dates.map(date => moment.parseZone(new Date(date).toISOString()).format('M/D'));
              this.set('familiesWithAppointmentsText', _stringUtil.default.joinWithAnd(formattedDates, 'or'));
            } else {
              let formattedDates = dates.map(date => moment.parseZone(new Date(date).toISOString()).format('dddd, M/D'));
              this.set('familiesWithAppointmentsText', formattedDates[0]);
            }

            if (serviceCategoryShortenedNames.length === this.get('serviceCategories.length')) {
              this.set('familiesWithAppointmentsTitle', 'Families with appointments on...');
            } else {
              this.set('familiesWithAppointmentsTitle', `Families with ${_stringUtil.default.joinWithAnd(serviceCategoryShortenedNames)} on...`);
            }

            this.set('specificDatesSelected', true);
            this.set('selectedDistributionList', 'selectedAppointments');
            this.set('selectedHouseholds', ids.map(id => ({
              id
            })));
            this.set('isLoadingAppts', false);
          });
        } else {
          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/messages/household_ids_by_dates`,
            method: 'GET',
            data: {
              company_id: this.get('currentUser.user.company.id'),
              numberOfMonths: dateSelection,
              service_category_ids: serviceCategoryIds
            }
          }).then(ids => {
            this.set('selectedDistributionList', 'selectedAppointments');
            this.set('selectedHouseholds', ids.map(id => ({
              id
            })));
            this.set('familiesWithAppointmentsText', `in the last ${dateSelection} months`);

            if (serviceCategoryShortenedNames.length === this.get('serviceCategories.length')) {
              this.set('familiesWithAppointmentsTitle', 'Families with appointments on...');
            } else {
              this.set('familiesWithAppointmentsTitle', `Families with ${_stringUtil.default.joinWithAnd(serviceCategoryShortenedNames)} on...`);
            }

            this.set('specificDatesSelected', false);
            this.set('isLoadingAppts', false);
            this.set('isSelectingHouseholds', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});