define("frontend/utils/action-bar-item-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ADDRESS = 'address';
  const EMAIL = 'email';
  const PHONE = 'phone';
  const MOBILE_PHONE = 'mobilePhone';
  var _default = {
    ADDRESS,
    EMAIL,
    PHONE,
    MOBILE_PHONE
  };
  _exports.default = _default;
});