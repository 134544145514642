define("frontend/components/bootstrap-datepicker", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['bootstrap-datepicker'],
    datepicker: null,
    showOneWeek: false,
    offsetDay: null,
    showToday: false,
    multiDate: false,
    firstChangeDate: true,
    currentUser: inject.service(),
    startDate: null,
    startWithNothingSelected: false,
    setDateOnChange: true,
    disabledDays: [],
    highlightedDays: [],
    company: computed.alias('currentUser.user.company'),

    initializeCalendar(weekStart) {
      let dp = (0, _jquery.default)(this.element).datepicker({
        format: 'yyyy-mm-dd',
        weekStart,
        autoclose: true,
        todayHighlight: true,
        container: '.calendar-container',
        showOtherMonths: false,
        multidate: this.get('multiDate'),
        selectOtherMonths: false,
        startDate: this.get('startDate'),
        daysOfWeekDisabled: this.get('disabledDays'),
        beforeShowDay: this.shouldHighlightDay.bind(this)
      }, 'en').on('changeDate', e => {
        Ember.run(() => {
          if (this.get('firstChangeDate')) {
            this.set('firstChangeDate', false);

            if (!this.get('startWithNothingSelected')) {
              return;
            }
          }

          if (this.get('showOneWeek')) {
            this.updateWeek(e.date);
          }

          if (this.get('multiDate')) {
            this.changeDates(e.dates);
          } else {
            let date = (0, _moment.default)(e.date).format('YYYY-MM-DD');
            this.changeDate((0, _moment.default)(e.date));

            if (this.get('setDateOnChange')) {
              this.set('value', date);
            }
          }
        });
      });

      if (this.get('value')) {
        dp.datepicker('setDate', this.get('value'));
      }

      if (this.get('values')) {
        dp.datepicker('setDates', this.get('values'));
      }

      this.set('datepicker', dp);

      if (this.get('showOneWeek')) {
        this.addSwipeHandlers();
        this.updateWeek(this.get('value'));
        (0, _jquery.default)(this.element.querySelectorAll('.datepicker-switch')).click(() => {
          Ember.run.scheduleOnce('afterRender', () => {
            this.set('showToday', false);
          });
        });
      }
    },

    shouldHighlightDay(date) {
      if (this.get('highlightedDays').includes(date.toISOString().split('T')[0])) {
        return 'highlighted';
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('company').then(company => {
        let weekStart = 0;

        if (company && company.get('week_starts_on') == 'Monday') {
          weekStart = 1;
        }

        this.initializeCalendar(weekStart);
      });
    },

    addSwipeHandlers() {
      let self = this;
      let dp = this.get('datepicker');
      let weekType = this.get('company.weekType');
      (0, _jquery.default)(this.element).swipe({
        swipeLeft() {
          let newOffset = (0, _moment.default)(self.get('value'), 'YYYY-MM-DD').add(1, 'week').startOf(weekType).format('YYYY-MM-DD');
          dp.datepicker('setDate', newOffset);
        },

        swipeRight() {
          let newOffset = (0, _moment.default)(self.get('value'), 'YYYY-MM-DD').subtract(1, 'week').endOf(weekType).format('YYYY-MM-DD');
          dp.datepicker('setDate', newOffset);
        },

        threshold: 50
      });
    },

    updateWeek(date) {
      let weekType = this.get('company.weekType');
      let startDate = (0, _moment.default)(date).startOf(weekType);
      let endDate = (0, _moment.default)(date).endOf(weekType);
      (0, _jquery.default)(this.element.querySelectorAll('.day')).each((index, element) => {
        let ele = $(element);
        let val = parseInt(ele.text());
        let parsedDate = (0, _moment.default)(date);

        if (ele.hasClass('old')) {
          parsedDate.subtract(1, 'month');
        }

        if (ele.hasClass('new')) {
          parsedDate.add(1, 'month');
        }

        parsedDate = parsedDate.date(val);

        if (parsedDate.startOf('day').diff(startDate, 'days') < 0 || parsedDate.endOf('day').diff(endDate, 'days') > 0) {
          ele.addClass('hidden');
        } else {
          ele.removeClass('hidden');
        }
      });
    },

    // Checks to see if date was modified upstream
    value: computed({
      set(key, value) {
        let dp = this.get('datepicker');

        if (this.get('showOneWeek')) {
          this.set('showToday', true);
        }

        if (dp && this.$ && (0, _moment.default)((0, _jquery.default)(this.element).datepicker('getDate')).format('YYYY-MM-DD') != value) {
          dp.datepicker('setDate', value);
        }

        return value;
      },

      get() {
        return (0, _jquery.default)(this.element).datepicker('getDate');
      }

    }),

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('datepicker')) {
        this.get('datepicker').off('changeDate');
        this.get('datepicker').datepicker('destroy');
        this.set('datepicker', null);
      }
    },

    isToday: computed('value', function () {
      let date = (0, _moment.default)(this.get('value'), 'YYYY-MM-DD').startOf('day');
      let today = (0, _moment.default)().startOf('day');
      return date.diff(today, 'days') === 0;
    }),
    actions: {
      today() {
        this.set('value', (0, _moment.default)().format('YYYY-MM-DD'));
      }

    }
  });

  _exports.default = _default;
});