define("frontend/controllers/portal/notifications", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    // queryParams: ['company_id'],
    currentUser: inject.service(),
    flashMessage: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    isLoading: true,
    companyUserProfiles: null,
    companyName: null,
    clientCompanies: computed('currentUser', function () {
      let userId = this.get('currentUser.user.id');

      if (userId) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/get_client_companies`,
          method: 'GET'
        }).then(response => {
          this.set('clientCompanies', response);
        });
      }

      return null;
    }),
    companiesWithSettings: computed('activeCompanies.[]', function () {
      let activeCompanies = this.get('activeCompanies');

      if (activeCompanies) {
        let apiPromises = [];
        activeCompanies.forEach(_ref => {
          let {
            company_id
          } = _ref;
          apiPromises.push(_jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/notifications`,
            method: 'GET',
            data: {
              user_id: this.get('currentUser.user.id'),
              company_id
            }
          }));
        });
        Ember.RSVP.Promise.all(apiPromises).then(response => {
          this.set('companyUserProfiles', response);
        });
        if (activeCompanies.length === 1) this.set('companyName', activeCompanies[0]?.company_name);
        if (activeCompanies.length >= 1) this.set('isLoading', false);
        return $.map(activeCompanies, companySettings => {
          return companySettings.company_name;
        });
      }

      return [];
    }),
    selectedCompanySettings: computed('activeCompanies.[]', function () {
      let companySettings = this.get('activeCompanies');

      if (companySettings && companySettings.length > 0) {
        let settings = companySettings;
        let firstSettings = settings.get('firstObject');
        this.set('companyName', firstSettings.company_name);
        return firstSettings;
      }

      return null;
    }),
    activeCompanies: computed('clientCompanies.[]', function () {
      let companySettings = this.get('clientCompanies.settings');

      if (companySettings) {
        return companySettings.filter(settings => !settings.company_deactivated && !settings.denied_request && settings.pet_parents_activated || settings.staff_status == 'Owner');
      }

      return [];
    }),
    selectedCompanyUserProfile: computed('companyUserProfiles.[]', function () {
      let companyUserProfiles = this.get('companyUserProfiles');

      if (companyUserProfiles && companyUserProfiles.length > 0) {
        let settings = companyUserProfiles;
        return settings.get('firstObject');
      }

      return null;
    }),
    actions: {
      changeCompany(companyName) {
        // Set selected company
        let companySettings = this.get('clientCompanies');
        let settings = companySettings.settings.find(s => {
          return s.company_name == companyName;
        });
        this.set('selectedCompanySettings', settings); // Set selected company user profile

        let companyId = settings.company_id;
        let selectedCompanyUserProfile = this.get('companyUserProfiles').find(cup => {
          return cup.company_id == companyId;
        });
        this.set('selectedCompanyUserProfile', selectedCompanyUserProfile);
        this.set('companyName', companyName);
      }

    }
  });

  _exports.default = _default;
});