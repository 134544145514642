define("frontend/controllers/staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    headerText: 'Staff',
    currentUser: inject.service(),
    showingStaffList: false,
    hasSelectedEmployee: false,
    notSelectedEmployee: computed.not('hasSelectedEmployee'),
    notShowingStaffList: computed.not('showingStaffList'),
    showHamburger: computed.and('notSelectedEmployee', 'notShowingStaffList'),
    isOwner: computed.alias('currentUser.user.isOwner'),
    notOwner: computed.not('isOwner'),
    actions: {
      toggleStaffList() {
        this.toggleProperty('showingStaffList');
      },

      backToStaff() {
        this.set('hasSelectedEmployee', false);
        this.set('headerText', 'Staff');
        this.transitionToRoute('staff.index', {
          queryParams: {
            employeeFilter: null
          }
        });
      }

    }
  });

  _exports.default = _default;
});