define("frontend/components/appointment-staff-time", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['appointment-staff-time'],
    classNameBindings: ['active'],
    appointment: null,
    active: false,
    showService: false,
    offeredService: null,
    allRecurringAppointments: null,
    date: null,
    store: inject.service(),
    addOnServices: computed('appointment.addOnServices.[]', 'appointment.recurring_appointment_add_on_services.[]', 'allRecurringAppointments.[]', function () {
      if (this.get('allRecurringAppointments')) {
        let appointment = this.get('appointment');
        let ras = this.get('allRecurringAppointments').filter(ra => {
          return moment(appointment.get('start_date')).day() === moment(ra.get('start_date')).day() && appointment.get('offered_service.id') === ra.get('offered_service.id') && appointment.get('careProvider.id') === ra.get('careProvider.id') && appointment.get('preferred_start_time') === ra.get('preferred_start_time') && appointment.get('timeFrameStart') === ra.get('timeFrameStart') && appointment.get('timeFrameEnd') === ra.get('timeFrameEnd');
        });
        let addOnServices = {};
        ras.forEach(ra => {
          ra.get('recurring_appointment_add_on_services').forEach(raaos => {
            let osId = raaos.get('offered_service.id');

            if (!addOnServices[osId]) {
              addOnServices[osId] = {
                offered_service: raaos.get('offered_service'),
                petIds: [],
                petNames: []
              };
            }

            addOnServices[osId].petIds.pushObject(ra.get('pet.id'));
            addOnServices[osId].petNames.pushObject(ra.get('pet.name'));
          });
        });
        return Object.values(addOnServices);
      } else {
        return this.get('appointment.addOnServices') || this.get('appointment.recurring_appointment_add_on_services');
      }
    }),
    appointmentsWithRecurrence: computed('service.appointmentsWithRecurrence.[]', 'pets.[]', function () {
      return this.get('service.appointmentsWithRecurrence').reject(appointment => {
        return this.get('pets').mapBy('id').indexOf(appointment.get('pet.id')) >= 0;
      });
    }),
    recurringAppointmentGroup: computed('date', 'appointment.date', 'appointment.start_date', 'appointment.time', 'appointment.careProvider.id', function () {
      let time = this.get('appointment.time');
      let care_provider_id = this.get('appointment.careProvider.id');
      let date = this.get('date');

      let promise = _jquery.default.ajax({
        url: '/api/v2/recurring_appointments/slot',
        method: 'GET',
        data: {
          time,
          care_provider_id,
          date
        }
      }).then(resp => resp);

      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    recurringGroupedAppointments: computed('recurringAppointmentGroup.[]', 'appointment.positionsPreviousPets', 'pets.[]', function () {
      let groupedAppointments = this.get('recurringAppointmentGroup');

      if (!groupedAppointments.isFulfilled) {
        return;
      }

      groupedAppointments = groupedAppointments.map(groupedAppt => {
        let selected = false;
        let appointments = groupedAppt.map(appt => {
          if (parseInt(this.get('appointment.id')) == appt.recurring_appointment_id) {
            selected = true;
          }

          return {
            recurring_appointment_id: appt.recurring_appointment_id,
            pet: this.get('store').findRecord('pet', appt.pet.id),
            offered_service_color_hex: appt.offered_service_color_hex
          };
        });
        let petNames = groupedAppt.map(appt => appt.pet.name);
        petNames = petNames.join('/');
        return {
          appointments,
          petNames,
          selected
        };
      });

      if (this.get('appointment.positions_previous_pets')) {
        groupedAppointments = groupedAppointments.reject(ga => ga.appointments.map(a => a.recurring_appointment_id).includes(parseInt(this.get('appointment.id'))));
      }

      return this.addInAppointment(groupedAppointments);
    }),
    service: computed('date', 'appointment.date', 'appointment.careProvider.id', 'appointment.time', function () {
      let care_provider_id = this.get('appointment.careProvider.id');
      let start_date = this.get('date') || this.get('appointment.date') || this.get('appointment.start_date');
      let end_date = this.get('date') || this.get('appointment.date') || this.get('appointment.start_date');
      let promise = this.get('store').query('service', {
        care_provider_id,
        start_date,
        end_date
      }).then(services => {
        return services.filter(service => this.get('appointment.time') === service.get('starts_at_time')).get('firstObject');
      });
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    oneTimeGroupedAppointments: computed('service', 'appointment.positionsPreviousPets', 'pets.[]', function () {
      let groupedAppointments = this.get('service.sortedGroupedAppointmentsWithoutCanceled');
      groupedAppointments = groupedAppointments.map(groupedAppt => {
        let appointments = groupedAppt.get('sortedAppointments').map(appt => {
          return {
            pet: appt.get('pet'),
            offered_service_color_hex: appt.get('offered_service_color_hex')
          };
        });
        let petNames = groupedAppt.get('sortedAppointments').map(appt => appt.get('pet.name'));
        petNames = petNames.join('/');
        return {
          appointments,
          petNames
        };
      });
      return this.addInAppointment(groupedAppointments);
    }),

    addInAppointment(groupedAppointments) {
      let appt = this.get('appointment');
      let previousPets = appt.positionsPreviousPets || appt.get('positions_previous_pets');

      if (!previousPets) {
        return groupedAppointments;
      }

      let position = 0;

      if (previousPets == 'first') {
        position = 0;
      } else if (previousPets == 'last') {
        position = groupedAppointments.length;
      } else {
        groupedAppointments.forEach((groupedAppt, index) => {
          if (groupedAppt.petNames == previousPets) {
            position = index + 1;
          }
        });
      }

      let appointments = this.get('pets').map(pet => {
        return {
          pet,
          offered_service_color_hex: this.get('offeredService.color_hex')
        };
      });
      groupedAppointments.insertAt(position, {
        appointments,
        selected: true
      });
      return groupedAppointments;
    }

  });

  _exports.default = _default;
});