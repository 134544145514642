define("frontend/routes/companies/making-payments", ["exports", "frontend/config/environment", "frontend/routes/base-route", "jquery"], function (_exports, _environment, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),

    model() {
      this._super(...arguments);

      let company = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/companies/${this.get('currentUser.user.company.id')}`,
        method: 'GET'
      });

      return company;
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('disabled', false);
    }

  });

  _exports.default = _default;
});