define("frontend/routes/add-client/documents", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: inject.service(),

    model(params) {
      let {
        household_id
      } = params;
      return this.store.findRecord('household', household_id).then(household => {
        let documents = _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.get('id')}/user_documents`,
          method: 'GET'
        });

        let signatureRequests = _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/client_contracts/household_signature_requests?household_id=${household.get('id')}`,
          method: 'GET'
        });

        return Ember.RSVP.hash({
          household,
          documents,
          signatureRequests,
          owner: household.get('owner')
        });
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);
      controller.set('uploading', false);
    },

    actions: {
      goBack() {
        let household = this.get('controller.model.household');
        let householdId = household.get('id');

        if (this.get('currentUser.user.isAdmin')) {
          return this.transitionTo('add-client.billing-settings', householdId);
        } else {
          let petIndex = household.get('pets.length') - 1;
          return this.transitionTo('add-client.pet', householdId, {
            queryParams: {
              petIndex
            }
          });
        }
      },

      finish(user) {
        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(savedUser => {
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message: `Your request to connect was sent to ${savedUser.get('user').get('email')}`,
            type: 'success'
          });
          return this.transitionTo('client-list.household.household-user', savedUser.get('household.id'), savedUser.get('id'));
        }).finally(() => {
          this.set('saving', false);
          window.location.reload();
        });
      },

      reload() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});