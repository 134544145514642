define("frontend/components/multi-select/select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-option'],
    classNameBindings: ['selected:clicked'],
    thumbnail: null,
    label: null,
    value: null,
    selected: false,

    click() {
      this.toggleProperty('selected');

      if (this.get('selected')) {
        this.selectOption(this.get('value'));
      } else {
        this.deselectOption(this.get('value'));
      }
    }

  });

  _exports.default = _default;
});