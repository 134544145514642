define("frontend/mixins/dates-to-ranges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    datesToRanges(dateArray) {
      let dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ddd M/D';
      let connectingWord = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '-';
      let sortedDates = dateArray.sort().map(date => moment(date));
      let ranges = [];

      for (let i = 0; i < sortedDates.length; i++) {
        let rangeStart = sortedDates[i];
        let rangeEnd = sortedDates[i];
        let startDate = rangeStart.clone();

        while (i + 1 < sortedDates.length && startDate.add(1, 'day').format('YYYY-MM-DD') == sortedDates[i + 1].format('YYYY-MM-DD')) {
          rangeEnd = sortedDates[i + 1];
          i += 1;
        }

        rangeStart = rangeStart.format(dateFormat);
        rangeEnd = rangeEnd.format(dateFormat);

        if (rangeStart == rangeEnd) {
          ranges.push(rangeStart);
        } else {
          ranges.push(`${rangeStart} ${connectingWord} ${rangeEnd}`);
        }
      }

      return ranges;
    }

  });

  _exports.default = _default;
});