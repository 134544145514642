define("frontend/components/payment-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,
    balance: null,
    isPastPayment: null,
    pastPaymentAmount: null,
    pastPaymentDate: null,
    absoluteBalance: Ember.computed('balance', function () {
      return Math.abs(this.get('balance'));
    }),
    whenPastPaymentWasMade: Ember.computed('pastPaymentDate', function () {
      const pastPaymentDate = moment(this.get('pastPaymentDate'));
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');

      if (pastPaymentDate.isSame(today, 'd')) {
        return "today";
      } else if (pastPaymentDate.isSame(yesterday, 'd')) {
        return "yesterday";
      } else {
        const daysAgo = today.diff(pastPaymentDate, 'days');
        return `${daysAgo} days ago`;
      }
    }),
    actions: {
      onClose() {
        this.get('onClose')();
      }

    }
  });

  _exports.default = _default;
});