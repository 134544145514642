define("frontend/components/input-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['input-callout'],
    classNameBindings: ['inputActivated:input-activated'],
    attributeBindings: ['fixedTabIndex:tabindex'],
    fixedTabIndex: computed('inputActivated', 'tabindex', function () {
      if (!this.get('inputActivated')) {
        return this.get('tabindex');
      } else {
        return null;
      }
    }),
    inputDeactivated: computed.not('inputActivated'),
    inputActivated: false,
    inputAction: 'focus',
    inputSelector: 'input',

    didInsertElement() {
      (0, _jquery.default)(this.element).on('focus', () => {
        this.send('clickCallout');
      });
    },

    willDestroyElement() {
      (0, _jquery.default)(this.element).off('focus');
    },

    actions: {
      clickCallout() {
        if (this.get('clickCallout')) {
          this.get('clickCallout')();
        } else {
          Ember.run.scheduleOnce('afterRender', () => {
            (0, _jquery.default)(this.element.querySelectorAll(this.get('inputSelector'))).trigger(this.get('inputAction'));
          });
          this.set('inputActivated', true);
        }
      },

      clickCalloutLabel() {
        if (this.get('clickCalloutLabel')) {
          this.get('clickCalloutLabel')();
        }
      }

    }
  });

  _exports.default = _default;
});