define("frontend/components/photo-upload", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;
  const MAX_FILE_SIZE_MB = 15;

  var _default = Ember.Component.extend({
    classNames: ['photo-upload'],
    photo: null,
    placeholder: null,
    flashMessage: inject.service(),
    canUpload: true,
    aspectRatio: undefined,
    compressionRatio: 0.7,

    didInsertElement() {
      this.parseImage = this.parseImage.bind(this);
      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).on('change', evt => {
        let [file] = evt.target.files;
        let mb = this.getMb(file.size);
        let flashMessage = this.get('flashMessage');

        if (mb > MAX_FILE_SIZE_MB) {
          flashMessage.error(`Please upload an image smaller than ${MAX_FILE_SIZE_MB}MB!`);
        } else {
          this.parseImage(evt.target.files[0]);
        }
      });
    },

    parseImage(file) {
      let aspectRatio = this.get('aspectRatio'); // We're only disabling webP file formats right now but should probably add
      // other restrictions later on

      if (file.type == 'image/webp') {
        this.get('flashMessage').error('Please try uploading your image in a different file format!');
        (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).prop('value', '');
        return;
      }

      loadImage(file, imgOrCanvasOrEvent => {
        if (imgOrCanvasOrEvent.type === 'error' || imgOrCanvasOrEvent.tagName !== 'CANVAS') {
          this.get('flashMessage').errorWithoutRefresh('There was a problem uploading your image.');
        } else {
          let base64data = imgOrCanvasOrEvent.toDataURL(file.type, this.get('compressionRatio'));
          this.uploadedPhoto(base64data);
        }
      }, {
        // should be set to canvas : true to activate auto fix orientation
        canvas: true,
        orientation: true,
        aspectRatio
      });
    },

    getMb(size) {
      return size / 1000000;
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).off('change');
    },

    actions: {
      openImageSelect() {
        if (!this.get('canUpload')) {
          return;
        }

        (0, _jquery.default)(`#${this.elementId} [name="filename"]`).click();
      }

    }
  });

  _exports.default = _default;
});