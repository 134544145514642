define("frontend/controllers/microdeposits", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'depositOne': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'depositTwo': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['isStandalone'],
    flashMessage: inject.service(),
    attemptsLeft: 0,
    isStandalone: false,
    showClose: computed.not('isStandalone'),
    bankInfo: computed('model.bank_info', function () {
      let bank_info = this.get('model.bank_info');
      return `${bank_info.bank_name} ending ${bank_info.last4}`;
    }),
    actions: {
      openManualBankAccount() {
        this.set('openManualBankAccount', true);
      },

      closeManualBankAccount() {
        this.set('openManualBankAccount', false);
      },

      save() {
        this.set('depositOneError', false);
        this.set('depositTwoError', false);
        this.set('incorrectValuesError', false);
        let deposit_one = this.get('depositOne');
        let deposit_two = this.get('depositTwo');
        let errors = false;

        if (!this.get('validations.attrs.depositOne.isValid') || deposit_one < 0 || deposit_one >= 1) {
          this.set('depositOneError', true);
          errors = true;
        }

        if (!this.get('validations.attrs.depositTwo.isValid') || deposit_two < 0 || deposit_two >= 1) {
          this.set('depositTwoError', true);
          errors = true;
        }

        if (errors) {
          return;
        }

        this.set('submitDisabled', true);

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/users/verify_bank`,
          method: 'POST',
          data: {
            emd5: this.get('emd5'),
            deposit_one,
            deposit_two
          }
        }).done(response => {
          this.set('submitDisabled', false);
          this.transitionToRoute('verified-bank-account', this.get('emd5'), {
            queryParams: {
              last_four: response.last4
            }
          });
        }).fail(data => {
          if (data && data.responseText) {
            let parsed = JSON.parse(data.responseText);

            if (parsed.message) {
              if (parsed.attempts_left == 0) {
                return this.transitionToRoute('add-bank-account', parsed.emd5, {
                  queryParams: {
                    manualVerification: true
                  }
                }).then(() => {
                  this.get('flashMessage').sendFlashMessage('It looks like you’ve used up all 10 attempts to verify your account. Please try entering your account information again.', 'user-error');
                });
              } else {
                this.set('incorrectValuesError', true);
                this.set('submitDisabled', false);
                this.set('attemptsLeft', parsed.attempts_left);
              }
            } else {
              this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
            }
          } else {
            this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
          }
        });
      }

    }
  });

  _exports.default = _default;
});