define("frontend/components/off-hours-surcharge-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    offHoursSurchargePolicy: null,
    percentageType: computed.equal('offHoursSurchargePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('offHoursSurchargePolicy.amount_type', 'fixed'),
    percentageCompensation: computed.equal('offHoursSurchargePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('offHoursSurchargePolicy.employee_compensation_type', 'fixed'),
    surchargeTypeOptions: [{
      label: 'fixed $ per visit',
      value: 'fixed'
    }, {
      label: '% of service cost',
      value: 'percentage'
    }],
    selectedAmountType: computed('offHoursSurchargePolicy.amount_type', function () {
      let options = this.get('surchargeTypeOptions');
      let selected = options.filter(option => this.get('offHoursSurchargePolicy.amount_type') == option.value);
      return selected.get('firstObject');
    }),

    didInsertElement() {
      this.set('loading', false);

      if (!this.get('newOffHoursSurchargePolicy') && this.get('fixedType')) {
        let fee = this.get('offHoursSurchargePolicy.amount');
        this.set('offHoursSurchargePolicy.amount', this.formatMoney(fee, ''));
      }
    },

    amountError: computed('percentageType', 'offHoursSurchargePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('offHoursSurchargePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'offHoursSurchargePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('offHoursSurchargePolicy.employee_compensation_amount');

        if (!amount && amount != 0 || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
        this.get('offHoursSurchargePolicy').rollbackAttributes();
      },

      changeAmountType(option) {
        this.set('offHoursSurchargePolicy.amount_type', option.value);
      },

      toggleTimeFrameOffHours(timeFrame) {
        timeFrame.toggleProperty('is_off_hours');

        if (timeFrame.get('is_off_hours')) {
          this.get('offHoursSurchargePolicy.time_frames').pushObject(timeFrame);
        } else {
          this.get('offHoursSurchargePolicy.time_frames').removeObject(timeFrame);
        }
      },

      save() {
        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('compensationAmountError') || this.get('offHoursSurchargePolicy.time_frames.length') == 0) {
          return;
        }

        this.set('loading', true);
        let policy = this.get('offHoursSurchargePolicy');
        let url = `/api/v2/off_hours_surcharge_policies/${policy.get('id')}`;
        let method = 'PUT';

        if (this.get('newOffHoursSurchargePolicy')) {
          url = '/api/v2/off_hours_surcharge_policies';
          method = 'POST';
        }

        let time_frames = policy.get('time_frames').map(tf => {
          return {
            id: tf.get('id')
          };
        });
        Ember.$.ajax({
          url,
          method,
          data: {
            off_hours_surcharge_policy: {
              service_category_id: policy.get('service_category.id'),
              amount: policy.get('amount'),
              amount_type: policy.get('amount_type'),
              employee_compensation_type: policy.get('employee_compensation_type'),
              employee_compensation_amount: policy.get('employee_compensation_amount'),
              time_frames: time_frames
            }
          }
        }).then(response => {
          this.get('offHoursSurchargePolicy').rollbackAttributes();
          this.get('store').findRecord('off-hours-surcharge-policy', response.off_hours_surcharge_policy.id).then(newPolicy => {
            newPolicy.set('service_category', this.get('serviceCategory'));
            this.set('serviceCategory.off_hours_surcharge_policy', newPolicy);
            this.get('onClose')();
          });
        });
      }

    }
  });

  _exports.default = _default;
});