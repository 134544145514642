define("frontend/helpers/fancy-week-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fancyWeekDate = fancyWeekDate;

  function fancyWeekDate(_ref) {
    let [date, format, weekType] = _ref;
    date = (0, _moment.default)(date, 'YYYY-MM-DD');
    let startOfWeek = (0, _moment.default)().startOf(weekType).startOf('day');
    let dateStartOfWeek = date.startOf(weekType).startOf('day');

    if (date.isSame((0, _moment.default)(), 'week')) {
      return 'this week';
    } else if (dateStartOfWeek.diff(startOfWeek, 'weeks') <= 1 && dateStartOfWeek.diff(startOfWeek, 'weeks') > 0) {
      return 'next week';
    } else if (dateStartOfWeek.diff(startOfWeek, 'weeks') >= -1 && dateStartOfWeek.diff(startOfWeek, 'weeks') <= 0) {
      return 'last week';
    }

    return `on ${dateStartOfWeek.format(format)} - ${date.endOf(weekType).format(format)}`;
  }

  var _default = Ember.Helper.helper(fancyWeekDate);

  _exports.default = _default;
});