define("frontend/components/mobile-action-bar/action-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['action-item'],
    classNameBindings: ['active'],
    active: true,
    name: computed('type', function () {
      return this.get('type').capitalize();
    }),

    click() {
      if (this.get('active')) {
        this.activeClick();
      }
    },

    iconSrc: computed('type', function () {
      switch (this.get('type')) {
        case 'phone':
          return 'assets/images/phone.svg';
      }
    })
  });

  _exports.default = _default;
});