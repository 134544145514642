define("frontend/utils/calendar-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SCROLL_SPEED = 6;
  var _default = {
    resizeExistingHeaders() {
      Ember.run.scheduleOnce('afterRender', () => {
        $('.column').each((index, column) => {
          let unfixedHeader = $(column).find('.calendar-header-item:not(.fixed)');
          let fixedHeader = $(column).find('.calendar-header-item.fixed');
          let unfixedWidth = unfixedHeader.width();
          fixedHeader.width(unfixedWidth);
          this.translateHeaderWithScroll(fixedHeader);
        });
      });
    },

    initHeaders() {
      Ember.run.scheduleOnce('afterRender', () => {
        if (!$('.column')) {
          Bugsnag.notify('Notice', 'initHeaders .column is null (see the code for this notify)');
          return;
        }

        $('.column').each((index, column) => {
          if ($(column).find('.calendar-header-item.fixed').length > 0) {
            return;
          }

          let unfixedHeader = $(column).find('.calendar-header-item');
          let fixedHeader = unfixedHeader.clone();
          fixedHeader.addClass('fixed');
          fixedHeader.width(unfixedHeader.width() + 1);
          fixedHeader.prependTo(column);
          this.translateHeaderWithScroll(fixedHeader);
        });
      });
    },

    translateHeaderWithScroll(header) {
      header.css({
        'transform': `translateX(-${$('.calendar-body').scrollLeft()}px)`
      });
    },

    teardownTimeScrolling() {
      $('.calendar-body').off('scroll');
    },

    initScrollArrows() {
      let setupScrollArrow = (selector, cb) => {
        let scrolling = false;
        $(selector).on('mouseover dragenter', () => {
          if (scrolling) {
            return;
          }

          $(selector).addClass('hover');
          scrolling = true;

          let scroll = () => {
            cb();
            window.requestAnimationFrame(() => {
              if (scrolling) {
                scroll();
              }
            });
          };

          scroll();
        });
        $(selector).on('mouseout dragleave', () => {
          $(selector).removeClass('hover');
          scrolling = false;
        });
      };

      setupScrollArrow('.right-scroll', () => {
        $('.calendar-body')[0].scrollLeft += SCROLL_SPEED;
      });
      setupScrollArrow('.left-scroll', () => {
        $('.calendar-body')[0].scrollLeft -= SCROLL_SPEED;
      });
    },

    teardownScrollArrows() {
      $('.right-scroll').off('mouseover mouseout dragenter dragleave');
      $('.left-scroll').off('mouseover mouseout dragenter dragleave');
    },

    initScrollHandler(cb) {
      $('.time-column').css({
        'transform': `translateY(-${$('.calendar-body').scrollTop()}px)`
      });
      $('.calendar-body').scroll(() => {
        Ember.run.throttle(() => {
          $('.time-column').css({
            'transform': `translateY(-${$('.calendar-body').scrollTop()}px)`
          });
          $('.calendar-header-item.fixed').css({
            'transform': `translateX(-${$('.calendar-body').scrollLeft()}px)`
          });
          cb();
        }, 100);
      });
    }

  };
  _exports.default = _default;
});