define("frontend/components/holiday-surcharge-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    holidaySurchargePolicy: null,
    company: null,
    percentageType: computed.equal('holidaySurchargePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('holidaySurchargePolicy.amount_type', 'fixed'),
    chargePerDay: computed.readOnly('holidaySurchargePolicy.charge_per_day'),
    chargePerVisit: computed.not('chargePerDay'),
    percentageCompensation: computed.equal('holidaySurchargePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('holidaySurchargePolicy.employee_compensation_type', 'fixed'),
    surchargeTypeOptions: [{
      label: 'fixed $ per visit',
      value: 'fixed'
    }, {
      label: '% of service cost',
      value: 'percentage'
    }],

    didInsertElement() {
      this.set('loading', false);
      this.set('originalDates', [...this.get('holidaySurchargePolicy.dates')]);

      if (!this.get('newHolidaySurchargePolicy') && this.get('fixedType')) {
        let fee = this.get('holidaySurchargePolicy.amount');
        this.set('holidaySurchargePolicy.amount', this.formatMoney(fee, ''));
      }
    },

    amountTypeObserver: observer('holidaySurchargePolicy.amount_type', function () {
      if (this.get('holidaySurchargePolicy.amount_type') === 'percentage') {
        this.set('holidaySurchargePolicy.charge_per_day', false);
      }
    }),
    amountError: computed('percentageType', 'holidaySurchargePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('holidaySurchargePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'holidaySurchargePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        let amount = this.get('holidaySurchargePolicy.employee_compensation_amount');

        if (!amount && amount != 0 || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      toggleChargePerDay() {
        this.toggleProperty('holidaySurchargePolicy.charge_per_day');
      },

      cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
        this.get('holidaySurchargePolicy').rollbackAttributes();

        if (this.get('holidaySurchargePolicy.id')) {
          this.set('holidaySurchargePolicy.dates', this.get('originalDates'));
        }
      },

      changeAmountType(option) {
        this.set('holidaySurchargePolicy.amount_type', option.value);
      },

      save() {
        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('compensationAmountError') || this.get('holidaySurchargePolicy.dates.length') == 0) {
          return;
        }

        this.set('loading', true);

        if (this.get('newHolidaySurchargePolicy')) {
          let policy = this.get('holidaySurchargePolicy');
          Ember.$.ajax({
            url: '/api/v2/holiday_surcharge_policies',
            method: 'POST',
            data: {
              holiday_surcharge_policy: {
                service_category_id: policy.get('service_category.id'),
                amount: policy.get('amount'),
                amount_type: policy.get('amount_type'),
                dates: policy.get('dates'),
                employee_compensation_type: policy.get('employee_compensation_type'),
                employee_compensation_amount: policy.get('employee_compensation_amount'),
                charge_per_day: policy.get('charge_per_day')
              }
            }
          }).then(response => {
            this.get('holidaySurchargePolicy').deleteRecord();
            this.get('store').findRecord('holiday-surcharge-policy', response.holiday_surcharge_policy.id).then(newPolicy => {
              newPolicy.set('service_category', this.get('serviceCategory'));
              this.set('serviceCategory.holiday_surcharge_policy', newPolicy);
              this.get('onClose')();
            });
          });
        } else {
          // Updates will destroy the record and create a new one which Ember data
          // doesn't like
          let policy = this.get('holidaySurchargePolicy');
          Ember.$.ajax({
            url: `/api/v2/holiday_surcharge_policies/${policy.get('id')}`,
            method: 'PUT',
            data: {
              holiday_surcharge_policy: {
                service_category_id: policy.get('service_category.id'),
                amount: policy.get('amount'),
                amount_type: policy.get('amount_type'),
                dates: policy.get('dates'),
                employee_compensation_type: policy.get('employee_compensation_type'),
                employee_compensation_amount: policy.get('employee_compensation_amount'),
                charge_per_day: policy.get('charge_per_day')
              }
            }
          }).then(response => {
            this.get('store').unloadRecord(this.get('holidaySurchargePolicy'));
            this.get('store').findRecord('holiday-surcharge-policy', response.holiday_surcharge_policy.id).then(newPolicy => {
              newPolicy.set('service_category', this.get('serviceCategory'));
              this.set('serviceCategory.holiday_surcharge_policy', newPolicy);
              this.get('onClose')();
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});