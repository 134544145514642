define("frontend/components/invoice-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['invoice-tag', 'invoice-type'],
    void: computed.readOnly('invoice.isVoided'),
    due: computed.readOnly('invoice.isDue'),
    paid: computed.readOnly('invoice.isPaid'),
    draft: computed.readOnly('invoice.isDraft'),
    partial: computed.readOnly('invoice.isPartial'),
    classNameBindings: ['void', 'draft', 'due', 'paid', 'partial']
  });

  _exports.default = _default;
});