define("frontend/controllers/portal/messages/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    queryParams: ['appointmentId', 'showReportCard'],
    household: computed.readOnly('currentUser.user.household'),
    company_id: computed.readOnly('model.company.id'),
    settings: computed.readOnly('model.settings.settings'),
    appointmentId: null,
    showReportCard: false,
    groupedReportCardObserver: observer('appointmentId', 'showReportCard', function () {
      if (this.get('showReportCard')) {
        this.set('loadingReportCard', true);
        return this.get('store').findRecord('appointment', this.get('appointmentId')).then(appt => {
          return this.get('store').findRecord('grouped_report_card', appt.get('grouped_report_card.id')).then(grc => {
            this.set('loadingReportCard', false);
            this.set('selectedReportCard', grc);
          });
        });
      }
    }),
    companies: computed('settings.@each.{user_last_seen_message_thread}', function () {
      let settings = this.get('settings');

      if (settings) {
        return $.map(settings, companySettings => {
          return Ember.Object.create({
            id: companySettings.company_id.toString(10),
            name: companySettings.company_name,
            logoUrl: companySettings.company_logo,
            lastMessage: companySettings.last_message,
            lastMessageTime: companySettings.last_message_time,
            lastSeenTime: companySettings.user_last_seen_message_thread,
            pendingRequestCategory: companySettings.pending_request_category
          });
        });
      }

      return [];
    }),
    hasMultipleCompanies: computed('companies.[]', function () {
      return this.get('companies.length') > 1;
    }),
    actions: {
      switchCompany(companyId) {
        this.transitionToRoute('portal.messages.company', companyId);
      },

      onSentMessage() {
        this.get('settings').forEach(settings => {
          Ember.set(settings, 'user_last_seen_message_thread', moment().format());
        });
      },

      closeReportCard() {
        this.set('showReportCard', false);
        this.set('selectedReportCard', null);
      }

    }
  });

  _exports.default = _default;
});