define("frontend/components/message-thread/company-option", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--company-option'],
    company: null,
    selected: false,
    currentUser: inject.service(),
    firestore: inject.service(),
    firestoreListener: null,
    companyLogoPlaceholder: '/assets/images/settings/company-placeholder.png',
    hasNewMessage: computed('company.lastSeenTime', 'lastMessageTime', function () {
      if (!this.get('lastMessageTime')) {
        return false;
      }

      let lastSeen = this.get('company.lastSeenTime');
      let lastMessage = this.get('lastMessageTime');

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage;
    }),
    companyFirstLetter: computed('company', function () {
      return this.get('company.name')[0];
    }),

    didInsertElement() {
      this._super(...arguments);

      let self = this;
      let collection = this.get('firestore').getCollection(`${_environment.default.environment}_message_times`);

      if (collection) {
        let firestoreListener = collection.doc(`${self.get('company.id')}_${self.get('currentUser.user.household.id')}`).onSnapshot(function (doc) {
          if (doc.exists) {
            let lastMessageTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              lastMessageTime = moment.unix(lastMessageTime).format();
            }

            _jquery.default.ajax({
              url: `${_environment.default.APP.host}/api/v2/messages`,
              method: 'GET',
              data: {
                household_id: self.get('currentUser.user.household.id'),
                company_id: self.get('company.id'),
                last_message_time: lastMessageTime
              }
            }).then(response => {
              self.set('lastMessageTime', doc.data().last_message);
              let lastMessage = response.messages.sortBy('created_at').get('lastObject');

              if (lastMessage) {
                self.get('company').set('lastMessageTime', lastMessage.created_at);
                self.get('company').set('lastMessage', lastMessage.message);

                if (self.get('selected')) {
                  setTimeout(() => {
                    self.get('company').set('lastSeenTime', moment().format());
                  }, 3000);
                }
              }
            });
          } else {
            self.set('lastMessageTime', null);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },

    actions: {
      companySelected(company) {
        this.get('companySelected')(company);
      }

    }
  });

  _exports.default = _default;
});