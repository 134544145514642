define("frontend/controllers/companies/getting-paid", ["exports", "frontend/config/environment", "ember-data", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberData, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'companyTaxId': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    }), (0, _emberCpValidations.validator)('tax-id'), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'company.bank_account_nickname': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    })],
    'bankUser.last_four_ssn': [(0, _emberCpValidations.validator)('last-four-ssn')],
    'bankUser.dob': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: false,
      presence: true
    }), (0, _emberCpValidations.validator)('date')],
    'company.sales_tax': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 50,
      gte: 1,
      allowBlank: true,
      positive: true,
      message: 'This must be greater than or equal to 1'
    })]
  }); // This is for payment reminder, since it
  // has to change when invoice frequency changes

  const REMINDER_OPTIONS = {
    monthly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'every-2-weeks',
      label: 'Every 2 weeks'
    }, {
      value: 'off',
      label: 'Never'
    }],
    biweekly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'off',
      label: 'Never'
    }],
    weekly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'off',
      label: 'Never'
    }],
    semimonthly: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'every-week',
      label: 'Every week'
    }, {
      value: 'off',
      label: 'Never'
    }],
    daily: [{
      value: 'every-3-days',
      label: 'Every 3 days'
    }, {
      value: 'every-4-days',
      label: 'Every 4 days'
    }, {
      value: 'every-5-days',
      label: 'Every 5 days'
    }, {
      value: 'off',
      label: 'Never'
    }],
    null: []
  };

  var _default = Ember.Controller.extend(Validations, {
    flashMessage: inject.service(),
    selectedSalesTaxPolicy: null,
    selectedToRemoveSalesTaxPolicy: null,
    needSalesTaxPolicyDefaultAnswer: false,
    ePaymentOptions: computed('model.company.allows_ach_transfer', function () {
      let options = [{
        label: 'Credit/debit cards',
        value: 'cards'
      }];

      if (this.get('model.company.allows_ach_transfer')) {
        if (this.get('model.company.epayments') == 'ach') {
          options.push({
            label: 'ACH bank transfers',
            value: 'ach'
          });
        }

        options.push({
          label: 'Card and ACH',
          value: 'both'
        });
      }

      if (!this.get('model.company.has_stripe_account')) {
        options.push({
          label: 'None',
          value: 'none'
        });
      }

      return options;
    }),
    company: computed.alias('model.company'),
    canSave: computed('validations.attrs.bankUser.dob.isValid', 'validations.attrs.bankUser.last_four_ssn.isValid', 'validations.attrs.company.bank_account_nickname.isValid', 'validations.attrs.companyTaxId.isValid', 'validations.attrs.company.sales_tax.isValid', 'company.tax_id', 'company.acceptsEpayments', function () {
      let accepts_payments = this.get('company.acceptsEpayments');
      return this.get('validations.attrs.company.sales_tax.isValid') && (!accepts_payments || accepts_payments && this.get('validations.attrs.bankUser.dob.isValid') && this.get('validations.attrs.bankUser.last_four_ssn.isValid') && this.get('validations.attrs.company.bank_account_nickname.isValid') && this.get('validations.attrs.company.sales_tax.isValid') && (this.get('company.tax_id') || this.get('validations.attrs.companyTaxId.isValid')));
    }),
    bankUser: computed('company', function () {
      let promise = this.get('store').find('user', this.get('company.bank_user_id'));
      return _emberData.default.PromiseObject.create({
        promise
      });
    }),
    companyTaxId: computed('company', function () {
      return this.get('company.tax_id');
    }),
    showLastFourSsn: computed('bankUser.last_four_ssn', function () {
      return this.get('bankUser.last_four_ssn') == null;
    }),
    showUserDob: computed('bankUser.dob', function () {
      return this.get('bankUser.dob') == null;
    }),
    autoPayOptions: [{
      value: 'opt_in',
      label: 'Allow clients to opt-in'
    }, {
      value: 'required',
      label: 'Required for all clients'
    }],
    serviceFeeOptions: [{
      value: true,
      label: 'Enabled'
    }, {
      value: false,
      label: 'Disabled'
    }],
    showConfirmNeverAutoBill: false,
    showConfirmSwitchUserInvoiceFrequencies: false,
    showChooseBankAccountLinkingMethod: false,
    editingBankAccount: false,
    editingAutoBill: false,
    editingBillReminders: false,
    anySidePanelActive: computed.or('editingBankAccount', 'editingAutoBill', 'editingBillReminders'),
    hoverBankAccount: false,
    changedTaxId: false,
    showBankAccount: computed('company.bank_account_number', function () {
      return this.get('company.bank_account_number');
    }),
    billReminderFrequencyOptions: computed('company.invoice_frequency', function () {
      return REMINDER_OPTIONS[this.get('company.invoice_frequency')];
    }),
    authorizedIndividuals: computed(function () {
      let promise = this.get('store').findAll('employee').then(employees => {
        return employees.filterBy('user.user_role', 'Owner').map(employee => {
          return {
            value: employee.get('user.id'),
            label: employee.get('user.fullName')
          };
        });
      });
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    removedInvoiceFrequencies: computed('company.invoice_frequencies.[]', function () {
      let removedFrequencies = [];
      let oldFrequencies = this.get('originalInvoiceFrequencies');
      let newFrequencies = this.get('company.invoice_frequencies');
      oldFrequencies.forEach(frequency => {
        if (!newFrequencies.includes(frequency)) {
          removedFrequencies.push(frequency);
        }
      });
      let removedFrequencyNames = removedFrequencies.map(rif => {
        let frequency = this.get('model.invoiceFrequencies').filter(f => f.id == rif.id);
        return frequency.get('firstObject.name');
      });
      return removedFrequencyNames;
    }),
    companyAccountVerified: computed('company.stripe_account_verified', function () {
      return this.get('company.stripe_account_verified') === 'verified';
    }),
    // Don't show the checks payable field to MixyPaws
    // TODO: switch this to not show this field if the company doesn't accept checks
    showChecksPayableField: computed('company.id', function () {
      return this.get('company.id') != 138;
    }),

    invoiceFrequencyUsers(invoiceFrequencies) {
      let checkIsOnFrequency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      let exceptionCount = 0;
      Object.keys(this.get('model.invoiceFrequencyCounts')).forEach(frequency => {
        if (checkIsOnFrequency) {
          if (invoiceFrequencies.includes(frequency)) {
            exceptionCount += this.get('model.invoiceFrequencyCounts')[frequency];
          }
        } else {
          if (!invoiceFrequencies.includes(frequency)) {
            exceptionCount += this.get('model.invoiceFrequencyCounts')[frequency];
          }
        }
      });
      return exceptionCount;
    },

    salesTaxHelperText: 'Sales tax rates and laws vary from state to state. To collect sales tax, your state may require you to obtain a sales tax permit. Please check your state and/or city websites for more information on tax laws in your area.',
    removeSalesTaxPolicyText: computed('selectedToRemoveSalesTaxPolicy', 'activeSalesTaxPolicies.[]', function () {
      if (this.get('activeSalesTaxPolicies.length') === 1) {
        return `You're removing your ${parseFloat(this.get('selectedToRemoveSalesTaxPolicy.rate'))}% tax rate (${this.get('selectedToRemoveSalesTaxPolicy.name')}). If you proceed, no sales tax would be applied on invoices going forward unless you add a new tax rate.`;
      }

      let defaultPolicy = this.get('activeSalesTaxPolicies').find(policy => policy.get('is_default'));

      if (defaultPolicy === this.get('selectedToRemoveSalesTaxPolicy')) {
        let foundDefault = false;
        this.get('activeSalesTaxPolicies').forEach(policy => {
          if (policy !== defaultPolicy && !foundDefault) {
            defaultPolicy = policy;
            foundDefault = true;
          }
        });
      }

      return `You’re removing your ${parseFloat(this.get('selectedToRemoveSalesTaxPolicy.rate'))}% tax rate (${this.get('selectedToRemoveSalesTaxPolicy.name')}). Any families currently on this tax rate will be set to your default sales tax rate of ${parseFloat(defaultPolicy.get('rate'))}% for future invoices.`;
    }),
    activeSalesTaxPolicies: computed('company.sales_tax_policies.@each.isDeleted', function () {
      return this.get('company.sales_tax_policies').filter(policy => !policy.get('isDeleted'));
    }),
    actions: {
      selectEPaymentOption(value) {
        if (value == 'none') {
          this.set('companyTaxId', null);
        }
      },

      updateAutoPayPreference(value) {
        this.set('company.auto_pay', value);
      },

      updateServiceFee(value) {
        this.set('company.charge_service_fee', value);
      },

      openPaymentMethodSelector() {
        this.set('showChooseBankAccountLinkingMethod', true);
      },

      closePaymentMethodSelector() {
        this.set('showChooseBankAccountLinkingMethod', false);
      },

      confirmNeverAutoBill() {
        this.set('showConfirmNeverAutoBill', false);
      },

      denyNeverAutoBill() {
        this.set('invoiceFrequency', 'monthly');
      },

      confirmSwitchUserInvoiceFrequencies() {
        this.set('showConfirmSwitchUserInvoiceFrequencies', false);
        Ember.getOwner(this).lookup('route:companies.getting-paid').send('save', this.get('company'));
      },

      denySwitchUserInvoiceFrequencies() {
        this.set('showConfirmSwitchUserInvoiceFrequencies', false);
      },

      bankAccountFocus() {
        this.set('showBankAccount', true);
        this.set('editingBankAccount', true);
      },

      closeBankAccount() {
        this.set('showBankAccount', this.get('company.bank_account_number'));
        this.set('editingBankAccount', false);
      },

      closeInvoiceFrequencyModal() {
        this.set('showChangeInvoiceFrequencyModal', false);

        if (!this.get('company.update_all_households') && this.get('removedInvoiceFrequencies.length') > 0) {
          let removedFrequencies = this.get('removedInvoiceFrequencies').filter(frequency => frequency != this.get('originalDefaultInvoiceFrequency.name'));
          let exceptionCount = this.invoiceFrequencyUsers(removedFrequencies, true);

          if (exceptionCount > 0) {
            exceptionCount = this.invoiceFrequencyUsers(this.get('removedInvoiceFrequencies'), true);
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showRemovedInvoiceFrequencyModal', true);
          } else {
            this.send('save');
          }
        } else {
          this.send('save');
        }
      },

      closeRemoveInvoiceFrequencyModal(shouldProceed) {
        this.set('showRemovedInvoiceFrequencyModal', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.get('company.invoice_frequencies').clear();
          this.get('originalInvoiceFrequencies').forEach(frequency => {
            this.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },

      closeRemovedAutoBillModal(shouldProceed) {
        this.set('showRemovedAutoBillModal', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.set('company.default_invoice_frequency', this.get('originalDefaultInvoiceFrequency'));
          this.get('originalInvoiceFrequencies').forEach(frequency => {
            this.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },

      closeAddedAutoBillModal() {
        this.set('showAddedAutoBillModal', false);
        this.send('save');
      },

      closeChangedDefaultInvoiceFrequency(shouldProceed) {
        this.set('showChangeInvoiceFrequencyModalWithNoExceptions', false);

        if (shouldProceed) {
          this.send('save');
        } else {
          this.set('company.default_invoice_frequency', this.get('originalDefaultInvoiceFrequency'));
          this.get('originalInvoiceFrequencies').forEach(frequency => {
            this.get('company.invoice_frequencies').pushObject(frequency);
          });
          this.send('openAutoBill');
        }
      },

      closeAutoBill() {
        this.set('editingAutoBill', false);
      },

      openAutoBill() {
        this.set('editingAutoBill', true);
      },

      openBillReminders() {
        this.set('editingBillReminders', true);
      },

      closeBillReminders() {
        this.set('editingBillReminders', false);
      },

      openBankAccount() {
        this.set('editingBankAccount', true);
      },

      closeChangeSalesTaxPolicyDefaultModal() {
        this.set('needSalesTaxPolicyDefaultAnswer', false);
        this.set('showChangeSalesTaxPolicyDefaultModal', false);
        this.send('save');
      },

      setBankAccount(token, account_id, nickname) {
        let manual_bank_account = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        this.set('showChooseBankAccountLinkingMethod', false);
        this.set('company.bank_account_nickname', nickname);
        this.set('company.account_id', account_id);

        if (manual_bank_account) {
          this.set('company.manual_token', token);
          this.set('company.token', null);
          this.set('company.payment_method', 'manual_bank_account');
        } else {
          this.set('company.manual_token', null);
          this.set('company.token', token);
          this.set('company.payment_method', 'bank_account');
        }
      },

      clickedSave() {
        this.set('didValidate', true);

        if (!this.get('canSave')) {
          return;
        }

        let originalStp = this.get('originalDefaultSalesTaxPolicy');
        let defaultStp = this.get('activeSalesTaxPolicies').find(policy => policy.get('is_default'));

        if (originalStp?.id != defaultStp?.id && this.get('activeSalesTaxPolicies.length') > 0 && !originalStp.get('isDeleted')) {
          this.set('needSalesTaxPolicyDefaultAnswer', true);
          this.set('showChangeSalesTaxPolicyDefaultModal', true);
        }

        let oldFrequency = this.get('originalDefaultInvoiceFrequency');
        let newFrequency = this.get('company.default_invoice_frequency');

        if (oldFrequency && !newFrequency) {
          this.set('showRemovedAutoBillModal', true);
          return;
        } else if (newFrequency && !oldFrequency) {
          this.set('showAddedAutoBillModal', true);
          return;
        }

        if (!oldFrequency && !newFrequency) {
          this.send('save');
          return;
        }

        let exceptionCount = this.invoiceFrequencyUsers([newFrequency.get('name'), oldFrequency.get('name')], false);

        if (oldFrequency != newFrequency && newFrequency && oldFrequency) {
          if (exceptionCount == 0) {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showChangeInvoiceFrequencyModalWithNoExceptions', true);
          } else {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showChangeInvoiceFrequencyModal', true);
          }
        } else if (this.get('removedInvoiceFrequencies.length') > 0) {
          exceptionCount = this.invoiceFrequencyUsers(this.get('removedInvoiceFrequencies'), true);

          if (exceptionCount > 0) {
            this.set('invoiceFrequencyExceptionCount', exceptionCount);
            this.set('showRemovedInvoiceFrequencyModal', true);
          } else {
            this.send('save');
          }
        } else {
          this.send('save');
        }
      },

      save() {
        if (this.get('needSalesTaxPolicyDefaultAnswer')) {
          return;
        }

        this.set('company.last_four_ssn', this.get('bankUser.last_four_ssn'));
        this.set('company.birthday', this.get('bankUser.dob'));
        this.set('company.tax_id', this.get('companyTaxId'));

        if (!this.get('company.acceptsEpayments')) {
          this.set('company.manual_token', null);
          this.set('company.token', null);
          this.set('company.payment_method', null);
          this.set('company.bank_account_nickname', null);
          this.set('company.account_id', null);
        }

        Ember.getOwner(this).lookup('route:companies.getting-paid').send('save', this.get('company'));
      },

      changeTaxId() {
        if (!this.get('changedTaxId')) {
          let company_id = this.get('company.id');

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/companies/${company_id}/notify_support_for_tax_id_change`,
            method: 'POST',
            data: {
              company_id: this.get('company.id')
            }
          }).done(response => {
            this.set('changingTaxId', true);
            this.get('flashMessage').sendFlashMessage(response.message, 'Success');
          });

          this.set('changedTaxId', true);
        }
      },

      hoverBankAccount(hover) {
        this.set('hoverBankAccount', hover);
      },

      hideReminderPopout() {
        this.set('showingReminderPopout', false);
      },

      addSalesTaxPolicy() {
        const salesTaxPolicyName = this.get('activeSalesTaxPolicies').length === 0 ? this.get('company.state') : null;
        const salesTaxPolicy = this.get('store').createRecord('sales-tax-policy', {
          name: salesTaxPolicyName,
          is_default: this.get('activeSalesTaxPolicies.length') === 0
        });
        this.get('company.sales_tax_policies').pushObject(salesTaxPolicy);
        this.set('selectedSalesTaxPolicy', salesTaxPolicy);
      },

      editSalesTaxPolicy(salesTaxPolicy) {
        this.set('selectedSalesTaxPolicy', salesTaxPolicy);
      },

      closeSalesTaxPolicyModal() {
        this.get('selectedSalesTaxPolicy').rollbackAttributes();
        this.set('selectedSalesTaxPolicy', null);
      },

      saveSalesTaxPolicy() {
        if (this.get('selectedSalesTaxPolicy.is_default')) {
          this.get('activeSalesTaxPolicies').forEach(policy => {
            policy.set('is_default', policy === this.get('selectedSalesTaxPolicy'));
          });
        } else {
          let defaultPolicy = this.get('activeSalesTaxPolicies').find(policy => policy !== this.get('selectedToRemoveSalesTaxPolicy'));
          defaultPolicy.set('is_default', true);
        }

        this.set('selectedSalesTaxPolicy', null);
      },

      deleteSalesTaxPolicy(salesTaxPolicy) {
        this.set('selectedToRemoveSalesTaxPolicy', salesTaxPolicy);
      },

      confirmRemoveSalesTaxPolicy() {
        if (this.get('selectedToRemoveSalesTaxPolicy.is_default')) {
          let defaultPolicy = this.get('activeSalesTaxPolicies').find(policy => policy !== this.get('selectedToRemoveSalesTaxPolicy'));

          if (defaultPolicy) {
            defaultPolicy.set('is_default', true);
          }
        }

        this.get('selectedToRemoveSalesTaxPolicy').deleteRecord();
        this.set('selectedToRemoveSalesTaxPolicy', null);
      },

      denyRemoveSalesTaxPolicy() {
        this.set('selectedToRemoveSalesTaxPolicy', null);
      },

      setDefaultSalesTaxPolicy(salesTaxPolicy) {
        this.get('activeSalesTaxPolicies').forEach(policy => {
          policy.set('is_default', policy === salesTaxPolicy);
        });
      },

      salesTaxValidation(percentage) {
        const regex = /^(?!0+(\.0+)?$)(\d+|\d*\.\d{1,3})$/;
        return regex.test(percentage);
      }

    }
  });

  _exports.default = _default;
});