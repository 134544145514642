define("frontend/components/calendar-list", ["exports", "frontend/config/environment", "frontend/utils/calendar-util", "moment", "frontend/services/flash-message", "jquery"], function (_exports, _environment, _calendarUtil, _moment, _flashMessage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  function ignoreTouch(e) {
    e.preventDefault();
  }

  var _default = Ember.Component.extend({
    classNames: ['calendar-list'],
    scheduleDragActionService: inject.service(),
    careProviders: [],
    store: inject.service(),
    canScrollLeft: false,
    canScrollRight: false,
    checkDuplicateAppointmentTimes: inject.service(),
    checkStartedAppointment: inject.service(),
    timeZone: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    canDrag: computed.readOnly('currentUser.user.canAddEditAppointments'),
    dragScrollActivated: false,
    onCancelationSchedule: false,
    highlightWindowStart: null,
    highlightWindowEnd: null,
    highlightWindowDate: null,
    showStartedAppointmentMessage: false,

    didInsertElement() {
      this._super(...arguments);

      this.showScrollArrows = this.showScrollArrows.bind(this);
      this.setupInteract = this.setupInteract.bind(this);
      this.teardownInteract = this.teardownInteract.bind(this);
      this.showScrollArrows();
      this.scrollToFirstService();

      _calendarUtil.default.initScrollArrows();

      _calendarUtil.default.initScrollHandler(this.showScrollArrows);

      _calendarUtil.default.initHeaders();

      this.updateCurrentTime();
      this.set('interval', window.setInterval(this.updateCurrentTime.bind(this), 30000));
      $(window).resize(() => {
        _calendarUtil.default.resizeExistingHeaders();

        this.showScrollArrows();
      });
      this.setupInteract();
    },

    setupInteract() {
      let _this = this;

      let dragging = false;
      interact.dynamicDrop(true);
      interact('.draggable').on('click', event => {
        if (dragging) {
          (event.originalEvent || event).stopImmediatePropagation();
        }
      }, {
        capture: true
      }).draggable({
        inertia: false,

        onstart(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let {
            target
          } = event;
          let {
            x,
            y
          } = target.getBoundingClientRect();
          let width = $(target).width() + 10;
          target.style.opacity = .5;
          let cloned = target.cloneNode(true);
          cloned.style.width = `${width}px`;
          let draggableItem = document.querySelector('#draggable-item');
          draggableItem.style.width = `${width}px`;
          draggableItem.style.position = 'fixed';
          draggableItem.style.display = 'inline-block';
          draggableItem.style.top = `${y}px`;
          draggableItem.style.left = `${x}px`;
          draggableItem.appendChild(cloned);
          document.querySelector('.calendar-body').classList.add('dragging');
          ['touchstart', 'touchmove', 'touchend'].forEach(et => document.querySelector('.calendar-body').addEventListener(et, ignoreTouch));

          if (!target.classList.contains('no-drag-cancel')) {
            _this.set('scheduleDragActionService.showingCancel', true);
          }

          if (!target.classList.contains('no-drag-archive')) {
            _this.set('scheduleDragActionService.showingArchive', true);
          }

          let serviceId = target.getAttribute('data-service-id');

          if (serviceId) {
            let service = _this.get('store').peekAll('service').filterBy('id', serviceId).get('firstObject');

            let groupedAppts = service.get('sortedGroupedAppointmentsWithoutCanceled');
            let startTime = null;
            let endTime = null;
            groupedAppts.forEach(groupedAppt => {
              groupedAppt.get('sortedAppointments').forEach(appt => {
                let apptStartTime = appt.get('time_frame_start_time');
                let apptEndTime = appt.get('time_frame_end_time');

                if (apptStartTime && apptEndTime) {
                  if (startTime && endTime) {
                    if ((0, _moment.default)(apptStartTime, 'h:mmA').isAfter((0, _moment.default)(startTime, 'h:mmA'))) {
                      startTime = apptStartTime;
                    }

                    if ((0, _moment.default)(endTime, 'h:mmA').isAfter((0, _moment.default)(apptEndTime, 'h:mmA'))) {
                      endTime = apptEndTime;
                    }
                  } else {
                    startTime = apptStartTime;
                    endTime = apptEndTime;
                  }
                }
              });
            });

            if (startTime && endTime) {
              _this.set('highlightWindowStart', startTime);

              _this.set('highlightWindowEnd', endTime);
            }
          }

          let groupedAppointmentId = target.getAttribute('data-grouped-appointment-id');

          if (groupedAppointmentId) {
            let groupedAppointment = _this.get('store').peekAll('grouped-appointment').filterBy('id', groupedAppointmentId).get('firstObject');

            let firstAppt = groupedAppointment.get('appointments.firstObject');
            let startTime = firstAppt.get('time_frame_start_time');
            let endTime = firstAppt.get('time_frame_end_time');

            if (startTime && endTime) {
              _this.set('highlightWindowStart', startTime);

              _this.set('highlightWindowEnd', endTime);
            }
          }

          let appointmentRequestId = target.getAttribute('data-appointment-request-id');

          if (appointmentRequestId) {
            let appointmentRequest = _this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

            appointmentRequest.set('isDragging', true);
            let startTime = appointmentRequest.get('time_frame_start_time');
            let endTime = appointmentRequest.get('time_frame_end_time');

            if (startTime && endTime) {
              _this.set('highlightWindowStart', startTime);

              _this.set('highlightWindowEnd', endTime);

              _this.set('highlightWindowDate', appointmentRequest.get('starts_at_date'));
            }
          }

          _this.set('dragScrollActivated', true);

          dragging = true;
        },

        onend(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let {
            target
          } = event;
          target.style.display = 'inline-block';
          target.style.opacity = 1;
          target.style.backgroundColor = '';
          let draggableItem = document.querySelector('#draggable-item');
          draggableItem.removeChild(draggableItem.firstChild);
          draggableItem.setAttribute('data-x', '');
          draggableItem.setAttribute('data-y', '');
          document.querySelector('.calendar-body').classList.remove('dragging');

          _this.get('scheduleDragActionService').stopDragging();

          _this.set('dragScrollActivated', false);

          _this.set('highlightWindowStart', null);

          _this.set('highlightWindowEnd', null);

          let appointmentRequestId = target.getAttribute('data-appointment-request-id');

          if (appointmentRequestId) {
            let appointmentRequest = _this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

            appointmentRequest.set('isDragging', false);
          }

          ['touchstart', 'touchmove', 'touchend'].forEach(et => document.querySelector('.calendar-body').removeEventListener(et, ignoreTouch)); // This needs to run after the click event fires so that we can properly disable clicking when dragging

          Ember.run.scheduleOnce('afterRender', () => dragging = false);
        },

        onmove(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let draggableItem = document.querySelector('#draggable-item');
          let x = (parseFloat(draggableItem.getAttribute('data-x')) || 0) + event.dx;
          let y = (parseFloat(draggableItem.getAttribute('data-y')) || 0) + event.dy;
          draggableItem.style.webkitTransform = draggableItem.style.transform = `translate(${x}px, ${y}px)`;
          draggableItem.setAttribute('data-x', x);
          draggableItem.setAttribute('data-y', y);
        }

      });
      interact('.drop-zone').dropzone({
        accept: '.draggable',

        ondragenter(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let dropzoneElement = event.target;
          let height = `${event.relatedTarget.getAttribute('data-height')}px`;
          $(dropzoneElement).find('.drag-box').css({
            height
          });
          dropzoneElement.classList.add('accepts-drag');
        },

        ondragleave(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let dropzoneElement = event.target;
          dropzoneElement.classList.remove('accepts-drag');
        },

        ondrop(event) {
          if (_this.isDestroyed || _this.get('onCancelationSchedule')) {
            return;
          }

          let dropzoneElement = event.target;
          dropzoneElement.classList.remove('accepts-drag');
          let draggableElement = event.relatedTarget;
          let date = dropzoneElement.getAttribute('data-date');
          let minute = dropzoneElement.getAttribute('data-minute');
          let hour = dropzoneElement.getAttribute('data-hour');

          let companyOffset = _this.get('timeZone').offsetDSTAware((0, _moment.default)(date).format('YYYY-MM-DD'));

          let momentTime = (0, _moment.default)(date, 'YYYY-MM-DD').utcOffset(companyOffset, true).hour(hour).minute(minute);
          let starts_at = momentTime.format();
          let care_provider_id = dropzoneElement.getAttribute('data-care-provider-id');
          let serviceId = draggableElement.getAttribute('data-service-id');
          let groupedAppointmentId = draggableElement.getAttribute('data-grouped-appointment-id');
          let appointmentRequestId = draggableElement.getAttribute('data-appointment-request-id');

          if (serviceId) {
            _this.moveService(serviceId, starts_at, care_provider_id);
          } else if (groupedAppointmentId) {
            _this.moveGroupedAppointment(groupedAppointmentId, starts_at, care_provider_id);
          } else {
            _this.approveAppointmentRequest(appointmentRequestId, starts_at, care_provider_id);
          }
        }

      });
    },

    moveService(serviceId, starts_at, care_provider_id) {
      let no = () => new Ember.RSVP.Promise(resolve => resolve());

      let yes = () => this.handleMovingService(serviceId, starts_at, care_provider_id);

      let service = this.get('store').peekAll('service').filterBy('id', serviceId).get('firstObject');
      let hasAppointments = [];
      let apptsOutsideOfTimeWindow = [];
      let startedAppointments = [];
      let startsAtString = (0, _moment.default)(starts_at).format('h:mm A');
      service.get('appointments').forEach(appt => {
        if (appt.get('started_at') && !appt.get('finished_at')) {
          startedAppointments.push(appt);
        }
      }); // Commented out this code until we add the dragged out of time window modal back
      // let startTime = moment(startsAtString, 'h:mm A');
      // service.get('appointments').forEach((appointment) => {
      //   if (this.get('checkDuplicateAppointmentTimes').isDuplicate(appointment, starts_at)) {
      //     hasAppointments.pushObject(appointment.get('pet.name'));
      //   }
      //
      //   let timeFrameStart = moment(appointment.get('time_frame_start_time'), 'h:mmA');
      //   let timeFrameEnd = moment(appointment.get('time_frame_end_time'), 'h:mmA');
      //
      //   if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //     apptsOutsideOfTimeWindow.push(appointment);
      //   }
      // });

      if (apptsOutsideOfTimeWindow.length > 0) {
        this.set('serviceBeingMoved', service);
        this.set('serviceAppointmentsOutOfWindow', apptsOutsideOfTimeWindow);
        this.set('careProviderId', care_provider_id);
        this.set('startsAt', starts_at);
        this.set('selectedStartTime', startsAtString);
        this.set('showOutOfTimeWindowModal', true);
      } else if (hasAppointments.length > 0) {
        let message = `${hasAppointments.join(', ')} already ${hasAppointments.length > 1 ? 'have an appointment' : 'has an appointment'} at that time`;
        this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
      } else if (startedAppointments.length >= 1) {
        if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
          this.set('showStartedAppointmentMessage', true);
        }

        no();
      } else {
        yes();
      }
    },

    moveGroupedAppointment(groupedAppointmentId, starts_at, care_provider_id) {
      let no = () => new Ember.RSVP.Promise(resolve => resolve());

      let yes = () => this.handleMovingAppointment(groupedAppointmentId, starts_at, care_provider_id);

      let groupedAppointment = this.get('store').peekAll('grouped-appointment').filterBy('id', groupedAppointmentId).get('firstObject'); // Commented out this code until we add the dragged out of time window modal back
      // let startsAtString = moment(starts_at).format('h:mm A');
      // let startTime = moment(startsAtString, 'h:mm A');
      // let firstAppointment = groupedAppointment.get('appointments.firstObject');
      // let timeFrameStart = moment(firstAppointment.get('time_frame_start_time'), 'h:mmA');
      // let timeFrameEnd = moment(firstAppointment.get('time_frame_end_time'), 'h:mmA');
      //
      // if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd)) {
      //   this.set('groupedAppointmentBeingMoved', groupedAppointment);
      //   this.set('careProviderId', care_provider_id);
      //   this.set('startsAt', starts_at);
      //   this.set('selectedStartTime', startsAtString);
      //   this.set('showOutOfTimeWindowModal', true);
      //   return;
      // }

      let isDuplicate = this.get('checkDuplicateAppointmentTimes').isGroupedAppointmentDuplicate(groupedAppointment, starts_at);

      if (isDuplicate) {
        let message = `${groupedAppointment.get('petNames')} already has an appointment at that time`;
        this.get('flashMessage').confirm(yes, no, message, 'Proceed', 'Cancel');
      } else if (this.get('checkStartedAppointment').hasStartedAppointment(groupedAppointment)) {
        if (this.get('currentUser.user').shouldSeeMessage('drag-drop-started-appointment')) {
          this.set('showStartedAppointmentMessage', true);
        }

        no();
      } else {
        yes();
      }
    },

    approveAppointmentRequest(appointmentRequestId, starts_at, care_provider_id) {
      let appointmentRequest = this.get('store').peekAll('appointment-request').filterBy('id', appointmentRequestId).get('firstObject');

      let startsAtString = _moment.default.parseZone(starts_at).format('h:mm A');

      let startTime = _moment.default.parseZone(startsAtString, 'h:mm A');

      let timeFrameStart = _moment.default.parseZone(appointmentRequest.get('time_frame_start_time'), 'h:mmA');

      let timeFrameEnd = _moment.default.parseZone(appointmentRequest.get('time_frame_end_time'), 'h:mmA');

      let serviceDate = _moment.default.parseZone(starts_at).startOf('day');

      let startsAtDate = _moment.default.parseZone(appointmentRequest.get('starts_at')).startOf('day');

      if (startTime.isBefore(timeFrameStart) || startTime.isAfter(timeFrameEnd) || !serviceDate.isSame(startsAtDate)) {
        this.set('appointmentRequestBeingConfirmed', appointmentRequest);
        this.set('careProviderId', care_provider_id);
        this.set('startsAt', starts_at);
        this.set('selectedStartTime', startsAtString);

        if (!serviceDate.isSame(startsAtDate)) {
          this.set('movedToWrongDate', true);
        }

        this.set('showOutOfTimeWindowModal', true);
        return;
      }

      this.handleApprovingRequest(appointmentRequest, starts_at, care_provider_id);
    },

    handleMovingService(serviceId, starts_at, care_provider_id) {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/services/${serviceId}`,
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          care_provider_id,
          starts_at
        })
      }).done(response => {
        this.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        this.notifyPropertyChange('timeBoxes');
        this.get('servicesChanged')();
      }).fail(failResponse => {
        this.notifyUserOfError(failResponse);
      });
    },

    handleMovingAppointment(groupedAppointmentId, starts_at, care_provider_id) {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/grouped_appointments/${groupedAppointmentId}/move`,
        method: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          position: 0,
          care_provider_id,
          starts_at
        })
      }).done(response => {
        this.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        this.notifyPropertyChange('timeBoxes');
        this.get('servicesChanged')();
      }).fail(failResponse => {
        this.notifyUserOfError(failResponse);
      });
    },

    handleApprovingRequest(appointmentRequest, starts_at, care_provider_id) {
      if (this.get('currentUser.user').shouldSeeMessage('drag-drop-appointment-request')) {
        this.set('showDragDropRequestMessage', true);
      }

      let offered_service_id = appointmentRequest.get('offered_service.id');
      let time_frame_start_time = appointmentRequest.get('time_frame_start_time');
      let time_frame_end_time = appointmentRequest.get('time_frame_end_time');
      let date_time = starts_at;
      let special_instructions = appointmentRequest.get('special_instructions');

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/approve`,
        method: 'POST',
        data: {
          care_provider_id,
          offered_service_id,
          time_frame_start_time,
          time_frame_end_time,
          date_time,
          special_instructions
        }
      }).then(response => {
        this.get('store').pushPayload(response);

        _calendarUtil.default.resizeExistingHeaders(); // This is for properly resizing & removing the existing time box


        this.notifyPropertyChange('timeBoxes');
        appointmentRequest.deleteRecord();
        this.get('servicesChanged')();
      });
    },

    clearAppointmentOrServiceBeingMoved() {
      this.set('groupedAppointmentBeingMoved', null);
      this.set('appointmentRequestBeingConfirmed', null);
      this.set('serviceBeingMoved', null);
      this.set('serviceAppointmentsOutOfWindow', null);
      this.set('careProviderId', null);
      this.set('selectedStartTime', null);
      this.set('startsAt', null);
      this.set('movedToWrongDate', false);
    },

    didRender() {
      this._super(...arguments);

      _calendarUtil.default.initHeaders();

      _calendarUtil.default.resizeExistingHeaders();
    },

    updateCurrentTime() {
      this.set('currentTime', (0, _moment.default)());
      let midnight = this.get('currentTime').clone().startOf('day');
      this.set('seconds', this.get('currentTime').diff(midnight, 'seconds') / 28.125 + 122);
    },

    currentTimeStyle: computed('seconds', function () {
      return Ember.String.htmlSafe(`top: ${this.seconds}px;`);
    }),

    showScrollArrows() {
      if (!this || !(0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))) {
        return;
      }

      let [calendarBody] = (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'));
      let amountToScroll = calendarBody.scrollWidth - calendarBody.offsetWidth;

      if (amountToScroll > 0) {
        this.set('canScrollRight', amountToScroll > calendarBody.scrollLeft);
        this.set('canScrollLeft', calendarBody.scrollLeft > 0);
      } else {
        this.set('canScrollRight', false);
        this.set('canScrollLeft', false);
      }
    },

    nowAtHourMinute(time) {
      let momentTime = _moment.default.parseZone(time);

      return (0, _moment.default)().hour(momentTime.hour()).minute(momentTime.minute());
    },

    scrollToFirstService() {
      if ((0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop !== 0) {
        return;
      }

      let firstService = this.get('services.firstObject');

      if (firstService) {
        this.get('services').forEach(service => {
          let serviceMoment = this.nowAtHourMinute(service.get('starts_at'));
          let firstServiceMoment = this.nowAtHourMinute(firstService.get('starts_at'));

          if (serviceMoment.isBefore(firstServiceMoment) && service.get('appointments.length') > 0) {
            firstService = service;
          }
        });
        let $service = (0, _jquery.default)(`.service-box[data-service-id=${firstService.get('id')}]`);

        if ($service.length > 0) {
          (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = $service.parent()[0].offsetTop - 120;
        } else {
          (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = 900;
        }
      } else {
        (0, _jquery.default)(this.element.querySelectorAll('.calendar-body'))[0].scrollTop = 900;
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      _calendarUtil.default.teardownTimeScrolling();

      _calendarUtil.default.teardownScrollArrows();

      $(window).off('resize');
      window.clearInterval(this.get('interval'));
      this.teardownInteract();
    },

    teardownInteract() {
      interact('.draggable').unset();
      interact('.drop-zone').unset();
    },

    timeBoxes: computed('services.@each.starts_at', 'date', function () {
      // IMPORTANT this is a non DST switch day so hours don't repeat
      let FIXED_DAY = '2017-01-01';
      let firstTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      let lastTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      let times = [];
      let serviceTimes = {
        'allDay': {
          0: []
        }
      };
      this.get('services').forEach(service => {
        if (service.get('all_day_service')) {
          serviceTimes.allDay[0].push(service);
        } else {
          let serviceMoment = _moment.default.parseZone(service.get('starts_at'));

          let minutes = serviceMoment.get('minute');
          let hour = serviceMoment.get('hour');

          if (!serviceTimes[hour]) {
            serviceTimes[hour] = {};
          }

          if (minutes < 8) {
            if (!serviceTimes[hour][0]) {
              serviceTimes[hour][0] = [];
            }

            serviceTimes[hour][0].push(service);
          }

          if (minutes < 23 && minutes >= 8) {
            if (!serviceTimes[hour][1]) {
              serviceTimes[hour][1] = [];
            }

            serviceTimes[hour][1].push(service);
          }

          if (minutes < 38 && minutes >= 23) {
            if (!serviceTimes[hour][2]) {
              serviceTimes[hour][2] = [];
            }

            serviceTimes[hour][2].push(service);
          }

          if (minutes < 60 && minutes >= 38) {
            if (!serviceTimes[hour][3]) {
              serviceTimes[hour][3] = [];
            }

            serviceTimes[hour][3].push(service);
          }
        }
      });
      times.push({
        time: 'all-day',
        faded: false,
        hidden: false,
        services: serviceTimes.allDay[0],
        services1: [],
        services2: [],
        services3: []
      });

      while (firstTime < lastTime) {
        let servicesAtHour = serviceTimes[firstTime.get('hour')] || [];
        let faded = false;
        let hidden = false;
        let time = firstTime.format('h A');
        let hour = +firstTime.clone().format('H');

        if (hour < 6) {
          faded = true;
        }

        if (hour === 0) {
          hidden = true;
        }

        times.push({
          time,
          faded,
          hidden,
          hour,
          services: servicesAtHour[0] || [],
          services1: servicesAtHour[1] || [],
          services2: servicesAtHour[2] || [],
          services3: servicesAtHour[3] || []
        });
        firstTime.add(1, 'hour');
      }

      return times;
    }),

    notifyUserOfError(failResponse) {
      if (failResponse && failResponse.responseText) {
        let parsedResponse = JSON.parse(failResponse.responseText);

        if (parsedResponse.errors) {
          this.get('flashMessage').errorWithRefresh(parsedResponse.errors.service);
        } else if (parsedResponse.message) {
          this.get('flashMessage').errorWithRefresh(parsedResponse.message);
        } else {
          this.get('flashMessage').errorWithRefresh(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
        }
      } else {
        this.get('flashMessage').errorWithRefresh(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
      }
    },

    actions: {
      createNewAppointment(careProvider, e) {
        let {
          target
        } = e;
        let date = target.getAttribute('data-date');
        let minute = target.getAttribute('data-minute');
        let hour = target.getAttribute('data-hour');

        if (target.classList.contains('time-target')) {
          let dateTime = (0, _moment.default)(date).hour(hour).minute(minute);
          this.get('createNewAppointment')(careProvider, dateTime);
        }
      },

      clickAppointment(appointment) {
        if (this.get('clickAppointment')) {
          this.get('clickAppointment')(appointment);
        }
      },

      denyUpdateTimeWindow() {
        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentOrServiceBeingMoved();
      },

      confirmUpdateTimeWindow() {
        if (this.get('groupedAppointmentBeingMoved')) {
          this.handleMovingAppointment(this.get('groupedAppointmentBeingMoved.id'), this.get('startsAt'), this.get('careProviderId'));
        } else if (this.get('appointmentRequestBeingConfirmed')) {
          this.handleApprovingRequest(this.get('appointmentRequestBeingConfirmed'), this.get('startsAt'), this.get('careProviderId'));
        } else {
          this.handleMovingService(this.get('serviceBeingMoved.id'), this.get('startsAt'), this.get('careProviderId'));
        }

        this.set('showOutOfTimeWindowModal', false);
        this.clearAppointmentOrServiceBeingMoved();
      },

      hideDragDropRequestMessage() {
        this.set('showDragDropRequestMessage', false);
      },

      dismissedDragDropRequestMessage() {
        this.send('hideDragDropRequestMessage');
        this.get('currentUser.user').reload();
      },

      hideStartedAppointmentMessage() {
        this.set('showStartedAppointmentMessage', false);
      },

      dismissedStartedAppointmentMessage() {
        this.send('hideStartedAppointmentMessage');
        this.get('currentUser.user').reload();
      }

    }
  });

  _exports.default = _default;
});