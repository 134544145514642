define("frontend/components/service-category-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    selected: false,
    category: null,
    iconUrl: computed('category', 'selected', function () {
      return `assets/images/category-icons/${this.get('category')}${this.get('selected') ? '-selected' : ''}.svg`;
    })
  });

  _exports.default = _default;
});