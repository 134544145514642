define("frontend/routes/add-client/pet", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    household: null,
    householdId: computed.readOnly('household.id'),

    model(params) {
      let {
        household_id,
        petIndex
      } = params;
      this.set('petIndex', petIndex);
      return this.store.findRecord('household', household_id).then(household => {
        this.set('household', household);
        return household;
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);
    },

    actions: {
      transitionToHuman() {
        let householdId = this.get('householdId');

        if (this.get('household.secondaryContact.id')) {
          this.transitionTo('add-client.secondary-contact', householdId);
        } else {
          this.transitionTo('add-client.primary-contact', {
            queryParams: {
              householdId
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});