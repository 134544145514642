define("frontend/controllers/application", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Controller.extend({
    phoneTypes: [{
      name: 'Mobile',
      id: 1
    }, {
      name: 'Work',
      id: 2
    }, {
      name: 'Home',
      id: 3
    }],
    timeZones: ['Eastern Time (US & Canada)', 'Central Time (US & Canada)', 'Mountain Time (US & Canada)', 'Pacific Time (US & Canada)', 'Alaska', 'Hawaii'],
    type: 'success',
    showAcceptTOSModal: false,
    showTOSDeclined: false,
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    inboxHasUnreadMessages: Ember.inject.service(),
    hasUnreadMessages: Ember.computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    onIndex: computed.equal('currentRouteName', 'index'),
    onMessagesTab: computed.equal('currentRouteName', 'portal.messages.company'),
    onGettingPaidPage: computed.equal('currentRouteName', 'companies.getting-paid'),
    showBeacons: computed('currentUser.user', 'currentUser.user.isPetParent', 'onMessagesTab', function () {
      return !this.get('currentUser.user') || this.get('currentUser.user.isPetParent') && !this.get('onMessagesTab');
    }),
    showEpaymentsReminderModal: computed('currentUser.user.showEpaymentsReminder', 'onGettingPaidPage', function () {
      if (this.get('onGettingPaidPage')) {
        return null;
      }

      return this.get('currentUser.user.showEpaymentsReminder');
    }),
    showHolidayUpdateReminderModal: computed('currentUser.user.showHolidayUpdateReminder', 'onGettingPaidPage', function () {
      return this.get('currentUser.user.showHolidayUpdateReminder');
    }),

    dismissHolidaysModal(completed, callback) {
      _jquery.default.ajax({
        url: `/api/v2/users/${this.get('currentUser.user.id')}/dismiss_holidays_reminder`,
        method: 'PUT',
        data: {
          completed
        }
      }).done(() => {
        this.set('currentUser.user.showHolidayUpdateReminder', false);

        if (callback) {
          callback();
        }
      }).fail(() => {
        this.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
      });
    },

    goToCompanyPolicies() {
      this.transitionToRoute('companies.category-settings', this.get('currentUser.user.company.id'), 'policies', {
        queryParams: {
          showHolidayPolicyModal: true
        }
      });
    },

    actions: {
      closeNotification() {
        this.set('notification', null);
      },

      reloadModel(retryTransition) {
        this.send('reload', retryTransition);
      },

      refresh() {
        window.location.reload();
      },

      notify(options) {
        this.set('yes', options.yes);
        this.set('no', options.no);
        this.set('notification', options);
      },

      yes() {
        this.get('yes')();
        this.set('notification', null);
      },

      no() {
        this.get('no')();
        this.set('notification', null);
      },

      declineTOS() {
        this.set('showTOSDeclined', true);
      },

      acceptTOS() {
        _jquery.default.ajax({
          url: `/api/v1/users/${this.get('currentUser.user.id')}/accept_tos`,
          method: 'PUT'
        }).done(() => {
          this.set('showAcceptTOSModal', false);
          this.set('showTOSDeclined', false);
          this.set('currentUser.user.accept_new_tos', false);
        }).fail(() => {
          this.get('flashMessage').error('Unexpected error updating terms of service acceptance. Please try again');
        });
      },

      openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      },

      goToClientBilling() {
        this.transitionToRoute('companies.getting-paid', this.get('currentUser.user.company'));
      },

      goToPolicies() {
        this.dismissHolidaysModal(true, this.goToCompanyPolicies.bind(this));
      },

      permenantlyDismissModal() {
        this.dismissHolidaysModal(true, null);
      },

      dismissHolidaysReminder() {
        this.dismissHolidaysModal(false, null);
      },

      dismissEpaymentsReminder() {
        _jquery.default.ajax({
          url: `/api/v2/users/${this.get('currentUser.user.id')}/dismiss_epayments_reminder`,
          method: 'PUT'
        }).done(() => {
          this.set('currentUser.user.showEpaymentsReminder', false);
        }).fail(() => {
          this.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
        });
      }

    }
  });

  _exports.default = _default;
});