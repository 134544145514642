define("frontend/routes/add-client/billing-settings", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    household: null,
    householdId: computed.readOnly('household.id'),

    model(params) {
      let {
        household_id
      } = params;
      let householdUser = this.store.findRecord('household', household_id).then(household => {
        this.set('household', household);
        return household.get('owner');
      });
      let invoiceFrequencies = this.store.query('invoice-frequency', {
        reload: true
      });
      return Ember.RSVP.hash({
        householdUser,
        invoiceFrequencies
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('anySidePanelActive', false);
    },

    actions: {
      goBack() {
        let householdId = this.get('householdId');
        let petIndex = this.get('household.pets.length') - 1;
        this.transitionTo('add-client.pet', householdId, {
          queryParams: {
            petIndex
          }
        });
      }

    }
  });

  _exports.default = _default;
});