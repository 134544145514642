define("frontend/components/select-service-categories-modal", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    flashMessage: inject.service(),
    company: null,
    allCategories: [],
    selectedCategories: [],
    originalCategoryIds: [],
    showTimeBlocksModal: false,

    didInsertElement() {
      this.set('loading', true);
      this.set('allCategories', []);
      this.set('selectedCategories', []);

      _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/companies/${this.get('company.id')}/service_categories`,
        method: 'GET'
      }).then(response => {
        this.set('loading', false);
        this.set('allCategories', response.all_categories);
        let selectedCategoryIds = response.company_categories.map(category => category.id);
        this.set('originalCategoryIds', selectedCategoryIds);
        let selectedCategories = response.company_categories.map(category => {
          return {
            id: category.id,
            time_frames: []
          };
        });
        this.set('selectedCategories', selectedCategories);
        this.set('categoriesWithServices', response.company_categories);
      });
    },

    selectedCategoryIds: computed('selectedCategories.[]', function () {
      return this.get('selectedCategories').map(category => category.id);
    }),
    categoriesWithSelected: computed('allCategories.[]', 'selectedCategoryIds.[]', function () {
      let categories = this.get('allCategories');
      let selected = this.get('selectedCategoryIds');
      categories.forEach(category => {
        if (selected.includes(category.id)) {
          Ember.set(category, 'isSelected', true);
        } else {
          Ember.set(category, 'isSelected', false);
        }
      });
      return categories;
    }),
    actions: {
      close() {
        this.close();
      },

      closeRemoveServiceModal() {
        let selectedCategoryIds = this.get('selectedCategoryIds');
        let selectedCategories = this.get('selectedCategories');
        this.get('categoriesWithServices').forEach(category => {
          if (category.has_services && !selectedCategoryIds.includes(category.id)) {
            selectedCategories.pushObject({
              id: category.id,
              timeFrames: []
            });
          }
        });
        this.set('showRemovingServiceModal', false);
      },

      toggleCategory(category) {
        let selected = this.get('selectedCategoryIds');

        if (selected.includes(category.id)) {
          if (category.name == 'Miscellaneous') {
            let misc_service = this.get('categoriesWithServices').filter(cat => cat.id == category.id).get('firstObject');

            if (misc_service && misc_service.has_services) {
              this.get('flashMessage').sendFlashMessage('This category can\'t be removed when there are still services associated with it.', 'error-without-refresh');
              return;
            }
          }

          let selectedCategories = this.get('selectedCategories').filter(cat => cat.id != category.id);
          this.set('selectedCategories', selectedCategories);
        } else {
          let selectedCategories = this.get('selectedCategories');
          category.time_window_in_minutes = '60';
          category.time_frames = [];
          selectedCategories.pushObject({
            id: category.id,
            time_frames: [],
            use_time_blocks: false,
            show_to_pet_parents: false
          });

          if (!this.get('originalCategoryIds').includes(category.id)) {
            this.set('lastSelectedCategory', category);
            this.set('showTimeBlocksModal', true);
          }
        }
      },

      closeTimeBlockModal() {
        let category = this.get('lastSelectedCategory');
        let selectedCategories = this.get('selectedCategories').filter(cat => cat.id != category.id);
        this.set('selectedCategories', selectedCategories);
        this.set('lastSelectedCategory', null);
        this.set('showTimeBlocksModal', false);
      },

      addCategory(timeFrames, timeWindow, useTimeBlocks, showToPetParents) {
        let lastCategory = this.get('lastSelectedCategory');
        let selectedCategories = this.get('selectedCategories').filter(cat => cat.id != lastCategory.id);
        selectedCategories.pushObject({
          id: lastCategory.id,
          time_frames: timeFrames,
          time_window_in_minutes: timeWindow,
          use_time_blocks: useTimeBlocks,
          show_to_pet_parents: showToPetParents
        });
        this.set('selectedCategories', selectedCategories);
        this.set('lastSelectedCategory', null);
        this.set('showTimeBlocksModal', false);
      },

      clickedSave() {
        let removedCategoryWithServices = false;
        let selectedCategoryIds = this.get('selectedCategoryIds');
        this.get('categoriesWithServices').forEach(category => {
          if (category.has_services && !selectedCategoryIds.includes(category.id)) {
            removedCategoryWithServices = true;
          }
        });

        if (removedCategoryWithServices) {
          this.set('showRemovingServiceModal', true);
        } else {
          this.send('save');
        }
      },

      save() {
        this.set('loading', true);
        this.set('showRemovingServiceModal', false);
        let selectedCategories = this.get('selectedCategories');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/companies/${this.get('company.id')}/set_categories`,
          method: 'POST',
          data: {
            categories: JSON.stringify(selectedCategories)
          }
        }).then(() => {
          window.location.reload();
        });
      }

    }
  });

  _exports.default = _default;
});