define("frontend/components/sign-up-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      // eslint-disable-next-line no-undef
      tRunSlider('.slider');
    }

  });

  _exports.default = _default;
});