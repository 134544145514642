define("frontend/models/grouped-report-card-checklist", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    petId: _emberData.default.attr(),
    pet: _emberData.default.belongsTo('pet', {
      async: false
    }),
    peed: _emberData.default.attr('boolean'),
    pooped: _emberData.default.attr('boolean'),
    fedTreat: _emberData.default.attr('boolean'),
    providedWater: _emberData.default.attr('boolean'),
    isCompleted: computed.or('peed', 'pooped', 'fedTreat', 'providedWater')
  });

  _exports.default = _default;
});