define("frontend/components/document-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: 'img',
    classNames: ['document-image'],
    fileType: null,
    attributeBindings: ['src'],
    // These need to be static so that fingerprinting works
    src: computed('fileType', function () {
      switch (this.get('fileType')) {
        case 'pdf':
          return 'assets/images/document-image/pdf.svg';

        case 'csv':
          return 'assets/images/document-image/csv.svg';

        case 'gif':
          return 'assets/images/document-image/gif.svg';

        case 'jpeg':
          return 'assets/images/document-image/jpeg.svg';

        case 'jpg':
          return 'assets/images/document-image/jpg.svg';

        case 'doc':
          return 'assets/images/document-image/doc.svg';

        case 'docx':
          return 'assets/images/document-image/doc.svg';

        case 'mov':
          return 'assets/images/document-image/mov.svg';

        case 'mpg':
          return 'assets/images/document-image/mpg.svg';

        case 'xls':
          return 'assets/images/document-image/xls.svg';

        case 'png':
          return 'assets/images/document-image/png.svg';

        case 'zip':
          return 'assets/images/document-image/zip.svg';

        case 'ppt':
          return 'assets/images/document-image/ppt.svg';

        case 'rtf':
          return 'assets/images/document-image/rtf.svg';

        case 'txt':
          return 'assets/images/document-image/txt.svg';

        default:
          return 'assets/images/document-image/other.svg';
      }
    })
  });

  _exports.default = _default;
});