define("frontend/routes/client-list/household/messages", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    currentUser: inject.service(),
    router: inject.service(),

    model() {
      let household = this._super(...arguments);

      let household_id = household.get('id');
      let company_id = this.get('currentUser.user.company.id');
      let company = this.store.findRecord('company', company_id);
      let messages = this.get('infinity').model('message', {
        household_id,
        company_id,
        perPage: 20,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        household,
        messages,
        company
      });
    },

    actions: {
      goToAppointmentRequest(requestId, companyId, householdId) {
        let queryParams = {
          backRoute: this.get('router.currentRouteName'),
          backHouseholdId: householdId,
          requestId
        };
        this.transitionTo('edit-single-appointment.index', {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});