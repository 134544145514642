define("frontend/components/masked-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputActivated: false,
    tabindex: 0,
    value: null,
    hint: null,
    showHint: false,
    hover: false,
    hiddenInput: false,
    classNames: ['masked-callout'],
    borderTop: true,

    didInsertElement() {
      this._super(...arguments);

      this.send('resetActivated');
      (0, _jquery.default)(this.element).hover(() => {
        this.set('hover', true);
      }, () => {
        this.set('hover', false);
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).off('hover');
    },

    actions: {
      focusOut() {
        this.send('resetActivated');
      },

      resetActivated() {
        if (this.get('value')) {
          let replacedValue = this.get('value').replace(/_|-|\(|\)| /g, '');
          this.set('inputActivated', this.get('value.length') > 0 && replacedValue.length > 0);
        } else {
          this.set('inputActivated', false);
        }
      }

    }
  });

  _exports.default = _default;
});