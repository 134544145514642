define("frontend/routes/inbox", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      search_term: null
    },
    filtered: false,
    infinity: inject.service(),
    currentUser: inject.service(),
    router: inject.service(),

    beforeModel(transition) {
      this._super(...arguments);

      if (transition.targetName == 'inbox' && !this.get('currentUser.user.canViewInbox')) {
        this.transitionTo('index');
      }

      if (this.get('controller')) {
        this.set('controller.loading', true);
      }
    },

    model(params) {
      let distributionThread = _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/messages/get_distribution_thread`,
        method: 'GET',
        data: {
          company_id: this.get('currentUser.user.company.id')
        }
      });

      let serviceCategories = this.store.query('service-category', {
        company_id: this.get('currentUser.user.company.id')
      });

      if (params.filter !== '') {
        this.set('filtered', true);
        let householdsWithMessages = this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter,
          with_messages: true
        });
        let allHouseholds = this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter
        });
        return Ember.RSVP.hash({
          householdsWithMessages,
          allHouseholds,
          distributionThread,
          serviceCategories
        });
      }

      this.set('filtered', false);
      let householdsWithMessages = this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1,
        with_messages: true
      });
      let allHouseholds = this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        householdsWithMessages,
        allHouseholds,
        distributionThread,
        serviceCategories
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('loading', false);
      this.set('allHouseholds', model.allHouseholds);
      this.set('householdsWithMessages', model.householdsWithMessages);
    },

    actions: {
      willTransition(transition) {
        this._super(...arguments);

        if (transition.targetName.search('inbox') === -1) {
          this.get('controller').set('selectedHouseholds', []);
          this.get('controller').set('showingNewMessagePage', false);
        }
      },

      loading() {
        return !this.get('controller') || this.get('controller.loading');
      },

      parentRefresh() {
        this.refresh();
      },

      parentInfinityLoad() {
        this._infinityLoad();
      },

      transitionToInbox() {
        this.transitionTo('inbox');
      },

      transitionToDistributionList() {
        this.transitionTo('inbox.distribution-list');
      },

      goToAppointmentRequest(requestId, companyId, householdId) {
        let queryParams = {
          backRoute: this.get('router.currentRouteName'),
          backHouseholdId: householdId,
          requestId
        };
        this.transitionTo('edit-single-appointment.index', {
          queryParams
        });
      },

      goToHousehold(householdId) {
        let queryParams = {
          backToMessages: true
        };
        this.transitionTo('client-list.household', householdId, {
          queryParams
        });
      }

    }
  });

  _exports.default = _default;
});