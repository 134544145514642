define("frontend/components/holiday-surcharge-holiday-list-item-date-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['date-choice'],
    classNameBindings: ['selected'],
    holidayName: null,
    iconUrl: null,
    holidayDate: null,
    date: computed('momentDate', function () {
      let momentDate = this.get('momentDate');
      let dayMap = ['SU', 'M', 'T', 'W', 'TH', 'F', 'SA'];
      return {
        day: momentDate.date(),
        dayOfWeek: dayMap[momentDate.day()],
        month: momentDate.format('MMM'),
        date: momentDate.format('YYYY-MM-DD')
      };
    }),
    selected: computed('holidaySurchargePolicy.dates.[]', function () {
      return this.get('holidaySurchargePolicy.dates').includes(this.get('date.date'));
    }),

    click() {
      let selectedDates = this.get('holidaySurchargePolicy.dates');
      let date = this.get('date.date');

      if (this.get('selected')) {
        selectedDates.removeObject(date);
      } else {
        selectedDates.pushObject(date);
      }
    }

  });

  _exports.default = _default;
});