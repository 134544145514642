define("frontend/components/invoices-widget/invoices-group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['invoices-group-header'],
    classNameBindings: ['collapsed'],
    sendDate: computed('invoicesGroup.end_date', function () {
      return moment(this.get('invoicesGroup.end_date'));
    }),
    actions: {
      toggleCollapsed() {
        this.get('toggleCollapsed')();
      }

    }
  });

  _exports.default = _default;
});