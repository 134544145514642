define("frontend/helpers/join-with-and", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.joinWithAnd = joinWithAnd;

  function joinWithAnd(_ref) {
    let [arr] = _ref;
    return _stringUtil.default.joinWithAnd(arr);
  }

  var _default = Ember.Helper.helper(joinWithAnd);

  _exports.default = _default;
});