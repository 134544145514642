define("frontend/controllers/portal/invoices-summary/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    stripeInfo: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    router: inject.service(),
    flashMessage: inject.service(),
    queryParams: ['showMakePaymentModal', 'showAddPaymentModal', 'directFromEmail'],
    showMakePaymentModal: false,
    showAddPaymentModal: false,
    parentCameFromEmailToMakePaymentButHasAlreadyDoneSo: false,
    displayName: computed.readOnly('model.company.displayName'),
    directFromEmail: false,
    currentRouteName: computed.readOnly('router.currentRouteName'),
    household: computed.readOnly('currentUser.user.household'),
    company_id: computed.readOnly('model.company.id'),
    settings: computed.readOnly('model.settings.settings'),
    invoiceDetail: inject.controller('portal.invoices-summary.company.invoice-detail'),
    paymentDetail: inject.controller('portal.invoices-summary.company.payment-detail'),
    activeInvoiceId: computed.readOnly('invoiceDetail.model.id'),
    activePaymentId: computed.readOnly('paymentDetail.model.id'),
    fetchedCustomerInfo: computed.readOnly('paymentMethods.isFulfilled'),
    onInvoiceDetailPage: computed.equal('currentRouteName', 'portal.invoices-summary.company.invoice-detail'),
    onPaymentDetailPage: computed.equal('currentRouteName', 'portal.invoices-summary.company.payment-detail'),
    companies: computed('settings', function () {
      let settings = this.get('settings');

      if (settings) {
        let settingsToShow = settings.filter(setting => !setting.denied_request && setting.pet_parents_activated || setting.staff_status == 'Owner');
        return $.map(settingsToShow, companySettings => {
          return companySettings.company_name;
        });
      }

      return [];
    }),
    ownerUserId: computed('household', 'household.owner', function () {
      if (this.get('household.owner')) {
        return this.get('household.owner').get('id');
      }

      return null;
    }),
    paymentMethods: computed('ownerUserId', 'company_id', function () {
      let userId = this.get('ownerUserId');
      let companyId = this.get('company_id');

      if (userId && companyId) {
        let promise = this.get('stripeInfo').fetchCustomerInfo(userId, companyId);
        return _emberData.default.PromiseObject.create({
          promise
        });
      }

      return null;
    }),
    selectedCompany: computed('settings', 'company_id', function () {
      let settings = this.get('settings');
      let company_id = this.get('company_id');

      if (settings && settings.length > 0) {
        return settings.find(s => {
          return s.company_id == company_id;
        });
      }

      return null;
    }),
    userClickedLinkFromFailedPaymentEmailObserver: observer('directFromEmail', 'showMakePaymentModal', 'selectedCompany.account_balance', function () {
      const directFromEmail = this.get('directFromEmail');
      const showMakePaymentModal = this.get('showMakePaymentModal');
      const accountBalance = this.get('selectedCompany.account_balance');
      const alreadyPaidUp = parseFloat(accountBalance) <= 0;

      if (directFromEmail && showMakePaymentModal) {
        if (alreadyPaidUp) {
          this.set('directFromEmail', false);
          this.set('showMakePaymentModal', false);
          this.set('showSuccessPaymentModal', true);
          this.set('parentCameFromEmailToMakePaymentButHasAlreadyDoneSo', true);
          this.transitionToRoute({
            queryParams: {
              showMakePaymentModal: false,
              directFromEmail: false
            }
          });
        } else {
          this.transitionToRoute({
            queryParams: {
              showMakePaymentModal: true,
              directFromEmail: false
            }
          });
        }
      }
    }),
    invoiceListItems: computed('selectedCompany', 'household', function () {
      let household_id = this.get('household.id');
      let company_id = this.get('selectedCompany.company_id');

      if (company_id && household_id) {
        return this.get('infinity').model('invoice-list-item', {
          company_id,
          perPage: 15,
          startingPage: 1
        });
      }

      return null;
    }),
    lastPayment: computed('invoiceListItems.[]', function () {
      const invoiceListItems = this.get('invoiceListItems');

      if (invoiceListItems && invoiceListItems.get('content')) {
        let plainArrayItems = invoiceListItems.get('content');
        const lastPaymentItem = plainArrayItems.find(invoiceListItem => invoiceListItem.isPayment);
        return lastPaymentItem.get('invoice_payment');
      }

      return null;
    }),

    showMadePaymentModal(balance, amountPaid, isTip) {
      this.set('showMakePaymentModal', false);
      this.set('showAddTipModal', false);
      this.set('selectedCompany.account_balance', balance);
      this.notifyPropertyChange('invoiceListItems');
      this.set('amountPaid', amountPaid);

      if (isTip) {
        this.set('showSuccessTipModal', true);
      } else {
        this.set('showSuccessPaymentModal', true);
      }
    },

    actions: {
      changeCompany(companyName) {
        let allSettings = this.get('settings');
        let settings = allSettings.find(s => {
          return s.company_name == companyName;
        });
        this.transitionToRoute('portal.invoices-summary.company', settings.company_id);
      },

      clickInvoice(invoice) {
        this.transitionToRoute('portal.invoices-summary.company.invoice-detail', this.get('company_id'), invoice.id);
      },

      clickPayment(payment) {
        this.transitionToRoute('portal.invoices-summary.company.payment-detail', this.get('company_id'), payment.id);
      },

      clickMakeAPayment() {
        let paymentMethods = this.get('paymentMethods.content');

        if (paymentMethods && paymentMethods.default) {
          this.set('showMakePaymentModal', true);
        } else {
          this.set('showAddPaymentModal', true);
          this.set('modalToShowAfterSave', 'showMakePaymentModal');
        }
      },

      clickAddTip(invoicePayment) {
        this.set('selectedPayment', invoicePayment);
        let paymentMethods = this.get('paymentMethods.content');

        if (paymentMethods && paymentMethods.default) {
          this.set('showAddTipModal', true);
        } else {
          this.set('showAddPaymentModal', true);
          this.set('modalToShowAfterSave', 'showAddTipModal');
        }
      },

      changePaymentMethod(modalToShowAfterSave) {
        this.set('showMakePaymentModal', false);
        this.set('showAddPaymentModal', true);
        this.set('modalToShowAfterSave', modalToShowAfterSave);
      },

      madePayment(balance, amountPaid) {
        let isTip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        if (this.get('media.isMobile')) {
          this.transitionToRoute('portal.invoices-summary.company', this.get('company_id')).then(() => {
            this.showMadePaymentModal(balance, amountPaid, isTip);
          });
        } else {
          this.showMadePaymentModal(balance, amountPaid, isTip);
        }
      },

      savePaymentMethod(token, paymentMethod) {
        this.set('showingPaymentMethodModal', false);
        this.set('updatingPaymentMethod', true);
        let userId = this.get('ownerUserId');
        this.get('stripeInfo').createPaymentMethod(userId, token, paymentMethod).then(() => {
          this.notifyPropertyChange('paymentMethods');
          this.set('showAddPaymentModal', false);
          this.set(this.get('modalToShowAfterSave'), true);
          this.set('updatingPaymentMethod', false);
        }).catch(result => {
          this.get('flashMessage').sendFlashMessage(result.payload.message, 'user-error');
          this.set('updatingPaymentMethod', false);
        });
      },

      redirectToMicrodeposits() {
        this.transitionToRoute('microdeposits', this.get('currentUser.user.emd5'));
      }

    }
  });

  _exports.default = _default;
});