define("frontend/routes/add-appointment/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      selectedHouseholdId: {}
    },
    infinity: inject.service(),

    model(_ref) {
      let {
        filter,
        selectedHouseholdId
      } = _ref;
      this.set('selectedHouseholdId', selectedHouseholdId);
      return this.get('infinity').model('household', {
        perPage: 10,
        startingPage: 1,
        filter
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('loading', false);
      controller.set('showErrorFlash', false);

      if (this.get('selectedHouseholdId')) {
        this.store.findRecord('household', this.get('selectedHouseholdId')).then(household => {
          controller.send('selectHousehold', household);
        });
      } else {
        controller.send('selectHousehold', null);
      }
    },

    actions: {
      next() {
        this.transitionTo('add-appointment.details');
      },

      loading() {
        if (this.get('controller')) {
          this.set('controller.loading', true);
          return false;
        } else {
          return true;
        }
      }

    }
  });

  _exports.default = _default;
});