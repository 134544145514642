define("frontend/components/activity-log-side-panel", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['activity-log-side-panel'],
    activityLogs: Ember.inject.service(),
    storedLogDates: [],
    unreadAfter: computed.alias('activityLogs.unreadAfter'),
    lastSeen: null,
    loadedAll: computed.alias('activityLogs.loadedAll'),
    loadingMore: computed.not('loadedAll'),

    willInsertElement() {
      this._super(...arguments); // eslint-disable-next-line no-undef


      this.set('uniqueLogIds', new Set());
      this.get('filterChanged');
    },

    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },

    willDestroyElement() {
      this._super(...arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },

    filterChanged: observer('activityLogs.filter', function () {
      this.set('storedLogDates', []); // eslint-disable-next-line no-undef

      this.set('uniqueLogIds', new Set());
    }),
    logDates: computed('logs', 'logs.[]', function () {
      let logs = this.get('logs');
      let keys = Object.keys(logs);
      let uniqueLogIds = this.get('uniqueLogIds');
      keys.sort().reverse();
      let logDates = this.get('storedLogDates');
      keys.forEach(date => {
        let logDate = logDates.filter(storedLog => storedLog.date == date);

        if (logDate.length > 0) {
          logDate = logDate[0];
          logs[date].forEach(log => {
            if (logDate.logs.filter(logDateLog => logDateLog.id == log.id).length == 0) {
              logDate.logs.pushObject(log);
            }
          });
        } else {
          let newLogDate = {
            date,
            logs: []
          };
          logs[date].forEach(log => {
            if (!uniqueLogIds.has(log.id)) {
              uniqueLogIds.add(log.id);
              newLogDate.logs.pushObject(log);
            }
          });
          logDates.pushObject(newLogDate);
        }
      });
      logDates.forEach((logDate, index) => logDates.set(`${index}.logs`, logDate.logs.sortBy('created_at').reverse()));

      if (logDates[0]) {
        let lastSeen = logDates[0].logs[0].created_at;

        if (this.get('lastSeen') != lastSeen) {
          this.set('lastSeen', lastSeen);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/activity_logs/record_last_seen`,
            method: 'POST',
            data: {
              last_seen: lastSeen
            }
          });
        }
      }

      return logDates;
    }),

    handleMouseEnter(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },

    actions: {
      closeActivityLog() {
        this.get('closeActivityLog')();
      },

      loadMoreLogs() {
        let activityLogs = this.get('activityLogs');

        if (activityLogs.loadedAll) {
          return;
        }

        this.get('activityLogs').pullLogs();
      }

    }
  });

  _exports.default = _default;
});