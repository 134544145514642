define("frontend/components/remove-contract-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onClose() {
        this.onClose();
      },

      removeContract() {
        this.removeContract();
      }

    }
  });

  _exports.default = _default;
});