define("frontend/controllers/edit-single-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['backRoute', 'backHouseholdId', 'backId', 'backDate', 'backCareProviderId'],
    backRoute: null,
    backHouseholdId: null,
    backId: null,
    backDate: null,
    backCareProviderId: null,
    saving: false,
    media: inject.service(),
    notSaving: computed.not('saving'),
    notMobile: computed.not('media.isMobile'),
    hasClose: computed.and('notSaving', 'notMobile'),
    indexController: inject.controller('edit-single-appointment.index'),
    anySidePanelOpen: computed.readOnly('indexController.anySidePanelOpen'),
    showCloseButton: computed('hasClose', 'anySidePanelOpen', function () {
      return this.get('hasClose') && !this.get('anySidePanelOpen');
    })
  });

  _exports.default = _default;
});