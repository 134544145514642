define("frontend/components/ppb-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ppb-input'],
    disabled: false,

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.get('value')) {
        this.set('floatLabel', true);
      }
    },

    actions: {
      focusIn() {
        this.set('focused', true);
        this.set('floatLabel', true);
      },

      focusOut() {
        if (!this.get('value')) {
          this.set('floatLabel', false);
        }

        this.set('focused', false);
      }

    }
  });

  _exports.default = _default;
});