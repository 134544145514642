define("frontend/components/grouped-appointment-list", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed,
    observer
  } = Ember;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    store: inject.service(),
    media: inject.service(),
    appointmentRequests: [],
    groupedAppointments: [],
    selectedGroupedAppointments: [],
    per_page: 10,
    upcomingPage: 1,
    pastPage: 1,
    loadedOnce: false,
    upcomingReachedInfinity: false,
    lastAppointmentTime: null,
    pastReachedInfinity: false,
    upcomingCount: 0,
    pastCount: 0,
    showCanceledAppointments: false,
    readOnly: false,
    household: null,
    bulkEditing: false,
    showPendingAppointments: false,
    isPetParent: computed.alias('currentUser.user.isPetParent'),

    didInsertElement() {
      this.send('resetElement');

      if (this.get('setGroupedAppointmentListChild')) {
        this.get('setGroupedAppointmentListChild')(this);
      }
    },

    shouldResetElement: observer('household.id', 'showCanceledAppointments', 'showPendingAppointments', function () {
      this.send('resetElement');
    }),
    sortedGroupedAppointments: computed('groupedAppointments.[]', function () {
      return this.get('groupedAppointments').sort((a, b) => {
        let timeOne = moment(a.get('time'));
        let timeTwo = moment(b.get('time'));

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
    }),
    sortedAppointmentRequests: computed('appointmentRequests.[]', function () {
      return this.get('appointmentRequests').sort((a, b) => {
        let timeOne = moment(a.get('starts_at'));
        let timeTwo = moment(b.get('starts_at'));

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
    }),

    sendCancellationEmail(groupedAppointment) {
      let petNames = this.get('currentUser.user.household.petNames');
      let companyEmail = groupedAppointment.get('service.company_email');
      let lastName = this.get('currentUser.user.household.owner.last_name');
      let apptDate = moment(groupedAppointment.get('service.starts_at')).format('dddd, MMMM Do');
      let subject = `Request to cancel ${apptDate} appointment for ${petNames} ${lastName}`;
      let userName = this.get('currentUser.user.fullName');
      let service = groupedAppointment.get('offered_service.nameWithDuration');
      let pickupWindow = groupedAppointment.get('pickup_window') ? `${groupedAppointment.get('pickup_window')} arrival` : groupedAppointment.get('service.starts_at_time');
      let body = `Hi!%0D%0A%0D%0AI'd like to cancel the ${service} for ${petNames} on ${apptDate}, currently scheduled for ${pickupWindow}.%0D%0A%0D%0APlease let me know if you can accomodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A${userName}`;
      window.location.href = `mailto:${companyEmail}?subject=${subject}&body=${body}`;
    },

    openCancelAppointmentModal(group, cancellationNote) {
      this.set('showCancelApptModal', true);
      this.set('cancellationNote', cancellationNote);
      this.set('selectedGroup', group);
    },

    removeAppointmentsFromGroup(groupedAppointment, apptIds) {
      if (groupedAppointment.get('appointments.length') == apptIds.length) {
        this.get('groupedAppointments').removeObject(groupedAppointment);
      } else {
        let appts = groupedAppointment.get('appointments');
        apptIds.forEach(apptId => {
          appts = appts.filter(appt => appt.get('id') != apptId);
        });
        groupedAppointment.set('appointments', appts);
      }
    },

    removePetsFromRequest(appointmentRequest, petIds) {
      if (appointmentRequest.get('pets.length') == petIds.length) {
        this.get('appointmentRequests').removeObject(appointmentRequest);
      } else {
        let pets = appointmentRequest.get('pets');
        petIds.forEach(petId => {
          pets = pets.filter(pet => pet.get('id') != petId);
        });
        appointmentRequest.set('pets', pets);
      }
    },

    loadedAppointmentsCallback(newAppointments, apptArrayName, past) {
      if (this.get('reseting')) {
        this.set('reseting', false);
        this.set('isFetching', false);
        this.set('loadingPastAppointments', false);
        this.send('loadAppointments');
        return;
      }

      let scrollTop = $('.appointments-content').scrollTop();
      let scrollHeight = $('.appointments-content').prop('scrollHeight');
      let clientAccounts = this.get('currentUser.user.client_accounts');
      let loadedApptIds = this.get(apptArrayName).map(appt => parseInt(appt.get('id')));
      newAppointments.forEach(appt => {
        if (!loadedApptIds.includes(parseInt(appt.get('id')))) {
          let showCancellations = true;
          let showRequests = true;

          if (this.get('isPetParent')) {
            let account = clientAccounts.filter(acct => acct.company_id == appt.get('company_id')).get('firstObject');
            showCancellations = account.parent_in_app_booking;
          }

          appt.set('showCancellations', showCancellations);
          appt.set('showRequests', showRequests);

          if (this.get('lastAppointmentTime')) {
            let lastAppointmentTime = moment(this.get('lastAppointmentTime'));
            let apptTime = moment(appt.get('service.starts_at'));

            if (apptTime.isAfter(lastAppointmentTime)) {
              this.set('lastAppointmentTime', appt.get('service.starts_at'));
            }
          } else {
            this.set('lastAppointmentTime', appt.get('service.starts_at'));
          }

          this.get(apptArrayName).pushObject(appt);
        }
      });
      let apptsLength = newAppointments.get('length');
      let reachedInfinity = apptsLength < this.get('per_page');

      if (past) {
        this.incrementProperty('pastPage');
        this.set('pastReachedInfinity', reachedInfinity);
        this.set('pastCount', this.get('pastCount') + apptsLength);
      } else {
        this.incrementProperty('upcomingPage');
        this.set('upcomingReachedInfinity', reachedInfinity);
        let upcomingCount = 0;
        newAppointments.forEach(newAppt => {
          if (!newAppt.get('completed')) {
            upcomingCount++;
          }
        });
        this.set('upcomingCount', this.get('upcomingCount') + upcomingCount);
      }

      this.set('loadedOnce', true);
      this.set('isFetching', false);
      this.set('loadingPastAppointments', false);

      if (apptsLength > 0) {
        Ember.run.scheduleOnce('afterRender', () => {
          if (past) {
            if (this.get('media.isMobile')) {
              scrollTop = $('.appointments-content').prop('scrollHeight') - scrollHeight - 3;

              if (this.get('isPetParent')) {
                scrollTop -= 20;
              }
            } else {
              scrollTop = $('.appointments-content').prop('scrollHeight') - scrollHeight - 6;
            }
          }

          if (this.get('showCanceledAppointments')) {
            scrollTop -= 16;
          }

          document.querySelector('.appointments-content').scroll({
            top: scrollTop
          });
        });
      }
    },

    actions: {
      resetElement() {
        this.set('groupedAppointments', []);
        this.set('appointmentRequests', []);
        this.set('selectedGroupedAppointments', []);
        this.set('upcomingPage', 1);
        this.set('pastPage', 1);
        this.set('loadedOnce', false);
        this.set('upcomingReachedInfinity', false);
        this.set('pastReachedInfinity', false);
        this.set('upcomingCount', 0);
        this.set('pastCount', 0);
        this.set('lastAppointmentTime', null);

        if (this.get('isFetching')) {
          this.set('reseting', true);
        } else {
          this.send('loadAppointments');
        }
      },

      loadAppointments(appointmentType) {
        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        let per_page = this.get('per_page');
        let past = appointmentType == 'past';
        let page = past ? this.get('pastPage') : this.get('upcomingPage');
        let household_id = this.get('household.id');
        let canceled = this.get('showCanceledAppointments');
        let last_appointment_time = this.get('lastAppointmentTime');

        if (past) {
          this.set('loadingPastAppointments', true);
        }

        if (this.get('showPendingAppointments')) {
          let type = past ? 'expired' : 'pending';
          this.get('store').query('appointment-request', {
            per_page,
            page,
            past,
            household_id,
            type
          }).then(appointmentRequests => {
            this.loadedAppointmentsCallback(appointmentRequests, 'appointmentRequests', past);
          });
        } else {
          this.get('store').query('grouped-appointment', {
            per_page,
            page,
            past,
            household_id,
            canceled,
            last_appointment_time
          }).then(groupedAppointments => {
            this.loadedAppointmentsCallback(groupedAppointments, 'groupedAppointments', past);
          });
        }
      },

      clickCancelAppointment(group) {
        if (this.get('isPetParent') && !group.showCancellations) {
          this.sendCancellationEmail(group);
          return;
        }

        this.openCancelAppointmentModal(group);
      },

      closeCancelAppointmentModal() {
        this.set('showCancelApptModal', false);
        this.set('selectedGroup', null);

        if (this.get('nextGroupedAppointmentToCancel')) {
          this.get('nextGroupedAppointmentToCancel')();
        }
      },

      modifyAppointment(groupedAppointment) {
        this.get('modifyAppointment')(groupedAppointment);
      },

      modifyAppointmentRequest(appointmentRequest) {
        this.get('modifyAppointmentRequest')(appointmentRequest);
      },

      cancelAppointment(groupedAppointment, apptIds, cancellationFee, cancellationNotes) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/cancel_multiple`,
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee,
            cancellation_notes: cancellationNotes
          }
        }).then(() => {
          this.removeAppointmentsFromGroup(groupedAppointment, apptIds);
          this.set('showCancelApptModal', false);
          this.set('selectedGroup', null);

          if (this.get('nextGroupedAppointmentToCancel')) {
            this.get('nextGroupedAppointmentToCancel')();
          }
        }).fail(response => {
          let message = 'Unexpected error canceling appointment. Please try again';

          if (response.responseJSON.error) {
            message = response.responseJSON.error;
          }

          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('showCancelApptModal', false);
          this.set('selectedGroup', null);
        });
      },

      clickAddAppointment() {
        this.get('clickAddAppointment')();
      },

      openCancelAppointmentRequestModal(appointmentRequest) {
        this.set('showCancelApptRequestModal', true);
        this.set('selectedAppointmentRequest', appointmentRequest);
      },

      closeCancelAppointmentRequestModal() {
        this.set('showCancelApptRequestModal', false);
        this.set('selectedAppointmentRequest', null);
      },

      cancelAppointmentRequest(appointmentRequest, petIds) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointment_requests/${appointmentRequest.get('id')}/cancel`,
          method: 'POST',
          data: {
            pet_ids: petIds
          }
        }).then(() => {
          this.removePetsFromRequest(appointmentRequest, petIds);
          this.set('showCancelApptRequestModal', false);
          this.set('selectedAppointmentRequest', null);
        }).fail(response => {
          if (response.status === 400) {
            let {
              message
            } = response.responseJSON;
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message,
              type: 'user-error'
            });
            this.removePetsFromRequest(appointmentRequest, petIds);
            this.set('showCancelApptRequestModal', false);
            this.set('selectedAppointmentRequest', null);
          } else {
            let message = 'Unexpected error canceling the appointment. Please try again';
            Ember.getOwner(this).lookup('controller:application').send('notify', {
              message,
              type: 'error'
            });
            this.set('showCancelApptRequestModal', false);
            this.set('selectedAppointmentRequest', null);
          }
        });
      },

      openArchiveAppointmentModal(group) {
        this.set('showArchiveApptModal', true);
        this.set('selectedGroup', group);
      },

      closeArchiveAppointmentModal() {
        this.set('showArchiveApptModal', false);
        this.set('selectedGroup', null);
      },

      archiveAppointment(groupedAppointment, apptIds) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v1/appointments/delete_multiple`,
          method: 'DELETE',
          data: {
            appointment_ids: apptIds
          }
        }).then(() => {
          this.removeAppointmentsFromGroup(groupedAppointment, apptIds);
          this.set('showArchiveApptModal', false);
          this.set('selectedGroup', null);
        }).fail(() => {
          let message = 'Unexpected error archiving the appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('showArchiveApptModal', false);
          this.set('selectedGroup', null);
        });
      },

      openRestoreAppointmentModal(group) {
        this.set('showRestoreApptModal', true);
        this.set('selectedGroup', group);
      },

      closeRestoreAppointmentModal() {
        this.set('showRestoreApptModal', false);
        this.set('selectedGroup', null);
      },

      closeRemovedCancellationFeeModal() {
        this.set('showRemovedCancellationFeeModal', false);
      },

      restoreAppointment(groupedAppointment, apptIds) {
        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/appointments/restore_multiple`,
          method: 'POST',
          data: {
            appointment_ids: apptIds
          }
        }).then(response => {
          this.removeAppointmentsFromGroup(groupedAppointment, apptIds);
          this.set('showRestoreApptModal', false);
          this.set('selectedGroup', null);

          if (response.removed_cancellation_fee) {
            this.set('showRemovedCancellationFeeModal', true);
          }
        }).fail(() => {
          let message = 'Unexpected error restoring the appointment. Please try again';
          Ember.getOwner(this).lookup('controller:application').send('notify', {
            message,
            type: 'error'
          });
          this.set('showRestoreApptModal', false);
          this.set('selectedGroup', null);
        });
      },

      selectAppointment(groupedAppointment) {
        let selectedGroupedAppointments = this.get('selectedGroupedAppointments');
        let found = false;
        selectedGroupedAppointments.forEach(selectedGroupedAppt => {
          if (selectedGroupedAppt.get('id') == groupedAppointment.get('id')) {
            selectedGroupedAppointments.removeObject(groupedAppointment);
            found = true;
          }
        });

        if (!found) {
          selectedGroupedAppointments.pushObject(groupedAppointment);
        }

        this.get('selectAppointment')(groupedAppointment);
      },

      showReportCard(groupedReportCard) {
        this.showReportCard(groupedReportCard);
      }

    }
  });

  _exports.default = _default;
});