define("frontend/routes/client-list/household/household-user", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model(params) {
      let {
        household_user_id
      } = params;
      return this.store.findRecord('household-user', household_user_id);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('addressModalOpen', false);
      controller.set('upcomingAppointments', []);
      controller.set('editingUpcomingAppointments', false);
      controller.set('loadingAppointments', false);
      controller.set('showLoadMore', false);
      controller.set('alreadySentRequest', false);
      controller.set('page', 1);
      controller.set('loadingMessages', false);
    },

    backQueryParams() {
      let backRoute = this.get('controller.currentRouteName');
      let backHouseholdId = this.get('controller.model.id');
      let backId = this.get('controller.selectedUser.id');
      return {
        backHouseholdId,
        backId,
        backRoute
      };
    },

    actions: {
      transitionToMessages(household) {
        this.get('controller').set('messagesLoading', true);
        let queryParams = this.backQueryParams.call(this);
        this.transitionTo('client-list.household.messages', household, {
          queryParams
        }).then(() => {
          this.get('controller').set('messagesLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});