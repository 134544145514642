define("frontend/routes/add-client", ["exports", "frontend/mixins/transition-back-route", "frontend/routes/base-route"], function (_exports, _transitionBackRoute, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    cookies: inject.service(),
    currentUser: inject.service(),

    setupController(controller) {
      this._super(...arguments);

      controller.set('saving', false);
    },

    actions: {
      cancel() {
        let backRoute = this.get('controller.backRoute');
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backId = this.get('controller.backId');

        if (backRoute) {
          this.transitionBack(backRoute, backHouseholdId, backId);
        } else {
          this.transitionTo('client-list');
        }
      },

      redirectToSearch(email) {
        this.transitionTo('add-client.client-search', {
          queryParams: {
            email
          }
        });
      },

      redirectToAddedUser(household_id, directToDocumentsPage) {
        this.get('cookies').write('clientListAddedUser', 'true', {
          path: '/'
        });

        if (directToDocumentsPage) {
          window.location.href = `/client-list/${household_id}/documents`;
        } else {
          window.location.href = `/client-list/${household_id}/billing`;
        }
      },

      redirectToUser(household_id, user_id) {
        this.transitionTo('client-list.household.household-user', household_id, user_id);
      }

    }
  });

  _exports.default = _default;
});