define("frontend/helpers/if-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ifIn = ifIn;

  function ifIn(_ref) {
    let [elem, list] = _ref;

    if (list.indexOf(elem) > -1) {
      return true;
    }

    return false;
  }

  var _default = Ember.Helper.helper(ifIn);

  _exports.default = _default;
});