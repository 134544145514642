define("frontend/components/invoice-detail-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['invoice-detail-buttons'],
    classNameBindings: ['editing'],
    actions: {
      print() {
        this.get('print')();
      },

      resend() {
        this.get('resend')();
      },

      void() {
        this.get('void')();
      },

      unvoid() {
        this.get('unvoid')();
      },

      edit() {
        this.get('edit')();
      },

      cancel() {
        this.get('cancel')();
      },

      remove() {
        this.get('remove')();
      },

      save() {
        this.get('save')();
      },

      billNow() {
        this.get('billNow')();
      }

    }
  });

  _exports.default = _default;
});