define("frontend/components/add-on-service-dropdown", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['add-on-service-dropdown'],
    pets: [],
    showPetNames: false,
    addOnServicesSelected: [],
    filteredOptions: computed('options', 'addOnServicesSelected', 'selectedService.offered_service', function () {
      return this.get('options').filter(option => {
        return !this.get('addOnServicesSelected').includes(option.value) || option.value == this.get('selectedService.offered_service.id');
      });
    }),
    selectedOption: computed('options', 'selectedService', 'selectedService.offered_service', function () {
      let options = this.get('options');
      let selected = this.get('selectedService.offered_service');

      if (options.length > 0 && selected) {
        return options.findBy('value', selected.get('id'));
      }
    }),
    petNames: computed('pets', function () {
      return _stringUtil.default.joinWithAnd(this.get('pets').map(pet => pet.get('name')));
    }),
    dropdownLabel: computed('petNames', 'media.isMobile', function () {
      if (this.get('media.isMobile')) {
        return this.get('showPetNames') ? `Add-on (for ${this.get('petNames')})` : 'Add-on';
      } else {
        return this.get('showPetNames') ? `add-on service (for ${this.get('petNames')})` : 'add-on service';
      }
    }),
    actions: {
      changeAddOnService(selected) {
        this.get('changeAddOnService')(this.get('selectedService'), selected);
      },

      removeAddOnService() {
        this.get('removeAddOnService')(this.get('selectedService'));
      }

    }
  });

  _exports.default = _default;
});