define("frontend/services/react-app", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Service.extend({
    reactBaseUrl: _environment.default.environment == 'development' ? "http://localhost:3001" : "",
    reportsUrl: computed('reactBaseUrl', function () {
      return `${this.get('reactBaseUrl')}/react/analytics`;
    }),
    companyDocumentsUrl: computed('reactBaseUrl', function () {
      return `${this.get('reactBaseUrl')}/react/settings/company/documents`;
    }),
    companySubscriptionsUrl: computed('reactBaseUrl', function () {
      return `${this.get('reactBaseUrl')}/react/settings/company/subscriptions`;
    }),

    getAppointmentRequestUrl(companyId) {
      let requestId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let apptIds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let petIds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let categoryId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      let baseUrl = `${this.get('reactBaseUrl')}/react/portal/${companyId}/request-appointments/`;

      if (requestId) {
        baseUrl += `?requestId=${requestId}`;
      }

      if (apptIds) {
        baseUrl += `?apptIds=${apptIds.join(',')}`;
      }

      if (petIds && categoryId) {
        baseUrl += `?petIds=${petIds.join(',')}&categoryId=${categoryId}`;
      }

      return baseUrl;
    },

    getPetParentDocumentsUrl(householdId) {
      return `${this.get('reactBaseUrl')}/react/portal/${householdId}/documents`;
    }

  });

  _exports.default = _default;
});