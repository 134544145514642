define("frontend/utils/string-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    joinWithAnd(arr) {
      let andAlternative = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'and';
      return arr.reduce((newArr, item, index, arr) => {
        let lastItem = arr.length - 1 === index;

        if (arr.length === 1) {
          newArr.push(item);
        } else if (arr.length === 2) {
          newArr.push(lastItem ? ` ${andAlternative} ${item}` : item);
        } else {
          newArr.push(lastItem ? ` ${andAlternative} ${item}` : `${item}, `);
        }

        return newArr;
      }, []).join('');
    }

  };
  _exports.default = _default;
});