define("frontend/initializers/bugsnag", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    Bugsnag.apiKey = _environment.default.APP.BUGSNAG.API_KEY;
    Bugsnag.appVersion = _environment.default.APP.version;
    Bugsnag.releaseStage = _environment.default.environment;
    Bugsnag.notifyReleaseStages = ['production', 'uat'];
  }

  var _default = {
    name: 'bugsnag',
    initialize
  };
  _exports.default = _default;
});