define("frontend/services/polling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    polls: {},

    add(label, context, pollingCallback) {
      let interval = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
      this.schedule(label, context, pollingCallback, interval);
    },

    stop(label) {
      if (this.get(`polls.${label}`)) {
        clearInterval(this.get(`polls.${label}`));
      }
    },

    schedule(label, context, pollingCallback, interval) {
      let handler = Ember.run.bind(context, pollingCallback);
      let intervalId = setInterval(handler, interval);
      this.set(`polls.${label}`, intervalId);
    }

  });

  _exports.default = _default;
});