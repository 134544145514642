define("frontend/mixins/cleanup-on-transition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      willTransition() {
        let model = this.get('controller.model');

        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }
      }

    }
  });

  _exports.default = _default;
});