define("frontend/components/modify-appointment-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    appointments: [],

    didInsertElement() {
      this._super(...arguments);

      let appointments = this.get('groupedAppointment.appointments').map(appt => {
        return {
          id: appt.get('id'),
          avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
          pet_name: appt.get('pet').get('name'),
          selected: true
        };
      });
      this.set('appointments', appointments);
    },

    month: computed('groupedAppointment.service.starts_at', function () {
      return (0, _moment.default)(this.get('groupedAppointment.service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('groupedAppointment.service.starts_at', function () {
      return (0, _moment.default)(this.get('groupedAppointment.service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('groupedAppointment.service.starts_at', function () {
      return (0, _moment.default)(this.get('groupedAppointment.service.starts_at')).startOf('day').format('ddd');
    }),
    showSelectionError: computed('appointments.@each.selected', function () {
      let selected = this.get('appointments').filter(appt => appt.selected);
      return selected.length == 0;
    }),
    actions: {
      modifyAppointment() {
        if (this.get('showSelectionError')) {
          return;
        }

        let selectedAppts = this.get('appointments').filter(appt => appt.selected);
        let apptIds = selectedAppts.map(appt => appt.id);
        this.get('modifyAppointment')(apptIds);
      },

      onClose() {
        this.get('close')();
      }

    }
  });

  _exports.default = _default;
});