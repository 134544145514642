define("frontend/components/mobile-collapse-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['link-container', 'collapse-dropdown'],
    classNameBindings: ['active'],
    active: false,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('.header-link')).click(e => {
        let dropdownClosed = (0, _jquery.default)(e.target).hasClass('active') && !this.get('active');
        this.set('active', dropdownClosed);
      });
      (0, _jquery.default)(this.element).focusout(() => {
        this.set('active', false);
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).off('focusout');
      (0, _jquery.default)(this.element.querySelectorAll('.header-link')).off('click');
    }

  });

  _exports.default = _default;
});