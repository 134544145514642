define("frontend/components/report-card-image-view", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['report-card-image-view'],
    media: inject.service(),
    isMobile: computed.alias('media.isMobile'),
    coverPhoto: null,
    photos: [],
    selectedPhoto: null,
    isUploadingPhoto: false,
    isDeletingPhoto: false,
    isLoading: computed.or('isUploadingPhoto', 'isDeletingPhoto'),
    formattedDate: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).format('ddd, MMM D, YYYY');
    }),
    onAddAnotherSlide: computed.equal('selectedPhoto.id', undefined),
    isSelectedPhotoCoverPhoto: computed('coverPhoto.url', 'selectedPhoto.url', function () {
      return this.get('coverPhoto.url') === this.get('selectedPhoto.url');
    }),

    didInsertElement() {
      this._super(...arguments);

      this.setSelectedPhotoUrl();
      Ember.run.schedule('afterRender', () => {
        let selectedPhoto = this.findPhotoInView();

        if (!selectedPhoto) {
          selectedPhoto = this.get('coverPhoto');
        }

        this.set('selectedPhoto', selectedPhoto);
        this.setupMouseScroll();
      });
      (0, _jquery.default)('#report-card-carousel').on('scroll', () => {
        this.set('selectedPhoto', this.findPhotoInView());
      });
    },

    setupMouseScroll() {
      const slider = document.querySelector('#report-card-carousel');
      let isDown = false;
      let startX;
      let scrollLeft;
      slider.addEventListener('mousedown', e => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const SCROLL_SPEED = 3;
        const walk = (x - startX) * SCROLL_SPEED;
        slider.scrollLeft = scrollLeft - walk;
      });
    },

    setSelectedPhotoUrl() {
      let image;

      if (this.get('coverPhoto')) {
        image = this.get('coverPhoto');
      } else {
        image = this.get('photos.firstObject');
      }

      this.set('selectedPhoto', image);
    },

    findPhotoInView() {
      let visibleSlideId = null;
      const carousel = (0, _jquery.default)('#report-card-carousel');
      const carouselLeft = carousel.scrollLeft();
      const carouselRight = carouselLeft + carousel.outerWidth();
      (0, _jquery.default)('#report-card-carousel li').each((index, slide) => {
        const slideLeft = slide.offsetLeft;
        const slideWidth = slide.offsetWidth;
        const slideRight = slideLeft + slideWidth;

        if (this.get('isMobile')) {
          if (slideLeft >= carouselLeft && slideRight <= carouselRight) {
            visibleSlideId = slide.dataset.imageId;
          }
        } else {
          if (slideRight >= carouselLeft + 50 && slideLeft <= carouselRight - 50) {
            visibleSlideId = slide.dataset.imageId;
          }
        }
      });
      let selectedPhoto = null;

      if (visibleSlideId === this.get('coverPhoto.id')) {
        selectedPhoto = this.get('coverPhoto');
      } else {
        this.get('photos').forEach(photo => {
          if (visibleSlideId === photo.get('id')) {
            selectedPhoto = photo;
          }
        });
      }

      return selectedPhoto;
    },

    actions: {
      hidePhotos() {
        this.onClose();
      },

      changeCoverPhoto() {
        let onCoverPhotoChanged = () => {
          Ember.run.schedule('afterRender', () => {
            (0, _jquery.default)('#report-card-carousel').scrollLeft(0);
          });
        };

        this.onChangeCoverPhoto(this.get('selectedPhoto'), onCoverPhotoChanged);
      },

      removePhoto() {
        let onPhotoRemovedCallback = () => {
          Ember.run.schedule('afterRender', () => {
            if (this.get('coverPhoto') === null && this.get('photos.length') === 0) {
              this.send('hidePhotos');
              return;
            }

            this.set('isDeletingPhoto', false);
          });
        };

        let selectedPhoto = this.findPhotoInView();
        this.set('isDeletingPhoto', true);
        this.onRemovePhoto(selectedPhoto, onPhotoRemovedCallback);
      },

      addedPhotoToReportCard(file) {
        let onPhotoAdded = () => {
          this.set('isUploadingPhoto', false);
          Ember.run.schedule('afterRender', () => {
            (0, _jquery.default)('#report-card-carousel').scrollLeft(100000);
          });
        };

        this.set('isUploadingPhoto', true);
        this.onPhotoAddedToReportCard(file, onPhotoAdded);
      }

    }
  });

  _exports.default = _default;
});