define("frontend/components/custom-prices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['custom-prices'],
    model: null,
    numCustomPrices: computed.readOnly('household.offered_service_client_prices.length'),
    newOfferedServicePrice: null,
    existingOfferedservicePrice: null,
    editingPrice: false,
    inFlight: false,
    store: Ember.inject.service(),
    hasCustomPrices: computed.gt('household.offered_service_client_prices.length', 0),
    customPricesSorting: ['offered_service.position'],
    currentUser: Ember.inject.service(),
    currentCompany: computed.readOnly('currentUser.user.company'),
    actions: {
      editOfferedServicePrice(offeredServicePrice) {
        let store = this.get('store');
        let props = offeredServicePrice.getProperties('price', 'incremental_price', 'household', 'offered_service');
        let newOfferedServicePrice = store.createRecord('offered-service-client-price', props);
        this.set('editingPrice', true);
        this.set('newOfferedServicePrice', newOfferedServicePrice);
        this.set('existingOfferedServicePrice', offeredServicePrice);
        this.set('anySidePanelActive', true);
      },

      createOfferedServicePrice(offeredService) {
        let store = this.get('store');
        let household = this.get('model');
        let props = Ember.assign(offeredService.getProperties('price', 'incremental_price'), {
          household,
          offered_service: offeredService
        });
        let newOfferedServicePrice = store.createRecord('offered-service-client-price', props);
        this.set('editingPrice', false);
        this.set('newOfferedServicePrice', newOfferedServicePrice);
        this.set('anySidePanelActive', true);
      },

      deleteOfferedServicePrice(offeredServicePrice) {
        this.get('newOfferedServicePrice').deleteRecord();
        this.set('inFlight', true);
        offeredServicePrice.destroyRecord().then(() => {
          this.set('newOfferedServicePrice', null);
          this.set('existingOfferedServicePrice', null);
          this.set('anySidePanelActive', false);
        }).finally(() => {
          this.set('inFlight', false);
        });
      },

      saveOfferedServicePrice(offeredServicePrice) {
        this.set('inFlight', true);
        offeredServicePrice.save().then(() => {
          this.savedOfferedServicePrice();
          this.set('anySidePanelActive', false);
          this.set('newOfferedServicePrice', null);
        }).finally(() => {
          this.set('inFlight', false);
        });
      }

    }
  });

  _exports.default = _default;
});