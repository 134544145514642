define("frontend/components/invoices-widget/sent-header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sent-header-dropdown'],

    didInsertElement() {
      this._super(...arguments);

      $(window).click(_ref => {
        let {
          target
        } = _ref;
        let $target = $(target);

        if ($target.parent().hasClass('sent-header') || $target.hasClass('dropdown-arrow')) {
          return;
        }

        this.close();
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      $(window).off('click');
    },

    actions: {
      selectOption(option) {
        this.get('selectOption')(option);
      }

    }
  });

  _exports.default = _default;
});