define("frontend/initializers/integrations", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // Injects the configurations for various 3rd party integrations into all components
  function initialize(application) {
    application.register('integrations:config', _environment.default.integrations, {
      instantiate: false
    });
    application.inject('component', 'integrations', 'integrations:config');
  }

  var _default = {
    name: 'integrations',
    initialize
  };
  _exports.default = _default;
});