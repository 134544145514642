define("frontend/models/appointment-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = _emberData.default.Model.extend({
    internal_notes: _emberData.default.attr('string'),
    client_instructions: _emberData.default.attr('string'),
    address: _emberData.default.belongsTo('address', {
      async: true
    }),
    addressNotes: computed.readOnly('address.notes'),
    fullAddress: computed.readOnly('address.fullAddress'),
    static_maps_url: _emberData.default.attr('string'),
    address_locked: _emberData.default.attr('boolean'),
    pickup_window: _emberData.default.attr('string'),
    family_last_name: _emberData.default.attr('string'),
    report_card: _emberData.default.belongsTo('report-card', {
      async: true
    }),
    appointment_add_on_services: _emberData.default.hasMany('appointment-add-on-service', {
      async: false
    }),
    cancellation_note: _emberData.default.attr('string')
  });

  _exports.default = _default;
});