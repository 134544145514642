define("frontend/helpers/format-recurring-weekdays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatRecurringWeekdays = formatRecurringWeekdays;
  const DAY_OF_WEEK = ['Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'];

  function formatRecurringWeekdays(_ref) {
    let [recurringAppointments] = _ref;

    if (recurringAppointments.get('firstObject.frequency') == 'monthly') {
      return 'Monthly';
    }

    let days = recurringAppointments.map(ra => ra.get('day_of_week')).uniq().sort();

    if (days.join(',') === '0,1,2,3,4,5,6') {
      return 'Daily';
    }

    if (days.join(',') === '1,2,3,4,5') {
      return 'Weekdays';
    }

    if (days[0] === 0) {
      days.push(days.shift());
    }

    return days.map(day => DAY_OF_WEEK[day]).join(' ');
  }

  var _default = Ember.Helper.helper(formatRecurringWeekdays);

  _exports.default = _default;
});