define("frontend/utils/payment-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALL = 'all';
  const CARD = 'card';
  const BANK = 'bank';
  const MANUAL = 'manual';
  var _default = {
    ALL,
    CARD,
    BANK,
    MANUAL
  };
  _exports.default = _default;
});