define("frontend/routes/edit-staff", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      return this.transitionTo('staff');
    },

    actions: {
      transitionToStaff() {
        let employeeFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';
        this.transitionTo('staff.index', {
          queryParams: {
            employeeFilter
          }
        }).then(transition => {
          transition.send('refresh');
        });
      }

    }
  });

  _exports.default = _default;
});