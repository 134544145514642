define("frontend/components/user-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['user-bar'],
    classNameBindings: ['color'],
    media: inject.service(),
    value: 0,
    total: 0,
    label: null,
    fixedValue: computed('value', function () {
      let maxWidth = this.get('media.isMobile') ? 0.65 : 0.8;
      return parseFloat(this.get('value')) * maxWidth;
    })
  });

  _exports.default = _default;
});