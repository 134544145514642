define("frontend/components/pet-inputs/pet-vaccinations", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['layout-column', 'flex-100', 'pet-vaccinations'],
    classNameBindings: ['hasVaccinations'],
    store: inject.service(),
    ajax: inject.service(),
    allVaccinations: computed('pet.pet_type', function () {
      let pet_type_id = this.get('pet.pet_type_id');
      let url = `/api/v2/vaccinations?pet_type_id=${pet_type_id}`;
      let promise = this.get('ajax').request(url).then(resp => resp.vaccinations);
      return _emberData.default.PromiseArray.create({
        promise
      });
    }),
    hasVaccinations: computed('pet.vaccinations.@each.dirtyType', function () {
      let vaccinations = this.get('pet.vaccinations');
      const nonDeletedVaccinations = vaccinations.filter(v => v.dirtyType !== "deleted");
      return nonDeletedVaccinations.get("length");
    }),
    actions: {
      updateVaccinations() {
        if (this.get('pet.vaccinations.length') === 0) {
          this.send('addAnotherVaccination');
        }

        this.set('showingModal', true);
      },

      addAnotherVaccination() {
        let newVaccination = this.get('store').createRecord('vaccination');
        this.get('pet.vaccinations').pushObject(newVaccination);
      }

    }
  });

  _exports.default = _default;
});