define("frontend/mixins/autocomplete/highlight-first-match", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      highlightFirstMatch(api) {
        if (api && api.results && api.results.length) {
          return api.results[0];
        }

        return null;
      },

      disableEnterKey() {
        $(window).on('keydown', function (event) {
          if (event.keyCode == 13) {
            event.preventDefault();
            return false;
          }
        });
      },

      enableEnterKey() {
        $(window).off('keydown');
      }

    }
  });

  _exports.default = _default;
});