define("frontend/components/user-selection-wheel/selection-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['selection-item'],
    media: inject.service(),
    circleDiameter: 200,
    degrees: 35,
    classNameBindings: ['selected'],
    attributeBindings: ['style'],
    selected: false,
    index: 0,
    style: computed('circleDiameter', 'index', function () {
      let diameter = this.get('circleDiameter');
      let degrees = this.get('degrees');
      let radius = diameter / 2;
      let index = parseInt(this.get('index'));
      let left = radius + Math.sin(this.toRadians(degrees * index * -1)) * radius * -1 - 22.5;
      let top = radius + Math.cos(this.toRadians(degrees * index)) * radius - 22.5;

      if (!this.get('media.isMobile')) {
        left += 25;
      }

      return `left: ${left}px; top: ${top}px;`;
    }),

    toRadians(angle) {
      return angle * (Math.PI / 180.0);
    }

  });

  _exports.default = _default;
});