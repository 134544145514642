define("frontend/components/pet-or-user-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['pet-or-user-information'],
    petOrUser: null,
    showAdditionalPetInfo: false,
    isUser: computed('petOrUser.modelName', function () {
      let modelName = this.get('petOrUser.modelName');
      return modelName === 'household-user' || modelName === 'user';
    })
  });

  _exports.default = _default;
});