define("frontend/components/invoice-receipt/adjustments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    classNames: ['invoice-summary'],
    adjustmentsSorting: ['updated_at:desc'],
    sortedManualAdjustments: computed.sort('manualAdjustments', 'adjustmentsSorting'),
    multipleWeekendSurcharges: computed.gt('groupedWeekendSurcharges.length', 1),
    multipleOffHoursSurcharges: computed.gt('groupedOffHoursSurcharges.length', 1),
    multipleLateBookingSurcharges: computed.gt('groupedLateBookingSurcharges.length', 1),
    multipleHolidaySurcharges: computed.gt('groupedHolidaySurchargesholidaySurcharges.length', 1),
    multipleCancellationFees: computed.gt('cancellationFees.length', 1),
    indentAdjustments: computed.or('multipleWeekendSurcharges', 'multipleLateBookingSurcharges', 'multipleHolidaySurcharges', 'multipleOffHoursSurcharges', 'multipleCancellationFees'),
    manualAdjustments: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return !adjustment.get('surcharge_policy_type') && !adjustment.get('is_cancellation_fee') && !adjustment.get('is_sales_tax');
      });
    }),
    hasNonTaxableAdjustments: computed('adjustments.@each.apply_sales_tax', function () {
      return this.get('adjustments').some(adjustment => {
        return !adjustment.get('apply_sales_tax');
      });
    }),
    showLowerInvoiceSummarySection: computed('salesTaxTotal', 'hasNonTaxableAdjustments', function () {
      const hasNonTaxableAdjustments = this.get('hasNonTaxableAdjustments') || false;
      const salesTaxTotal = this.get('salesTaxTotal') || 0;
      return hasNonTaxableAdjustments || salesTaxTotal > 0;
    }),
    salesTaxAdjustment: computed('adjustments.[]', function () {
      let adjustments = this.get('adjustments').filter(adjustment => {
        return adjustment.get('is_sales_tax');
      });
      return adjustments.get('firstObject');
    }),
    salesTaxRate: computed('salesTaxAdjustment', function () {
      const salesTaxAdjustment = this.get('salesTaxAdjustment');
      const rate = salesTaxAdjustment ? salesTaxAdjustment.get('sales_tax_rate') || 0 : 0;
      return `Estimated sales tax (${rate}%)`;
    }),
    // OFF HOURS SURCHARGES
    offHoursSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return adjustment.get('isOffHoursSurcharge');
      });
    }),
    offHoursSurchargesTotal: computed('offHoursSurcharges.[]', function () {
      return this.get('offHoursSurcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
    }),
    offHoursSurchargesTooltip: computed('offHoursSurcharges.[]', function () {
      let groupedSurcharges = this.groupSurcharges(this.get('offHoursSurcharges'));
      let text = `${this.get('offHoursSurcharges.firstObject.surcharge_policy_description')} applied to:\n\n`;
      groupedSurcharges.forEach((surcharge, index) => {
        if (index > 0) {
          text += '\n';
        }

        text += `${surcharge.get('label')}`;
      });
      return text;
    }),
    // WEEKEND SURCHARGES
    weekendSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return adjustment.get('isWeekendSurcharge');
      });
    }),
    weekendSurchargesTotal: computed('weekendSurcharges.[]', function () {
      return this.get('weekendSurcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
    }),
    weekendSurchargesTooltip: computed('weekendSurcharges.[]', function () {
      let groupedSurcharges = this.groupSurcharges(this.get('weekendSurcharges'));
      let text = `${this.get('weekendSurcharges.firstObject.surcharge_policy_description')} applied to:\n\n`;
      groupedSurcharges.forEach((surcharge, index) => {
        if (index > 0) {
          text += '\n';
        }

        text += `${surcharge.get('label')}`;
      });
      return text;
    }),
    // HOLIDAY SURCHARGES
    holidaySurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return adjustment.get('isHolidaySurcharge');
      });
    }),
    holidaySurchargesTotal: computed('holidaySurcharges.[]', function () {
      return this.get('holidaySurcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
    }),
    holidaySurchargesTooltip: computed('holidaySurcharges.[]', function () {
      let groupedSurcharges = this.groupSurcharges(this.get('holidaySurcharges'));
      let text = `${this.get('holidaySurcharges.firstObject.surcharge_policy_description')} applied to:\n\n`;
      groupedSurcharges.forEach((surcharge, index) => {
        if (index > 0) {
          text += '\n';
        }

        text += `${surcharge.get('label')}`;
      });
      return text;
    }),
    // LATE BOOKING SURCHARGES
    lateBookingSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return adjustment.get('isLateBookingSurcharge');
      });
    }),
    lateBookingSurchargesTotal: computed('lateBookingSurcharges.[]', function () {
      return this.get('lateBookingSurcharges').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
    }),
    lateBookingSurchargesTooltip: computed('lateBookingSurcharges.[]', function () {
      let groupedSurcharges = this.groupSurcharges(this.get('lateBookingSurcharges'));
      let text = `${this.get('lateBookingSurcharges.firstObject.surcharge_policy_description')} applied to:\n\n`;
      groupedSurcharges.forEach((surcharge, index) => {
        if (index > 0) {
          text += '\n';
        }

        text += `${surcharge.get('label')}`;
      });
      return text;
    }),
    // CANCELLATION SURCHARGES
    cancellationFees: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(adjustment => {
        return adjustment.get('is_cancellation_fee');
      });
    }),
    cancellationFeesTotal: computed('cancellationFees.[]', function () {
      return this.get('cancellationFees').reduce((a, b) => a + parseFloat(b.get('amount')), 0);
    }),
    cancellationFeesTooltip: computed('cancellationFees.[]', function () {
      let groupedSurcharges = this.groupSurcharges(this.get('cancellationFees'));
      let text = `${this.get('cancellationFees.firstObject.surcharge_policy_description')} applied to:\n\n`;
      groupedSurcharges.forEach((surcharge, index) => {
        if (index > 0) {
          text += '\n';
        }

        text += `${surcharge.get('label')}`;
      });
      return text;
    }),
    groupedCancellationFees: computed('cancellationFees.[]', function () {
      return this.groupSurcharges(this.get('cancellationFees'));
    }),
    groupedWeekendSurcharges: computed('weekendSurcharges.[]', function () {
      return this.groupSurcharges(this.get('weekendSurcharges'));
    }),
    groupedOffHoursSurcharges: computed('offHoursSurcharges.[]', function () {
      return this.groupSurcharges(this.get('offHoursSurcharges'));
    }),
    groupedLateBookingSurcharges: computed('lateBookingSurcharges.[]', function () {
      return this.groupSurcharges(this.get('lateBookingSurcharges'));
    }),
    groupedHolidaySurcharges: computed('holidaySurcharges.[]', function () {
      return this.groupSurcharges(this.get('holidaySurcharges'));
    }),

    groupSurcharges(surcharges) {
      let groupedSurcharges = {};
      surcharges.forEach(surcharge => {
        let key = `${surcharge.get('appointment_date')} ${surcharge.get('offered_service_name')}`;

        if (!groupedSurcharges[key]) {
          groupedSurcharges[key] = Ember.Object.create({
            appointment_date: surcharge.get('appointment_date'),
            appointment_sortable_date: surcharge.get('appointment_sortable_date'),
            categoryName: surcharge.get('appointment_category_name'),
            label: key,
            amount: 0,
            adjustments: [],
            selected: false
          });
        }

        groupedSurcharges[key].amount += parseFloat(surcharge.get('amount'));
        groupedSurcharges[key].adjustments.push(surcharge);
      });
      return Object.values(groupedSurcharges);
    }

  });

  _exports.default = _default;
});