define("frontend/controllers/companies/edit", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'model.preferred_name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'model.email': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    'model.phone': [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    newCompaniesController: Ember.inject.controller('companies'),
    saving: computed.alias('newCompaniesController.saving'),
    hasAddress: computed.notEmpty('model.zipcode'),
    timeZone: inject.service(),
    flashMessage: inject.service(),
    timeZoneOptions: computed.readOnly('timeZone.timeZoneOptions'),
    legalEntityOptions: [{
      label: 'No',
      value: false
    }, {
      label: 'Yes',
      value: true
    }],
    weekStartsOnOptions: [{
      label: 'Sunday through Saturday',
      value: 'Sunday'
    }, {
      label: 'Monday through Sunday',
      value: 'Monday'
    }],
    nameInvalid: computed('model.name', 'model.legal_entity', function () {
      return this.get('model.legal_entity') && !this.get('validations.attrs.model.name.isValid');
    }),
    preferredNameInvalid: computed('model.preferred_name', 'model.legal_entity', function () {
      return !this.get('model.legal_entity') && !this.get('validations.attrs.model.preferred_name.isValid');
    }),
    emailInvalid: computed('model.email', function () {
      return !this.get('validations.attrs.model.email.isValid');
    }),
    phoneInvalid: computed('model.phone', function () {
      return !this.get('validations.attrs.model.phone.isValid');
    }),
    canSave: computed('nameInvalid', 'preferredNameInvalid', 'emailInvalid', 'phoneInvalid', function () {
      return !this.get('nameInvalid') && !this.get('preferredNameInvalid') && !this.get('emailInvalid') && !this.get('phoneInvalid');
    }),
    selectedLegalStatus: computed('model.legal_entity', function () {
      return this.get('legalEntityOptions').find(item => item.value === this.get('model.legal_entity'));
    }),
    selectedTimeZone: computed('model.time_zone', function () {
      return this.get('timeZoneOptions').find(item => item.value === this.get('model.time_zone'));
    }),
    selectedCalendarWeek: computed('model.week_starts_on', function () {
      return this.get('weekStartsOnOptions').find(item => item.value === this.get('model.week_starts_on'));
    }),
    disableLegalStatus: computed('model.has_stripe_account', function () {
      return this.get('model.has_stripe_account');
    }),
    actions: {
      save() {
        if (this.get('saving')) {
          return;
        }

        if (!this.get('canSave')) {
          return;
        }

        this.set('saving', true);
        this.get('model').save().then(() => this.transitionToRoute('index')).finally(() => this.set('saving', false));
      },

      uploadedPhoto(photo) {
        this.set('model.logo_url', photo);
      },

      changeLegalStatus(value) {
        this.set('model.legal_entity', value.value);
      },

      changePhoneNumber(value) {
        this.set('model.phone', value);
      },

      changeTimeZone(value) {
        this.set('model.time_zone', value.value);
      },

      changeCalendarWeek(value) {
        this.set('model.week_starts_on', value.value);
      },

      updateLegalStatus() {
        this.set('showUpdateLegalStatusModal', true);
      },

      closeLegalStatusModal() {
        this.set('showUpdateLegalStatusModal', false);
      },

      confirmUpdateLegalStatus() {
        if (!this.get('updatedLegalStatus')) {
          let company_id = this.get('model.id');
          this.set('updatingLegalStatus', true);

          _jquery.default.ajax({
            url: `${_environment.default.APP.host}/api/v2/companies/${company_id}/notify_support_for_legal_status_change`,
            method: 'POST',
            data: {
              company_id
            }
          }).done(response => {
            this.set('updatingLegalStatus', false);
            this.set('showUpdateLegalStatusModal', false);
            this.get('flashMessage').sendFlashMessage(response.message, 'Success');
          });

          this.set('updatedLegalStatus', true);
        }
      }

    }
  });

  _exports.default = _default;
});