define("frontend/controllers/edit-client/secondary-contact", ["exports", "frontend/controllers/edit-client/edit-client-page-base", "frontend/config/environment", "jquery"], function (_exports, _editClientPageBase, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _editClientPageBase.default.extend({
    session: inject.service(),
    accountSession: inject.service(),
    valid: computed.readOnly('model.validations.isValid'),
    application: Ember.inject.controller(),
    editingNotificationSettings: false,
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    actions: {
      clickEditNotifications() {
        this.set('editingNotificationSettings', true);
      },

      closeEditNotifications() {
        this.set('editingNotificationSettings', false);
      },

      uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      },

      redirectToRemoveHuman(secondaryContact) {
        this.transitionToRoute('edit-client.remove-household-user', secondaryContact);
      },

      switchPrimaryContact() {
        let household = this.get('model');

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/households/${household.id}/switch_primary_contact`,
          method: 'POST',
          data: {
            id: household.id
          }
        }).then(() => {
          this.get('model').reload().then(() => {
            this.transitionToRoute('edit-client.primary-contact', household);
          });
        });
      }

    }
  });

  _exports.default = _default;
});