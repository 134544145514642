define("frontend/controllers/admin/companies", ["exports", "frontend/config/environment", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match", "jquery"], function (_exports, _environment, _approvableTable, _highlightFirstMatch, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject
  } = Ember;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    session: inject.service(),
    accountSession: inject.service(),
    selectedCompany: null,
    showServiceFeeModal: false,
    updateServiceFeeModalText: computed('selectedCompany', function () {
      return `Are you sure you want to turn ${this.get('selectedCompany.service_fees_enabled') ? 'OFF' : 'ON'} service fees for ${this.get('selectedCompany.name')}?`;
    }),
    updatePetParentsActivatedModalInfoText: computed('selectedCompany', function () {
      return `Turning ON this toggle will mean that welcome emails will be released to all pet parent clients for <span class="bold">${this.get('selectedCompany.name')}</span>. Pet parents with existing PetPocketbook accounts will be prompted to verify their connection with this provider the next time they login and will also be able to see any and all activity from this company, including appointments already booked on the calendar.`;
    }),
    updateQuickbooksModalText: computed('selectedCompany', function () {
      return `Are you sure you want to ${this.get('selectedCompany.quickbooks_access') ? 'disallow' : 'allow'} <span class="bold">${this.get('selectedCompany.name')}</span> to access our QuickBooks beta?`;
    }),
    updateQuickbooksModalButtonText: computed('selectedCompany', function () {
      return this.get('selectedCompany.quickbooks_access') ? 'Disallow' : 'Allow';
    }),
    filteredRecords: computed('model.@each.{name,preferred_name}', 'filter', function () {
      if (this.get('filter') !== '') {
        return this.get('model').filter(company => {
          let ownerMatch = false;
          company.owners.forEach(owner => {
            if (owner.name.toLowerCase().match(this.get('filter').toLowerCase())) {
              ownerMatch = true;
            }
          });
          return ownerMatch || company.name.toLowerCase().match(this.get('filter').toLowerCase()) || company.preferred_name && company.preferred_name.toLowerCase().match(this.get('filter').toLowerCase());
        });
      } else {
        return this.get('model');
      }
    }),
    actions: {
      impersonate(user_email) {
        let store = this.get('session.store');

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/impersonate_session`, {
          user: {
            user_email
          }
        }).then(response => {
          store.clear().then(() => {
            store.persist(response).then(() => {
              this.get('accountSession').clearCompany();
            });
          });
        });
      },

      showServiceFeeModal(company) {
        this.set('selectedCompany', company);
        this.set('showServiceFeeModal', true);
      },

      closeServiceFeeModal() {
        this.set('selectedCompany', null);
        this.set('showServiceFeeModal', false);
      },

      showQuickbooksModal(company) {
        this.set('selectedCompany', company);
        this.set('showQuickbooksModal', true);
      },

      closeQuickbooksModal() {
        this.set('selectedCompany', null);
        this.set('showQuickbooksModal', false);
      },

      toggleServiceFees() {
        this.set('showServiceFeeModal', false);

        _jquery.default.post(`${_environment.default.APP.host}/api/v2/companies/${this.get('selectedCompany.id')}/toggle_service_fees`).then(() => {
          Ember.set(this.get('selectedCompany'), 'service_fees_enabled', !this.get('selectedCompany.service_fees_enabled'));
          this.set('selectedCompany', null);
        });
      },

      toggleFeature(company, feature_name) {
        _jquery.default.post(`${_environment.default.APP.host}/api/v2/companies/${company.id}/toggle_feature`, {
          feature_name
        }).then(() => {
          window.location.reload();
        });
      },

      showPetParentsActivationModal(company) {
        this.set('selectedCompany', company);
        this.set('showPetParentsActivatedModal', true);
      },

      closePetParentsActivationModal() {
        this.set('selectedCompany', null);
        this.set('showPetParentsActivatedModal', false);
      },

      togglePetParentsActivated() {
        this.set('showPetParentsActivatedModal', false);
        Ember.$.post(`${_environment.default.APP.host}/api/v2/companies/${this.get('selectedCompany.id')}/toggle_pet_parents_activated`, {
          send_welcome_emails: true
        }).then(() => {
          Ember.set(this.get('selectedCompany'), 'pet_parents_activated', !this.get('selectedCompany.pet_parents_activated'));
          this.set('selectedCompany', null);
        });
      },

      togglePetParentsActivatedWithoutEmails() {
        this.set('showPetParentsActivatedModal', false);
        Ember.$.post(`${_environment.default.APP.host}/api/v2/companies/${this.get('selectedCompany.id')}/toggle_pet_parents_activated`, {
          send_welcome_emails: false
        }).then(() => {
          Ember.set(this.get('selectedCompany'), 'pet_parents_activated', !this.get('selectedCompany.pet_parents_activated'));
          this.set('selectedCompany', null);
        });
      },

      showFreeTrialModal(company) {
        this.set('freeTrialModalOpen', true);
        this.set('selectedCompany', company);
        this.set('originalFreeTrialEnds', company.free_trial_ends);
      },

      closeFreeTrialModal() {
        this.set('selectedCompany.free_trial_ends', this.get('originalFreeTrialEnds'));
        this.set('selectedCompany', null);
        this.set('originalFreeTrialEnds', null);
        this.set('freeTrialModalOpen', false);
      },

      updateFreeTrial(company) {
        _jquery.default.post(`${_environment.default.APP.host}/api/v2/companies/${company.id}/update_free_trial`, {
          end_date: company.free_trial_ends
        }).then(() => {
          this.set('selectedCompany', null);
          this.set('originalFreeTrialEnds', null);
          this.set('freeTrialModalOpen', false);
        });
      },

      showHubspotModal(company) {
        this.set('hubspotModalOpen', true);
        this.set('selectedCompany', company);
        this.set('originalHubspotId', company.hubspot_id);
      },

      closeHubpotModal() {
        this.set('selectedCompany.hubspot_id', this.get('originalHubspotId'));
        this.set('selectedCompany', null);
        this.set('originalHubspotId', null);
        this.set('hubspotModalOpen', false);
      },

      updateHubspotId(company) {
        _jquery.default.post(`${_environment.default.APP.host}/api/v2/companies/${company.id}/update_hubspot_id`, {
          hubspot_id: company.hubspot_id
        }).then(() => {
          this.set('selectedCompany', null);
          this.set('originalHubspotId', null);
          this.set('hubspotModalOpen', false);
        });
      },

      triggerStripeVerification(company) {
        this.set('selectedCompany', company);
        this.set('showStripeVerificationModal', true);
      },

      closeStripeVerificationModal() {
        this.set('selectedCompany', null);
        this.set('showStripeVerificationModal', false);
      },

      confirmStripeVerification(company) {
        _jquery.default.post(`${_environment.default.APP.host}/api/v2/admin/stripe_verification`, {
          company_id: company.id
        }).then(() => {
          this.set('selectedCompany', null);
          this.set('showStripeVerificationModal', false);
        });
      }

    }
  });

  _exports.default = _default;
});