define("frontend/components/datepicker-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['datepicker-side-panel'],
    date: null,
    hasSave: true,
    actions: {
      close() {
        this.close();
      },

      changeDate(date) {
        this.changeDate(date);
      }

    }
  });

  _exports.default = _default;
});