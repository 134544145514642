define("frontend/routes/profile", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model() {
      return this.store.findRecord('user', this.get('session.data.authenticated.tokenData.user_id'));
    },

    actions: {
      cancel() {
        this.get('controller.model').rollbackAttributes();
        this.transitionTo('index');
      },

      uploadedPhoto(photo) {
        this.set('controller.model.avatar_url', photo);
      }

    }
  });

  _exports.default = _default;
});