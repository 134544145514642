define("frontend/services/activity-logs", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    run: {
      debounce
    }
  } = Ember;

  var _default = Ember.Service.extend({
    logs: [],
    loadedAll: false,
    currentUser: Ember.inject.service(),
    unreadAfter: null,
    filter: '',
    earliestTime: Ember.computed('logs.[]', function () {
      let logs = this.get('logs');

      if (logs.length > 0) {
        logs = logs.sortBy('created_at');
        return logs[0].created_at;
      }
    }),
    hasUnreadLogs: Ember.computed('unreadAfter', 'logs', 'logs.[]', function () {
      let hasUnreadLogs = false;
      let logArray = this.get('logs');
      logArray.forEach(log => {
        if (log.created_at > this.get('unreadAfter')) {
          hasUnreadLogs = true;
        }
      });
      return hasUnreadLogs;
    }),
    filterChanged: Ember.observer('filter', function () {
      this.set('logs', []); // eslint-disable-next-line no-undef

      this.set('logIds', new Set());
      this.set('loadedAll', false);
      this.notifyPropertyChange('logs');
      debounce(this, this.pullLogs, false, 1000);
    }),

    load() {
      let self = this;
      this.get('filterChanged'); // eslint-disable-next-line no-undef

      this.set('logIds', new Set()); // This needs to run after currentUser is loaded

      setTimeout(() => {
        self.pullLogs(false);
      }, 5000);
      setInterval(function () {
        self.pullLogs(false);
      }, 60000);
    },

    setUnreadAfter() {
      this.set('unreadAfter', moment().format());
    },

    pullLogs() {
      let pullPaginated = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      if (!this.get('currentUser.user.company.id')) {
        return;
      }

      let self = this;
      let url = `${_environment.default.APP.host}/api/v2/activity_logs?filter=${this.get('filter')}`;

      if (pullPaginated) {
        url += `&earliest=${this.get('earliestTime')}`;
      }

      return _jquery.default.ajax({
        url,
        method: 'GET'
      }).then(response => {
        if (self.isDestroyed) {
          return;
        }

        if (pullPaginated && response.logs.length == 0) {
          self.set('loadedAll', true);
          return;
        }

        if (self.get('filter') && response.logs.length < 15) {
          self.set('loadedAll', true);
        }

        if (self.get('unreadAfter') == null) {
          self.set('unreadAfter', moment(response.last_seen).format());
        }

        let currentLogIds = self.get('logIds');
        response.logs.forEach(log => {
          if (!currentLogIds.has(log.id)) {
            self.get('logs').pushObject(log);
            currentLogIds.add(log.id);
          }
        });
        self.notifyPropertyChange('logs');
        self.set('logIds', currentLogIds);
      });
    }

  });

  _exports.default = _default;
});