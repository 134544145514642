define("frontend/initializers/firebase", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    if (typeof firebase !== 'undefined') {
      // eslint-disable-next-line no-undef
      firebase.initializeApp({
        apiKey: _environment.default.APP.FIRESTORE.API_KEY,
        authDomain: _environment.default.APP.FIRESTORE.AUTH_DOMAIN,
        projectId: _environment.default.APP.FIRESTORE.PROJECT_ID
      });
    }
  }

  var _default = {
    name: 'firebase',
    initialize
  };
  _exports.default = _default;
});