define("frontend/instance-initializers/hubspot", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    let router = applicationInstance.lookup('router:main');
    router.on('routeDidChange', function () {
      if (_environment.default.integrations.hubspot) {
        let path = this.get('url');

        if (!path) {
          let loc = window.location;
          path = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
        }

        let _hsq = window._hsq = window._hsq || [];

        _hsq.push(['setPath', path]);

        _hsq.push(['trackPageView']);
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});