define("frontend/routes/add-bank-account", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return _jquery.default.ajax({
        url: `${_environment.default.APP.host}/api/v2/users/get_bank_info?emd5=${params.emd5}`,
        method: 'GET'
      });
    }

  });

  _exports.default = _default;
});