define("frontend/components/adaptive-paper-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['adaptive-paper-switch'],
    actions: {
      onChange() {
        this.get('onChange')(...arguments);
      }

    }
  });

  _exports.default = _default;
});