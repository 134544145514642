define("frontend/controllers/portal/invoices-summary/company/payment-detail", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed,
    inject,
    observer
  } = Ember;

  var _default = Ember.Controller.extend({
    queryParams: ['showAddTip'],
    deletedAdjustments: [],
    addingAdjustment: false,
    company: inject.controller('portal.invoices-summary.company'),
    companyId: computed.readOnly('company.company_id'),
    showAddTip: false,
    autoAddTip: observer('showAddTip', 'company.fetchedCustomerInfo', function () {
      if (this.get('showAddTip') && this.get('company.fetchedCustomerInfo')) {
        Ember.run.scheduleOnce('afterRender', () => {
          this.send('addTip');
        });
      }
    }),
    actions: {
      addTip() {
        this.get('company').send('clickAddTip', this.get('model'));
      },

      print() {
        let w = window.open();

        _jquery.default.ajax({
          url: `${_environment.default.APP.host}/api/v2/invoice_payments/${this.get('model.id')}`,
          method: 'GET',
          dataType: 'html'
        }).done(response => {
          w.document.open();
          w.document.write(response);
          w.document.close();
          $(w).load(() => {
            w.print();
          });
        }).fail(function () {// TODO: Show error dialog?
        });
      }

    }
  });

  _exports.default = _default;
});