define("frontend/utils/invoice-frequency-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NEVER = null;
  const BIWEEKLY = 'biweekly';
  const SEMIMONTHLY = 'semimonthly';
  const WEEKLY = 'weekly';
  const MONTHLY = 'monthly';
  const DAILY = 'daily';
  var _default = {
    NEVER,
    BIWEEKLY,
    SEMIMONTHLY,
    WEEKLY,
    MONTHLY,
    DAILY
  };
  _exports.default = _default;
});