define("frontend/components/plaid-dropin", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    integration: null,
    user: null,
    user_id: null,
    reloadAfterSave: false,
    callSuccessAfterSave: false,
    autoOpen: false,
    setAutoPay: true,
    showManualClose: true,
    accountHolderName: null,
    calcd_user_id: Ember.computed('user', 'user_id', function () {
      if (this.get('user_id')) {
        return this.get('user_id');
      } else {
        return this.get('user').get('id');
      }
    }),

    didInsertElement() {
      let controller = Ember.getOwner(this).lookup('controller:application');
      let reload = this.get('reloadAfterSave');
      let callSuccess = this.get('callSuccessAfterSave');
      let set_auto_pay = this.get('setAutoPay');
      let user_id = this.get('user_id') || this.get('user.id');
      let url = `${_environment.default.APP.host}/api/v2/users/${user_id}/create_stripe_payment_method`;
      let self = this;
      this.getPlaidLinkToken(user_id).then(response => {
        let linkHandler = Plaid.create({
          env: _environment.default.plaid_env,
          token: response.token,
          selectAccount: true,
          onSuccess: (public_token, metadata) => {
            if (this.setSaving) {
              this.setSaving();
            }

            _jquery.default.ajax({
              url,
              method: 'POST',
              data: {
                stripe_token: {
                  token: public_token,
                  account_id: metadata.account_id
                },
                payment_method: 'bank_account',
                id: user_id,
                set_auto_pay
              }
            }).done(() => {
              if (reload) {
                location.reload();
              } else if (callSuccess) {
                self.send('saveSuccess');
              }
            }).fail(() => {
              controller.send('notify', {
                message: 'Unexpected error saving payment info',
                type: 'error'
              });
            });
          },
          onExit: (err, metadata) => {
            if (err != null) {
              (0, _jquery.default)('#plaid-errors').text(err);
            } else if (metadata.status == 'institution_not_found') {
              self.set('openManualBankAccount', true);
            }
          }
        });

        if (this.get('autoOpen')) {
          linkHandler.open();
        }

        (0, _jquery.default)(this.element.querySelectorAll('#plaid-link-div')).click(() => linkHandler.open());
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)('#plaid-link-div').off('click');
      (0, _jquery.default)('#new-plaid-link-div').off('click');
    },

    getPlaidLinkToken(userId) {
      return _jquery.default.ajax({
        url: `/api/v2/users/${userId}/plaid_link_token`,
        method: 'GET'
      });
    },

    actions: {
      closeManualBankAccount() {
        this.set('openManualBankAccount', false);
      },

      saveSuccess() {
        this.saveSuccess();
      }

    }
  });

  _exports.default = _default;
});