define("frontend/routes/edit-client", ["exports", "frontend/routes/base-route", "frontend/mixins/transition-back-route"], function (_exports, _baseRoute, _transitionBackRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject,
    computed
  } = Ember;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    queryParams: {
      editSection: {
        refreshModel: false
      }
    },
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('addClientSaving.saving'),

    model(params) {
      let {
        household_id
      } = params;
      return this.store.findRecord('household', household_id);
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('saving', false);
    },

    // The below code performing some action on a model relationship is here likely due to how we are saving these types
    // of records. This could result in duplicate records being displayed on the UI or other potential issues. If you
    // notice odd behaviors with a relationship that is saved as part of this component try adding it to rollbackRecordModels
    rollbackRecordModels: ['phone_number', 'address', 'email', 'vaccination', 'pet_prescription'],

    rollbackAttributesRecords() {
      this.get('rollbackRecordModels').forEach(modelName => {
        this.store.peekAll(modelName).filterBy('hasDirtyAttributes', true).forEach(m => m.rollbackAttributes());
      });
    },

    removeNewRecords() {
      this.get('rollbackRecordModels').forEach(modelName => {
        this.store.peekAll(modelName).filter(m => m.get('id') === undefined).forEach(m => m.deleteRecord());
      });
    },

    reloadDirtyRecords() {
      this.get('rollbackRecordModels').forEach(modelName => {
        // If the model id is null we cannot reload it so make sure that an id actually exists before we reload anything
        this.store.peekAll(modelName).filter(m => m.get('id')).forEach(m => m.reload());
      });
    },

    rollbackHousehold(household) {
      household.rollbackAttributes();
      household.get('users').forEach(householdUser => {
        if (householdUser.get('id') && householdUser.get('user.id')) {
          householdUser.rollbackAttributes();
          householdUser.get('user.content').rollbackAttributes();
        }
      });
      household.get('pets').forEach(pet => pet.rollbackAttributes());
      this.rollbackAttributesRecords.call(this);
    },

    actions: {
      clickPetOrUser(petOrUser) {
        if (petOrUser.get('modelName') === 'pet') {
          this.transitionTo('edit-client.pet', petOrUser);
        } else {
          let household = this.get('controller.model');

          if (household.get('owner.id') === petOrUser.get('id')) {
            this.transitionTo('edit-client.primary-contact', household);
          } else {
            this.transitionTo('edit-client.secondary-contact', household);
          }
        }
      },

      createPetOrUser(petOrUser) {
        let household = this.get('controller.model');
        this.rollbackHousehold(household);

        if (petOrUser === 'pet') {
          this.transitionTo('edit-client.add-pet', household);
        } else {
          this.transitionTo('edit-client.add-secondary-contact', household);
        }
      },

      save() {
        let household = this.get('controller.model');
        let allEmails = [];
        household.get('users').map(user => {
          user.get('emails').forEach(email => {
            if (!email.get('isDeleted') && !Ember.isBlank(email.get('address'))) {
              allEmails.push(email.get('address').toLowerCase());
            }
          });
        });
        let emails = [];
        let duplicateEmail = false;
        allEmails.forEach(address => {
          if (Ember.isBlank(address)) {
            return;
          }

          address = address.toLowerCase();

          if (emails.includes(address)) {
            duplicateEmail = true;
            this.get('flashMessage').sendFlashMessage(`Sorry, an email can’t belong to multiple contacts! Please remove ${address} from one of your human profiles before proceeding.`, 'user-error');
            return;
          }

          emails.push(address);
        });

        if (duplicateEmail) {
          return;
        }

        this.set('saving', true);
        let userPromises = household.get('users').map(user => user.save());
        let petPromises = household.get('pets').map(pet => pet.save());
        let promises = [...userPromises, ...petPromises];
        return Ember.RSVP.Promise.all(promises).then(() => {
          this.removeNewRecords.call(this);
          this.reloadDirtyRecords.call(this);
          this.rollbackAttributesRecords.call(this);
          let backRoute = this.get('controller.backRoute');
          let backId = this.get('controller.backId');
          let backHouseholdId = this.get('controller.backHouseholdId');
          this.get('controller.model').reload();
          this.transitionBack(backRoute, backHouseholdId, backId).then(() => {
            this.set('saving', false);
          });
        });
      },

      transitionBack() {
        let backHouseholdId = this.get('controller.backHouseholdId');
        let backRoute = this.get('controller.backRoute');
        let backId = this.get('controller.backId');
        this.transitionBack(backRoute, backHouseholdId, backId);
      },

      redirectToSearch(email) {
        this.transitionTo('add-client.client-search', {
          queryParams: {
            email
          }
        });
      },

      cancel() {
        let household = this.get('controller.model');
        this.rollbackHousehold(household);
        this.send('transitionBack');
      }

    }
  });

  _exports.default = _default;
});