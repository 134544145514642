define("frontend/components/mobile-masked-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-text-input', 'mobile-masked-input'],
    classNameBindings: ['borderBottom', 'borderTop'],
    label: null,
    value: null,
    type: null,
    borderBottom: false,
    borderTop: true,

    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('input')).mask(this.get('mask'), {
        selectOnFocus: true
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element.querySelectorAll('input')).unmask();
    }

  });

  _exports.default = _default;
});