define("frontend/components/client-profile-contact-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    website: null,
    doctorName: null,
    fullAddress: null,
    email: null,
    phone: null,
    actions: {
      tapOnDirections() {
        const fullAddress = this.get('fullAddress');
        let encodedAddress = encodeURIComponent(fullAddress);
        window.location.href = `https://maps.google.com?q=${encodedAddress}`;
      },

      tapOnEmail() {
        const email = this.get('email');
        window.location.href = `mailto:${email}`;
      },

      tapOnPhone() {
        const phone = this.get('phone');
        window.location.href = `tel:${phone}`;
      }

    }
  });

  _exports.default = _default;
});