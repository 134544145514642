define("frontend/routes/client-list/household/appointments", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject
  } = Ember;

  var _default = _baseRoute.default.extend({
    queryParams: {
      appointmentType: {
        refreshModel: true
      }
    },
    currentUser: inject.service(),
    infinity: inject.service(),

    model(_ref) {
      let {
        appointmentType
      } = _ref;

      let household = this._super(...arguments);

      let userIsStaff = this.get('currentUser.user.isStaff');

      if (userIsStaff) {
        return {
          forbidden: true
        };
      }

      if (appointmentType === 'repeating') {
        let recurring_appointment_groups = this.store.query('recurring-appointment-group', {
          household_id: household.get('id')
        });
        return Ember.RSVP.hash({
          household,
          recurring_appointment_groups
        });
      } else if (appointmentType === 'preferences') {
        let employees = this.store.findAll('employee');
        household.reload();
        return Ember.RSVP.hash({
          household,
          employees
        });
      } else {
        return Ember.RSVP.hash({
          household
        });
      }
    },

    afterModel(model) {
      // This will remove any newly created preferred staff while we load the real ones
      // from the backend
      // Need to do this in two loops since rolling back attributes will remove it
      // from preferred_staff and cause the forEach to go out of bounds
      let staffToRollback = [];
      model.household.get('preferred_staff').forEach(staff => staffToRollback.push(staff));
      staffToRollback.forEach(staff => staff.rollbackAttributes());
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('destroying', false);
      controller.set('removingAppointment', null);
      controller.notifyPropertyChange('recurringAppointmentGroups');
      controller.set('selectedAppointments', []);
      controller.set('showAppointmentModal', false);
      controller.set('bulkEditing', false);
    },

    actions: {
      loading(transition) {
        let controller = this.controllerFor('client-list.household.appointments');
        controller.set('loading', true);
        transition.promise.finally(() => {
          controller.set('loading', false);
        });
      },

      transitionToAddAppointment(household) {
        let queryParams = {
          selectedHouseholdId: household.get('id'),
          backRoute: 'client-list.household.appointments',
          filter: household.get('owner.fullName'),
          backHouseholdId: household.get('id')
        };
        this.transitionTo('add-appointment.index', {
          queryParams
        });
      },

      transitionToEditSingleAppointment(appointmentIds) {
        let queryParams = {
          ids: appointmentIds,
          backRoute: 'client-list.household.appointments',
          backHouseholdId: this.get('controller.model.household.id')
        };
        this.transitionTo('edit-single-appointment', {
          queryParams
        });
      },

      transitionToEditRecurringAppointmentGroup(group) {
        let queryParams = {
          backRoute: 'client-list.household.appointments',
          backHouseholdId: this.get('controller.model.household.id')
        };
        this.transitionTo('edit-recurring-appointment-group', group.get('id'), {
          queryParams
        });
      },

      removeRecurringAppointmentGroup(appointmentGroup) {
        let queryParams = {
          backRoute: 'client-list.household.appointments'
        };
        this.transitionTo('remove-recurring-appointment-group', appointmentGroup.get('id'), {
          queryParams
        });
      },

      infinityLoad() {
        this._infinityLoad();
      }

    }
  });

  _exports.default = _default;
});