define("frontend/controllers/index", ["exports", "moment", "frontend/config/environment", "jquery"], function (_exports, _moment, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    index: true,
    todaysDate: (0, _moment.default)().format('YYYY-MM-DD'),
    currentUser: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    showPage: !_environment.default.unstack_enabled,
    actions: {
      submitBetaRequest(_ref) {
        let {
          name,
          email
        } = _ref;

        _jquery.default.ajax({
          url: '/api/v1/support_messages/beta_sign_up',
          method: 'POST',
          data: {
            name,
            email
          }
        }).done(() => {
          this.get('flashMessage').success('We’ll be in touch shortly. Thanks for your interest!');
        }).fail(data => {
          this.get('flashMessage').error(JSON.parse(data.responseText).errors[0]);
        });
      },

      sendMessage(_ref2) {
        let {
          name,
          email,
          subject,
          message
        } = _ref2;

        _jquery.default.ajax({
          url: '/api/v1/support_messages',
          method: 'POST',
          data: {
            name,
            email,
            subject,
            message
          }
        }).done(() => {
          this.get('flashMessage').success('Your message is on its way. Thanks for contacting us!');
        }).fail(data => {
          this.get('flashMessage').error(JSON.parse(data.responseText).errors[0]);
        });
      }

    }
  });

  _exports.default = _default;
});