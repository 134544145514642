define("frontend/components/pet-tab-pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    computed
  } = Ember;

  var _default = Ember.Component.extend({
    pet: null,
    attributeBindings: ['id'],
    classNames: ['tab-pane'],
    id: computed.readOnly('pet.id')
  });

  _exports.default = _default;
});